/* eslint-disable no-unused-vars */

import "datatables.net-buttons-dt/css/buttons.dataTables.css";
import "datatables.net-buttons-dt/css/buttons.dataTables.min.css";
import "datatables.net-buttons-dt/js/buttons.dataTables.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import "datatables.net-buttons/js/dataTables.buttons.js";
import DataTable from "datatables.net-dt";
import "datatables.net-dt/css/dataTables.dataTables.css";
import "datatables.net-responsive-dt";
import "datatables.net-scroller";
import "datatables.net-scroller-dt/css/scroller.dataTables.css";
import "datatables.net-scroller-dt/css/scroller.dataTables.min.css";
import "datatables.net-scroller-dt/js/scroller.dataTables.js";
import "datatables.net-scroller/js/dataTables.scroller.js";
import "datatables.net-select/js/dataTables.select.js";

import JSZip from "jszip";
import PDFMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

import React, { useCallback, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import {
  MdOutlineKeyboardArrowDown,
  MdOutlineKeyboardArrowUp,
} from "react-icons/md";
import { toast } from "react-toastify";

import { getFeeSummaryHeadwiseDetailsAPI } from "../../API/getFeeSummaryHeadwiseDetailsAPI";
import { getFeeSummaryStudentHeadWiseAPI } from "../../API/getFeeSummaryStudentHeadWiseAPI";
import { getFeeSummaryStudentMonthWiseAPI } from "../../API/getFeeSummaryStudentMonthWiseAPI";
import ModalComponent from "../../components/Modal";
import Spinner from "../../components/Spinner";
import { GetClassSectionMasterLists } from "../../API/GetClassSectionMasterLists";
import { getFeeSummaryStudentListDetailsAPI } from "../../API/getFeeSummaryStudentListDetailsAPI";
import ModalFunctionComponent from "../../components/ModalFunctionComponent";

window.JSZip = JSZip;
PDFMake.vfs = pdfFonts.pdfMake?.vfs;

const columns = [
  { index: 0, title: "select", data: "select", visible: true },
  { index: 1, title: "ADM NO", data: "AdmNo", visible: true },
  { index: 2, title: "NAME", data: "Name", visible: true },
  { index: 3, title: "CLASS", data: "Class", visible: true },
  { index: 4, title: "SECTION", data: "Section", visible: true },
  { index: 5, title: "DEMAND", data: "Demand", visible: true },
  { index: 6, title: "CONC", data: "Conc", visible: true },
  { index: 16, title: "ACTUAL DEMAND", data: "ActualDemand", visible: true },
  { index: 62, title: "COLLECT", data: "Collect", visible: true },
  { index: 8, title: "DUE AMOUNT", data: "DueAmount", visible: true },
  { index: 9, title: "", data: "sl", visible: true },
  { index: 10, title: "", data: "hw", visible: true },
];
const columns2 = [
  { index: 0, title: "HEAD", data: "Head", visible: true },
  { index: 1, title: "DEMAND", data: "Demand", visible: true },
  { index: 2, title: "CONC", data: "Concession", visible: true },
  { index: 3, title: "ACTUAL DEMAND", data: "ActualDemand", visible: true },
  { index: 4, title: "COLLECT", data: "Collect", visible: true },
  { index: 4, title: "DUE AMOUNT", data: "DueAmount", visible: true },
];

const QuickInfoFeeSummaryClassList = () => {
  const location = useLocation();

  const [loading, setLoading] = useState(false);
  const [feeSummaryDetails, setFeeSummaryDetails] = useState([]);
  const [headwiseSelectedClass, setHeadwiseSelectedClass] = useState(
    location.state.selectedClass
  );
  const [demand, setDemand] = useState(null);
  const [concession, setConcession] = useState(null);
  const [headWiseDetails, setHeadWiseDetails] = useState([]);
  const [actualDemand, setActualDemand] = useState(null);
  const [collection, setCollection] = useState(null);
  const [dueAmount, setDueAmount] = useState(null);
  const [nofStudents, setNofStudents] = useState(null);
  const [nofDefaulters, setNofDefaulters] = useState(null);
  const [isClosed, setIsClosed] = useState(false);
  const [spin, setSpin] = useState(false);
  const [classAndSub, setClassAndSub] = useState([]);
  const [classList, setClassList] = useState([]);
  const [sectionList, setSectionList] = useState(location.state.sectionList);
  const [selectedClass, setSelectedClass] = useState(
    location.state.selectedClass
  );
  const [selectedSection, setSelectedSection] = useState("");
  const [dis, setDis] = useState(location.state.dispType);
  const [openModal, setOpenModal] = useState(false);
  const [openModalData, setOpenModalData] = useState("");
  const [modalComponent, setModalComponent] = useState(false);
  const [userDetails, setUserDetails] = useState(null);

  const [tableCol, setTableCol] = useState(columns);
  const [tableData, setTableData] = useState([]);
  let tableRef = useRef(null);
  let tableInstance = useRef(null);
  const [tableCol2, setTableCol2] = useState(columns2);
  const [tableData2, setTableData2] = useState([]);
  let tableRef2 = useRef(null);
  let tableInstance2 = useRef(null);

  const { customId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    // setLoading(true)
    const fetchData = async () => {
      setLoading(true);
      const users = JSON.parse(localStorage.getItem("registeredUsers")) || [];

      if (customId) {
        const decodedData = JSON.parse(atob(customId));
        let matchedItem = [];

        users.forEach((innerArray) => {
          const [userData] = innerArray;

          if (
            userData.userId === decodedData.userId &&
            userData.userType === decodedData.userType &&
            userData.schoolId === decodedData.schoolId &&
            userData.year === decodedData.year
          ) {
            matchedItem.push(userData);
          }
        });
        // handleClassAndSectionFetch(
        //   decodedData.userId,
        //   decodedData.schoolId,
        //   decodedData.userType,
        //   decodedData.year
        // );
        setUserDetails(matchedItem);

        setLoading(false);
      }
    };

    fetchData();
  }, [customId]);

  const fetchData = async () => {
    setLoading(true);
    if (userDetails) {
      const result = await getFeeSummaryStudentListDetailsAPI(
        userDetails[0].userId,
        userDetails[0].schoolId,
        userDetails[0].userType,
        userDetails[0].year,
        selectedClass,
        selectedSection,
        dis
      );
      setFeeSummaryDetails(result);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [userDetails]);

  const TableData = feeSummaryDetails.map((data) => ({
    AdmNo: data.AdmNo,
    Name: data.Sname,
    Class: data.ClName,
    Section: data.SecName,
    Demand: data.Demand,
    Conc: data.Conc,
    ActualDemand: data.ToBePay,
    Collect: data.Collect,
    DueAmount: data.DueAmt,
    StdMstid: data?.StdMstid,
    StdYrId: data?.StdYrId,
  }));

  const HeadWiseTable = headWiseDetails?.map((data) => ({
    Head: data?.ClName,
    Demand: data?.Demand,
    Concession: data?.Conc,
    ActualDemand: data?.ToBePay,
    Collect: data?.Collect,
    DueAmount: data?.DueAmt,
  }));

  const HeadWiseTable2 = headWiseDetails?.map((data) => ({
    Head: data?.ClName,
    Demand: data?.Demand,
    Concession: data?.Conc,
    ActualDemand: data?.ToBePay,
    Collect: data?.Collect,
    DueAmount: data?.DueAmt,
  }));

  const HeadWiseTable3 = HeadWiseTable?.map((data) => ({
    Head: data?.Head,
    Demand: data?.Demand,
    Concession: data?.Concession,
    ActualDemand: data?.ActualDemand,
    Collect: data?.Collect,
    DueAmount: data?.DueAmount,
  }));

  const handleClassAndSectionFetchGlobal = async () => {
    setLoading(true);
    if (userDetails) {
      try {
        const respone = await GetClassSectionMasterLists(
          userDetails[0].userId,
          userDetails[0].schoolId,
          userDetails[0].userType,
          userDetails[0].year
        );
        const ClassList = respone.filter(
          (item) => item.MasterType === "ClassMaster"
        );

        setClassAndSub(ClassList);
        setClassList(ClassList.map((item) => item.Head1));
        setLoading(false);
      } catch (error) {
        console.log(error);
        toast.error("Error");
        setLoading(false);
      }
    }
  };

  const handleClassChange = (e) => {
    if (classAndSub.length > 0) {
      const userSelectedClass = classAndSub.filter(
        (items) => items.Head1 === e.target.value
      );
      const sectionList = userSelectedClass.flatMap((item) =>
        item.Head2.split(",")
      );
      if (sectionList?.length > 0) {
        setSectionList(sectionList);
      } else {
        setSectionList(["A", "B", "C", "D"]);
      }
    }
    setSelectedClass(e.target.value);
  };

  const handleSectionChange = useCallback(
    (e) => {
      setSelectedSection(e.target.value);
    },
    [setSelectedSection]
  );

  useEffect(() => {
    handleClassAndSectionFetchGlobal();
  }, [userDetails]);

  const calculateSums = useCallback(() => {
    let demandSum = 0;
    let concessionSum = 0;
    let actualDemandSum = 0;
    let collectionSum = 0;
    let dueAmountSum = 0;
    TableData.forEach((data) => {
      demandSum += data?.Demand;
      concessionSum += data?.Conc;
      actualDemandSum += data?.ActualDemand;
      collectionSum += data?.Collect;
      dueAmountSum += data?.DueAmount;
    });

    setDemand(demandSum);
    setConcession(concessionSum);
    setActualDemand(actualDemandSum);
    setCollection(collectionSum);
    setDueAmount(dueAmountSum);
    if (dis == 0) {
      setNofStudents(TableData?.length);
      setNofDefaulters(0);
    } else {
      setNofStudents(0);
      setNofDefaulters(TableData?.length);
    }
  }, [TableData, dis]);

  useEffect(() => {
    calculateSums();
  }, [calculateSums]);

  const wholeClassSection = location.state.wholeClassSection;

  const ClassDetailTypeData = [
    {
      index: 1,
      label: "Display Type",
      dbLabel: "Reqdisptype",
      SelectOption: ["Demand/Collection List", "Defaulters List"],
    },
  ];

  const handleSelectChange = (label, value) => {
    console.log(`Selected value for ${label}: ${value}`);
    if (label === "ReqClass") setSelectedClass(value);
  };

  const handleCloseClick = () => {
    setTimeout(() => setIsClosed(!isClosed), 250);
  };

  const handleHeadwiseButt = async () => {
    setLoading(true);
    try {
      const result2 = await getFeeSummaryHeadwiseDetailsAPI(
        userDetails[0].year,
        headwiseSelectedClass,
        ""
      );
      setHeadWiseDetails(result2);
      setModalComponent(!modalComponent);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
    setLoading(false);
  };

  const handleHeadwiseList = async (student) => {
    setLoading(true);
    try {
      if (userDetails) {
        setOpenModalData(student);
        const result2 = await getFeeSummaryStudentHeadWiseAPI(
          userDetails[0].userId,
          userDetails[0].schoolId,
          userDetails[0].userType,
          userDetails[0].year,
          student?.AdmNo
        );
        setHeadWiseDetails(result2);
        setOpenModal(!openModal);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
    setLoading(false);
  };

  const handleMonthWiseList = async (student) => {
    setLoading(true);
    if (userDetails) {
      try {
        setOpenModalData(student);
        const result2 = await getFeeSummaryStudentMonthWiseAPI(
          userDetails[0].userId,
          userDetails[0].schoolId,
          userDetails[0].userType,
          userDetails[0].year,
          student?.AdmNo
        );
        setHeadWiseDetails(result2);
        setOpenModal(!openModal);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }
    setLoading(false);
  };

  window.handleShowDetails = (cls) => {
    navigate(`/admin/u/${customId}/QuickInfo/student_profile`, {
      state: {
        admno: cls,
      },
    });
  };

  window.headWiseList = async (student) => {
    setLoading(true);
    try {
      setOpenModalData(student);
      const result2 = await getFeeSummaryStudentHeadWiseAPI(student?.AdmNo);
      setHeadWiseDetails(result2);
      setOpenModal(!openModal);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
    setLoading(false);
  };

  window.monthWiseList = async (student) => {
    setLoading(true);
    try {
      setOpenModalData(student);
      const result2 = await getFeeSummaryStudentMonthWiseAPI(student?.AdmNo);
      setHeadWiseDetails(result2);
      setOpenModal(!openModal);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
    setLoading(false);
  };

  const TableData2 = feeSummaryDetails?.map((data) => ({
    AdmNo: data.AdmNo,
    Name: data.Sname,
    Class: data.ClName,
    Section: data.SecName,
    Demand: data.Demand,
    Conc: data.Conc,
    ActualDemand: data.ToBePay,
    Collect: data.Collect,
    DueAmount: data.DueAmt,
    sl: `<div class="h-full w-full grid grid-cols-1 gap-2">
          <p onClick="monthWiseList('${data}')" class="text-blue-500 cursor-pointer text-sm capitalize">
            Month wise List
          </p>
          <p
            onClick="handleShowDetails('${data?.AdmNo}')"
            class="text-blue-500 cursor-pointer text-sm capitalize"
          >
            Show Profile
          </p>
        </div>`,
    hw: ` <p onClick="headWiseList('${data}')" class="text-blue-500 cursor-pointer text-sm capitalize">
            Head wise List 
          </p>`,
    select: `<input type="radio" class="radioButton" name="group1" />`,
  }));

  useEffect(() => {
    // Initialize DataTable when the component mounts
    if (tableCol2 && HeadWiseTable3) {
      tableInstance2.current = new DataTable("#studentDetails3", {
        responsive: false,
        // dom: "Bfrtip",
        layout: {
          topStart: {
            buttons: [
              "excel",
              "csv",
              "xls",
              "pdf",
              {
                extend: "print",
                exportOptions: {
                  columns: ":visible",
                },
              },
              "colvis",
            ],
          },
        },
        // select: true,
        info: "",
        scrollY: 500,
        scrollX: true,
        deferRender: true,
        scroller: true,
        data: HeadWiseTable3,
        columns: tableCol2,
      });
    }
    // Cleanup DataTable instance when the component unmounts
    return () => {
      if (tableInstance2.current !== null) {
        tableInstance2.current.destroy();
      }
    };
  }, [HeadWiseTable3, tableCol2]);

  useEffect(() => {
    // Initialize DataTable when the component mounts
    if (tableCol && TableData2) {
      tableInstance.current = new DataTable("#studentDetails", {
        responsive: false,
        // dom: "Bfrtip",
        layout: {
          topStart: {
            buttons: [
              "excel",
              "csv",
              "xls",
              "pdf",
              {
                extend: "print",
                exportOptions: {
                  columns: ":visible",
                },
              },
              "colvis",
            ],
          },
        },
        // select: true,
        info: "",
        scrollY: 500,
        scrollX: true,
        deferRender: true,
        scroller: true,
        data: TableData2,
        columns: tableCol,
      });
    }
    // Cleanup DataTable instance when the component unmounts
    return () => {
      if (tableInstance.current !== null) {
        tableInstance.current.destroy();
      }
    };
  }, [TableData2, tableCol]);

  useEffect(() => {
    // Initialize DataTable when the component mounts
    if (tableCol2 && HeadWiseTable2) {
      tableInstance2.current = new DataTable("#studentDetails2", {
        responsive: false,
        // dom: "Bfrtip",
        layout: {
          topStart: {
            buttons: [
              "excel",
              "csv",
              "xls",
              "pdf",
              {
                extend: "print",
                exportOptions: {
                  columns: ":visible",
                },
              },
              "colvis",
            ],
          },
        },
        // select: true,
        info: "",
        scrollY: 400,
        scrollX: true,
        deferRender: true,
        scroller: true,
        data: HeadWiseTable2,
        columns: tableCol2,
      });
    }
    // Cleanup DataTable instance when the component unmounts
    return () => {
      if (tableInstance2.current !== null) {
        tableInstance2.current.destroy();
      }
    };
  }, [HeadWiseTable2, tableCol2]);

  return (
    <>
      {modalComponent && (
        <ModalComponent
          title={"Head wise Demand and Collection Details"}
          setModalComponent={setModalComponent}
        >
          {headwiseSelectedClass ? (
            <h2 className="text-black">
              Selected Class: {headwiseSelectedClass}
            </h2>
          ) : (
            ""
          )}
          <div className="relative max-h-96 overflow-x-auto border border-black border-solid rounded-sm">
            <table
              ref={tableRef2}
              id="studentDetails2"
              style={{ width: "100%" }}
              className="h-fit w-fit max-h-[500px] responsive extraCSS display nowrap"
            ></table>

            {/*             
            <table className="w-full bg-white text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
              <thead className="tableHead sticky top-0 z-50 blur-0">
                <tr>
                  <th scope="col" className="p-3 uppercase text-sm md:text-base">
                    Head
                  </th>
                  <th scope="col" className="p-3 uppercase text-sm md:text-base">
                    Demand
                  </th>
                  <th scope="col" className="p-3 uppercase text-sm md:text-base">
                    Conc
                  </th>
                  <th scope="col" className="p-3 uppercase text-sm md:text-base">
                    Actual Demand
                  </th>
                  <th scope="col" className="p-3 uppercase text-sm md:text-base">
                    Collect
                  </th>
                  <th scope="col" className="p-3 uppercase text-sm md:text-base">
                    Due Amount
                  </th>
                </tr>
              </thead>
              <tbody>
                {HeadWiseTable?.map((item, index) => {
                  return (
                    <tr
                      key={index}
                      className={`border-b ${index % 2 === 0 ? "bg-white" : "bg-slate-200"} dark:bg-gray-800 dark:border-gray-700`}
                    >
                      <th
                        scope="row"
                        className="p-3 border border-solid border-black/10 font-medium text-gray-900 md:whitespace-nowrap dark:text-white"
                      >
                        {item?.Head}
                      </th>
                      <td className="p-3 border border-solid border-black/10 font-medium">{item?.Demand}</td>
                      <td className="p-3 border border-solid border-black/10 font-medium">{item?.Concession}</td>
                      <td className="p-3 border border-solid border-black/10 font-medium">{item?.ActualDemand}</td>
                      <td className="p-3 border border-solid border-black/10 font-medium">{item?.Collect}</td>
                      <td className="p-3 border border-solid border-black/10 font-medium">{item?.DueAmount}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table> */}
          </div>
        </ModalComponent>
      )}
      {openModal && (
        <ModalFunctionComponent
          close={setOpenModal}
          title={"Head wise Demand and Collection Details"}
        >
          {openModalData ? (
            <h2 className="text-black">
              Adm No. :{openModalData?.AdmNo} Student :{openModalData?.Name}{" "}
              Class :{`${openModalData?.Class}-${openModalData?.Section}`}
            </h2>
          ) : (
            ""
          )}
          <div className="relative max-h-96 overflow-x-auto border border-black border-solid rounded-sm">
            <table
              ref={tableRef2}
              id="studentDetails3"
              style={{ width: "100%" }}
              className="h-fit w-fit max-h-[500px] responsive extraCSS display nowrap"
            ></table>
            {/* <table className="w-full bg-white text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
              <thead className="tableHead sticky top-0 z-50 blur-0">
                <tr>
                  <th
                    scope="col"
                    className="p-3 uppercase text-sm md:text-base"
                  >
                    Head
                  </th>
                  <th
                    scope="col"
                    className="p-3 uppercase text-sm md:text-base"
                  >
                    Demand
                  </th>
                  <th
                    scope="col"
                    className="p-3 uppercase text-sm md:text-base"
                  >
                    Conc
                  </th>
                  <th
                    scope="col"
                    className="p-3 uppercase text-sm md:text-base"
                  >
                    Actual Demand
                  </th>
                  <th
                    scope="col"
                    className="p-3 uppercase text-sm md:text-base"
                  >
                    Collect
                  </th>
                  <th
                    scope="col"
                    className="p-3 uppercase text-sm md:text-base"
                  >
                    Due Amount
                  </th>
                </tr>
              </thead>
              <tbody>
                {HeadWiseTable?.map((item, index) => {
                  return (
                    <tr
                      key={index}
                      className={`border-b ${
                        index % 2 === 0 ? "bg-white" : "bg-slate-200"
                      } dark:bg-gray-800 dark:border-gray-700`}
                    >
                      <th
                        scope="row"
                        className="p-3 border border-solid border-black/10 font-medium text-gray-900 md:whitespace-nowrap dark:text-white"
                      >
                        {item?.Head}
                      </th>
                      <td className="p-3 border border-solid border-black/10 font-medium">
                        {item?.Demand}
                      </td>
                      <td className="p-3 border border-solid border-black/10 font-medium">
                        {item?.Concession}
                      </td>
                      <td className="p-3 border border-solid border-black/10 font-medium">
                        {item?.ActualDemand}
                      </td>
                      <td className="p-3 border border-solid border-black/10 font-medium">
                        {item?.Collect}
                      </td>
                      <td className="p-3 border border-solid border-black/10 font-medium">
                        {item?.DueAmount}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table> */}
          </div>
        </ModalFunctionComponent>
      )}

      <div className="lg:mt-0 mt-8">
        <div className="container mx-auto p-4 containerBoxShadow">
          <>
            {loading ? (
              <Spinner />
            ) : (
              <>
                <div className="flex justify-start w-full rounded-md bg-gradient-to-r from-cyan-400 to-blue-400 hover:bg-gradient-to-bl">
                  <h2 className="w-full text-center text-2xl p-0 my-auto uppercase text-black font-mono font-semibold">
                    Class wise Demand and Collection Summary
                  </h2>
                  <button
                    onClick={handleCloseClick}
                    className={`w-fit rounded-md p-2 `}
                  >
                    <MdOutlineKeyboardArrowUp
                      className={`duration-500 ease-ease-in-out text-black transition-all ${
                        isClosed ? "rotate-180" : ""
                      } `}
                      size={25}
                    />
                  </button>
                </div>

                <div
                  className={`transition-all duration-700 ease-ease-in ${
                    !isClosed ? "opacity-100 max-h-screen" : "opacity-0 max-h-0"
                  }`}
                >
                  <div className="flex flex-col gap-3 rounded">
                    <div className="flex py-2 formBackground justify-between md:flex-row flex-col md:px-10 px-3 sm:px-5 gap-0 md:gap-10 items-center">
                      <div className="w-full grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-2 justify-evenly items-center">
                        <div className=" flex flex-row items-center gap-2 mb-1 justify-start">
                          <label
                            className="block uppercase tracking-wide text-start text-grey-darker basis-[30%] text-xs font-bold mb-2"
                            htmlFor="grid-state"
                          >
                            Class
                          </label>
                          <div className="relative flex flex-row ">
                            <select
                              onChange={handleClassChange}
                              value={selectedClass}
                              className="textInput min-w-20 w-[150px] md:w-[190px]"
                            >
                              {classList?.map((data, i) => (
                                <option key={i} value={data}>
                                  {data}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div className=" flex flex-row items-center gap-2 mb-1 justify-start">
                          <label
                            className="block uppercase tracking-wide text-start text-grey-darker basis-[30%] text-xs font-bold mb-2"
                            htmlFor="grid-state"
                          >
                            Section
                          </label>
                          <div className="relative flex flex-row ">
                            <select
                              onChange={handleSectionChange}
                              value={selectedSection}
                              className="textInput min-w-20 w-[150px] md:w-[190px]"
                            >
                              {sectionList?.map((data, i) => (
                                <option key={i} value={data}>
                                  {data}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        {ClassDetailTypeData?.map((item) => {
                          return (
                            <div
                              key={item?.index}
                              className=" flex flex-row items-center gap-2 mb-1 justify-start"
                            >
                              <label
                                className="block uppercase tracking-wide text-start text-grey-darker basis-[30%] text-xs font-bold mb-2"
                                for="grid-state"
                              >
                                {item?.label}
                              </label>
                              <div className="relative flex flex-row ">
                                <select
                                  value={dis}
                                  onChange={(e) => setDis(e.target.value)}
                                  className="textInput min-w-20 w-[150px] md:w-[190px]"
                                >
                                  {item?.SelectOption?.map((data, index) => (
                                    <option key={index} value={index}>
                                      {data}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                          );
                        })}
                      </div>

                      <div className="flex  justify-between md:flex-col md:w-fit w-full flex-row gap-1 items-start ">
                        <button
                          onClick={fetchData}
                          className="buttonClass w-full"
                        >
                          Refresh
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="formBackground shapedividers my-3 py-3 px-2 uppercase grid md:grid-cols-4 sm:grid-cols-3 grid-cols-2 justify-evenly tracking-wide text-center text-grey-darker text-xs font-bold mb-2 gap-y-3">
                  <div className="flex justify-center items-center gap-4">
                    <h2>Demand:</h2>
                    <h2>{demand}</h2>
                  </div>
                  <div className="flex justify-center items-center gap-4">
                    <h2>Concession:</h2>
                    <h2>{concession}</h2>
                  </div>
                  <div className="flex justify-center items-center gap-4">
                    <h2>Actual Demand:</h2>
                    <h2>{actualDemand}</h2>
                  </div>
                  <div className="flex justify-center items-center gap-4">
                    <h2>Collection:</h2>
                    <h2>{collection}</h2>
                  </div>
                  <div className="flex justify-center items-center gap-4">
                    <h2>Due Amount:</h2>
                    <h2>{dueAmount}</h2>
                  </div>
                  <div className="flex justify-center items-center gap-4">
                    <h2>Nof Students:</h2>
                    <h2>{nofStudents}</h2>
                  </div>
                  <div className="flex justify-center items-center gap-4">
                    <h2>Nof Defaulters:</h2>
                    <h2>{nofDefaulters}</h2>
                  </div>
                  <div className="flex justify-center items-center gap-4">
                    <button
                      onClick={() => handleHeadwiseButt()}
                      className="buttonClass whitespace-nowrap"
                    >
                      Head wise
                    </button>
                  </div>
                </div>

                <div className="p-1 md:p-2 w-full shadow-lg">
                  <div className="relative max-h-full overflow-x-auto border md:block hidden border-black border-solid rounded-sm">
                    {feeSummaryDetails && feeSummaryDetails?.length > 0 ? (
                      <table
                        ref={tableRef}
                        id="studentDetails"
                        style={{ width: "100%" }}
                        className="h-fit w-fit max-h-[500px] responsive extraCSS display nowrap"
                      ></table>
                    ) : (
                      <div className="h-auto p-5">
                        <h1 className="text-2xl font-bold">Record Not Found</h1>
                      </div>
                    )}
                  </div>

                  <div className="md:hidden block">
                    <div className="max-w-xl mx-auto bg-white shadow-md p-2 rounded-lg">
                      <div className="overflow-x-auto">
                        {TableData.map((item, index) => (
                          <div
                            className="border-2 bg-gradient-to-b from-sky-200 to-cyan-400 rounded-lg mb-4 shadow-lg overflow-hidden"
                            key={index}
                          >
                            <div className="flex items-center  justify-between tabButton py-2 px-4 rounded-t-lg shadow-md">
                              <input
                                className="radioButton"
                                type="radio"
                                name="employeesRadio"
                                id={`employeesRadio-${index}`}
                              />
                              <span className="font-bold text-center w-full">
                                {" "}
                                {item?.Name}
                              </span>
                            </div>
                            <table className="w-full table-auto">
                              <tbody>
                                <tr>
                                  <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                                    <strong>Adm No</strong>
                                  </td>
                                  <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                                    {item?.AdmNo}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                                    <strong>Class</strong>
                                  </td>
                                  <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                                    {item?.Class}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                                    <strong>Section</strong>
                                  </td>
                                  <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                                    {item?.Section}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                                    <strong>Conc</strong>
                                  </td>
                                  <td className="border border-solid border-gray-400 text-start capitalize p-2 w-1/2">
                                    {item?.Conc}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                                    <strong>Actual Demand</strong>
                                  </td>
                                  <td className="border border-solid border-gray-400 text-start capitalize p-2 w-1/2">
                                    {item?.ActualDemand}
                                  </td>
                                </tr>

                                <tr>
                                  <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                                    <strong>Collect</strong>
                                  </td>
                                  <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                                    {item?.Collect}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                                    <strong>Due Amount</strong>
                                  </td>
                                  <td className="border border-solid border-gray-400 text-start capitalize p-2 w-1/2">
                                    {item?.DueAmount}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                                    <strong></strong>
                                  </td>
                                  <td className="border border-solid border-gray-400 text-start capitalize p-2 w-1/2">
                                    <p
                                      onClick={() => handleMonthWiseList(item)}
                                      className="text-white tracking-wider font-medium cursor-pointer text-sm capitalize"
                                    >
                                      Month wise Details
                                    </p>
                                    <p
                                      onClick={() => handleHeadwiseList(item)}
                                      className="text-white tracking-wider font-medium cursor-pointer text-sm capitalize"
                                    >
                                      Head wise Details
                                    </p>
                                    <p
                                      onClick={() =>
                                        navigate(
                                          `/admin/u/${customId}/QuickInfo/student_profile`,
                                          {
                                            state: {
                                              admno: item?.AdmNo,
                                              wholeClassSection,
                                            },
                                          }
                                        )
                                      }
                                      className="text-white tracking-wider font-medium cursor-pointer text-sm capitalize"
                                    >
                                      Show Profile
                                    </p>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </>
        </div>
      </div>
    </>
  );
};

export default QuickInfoFeeSummaryClassList;
