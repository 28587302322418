import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import Spinner from "../../components/Spinner";
import { getUserDataFromLocalByKey } from "../../API/getStudentInfoByKeyLocal";
import icon3 from "../../assets/Dashboard/class-tc.svg";
import icon2 from "../../assets/Dashboard/section-stngthrprt.svg";
import icon1 from "../../assets/Dashboard/strength-report.svg";
import icon4 from "../../assets/Dashboard/cls-sec-tc.svg";
import icon5 from "../../assets/Dashboard/gender-strgt.svg";
import icon6 from "../../assets/Dashboard/gende-sec.svg";

function StrengthAnalyses() {
    const { customId } = useParams();
    const [userDetails, setUserDetails] = useState(null);
    const [loading, setLoading] = useState(true);
    const [initialResourceUrl, setInitialResourceUrl] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            const users = JSON.parse(localStorage.getItem("registeredUsers")) || [];

            if (customId) {
                const decodedData = JSON.parse(atob(customId));
                let matchedItem = [];

                users.forEach((innerArray) => {
                    const [userData] = innerArray;

                    if (
                        userData.userId === decodedData.userId &&
                        userData.userType === decodedData.userType &&
                        userData.schoolId === decodedData.schoolId &&
                        userData.year === decodedData.year
                    ) {
                        matchedItem.push(userData);
                    }
                });

                const keyval = "webResourceURL";
                const dataUrl = await getUserDataFromLocalByKey(
                    decodedData.userId,
                    decodedData.schoolId,
                    decodedData.userType,
                    decodedData.year,
                    keyval
                );
                setInitialResourceUrl(dataUrl);
                setLoading(false);
                setUserDetails(matchedItem);
            }
        };

        fetchData();
    }, [customId]);

    return (
        <>
           <div className="md:mt-6 mt-10">
                <div className="p-2 mb-3 flex items-center w-fit flex-wrap">
                    <ul className="flex items-center">
                        <li className="inline-flex items-center cursor-default">
                            <a href="/" className="text-black font-medium hover:scale-105">
                                <svg
                                    className="w-5 cursor-default h-auto fill-current mx-2"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    fill="#000000"
                                >
                                    <path d="M0 0h24v24H0V0z" fill="none" />
                                    <path d="M10 19v-5h4v5c0 .55.45 1 1 1h3c.55 0 1-.45 1-1v-7h1.7c.46 0 .68-.57.33-.87L12.67 3.6c-.38-.34-.96-.34-1.34 0l-8.36 7.53c-.34.3-.13.87.33.87H5v7c0 .55.45 1 1 1h3c.55 0 1-.45 1-1z" />
                                </svg>
                            </a>

                            <span className="mx-4 h-auto font-medium">/</span>
                        </li>
                        <li className="inline-flex items-center">
                            <p className="text-black font-medium cursor-default hover:scale-105">Quick Info</p>
                            {/* <span className="mx-4 h-auto text-gray-400 font-medium">/</span> */}
                        </li>
                    </ul>
                </div>
                <div className="container mx-auto p-4 containerBoxShadow">
                    <div className="flex flex-col md:flex-row items-center justify-between mb-4">
                        <div className="w-2/3">
                            <h2 className=" md:text-2xl font-bold text-xl mb-2 md:mb-0">
                                Admin :{userDetails && userDetails.length > 0 && userDetails[0].schoolId}
                            </h2>
                            <h4 className="mb-2 font-bold">Select Strength Report Type </h4>
                        </div>
                    </div>

                    <div class="flex flex-wrap">
                        <div class="w-full md:w-1/2 xl:w-1/3 p-6  hover:cursor-pointer ">
                            <Link to={`/admin/u/${customId}/CWSReport`}>
                                <div class="bg-gradient-to-b from-green-200 to-green-100 border-b-4 border-green-600 rounded-lg shadow-xl p-5 hover:transform hover:-translate-y-1 hover:scale-105 transition duration-300 ease-in-out">
                                    <div class="flex flex-row items-center">
                                        <div>
                                            <img src={icon1} width={50} height={10} alt="logo" />
                                        </div>
                                        <div class="flex-1 text-right md:text-center">
                                            <h2 class="font-bold uppercase text-gray-600 text-sm">
                                                Class Wise Strength Report
                                            </h2>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div class="w-full md:w-1/2 xl:w-1/3 p-6  hover:cursor-pointer ">
                            <Link to={`/admin/u/${customId}/SWSReport`}>
                                <div class="bg-gradient-to-b from-pink-200 to-pink-100 border-b-4 border-pink-500 rounded-lg shadow-xl p-5 hover:transform hover:-translate-y-1 hover:scale-105 transition duration-300 ease-in-out">
                                    <div class="flex flex-row items-center">
                                        <div class="flex-shrink pr-4">
                                            <img src={icon2} width={50} height={10} alt="logo" />
                                        </div>
                                        <div class="flex-1 text-right md:text-center">
                                            <h2 class="font-bold uppercase text-gray-600 text-sm">
                                                Section Wise Strength Report
                                            </h2>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div class="w-full md:w-1/2 xl:w-1/3 p-6  hover:cursor-pointer ">
                            <Link to={`/admin/u/${customId}/CTCReport`}>
                                <div class="bg-gradient-to-b from-yellow-200 to-yellow-100 border-b-4 border-yellow-600 rounded-lg shadow-xl p-5 hover:transform hover:-translate-y-1 hover:scale-105 transition duration-300 ease-in-out">
                                    <div class="flex flex-row items-center">
                                        <div class="flex-shrink pr-4">
                                            <img src={icon3} width={50} height={10} alt="logo" />
                                        </div>
                                        <div class="flex-1 text-right md:text-center">
                                            <h2 class="font-bold uppercase text-gray-600 text-sm">Class Wise TC Report</h2>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div class="w-full md:w-1/2 xl:w-1/3 p-6  hover:cursor-pointer ">
                            <Link to={`/admin/u/${customId}/CSTCReport`}>
                                <div class="bg-gradient-to-b from-blue-200 to-blue-100 border-b-4 border-blue-500 rounded-lg shadow-xl p-5 hover:transform hover:-translate-y-1 hover:scale-105 transition duration-300 ease-in-out">
                                    <div class="flex flex-row items-center">
                                        <div class="flex-shrink pr-4">
                                            <img src={icon4} width={50} height={10} alt="logo" />
                                        </div>
                                        <div class="flex-1 text-right md:text-center">
                                            <h2 class="font-bold uppercase text-gray-600 text-sm">
                                                Class & Section Wise TC Report
                                            </h2>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div class="w-full md:w-1/2 xl:w-1/3 p-6  hover:cursor-pointer ">
                            <Link to={`/admin/u/${customId}/GWSReport`}>
                                <div class="bg-gradient-to-b from-indigo-200 to-indigo-100 border-b-4 border-indigo-500 rounded-lg shadow-xl p-5 hover:transform hover:-translate-y-1 hover:scale-105 transition duration-300 ease-in-out">
                                    <div class="flex flex-row items-center">
                                        <div class="flex-shrink pr-4">
                                            <img src={icon5} width={50} height={10} alt="logo" />
                                        </div>
                                        <div class="flex-1 text-right md:text-center">
                                            <h2 class="font-bold uppercase text-gray-600 text-sm">
                                                Gender Wise Strength Section Wise
                                            </h2>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>

                        <div class="w-full md:w-1/2 xl:w-1/3 p-6 hover:cursor-pointer ">
                            <Link to={`/admin/u/${customId}/GWCReport`}>
                                <div class="bg-gradient-to-b from-red-200 to-red-100 border-b-4 border-red-500 rounded-lg shadow-xl p-5 hover:transform hover:-translate-y-1 hover:scale-105 transition duration-300 ease-in-out">
                                    <div class="flex flex-row items-center">
                                        <div class="flex-shrink pr-4">
                                            <img src={icon6} width={50} height={10} alt="logo" />
                                        </div>
                                        <div class="flex-1 text-right md:text-center">
                                            <h2 class="font-bold uppercase text-gray-600 text-sm">
                                                Gender Wise Strength Class Wise
                                            </h2>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default StrengthAnalyses;
