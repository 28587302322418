import React from "react";
import { FcAbout } from "react-icons/fc";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import useSidebar from "../../hooks/useSidebar";
import { FaGraduationCap } from "react-icons/fa";
import { FaPhoneFlip } from "react-icons/fa6";
import { getTeacherFullDataFromLocal } from "../../API/getSubTeacherDetailFromLocal";
import { getUserDataFromLocalByKey } from "../../API/getStudentInfoByKeyLocal";
 
function CTProfile() {
  const { customId, keyName } = useParams();
  const [loading, setLoading] = useState(true);
  const [userDetails, setUserDetails] = useState(null);
  const [stName, setStName] = useState();
  const [stID, setStID] = useState();
  const [gender, setGender] = useState();
  const [stClass, setStClass] = useState();
  const [phnNo, setPhonNo] = useState();
  const [empCategory, setEmpCategory] = useState();
  const [designation, setDesignation] = useState();
  const [teachinType, setTeachingType] = useState();
  const [profilePic, setProfilePic] = useState();
  const [resourceUrl,setResourceUrl] = useState(null);
  const ItemKeyName = ["EMPCODE", "EmployeeName", "EMPLOYEECATEGORY","EMPCLASS","MOBILENO","EmployeeType","Gender","Designation","PHOTOFILE"];
  const { sidebarVisible, toggleSidebar } = useSidebar();

  useEffect(() => {
    const fetchData = async () => {
      const users = JSON.parse(localStorage.getItem("registeredUsers")) || [];

      if (customId) {
        const decodedData = JSON.parse(atob(customId));
        let matchedItem = [];

        console.log("DECODED DATA", decodedData);

        users.forEach((innerArray) => {
          const [userData] = innerArray;

          if (
            userData.userId === decodedData.userId &&
            userData.userType === decodedData.userType &&
            userData.schoolId === decodedData.schoolId &&
            userData.year === decodedData.year
          ) {
            matchedItem.push(userData);
          }
        });


        const key="WebResourceAWSDOTNETURL"
        const dataResourceUrl = await getUserDataFromLocalByKey(
         decodedData.userId,
         decodedData.schoolId,
         decodedData.userType,
         decodedData.year,
         key
       );
       setResourceUrl(dataResourceUrl)
        const result = await getTeacherFullDataFromLocal(
            decodedData.userId,
            decodedData.schoolId,
            decodedData.userType,
            decodedData.year
          );
  
          setUserDetails(result);
          console.log("USER DETAILS RESULT", result);



          const getInfoFields = () => {
            const infoFields = {};
            ItemKeyName.forEach(itemKeyName => {
              const item = result.find(item => item.ItemKeyName === itemKeyName);
              infoFields[itemKeyName] = item?.InfoField;
            });
            return infoFields;
          };
    
          // Get InfoFields based on ItemKeyNames
          const infoFields = getInfoFields();

          setProfilePic(infoFields.PHOTOFILE);
          setStName(infoFields.EmployeeName);
          setStID(infoFields.EMPCODE);
          setStClass(infoFields.EMPCLASS)
          setEmpCategory(infoFields.EMPLOYEECATEGORY);
          setPhonNo(infoFields.MOBILENO);
          setTeachingType(infoFields.EmployeeType);
          setGender(infoFields.Gender);
          setDesignation(infoFields.Designation);

      }
    };

    fetchData();
  }, [customId]);

  const values = {
    "Phone Number": phnNo,
    "Employee Category" : empCategory,
    "Designation" : designation,
    "Teaching Type" : teachinType,
  };

  const records = [
    { id: 1, text: "Phone Number", icon: <FaPhoneFlip className="h-10 w-10 " /> },
    { id: 2, text: "Employee Category", icon: <FaGraduationCap className="h-10 w-10 " /> },
    { id: 3, text: "Designation", icon: <FaGraduationCap className="h-10 w-10 " /> },
    { id: 4, text: "Teaching Type", icon: <FaGraduationCap className="h-10 w-10 " /> },
  ];
  return (
    <>
      <div className="max-w-7xl mx-auto p-4 relative">
        <main>
          <div className="flex justify-between items-center max-w-7xl">
            <p className="text-3xl font-bold ml-2 mb-2">My Profile</p>
          </div>

          <div className="m-2 flex flex-col justify-center relative h-64 shadow-md mx-auto bg-gradient-to-b from-sky-400 to-indigo-500 max-w-7xl p-4 rounded-2xl">
            {/* Profile Content */}

            <div className="flex justify-center  items-center rounded-full flex-col mt-8">
              <img
                className="w-24 h-24 rounded-full absolute top-6  border-4 border-white"
              
                src={`${resourceUrl}/PICPAY/01/${profilePic}`}
                alt="pic"
              />
              <div className="absolute text-center top-28">
                <h2 className=" mt-4 text-xl font-bold text-white py-2 px-4 rounded-md">{stName}</h2>
                <p className="font-semibold  text-white">{stID}</p>
              </div>
            </div>
          </div>
        </main>

        <div className="mt-8 flex absolute  w-full top-60 right-0 left-0 rounded-md  justify-around px-4 py-5 items-center max-w-xl mx-auto bg-white shadow-md">
          {/* Placeholder content */}
          <div className="Items flex flex-col items-center">
            <div className="text-gray-500">Class</div>
            <div className="font-bold">{stClass}</div>
          </div>
          <div className="Items flex flex-col items-center">
            <div className="text-gray-500">Gender</div>
            <div className="font-bold">{gender}</div>
          </div>
        </div>

        <div className="max-w-7xl h-96 overflow-y-auto mx-auto mt-12 rounded-md shadow-md  bg-white ">
          <div className="px-3 h-96 py-8 max-w-4xl mx-auto">
            <h2 className="font-semibold text-2xl">Information</h2>
            <div className="flex flex-col  ">
              {records.map((record) => (
                <div
                  key={record.id}
                  className="flex py-3 px-3 items-center mb-2"
                >
                  <div className="mx-2">
                    <div style={{ color: " #0EA5E9" }}>{record.icon}</div>
                  </div>
                  <div className="mx-2">
                    {record.text}
                    <div style={{ color: " #0EA5E9" }}>
                      {values[record.text]}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CTProfile;
