import React from "react";
import axios from "axios";
import toast from "react-toastify";
import { getUserDataFromLocalByKey } from "./getStudentInfoByKeyLocal";

export const getNoticeListDetail = async (userId, schoolId, userType, year) => {
  try {
    const key = "webServiceURLAWSDOTNET";
    // const classKey='CLNAME'
    // const sectionKey='SECNAME'

    const returnUrl = await getUserDataFromLocalByKey(
      userId,
      schoolId,
      userType,
      year,
      key
    );

   

    // const className=await getUserDataFromLocalByKey(userId, schoolId, userType, year, classKey)

    // const sectionName= await getUserDataFromLocalByKey(userId, schoolId, userType, year, classKey)

   

    const formData = new FormData();
    formData.append("title", "NoticeBoard");
    formData.append("description", "");
    formData.append("ReqClass", "");
    formData.append("ReqSection", "");
    formData.append("ReqYear", year);
    formData.append("ReqPara1", "");
    formData.append("ReqAdmNo", "");

    let userdata = [];

    const Corskey = process.env.REACT_APP_CORS_URL;

    const result = await axios.post(`${Corskey}${returnUrl}`, formData);

    userdata = JSON.parse(
      result.data.substring(0, result.data.indexOf("||JasonEnd", 0))
    );

    return userdata;
  } catch (error) {
    console.error("An error occurred:", error);
    // Handle error here
  }
  // Placeholder JSX, replace with actual JSX
};
