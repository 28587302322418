/* eslint-disable no-unused-vars */
import "datatables.net-buttons-dt/css/buttons.dataTables.css";
import "datatables.net-buttons-dt/css/buttons.dataTables.min.css";
import "datatables.net-buttons-dt/js/buttons.dataTables.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import "datatables.net-buttons/js/dataTables.buttons.js";
import DataTable from "datatables.net-dt";
import "datatables.net-dt/css/dataTables.dataTables.css";
import "datatables.net-responsive-dt";
import "datatables.net-scroller";
import "datatables.net-scroller-dt/css/scroller.dataTables.css";
import "datatables.net-scroller-dt/css/scroller.dataTables.min.css";
import "datatables.net-scroller-dt/js/scroller.dataTables.js";
import "datatables.net-scroller/js/dataTables.scroller.js";
import "datatables.net-select/js/dataTables.select.js";

import JSZip from "jszip";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

import React, { useCallback, useEffect, useRef, useState } from "react";
import { MdOutlineKeyboardArrowDown, MdOutlineKeyboardArrowUp } from "react-icons/md";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { GetClassSectionMasterLists } from "../../API/GetClassSectionMasterLists";

import Spinner from "../../components/Spinner";
import { getQuickInfoDetail } from "../../API/getQuickInfoDetail";

window.JSZip = JSZip;
pdfMake.vfs = pdfFonts.pdfMake?.vfs;

const columns = [
  { index: 0, title: "Select", data: "select", visible: true },
  { index: 1, title: "Group", data: "dbName", visible: true },
  { index: 2, title: "Active", data: "Active", visible: true },
  { index: 3, title: "S. Off", data: "NoOfStd", visible: true },
  { index: 4, title: "TC", data: "NoOfTC", visible: true },
  { index: 5, title: "Total", data: "Total", visible: true },
  { index: 6, title: "", data: "sd", visible: true },
];

const QuickInfoSchoolStrength = () => {
  const location = useLocation();
  const sumType = location.state?.sumType ?? ""; //ConcStd,ActStd,SOStd,TCSTd,NewStd,RTEStd,ConcStd

  const [loading, setLoading] = useState(false);
  const [quickInfoList, setQuickInfoList] = useState([]);
  const [classAndSub, setClassAndSub] = useState([]);
  const [classList, setClassList] = useState([]);
  const [classCatList, setClassCatList] = useState([]);
  const [sectionList, setSectionList] = useState(["A", "B", "C", "D"]);
  const [selectedClass, setSelectedClass] = useState("");
  const [selectedClassR1, setSelectedClassR1] = useState("");
  const [selectedClassR2, setSelectedClassR2] = useState("");
  const [selectedSection, setSelectedSection] = useState("");
  const [selectedClassCat, setSelectedClassCat] = useState("");
  const [activeNo, setActiveNo] = useState(null);
  const [stuckOff, setStuckOff] = useState(null);
  const [tcGiven, setTcGiven] = useState(null);
  const [totalNo, setTotalNo] = useState(null);
  const [isClosed, setIsClosed] = useState(false);
  const [spin, setSpin] = useState(false);
  const [dispType, setDispType] = useState("0");
  const [userDetails, setUserDetails] = useState(null);
  const [concessionList, setConcessionList] = useState([]);
  const [concessionId, setConcessionId] = useState("");

  const [tableCol, setTableCol] = useState(columns);
  const [tableData, setTableData] = useState([]);
  let tableRef = useRef(null);
  let tableInstance = useRef(null);

  const { customId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    // setLoading(true)
    const fetchData = async () => {
      setLoading(true);
      const users = JSON.parse(localStorage.getItem("registeredUsers")) || [];

      if (customId) {
        const decodedData = JSON.parse(atob(customId));
        let matchedItem = [];

        users.forEach((innerArray) => {
          const [userData] = innerArray;

          if (
            userData.userId === decodedData.userId &&
            userData.userType === decodedData.userType &&
            userData.schoolId === decodedData.schoolId &&
            userData.year === decodedData.year
          ) {
            matchedItem.push(userData);
          }
        });
        handleClassAndSectionFetchGlobal(decodedData.userId, decodedData.schoolId, decodedData.userType, decodedData.year);
        setUserDetails(matchedItem);

        setLoading(false);
      }
    };

    fetchData();
  }, [customId]);

  const fetchData = async () => {
    setLoading(true);
    if (userDetails) {
      const result = await getQuickInfoDetail(
        userDetails[0].year,
        selectedClass,
        selectedClassCat,
        selectedSection,
        dispType,
        selectedClassR1,
        selectedClassR2,
        sumType,
        concessionId
      );
      setQuickInfoList(result);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetails]);

  const handleClassAndSectionFetchGlobal = async (userId, schoolId, userType, year) => {
    setLoading(true);
    try {
      const respone = await GetClassSectionMasterLists(userId, schoolId, userType, year);
      const ClassList = respone.filter((item) => item.MasterType === "ClassMaster");
      const ClassCatList = respone.filter((item) => item.MasterType === "ClassCategory");
      const Concession = respone.filter((item) => item.MasterType === "Concession");

      setClassAndSub(ClassList);
      setClassCatList(ClassCatList.map((item) => item.Head1));
      setClassList(ClassList.map((item) => item.Head1));
      setConcessionList(Concession.map((item) => ({ name: item.Head1, id: item.Rcdid })));
      setLoading(false);
    } catch (error) {
      console.log(error);
      toast.error("Error");
      setLoading(false);
    }
  };

  const handleClassChange = (e) => {
    if (classAndSub.length > 0) {
      const userSelectedClass = classAndSub.filter((items) => items.Head1 === e.target.value);
      const sectionList = userSelectedClass.flatMap((item) => item.Head2.split(","));
      if (sectionList?.length > 0) {
        setSectionList(sectionList);
      } else {
        setSectionList(["A", "B", "C", "D"]);
      }
    }
    setSelectedClass(e.target.value);
  };

  const handleSectionChange = useCallback(
    (e) => {
      setSelectedSection(e.target.value);
    },
    [setSelectedSection]
  );

  const handleClassCatChange = useCallback(
    (e) => {
      setSelectedSection(e.target.value);
    },
    [setSelectedSection]
  );

  const calculateSums = useCallback(() => {
    let activeStd = 0;
    let stuckOff = 0;
    let TcGivenN = 0;
    let Tota = 0;
    quickInfoList.forEach((data) => {
      activeStd += data?.NofStdA;
      stuckOff += data?.NofStdS;
      TcGivenN += data?.NofStdT;
      Tota += data?.NofStd;
    });

    setActiveNo(activeStd);
    setStuckOff(stuckOff);
    setTcGiven(TcGivenN);
    setTotalNo(Tota);
  }, [quickInfoList]);

  useEffect(() => {
    calculateSums();
  }, [calculateSums]);

  const TableData = quickInfoList.map((data) => ({
    Location: data.Location,
    Logourl: data.Logourl,
    Total: data.NofStd,
    Active: data.NofStdA,
    NoOfStd: data.NofStdS,
    NoOfTC: data.NofStdT,
    SlCODE: data.SLCODE,
    SlNo: data.Slno,
    dbName: data.dbname,
  }));

  window.handleShowDetails = (logourl) => {
    // a function on datatable
    const sectionOfClass = classAndSub.filter((items) => items.Head1 === logourl);
    const sectionList = sectionOfClass.flatMap((item) => item.Head2.split(","));
    navigate(`/admin/u/${customId}/QuickInfo/students_list`, {
      state: {
        selectedClass: logourl,
        sectionList: sectionList,
      },
    });
  };

  const TableData2 = quickInfoList.map((data) => ({
    Location: data.Location,
    select: `<input type="radio" className="radioButton" name="group1" />`,
    Logourl: data.Logourl,
    Total: data.NofStd,
    Active: data.NofStdA,
    NoOfStd: data.NofStdS,
    NoOfTC: data.NofStdT,
    SlCODE: data.SLCODE,
    SlNo: data.Slno,
    sd: `<p
            onClick="handleShowDetails('${data.Logourl}')"
            class='text-blue-500 cursor-pointer text-sm font-medium capitalize'
          >
            Show Details
          </p>`,
    dbName: data.dbname,
  }));

  useEffect(() => {
    // Initialize DataTable when the component mounts
    if (tableCol && TableData2) {
      tableInstance.current = new DataTable("#studentDetails", {
        responsive: false,
        // dom: "Bfrtip",
        layout: {
          topStart: {
            buttons: [
              "excel",
              "csv",
              "xls",
              "pdf",
              {
                extend: "print",
                exportOptions: {
                  columns: ":visible",
                },
              },
              "colvis",
            ],
          },
          bottomEnd: {
            paging: {
              type: "simple_numbers",
            },
          },
        },
        pageLength: 10,
        lengthMenu: [10, 25, 50],
        select: true,
        info: "",
        scrollY: 500,
        scrollX: true,
        deferRender: true,
        scroller: true,
        data: TableData2,
        columns: tableCol,
      });
    }
    // Cleanup DataTable instance when the component unmounts
    return () => {
      if (tableInstance.current !== null) {
        tableInstance.current.destroy();
      }
    };
  }, [TableData2, tableCol]);

  const ClassDetailTypeData20 = [
    {
      index: 2,
      label: "Active Type",
      SelectOption: ["All", "Active", "Stuck Off", "TC Given"],
    },
    {
      index: 3,
      label: "Report",
      SelectOption: ["Class/Section", "Class Cat./Class/Selection", "Class/Section/Gender"],
    },
  ];

  const handleCloseClick = () => {
    setTimeout(() => setIsClosed(!isClosed), 250);
  };

  return (
    <>
      <div className="lg:mt-0 mt-5">
        <div className="container mx-auto p-4 containerBoxShadow ">
          <>
            {loading ? (
              <Spinner />
            ) : (
              <>
                <div className="flex justify-start w-full rounded-md bg-gradient-to-r from-cyan-400 to-blue-400 hover:bg-gradient-to-bl">
                  <h2 className="w-full text-center text-2xl p-0 my-auto uppercase text-black font-mono font-semibold">School Strength</h2>
                  <button onClick={handleCloseClick} className={`w-fit rounded-md p-2 `}>
                    <MdOutlineKeyboardArrowUp
                      className={`duration-500 ease-ease-in-out text-black transition-all ${isClosed ? "rotate-180" : ""} `}
                      size={25}
                    />
                  </button>
                </div>
                <div className={`transition-all duration-700 ease-ease-in ${!isClosed ? "opacity-100 max-h-screen" : "opacity-0 max-h-0"}`}>
                  <div className="flex flex-col gap-3 px-0 rounded pt-3">
                    <div className="flex py-2 formBackground justify-between md:flex-row flex-col md:px-10 px-3 sm:px-5 md:gap-10 gap-0 items-end">
                      <div className="w-full md:w-1/2 flex justify-between flex-col">
                        <div className=" flex flex-row items-center gap-2 mb-1 justify-start">
                          <label
                            className="block uppercase basis-[35%] tracking-wide text-start text-grey-darker text-xs font-bold mb-2"
                            htmlFor="grid-state"
                          >
                            Class
                          </label>
                          <div className="relative flex flex-row ">
                            <select
                              onChange={handleClassChange}
                              value={selectedClass}
                              className="textInput min-w-20 w-[150px] md:w-[190px]"
                            >
                              <option value="">--select--</option>
                              {classList?.map((data, i) => (
                                <option key={i} value={data}>
                                  {data}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>

                        <div className=" flex flex-row items-center gap-2 mb-1 justify-start">
                          <label
                            className="block uppercase basis-[35%] tracking-wide text-start text-grey-darker text-xs font-bold mb-2"
                            htmlFor="grid-state"
                          >
                            Section
                          </label>
                          <div className="relative flex flex-row ">
                            <select
                              onChange={handleSectionChange}
                              value={selectedSection}
                              className="textInput min-w-20 w-[150px] md:w-[190px]"
                            >
                              <option value="">--select--</option>
                              {sectionList?.map((data, i) => (
                                <option key={i} value={data}>
                                  {data}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>

                        <div className=" flex flex-row items-center gap-2 mb-1 justify-start">
                          <label
                            className="block uppercase basis-[35%] tracking-wide text-start text-grey-darker text-xs font-bold mb-2"
                            htmlFor="grid-state"
                          >
                            Class Category
                          </label>
                          <div className="relative flex flex-row ">
                            <select
                              onChange={handleClassCatChange}
                              value={selectedClassCat}
                              className="textInput min-w-20 w-[150px] md:w-[190px]"
                            >
                              <option value="">--select--</option>
                              {classCatList?.map((data, i) => (
                                <option key={i} value={data}>
                                  {data}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>

                      <div className="w-full md:w-1/2 flex justify-end md:gap-3 gap-1 h-full flex-col">
                        <div className="w-full relative flex justify-between items-start md:items-end lg:flex-row md:flex-col sm:flex-row flex-col gap-2 border-2 pt-3 pb-2 px-2 border-black shadow rounded-md">
                          <h2 className="text-sm uppercase absolute font-semibold z-20 !border-0 formBackground !shadow-sm !px-1 !h-fit !py-0.5 !rounded whitespace-nowrap top-0 -mt-1.5">
                            Class Range
                          </h2>
                          <div className=" flex flex-row items-center gap-2 mb-1 md:mt-0 mt-2 justify-start w-full">
                            <label
                              className="block uppercase basis-[35%] tracking-wide text-start text-grey-darker text-xs font-bold mb-2"
                              htmlFor="grid-state"
                            >
                              From
                            </label>
                            <div className="relative flex flex-row  w-full">
                              <select
                                value={selectedClassR1}
                                onChange={(e) => setSelectedClassR1(e.target.value)}
                                className="textInput min-w-20 w-[150px] md:w-[190px]"
                              >
                                <option value="">--select--</option>
                                {classList?.map((data, i) => (
                                  <option key={i}>{data}</option>
                                ))}
                              </select>
                            </div>
                          </div>

                          <div className=" flex flex-row items-center gap-2 mb-1 justify-start w-full">
                            <label
                              className="block uppercase basis-[35%] tracking-wide text-start text-grey-darker text-xs font-bold mb-2"
                              htmlFor="grid-state"
                            >
                              Upto
                            </label>
                            <div className="relative flex flex-row  w-full">
                              <select
                                value={selectedClassR2}
                                onChange={(e) => setSelectedClassR2(e.target.value)}
                                className="textInput min-w-20 w-[150px] md:w-[190px]"
                              >
                                <option value="">--select--</option>
                                {classList?.map((data, i) => (
                                  <option key={i}>{data}</option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>

                        <div>
                          <div className=" flex flex-row items-center gap-2 mb-1 justify-start">
                            <label
                              className="block uppercase basis-[35%] tracking-wide text-start text-grey-darker text-xs font-bold mb-2 md:mb-0"
                              htmlFor="grid-state"
                            >
                              DISPLAY TYPE
                            </label>
                            <div className="relative flex flex-row ">
                              <select
                                value={dispType}
                                onChange={(e) => setDispType(e.target.value)}
                                className="textInput min-w-20 w-[150px] md:w-[190px]"
                              >
                                <option value="0">Class Wise</option>
                                <option value="1">Class/Section Wise</option>
                                <option value="2">Class Category Wise</option>
                              </select>
                            </div>
                          </div>
                          {sumType === "ConcStd" && (
                            <div className=" flex flex-row w-full items-center gap-2 justify-start">
                              <label
                                className="block uppercase basis-[35%] tracking-wide text-start text-grey-darker text-xs font-bold mb-2 md:mb-0"
                                htmlFor="grid-state"
                              >
                                Concession
                              </label>
                              <div className="relative flex flex-row ">
                                <select
                                  value={concessionId}
                                  onChange={(e) => {
                                    setConcessionId(e.target.value);
                                  }}
                                  className="textInput min-w-20 w-[150px] md:w-[250px]"
                                >
                                  <option value="">--select--</option>
                                  {concessionList?.map((item, ind) => (
                                    <option key={ind} value={item.id}>
                                      {item.name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="flex  justify-between gap-1 flex-row w-full md:w-fit md:flex-col h-full items-start ">
                        <button onClick={fetchData} className="buttonClass">
                          Refresh
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="formBackground my-2 p-2 uppercase flex justify-start md:flex-row flex-col md:gap-5 gap-2 tracking-wide text-center text-grey-darker text-xs font-bold mb-2 gap-y-0 md:gap-y-3 relative">
                    <h2 className="text-sm uppercase absolute font-semibold z-20 !border-0 formBackground !shadow-sm !px-1 !h-fit !py-0.5 !rounded whitespace-nowrap top-0 -mt-2.5">
                      Print
                    </h2>
                    <div className="w-full flex justify-between md:mt-0 mt-2 md:flex-row gap-1 md:gap-2 flex-col md:basis-[80%]">
                      {ClassDetailTypeData20?.map((item) => {
                        return (
                          <div key={item?.index} className=" flex flex-row w-full items-center gap-2 justify-start">
                            <label
                              className="block uppercase basis-[35%] tracking-wide text-start text-grey-darker text-xs font-bold"
                              htmlFor="grid-state"
                            >
                              {item?.label}
                            </label>
                            <div className="relative flex flex-row ">
                              <select className="textInput min-w-20 w-[150px] md:w-[190px]">
                                {item?.SelectOption?.map((data, i) => (
                                  <option value={i} key={i}>
                                    {data}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    <div className="flex w-fit h-full items-start ">
                      <button className="buttonClass">Print</button>
                    </div>
                  </div>
                </div>

                <div className="formBackground shapedividers my-2 py-3 px-2 uppercase grid md:grid-cols-4 sm:grid-cols-3 grid-cols-2 justify-evenly tracking-wide text-center text-grey-darker text-xs font-bold mb-2 gap-y-3">
                  <div className="flex justify-center items-center gap-4">
                    <h2>Active:</h2>
                    <h2>{activeNo}</h2>
                  </div>
                  <div className="flex justify-center items-center gap-4">
                    <h2>Stuck Off:</h2>
                    <h2>{stuckOff}</h2>
                  </div>
                  <div className="flex justify-center items-center gap-4">
                    <h2>TC Given:</h2>
                    <h2>{tcGiven}</h2>
                  </div>
                  <div className="flex justify-center items-center gap-4">
                    <h2>Total:</h2>
                    <h2>{totalNo}</h2>
                  </div>
                </div>

                <div className="p-1 md:p-2 w-full shadow-lg">
                  
                  <div className="md:hidden block">
                    <div className="max-w-xl mx-auto bg-white shadow-md p-2 rounded-lg">
                      <h2 className="text-lg cardHeadingClass mb-4 w-full text-center">Students Strength</h2>
                      <div className="overflow-x-auto">
                        {TableData.map((item, index) => (
                          <div
                            className="border-2 bg-gradient-to-b from-sky-200 to-cyan-400 rounded-lg mb-4 shadow-lg overflow-hidden"
                            key={index}
                          >
                            <div className="flex items-center  justify-between tabButton py-2 px-4 rounded-t-lg shadow-md">
                              <input className="radioButton" type="radio" name="employeesRadio" id={`employeesRadio-${index}`} />
                              <span className="font-bold text-center w-full"> {item?.dbName}</span>
                            </div>
                            <table className="w-full table-auto">
                              <tbody>
                                <tr>
                                  <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                                    <strong>Active</strong>
                                  </td>
                                  <td className="border border-solid border-gray-400 text-start p-2 w-1/2">{item?.Active}</td>
                                </tr>
                                <tr>
                                  <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                                    <strong>S. Off</strong>
                                  </td>
                                  <td className="border border-solid border-gray-400 text-start p-2 w-1/2">{item?.NoOfStd}</td>
                                </tr>
                                <tr>
                                  <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                                    <strong>TC</strong>
                                  </td>
                                  <td className="border border-solid border-gray-400 text-start p-2 w-1/2">{item?.NoOfTC}</td>
                                </tr>
                                <tr>
                                  <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                                    <strong>Total</strong>
                                  </td>
                                  <td className="border border-solid border-gray-400 text-start capitalize p-2 w-1/2">{item?.Total}</td>
                                </tr>
                                <tr>
                                  <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                                    <strong></strong>
                                  </td>
                                  <td className="border border-solid border-gray-400 text-start capitalize p-2 w-1/2">
                                    <p
                                      onClick={() => {
                                        const sectionOfClass = classAndSub.filter((items) => items.Head1 === item?.Logourl);
                                        const sectionList = sectionOfClass.flatMap((item) => item.Head2.split(","));
                                        navigate(`/admin/u/${customId}/QuickInfo/students_list`, {
                                          state: {
                                            selectedClass: item?.Logourl,
                                            sectionList: sectionList,
                                          },
                                        });
                                      }}
                                      className="text-white tracking-wider font-medium cursor-pointer text-sm capitalize"
                                    >
                                      Show Details
                                    </p>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="relative max-h-full overflow-x-auto border md:block hidden border-black border-solid rounded-sm">
                    {quickInfoList && quickInfoList?.length > 0 ? (
                      <table
                        ref={tableRef}
                        id="studentDetails"
                        style={{ width: "100%" }}
                        className="h-fit w-fit max-h-[500px] responsive extraCSS display nowrap"
                      ></table>
                    ) : (
                      <div className="h-auto p-5">
                        <h1 className="text-2xl font-bold">Record Not Found</h1>
                      </div>
                    )}
                  </div>
                </div>
              </>
            )}
          </>
        </div>
      </div>
    </>
  );
};

export default QuickInfoSchoolStrength;
