import axios from "axios";

export const GetStaffListERPQI = async (groupType, seltype, selaccCat, seldept, seldesgn, disp) => {
  try {
    const returnUrl = process.env.REACT_APP_ERP_QUICKINFO_PAGE;

    const formData = new FormData();
    formData.append("title", "GetStaffListERPQI");
    formData.append("description", "Checking");
    formData.append("Reqdisptype", disp);
    formData.append("ReqEmpType", seltype);
    formData.append("ReqEmpDept", seldept);
    formData.append("ReqEmpAccCat", selaccCat);
    formData.append("ReqDesig", seldesgn);
    formData.append("ReqEmpCategory", groupType);

    let userdata = [];
    const Corskey = process.env.REACT_APP_CORS_URL;
    const result = await axios.post(`${Corskey}${returnUrl}`, formData);
    userdata = JSON.parse(result.data.substring(0, result.data.indexOf("||JasonEnd", 0)));
    return userdata;
  } catch (error) {
    console.error("An error occurred:", error);
  }
};
