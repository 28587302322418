import React, { useState, useRef, useEffect } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { AiOutlineClose } from "react-icons/ai";
import { BsFillClipboard2XFill } from "react-icons/bs";
import { useGlobalContext } from "../../context/GlobalContext";
import Webcam from "react-webcam";
import { FaCamera } from "react-icons/fa";
import { RiGalleryFill, RiComputerLine } from "react-icons/ri";
import { MdPictureAsPdf, MdVideoLibrary } from "react-icons/md";
import { IoCloseCircleOutline } from "react-icons/io5";
import { updateCTNotificationAPI } from "../../API/updateCTNotificationAPI";
import { getUserDataFromLocalByKey } from "../../API/getStudentInfoByKeyLocal";
import Navbar from "../../components/Navbar";
import Sidebar from "../../components/Sidebar";
import { useSidebar } from "../../context/SidebarContext";
// import useSidebar from "../../hooks/useSidebar";
import Spinner from "../../components/Spinner";

const generateTabContent = (tabs) => (
  <div className="border border-gray-300  rounded-lg p-4 hover:shadow-md transition duration-300 mt-4">
    <div className="flex justify-around items-center">
      {tabs.map((tab, index) => (
        <div
          key={index}
          className="flex flex-col cursor-pointer items-center mx-2"
          onClick={() => tab.onClick()}
        >
          <div className="bg-gray-300 rounded-full p-3">{tab.icon}</div>
          <p className="mt-1 text-center">{tab.label}</p>
        </div>
      ))}
    </div>
  </div>
);

function WebcamModal({ onClose, onCaptureClick }) {
  const videoConstraints = {
    facingMode: "user",
  };

  const webcamRef = React.useRef(null);

  const dataURLtoFile = (dataurl, filename) => {
    const arr = dataurl.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  };

  const handleCaptureClick = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    const capturedFile = dataURLtoFile(imageSrc, "capturedImage.png");
    onCaptureClick(capturedFile);
    onClose();
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center mt-10 bg-black bg-opacity-60">
      <div className="bg-white customPopupHeight relative rounded-lg mt-8 shadow-lg p-8 md:w-1/2 w-full overflow-y-auto">
        <div className="w-1/3 sm:w-auto mr-4">
          <AiOutlineClose
            className="absolute top-4 right-4 cursor-pointer"
            onClick={onClose}
          />

          {/* Content of the YouTube Link modal */}
          {/* Add your YouTube link input field and submit button here */}
          <Webcam
            audio={false}
            ref={webcamRef}
            screenshotFormat="image/png"
            videoConstraints={videoConstraints}
          />
          <button
            onClick={handleCaptureClick}
            className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded mt-4"
          >
            Take Snapshot
          </button>
        </div>
      </div>
    </div>
  );
}
function CTNotificationSubmitPage() {
  const { customId } = useParams();
  const [userDetails, setUserDetails] = useState(null);
  const [ctName, setCtName] = useState("");
  const [data, setData] = useState(true);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [webcamActive, setWebcamActive] = useState(false);
  const [remarkMessage, setRemarkMessage] = useState("");
  const [pdfFiles, setPdfFiles] = useState([]);
  const [imageFiles, setImageFiles] = useState([]);
  const [videoFiles, setVideoFiles] = useState([]);
  const [subjectName, setSubjectName] = useState("");
  const [isYoutubeLinkModalOpen, setIsYoutubeLinkModalOpen] = useState(false);
  const [youtubeLink, setYoutubeLink] = useState("");
  const { globalVariable } = useGlobalContext();
  const [loading, setLoading] = useState(false);
  const [isWebcamModalOpen, setWebcamModalOpen] = useState(false);
  // const { sidebarVisible, toggleSidebar } = useSidebar();
  const { isSidebarOpen, currentSidebarTab, toggleSidebar,setIsSidebarOpen } = useSidebar();

  useEffect(() => {
    const fetchData = async () => {
      if (customId) {
        const decodedData = JSON.parse(atob(customId));
        setUserDetails(decodedData);

        const namekeyval = "EmployeeName";
        const username = await getUserDataFromLocalByKey(
          decodedData.userId,
          decodedData.schoolId,
          decodedData.userType,
          decodedData.year,
          namekeyval
        );

        const modifiedUsername = username.replace(/(?:MS|MR)\s*/g, "");

        setCtName(modifiedUsername);
      }
    };
    fetchData();
  }, [customId]);

  const handleCameraClick = () => {
    setWebcamModalOpen(true);
  };

  const closeWebcamModal = () => {
    setWebcamModalOpen(false);
  };

  const handleCaptureClick = (capturedFile) => {
    // Update selectedFiles array
    setImageFiles([...imageFiles, capturedFile]);

    // Update selectedFiles array
    setSelectedFiles([...selectedFiles, capturedFile]);
  };

  const handleGalleryClick = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/*, video/*";
    input.multiple = true;
    input.click();

    input.addEventListener("change", (event) => {
      const files = event.target.files;
      const imageFiles = [];
      const videoFiles = [];

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        if (file.type.startsWith("image/")) {
          imageFiles.push(file);
        } else if (file.type.startsWith("video/")) {
          videoFiles.push(file);
        }
      }

      // Use the imageFiles and videoFiles arrays as needed
      setImageFiles([...imageFiles]);
      setVideoFiles([...videoFiles]);

      // Optionally, update the general selectedFiles array
      setSelectedFiles([...files]);
    });
  };

  const handlePdfClick = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = ".pdf";
    input.multiple = true; // Allow multiple file selection
    input.click();

    input.addEventListener("change", (event) =>
      handlePdfFileSelection(event.target.files)
    );
  };

  const handlePdfFileSelection = (files) => {
    const pdfFilesArray = Array.from(files).filter(
      (file) => file.type === "application/pdf"
    );
    setPdfFiles([...pdfFiles, ...pdfFilesArray]);

    // Optionally, you can also update the general selectedFiles array
    setSelectedFiles([...selectedFiles, ...pdfFilesArray]);
  };
  const handleVideoClick = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "video/*"; // Specify the allowed video formats
    input.multiple = true;
    input.click();

    input.addEventListener("change", (event) => {
      const videoFilesArray = Array.from(event.target.files);
      setVideoFiles([...videoFiles, ...videoFilesArray]);

      // Optionally, update the general selectedFiles array
      setSelectedFiles([...selectedFiles, ...videoFilesArray]);
    });
  };

  const handleYoutubeLink = () => {
    setIsYoutubeLinkModalOpen(true);
  };

  const closeYoutubeLinkModal = () => {
    setIsYoutubeLinkModalOpen(false);
  };

  const clearSelectedFiles = () => {
    setSelectedFiles([]);
  };

  // Call this function where needed, for example, in handleResetAttachmentClick
  const handleResetAttachmentClick = () => {
    clearSelectedFiles();
    setPdfFiles([]);
    setImageFiles([]);
    setVideoFiles([]);
    setWebcamActive(false);
    // Your other logic here
  };

  const handleSubmit = async () => {

    console.log(youtubeLink)


    if (selectedFiles.length === 0 && youtubeLink === "") {
      toast.error("There are no attachments");
      return; // Stop execution if no files are selected
    }

    // Check if there is a subject name
    if (subjectName === "") {
      toast.warning("Please enter the subject");
      return; // Stop execution if subject name is not provided
    }

    // Check if there is a remark message
    if (remarkMessage === "") {
      toast.warning("Please enter the remark");
      return; // Stop execution if remark message is not provided
    }
    setLoading(true);

    if (selectedFiles.length > 0) {
      if (imageFiles.length > 0) {
        
        for (let i = 0; i < imageFiles.length; i++) {
          const file = imageFiles[i];
          const reader = new FileReader();

          const fileName = file.name;

          const newFileName = fileName.replace(/\s/g, "");

          console.log("File Name with out spaces", newFileName);

          reader.onload = async (event) => {
            const fileBytes = event.target.result;
            const base64String = arrayBufferToBase64(fileBytes);

            const contentType = "Image";
            const result = await updateCTNotificationAPI(
              userDetails.userId,
              userDetails.schoolId,
              userDetails.userType,
              userDetails.year,
              newFileName,
              base64String,
              contentType,
              subjectName,
              remarkMessage
            );
            setImageFiles([])
            setLoading(false);
            setRemarkMessage("");
            setSubjectName("");
            toast.success(result[0]?.InfoField)       
            console.log("Result after creating notice in image:", result);
          };

          reader.readAsArrayBuffer(file);
        }
      } else if (pdfFiles.length > 0) {
        for (let i = 0; i < pdfFiles.length; i++) {
          const file = pdfFiles[i];
          const reader = new FileReader();

          const fileName = file.name;

          const newFileName = fileName.replace(/\s/g, "");

          console.log("File Name with out spaces", newFileName);

          reader.onload = async (event) => {
            const fileBytes = event.target.result;
            const base64String = arrayBufferToBase64(fileBytes);

            const contentType = "PDF";
            const result = await updateCTNotificationAPI(
              userDetails.userId,
              userDetails.schoolId,
              userDetails.userType,
              userDetails.year,
              newFileName,
              base64String,
              contentType,
              subjectName,
              remarkMessage
            );
            setLoading(false);
            setRemarkMessage("");
            setSubjectName("");
            toast.success(result[0]?.InfoField)       
            console.log("Result after creating notice in PDF:", result);
          };

          reader.readAsArrayBuffer(file);
        }
      } else {
        for (let i = 0; i < videoFiles.length; i++) {
          const file = videoFiles[i];
          const reader = new FileReader();

          const fileName = file.name;

          const newFileName = fileName.replace(/\s/g, "");

          console.log("File Name with out spaces", newFileName);

          reader.onload = async (event) => {
            const fileBytes = event.target.result;
            const base64String = arrayBufferToBase64(fileBytes);

            const contentType = "Video";
            const result = await updateCTNotificationAPI(
              userDetails.userId,
              userDetails.schoolId,
              userDetails.userType,
              userDetails.year,
              newFileName,
              base64String,
              contentType,
              subjectName,
              remarkMessage
            );
            setLoading(false);
            setRemarkMessage("");
            setSubjectName("");
            toast.success(result[0]?.InfoField)            
            console.log("Result after creating notice in video:", result);
          };

          reader.readAsArrayBuffer(file);
        }
      }
    } else {
      const base64String = "";
      const contentType = "Youtube Link";
      const result = await updateCTNotificationAPI(
        userDetails.userId,
        userDetails.schoolId,
        userDetails.userType,
        userDetails.year,
        youtubeLink,
        base64String,
        contentType,
        subjectName,
        remarkMessage
      );
      setLoading(false);
      setRemarkMessage("");
      setSubjectName("");
      toast.success(result[0]?.InfoField)       
      console.log("Result after creating notice in Youtube Link:", result);
    }
    setSelectedFiles([])
  
  };

  const handleReset = () => {
    // Logic to reset the form
    setRemarkMessage("");
    setSubjectName("");
  };

  const arrayBufferToBase64 = (buffer) => {
    let binary = "";
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  };

  const handleYoutubeChange = (e) => {
    setIsYoutubeLinkModalOpen(false)
    toast.success("Youtube Link Added")
  };

  return (
    <>
      {data && (
        <Navbar
          data={userDetails}
          // isOpen={sidebarVisible}
          toggleSidebar={toggleSidebar}
        />
      )}
      {loading ? (
        <Spinner />
      ) : (
        <>
          <div className="flex">
            {data && <Sidebar data={userDetails} />}

            <div className={`w-full absolute ${isSidebarOpen?"lg:w-4/6 xl:w-9/12":"w-full"} sm:w-11/12 right-0 top-16 mx-auto   p-4 md:p-6 lg:p-8 xl:p-10`}>
              <div className="flex items-center justify-between mb-4">
                <div className="w-2/3">
                  <h3 className="text-md font-semibold mb-2">
                    Teacher Name: {ctName}
                  </h3>
                  <h5 className="mb-2"></h5>
                </div>
              </div>

              {generateTabContent([
                {
                  icon: (
                    <FaCamera
                      style={{ color: "#0EA5E9" }}
                      className="text-2xl"
                    />
                  ),
                  label: "Camera",
                  onClick: handleCameraClick,
                },
                {
                  icon: (
                    <RiGalleryFill
                      style={{ color: "#0EA5E9" }}
                      className="text-2xl"
                    />
                  ),
                  label: "Gallery",
                  onClick: handleGalleryClick,
                },
                {
                  icon: (
                    <MdPictureAsPdf
                      style={{ color: "#0EA5E9" }}
                      className="text-2xl"
                    />
                  ),
                  label: "PDF",
                  onClick: handlePdfClick,
                },
              ])}
              {generateTabContent([
                {
                  icon: (
                    <MdVideoLibrary
                      style={{ color: "#0EA5E9" }}
                      className="text-2xl"
                    />
                  ),
                  label: "Video",
                  onClick: handleVideoClick,
                },
                {
                  icon: (
                    <RiComputerLine
                      style={{ color: "#0EA5E9" }}
                      className="text-2xl"
                    />
                  ),
                  label: "You Tube Link",
                  onClick: handleYoutubeLink,
                },
                {
                  icon: (
                    <IoCloseCircleOutline
                      style={{ color: "#0EA5E9" }}
                      className="text-2xl"
                    />
                  ),
                  label: "Reset Attachment",
                  onClick: handleResetAttachmentClick,
                },
              ])}
              {isWebcamModalOpen && (
                <WebcamModal
                  onClose={closeWebcamModal}
                  onCaptureClick={handleCaptureClick}
                />
              )}
              <div className="flex flex-wrap">
                {selectedFiles.map((file, index) => (
                  <div key={index} className="m-2">
                    {file.name}
                  </div>
                ))}
              </div>
              {/* Input field */}
              <div className="mb-4">
                <label className="block text-gray-700 font-semibold mb-2">
                  Enter Subject here
                </label>
                <textarea
                  value={subjectName}
                  onChange={(e) => setSubjectName(e.target.value)}
                  placeholder="Enter subject here..."
                  className="border border-gray-300 rounded px-3 py-2 w-full focus:outline-none"
                  rows="1"
                ></textarea>
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 font-semibold mb-2">
                  Remark Message
                </label>
                <textarea
                  value={remarkMessage}
                  onChange={(e) => setRemarkMessage(e.target.value)}
                  placeholder="Enter Remark Message here..."
                  className="border border-gray-300 rounded px-3 py-2 w-full focus:outline-none"
                  rows="5"
                ></textarea>
              </div>
              {/* Submit button */}
              <div className="flex justify-center">
                <button
                  onClick={handleSubmit}
                  className="bg-green-500 text-white font-semibold px-4 py-2 rounded shadow-md mr-4 focus:outline-none"
                >
                  Submit
                </button>
                <button
                  onClick={handleReset}
                  className="bg-red-500 text-white font-semibold px-4 py-2 rounded shadow-md focus:outline-none"
                >
                  Reset
                </button>
              </div>
            </div>
          </div>

          {isYoutubeLinkModalOpen && (
            <div className="fixed inset-0 flex items-center justify-center mt-10 bg-black bg-opacity-60">
              <div className="bg-white customPopupHeight relative rounded-lg mt-8 shadow-lg p-8 md:w-1/2 w-full overflow-y-auto">
                <div className="w-1/3 sm:w-auto mr-4">
                  <AiOutlineClose
                    className="absolute top-4 right-4 cursor-pointer"
                    onClick={closeYoutubeLinkModal}
                  />

                  {/* Content of the YouTube Link modal */}
                  {/* Add your YouTube link input field and submit button here */}
                  <div className="mt-4 flex justify-center relative">
                    <div className="inline-flex w-3/4">
                      <span
                        style={{ marginLeft: "5px" }}
                        className="absolute top-1/2 transform -translate-y-1/2"
                      >
                        <BsFillClipboard2XFill className="w-4 h-4 text-gray-500 focus:text-blue-500" />
                      </span>
                      <input
                        value={youtubeLink}
                        type="text"
                        onChange={(e) => setYoutubeLink(e.target.value)}
                        placeholder="Enter YouTube Link"
                        className="pl-8 pr-2 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500 w-full"
                      />
                    </div>
                  </div>

                  {/* Submit button */}
                  <div className="mt-8 flex justify-center">
                    <button
                      onClick={handleYoutubeChange}
                      className="bg-green-500 text-white font-semibold px-4 py-2 rounded shadow-md mr-4 focus:outline-none"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
}

export default CTNotificationSubmitPage;
