import React, { useState } from "react";
import Modal from "react-modal";
import sample from "../../assets/png/avatar.png";

Modal.setAppElement("#root");

const VerificationModal = ({
  isOpen,
  onRequestClose,
  file,
  onSubmit,
  resourceUrl,
}) => {
  const [remarks, setRemarks] = useState("");
  const [marks, setMarks] = useState("");
  const [grade,setGrade]=useState("");

  const handleSubmit = () => {
    const stdhwid = file?.STDHWID || null;
    onSubmit({ remarks, marks,stdhwid,grade });
    
  };

  const checkContentType = () => {
    if (file?.HWSTDFILELOCATION.includes("v=")) {
      return (
        <iframe
          title="Notification Content"
          src={`https://www.youtube.com/embed/${
            file.HWSTDFILELOCATION.split("v=")[1].split("&")[0]
          }`}
          className="w-full h-64 border-none"
          allowFullScreen
        ></iframe>
      );
    }
    if (file?.HWSTDFILELOCATION === "") {
      return null;
    } else {
      return (
        <div className="w-full overflow-auto border rounded">
          <iframe
            src={`${resourceUrl}/StudentHomework/${file?.HWSTDFILELOCATION}`}
            alt="Submission"
            className="w-full h-96"
          ></iframe>
        </div>
      );
    }
  };

  return (
    <Modal
    isOpen={isOpen}
    onRequestClose={onRequestClose}
    contentLabel="Verification Modal"
    className="absolute top-16 left-1/2 transform -translate-x-1/2 bg-white p-6 rounded-lg shadow-lg w-full max-w-3xl max-h-[80vh] mx-auto my-8 overflow-auto"
    overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
  >
    <div className="relative">
      <button
        onClick={onRequestClose}
        className="absolute top-0 right-0 m-2 text-gray-600 hover:text-gray-800 transition-colors"
        aria-label="Close modal"
      >
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
        </svg>
      </button>
      <h2 className="text-lg font-bold mb-4 pr-8 text-center ">Verify Submission</h2>
      <div className="mb-4">{checkContentType()}</div>
      <p className="flex items-center text-sm font-medium text-gray-700 mb-4">
        Student's Answer:
        <span className="ml-2 font-semibold text-xl">{file?.HWSTDREMARKS}</span>
      </p>
      
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Remarks
        </label>
        <textarea
          value={remarks}
          onChange={(e) => setRemarks(e.target.value)}
          className="w-full p-2 border border-gray-300 rounded"
          rows="3"
        ></textarea>
      </div>
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Marks
        </label>
        <input
          type="number"
          value={marks}
          onChange={(e) => setMarks(e.target.value)}
          className="w-full p-2 border border-gray-300 rounded"
        />
      </div>
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Grade
        </label>
        <input
          type="text"
          value={grade}
          onChange={(e) => setGrade(e.target.value)}
          className="w-full p-2 border border-gray-300 rounded uppercase"
        />
      </div>
      <div className="flex justify-end">
        <button
          onClick={handleSubmit}
          className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-800 transition-colors"
        >
          Submit
        </button>
        <button
          onClick={onRequestClose}
          className="ml-2 px-4 py-2 bg-gray-600 text-white rounded hover:bg-gray-800 transition-colors"
        >
          Cancel
        </button>
      </div>
    </div>
  </Modal>
  );
};

export default VerificationModal;
