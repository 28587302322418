import React, { useRef, useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCoverflow, Parallax, Autoplay } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/parallax';

const FocusedMovieSlider = ({ sliderImages, imageUrl }) => {
  const swiperRef = useRef(null);
  const [key, setKey] = useState(0);

  useEffect(() => {
    // Force remount of Swiper component
    setKey(prevKey => prevKey + 1);

    const initSwiper = () => {
      const swiper = swiperRef.current?.swiper;
      if (swiper) {
        const updateSlideOpacity = () => {
          swiper.slides.forEach((slide, index) => {
            const slideImage = slide.querySelector('img');
            if (slideImage) {
              slideImage.style.opacity = index === swiper.activeIndex ? '1' : '0.8';
            }
          });
        };

        swiper.on('slideChange', updateSlideOpacity);
        swiper.on('init', updateSlideOpacity);

        // Manually call update and updateSlides methods
        swiper.update();
        swiper.updateSlides();

        return () => {
          swiper.off('slideChange', updateSlideOpacity);
          swiper.off('init', updateSlideOpacity);
        };
      }
    };

    // Small delay to ensure DOM is ready
    const timer = setTimeout(initSwiper, 100);

    return () => clearTimeout(timer);
  }, [sliderImages]);

  return (
    <div
      className="min-h-[30%] flex items-center justify-center p-2 relative overflow-hidden border border-2 shadow-lg "
      style={{
        boxShadow: 'inset 0 4px 8px rgba(0, 0, 0, 0.6)',
      }}
    >
      <Swiper
        key={key}
        effect={'coverflow'}
        grabCursor={true}
        centeredSlides={true}
        loop={true}
        coverflowEffect={{
          rotate: 0,
          stretch: 0,
          depth: 200,
          modifier: 2.5,
          slideShadows: true,
        }}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        parallax={true}
        modules={[EffectCoverflow, Parallax, Autoplay]}
        className="mySwiper max-w-[1350px] w-full rounded-lg"
        onSwiper={(swiper) => (swiperRef.current = swiper)}
        breakpoints={{
          1200: { slidesPerView: 2.5, spaceBetween: -140 },
          1024: { slidesPerView: 2.5, spaceBetween: -160 },
          768: { slidesPerView: 2, spaceBetween: -150 },
          640: { slidesPerView: 1, spaceBetween: -170 },
          480: { slidesPerView: 1, spaceBetween: -180 },
          0: { slidesPerView: 1, spaceBetween: -120 },
        }}
      >
        {sliderImages.map((slide, index) => (
          <SwiperSlide key={`${index}-${key}`} className="flex justify-center items-center relative">
            <img
              src={`${imageUrl}/AppSlides/${encodeURIComponent(slide.ItemName)}`}
              alt={slide.title || 'Movie Poster'}
              className="w-full h-[300px] md:h-[350px] lg:h-[300px] rounded-lg shadow-md transition-transform duration-300 ease-in-out hover:scale-105"
              data-swiper-parallax="-200"
              onClick={() => swiperRef.current?.swiper?.slideNext()}
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = '/path/to/fallback/image.jpg'; // Replace with your fallback image
              }}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default FocusedMovieSlider;