import React from "react";
import { FcAbout } from "react-icons/fc";
import { MdDevices } from "react-icons/md";
import {
  FaFemale,
  FaMale,
  FaAddressBook,
  FaRegCalendarCheck,
  FaGraduationCap,
  FaBookMedical,
  FaPeopleCarry,
} from "react-icons/fa";
import personicon from "../../assets/png/person-icon.png";
import { FaPhoneFlip, FaRegCalendar } from "react-icons/fa6";
import { BsFillTicketPerforatedFill } from "react-icons/bs";
import { GiGreekTemple } from "react-icons/gi";
import { MdCastle } from "react-icons/md";
import { IoIosListBox } from "react-icons/io";
import { PiBagSimpleFill } from "react-icons/pi";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { getInnerDataFromLocalStorage } from "../../API/getInnerDataLocalStore";
import { getStudentFullDataFromLocal } from "../../API/getDataFromLocalStorage";
import useSidebar from "../../hooks/useSidebar";
import { getUserDataFromLocalByKey } from "../../API/getStudentInfoByKeyLocal";

const StudentProfile = () => {
  const { customId, keyName } = useParams();
  const [loading, setLoading] = useState(true);
  const [userDetails, setUserDetails] = useState(null);
  const [studentName, setStudentName] = useState();
  const [stuClass, setStuClass] = useState();
  const [stuSection, setStuSection] = useState();
  const [stuGender, setStuGender] = useState();
  const [stuAddmissionNo, setStuAddmissionNo] = useState();
  const [stuDOB, setStuDOB] = useState();
  const [stuMotherName, setStuMotherName] = useState();
  const [stuFatherName, setStuFatherName] = useState();
  const [stuPhnNo, setStuPhnNo] = useState();
  const [stuAddress, setStuAddress] = useState();
  const [stuJoinClass, setStuJoinClass] = useState();
  const [stuRollNo, setStuRollNo] = useState();
  const [stuPlace, setStuPlace] = useState();
  const [stuDistrict, setStuDistrict] = useState();
  const [stuState, setStuState] = useState();
  const [stuBloodGrp, setStuBloodGrp] = useState();
  const [stuDateOfAddmission, setStuDateOfAddmission] = useState();
  const [stuReligion, setStuReligion] = useState();
  const [stuCommunity, setStuCommunity] = useState();
  const [stuCategory, setStuCategory] = useState();
  const [stuFatherOccu, setStuFatherOccu] = useState();
  const [stuMotherOccu, setStuMotherOccu] = useState();
  const [profileImage, setProfileImage] = useState();
  const [resourceUrl, setResourceUrl] = useState(null);
  const ItemKeyName = [
    "SNAME",
    "CLNAME",
    "SECNAME",
    "GENDER",
    "ADMNO",
    "DOB",
    "MNAME",
    "FATHERNAME",
    "SMSPHONE",
    "ADDRESS",
    "JOC",
    "RNO",
    "PLACE",
    "DISTRICT",
    "STATE",
    "BG",
    "DOA",
    "RELIGION",
    "COMMUNITY",
    "CATEGORY",
    "FOCC",
    "MOCC",
    "PHOTOFILE",
  ];

  useEffect(() => {
    const fetchData = async () => {
      const users = JSON.parse(localStorage.getItem("registeredUsers")) || [];

      if (customId) {
        const decodedData = JSON.parse(atob(customId));
        let matchedItem = [];

        console.log("DECODED DATA", decodedData);

        users.forEach((innerArray) => {
          const [userData] = innerArray;

          if (
            userData.userId === decodedData.userId &&
            userData.userType === decodedData.userType &&
            userData.schoolId === decodedData.schoolId &&
            userData.year === decodedData.year
          ) {
            matchedItem.push(userData);
          }
        });

        const key = "WebResourceAWSDOTNETURL";
        const dataResourceUrl = await getUserDataFromLocalByKey(
          decodedData.userId,
          decodedData.schoolId,
          decodedData.userType,
          decodedData.year,
          key
        );
        setResourceUrl(dataResourceUrl);
        const result = await getStudentFullDataFromLocal(
          decodedData.userId,
          decodedData.schoolId,
          decodedData.userType,
          decodedData.year
        );

        setUserDetails(result);
        console.log("USER DETAILS RESULT", result);

        const getInfoFields = () => {
          const infoFields = {};
          ItemKeyName.forEach((itemKeyName) => {
            const item = result.find(
              (item) => item.ItemKeyName === itemKeyName
            );
            infoFields[itemKeyName] = item?.InfoField;
          });
          return infoFields;
        };

        // Get InfoFields based on ItemKeyNames
        const infoFields = getInfoFields();

        

        // Update state variables with corresponding InfoFields
        setStudentName(infoFields.SNAME);
        setStuClass(infoFields.CLNAME);
        setStuSection(infoFields.SECNAME);
        setStuGender(infoFields.GENDER);
        setStuAddmissionNo(infoFields.ADMNO);
        setStuDOB(infoFields.DOB);
        setStuMotherName(infoFields.MNAME);
        setStuFatherName(infoFields.FATHERNAME);
        setStuPhnNo(infoFields.SMSPHONE);
        setStuAddress(infoFields.ADDRESS);
        setStuJoinClass(infoFields.JOC);
        setStuRollNo(infoFields.RNO);
        setStuPlace(infoFields.PLACE);
        setStuDistrict(infoFields.DISTRICT);
        setStuState(infoFields.STATE);
        setStuBloodGrp(infoFields.BG);
        setStuDateOfAddmission(infoFields.DOA);
        setStuReligion(infoFields.RELIGION);
        setStuCommunity(infoFields.COMMUNITY);
        setStuCategory(infoFields.CATEGORY);
        setStuFatherOccu(infoFields.FOCC);
        setStuMotherOccu(infoFields.MOCC);
        setLoading(false);
        setUserDetails(matchedItem);
        setProfileImage(infoFields?.PHOTOFILE);
      }
    };

    fetchData();
  }, [customId]);

  const values = {
    "Mother Name": stuMotherName,
    "Father Name": stuFatherName,
    "Date of Birth": stuDOB,
    "Phone Number": stuPhnNo,
    Address: stuAddress,
    "Join Class": stuJoinClass,
    "Roll Number": stuRollNo,
    Place: stuPlace,
    District: stuDistrict,
    State: stuState,
    "Blood Group": stuBloodGrp,
    "Date of Addmission": stuDateOfAddmission,
    Religion: stuReligion,
    Community: stuCommunity,
    Category: stuCategory,
    "Father Occupation": stuFatherOccu,
    "Mother Occupation": stuMotherOccu,
  };

  const records = [
    { id: 1, text: "Device ID", icon: <MdDevices className="h-10 w-10 " /> },
    {
      id: 2,
      text: "Date of Birth",
      icon: <FaRegCalendarCheck className="h-10 w-10" />,
    },
    { id: 3, text: "Mother Name", icon: <FaFemale className=" h-10 w-10" /> },
    { id: 4, text: "Father Name", icon: <FaMale className="h-10 w-10" /> },
    {
      id: 5,
      text: "Phone Number",
      icon: <FaPhoneFlip className="h-10 w-10" />,
    },
    { id: 6, text: "Address", icon: <FaAddressBook className="h-10 w-10" /> },
    {
      id: 7,
      text: "Join Class",
      icon: <FaGraduationCap className="h-10 w-10" />,
    },
    {
      id: 8,
      text: "Roll Number",
      icon: <BsFillTicketPerforatedFill className="h-10 w-10" />,
    },
    { id: 9, text: "Place", icon: <GiGreekTemple className="h-10 w-10" /> },
    { id: 10, text: "District", icon: <GiGreekTemple className="h-10 w-10" /> },
    { id: 11, text: "State", icon: <GiGreekTemple className="h-10 w-10" /> },
    {
      id: 12,
      text: "Blood Group",
      icon: <FaBookMedical className="h-10 w-10" />,
    },
    {
      id: 13,
      text: "Date of Addmission",
      icon: <FaRegCalendar className="h-10 w-10" />,
    },
    { id: 14, text: "Religion", icon: <MdCastle className="h-10 w-10" /> },
    {
      id: 15,
      text: "Community",
      icon: <FaPeopleCarry className="h-10 w-10" />,
    },
    { id: 16, text: "Category", icon: <IoIosListBox className="h-10 w-10" /> },
    {
      id: 17,
      text: "Father Occupation",
      icon: <PiBagSimpleFill className="h-10 w-10" />,
    },
    {
      id: 18,
      text: "Mother Occupation",
      icon: <PiBagSimpleFill className="h-10 w-10" />,
    },

    // Add more records as needed
  ];

  const { sidebarVisible, toggleSidebar } = useSidebar();
  return (
    <div className="max-w-7xl mx-auto p-4 relative">
      <main>
        <div className="flex justify-between items-center max-w-7xl">
          <p className="text-3xl font-bold ml-2 mb-2">My Profile</p>
        </div>

        <div className="m-2 flex flex-col justify-center relative h-64 shadow-md mx-auto bg-gradient-to-b from-sky-400 to-indigo-500 max-w-7xl p-4 rounded-2xl">
          {/* Profile Content */}

          <div className="flex justify-center  items-center rounded-full flex-col mt-8">
            <img
              src={`${resourceUrl}/PICS/01/${profileImage}`}
              alt="Profile"
              onError={(e) => {
                e.target.src = personicon;
              }}
              className="w-24 h-24 rounded-full absolute top-6  border-4 border-white"
            />

            <div className="absolute text-center top-28">
              <h2 className=" mt-4 text-xl font-bold text-white py-2 px-4 rounded-md">
                {studentName}
              </h2>
              <p className="font-semibold  text-white">{stuAddmissionNo}</p>
            </div>
          </div>
        </div>
      </main>

      <div className="mt-8 flex absolute  w-full top-60 right-0 left-0 rounded-md  justify-around px-4 py-5 items-center max-w-xl mx-auto bg-white shadow-md">
        {/* Placeholder content */}
        <div className="Items flex flex-col items-center">
          <div className="text-gray-500">Class</div>
          <div className="font-bold">{stuClass}</div>
        </div>
        <div className="Items flex flex-col items-center">
          <div className="text-gray-500">Section</div>
          <div className="font-bold">{stuSection}</div>
        </div>

        <div className="Items flex flex-col items-center">
          <div className="text-gray-500">Gender</div>
          <div className="font-bold">{stuGender}</div>
        </div>
      </div>

      <div className="max-w-7xl h-96 overflow-y-auto mx-auto mt-12 rounded-md shadow-md  bg-white ">
        <div className="px-3 h-96 py-8 max-w-4xl mx-auto">
          <h2 className="font-semibold text-2xl">Information</h2>
          <div className="flex flex-col  ">
            {records.map((record) => (
              <div key={record.id} className="flex py-3 px-3 items-center mb-2">
                <div className="mx-2">
                  <div style={{ color: " #0EA5E9" }}>{record.icon}</div>
                </div>
                <div className="mx-2">
                  {record.text}
                  <div style={{ color: " #0EA5E9" }}>{values[record.text]}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudentProfile;
