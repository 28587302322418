import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { Accordion } from "flowbite-react";

import { toast } from "react-toastify";
import { GetClassFullDetails } from "../../API/GetClassFullDetails";
import { TeachersTodaysTT } from "../../API/TeachersTodaysTT";
import PrintTableModal from "../../components/PrintTableModal";

const QuickInfoErpClassDetailsPage = () => {
  const location = useLocation();
  const classID = location.state.classID;
  const sectionID = location.state.sectionID;
  const navigate = useNavigate();
  const { customId } = useParams();
  const [loading, setLoading] = useState(false);
  const [erpClassList, setErpClassList] = useState([]);
  const [classDetails, setClassDetails] = useState([]);
  const [timeTable, setTimeTable] = useState([]);
  const [currentDayTimeTable, setCurrentDayTimeTable] = useState([]);
  const [classTeacher, setClassTeacher] = useState([]);
  const [classStudents, setClassStudents] = useState([]);
  const [collapsetrue, setCollapsetrue] = useState(false);
  const [empTableData, setEmpTableData] = useState([]);
  const [selectedEMPName, setSelectedEmpName] = useState([]);
  const [userDetails, setUserDetails] = useState(null);
  const [openTableModal, setOpenTableModal] = useState(false);

  useEffect(() => {
    // setLoading(true)
    const fetchData = async () => {
      setLoading(true);
      const users = JSON.parse(localStorage.getItem("registeredUsers")) || [];

      if (customId) {
        const decodedData = JSON.parse(atob(customId));
        let matchedItem = [];

        users.forEach((innerArray) => {
          const [userData] = innerArray;

          if (
            userData.userId === decodedData.userId &&
            userData.userType === decodedData.userType &&
            userData.schoolId === decodedData.schoolId &&
            userData.year === decodedData.year
          ) {
            matchedItem.push(userData);
          }
        });
        // handleClassAndSectionFetch(
        //   decodedData.userId,
        //   decodedData.schoolId,
        //   decodedData.userType,
        //   decodedData.year
        // );
        setUserDetails(matchedItem);

        setLoading(false);
      }
    };

    fetchData();
  }, [customId]);

  const erpClassWiseFetch = useCallback(async () => {
    if (userDetails) {
      const response = await GetClassFullDetails(
        userDetails[0].year,
        classID,
        sectionID
      );
      const JSONData5 = JSON.parse(response[0]?.JSONData5);
      const JSONData4 = JSON.parse(response[0]?.JSONData4);
      const JSONData3 = JSON.parse(response[0]?.JSONData3);
      const JSONData2 = JSON.parse(response[0]?.JSONData2);
      const JSONData1 = JSON.parse(response[0]?.JSONData1);

      setErpClassList(JSONData5);
      setClassDetails(JSONData1);
      setClassTeacher(JSONData3);
      setCurrentDayTimeTable(JSONData2);
      setTimeTable(JSONData4);
      setClassStudents(JSONData5);
    }
  }, [classID, sectionID, userDetails]);

  useEffect(() => {
    if (classID && sectionID) erpClassWiseFetch();
  }, [classID, erpClassWiseFetch, sectionID, userDetails]);

  const absentOnly = (checked) => {
    if (checked) {
      const data = erpClassList;
      const filteredData = data?.filter((item) => item?.AttSts === "A");
      setClassStudents(filteredData);
    } else {
      setClassStudents(erpClassList);
    }
  };

  // const columns = [
  //   { index: 1, title: "CLass Teacher", data: "CLassTeacher", visible: true },
  //   { index: 1, title: "CLass", data: "dbName", visible: true },
  //   { index: 2, title: "Total", data: "Total", visible: true },
  //   { index: 4, title: "Present", data: "present", visible: true },
  //   { index: 3, title: "Absent", data: "absent", visible: true },
  // ];
  // const sampleErpClassList = [
  //   {
  //     CLassTeacher: "John Doe",
  //     Logourl: "Class 1 Logo",
  //     absent: 20,
  //     present: 30,
  //     Total: 10,
  //     dbName: "db_class1",
  //   },
  //   {
  //     CLassTeacher: "Jane Smith",
  //     Logourl: "Class 2 Logo",
  //     absent: 18,
  //     present: 28,
  //     Total: 10,
  //     dbName: "db_class2",
  //   },
  //   {
  //     CLassTeacher: "Alice Johnson",
  //     Logourl: "Class 3 Logo",
  //     absent: 22,
  //     present: 32,
  //     Total: 10,
  //     dbName: "db_class3",
  //   },
  //   {
  //     CLassTeacher: "Bob Brown",
  //     Logourl: "Class 4 Logo",
  //     absent: 25,
  //     present: 35,
  //     Total: 10,
  //     dbName: "db_class4",
  //   },
  //   {
  //     CLassTeacher: "Carol White",
  //     Logourl: "Class 5 Logo",
  //     absent: 19,
  //     present: 29,
  //     Total: 10,
  //     dbName: "db_class5",
  //   },
  // ];

  const TTColumn = [
    // { index: 1, title: "Name", data: "EmpName", visible: true },
    { index: 2, title: "Code", data: "code", visible: true },
    { index: 3, title: "", data: "image", visible: true },
    { index: 4, title: "Subject", data: "subject", visible: true },
    { index: 5, title: "Period", data: "period", visible: true },
    { index: 7, title: "Class", data: "class", visible: true },
    { index: 6, title: "Section", data: "section", visible: true },
  ];

  const handleViewTodaysTT = async (cls) => {
    if (cls && userDetails) {
      const response = await TeachersTodaysTT(
        userDetails[0].userId,
        userDetails[0].schoolId,
        userDetails[0].userType,
        userDetails[0].year,
        cls
      );
      console.log(response, "respnse");
      if (response?.length > 0) {
        setSelectedEmpName(response[0]?.EmpName);
        const TableData2 = response?.map((data) => {
          let url = `${process.env.REACT_APP_ERP_STUDENT_IMAGE_URL}${data?.PHOTOFILE}`;
          return {
            // EmpName: data.EmpName,
            code: data.Empcode,
            image: `<img src="${url}" alt="Student Imae" className="rounded-full h-10 w-10" />`,
            subject: data.SUBJ,
            period: data.Cnd,
            class: data.TClass,
            section: data.TSection,
          };
        });

        if (TableData2?.length > 0) {
          setEmpTableData(TableData2);
          setOpenTableModal(true);
        }
      } else {
        toast.warn("No data found");
      }
    } else {
      toast.warn("Please select one");
    }
  };

  return (
    <div className="lg:mt-0 mt-5 h-full max-h-full md:max-h-[92vh] overflow-auto md:overflow-hidden">
      <div className="overflow-auto h-full w-full">
        {openTableModal && (
          <PrintTableModal
            close={setOpenTableModal}
            open={openTableModal}
            title={"Time Table"}
            tableData={TTColumn}
            tableRow={empTableData}
          />
        )}
        <div className="container mx-auto p-2 containerBoxShadow">
          <div>
            <div className="w-full formBackground flex flex-col gap-2 p-2 md:px-5">
              <div className="w-full flex md:flex-row flex-col-reverse gap-1">
                <div className="w-full grid md:grid-cols-4 mt-2 sm:grid-cols-3 grid-cols-2 gap-2 justify-start">
                  <div className="flex justify-center md:flex-row flex-col md:gap-2 gap-1 w-full">
                    <label
                      htmlFor=""
                      className="w-full align-middle flex md:whitespace-nowrap items-center text-sm text-end font-semibold"
                    >
                      Class & Section
                    </label>
                    <input
                      type="text"
                      value={classDetails[0]?.dbname}
                      className="max-w-60 w-full textInput "
                      readOnly
                    />
                  </div>

                  <div className="flex justify-center md:flex-row flex-col md:gap-2 gap-1 w-full">
                    <label
                      htmlFor=""
                      className="w-full align-middle flex md:whitespace-nowrap items-center text-sm text-end font-semibold"
                    >
                      Class Teacher
                    </label>
                    <input
                      type="text"
                      value={classDetails[0]?.CLassTeacher}
                      className="max-w-60 w-full textInput "
                      readOnly
                    />
                  </div>

                  <div className="flex justify-center md:flex-row flex-col md:gap-2 gap-1 w-full">
                    <label
                      htmlFor=""
                      className="w-full align-middle flex md:whitespace-nowrap items-center text-sm text-end font-semibold"
                    >
                      Total Strength
                    </label>
                    <input
                      type="text"
                      value={classDetails[0]?.NofStd}
                      className="max-w-60 w-full textInput "
                      readOnly
                    />
                  </div>

                  <div className="flex justify-center md:flex-row flex-col md:gap-2 gap-1 w-full">
                    <label
                      htmlFor=""
                      className="w-full align-middle flex md:whitespace-nowrap items-center text-sm text-end font-semibold"
                    >
                      Active Students
                    </label>
                    <input
                      type="text"
                      value={classDetails[0]?.NofStdA}
                      className="max-w-60 w-full textInput "
                      readOnly
                    />
                  </div>

                  <div className="flex justify-center md:flex-row flex-col md:gap-2 gap-1 w-full">
                    <label
                      htmlFor=""
                      className="w-full align-middle flex md:whitespace-nowrap items-center text-sm text-end font-semibold"
                    >
                      Absenties
                    </label>
                    <input
                      type="text"
                      value={classDetails[0]?.NofStdAb}
                      className="max-w-60 w-full textInput "
                      readOnly
                    />
                  </div>
                </div>
                {/* <div className="w-full flex flex-col gap-1 md:basis-[50%] justify-start">
                  <div className="flex bg-transparent justify-center w-fit items-center m-auto">
                    <img src={stdProfile} className="size-40" alt="profile" />
                  </div>
                </div> */}
              </div>
            </div>
            <div className="hidden">
              <button
                onClick={() => {
                  setOpenTableModal(!openTableModal);
                }}
                type="button"
                className="px-3 py-2 my-2 font-poppins font-medium text-black bg-purple-heart-400 rounded"
              >
                Open Print Modal
              </button>
            </div>
            <div className="container mx-auto my-5 mb-10">
              <Accordion collapseAll className="w-full border-0">
                <Accordion.Panel className="w-full">
                  <Accordion.Title className="accordian-title mb-2 focus:outline-none uppercase border-0 focus:ring-0">
                    Teacher's List with Subject
                  </Accordion.Title>
                  <Accordion.Content className="bg-transparent -mt-2 !p-0 transition-all duration-300 ease-custom-ease rounded-lg shadow-lg overflow-auto max-h-[400px] md:max-h-full md:overflow-hidden">
                    <div className="max-w-full formBackground p-2 h-full flex flex-wrap justify-between mx-auto border border-black border-solid rounded-sm">
                      <div className="relative max-h-[400px] md:block hidden overflow-auto border md:max-w-full border-black shadow-md mx-auto sm:rounded-lg md:min-w-full w-fit h-full">
                        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 border-collapse">
                          <thead className="h-12 text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400 sticky top-0 z-10">
                            <tr className="tableHead">
                              <th scope="col" className="p-1">
                                Sl No.
                              </th>
                              <th scope="col" className="p-1"></th>
                              <th scope="col" className="p-1">
                                Teacher Name
                              </th>
                              <th scope="col" className="p-1">
                                Code
                              </th>
                              <th scope="col" className="p-1">
                                Subject
                              </th>
                              <th scope="col" className="p-1">
                                No. of Period
                              </th>
                              <th scope="col" className="p-1">
                                Gender
                              </th>
                              <th scope="col" className="p-1"></th>
                              <th scope="col" className="p-1"></th>
                            </tr>
                          </thead>
                          <tbody className="text-gray-900">
                            {classTeacher?.map((item, i) => {
                              let url = `${process.env.REACT_APP_ERP_STUDENT_IMAGE_URL}${item?.PHOTOFILE}`;
                              return (
                                <tr
                                  key={i}
                                  className={`text-gray-900 ${
                                    i % 2 === 0 ? "bg-white" : "bg-gray-100"
                                  }`}
                                >
                                  <td className="p-1 border">{i + 1}</td>
                                  <td className="p-1 border">
                                    <img
                                      src={url}
                                      alt="img logo"
                                      className="size-10"
                                    />
                                  </td>
                                  <td className="p-1 border">
                                    {item?.EmpName}
                                  </td>
                                  <td className="p-1 border">
                                    {item?.Empcode}
                                  </td>
                                  <td className="p-1 border">{item?.SUBJ}</td>
                                  <td className="p-1 border">{item?.Cnd}</td>
                                  <td className="p-1 border">{item?.Sex}</td>
                                  <td className="p-1 border">
                                    <button
                                      onClick={() => {
                                        navigate(
                                          `/admin/u/quick_info/staff_profile/${customId}`,
                                          {
                                            state: {
                                              empCode: item?.Empcode,
                                            },
                                          }
                                        );
                                      }}
                                      className="buttonClass w-fit font-montserrat mx-auto"
                                    >
                                      View Profile
                                    </button>
                                  </td>
                                  <td className="p-1 border">
                                    <button
                                      onClick={() =>
                                        handleViewTodaysTT(item?.Empcode)
                                      }
                                      className="buttonClass w-fit font-montserrat mx-auto"
                                    >
                                      View Today's TT
                                    </button>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                      <div className="overflow-auto w-full md:hidden block">
                        {classTeacher?.map((student, index) => {
                          let url = `${process.env.REACT_APP_ERP_STUDENT_IMAGE_URL}${student?.PHOTOFILE}`;
                          return (
                            <div
                              className="border-2 table-bg-employeeClass rounded-lg mb-4 w-full shadow-lg overflow-hidden"
                              key={index}
                            >
                              <div className="flex students-center justify-between tabButton py-2 px-4 rounded-t-lg shadow-md">
                                <span className="font-bold">
                                  {" "}
                                  {student?.EmpName}
                                </span>
                                <button
                                  onClick={() => {
                                    navigate(
                                      `/admin/u/quick_info/staff_profile/${customId}`,
                                      {
                                        state: {
                                          empCode: student?.Empcode,
                                        },
                                      }
                                    );
                                  }}
                                  className="buttonClass border border-white"
                                >
                                  View Profile
                                </button>
                              </div>
                              <div className="flex justify-center">
                                <img
                                  src={url}
                                  alt="Student"
                                  className=" size-20 object-cover p-1 rounded-full"
                                />
                              </div>
                              <table className="w-full table-auto">
                                <tbody>
                                  <tr>
                                    <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                                      <strong>Code</strong>
                                    </td>
                                    <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                                      {student?.Empcode}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                                      <strong>Subject</strong>
                                    </td>
                                    <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                                      {student?.SUBJ}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                                      <strong>No. of Period</strong>
                                    </td>
                                    <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                                      {student?.Cnd}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                                      <strong>Gender</strong>
                                    </td>
                                    <td className="border border-solid border-gray-400 text-start capitalize p-2 w-1/2">
                                      {student?.Sex}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="border border-solid border-gray-400 text-start p-2 w-1/2"></td>
                                    <td className="border border-solid border-gray-400 text-start capitalize p-2 w-1/2">
                                      <button
                                        onClick={() =>
                                          handleViewTodaysTT(student?.Empcode)
                                        }
                                        className="buttonClass w-fit font-montserrat mx-auto"
                                      >
                                        View Today's TT
                                      </button>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </Accordion.Content>
                </Accordion.Panel>
                <Accordion.Panel className="w-full">
                  <Accordion.Title className="accordian-title mb-2 focus:outline-none uppercase border-0 focus:ring-0">
                    Class List
                  </Accordion.Title>
                  <Accordion.Content className="bg-transparent -mt-2 !p-0 transition-all duration-300 ease-custom-ease rounded-lg shadow-lg overflow-auto max-h-[400px] md:max-h-full md:overflow-hidden">
                    <div className="max-w-full formBackground p-2 h-full flex flex-wrap justify-between mx-auto border border-black border-solid rounded-sm">
                      <div className="flex justify-start items-center gap-2 my-1">
                        <label
                          htmlFor="onlyfor"
                          className="text-black sm:whitespace-nowrap font-montserrat font-semibold text-lg md:text-xl"
                        >
                          Show Absent Students only
                        </label>
                        <input
                          type="checkbox"
                          checked={collapsetrue}
                          onChange={(e) => {
                            setCollapsetrue(e.target.checked);
                            absentOnly(e.target.checked);
                          }}
                          id="onlyfor"
                          className="checkbox focus:outline-none focus:ring-0 focus:border-0"
                        />
                      </div>
                      <div className="relative max-h-[400px] md:block hidden overflow-auto border md:max-w-full border-black shadow-md mx-auto sm:rounded-lg md:min-w-full w-fit h-full">
                        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 border-collapse">
                          <thead className="h-12 text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400 sticky top-0 z-10">
                            <tr className="tableHead">
                              <th scope="col" className="p-1">
                                Sl No.
                              </th>
                              <th scope="col" className="p-1"></th>
                              <th scope="col" className="p-1">
                                Student Name
                              </th>
                              <th scope="col" className="p-1">
                                Admission No.
                              </th>
                              <th scope="col" className="p-1">
                                Father Name
                              </th>
                              <th scope="col" className="p-1">
                                Gender
                              </th>
                              <th scope="col" className="p-1">
                                Phone
                              </th>
                              <th scope="col" className="p-1">
                                Att Status
                              </th>
                              <th scope="col" className="p-1"></th>
                            </tr>
                          </thead>
                          <tbody className="text-gray-900">
                            {classStudents?.map((item, i) => {
                              let url = `${process.env.REACT_APP_ERP_STUDENT_IMAGE_URL}${item?.ImageFile}`;
                              return (
                                <tr
                                  key={i}
                                  className={`text-gray-900 ${
                                    i % 2 === 0 ? "bg-white" : "bg-gray-100"
                                  }`}
                                >
                                  <td className="p-1 border">{i + 1}</td>
                                  <td className="p-1 border">
                                    <img
                                      src={url}
                                      alt="img logo"
                                      className="size-10"
                                    />
                                  </td>
                                  <td className="p-1 border">{item?.Sname}</td>
                                  <td className="p-1 border">{item?.Admno}</td>
                                  <td className="p-1 border">
                                    {item?.FATHERNAME}
                                  </td>
                                  <td className="p-1 border">{item?.GENDER}</td>
                                  <td className="p-1 border">
                                    {item?.SMSPHONE}
                                  </td>
                                  <td className="p-1 border">{item?.AttSts}</td>
                                  <td className="p-1 border">
                                    <button
                                      onClick={() => {
                                        navigate(
                                          `/admin/u/${customId}/QuickInfo/student_profile`,
                                          {
                                            state: {
                                              admno: item?.Admno,
                                            },
                                          }
                                        );
                                      }}
                                      className="buttonClass w-fit font-montserrat mx-auto"
                                    >
                                      View Profile
                                    </button>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>

                      <div className="overflow-auto w-full md:hidden block">
                        {classStudents?.map((student, index) => {
                          let url = `${process.env.REACT_APP_ERP_STUDENT_IMAGE_URL}${student?.ImageFile}`;
                          return (
                            <div
                              className="border-2 table-bg-employeeClass rounded-lg mb-4 w-full shadow-lg overflow-hidden"
                              key={index}
                            >
                              <div className="flex students-center justify-between tabButton py-2 px-4 rounded-t-lg shadow-md">
                                <span className="font-bold">
                                  {" "}
                                  {student?.Sname}
                                </span>
                                <button
                                  onClick={() => {
                                    navigate(
                                      `/admin/u/${customId}/QuickInfo/student_profile`,
                                      {
                                        state: {
                                          admno: student?.Admno,
                                        },
                                      }
                                    );
                                  }}
                                  className="buttonClass border border-white"
                                >
                                  View Profile
                                </button>
                              </div>
                              <div className="flex justify-center">
                                <img
                                  src={url}
                                  alt="Student"
                                  className=" size-20 object-cover p-1 rounded-full"
                                />
                              </div>
                              <table className="w-full table-auto">
                                <tbody>
                                  <tr>
                                    <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                                      <strong> Admission No.</strong>
                                    </td>
                                    <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                                      {student?.Admno}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                                      <strong> Father Name</strong>
                                    </td>
                                    <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                                      {student?.FATHERNAME}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                                      <strong>Gender</strong>
                                    </td>
                                    <td className="border border-solid border-gray-400 text-start capitalize p-2 w-1/2">
                                      {student?.GENDER}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                                      <strong> Phone</strong>
                                    </td>
                                    <td className="border border-solid border-gray-400 text-start capitalize p-2 w-1/2">
                                      {student?.SMSPHONE}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                                      <strong> Att Status</strong>
                                    </td>
                                    <td className="border border-solid border-gray-400 text-start capitalize p-2 w-1/2">
                                      {student?.AttSts}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </Accordion.Content>
                </Accordion.Panel>
                <Accordion.Panel className="w-full">
                  <Accordion.Title className="accordian-title mb-2 focus:outline-none uppercase border-0 focus:ring-0">
                    Today's Time Table
                  </Accordion.Title>
                  <Accordion.Content className="bg-transparent -mt-2 !p-0 transition-all duration-300 ease-custom-ease rounded-lg shadow-lg overflow-auto max-h-[400px] md:max-h-full md:overflow-hidden">
                    <div className="max-w-full formBackground p-2 h-full flex flex-wrap justify-between mx-auto border border-black border-solid rounded-sm">
                      <div className="relative max-h-[300px] md:block hidden overflow-auto border md:max-w-full border-black shadow-md mx-auto sm:rounded-lg md:min-w-full w-fit h-full">
                        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 border-collapse">
                          <thead className="h-12 text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400 sticky top-0 z-10">
                            <tr className="tableHead">
                              <th scope="col" className="p-1">
                                Sl No.
                              </th>
                              <th scope="col" className="p-1"></th>
                              <th scope="col" className="p-1">
                                Teacher Name
                              </th>
                              <th scope="col" className="p-1">
                                Period
                              </th>
                              <th scope="col" className="p-1">
                                Subject
                              </th>
                              <th scope="col" className="p-1">
                                Emp Code
                              </th>
                              <th scope="col" className="p-1"></th>
                              <th scope="col" className="p-1"></th>
                            </tr>
                          </thead>
                          <tbody className="text-gray-900">
                            {currentDayTimeTable?.map((item, i) => {
                              let url = `${process.env.REACT_APP_ERP_STUDENT_IMAGE_URL}${item?.PHOTOFILE}`;
                              return (
                                <tr
                                  key={i}
                                  className={`text-gray-900 ${
                                    i % 2 === 0 ? "bg-white" : "bg-gray-100"
                                  }`}
                                >
                                  <td className="p-1 border">{i + 1}</td>
                                  <td className="p-1 border">
                                    <img
                                      src={url}
                                      alt="img logo"
                                      className="size-10"
                                    />
                                  </td>
                                  <td className="p-1 border">
                                    {item?.EmpName}
                                  </td>
                                  <td className="p-1 border">{item?.Cnd}</td>
                                  <td className="p-1 border">{item?.SUBJ}</td>
                                  <td className="p-1 border">
                                    {item?.Empcode}
                                  </td>
                                  <td className="p-1 border">
                                    <button
                                      onClick={() => {
                                        navigate(
                                          `/admin/u/quick_info/staff_profile/${customId}`,
                                          {
                                            state: {
                                              empCode: item?.Empcode,
                                            },
                                          }
                                        );
                                      }}
                                      className="buttonClass w-fit font-montserrat mx-auto"
                                    >
                                      View Profile
                                    </button>
                                  </td>
                                  <td className="p-1 border">
                                    <button
                                      onClick={() =>
                                        handleViewTodaysTT(item?.Empcode)
                                      }
                                      className="buttonClass w-fit font-montserrat mx-auto"
                                    >
                                      View Today's TT
                                    </button>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                      <div className="overflow-auto w-full md:hidden block">
                        {currentDayTimeTable?.map((student, index) => {
                          let url = `${process.env.REACT_APP_ERP_STUDENT_IMAGE_URL}${student?.PHOTOFILE}`;
                          return (
                            <div
                              className="border-2 table-bg-employeeClass rounded-lg mb-4 w-full shadow-lg overflow-hidden"
                              key={index}
                            >
                              <div className="flex students-center justify-between tabButton py-2 px-4 rounded-t-lg shadow-md">
                                <span className="font-bold">
                                  {" "}
                                  {student?.EmpName}
                                </span>
                                <button
                                  onClick={() => {
                                    navigate(
                                      `/admin/u/quick_info/staff_profile/${customId}`,
                                      {
                                        state: {
                                          empCode: student?.Empcode,
                                        },
                                      }
                                    );
                                  }}
                                  className="buttonClass border border-white"
                                >
                                  View Profile
                                </button>
                              </div>
                              <div className="flex justify-center">
                                <img
                                  src={url}
                                  alt="Student"
                                  className=" size-20 object-cover p-1 rounded-full"
                                />
                              </div>
                              <table className="w-full table-auto">
                                <tbody>
                                  <tr>
                                    <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                                      <strong> Period</strong>
                                    </td>
                                    <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                                      {student?.Cnd}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                                      <strong> Subject</strong>
                                    </td>
                                    <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                                      {student?.SUBJ}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                                      <strong> Emp Code</strong>
                                    </td>
                                    <td className="border border-solid border-gray-400 text-start capitalize p-2 w-1/2">
                                      {student?.Empcode}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="border border-solid border-gray-400 text-start p-2 w-1/2"></td>
                                    <td className="border border-solid border-gray-400 text-start capitalize p-2 w-1/2">
                                      <button
                                        onClick={() =>
                                          handleViewTodaysTT(student?.Empcode)
                                        }
                                        className="buttonClass w-fit font-montserrat mx-auto"
                                      >
                                        View Today's TT
                                      </button>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </Accordion.Content>
                </Accordion.Panel>
                <Accordion.Panel className="w-full">
                  <Accordion.Title className="accordian-title mb-2 focus:outline-none uppercase border-0 focus:ring-0">
                    Weekly Time Table
                  </Accordion.Title>
                  <Accordion.Content className="bg-transparent -mt-2 !p-0 transition-all duration-300 ease-custom-ease rounded-lg shadow-lg overflow-auto max-h-[400px] md:max-h-full md:overflow-hidden">
                    <div className="max-w-full formBackground p-2 h-full flex flex-wrap justify-between mx-auto border border-black border-solid rounded-sm">
                      <div className="relative max-h-[400px] md:block hidden overflow-auto border md:max-w-full border-black shadow-md mx-auto sm:rounded-lg md:min-w-full w-fit h-full">
                        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 border-collapse">
                          <thead className="h-12 text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400 sticky top-0 z-10">
                            <tr className="tableHead">
                              <th scope="col" className="p-1">
                                Day
                              </th>
                              <th scope="col" className="p-1">
                                Period 1
                              </th>
                              <th scope="col" className="p-1">
                                Period 2
                              </th>
                              <th scope="col" className="p-1">
                                Period 3
                              </th>
                              <th scope="col" className="p-1">
                                Period 4
                              </th>
                              <th scope="col" className="p-1">
                                Period 5
                              </th>
                              <th scope="col" className="p-1">
                                Period 6
                              </th>
                              <th scope="col" className="p-1">
                                Period 7
                              </th>
                              <th scope="col" className="p-1">
                                Period 8
                              </th>
                            </tr>
                          </thead>
                          <tbody className="text-gray-900">
                            {timeTable?.map((item, i) => {
                              return (
                                <tr
                                  key={i}
                                  className={`text-gray-900 ${
                                    i % 2 === 0 ? "bg-white" : "bg-gray-100"
                                  }`}
                                >
                                  <td className="p-1 border">{item?.Day}</td>
                                  <td className="p-1 border">{item?.Col1}</td>
                                  <td className="p-1 border">{item?.Col2}</td>
                                  <td className="p-1 border">{item?.Col3}</td>
                                  <td className="p-1 border">{item?.Col4}</td>
                                  <td className="p-1 border">{item?.Col5}</td>
                                  <td className="p-1 border">{item?.Col6}</td>
                                  <td className="p-1 border">{item?.Col7}</td>
                                  <td className="p-1 border">{item?.Col8}</td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                      <div className="overflow-auto w-full md:hidden block">
                        {timeTable?.map((student, index) => {
                          return (
                            <div
                              className="border-2 table-bg-employeeClass rounded-lg mb-4 w-full shadow-lg overflow-hidden"
                              key={index}
                            >
                              <h2 className="font-montserrat font-medium text-lg text-center w-full mx-auto">
                                {student?.Day}
                              </h2>

                              <table className="w-full table-auto">
                                <tbody>
                                  <tr>
                                    <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                                      <strong>Period 1</strong>
                                    </td>
                                    <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                                      {student?.Col1}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                                      <strong>Period 2</strong>
                                    </td>
                                    <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                                      {student?.Col2}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                                      <strong>Period 3</strong>
                                    </td>
                                    <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                                      {student?.Col3}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                                      <strong>Period 4</strong>
                                    </td>
                                    <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                                      {student?.Col4}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                                      <strong>Period 5</strong>
                                    </td>
                                    <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                                      {student?.Col5}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                                      <strong>Period 6</strong>
                                    </td>
                                    <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                                      {student?.Col6}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                                      <strong>Period 7</strong>
                                    </td>
                                    <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                                      {student?.Col7}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                                      <strong>Period 8</strong>
                                    </td>
                                    <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                                      {student?.Col8}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </Accordion.Content>
                </Accordion.Panel>
              </Accordion>
              {/* <div className="md:flex no-wrap md:-mx-2 ">
                <div className="relative gap-5 overflow-x-auto w-full flex py-2 flex-row flex-wrap">
                  <div className="mx-auto h-fit bg-sky-100 rounded-xl shadow-lg md:w-1/2 w-full"></div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuickInfoErpClassDetailsPage;
