import axios from "axios";

export const getStaffIndividualDataAPI = async (empCode) => {
  try {
    const returnUrl = process.env.REACT_APP_ERP_QUICKINFO_PAGE;
    const formData = new FormData();

    formData.append("title", "GetStaffListERPQI");
    formData.append("description", "Checking");
    formData.append("Reqdisptype", "0");
    formData.append("ReqEmpType", "");
    formData.append("ReqEmpDept", "");
    formData.append("ReqEmpAccCat", "");
    formData.append("ReqDesig", "");
    formData.append("ReqEmpCode", empCode);

    let userdata = [];
    const Corskey = process.env.REACT_APP_CORS_URL;
    const result = await axios.post(`${Corskey}${returnUrl}`, formData);
    userdata = JSON.parse(result.data.substring(0, result.data.indexOf("||JasonEnd", 0)));
    return userdata;
  } catch (error) {
    console.error("An error occurred:", error);
  }
};

export const GetStaffAttByMonth = async (year, mntId, empID) => {
  try {
    const returnUrl = process.env.REACT_APP_ERP_PAYROLL_PAGE;
    let formData = new FormData();

    formData.append("title", "GetStaffAttByMonth");
    formData.append("description", "Get ERP Class strength");
    formData.append("ReqYear", year);
    formData.append("ReqMntid", mntId);
    formData.append("ReqEmpAutoID", empID);

    let userdata = [];
    const Corskey = process.env.REACT_APP_CORS_URL;
    const result = await axios.post(`${Corskey}${returnUrl}`, formData);
    userdata = JSON.parse(result.data.substring(0, result.data.indexOf("||JasonEnd", 0)));
    return userdata;
  } catch (error) {
    console.error("There was a problem with the request:", error);
  }
};

export const GetEmployeeMasterByCode = async (year, empCode, empId) => {
  try {
    const returnUrl = process.env.REACT_APP_ERP_PAYROLL_PAGE;
    let formData = new FormData();

    formData.append("title", "GetEmployeeMasterByCode");
    formData.append("description", "Get ERP Class strength");
    formData.append("ReqType", 1);
    formData.append("ReqAcastart", year);
    formData.append("ReqEmpCode", empCode);
    formData.append("ReqEmpAutoID", empId);

    let userdata = [];
    const Corskey = process.env.REACT_APP_CORS_URL;
    const result = await axios.post(`${Corskey}${returnUrl}`, formData);
    userdata = JSON.parse(result.data.substring(0, result.data.indexOf("||JasonEnd", 0)));
    return userdata;
  } catch (error) {
    console.error("There was a problem with the request:", error);
  }
};
