import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import Spinner from "../../components/Spinner";
import useSidebar from "../../hooks/useSidebar";
import Images from "../../assets/Files/images.png";
import PDFImage from "../../assets/Files/pdficconpng.png";
import powerpointIcon from "../../assets/Files/PPTIcon.png";
import WordIcon from "../../assets/Files/wordicon.png";
import VideoIcon from "../../assets/Files/videoicon.png";
import Youtube from "../../assets/Files/youtube.png";
import Excel from "../../assets/Files/excel.svg";
import textMessage from "../../assets/Files/textmsg.png";
import OtherImg from "../../assets/Files/otherico.png";
import { getMyMentorsListDetail } from "../../API/getMyMentorsList";
import AudioIcon from "../../assets/Files/musicIcon.png";
import { getAdminScrollList } from "../../API/getAdminScrollNoticeList";

const ScrollNoticeList = () => {
  const { customId } = useParams();
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredMyMentors, setFilteredMyMentors] = useState([]);
  const [userDetails, setUserDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [myMentorsList, setMyMentorsList] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [selectedMyMentors, setSelectedMyMentors] = useState(null);
  const [contentType, setContentType] = useState("default");
  const [initialResourceUrl, setInitialResourceUrl] = useState(null);
  const [activeType, setActiveType] = useState("1");
  const [noticeList, setNoticeList] = useState([]);

  const handleSearch = (value) => {
    setSearchTerm(value);

    if (value.trim() === "") {
      setFilteredMyMentors([]);
    } else {
      const filtered = myMentorsList.filter((mentor) =>
        mentor.SUBJ.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredMyMentors(filtered);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const users = JSON.parse(localStorage.getItem("registeredUsers")) || [];

      if (customId) {
        const decodedData = JSON.parse(atob(customId));
        let matchedItem = [];

        users.forEach((innerArray) => {
          const [userData] = innerArray;

          if (
            userData.userId === decodedData.userId &&
            userData.userType === decodedData.userType &&
            userData.schoolId === decodedData.schoolId &&
            userData.year === decodedData.year
          ) {
            matchedItem.push(userData);
          }
        });

        const result = await getAdminScrollList(
          decodedData.userId,
          decodedData.schoolId,
          decodedData.userType,
          decodedData.year,
          activeType
        );

        if (result[0]?.ActionType === 1) {
          const noticeList = JSON.parse(result[0]?.JSONData1);

          setNoticeList(noticeList);
        }

        console.log("MENTORS RESULT", result);

        setLoading(false);
        setUserDetails(matchedItem);
      }
    };

    fetchData();
  }, [customId]);

  const { sidebarVisible, toggleSidebar } = useSidebar();

  const colors = [
    "#FF6633",
    "#FFB399",
    "#FF33FF",
    "#FFFF99",
    "#00B3E6",
    "#E6B333",
    "#3366E6",
    "#999966",
  ];
  let colorIndex = 0;

  const getColor = () => {
    const color = colors[colorIndex];
    colorIndex = (colorIndex + 1) % colors.length;
    return color;
  };

  const MentorsToRender = searchTerm ? filteredMyMentors : myMentorsList;

  return (
    <div className="container mx-auto p-4">
      {loading ? (
        <Spinner />
      ) : (
        <>
          <div className="flex items-center justify-between mb-4">
            <h1 className="md:text-3xl font-bold text-xl ">My Mentors</h1>
            <input
              type="text"
              placeholder="Search by title"
              value={searchTerm}
              onChange={(e) => handleSearch(e.target.value)}
              className="border border-gray-600 md:w-80  w-48 h-12 md:px-5 px-2 py-1 rounded-md"
            />
          </div>
          <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-4">
            {noticeList.map((item) => (
              <div
                key={item.NID}
                className="mb-6 bg-gradient-to-r from-blue-100 to-blue-50 p-5 rounded-lg shadow-lg relative hover:shadow-2xl transition-shadow duration-200 ease-in-out"
              >
                <h3 className="text-lg font-bold text-gray-900 mb-1">
                  {item.NRemarks}
                </h3>
                <p className="text-gray-600 mb-3">{item.NRemarks2}</p>

                <div className="text-sm text-gray-500">
                  <p>
                    <span className="font-semibold text-gray-800">Date:</span>{" "}
                    {new Date(item.NDate).toLocaleDateString()}
                  </p>
                  <p>
                    <span className="font-semibold text-gray-800">
                      Notice Link:
                    </span>{" "}
                    <a
                      href={item.NNoticeLink}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-600 hover:text-blue-800 transition-colors duration-200 underline"
                    >
                      {item.NNoticeLink}
                    </a>
                  </p>
                  <p>
                    <span className="font-semibold text-gray-800">Active:</span>{" "}
                    {item.IsActive ? (
                      <span className="text-green-500 font-medium">Yes</span>
                    ) : (
                      <span className="text-red-500 font-medium">No</span>
                    )}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default ScrollNoticeList;
