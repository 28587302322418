import { Viewer, Worker } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { useEffect, useState } from "react";

const PdfViewer = ({ url }) => {
  const [isValidPdf, setIsValidPdf] = useState(false);
  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  useEffect(() => {
    const checkPdf = async () => {
      try {
        const response = await fetch(url, { method: "HEAD" });
        if (!response.ok || response.headers.get("Content-Type") !== "application/pdf") {
          setIsValidPdf(false);
          // throw new Error("Invalid PDF");
        } else {
          setIsValidPdf(true);
        }
      } catch (error) {
        setIsValidPdf(false);
      }
    };

    checkPdf();
  }, [url]);

  return (
    <div className="pdfViewerContainer overflow-auto h-full md:h-[91%] w-full">
      {isValidPdf ? (
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
          <Viewer fileUrl={url} plugins={[defaultLayoutPluginInstance]} />
        </Worker>
      ) : (
        <div className="notFoundContainer flex items-center justify-center h-full">
          <h2 className="text-center text-red-500 text-lg font-workSans font-medium">Report is not yet updated</h2>
        </div>
      )}
    </div>
  );
};

export default PdfViewer;
