import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Webcam from "react-webcam";
import { createNoticeDataAPI } from "../../API/createNoticeAPI";
import ModalFunctionComponent from "../../components/ModalFunctionComponent";
import Navbar from "../../components/Navbar";
import Sidebar from "../../components/Sidebar";
import Spinner from "../../components/Spinner";
import { useGlobalContext } from "../../context/GlobalContext";
import { useSidebar } from "../../context/SidebarContext";

const CreateNotice = () => {
  const [attachments, setAttachments] = useState([]);
  const [pdfFiles, setPdfFiles] = useState([]);
  const [webcamActive, setWebcamActive] = useState(false);
  const [youtubeLink, setYoutubeLink] = useState("");
  const [description, setDescription] = useState("");
  const [userType, setUserType] = useState("");
  const [userDetails, setUserDetails] = useState(null);
  const [openTab, setOpenTab] = useState(1);
  const { globalVariable } = useGlobalContext();
  const [data, setData] = useState(true);
  let color = `text-white bg-gradient-to-r from-cyan-500 to-blue-500 hover:bg-gradient-to-bl focus:ring-2 focus:outline-none focus:ring-cyan-300 font-medium`;

  const [modalData, setModalData] = useState({
    totalCountParent: "",
    totalCountTeacher: "",
    totalSuccess: "",
    totalFailed: "",
    failedTokens: [],
    successTokens: [],
  });
  const [modal, setModal] = useState(false);
  const { customId } = useParams();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      if (customId) {
        const decodedData = JSON.parse(atob(customId));
        setUserDetails(decodedData);
      }
    };
    fetchData();
  }, [customId]);

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const handleYoutubeChange = (e) => {
    setYoutubeLink(e.target.value);
  };

  const onDrop = (acceptedFiles) => {
    const newAttachments = [...attachments, ...acceptedFiles];
    setAttachments(newAttachments);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "image/*, video/*",
    multiple: true,
  });

  const handlePdfFileChange = (e) => {
    const files = e.target.files;
    const pdfFilesArray = [...pdfFiles];
    for (let i = 0; i < files.length; i++) {
      if (files[i].type === "application/pdf") {
        pdfFilesArray.push(files[i]);
      } else {
        console.log("Unsupported file type");
      }
    }
    setPdfFiles(pdfFilesArray);
  };
  const handleResetAttachments = () => {
    setAttachments([]);
    setPdfFiles([]);
    setDescription("");
    setWebcamActive(false); // Disable webcam
  };

  const videoConstraints = {
    facingMode: "user",
  };

  const handleCapture = () => {
    setWebcamActive(true);
  };

  const handleCaptureClick = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    const capturedFile = dataURLtoFile(imageSrc, "capturedImage.png");
    setAttachments([...attachments, capturedFile]);
    setWebcamActive(false);
  };

  const webcamRef = React.useRef(null);

  const dataURLtoFile = (dataurl, filename) => {
    const arr = dataurl.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  };

  const handleSubmit = async () => {
    // Logic to handle form submission with all the data

    setLoading(true);

    if (attachments.length > 0) {
      for (let i = 0; i < attachments.length; i++) {
        const file = attachments[i];
        const reader = new FileReader();

        const fileName = file.name;

        const newFileName = fileName.replace(/\s/g, "");

        reader.onload = async (event) => {
          const fileBytes = event.target.result;
          const base64String = arrayBufferToBase64(fileBytes);

          if (file.type.startsWith("image")) {
            console.log(1);
            const contentType = "Image";
            const result = await createNoticeDataAPI(
              userDetails.userId,
              userDetails.schoolId,
              userDetails.userType,
              userDetails.year,
              newFileName,
              base64String,
              contentType,
              description,
              userType,
              "common"
            );
            setLoading(false);
            console.log(result, " resulser");
            toast.success(result[0]?.InfoField);
            setModal(true);
            setModalData({
              totalCountParent: result[0]?.InfoField2?.parent,
              totalCountTeacher: result[0]?.InfoField2?.teacher,
              totalSuccess: result[0]?.InfoField3?.totalSuccess,
              totalFailed: result[0]?.InfoField3?.totalFailed,
              failedTokens: result[0]?.InfoField3?.failedToken,
              successTokens: result[0]?.InfoField3?.successToken,
            });
            handleResetAttachments();
          } else if (file.type.startsWith("video")) {
            console.log(2);
            const contentType = "Video";
            const result = await createNoticeDataAPI(
              userDetails.userId,
              userDetails.schoolId,
              userDetails.userType,
              userDetails.year,
              newFileName,
              base64String,
              contentType,
              description,
              userType,
              "common"
            );
            setLoading(false);
            toast.success(result[0]?.InfoField);
            setModal(true);
            setModalData({
              totalCountParent: result[0]?.InfoField2?.parent,
              totalCountTeacher: result[0]?.InfoField2?.teacher,
              totalSuccess: result[0]?.InfoField3?.totalSuccess,
              totalFailed: result[0]?.InfoField3?.totalFailed,
              failedTokens: result[0]?.InfoField3?.failedToken,
              successTokens: result[0]?.InfoField3?.successToken,
            });
            handleResetAttachments();
            console.log("Result after creating notice Video:", result);
          }
        };

        reader.readAsArrayBuffer(file);
      }
    } else if (pdfFiles.length > 0) {
      for (let i = 0; i < pdfFiles.length; i++) {
        const file = pdfFiles[i];
        const reader = new FileReader();

        const fileName = file.name;

        const newFileName = fileName.replace(/\s/g, "");

        console.log("File Name with out spaces", newFileName);

        reader.onload = async (event) => {
          const fileBytes = event.target.result;
          const base64String = arrayBufferToBase64(fileBytes);
          console.log(description, "description submit notice");
          console.log(3);
          const contentType = "PDF";
          const result = await createNoticeDataAPI(
            userDetails.userId,
            userDetails.schoolId,
            userDetails.userType,
            userDetails.year,
            newFileName,
            base64String,
            contentType,
            description,
            userType,
            "common"
          );
          setLoading(false);
          handleResetAttachments();
          toast.success(result[0]?.InfoField);
          setModal(true);
          setModalData({
            totalCountParent: result[0]?.InfoField2?.parent,
            totalCountTeacher: result[0]?.InfoField2?.teacher,
            totalSuccess: result[0]?.InfoField3?.totalSuccess,
            totalFailed: result[0]?.InfoField3?.totalFailed,
            failedTokens: result[0]?.InfoField3?.failedToken,
            successTokens: result[0]?.InfoField3?.successToken,
          });
          console.log("Result after creating notice in PDF:", result);
        };

        reader.readAsArrayBuffer(file);
      }
    } else {
      const base64String = "";
      const contentType = "Youtube Link";
      console.log(4);
      const result = await createNoticeDataAPI(
        userDetails.userId,
        userDetails.schoolId,
        userDetails.userType,
        userDetails.year,
        youtubeLink,
        base64String,
        contentType,
        description,
        userType,
        "common"
      );
      setLoading(false);
      toast.success(result[0]?.InfoField);
      setModal(true);
      setModalData({
        totalCountParent: result[0]?.InfoField2?.parent,
        totalCountTeacher: result[0]?.InfoField2?.teacher,
        totalSuccess: result[0]?.InfoField3?.totalSuccess,
        totalFailed: result[0]?.InfoField3?.totalFailed,
        failedTokens: result[0]?.InfoField3?.failedToken,
        successTokens: result[0]?.InfoField3?.successToken,
      });
      console.log("Result after creating notice in Youtube Link:", result);
    }

    console.log("PDF Files:", pdfFiles);
    console.log("Description:", description);
    console.log("User Type:", userType);
  };

  const arrayBufferToBase64 = (buffer) => {
    let binary = "";
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  };

  const { isSidebarOpen, toggleSidebar } = useSidebar();

  return (
    <>
      {data && (
        <Navbar
          data={userDetails}
          // isOpen={sidebarVisible}
          toggleSidebar={toggleSidebar}
        />
      )}
      {process.env.REACT_APP_EDITING_MODE && modal && (
        <>
          <ModalFunctionComponent title={"Notification INFO"} close={setModal}>
            <div className="lg:mt-0 mt-24">
              <div className="container mx-auto md:p-1 md:containerBoxShadow">
                {loading ? (
                  <Spinner />
                ) : (
                  <>
                    <div>
                      <div className="rounded-md relative border-4 shadow-xl ">
                        <div className="md:containerBlock mx-auto pb-2 px-4">
                          <div className="flex flex-wrap -mx-4 rounded-lg pt-2">
                            <div className="flex justify-center w-full px-4 mb-4 md:mb-0">
                              <div className="flex flex-wrap justify-center w-full formBackground p-2">
                                <div className="flex flex-wrap justify-center gap-2 w-full">
                                  <div className="flex w-full my-1 mb-3 gap-1 flex-wrap">
                                    <div className="flex gap-1">
                                      <label
                                        htmlFor=""
                                        className="w-full max-w-36 font-montserrat whitespace-nowrap font-medium align-middle flex items-center text-sm text-start"
                                      >
                                        Total Parent Count
                                      </label>
                                      <h2 className="w-full font-montserrat px-3 py-1.5 border border-solid border-black bg-white min-w-14 text-xs sm:text-sm text-black font-medium rounded-md">
                                        {modalData?.totalCountParent}
                                      </h2>
                                    </div>
                                    <div className="flex gap-1">
                                      <label
                                        htmlFor=""
                                        className="w-full max-w-36 font-montserrat whitespace-nowrap font-medium align-middle flex items-center text-sm text-start"
                                      >
                                        Total Teacher Count
                                      </label>
                                      <h2 className="w-full font-montserrat px-3 py-1.5 border border-solid border-black bg-white min-w-14 text-xs sm:text-sm text-black font-medium rounded-md">
                                        {modalData?.totalCountTeacher}
                                      </h2>
                                    </div>
                                    <div className="flex gap-1">
                                      <label
                                        htmlFor=""
                                        className="w-full max-w-28 font-montserrat whitespace-nowrap font-medium align-middle flex items-center text-sm text-start"
                                      >
                                        Total Success
                                      </label>
                                      <h2 className="w-full font-montserrat px-3 py-1.5 border border-solid border-black bg-white min-w-14 text-xs sm:text-sm text-black font-medium rounded-md">
                                        {modalData?.totalSuccess}
                                      </h2>
                                    </div>
                                    <div className="flex gap-1">
                                      <label
                                        htmlFor=""
                                        className="w-full max-w-28 font-montserrat whitespace-nowrap font-medium align-middle flex items-center text-sm text-start"
                                      >
                                        Total Failed
                                      </label>
                                      <h2 className="w-full font-montserrat px-3 py-1.5 border border-solid border-black bg-white min-w-14 text-xs sm:text-sm text-black font-medium rounded-md">
                                        {modalData?.totalFailed}
                                      </h2>
                                    </div>
                                  </div>
                                </div>
                                <div className="flex flex-wrap px-4">
                                  <div className="flex flex-wrap w-full">
                                    <div className="w-full">
                                      <ul className="flex mb-0 list-none w-fit flex-wrap pb-4 flex-row" role="tablist">
                                        <li className="-mb-px w-fit mr-2 last:mr-0 flex-auto text-center">
                                          <a
                                            className={
                                              "text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal " +
                                              (openTab === 1 ? color : "text-black font-medium bg-white")
                                            }
                                            onClick={(e) => {
                                              e.preventDefault();
                                              setOpenTab(1);
                                            }}
                                            data-toggle="tab"
                                            href="#link1"
                                            role="tablist"
                                          >
                                            success tokens
                                          </a>
                                        </li>
                                        <li className="-mb-px w-fit mr-2 last:mr-0 flex-auto text-center">
                                          <a
                                            className={
                                              "text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal " +
                                              (openTab === 2 ? color : "text-black font-medium bg-white")
                                            }
                                            onClick={(e) => {
                                              e.preventDefault();
                                              setOpenTab(2);
                                            }}
                                            data-toggle="tab"
                                            href="#link2"
                                            role="tablist"
                                          >
                                            failed tokens
                                          </a>
                                        </li>
                                      </ul>
                                      <div className="relative flex flex-col min-w-0 break-words bg-white w-full shadow-lg rounded">
                                        <div className="flex-auto w-full">
                                          <div className="tab-content tab-space w-full">
                                            <div className={openTab === 1 ? "block" : "hidden"} id="link1">
                                              <div className="w-full">
                                                <div className="">
                                                  <div className="relative max-w-full max-h-[530px] border border-black shadow-md mx-auto sm:rounded-lg overflow-y-auto">
                                                    <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 border-collapse">
                                                      <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400 sticky top-0 z-10 h-10">
                                                        <tr className="tableHead">
                                                          <th scope="col" className=""></th>
                                                          <th scope="col" className="">
                                                            token
                                                          </th>
                                                        </tr>
                                                      </thead>
                                                      <tbody className="text-gray-900">
                                                        {modalData?.successTokens?.length > 0 &&
                                                          modalData?.successTokens?.map((student, index) => (
                                                            <>
                                                              <tr
                                                                key={index}
                                                                className={`text-gray-900 ${index % 2 === 0 ? "bg-white" : "bg-gray-100"}`}
                                                              >
                                                                <td className="p-1 border">{index + 1}</td>
                                                                <td className="p-1 border">{student?.token}</td>
                                                              </tr>
                                                            </>
                                                          ))}
                                                      </tbody>
                                                    </table>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div className={openTab === 2 ? "block" : "hidden"} id="link2">
                                              <div className="w-full">
                                                <div className="">
                                                  <div className="relative max-w-full max-h-[530px] border border-black shadow-md mx-auto sm:rounded-lg overflow-y-auto">
                                                    <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 border-collapse">
                                                      <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400 sticky top-0 z-10 h-10">
                                                        <tr className="tableHead">
                                                          <th scope="col" className=""></th>
                                                          <th scope="col" className="">
                                                            token
                                                          </th>
                                                          <th scope="col" className="">
                                                            message
                                                          </th>
                                                        </tr>
                                                      </thead>
                                                      <tbody className="text-gray-900">
                                                        {modalData?.failedTokens?.length > 0 &&
                                                          modalData?.failedTokens?.map((student, index) => (
                                                            <>
                                                              <tr
                                                                key={index}
                                                                className={`text-gray-900 ${index % 2 === 0 ? "bg-white" : "bg-gray-100"}`}
                                                              >
                                                                <td className="p-1 border">{index + 1}</td>
                                                                <td className="p-1 border">{student?.token}</td>
                                                                <td className="p-1 border">{student?.msg}</td>
                                                              </tr>
                                                            </>
                                                          ))}
                                                      </tbody>
                                                    </table>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </ModalFunctionComponent>
        </>
      )}

      {loading ? (
        <Spinner />
      ) : (
        <div className="flex">
          {data && <Sidebar data={userDetails} />}

          <div
            className={`w-full absolute ${
              isSidebarOpen ? "lg:w-4/6 xl:w-9/12" : "w-full"
            } sm:w-11/12 right-0 top-16 mx-auto   p-4 md:p-6 lg:p-8 xl:p-10`}
          >
            <div className="flex items-center justify-between">
              <h1 className="md:text-3xl text-xl font-semibold mb-4">Create New Notice{globalVariable}</h1>
              <button onClick={handleResetAttachments} className="bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded">
                Reset Attachments
              </button>
            </div>

            <div {...getRootProps()} className="border border-gray-300 rounded-md p-4 mb-4">
              <h2 className="text-lg font-semibold mb-2">Upload Images or Videos</h2>
              <input {...getInputProps()} />
              {attachments.map((file) => (
                <div key={file.name} className="mb-2">
                  {file.type.startsWith("image") ? (
                    <img src={URL.createObjectURL(file)} alt={file.name} className="max-w-full h-auto" />
                  ) : (
                    <video src={URL.createObjectURL(file)} controls className="max-w-full h-auto"></video>
                  )}
                </div>
              ))}
            </div>
            <div className="border border-gray-300 rounded-md p-4 mb-4">
              <h2 className="text-lg font-semibold mb-2">Upload PDF</h2>
              <input type="file" accept="application/pdf" onChange={handlePdfFileChange} multiple />
              {pdfFiles.map((pdfFile, index) => (
                <div key={index} className="mb-2">
                  <p>{pdfFile.name}</p>
                </div>
              ))}
            </div>
            <div className="border border-gray-300 rounded-md p-4 mb-4">
              <h2 className="text-lg font-semibold mb-2">Capture Image from Webcam</h2>
              <button onClick={handleCapture} className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded">
                Capture
              </button>
              {webcamActive && (
                <div>
                  <Webcam audio={false} ref={webcamRef} screenshotFormat="image/png" videoConstraints={videoConstraints} />
                  <button onClick={handleCaptureClick} className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded">
                    Take Snapshot
                  </button>
                </div>
              )}
            </div>

            <div className="mb-2">
              <label className="block text-gray-700 font-semibold mb-2">Enter Remark Message Here</label>
              <textarea
                value={description}
                onChange={handleDescriptionChange}
                placeholder="Enter notice description here..."
                className="border border-gray-300 rounded px-3 py-2 w-full focus:outline-none"
                rows="5"
              ></textarea>
            </div>
            <div className="mb-2">
              <label className="block text-gray-700 font-semibold mb-2">Enter Youtube Link</label>
              <input
                value={youtubeLink}
                onChange={handleYoutubeChange}
                placeholder="Enter Youtube Link here..."
                className="border border-gray-300 rounded px-3 py-2 w-full focus:outline-none"
                rows="5"
              ></input>
            </div>

            <select
              className="bg-gray-100 text-gray-900 focus:outline-none focus:shadow-outline border border-gray-300 rounded py-2 px-4 block w-full"
              onChange={(e) => setUserType(e.target.value)}
              required
            >
              <option hidden disabled selected value="">
                Notice To
              </option>
              <option value="Parents">Parents</option>
              <option value="Teachers">Teachers</option>
              <option value="Parents and Teachers">Parents and Teachers</option>
            </select>

            <div className="flex justify-center items-center">
              <button
                onClick={handleSubmit}
                className="bg-green-500 mt-3 hover:bg-green-600 text-white font-semibold px-4 py-2 rounded shadow-md focus:outline-none"
              >
                Submit Notice
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CreateNotice;
