import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { getUserDataFromLocalByKey } from "../../API/getStudentInfoByKeyLocal";
import Spinner from "../../components/Spinner";
import useSidebar from "../../hooks/useSidebar";
import { getStudentFullDataFromLocal } from "../../API/getDataFromLocalStorage";
import { getStuAbsentList } from "../../API/getStudentWiseAbsentListAPI";
function StuLeaveDays() {
    const { customId } = useParams();
  const [userDetails, setUserDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [initialResourceUrl, setInitialResourceUrl] = useState(null);
  const [studentInfo, setStudentInfo] = useState([]);
  const [stuAbsentList, setStuAbsentList] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const users = JSON.parse(localStorage.getItem("registeredUsers")) || [];

      if (customId) {
        const decodedData = JSON.parse(atob(customId));
        let matchedItem = [];

        users.forEach((innerArray) => {
          const [userData] = innerArray;

          if (
            userData.userId === decodedData.userId &&
            userData.userType === decodedData.userType &&
            userData.schoolId === decodedData.schoolId &&
            userData.year === decodedData.year
          ) {
            matchedItem.push(userData);
          }
        });

        const result = await getStudentFullDataFromLocal(
            decodedData.userId,
            decodedData.schoolId,
            decodedData.userType,
            decodedData.year
          );

          const AbsentResult = await getStuAbsentList(
            decodedData.userId,
            decodedData.schoolId,
            decodedData.userType,
            decodedData.year
          );



        const keyval = "webResourceURL";
        const dataUrl = await getUserDataFromLocalByKey(
          decodedData.userId,
          decodedData.schoolId,
          decodedData.userType,
          decodedData.year,
          keyval
        );
        setInitialResourceUrl(dataUrl);
        setStudentInfo(result);
        setStuAbsentList(AbsentResult);
        setLoading(false);
        setUserDetails(matchedItem);
      }
    };

    fetchData();
  }, [customId]);

  // console.log(stuAbsentList);

  const { sidebarVisible, toggleSidebar } = useSidebar();


  const studentName =
    studentInfo.find((item) => item.ItemKeyName === "SNAME")?.InfoField || "";
    return ( 
        <div className="container mx-auto p-4">
      {loading ? (
        <Spinner />
      ) : (
        <>
        <div className="flex items-center justify-between mb-4">
            <div className="w-2/3">
              <h2 className=" md:text-2xl font-bold text-xl mb-2 md:mb-0">
                Student : {studentName}
              </h2>
              <h4 className="mb-2 font-bold">Leave Days List</h4>
            </div>
          </div>

          <div className="w-4/5   shadow-md shadow-gray-400 rounded-md mt-28 lg:mt-12 bg-white mx-auto ">
                  <div className="w-full bg-blue-700 text-white font-semibold text-xl  text-center px-3 py-4">
                    Leave Days List
                  </div>

                  <div className="table-container  lg:max-h-96 overflow-y-auto">
            <table className="w-full table-auto border-collapse border border-gray-300">
              <thead className="sticky top-0 text-left bg-blue-500 text-white">
                <tr>
                  <th className=" border p-2">Sl.No</th>
                  <th className=" border p-2">Leave Date</th>
                  {/* Add more columns as needed */}
                </tr>
              </thead>
              <tbody>
                {stuAbsentList.map((item, index) => (
                  <tr
                    key={index}
                    className="bg-gray-300"
                  >
                    <td className="border p-2">{item.Slno}</td>
                    <td className="border p-2">{item.Head1}</td>
                    {/* Add more columns as needed */}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
                </div>
        </>
      )}
      </div>

     );
}

export default StuLeaveDays;