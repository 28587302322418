import axios from "axios";

export const registerStudentUsers = async (userType, schoolId, userId, year, preRegisterUrl) => {
  try {
    const formData = new FormData();
    formData.append("title", "RegisterStudent");
    formData.append("description", "");
    formData.append("ReqAcastart", year);
    formData.append("ReqUserID", userId);
    formData.append("ReqPhoneCode", "---");

    let userdata = [];

    const Corskey = process.env.REACT_APP_CORS_URL;

    const result = await axios.post(`${Corskey}${preRegisterUrl}`, formData);

    userdata = JSON.parse(result.data.substring(0, result.data.indexOf("||JasonEnd", 0)));

    return userdata;
  } catch (error) {
    console.error("An error occurred:", error);
    // Handle error here
  }
  // Placeholder JSX, replace with actual JSX
};
