import React, { useState, useEffect } from "react";
import Navbar from "../../components/Navbar";
import Sidebar from "../../components/Sidebar";
import { useSidebar } from "../../context/SidebarContext";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import Spinner from "../../components/Spinner";
import { getUserDataFromLocalByKey } from "../../API/getStudentInfoByKeyLocal";
import personicon from "../../assets/png/person-icon.png";
import { getAdminStudentDetailsList } from "../../API/getAdminStudentDetailsListAPI";
function AdminCDStuDetails() {
  const [userType, setUserType] = useState("");
  const [userDetails, setUserDetails] = useState(null);
  const [data, setData] = useState(true);
  const { customId, className, sec } = useParams();
  const [studentList, setStudentList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [initialResourceUrl, setInitialResourceUrl] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredStudents, setFilteredStudents] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const users = JSON.parse(localStorage.getItem("registeredUsers")) || [];

      if (customId) {
        const decodedData = JSON.parse(atob(customId));
        let matchedItem = [];

        users.forEach((innerArray) => {
          const [userData] = innerArray;

          if (
            userData.userId === decodedData.userId &&
            userData.userType === decodedData.userType &&
            userData.schoolId === decodedData.schoolId &&
            userData.year === decodedData.year
          ) {
            matchedItem.push(userData);
          }
        });

        const result = await getAdminStudentDetailsList(
          decodedData.userId,
          decodedData.schoolId,
          decodedData.userType,
          decodedData.year,
          className,
          sec
        );

        const keyval = "webResourceURL";
        const dataUrl = await getUserDataFromLocalByKey(
          decodedData.userId,
          decodedData.schoolId,
          decodedData.userType,
          decodedData.year,
          keyval
        );
        setInitialResourceUrl(dataUrl);
        setLoading(false);
        setStudentList(result);
        setUserDetails(matchedItem);
      }
    };

    fetchData();
  }, [customId]);

  //console.log(studentList);

  const handleSearch = (value) => {
    setSearchTerm(value);

    // If the search term is empty, reset filtered notifications
    if (value.trim() === "") {
      setFilteredStudents([]);
    } else {
      // Filter notifications based on the search term
      const filtered = studentList.filter((item) => item.SNAME.toLowerCase().includes(value.toLowerCase()));
      setFilteredStudents(filtered);
    }
  };

  const studentsToRender = searchTerm ? filteredStudents : studentList;

  const { isSidebarOpen, toggleSidebar } = useSidebar();

  return (
    <>
      {data && (
        <Navbar
          data={userDetails}
          // isOpen={sidebarVisible}
          toggleSidebar={toggleSidebar}
        />
      )}
      <div className="flex">
        {data && <Sidebar data={userDetails} />}

        {loading ? (
          <>
            <Spinner />
          </>
        ) : (
          <div
            className={`w-full absolute ${
              isSidebarOpen ? "lg:w-4/6 xl:w-9/12" : "w-full"
            } sm:w-11/12 right-0 top-16 mx-auto   p-4 md:p-6 lg:p-8 xl:p-10`}
          >
            <div className="md:flex items-center justify-between mb-4">
              <div className="w-2/3">
                <h2 className=" md:text-2xl font-bold text-xl mb-2 md:mb-0">
                  Admin :{userDetails && userDetails.length > 0 && userDetails[0].schoolId}
                </h2>
                <h4 className="mb-2 font-bold">
                  Class Wise Student List : - {className}-{sec}
                </h4>
              </div>
              <div className=" w-2/6">
                <input
                  type="text"
                  placeholder="Search..."
                  value={searchTerm}
                  onChange={(e) => handleSearch(e.target.value)}
                  className="border border-gray-300 px-3 py-2 rounded-md mr-2 focus:border-blue-500 focus:outline-none"
                />
              </div>
            </div>

            <div className="bg-white p-6 shadow-md rounded-md overflow-y-auto max-h-screen">
              {/* Display the content for the specific tab */}
              {studentsToRender?.map((item, index) => {
                let ur = `${process.env.REACT_APP_ERP_STUDENT_IMAGE_URL}${item?.PHOTOFILE}`;
                return (
                  <div key={index} className=" lg:flex items-center border border-gray-300 mb-2">
                    <img
                      src={
                        item.PHOTOFILE === "noPhoto.jpg" && (item?.PHOTOFILE === undefined || item?.PHOTOFILE === null) ? personicon : ur
                      }
                      alt={item.PHOTOFILE}
                      className="w-20 h-16 object-cover rounded-full mr-4"
                    />
                    <div className="ml-2">
                      <h3 className="text-lg font-semibold mb-2">{item.SNAME}</h3>
                      <p className=" text-gray-400">{item.ADMNO}</p>
                      <p className=" text-gray-400">
                        {item.CLNAME}-{item.SECNAME}
                      </p>
                      <p className="text-gray-400">{item.FATHERNAME}</p>
                      <p className="text-gray-400 mb-2">{item.SMSPHONE}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default AdminCDStuDetails;
