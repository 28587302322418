import "datatables.net-buttons-dt/css/buttons.dataTables.css";
import "datatables.net-buttons-dt/css/buttons.dataTables.min.css";
import "datatables.net-buttons-dt/js/buttons.dataTables.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import "datatables.net-buttons/js/dataTables.buttons.js";
import DataTable from "datatables.net-dt";
import "datatables.net-dt/css/dataTables.dataTables.css";
import "datatables.net-responsive-dt";
import "datatables.net-scroller";
import "datatables.net-scroller-dt/css/scroller.dataTables.css";
import "datatables.net-scroller-dt/css/scroller.dataTables.min.css";
import "datatables.net-scroller-dt/js/scroller.dataTables.js";
import "datatables.net-scroller/js/dataTables.scroller.js";
import "datatables.net-select/js/dataTables.select.js";

import JSZip from "jszip";
import PDFMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

import React, { useCallback, useEffect, useRef, useState } from "react";

import { useLocation, useNavigate, useParams } from "react-router-dom";
import { GetERPClassStrengthClasswise } from "../../API/GetERPClassStrengthClasswise";

window.JSZip = JSZip;
PDFMake.vfs = pdfFonts.pdfMake?.vfs;

const columns = [
  //   { index: 0, title: "Select", data: "select", visible: true },
  { index: 1, title: "CLass Teacher", data: "CLassTeacher", visible: true },
  { index: 1, title: "CLass", data: "dbName", visible: true },
  { index: 2, title: "Total", data: "Total", visible: true },
  { index: 4, title: "Present", data: "present", visible: true },
  { index: 3, title: "Absent", data: "absent", visible: true },
  //   { index: 4, title: "", data: "pl", visible: true },
  { index: 4, title: "", data: "al", visible: true },
];

const ErpClassStrengthPage = () => {
  const location = useLocation();
  const classID = location.state.classID;
  const navigate = useNavigate();
  const { customId } = useParams();
  const [loading, setLoading] = useState(false);
  const [userDetails, setUserDetails] = useState(null);

  const [erpClassList, setErpClassList] = useState([]);


  useEffect(() => {
    // setLoading(true)
    const fetchData = async () => {
      setLoading(true);
      const users = JSON.parse(localStorage.getItem("registeredUsers")) || [];

      if (customId) {
        const decodedData = JSON.parse(atob(customId));
        let matchedItem = [];

        users.forEach((innerArray) => {
          const [userData] = innerArray;

          if (
            userData.userId === decodedData.userId &&
            userData.userType === decodedData.userType &&
            userData.schoolId === decodedData.schoolId &&
            userData.year === decodedData.year
          ) {
            matchedItem.push(userData);
          }
        });
        // handleClassAndSectionFetch(
        //   decodedData.userId,
        //   decodedData.schoolId,
        //   decodedData.userType,
        //   decodedData.year
        // );
        setUserDetails(matchedItem);

        setLoading(false);
      }
    };

    fetchData();
  }, [customId]);

  const erpClassWiseFetch = useCallback(async () => {
    if (userDetails) {
        
        const response = await GetERPClassStrengthClasswise(userDetails[0].year,classID);
        setErpClassList(response);
    }
  }, [classID,userDetails]);

  const [tableCol, setTableCol] = useState(columns);
  let tableRef = useRef(null);
  let tableInstance = useRef(null);

  useEffect(() => {
    if (classID) erpClassWiseFetch();
  }, [classID, erpClassWiseFetch,userDetails]);

  window.handleShowDetails = (logourl, divsn) => {
    // a function on datatable
    navigate(`/admin/u/${customId}/QuickInfo/erp_class_details_page`, {
      state: {
        classID: logourl,
        sectionID: divsn,
      },
    });
  };

  const TableData2 = erpClassList?.map((data) => ({
    CLassTeacher: data.CLassTeacher,
    Logourl: data.Logourl,
    present: data.NofStdA,
    Total: data.NofStd,
    absent: data.NofStdS,
    SlCODE: data.SLCODE,
    Code: data.Code,
    SlNo: data.Slno,
    dbName: data.dbname,
    al: `<p
            onClick="handleShowDetails('${data.Logourl}','${data?.Location}')"
            class='cursor-pointer buttonClass !w-fit mx-auto font-poppins text-sm font-medium capitalize'
          >
            View Details
          </p>`,
    // pl: `<p
    //         onClick="handleShowDetails('${data.Location}')"
    //         class='text-blue-500 cursor-pointer text-sm font-medium capitalize'
    //       >
    //         Present List
    //       </p>`,
    // select: `<input type="radio" className="radioButton" name="group1" />`,
  }));

  useEffect(() => {
    // Initialize DataTable when the component mounts
    if (tableCol && TableData2) {
      tableInstance.current = new DataTable("#studentDetails", {
        responsive: false,
        // dom: "Bfrtip",
        layout: {
          topStart: {
            buttons: [
              "excel",
              "csv",
              "xls",
              "pdf",
              {
                extend: "print",
                exportOptions: {
                  columns: ":visible",
                },
              },
              "colvis",
            ],
          },
        },
        select: false,
        info: "",
        paging: false,
        scrollY: false,
        scrollX: true,
        order: [[1, "asc"]],
        deferRender: true,
        // scroller: true,
        data: TableData2,
        columns: tableCol,
      });
    }
    // Cleanup DataTable instance when the component unmounts
    return () => {
      if (tableInstance.current !== null) {
        tableInstance.current.destroy();
      }
    };
  }, [TableData2, tableCol]);

  return (
    <div className="lg:mt-0 mt-5">
      <div className="container mx-auto p-2 md:p-4 containerBoxShadow">
        <div className="flex mb-2 justify-start w-full rounded-md bg-gradient-to-r from-cyan-400 to-blue-400 hover:bg-gradient-to-bl">
          <h2 className="w-full text-center text-2xl p-0 my-auto uppercase text-black font-mono font-semibold">
            Class Strength of {classID}
          </h2>
        </div>
        <div className="relative max-h-full overflow-x-auto border md:block hidden border-black border-solid rounded-sm">
          {erpClassList && erpClassList?.length > 0 ? (
            <table
              ref={tableRef}
              id="studentDetails"
              style={{ width: "100%" }}
              className="h-full min-h-full w-fit max-h-[500px] responsive extraCSS display nowrap dataTable_wrapper"
            ></table>
          ) : (
            <div className="h-auto p-5">
              <h1 className="text-2xl font-bold">Record Not Found</h1>
            </div>
          )}
        </div>
        <div className="overflow-auto w-full md:hidden block">
          {erpClassList?.map((student, index) => {
            return (
              <div
                className="border-2 table-bg-employeeClass rounded-lg mb-4 w-full shadow-lg overflow-hidden"
                key={index}
              >
                <div className="flex students-center justify-between tabButton py-2 px-4 rounded-t-lg shadow-md">
                  <span className="font-bold"> {student?.CLassTeacher}</span>
                </div>

                <table className="w-full table-auto">
                  <tbody>
                    <tr>
                      <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                        <strong>Class</strong>
                      </td>
                      <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                        {student?.SLCODE}
                      </td>
                    </tr>
                    <tr>
                      <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                        <strong>Total</strong>
                      </td>
                      <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                        {student?.NofStd}
                      </td>
                    </tr>
                    <tr>
                      <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                        <strong>Present</strong>
                      </td>
                      <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                        {student?.NofStdA}
                      </td>
                    </tr>
                    <tr>
                      <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                        <strong>Absent</strong>
                      </td>
                      <td className="border border-solid border-gray-400 text-start capitalize p-2 w-1/2">
                        {student?.NofStdS}
                      </td>
                    </tr>
                    <tr>
                      <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                        <strong></strong>
                      </td>
                      <td className="border border-solid border-gray-400 text-start capitalize p-2 w-1/2">
                        <button
                          onClick={() => {
                            navigate(
                              `/admin/u/${customId}/QuickInfo/erp_class_details_page`,
                              {
                                state: {
                                  classID: student?.Logourl,
                                  sectionID: student?.Location,
                                },
                              }
                            );
                          }}
                          className="cursor-pointer buttonClass !w-fit mx-auto font-poppins text-sm font-medium capitalize"
                        >
                          View Details
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ErpClassStrengthPage;
