import axios from "axios";
import { getUserDataFromLocalByKey } from "./getStudentInfoByKeyLocal";

export const updateCTNoticeBoardAPI = async (userId, schoolId, userType, year, filedata, remarks, NoticeType) => {
  try {
    const key = "webServiceURLAWSDOTNET";
    const classKey = "EMPCLASS";

    console.log(filedata);

    const returnUrl = await getUserDataFromLocalByKey(userId, schoolId, userType, year, key);
    const className = await getUserDataFromLocalByKey(userId, schoolId, userType, year, classKey);

    let Class;
    let SecName;

    if (className) {
      const [classNumber, classSection] = className.split("-");
      Class = classNumber;
      SecName = classSection;
    }

    const SMSType = process.env.REACT_APP_SMS_TYPE;

    const formData = new FormData();
    formData.append("title", "UpdateNoticeBoard");
    formData.append("description", "");
    formData.append("HWID", "");
    formData.append("HWClass", Class || "");
    formData.append("HWSection", SecName || "");
    formData.append("HWSubject", "");
    formData.append("HWRemarks", remarks);
    formData.append("ReqAcastart", year);
    formData.append("AddedBy", userId);
    formData.append("ReqSMSType", SMSType);
    formData.append("ReqNoticeType", NoticeType);

    filedata.forEach((fileInfo) => {
      const fileType = fileInfo.fileType;

      formData.append(`PhotoLocation`, fileInfo.newFileName);
      formData.append(`imagestring`, fileInfo.base64String);

      if (fileType === "image") {
        formData.append("MediaType", fileType);
      } else if (fileType === "PDF") {
        formData.append("MediaType", fileType);
      } else {
        formData.append("MediaType", fileType);
      }
    });

    let userdata = [];
    const Corskey = process.env.REACT_APP_CORS_URL;
    const result = await axios.post(`${Corskey}${returnUrl}`, formData);
    userdata = JSON.parse(result.data.substring(0, result.data.indexOf("||JasonEnd", 0)));
    return userdata;
  } catch (error) {
    console.error("An error occurred:", error);
  }
};
