import React from "react";
import axios from "axios";
import toast from "react-toastify";
import { getUserDataFromLocalByKey } from "./getStudentInfoByKeyLocal";


export const getCTAddGalleryDetailsList  = async (userId,schoolId, userType, year, Classname, SectioName, Subject ) => {
 

  try {

    const key='webServiceURLAWSDOTNET'

   const returnUrl= await getUserDataFromLocalByKey(userId,schoolId,userType, year,key)

   
   
     





  

   const formData = new FormData();
   formData.append("title","StudyMaterialsTeacher");
   formData.append("Rdescription", "");
   formData.append("ReqClass", Classname);
   formData.append("ReqSection", SectioName);
   formData.append("ReqYear", year);
   formData.append("ReqPara1", Subject);
   formData.append("ReqAdmNo", userId);
   
   

    let userdata = [];

    const Corskey=process.env.REACT_APP_CORS_URL;

    // const result = await axios.post(
    //   "https://cors-anywhere.herokuapp.com/http://apkarnold.newgentechnology.net/WebDataProcessingServer.aspx",
    //   formData
    // );

    const result = await axios.post(
      `${Corskey}${returnUrl}`,
      formData
    );

    userdata = JSON.parse(
      result.data.substring(0, result.data.indexOf("||JasonEnd", 0))
    );

    return userdata;

  
  } catch (error) {
    console.error("An error occurred:", error);
    // Handle error here
  }
  // Placeholder JSX, replace with actual JSX
};
