import React, { useState, useEffect } from "react";
import Navbar from "../../components/Navbar";
import Sidebar from '../../components/Sidebar';
import { useSidebar } from "../../context/SidebarContext";

import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import Spinner from "../../components/Spinner";
import paymentIcon from '../../assets/Dashboard/paymentmethod.svg';
import { getUserDataFromLocalByKey } from "../../API/getStudentInfoByKeyLocal";
import { getClassSelectionList } from "../../API/getClassSelectionListAPI";
function AdminClassDetailsSectionList() {
  const [userType, setUserType] = useState("");
  const [userDetails, setUserDetails] = useState(null);
  const [data, setData] = useState(true);
  const { customId, className } = useParams();
  const [sectionList, setSectionList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [initialResourceUrl, setInitialResourceUrl] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const users = JSON.parse(localStorage.getItem("registeredUsers")) || [];

      if (customId) {
        const decodedData = JSON.parse(atob(customId));
        let matchedItem = [];

        users.forEach((innerArray) => {
          const [userData] = innerArray;

          if (
            userData.userId === decodedData.userId &&
            userData.userType === decodedData.userType &&
            userData.schoolId === decodedData.schoolId &&
            userData.year === decodedData.year
          ) {
            matchedItem.push(userData);
          }
        });

        const result = await getClassSelectionList(
            decodedData.userId,
            decodedData.schoolId,
            decodedData.userType,
            decodedData.year
          );

        const keyval = "webResourceURL";
        const dataUrl = await getUserDataFromLocalByKey(
          decodedData.userId,
          decodedData.schoolId,
          decodedData.userType,
          decodedData.year,
          keyval
        );
        setInitialResourceUrl(dataUrl);
        setSectionList(result);
        setLoading(false);
        setUserDetails(matchedItem);
      }
    };

    fetchData();
  }, [customId]);

//  console.log(sectionList);
//

const { isSidebarOpen, currentSidebarTab, toggleSidebar,setIsSidebarOpen } = useSidebar();

  const filteredSections = sectionList.slice(1).filter(section => section.Head1 === className && section.Head2);

  return (
    <>
      {data && (
        <Navbar
          data={userDetails}
          // isOpen={sidebarVisible}
          toggleSidebar={toggleSidebar}
        />
      )}

      <div className="flex">
        {data && <Sidebar data={userDetails} />}
        <div className={`w-full absolute ${isSidebarOpen?"lg:w-4/6 xl:w-9/12":"w-full"} sm:w-11/12 right-0 top-16 mx-auto   p-4 md:p-6 lg:p-8 xl:p-10`}>
          <div className="flex items-center justify-between mb-4">
            <div className="w-2/3">
              <h2 className=" md:text-2xl font-bold text-xl mb-2 md:mb-0">
                Admin :
                {userDetails &&
                  userDetails.length > 0 &&
                  userDetails[0].schoolId}
              </h2>
              <h4 className="mb-2 font-bold">Class Section List </h4>
            </div>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
            {/* Loop through filtered sections and create a box for each item */}
            {filteredSections.map((section, index) => {
              const sectionsArray = section.Head2.split(',').map(s => s.trim());
              return (
                sectionsArray.map((sec, idx) => (
                  <Link key={`${index}-${idx}`} to={`/admin/u/${customId}/${className}/${sec}/ClassTeacherDashBoard`}>
                    <div className="bg-gray-100 border border-gray-300 cursor-pointer p-4 rounded-md flex flex-col items-center justify-center" style={{
                      boxShadow: "5px 5px 12px 0px rgba(173, 216, 230, 0.9)",
                    }}>
                      {/* Customize the content based on your data */}
                      <img src={paymentIcon} alt="Icon" className="w-12 h-12 mb-2" />
                      <h3 className="text-lg font-semibold text-center">{className}-{sec}</h3>
                      {/* Add more details or customize as needed */}
                    </div>
                  </Link>
                ))
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}

export default AdminClassDetailsSectionList;
