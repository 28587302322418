import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import useSidebar from "../../hooks/useSidebar";
import plusicon from "../../assets/png/plusicon.png";
import pencilIcon from "../../assets/jpg/pencileIcon.jpg";
import { getUserDataFromLocalByKey } from "../../API/getStudentInfoByKeyLocal";

import { getAdminScrollList } from "../../API/getAdminScrollNoticeList";

const ScrollNoticeList = () => {
  const { customId } = useParams();
  const [searchTerm, setSearchTerm] = useState("");
  const [userDetails, setUserDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [noticeList, setNoticeList] = useState([]);
  const [initialResourceUrl, setInitialResourceUrl] = useState(null);
  const [activeType, setActiveType] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const users = JSON.parse(localStorage.getItem("registeredUsers")) || [];

      if (customId) {
        const decodedData = JSON.parse(atob(customId));
        let matchedItem = [];

        users.forEach((innerArray) => {
          const [userData] = innerArray;

          if (
            userData.userId === decodedData.userId &&
            userData.userType === decodedData.userType &&
            userData.schoolId === decodedData.schoolId &&
            userData.year === decodedData.year
          ) {
            matchedItem.push(userData);
          }
        });

        const result = await getAdminScrollList(
          decodedData.userId,
          decodedData.schoolId,
          decodedData.userType,
          decodedData.year,
          activeType
        );

        console.log(result[0], 646645);

        if (result[0]?.ActionType === 1) {
          const noticeList = JSON.parse(result[0]?.JSONData1);

          setNoticeList(noticeList);
        }

        const keyval = "webResourceURL";
        const dataUrl = await getUserDataFromLocalByKey(
          decodedData.userId,
          decodedData.schoolId,
          decodedData.userType,
          decodedData.year,
          keyval
        );
        setInitialResourceUrl(dataUrl);

        console.log("URL RESULT", dataUrl);

        setLoading(false);
        setUserDetails(matchedItem);
      }
    };

    fetchData();
  }, [customId, activeType]);

  const handleType = (e) => {
    setActiveType(e.target.value);
  };

  const handlePencilIconClick = (item) => {
    const urlBase = `/admin/u/${customId}/`;
    const queryParams = [];

    // Check and add parameters based on conditions (encoded)
    queryParams.push(item?.NID ? encodeURIComponent(item?.NID) : "null");

    queryParams.push("scrollNoticeUpdate");
    const url = urlBase + queryParams.join("/");

    navigate(url);
  };

  return (
    <>
     <div className="md:mt-6 mt-10">
        <div className="p-2 mb-3 flex items-center w-fit flex-wrap">
          <ul className="flex items-center">
            <li className="inline-flex items-center cursor-default">
              <a href="/" className="text-black font-medium hover:scale-105">
                <svg
                  className="w-5 cursor-default h-auto fill-current mx-2"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="#000000"
                >
                  <path d="M0 0h24v24H0V0z" fill="none" />
                  <path d="M10 19v-5h4v5c0 .55.45 1 1 1h3c.55 0 1-.45 1-1v-7h1.7c.46 0 .68-.57.33-.87L12.67 3.6c-.38-.34-.96-.34-1.34 0l-8.36 7.53c-.34.3-.13.87.33.87H5v7c0 .55.45 1 1 1h3c.55 0 1-.45 1-1z" />
                </svg>
              </a>

              <span className="mx-4 h-auto font-medium">/</span>
            </li>
            <li className="inline-flex items-center">
              <p className="text-black font-medium cursor-default hover:scale-105">
                Poll & List
              </p>
              {/* <span className="mx-4 h-auto text-gray-400 font-medium">/</span> */}
            </li>
          </ul>
        </div>
        <div className="container mx-auto p-4 containerBoxShadow">
          <div className="flex flex-col  mb-4">
            <h3 className="font-bold text-xl mb-2 md:mb-0">
              Admin :
              {userDetails && userDetails.length > 0 && userDetails[0].schoolId}
            </h3>
          </div>
          <h3 className="text-3xl font-semibold mb-4">Poll</h3>
          <Link
            to={`/admin/u/${customId}/${"0"}/scrollNoticeUpdate`}
            onClick={""}
          >
            <div
              className="border border-gray-300 cursor-pointer rounded-lg p-4 hover:shadow-md transition duration-300"
              style={{
                boxShadow: "5px 5px 12px 0px rgba(173, 216, 230, 0.9)",
              }}
            >
              <div className="flex items-center">
                <div className="w-1/3 sm:w-auto mr-4">
                  <img
                    src={plusicon}
                    alt="icon"
                    className="w-20 h-20 sm:w-15 sm:h-15 object-cover rounded"
                  />
                </div>

                <div className="w-2/3">
                  <h3 className="text-md font-semibold mb-2">
                    Click to Add New Poll
                  </h3>
                </div>
              </div>
            </div>
          </Link>

          <div className="bg-white p-6 shadow-lg mt-4 rounded-lg overflow-y-auto md:max-h-96">
            <div className="md:w-1/3 mb-4">
              <label
                className="block text-gray-800 text-sm font-bold mb-1"
                htmlFor="IsActive"
              >
                Is Active
              </label>
              <select
                id="IsActive"
                onChange={handleType}
                name="IsActive"
                className="shadow-sm border border-gray-300 rounded-lg w-full py-2 px-3 text-gray-700 focus:ring-blue-500 focus:border-blue-500 focus:outline-none transition duration-200 ease-in-out"
              >
                <option value="">All</option>
                <option value="1">Yes</option>
                <option value="0">No</option>
              </select>
            </div>

            {noticeList.map((item) => (
              <div
                key={item.NID}
                className="mb-6 bg-gradient-to-r from-blue-100 to-blue-50 p-5 rounded-lg shadow-lg relative hover:shadow-2xl transition-shadow duration-200 ease-in-out"
              >
                <img
                  src={pencilIcon}
                  alt="Edit"
                  className="absolute top-4 right-4 w-6 h-6 cursor-pointer hover:scale-110 transition-transform duration-150"
                  onClick={() => handlePencilIconClick(item)}
                  style={{ filter: "brightness(120%)" }}
                />
                <h3 className="text-lg font-bold text-gray-900 mb-1">
                  {item.NRemarks}
                </h3>
                <p className="text-gray-600 mb-3">{item.NRemarks2}</p>

                <div className="text-sm text-gray-500">
                  <p>
                    <span className="font-semibold text-gray-800">Date:</span>{" "}
                    {new Date(item.NDate).toLocaleDateString()}
                  </p>
                  <p>
                    <span className="font-semibold text-gray-800">
                      Notice Link:
                    </span>{" "}
                    <a
                      href={item.NNoticeLink}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-600 hover:text-blue-800 transition-colors duration-200 underline"
                    >
                      {item.NNoticeLink}
                    </a>
                  </p>
                  <p>
                    <span className="font-semibold text-gray-800">Active:</span>{" "}
                    {item.IsActive ? (
                      <span className="text-green-500 font-medium">Yes</span>
                    ) : (
                      <span className="text-red-500 font-medium">No</span>
                    )}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default ScrollNoticeList;
