import React from "react";
import axios from "axios";
import toast from "react-toastify";
import { getUserDataFromLocalByKey } from "./getStudentInfoByKeyLocal";

export const UpdateStudentAttendanceAPI = async (userId,schoolId,userType,year,stdyrid,dateData,AttType) => {
  try {
    const key = "webServiceURL";

    const returnUrl = await getUserDataFromLocalByKey(userId,schoolId,userType,year,key);

    const formData = new FormData();
    formData.append("title", "UpdateDailyAtt");
    formData.append("ReqStdYrid", stdyrid);
    formData.append("ReqAttType", AttType)
    formData.append("ReqAttDate", dateData);
    // nvc.Add("title", "UpdateDailyAtt");
    // nvc.Add("ReqStdYrid", "29721");
    // nvc.Add("ReqAttType", TxtClass.Text);
    // nvc.Add("ReqAttDate", TxtSection.Text);


    let userdata = [];

    const Corskey = process.env.REACT_APP_CORS_URL;

    const result = await axios.post(`${Corskey}${returnUrl}`, formData);

    console.log("RESULT RESPONSE",result)
    userdata = JSON.parse(
      result.data.substring(0, result.data.indexOf("||JasonEnd", 0))
    );


    // console.log("RESULT RESPONSE",result)
    console.log("RESULT RESPONSE DATA",userdata)

    return userdata;

    



  } catch (error) {
    console.error("An error occurred:", error);
    // Handle error here
  }
  // Placeholder JSX, replace with actual JSX
};
