import React from "react";
import axios from "axios";
import toast from "react-toastify";
import { getUserDataFromLocalByKey } from "./getStudentInfoByKeyLocal";

export const getAdminPollList = async (
  userId,
  schoolId,
  userType,
  year,
  type
) => {
  try {
    const key = "webServiceURLAWSDOTNET";

    const returnUrl = await getUserDataFromLocalByKey(
      userId,
      schoolId,
      userType,
      year,
      key
    );

    const classKey = "CLNAME";

    const className = await getUserDataFromLocalByKey(
      userId,
      schoolId,
      userType,
      year,
      classKey
    );

    const secKey = "SECNAME";

    const secName = await getUserDataFromLocalByKey(
      userId,
      schoolId,
      userType,
      year,
      secKey
    );

    const empClassKey = "EMPCLASS";
    const classSecEmp = await getUserDataFromLocalByKey(
      userId,
      schoolId,
      userType,
      year,
      empClassKey
    );

    let classAndsec;
    let subjectTo;

    console.log(classSecEmp, type, "class name");

    if (userType === "Admin") {
      subjectTo = "";
    } else if (userType === "Student") {
      subjectTo = "Parent";
      classAndsec = `${className || ""}${className && secName ? "-" : ""}${
        secName || ""
      }`.trim();
    } else {
      if (classSecEmp) {
        subjectTo = classSecEmp;
        if (type !== "Add") {
          subjectTo = userType;
        }
      } else {
        if (type === "PollVote") {
          subjectTo = userType;
        } else {
          classAndsec = classSecEmp;
          subjectTo = userType;
        }
      }
    }

    const formData = new FormData();
    formData.append("title", "GetPollList");
    formData.append("description", "");
    formData.append("ReqClassSec", classAndsec || "");
    formData.append("ReqUser", userId || "");
    formData.append("ReqSubjectTo", subjectTo);

    let userdata = [];

    const Corskey = process.env.REACT_APP_CORS_URL;

    // const result = await axios.post(
    //   "https://cors-anywhere.herokuapp.com/http://apkarnold.newgentechnology.net/WebDataProcessingServer.aspx",
    //   formData
    // );

    const result = await axios.post(`${Corskey}${returnUrl}`, formData);

    userdata = JSON.parse(
      result.data.substring(0, result.data.indexOf("||JasonEnd", 0))
    );

    return userdata;
  } catch (error) {
    console.error("An error occurred:", error);
    // Handle error here
  }
  // Placeholder JSX, replace with actual JSX
};
