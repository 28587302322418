import React from "react";
import axios from "axios";
import toast from "react-toastify";
import { getUserDataFromLocalByKey } from "./getStudentInfoByKeyLocal";

export const getSingleStudentInfoDetails = async (userId, schoolId, userType, year,classSelected) => {
    try {
        const key = "webServiceURL";
        // const classKey='CLNAME'
        // const sectionKey='SECNAME'
        if(!classSelected) classSelected = "KG I"

        const returnUrl = await getUserDataFromLocalByKey(userId, schoolId, userType, year, key);

       

        // const className=await getUserDataFromLocalByKey(userId, schoolId, userType, year, classKey)

        // const sectionName= await getUserDataFromLocalByKey(userId, schoolId, userType, year, classKey)

       
        

        const formData = new FormData();
        formData.append("title", "GetERPClassWiseStudentInfoList");
        formData.append("description", "Checking");
        formData.append("ReqAdmno", ""); 
        formData.append("ReqAcastart", "");
        let userdata = [];

        const Corskey = process.env.REACT_APP_CORS_URL;

        const result = await axios.post(`${Corskey}${returnUrl}`, formData);

        userdata = JSON.parse(result.data.substring(0, result.data.indexOf("||JasonEnd", 0)));

        return userdata;
    } catch (error) {
        console.error("An error occurred:", error);
        // Handle error here
    }
    // Placeholder JSX, replace with actual JSX
};
