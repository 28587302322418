/* eslint-disable no-unused-vars */

import "datatables.net-buttons-dt/css/buttons.dataTables.css";
import "datatables.net-buttons-dt/css/buttons.dataTables.min.css";
import "datatables.net-buttons-dt/js/buttons.dataTables.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import "datatables.net-buttons/js/dataTables.buttons.js";
import DataTable from "datatables.net-dt";
import "datatables.net-dt/css/dataTables.dataTables.css";
import "datatables.net-responsive-dt";
import "datatables.net-scroller";
import "datatables.net-scroller-dt/css/scroller.dataTables.css";
import "datatables.net-scroller-dt/css/scroller.dataTables.min.css";
import "datatables.net-scroller-dt/js/scroller.dataTables.js";
import "datatables.net-scroller/js/dataTables.scroller.js";
import "datatables.net-select/js/dataTables.select.js";

import JSZip from "jszip";
import PDFMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import StudentImage from "../../assets/png/avatar.png";

import { MdOutlineKeyboardArrowDown, MdOutlineKeyboardArrowUp } from "react-icons/md";
import { GetStaffListERPQI } from "../../API/GetStaffListERPQI";
import Spinner from "../../components/Spinner";
import { GetEmployeeDataMasterLists } from "../../API/GetEmployeeDataMasterLists";
// import { GetEmployeeDataMasterLists } from "../../../../API/Project/Admin/QuickInfo/GetEmployeeDataMasterLists";

window.JSZip = JSZip;
PDFMake.vfs = pdfFonts.pdfMake?.vfs;

const columns = [
  { index: 0, title: "Absent", data: "select", visible: true },
  { index: 1, title: "Photo", data: "ImageFile", visible: true },
  { index: 2, title: "Emp Code", data: "empCode", visible: true },
  { index: 3, title: "Name", data: "Name", visible: true },
  { index: 4, title: "Father Name", data: "FatherName", visible: true },
  { index: 4, title: "Mobile No.", data: "Phone", visible: true },
  { index: 4, title: "Date Of Join", data: "DateOfJoin", visible: true },
  { index: 4, title: "Type", data: "Type", visible: true },
  { index: 4, title: "Desig.", data: "Designation", visible: true },
  { index: 4, title: "Department", data: "Department", visible: true },
  { index: 4, title: "Group", data: "Group", visible: true },
];

const QuickInfoStaffList = () => {
  const { customId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const [loading, setLoading] = useState(false);
  const [staffListDetails, setStaffListDetails] = useState([]);
  let classSelected = location.state.class;
  const groupType = location?.state?.groupType ?? "";

  const [isClosed, setIsClosed] = useState(false);
  const [desgn, setDesgn] = useState([]);
  const [seldesgn, setselDesgn] = useState("");
  const [dept, setDept] = useState([]);
  const [seldept, setselDept] = useState("");
  const [type, setType] = useState([]);
  const [seltype, setselType] = useState("");
  const [accCat, setAccCat] = useState([]);
  const [selaccCat, setselAccCat] = useState("");
  const [selGrp, setSelGrp] = useState("");
  const [disp, setDisp] = useState("0");
  const [userDetails, setUserDetails] = useState(null);

  const [tableCol, setTableCol] = useState(columns);
  const [tableData, setTableData] = useState([]);
  let tableRef = useRef(null);
  let tableInstance = useRef(null);

  useEffect(() => {
    // setLoading(true)
    const fetchData = async () => {
      setLoading(true);
      const users = JSON.parse(localStorage.getItem("registeredUsers")) || [];

      if (customId) {
        const decodedData = JSON.parse(atob(customId));
        let matchedItem = [];

        users.forEach((innerArray) => {
          const [userData] = innerArray;

          if (
            userData.userId === decodedData.userId &&
            userData.userType === decodedData.userType &&
            userData.schoolId === decodedData.schoolId &&
            userData.year === decodedData.year
          ) {
            matchedItem.push(userData);
          }
        });

        setUserDetails(matchedItem);
        setLoading(false);
      }
    };

    fetchData();
  }, [customId]);

  console.log(userDetails, "ooooooooooo");

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      const response = await GetEmployeeDataMasterLists(userDetails?.[0]?.year);
      const JSONData1 = JSON.parse(response[0]?.JSONData1);
      const desgns = JSONData1.filter((item) => item.MasterType === "EmpDesig");
      const dpt = JSONData1.filter((item) => item.MasterType === "EmpDept");
      const types = JSONData1.filter((item) => item.MasterType === "EmpType");
      const accC = JSONData1.filter((item) => item.MasterType === "AccCat");
      setDesgn(desgns);
      setDept(dpt);
      setType(types);
      setAccCat(accC);

      setLoading(false);
    };

    fetchData();
  }, [userDetails]);

  useEffect(() => {
    setLoading(true);
    const fetchData = async (grp) => {
      const result = await GetStaffListERPQI(grp, seltype, selaccCat, seldept, seldesgn, disp);
      if (result?.length > 0) {
        setStaffListDetails(result);
      } else {
        if (tableInstance.current !== null) {
          tableInstance.current.destroy();
        }
        setStaffListDetails([]);
      }
      setLoading(false);
    };

    if (groupType) {
      let val = groupType;
      setSelGrp(val);
      fetchData(val);
    } else {
      fetchData("");
    }
  }, [groupType]);

  window.handleShowDetails = (logourl, id) => {
    // a function on datatable
    navigate(`/admin/u/${customId}/QuickInfo/staff_profile`, {
      state: {
        empCode: logourl,
        empId: id,
      },
    });
  };

  const handleRefresh = async () => {
    try {
      const result = await GetStaffListERPQI(selGrp, seltype, selaccCat, seldept, seldesgn, disp);
      if (result?.length > 0) {
        setStaffListDetails(result);
      } else {
        if (tableInstance.current !== null) {
          tableInstance.current.destroy();
        }
        setStaffListDetails([]);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const TableData = staffListDetails.map((student) => ({
    empCode: student.EMPCODE,
    Name: student.EmployeeName,
    FatherName: student.FatherName,
    Phone: student.PhoneNo,
    DateOfJoin: student.DOFJOIN,
    Type: student.EmployeeType,
    Designation: student.Designation,
    Department: student.Department,
    Group: student.EMPLOYEECATEGORY,
    EMPAUTOID: student.EMPAUTOID,
  }));

  const TableData2 = staffListDetails.map((student) => ({
    empCode: `<div class="flex flex-col gap-1">
                <p>${student.EMPCODE}</p>
                <button onClick="handleShowDetails('${student.EMPCODE}','${student.EMPAUTOID}')" class="buttonClass text-xs uppercase font-montserrat font-medium">view</button>
             </div>`,
    Name: student.EmployeeName,
    FatherName: student.FatherName,
    Phone: student.PhoneNo,
    DateOfJoin: student.DOFJOIN,
    Type: student.EmployeeType,
    Designation: student.Designation,
    Department: student.Department,
    Group: student.EMPLOYEECATEGORY,
    ImageFile: `<img src="${StudentImage}" alt="Student Imae" class="rounded-full !size-4" />`,
    select: `<input type="radio" class="radioButton" name="group1" />`,
  }));

  useEffect(() => {
    // Initialize DataTable when the component mounts
    if (tableCol && TableData2) {
      tableInstance.current = new DataTable("#studentDetails", {
        responsive: false,
        // dom: "Bfrtip",
        layout: {
          topStart: {
            buttons: [
              "excel",
              "csv",
              "xls",
              "pdf",
              {
                extend: "print",
                exportOptions: {
                  columns: ":visible",
                },
              },
              "colvis",
            ],
          },
          bottomEnd: {
            paging: {
              type: "simple_numbers",
            },
          },
        },
        pageLength: 10,
        lengthMenu: [10, 25, 50],
        select: true,
        info: "",
        scrollY: 500,
        scrollX: true,
        deferRender: true,
        scroller: true,
        data: TableData2,
        columns: tableCol,
      });
    }
    // Cleanup DataTable instance when the component unmounts
    return () => {
      if (tableInstance.current !== null) {
        tableInstance.current.destroy();
      }
    };
  }, [TableData2, tableCol]);

  const handleCloseClick = () => {
    setTimeout(() => setIsClosed(!isClosed), 250);
  };

  return (
    <>
      <div className="lg:mt-0 mt-5">
        <div className="container mx-auto p-4 containerBoxShadow">
          <div className="">
            <div className="flex justify-start w-full rounded-md bg-gradient-to-r from-cyan-400 to-blue-400 hover:bg-gradient-to-bl">
              <h2 className="w-full text-center text-2xl p-0 my-auto uppercase text-black font-mono font-semibold">Staff List</h2>
              <button onClick={handleCloseClick} className={`w-fit rounded-md p-2`}>
                <MdOutlineKeyboardArrowUp
                  className={`duration-500 ease-ease-in-out text-black transition-all ${isClosed ? "rotate-180" : ""} `}
                  size={25}
                />
              </button>
            </div>
            <div className={`transition-all duration-700 ease-ease-in ${!isClosed ? "opacity-100 max-h-screen" : "opacity-0 max-h-0"}`}>
              <div className="flex items-start flex-col my-2 justify-between gap-5 rounded">
                <div className="flex flex-col w-full">
                  <div className="flex md:flex-row p-3 px-3 md:px-7 lg:px-10 gap-0 md:gap-5 formBackground w-full justify-between flex-col">
                    <div className="w-full md:w-1/2 flex justify-between flex-col">
                      <div className=" flex flex-row items-center gap-2 mb-1 justify-between md:justify-start">
                        <label
                          className="block uppercase tracking-wide text-start text-grey-darker md:min-w-36 text-xs font-bold mb-2"
                          for="grid-state"
                        >
                          Type
                        </label>
                        <div className="relative flex flex-row ">
                          <select
                            value={seltype}
                            onChange={(e) => setselType(e.target.value)}
                            className="textInput min-w-20 w-[150px] md:w-[190px]"
                          >
                            <option value="">--select--</option>
                            {type?.map((data) => (
                              <option value={data.Head1}>{data.Head1}</option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className=" flex flex-row items-center gap-2 mb-1 justify-between md:justify-start">
                        <label
                          className="block uppercase tracking-wide text-start text-grey-darker md:min-w-36 text-xs font-bold mb-2"
                          for="grid-state"
                        >
                          Account Category
                        </label>
                        <div className="relative flex flex-row ">
                          <select
                            value={selaccCat}
                            onChange={(e) => setselAccCat(e.target.value)}
                            className="textInput min-w-20 w-[150px] md:w-[190px]"
                          >
                            <option value="">--select--</option>
                            {accCat?.map((data) => (
                              <option value={data.Head1}>{data.Head1}</option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className=" flex flex-row items-center gap-2 mb-1 justify-between md:justify-start">
                        <label
                          className="block uppercase tracking-wide text-start text-grey-darker md:min-w-36 text-xs font-bold mb-2"
                          for="grid-state"
                        >
                          DEPARTMENT
                        </label>
                        <div className="relative flex flex-row ">
                          <select
                            value={seldept}
                            onChange={(e) => setselDept(e.target.value)}
                            className="textInput min-w-20 w-[150px] md:w-[190px]"
                          >
                            {" "}
                            <option value="">--select--</option>
                            {dept?.map((data) => (
                              <option value={data.Head1}>{data.Head1}</option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className=" flex flex-row items-center gap-2 mb-1 justify-between md:justify-start">
                        <label
                          className="block uppercase tracking-wide text-start text-grey-darker md:min-w-36 text-xs font-bold mb-2"
                          for="grid-state"
                        >
                          DESIGNATION
                        </label>
                        <div className="relative flex flex-row ">
                          <select
                            value={seldesgn}
                            onChange={(e) => setselDesgn(e.target.value)}
                            className="textInput min-w-20 w-[150px] md:w-[190px]"
                          >
                            {" "}
                            <option value="">--select--</option>
                            {desgn?.map((data) => (
                              <option value={data.Head1}>{data.Head1}</option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className=" flex flex-row items-center gap-2 mb-1 justify-between md:justify-start">
                        <label
                          className="block uppercase tracking-wide text-start text-grey-darker md:min-w-36 text-xs font-bold mb-2"
                          for="grid-state"
                        >
                          GROUP
                        </label>
                        <div className="relative flex flex-row ">
                          <select
                            value={selGrp}
                            onChange={(e) => setSelGrp(e.target.value)}
                            className="textInput min-w-20 w-[150px] md:w-[190px]"
                          >
                            <option value="">-- Select --</option>
                            <option value="Class Teacher">Class Teacher</option>
                            <option value="All Teachers">All Teachers</option>
                            <option value="Helpers">Helpers</option>
                            <option value="Non Teaching">Non Teaching</option>
                            <option value="Teacher">Teacher</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="w-full md:w-1/2 flex justify-center flex-col items-start align-middle">
                      <div className=" flex flex-row items-center gap-2 mb-1 justify-between md:justify-start">
                        <label
                          className="block uppercase tracking-wide text-start text-grey-darker md:min-w-36 text-xs font-bold mb-2"
                          for="grid-state"
                        >
                          Display Type
                        </label>
                        <div className="relative flex flex-row ">
                          <select
                            value={disp}
                            onChange={(e) => {
                              setDisp(e.target.value);
                            }}
                            className="textInput min-w-20 w-[150px] md:w-[190px]"
                          >
                            <option value="0">Group wise</option>
                            <option value="1">Type wise</option>
                            <option value="2">Account Category wise</option>
                            <option value="3">Department wise</option>
                            <option value="4">Designation wise</option>
                          </select>
                        </div>
                      </div>
                      <div className=" flex flex-row items-center gap-2 mb-1 justify-between md:justify-start">
                        <label
                          className="block uppercase tracking-wide text-start text-grey-darker md:min-w-36 text-xs font-bold mb-2"
                          for="grid-state"
                        >
                          Report
                        </label>
                        <div className="relative flex flex-row ">
                          <select className="textInput min-w-20 w-[150px] md:w-[190px]">
                            <option value="0">Employee Type/Date wise</option>
                          </select>
                        </div>
                      </div>
                      <div className="flex w-full justify-end items-center">
                        <button type="button" onClick={handleRefresh} className="buttonClass w-fit -mb-2 mt-2">
                          Refresh
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {loading ? (
            <Spinner />
          ) : (
            <div className="p-1 md:p-2 w-full shadow-lg">
              {/* <div className="relative max-h-96 md:block hidden overflow-x-auto border border-solid border-black rounded-sm">
                <table className="w-full overflow-y-auto bg-white text-sm text-left rtl:text-right">
                  <thead className="tableHead sticky top-0 z-50 blur-0">
                    <tr>
                      {tableHead?.map((data) => {
                        return (
                          <th scope="col" className="p-1 uppercase text-sm">
                            {data}
                          </th>
                        );
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    {TableData?.map((item, index) => {
                      return (
                        <tr
                          key={index}
                          className={`border-b ${index % 2 === 0 ? "bg-white" : "bg-slate-200"} dark:bg-gray-800 dark:border-gray-700`}
                        >
                          <td className="border text-center border-solid border-black/10 p-1 w-fit h-full">
                            <input type="radio" className="radioButton" name="group1" />
                          </td>
                          <td className="border border-solid text-center border-black/10 p-1 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                            <img src={StudentImage} className="h-7 w-7" alt="Student Profile" />
                          </td>
                          <td className="border border-solid border-black/10 p-1 font-medium">{item?.empCode}</td>
                          <td className="border border-solid border-black/10 p-1 font-medium">{item?.Name}</td>
                          <td className="border border-solid border-black/10 p-1 font-medium">{item?.FatherName}</td>
                          <td className="border border-solid border-black/10 p-1 font-medium">{item?.Phone}</td>
                          <td className="border text-center border-solid border-black/10 p-1 font-medium">{item?.DateOfJoin}</td>
                          <td className="border border-solid border-black/10 p-1 font-medium">{item?.Type}</td>
                          <td className="border text-center border-solid border-black/10 p-1 font-medium">{item?.Designation}</td>
                          <td className="border border-solid border-black/10 p-1 font-medium">{item?.Department}</td>
                          <td className="border border-solid border-black/10 p-1 font-medium">{item?.Group}</td>
                          <td className="border border-solid border-black/10 p-1 font-medium">
                            <p
                              onClick={() => {
                                navigate(`/admin/u/quick_info/staff_profile/${customId}`, {
                                  state: {
                                    empCode: item?.empCode,
                                  },
                                });
                              }}
                              className="text-blue-500 cursor-pointer text-sm capitalize"
                            >
                              Show Details
                            </p>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div> */}
              <div className="md:hidden block">
                <div className="block md:hidden max-w-xl mx-auto bg-white shadow-md p-2 rounded-lg">
                  <h2 className="text-lg cardHeadingClass mb-4 w-full text-center">Employees</h2>
                  <div className="overflow-x-auto">
                    {TableData.map((item, index) => (
                      <div
                        className="border-2 bg-gradient-to-b from-sky-200 to-cyan-400 rounded-lg mb-4 shadow-lg overflow-hidden"
                        key={index}
                      >
                        <div className="flex items-center  justify-between tabButton py-2 px-4 rounded-t-lg shadow-md">
                          <input className="radioButton" type="radio" name="employeesRadio" id={`employeesRadio-${index}`} />
                          <span className="font-bold text-center w-full"> {item?.Name}</span>
                        </div>
                        <div className="flex justify-center mt-4">
                          <img src={StudentImage} alt="Employee img" className="rounded-full h-20 w-20" />
                        </div>
                        <table className="w-full table-auto mt-2">
                          <tbody>
                            <tr>
                              <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                                <strong>Emp Code.</strong>
                              </td>
                              <td className="border border-solid border-gray-400 text-start p-2 w-1/2">{item?.empCode}</td>
                            </tr>
                            <tr>
                              <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                                <strong>Father</strong>
                              </td>
                              <td className="border border-solid border-gray-400 text-start p-2 w-1/2">{item?.FatherName}</td>
                            </tr>
                            <tr>
                              <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                                <strong>Mobile No.</strong>
                              </td>
                              <td className="border border-solid border-gray-400 text-start p-2 w-1/2">{item?.Phone}</td>
                            </tr>
                            <tr>
                              <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                                <strong>Date Of Join</strong>
                              </td>
                              <td className="border border-solid border-gray-400 text-start capitalize p-2 w-1/2">
                                {new Date(item?.DateOfJoin).toLocaleDateString()}
                              </td>
                            </tr>
                            <tr>
                              <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                                <strong>Type</strong>
                              </td>
                              <td className="border border-solid border-gray-400 text-start capitalize p-2 w-1/2">{item?.Type}</td>
                            </tr>

                            <tr>
                              <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                                <strong>Desig.</strong>
                              </td>
                              <td className="border border-solid border-gray-400 text-start p-2 w-1/2">{item?.Designation}</td>
                            </tr>
                            <tr>
                              <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                                <strong>Department</strong>
                              </td>
                              <td className="border border-solid border-gray-400 text-start capitalize p-2 w-1/2">{item?.Department}</td>
                            </tr>
                            <tr>
                              <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                                <strong>Group</strong>
                              </td>
                              <td className="border border-solid border-gray-400 text-start capitalize p-2 w-1/2">{item?.Group}</td>
                            </tr>

                            <tr>
                              <td className="border border-solid border-gray-400 text-start capitalize p-2 w-1/2">
                                <strong>Show Profile</strong>
                              </td>
                              <td className="border border-solid border-gray-400 text-start capitalize p-2 w-1/2 px-6 py-4">
                                <p
                                  onClick={() => {
                                    navigate(`/admin/u/${customId}/QuickInfo/staff_profile`, {
                                      state: {
                                        empCode: item?.empCode,
                                        empId: item?.EMPAUTOID,
                                      },
                                    });
                                  }}
                                  className="text-blue-500 cursor-pointer text-sm capitalize"
                                >
                                  Show Details
                                </p>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="relative max-h-full overflow-x-auto border md:block hidden border-black border-solid rounded-sm">
                {staffListDetails && staffListDetails?.length > 0 ? (
                  <table
                    ref={tableRef}
                    id="studentDetails"
                    style={{ width: "100%" }}
                    className="h-fit w-fit max-h-[500px] responsive extraCSS display nowrap"
                  ></table>
                ) : (
                  <div className="h-auto p-5">
                    <h1 className="text-2xl font-bold">Record Not Found</h1>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default QuickInfoStaffList;
