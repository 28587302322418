/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { motion } from "framer-motion";
import detailIcon from "../../assets/Dashboard/detailsIcon.svg";
import { Accordion } from "flowbite-react";
import { toast } from "react-toastify";
import { getQuickInfoList } from "../../API/getQuickInfoList";
import Spinner from "../../components/Spinner";
import { getStudentBySearch } from "../../API/getStudentBySearch";
import { getStudntByAdmn } from "../../API/getStudntByAdmn";
import { GetEmployeeDataMasterLists } from "../../API/GetEmployeeDataMasterLists";
import { getEmployeelist } from "../../API/getEmployeelist";
import { GetEmployeeNameList } from "../../API/GetEmployeeNameList";
import { GetERPClassStrength } from "../../API/GetERPClassStrength";
import { GetStudentNameList } from "../../API/GetStudentNameList";
import { getClassSectionList } from "../../API/getClassSectionList";
import ModalFunctionComponent from "../../components/ModalFunctionComponent";

const QuickInfo = () => {
  const [userDetails, setUserDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const [quickInfoList, setQuickInfoList] = useState([]);
  const [initialResourceUrl, setInitialResourceUrl] = useState(null);
  const [collapse, setCollapse] = useState([1]);
  const [modal, setModal] = useState(false);
  const [modal2, setModal2] = useState(false);
  const [classAndSub, setClassAndSub] = useState([]);
  const [classList, setClassList] = useState([]);
  const [selectedStudents, setSelectedStudents] = useState("");
  const [sectionList, setSectionList] = useState(["A", "B", "C", "D"]);
  const [selectedClass, setSelectedClass] = useState("");
  const [selectedSection, setSelectedSection] = useState("");
  const [admno, setAdmno] = useState("");
  const [admno2, setAdmno2] = useState("");
  const [nameSearch, setNameSearch] = useState("");
  const [nameSearch2, setNameSearch2] = useState("");
  const [name, setName] = useState("");
  const [fname, setFName] = useState("");
  const [fname2, setFName2] = useState("");
  const [name2, setName2] = useState("");
  const [students, setStudents] = useState([]);
  const [admsnNo, setAdmsnNo] = useState("");
  const [emplist, setEmplist] = useState([]);

  const [desgn, setDesgn] = useState([]);
  const [seldesgn, setselDesgn] = useState("");
  const [dept, setDept] = useState([]);
  const [seldept, setselDept] = useState("");
  const [type, setType] = useState([]);
  const [seltype, setselType] = useState("");
  const [accCat, setAccCat] = useState([]);
  const [selaccCat, setselAccCat] = useState("");
  const [disp, setDisp] = useState("0");
  const [empcode, setempcode] = useState("");
  const [searchItems, setSearchItems] = useState([]);
  const [showSearch, setShowSearch] = useState(false);
  const [searchItems2, setSearchItems2] = useState([]);
  const [showSearch2, setShowSearch2] = useState(false);
  const [erpClass, setErpClass] = useState([]);

  const navigate = useNavigate();
  const { customId } = useParams();

  useEffect(() => {
    // setLoading(true)
    const fetchData = async () => {
      setLoading(true);
      const users = JSON.parse(localStorage.getItem("registeredUsers")) || [];

      if (customId) {
        const decodedData = JSON.parse(atob(customId));
        let matchedItem = [];

        users.forEach((innerArray) => {
          const [userData] = innerArray;

          if (
            userData.userId === decodedData.userId &&
            userData.userType === decodedData.userType &&
            userData.schoolId === decodedData.schoolId &&
            userData.year === decodedData.year
          ) {
            matchedItem.push(userData);
          }
        });
        setUserDetails(matchedItem);

        const result = await getQuickInfoList(decodedData.year);
        const response = await GetEmployeeDataMasterLists(decodedData.year);

        const responseClassStrength = await GetERPClassStrength(decodedData.year);
        setErpClass(responseClassStrength);

        handleClassAndSectionFetch(decodedData.userId, decodedData.schoolId, decodedData.userType, decodedData.year);

        const JSONData1 = JSON.parse(response[0]?.JSONData1);
        const desgns = JSONData1.filter((item) => item.MasterType === "EmpDesig");
        const dpt = JSONData1.filter((item) => item.MasterType === "EmpDept");
        const types = JSONData1.filter((item) => item.MasterType === "EmpType");
        const accC = JSONData1.filter((item) => item.MasterType === "AccCat");
        setDesgn(desgns);
        setDept(dpt);
        setType(types);
        setAccCat(accC);

        setQuickInfoList(result);
        setLoading(false);
        setUserDetails(matchedItem);
      }
    };

    fetchData();
  }, [customId]);

  let studentsList = quickInfoList
    ?.filter((item) => item?.Clid === 1)
    .map((item) => ({
      Head1: item?.Head1,
      Col1: item?.Col1,
      sumType: item?.Col2,
      Slno: item?.Slno,
      Rcdid: item?.Rcdid,
    }));
  let StaffList = quickInfoList
    ?.filter((item) => item?.Clid === 2)
    .map((item) => ({
      Head1: item?.Head1,
      Col1: item?.Col1,
      absent: item?.Col2,
      Slno: item?.Slno,
      Rcdid: item?.Rcdid,
    }));
  let FeeList = quickInfoList
    ?.filter((item) => item?.Clid === 3)
    .map((item) => ({
      Head1: item?.Head1,
      Col1: item?.Col1 !== "0" ? item?.Col1 : item?.Col2,
      Slno: item?.Slno,
      Rcdid: item?.Rcdid,
    }));

  const handleSelectStudentFromModal = (data) => {
    setModal(false);
    navigate(`/admin/u/${customId}/QuickInfo/student_profile`, {
      state: {
        admno: data.ADMNO,
      },
    });
    // setStudents([]);
    // setSelectedStudents(data);
  };

  const handleSelectStudentFromModal2 = (data) => {
    setModal2(false);
    navigate(`/admin/u/${customId}/QuickInfo/staff_profile`, {
      state: {
        empCode: data?.EMPCODE,
      },
    });
    // setStudents([]);
    // setSelectedStudents(data);
  };

  const fetchEMPlist = async () => {
    setLoading(true);
    const result = await getEmployeelist(name2, admno2, selaccCat, seltype, empcode);
    console.log(result, "relust");
    const JSONData1 = JSON.parse(result[0]?.JSONData1);
    setEmplist(JSONData1);
    setLoading(false);
  };

  const handleSearch = async () => {
    try {
      if (selectedClass && selectedSection) {
        setLoading(true);
        const userdata = await getStudentBySearch(userDetails[0].year, selectedClass, selectedSection, name, "", "", admno);
        const JSONData1 = JSON.parse(userdata[0].JSONData1);
        setStudents(JSONData1);
        setLoading(false);
      } else {
        toast.warn("Select class and section");
        setLoading(false);
      }
    } catch (error) {
      console.error("There was a problem with the request:", error);
      toast.error("Something went wrong");
    }
  };

  const handleClassAndSectionFetch = async (userId, schoolId, userType, year) => {
    setLoading(true);
    try {
      const respone = await getClassSectionList(userId, schoolId, userType, year);
      const filteredClassAndSub = respone.filter((item) => item.Clid !== -15);

      setClassAndSub(filteredClassAndSub);
      setClassList(filteredClassAndSub.map((item) => item.Head1));
      setLoading(false);
    } catch (error) {
      console.log(error);
      toast.error("Error");
      setLoading(false);
    }
  };

  const handleClassChange = useCallback(
    (e) => {
      if (classAndSub.length > 0) {
        const userSelectedClass = classAndSub.filter((items) => items.Head1 === e.target.value);
        const sectionList = userSelectedClass.flatMap((item) => item.Head2.split(","));
        setSectionList(sectionList);
      }
      setSelectedClass(e.target.value);
    },
    [setSelectedClass, classAndSub]
  );

  const handleSectionChange = useCallback(
    (e) => {
      setSelectedSection(e.target.value);
    },
    [setSelectedSection]
  );

  // useEffect(() => {
  //   handleClassAndSectionFetch();
  // }, []);

  const handleStudnetModal = async () => {
    if (admno.length > 0) {
      const response = await getStudntByAdmn(userDetails[0].year, admno);
      const JSONData1 = JSON.parse(response[0].JSONData1);
      if (JSONData1.length > 0) {
        let adm = JSONData1[0]?.ADMNO;
        navigate(`/admin/u/${customId}/QuickInfo/student_profile`, {
          state: {
            admno: adm,
          },
        });
      } else {
        toast.info("No data found");
      }
    } else {
      setModal(true);
    }
  };

  const STDSearch = async (value) => {
    if (value.length > 2) {
      const result = await GetStudentNameList(value);
      if (result.length > 0) {
        setShowSearch(true);
        setSearchItems(result);
      } else {
        setShowSearch(true);
        setSearchItems([{ Sname: "Student not found", Admno: "--" }]);
      }
    } else {
      setShowSearch(false);
      setSearchItems([]);
    }
  };

  const handleRowSelect = (item) => {
    setShowSearch(false);
    navigate(`/admin/u/${customId}/QuickInfo/student_profile`, {
      state: {
        admno: item?.Admno,
      },
    });
  };

  const showSearchItems = () => {
    if (showSearch) {
      return (
        <div
          onMouseLeave={() => setShowSearch(false)}
          className="bg-white shadow-lg border md:top-8 top-8 absolute z-[10000000000] max-h-40 overflow-auto w-full rounded p-1"
        >
          {searchItems?.length > 0 &&
            searchItems?.map((item, ind) => (
              <ol
                key={ind}
                className="hover:bg-gray-200 w-full cursor-pointer"
                onClick={() => {
                  handleRowSelect(item);
                }}
              >
                <p className="p-0.5 font-poppins font-medium">
                  - {item.Sname} | {item?.Admno}
                </p>
              </ol>
            ))}
        </div>
      );
    }
  };

  const EMPSearch = async (value) => {
    if (value.length > 0) {
      const result = await GetEmployeeNameList(value);
      if (result.length > 0) {
        setShowSearch2(true);
        setSearchItems2(result);
      } else {
        setShowSearch2(true);
        setSearchItems2([
          {
            EMPCODE: "---",
            EmployeeName: "Employee not found",
            FATHERNAME: "",
          },
        ]);
      }
    } else {
      setShowSearch2(false);
      setSearchItems2([]);
    }
  };

  const handleRowSelect2 = (item) => {
    setShowSearch2(false);
    navigate(`/admin/u/${customId}/QuickInfo/staff_profile`, {
      state: {
        empCode: item?.EMPCODE,
      },
    });
  };

  const showSearchItems2 = () => {
    if (showSearch2) {
      return (
        <div
          onMouseLeave={() => setShowSearch2(false)}
          className="bg-white shadow-lg border md:top-8 top-8 absolute z-[10000000000] max-h-40 overflow-auto w-full rounded p-1"
        >
          {searchItems2?.length > 0 &&
            searchItems2?.map((item, ind) => (
              <ol
                key={ind}
                className="hover:bg-gray-200 w-full cursor-pointer"
                onClick={() => {
                  handleRowSelect2(item);
                }}
              >
                <p className="p-0.5 font-poppins font-medium">
                  - {item.EmployeeName} | {item?.EMPCODE}
                </p>
              </ol>
            ))}
        </div>
      );
    }
  };

  const selectedERPClass = (cls) => {
    navigate(`/admin/u/${customId}/QuickInfo/erp_class_strength`, {
      state: {
        classID: cls,
      },
    });
  };

  return (
    <>
      <div className="lg:mt-0 mt-16">
        {modal && (
          <ModalFunctionComponent close={setModal} title={"Search Student List"}>
            <div className="flex flex-col py-2 formBackground w-full px-3 gap-2">
              <div className="flex gap-2 w-full md:flex-row h-full flex-col">
                <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2 w-full justify-start items-start align-top">
                  <div className="flex flex-col gap-1 w-full">
                    <label htmlFor="" className="w-full basis-[30%] align-middle flex items-center text-sm text-start font-semibold">
                      Name
                    </label>
                    <input type="text" value={name} onChange={(e) => setName(e.target.value)} className="max-w-60 w-full textInput " />
                  </div>

                  <div className="flex flex-col gap-1 w-full">
                    <label htmlFor="" className="w-full basis-[30%] align-middle flex items-center text-sm text-start font-semibold">
                      Father Name
                    </label>
                    <input type="text" value={admno} onChange={(e) => setAdmno(e.target.value)} className="max-w-60 w-full textInput " />
                  </div>
                  <div className="flex flex-col gap-1 w-full">
                    <label htmlFor="" className="w-full basis-[30%] align-middle flex items-center text-sm text-start font-semibold">
                      Class
                    </label>
                    <select className="max-w-60 w-full textInput " value={selectedClass} onChange={handleClassChange}>
                      <option value="">Select Class</option>
                      {classList?.length &&
                        classList?.length > 0 &&
                        classList?.map((cls, ind) => (
                          <option key={ind} value={cls}>
                            {cls}
                          </option>
                        ))}
                    </select>
                  </div>

                  <div className="flex flex-col gap-1 w-full">
                    <label htmlFor="" className="w-full basis-[30%] align-middle flex items-center text-sm text-start font-semibold">
                      Section
                    </label>
                    <select value={selectedSection} onChange={handleSectionChange} className="max-w-60 w-full textInput ">
                      <option value="">Select Section</option>
                      {sectionList?.length &&
                        sectionList?.length > 0 &&
                        sectionList?.map((cls, ind) => (
                          <option key={ind} value={cls}>
                            {cls}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
                <div className="flex gap-2 md:flex-row flex-col items-end my-auto justify-start h-full">
                  <button onClick={handleSearch} type="button" className="buttonClass w-fit h-full my-auto">
                    Search
                  </button>
                  <button
                    onClick={() => {
                      setSelectedClass("");
                      setSelectedSection("");
                      setAdmno("");
                      setName("");
                    }}
                    type="button"
                    className="buttonClass w-fit h-full my-auto"
                  >
                    Clear
                  </button>
                </div>
              </div>
              <div>
                <div className="relative max-w-6xl max-h-[480px] border border-black shadow-md mx-auto sm:rounded-lg overflow-y-auto">
                  {students.length > 0 ? (
                    <>
                      <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 border-collapse">
                        <thead className="md:text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400 py-2 sticky top-0 z-10 h-fit">
                          <tr className="tableHead !h-fit py-2">
                            {/* <th scope="col" className="p-1 text-sm md:text-base"></th> */}
                            <th scope="col" className="p-1 text-sm md:text-base">
                              Adm. No
                            </th>
                            <th scope="col" className="p-1 text-sm md:text-base">
                              Name
                            </th>
                            <th scope="col" className="p-1 text-sm md:text-base">
                              Class
                            </th>
                            <th scope="col" className="p-1 text-sm md:text-base">
                              Section
                            </th>
                            <th scope="col" className="p-1 text-sm md:text-base">
                              Father Name
                            </th>
                            <th scope="col" className="p-1 md:block hidden text-sm md:text-base">
                              Mother Name
                            </th>
                            <th scope="col" className="p-1 md:block hidden text-sm md:text-base">
                              Status
                            </th>
                            <th scope="col" className="p-1 text-sm md:text-base"></th>
                          </tr>
                        </thead>
                        <tbody className="text-gray-900">
                          {students.length > 0 &&
                            students.map((student, index) => (
                              <>
                                <tr key={student?.ADMNO} className={`text-gray-900  ${index % 2 === 0 ? "bg-white" : "bg-gray-100"}`}>
                                  <td className="p-1 border text-xs md:text-sm">{student?.ADMNO}</td>
                                  <td className="p-1 border  text-xs md:text-sm">{student?.SNAME}</td>
                                  <td className="p-1 border  text-xs md:text-sm">{student?.CLNAME}</td>
                                  <td className="p-1 border  text-xs md:text-sm">{student?.SECNAME}</td>
                                  <td className="p-1 border  text-xs md:text-sm">{student?.FATHERNAME}</td>
                                  <td className="p-1 border md:block hidden  text-xs md:text-sm">{student?.mname}</td>
                                  <td className="p-1 border md:block hidden  text-xs md:text-sm">{student?.STS}</td>
                                  <td className="p-1 border  text-xs md:text-sm">
                                    <button
                                      type="button"
                                      onClick={() => handleSelectStudentFromModal(student)}
                                      className="underline text-blue-500"
                                    >
                                      select
                                    </button>
                                  </td>
                                </tr>
                              </>
                            ))}
                        </tbody>
                      </table>
                    </>
                  ) : (
                    <>
                      <div className="p-5 flex justify-start items-center">
                        <h2 className="font-montserrat text-xl font-semibold">Please Search for results</h2>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </ModalFunctionComponent>
        )}
        {modal2 && (
          <ModalFunctionComponent close={setModal2} title={"Search Employee List"}>
            <div className="flex flex-col py-2 formBackground w-full px-3 gap-2">
              <div className="flex gap-2 w-full md:flex-row h-full flex-col">
                <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2 w-full justify-start items-start align-top">
                  <div className="flex flex-col gap-1 w-full">
                    <label htmlFor="" className="w-full basis-[30%] align-middle flex items-center text-sm text-start font-semibold">
                      Name
                    </label>
                    <input type="text" value={name2} onChange={(e) => setName2(e.target.value)} className="max-w-60 w-full textInput " />
                  </div>

                  <div className="flex flex-col gap-1 w-full">
                    <label htmlFor="" className="w-full basis-[30%] align-middle flex items-center text-sm text-start font-semibold">
                      Father Name
                    </label>
                    <input type="text" value={fname2} onChange={(e) => setFName2(e.target.value)} className="max-w-60 w-full textInput " />
                  </div>
                  <div className="flex flex-col gap-1 w-full">
                    <label htmlFor="" className="w-full basis-[30%] align-middle flex items-center text-sm text-start font-semibold">
                      Employee Type
                    </label>
                    <select className="max-w-60 w-full textInput " value={seltype} onChange={(e) => setselType(e.target.value)}>
                      <option value="">Select Class</option>
                      {type?.length &&
                        type?.length > 0 &&
                        type?.map((cls, ind) => (
                          <option key={ind} value={cls.Head1}>
                            {cls.Head1}
                          </option>
                        ))}
                    </select>
                  </div>

                  <div className="flex flex-col gap-1 w-full">
                    <label htmlFor="" className="w-full basis-[30%] align-middle flex items-center text-sm text-start font-semibold">
                      Account Category
                    </label>
                    <select value={selaccCat} onChange={(e) => setselAccCat(e.target.value)} className="max-w-60 w-full textInput ">
                      <option value="">Select Section</option>
                      {accCat?.length &&
                        accCat?.length > 0 &&
                        accCat?.map((cls, ind) => (
                          <option key={ind} value={cls.Head1}>
                            {cls.Head1}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
                <div className="flex gap-2 flex-row items-end my-auto justify-start h-full">
                  <button onClick={fetchEMPlist} type="button" className="buttonClass w-fit h-full my-auto">
                    Search
                  </button>
                  <button
                    onClick={() => {
                      setselAccCat("");
                      setselType("");
                      setAdmno2("");
                      setName2("");
                    }}
                    type="button"
                    className="buttonClass w-fit h-full my-auto"
                  >
                    Clear
                  </button>
                </div>
              </div>
              <div>
                <div className="relative max-w-6xl max-h-[480px] border border-black shadow-md mx-auto sm:rounded-lg overflow-y-auto">
                  {emplist.length > 0 ? (
                    <>
                      <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 border-collapse">
                        <thead className="md:text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400 py-2 sticky top-0 z-10 h-fit">
                          <tr className="tableHead !h-fit py-2">
                            {/* <th scope="col" className="p-1 text-sm md:text-base"></th> */}
                            <th scope="col" className="p-1 text-sm md:text-base">
                              Emp ID
                            </th>
                            <th scope="col" className="p-1 text-sm md:text-base">
                              Name
                            </th>
                            <th scope="col" className="p-1 text-sm md:text-base">
                              Phone Number
                            </th>
                            <th scope="col" className="p-1 text-sm md:text-base">
                              Category
                            </th>

                            <th scope="col" className="p-1 text-sm md:text-base"></th>
                          </tr>
                        </thead>
                        <tbody className="text-gray-900">
                          {emplist.length > 0 &&
                            emplist.map((student, index) => (
                              <>
                                <tr key={student?.EMPCODE} className={`text-gray-900  ${index % 2 === 0 ? "bg-white" : "bg-gray-100"}`}>
                                  <td className="p-1 border text-xs md:text-sm">{student?.EMPCODE}</td>
                                  <td className="p-1 border  text-xs md:text-sm">{student?.EmployeeName}</td>
                                  <td className="p-1 border  text-xs md:text-sm">{student?.PhoneNo}</td>
                                  <td className="p-1 border  text-xs md:text-sm">{student?.Department}</td>
                                  <td className="p-1 border  text-xs md:text-sm">
                                    <button
                                      type="button"
                                      onClick={() => handleSelectStudentFromModal2(student)}
                                      className="underline text-blue-500"
                                    >
                                      select
                                    </button>
                                  </td>
                                </tr>
                              </>
                            ))}
                        </tbody>
                      </table>
                    </>
                  ) : (
                    <>
                      <div className="p-5 flex justify-start items-center">
                        <h2 className="font-montserrat text-xl font-semibold">Please Search for results</h2>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </ModalFunctionComponent>
        )}
        <div className="container mx-auto p-2 containerBoxShadow">
          {loading ? (
            <Spinner />
          ) : (
            <>
              {/* <div className="mt-2">
                <div className="flex gap-3  justify-between  mx-auto mb-1">
                  <div className="formBackground border-b dark:bg-gray-800 dark:border-gray-700 flex justify-between items-center flex-wrap">
                    <div className="p-3 text-slate-800 font-normal">
                      <label htmlFor="" className=" hover:text-white font-spaceMono font-semibold text-purple-800">
                        Admission Number
                      </label>
                      <input className="textInput max-w-40" value={admno} onChange={(e) => setAdmno(e.target.value)} type="text" />
                    </div>
                    <div className="p-3 flex flex-col md:gap-2 gap-1">
                      <button
                        onClick={() =>
                          //    modal
                          setModal(true)
                        }
                        className="buttonClass"
                      >
                        Details
                      </button>
                      <button
                        onClick={() =>
                          //    modal
                          handleStudnetModal()
                        }
                        className="buttonClass"
                      >
                        Show Info
                      </button>
                    </div>
                  </div>
                  <tr className="formBackground border-b dark:bg-gray-800 dark:border-gray-700 flex justify-between items-center flex-wrap">
                    <th scope="row" className="p-3 font-medium flex flex-col text-gray-900 ">
                      <button
                        onClick={() =>
                          //    modal
                          setModal2(true)
                        }
                        className="buttonClass"
                      >
                        Details
                      </button>
                    </th>
                    <td className="p-3 text-slate-800 font-normal">
                      <label htmlFor="" className="font-semibold text-black">
                        EMP Code
                      </label>
                      <input
                        value={empcode}
                        onChange={(e) => setempcode(e.target.value)}
                        className="textInput"
                        placeholder="Search..."
                        type="text"
                      />
                    </td>
                    <td className="p-3">
                      <button
                        onClick={() =>
                          //    modal
                          setModal2(true)
                        }
                        className="buttonClass"
                      >
                        Show Info
                      </button>
                    </td>
                  </tr>
                </div>
              </div> */}
              <div className="flex flex-wrap  gap-3 justify-start  mx-auto ">
                <div className=" shadow-lg w-full h-fit hidden relative ">
                  <div className="max-w-full p-4 md:mx-0 mx-auto border border-black border-solid rounded-lg ">
                    <h2 className="text-2xl font-robotoSlab  uppercase py-4 font-bold text-center bg-gradient-to-r from-purple-300 via-purple-400 to-purple-500 text-white rounded-lg shadow-md">
                      Students Strength
                    </h2>
                    <div className="w-full mt-2">
                      <div className="relative flex gap-4 overflow-x-auto">
                        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4  w-full">
                          {studentsList?.map((item, index) => (
                            <div
                              key={index}
                              onClick={() =>
                                navigate(`/admin/u/${customId}/QuickInfo/school_strength`, {
                                  state: {
                                    sumType: item?.sumType ?? "",
                                  },
                                })
                              }
                              className={`border border-white m-2 p-4 flex flex-col justify-between items-start cursor-pointer transform transition-transform duration-200 ease-in-out hover:scale-105 rounded-lg shadow-lg bg-gradient-to-r ${
                                index % 4 === 0
                                  ? "from-blue-200 to-blue-400 hover:from-blue-300 hover:to-blue-500"
                                  : index % 4 === 1
                                  ? "from-green-200 to-green-400 hover:from-green-300 hover:to-green-500"
                                  : index % 4 === 2
                                  ? "from-yellow-100 to-yellow-300 hover:from-yellow-200 hover:to-yellow-400"
                                  : "from-red-200 to-red-400 hover:from-red-300 hover:to-red-500"
                              } dark:bg-gray-800 dark:border-gray-700 `}
                            >
                              <div className="flex justify-between items-center w-full mb-4">
                                <h2 className="lg:text-lg font-spaceMono font-semibold text-gray-800 text-base ">{item?.Head1}</h2>
                                <svg
                                  className="w-6 h-6 text-gray-900 "
                                  fill="none"
                                  stroke="currentColor"
                                  viewBox="0 0 24 24"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M13 16h-1v-4h-1m1-4h.01M21 12c0 4.418-3.582 8-8 8s-8-3.582-8-8 3.582-8 8-8 8 3.582 8 8z"
                                  ></path>
                                </svg>
                              </div>
                              <div className="flex justify-between gap-2 w-full">
                                <p className="font-spaceMono font-semibold text-end whitespace-nowrap text-gray-900 ">{item?.Col1}</p>
                              </div>
                            </div>
                          ))}
                        </div>

                        <div className="formBackground w-full h-fit md:w-1/3 bg-white rounded-lg shadow-md border border-gray-200 dark:bg-gray-800 dark:border-gray-700 px-2 p-2 mt-1 flex flex-col gap-4">
                          <div className="flex flex-col ">
                            <div className="p-3">
                              <label htmlFor="admission-number" className="block font-spaceMono font-semibold text-purple-800">
                                Admission Number
                              </label>
                              <input
                                id="admission-number"
                                className="textInput w-full border border-gray-300 rounded-md p-2 mt-1"
                                value={admno}
                                onChange={(e) => setAdmno(e.target.value)}
                                type="text"
                              />
                            </div>
                            <div className="p-3 rela">
                              <label htmlFor="name-search" className="block font-spaceMono font-semibold text-purple-800">
                                Name
                              </label>
                              <input
                                id="name-search"
                                className="textInput w-full border border-gray-300 rounded-md p-2 mt-1"
                                value={nameSearch}
                                onChange={(e) => setNameSearch(e.target.value)}
                                type="text"
                              />
                            </div>
                            <div className="flex gap-2">
                              <button
                                onClick={() => setModal(true)}
                                className="buttonClass bg-purple-500 hover:bg-purple-600 text-white py-2 px-4 rounded-lg shadow-md"
                              >
                                Details
                              </button>
                              <button
                                onClick={() => handleStudnetModal()}
                                className="buttonClass bg-purple-500 hover:bg-purple-600 text-white py-2 px-4 rounded-lg shadow-md"
                              >
                                Show Info
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="rounded-lg shadow-lg w-full relative hidden">
                  <div className="max-w-full h-full p-4 md:mx-0 mx-auto  border border-black rounded-lg">
                    <h2 className="text-2xl font-robotoSlab  uppercase py-4 font-bold text-center bg-gradient-to-r from-purple-300 via-purple-400 to-purple-500 text-white rounded-lg shadow-md">
                      Staff Strength
                    </h2>
                    <div className="w-full mt-2">
                      <div className="relative overflow-x-auto flex ">
                        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4  w-full">
                          {StaffList?.map((item, index) => (
                            <div
                              key={index}
                              onClick={() =>
                                navigate(`/admin/u/${customId}/QuickInfo/staff_strength`, {
                                  state: {
                                    group: item?.Head1,
                                  },
                                })
                              }
                              className={`h-fit border border-white m-2 p-3 flex flex-col justify-between items-start cursor-pointer transform transition-transform duration-200 ease-in-out hover:scale-105 rounded-lg shadow-lg bg-gradient-to-r ${
                                index % 4 === 0
                                  ? "from-blue-200 to-blue-400 hover:from-blue-300 hover:to-blue-500"
                                  : index % 4 === 1
                                  ? "from-green-200 to-green-400 hover:from-green-300 hover:to-green-500"
                                  : index % 4 === 2
                                  ? "from-yellow-100 to-yellow-300 hover:from-yellow-200 hover:to-yellow-400"
                                  : "from-red-200 to-red-400 hover:from-red-300 hover:to-red-500"
                              } dark:bg-gray-800 dark:border-gray-700 `}
                            >
                              <div className="flex justify-between items-center w-full h-14">
                                <h2 className="text-lg font-spaceMono font-semibold text-gray-800 ">{item?.Head1}</h2>
                                <svg
                                  className="w-6 h-6 text-gray-900 "
                                  fill="none"
                                  stroke="currentColor"
                                  viewBox="0 0 24 24"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M13 16h-1v-4h-1m1-4h.01M21 12c0 4.418-3.582 8-8 8s-8-3.582-8-8 3.582-8 8-8 8 3.582 8 8z"
                                  ></path>
                                </svg>
                              </div>
                              <div className="flex justify-around gap-2 w-full">
                                <p className="font-spaceMono font-semibold text-end whitespace-nowrap text-gray-900 ">T-{item?.Col1}</p>
                                <p className="font-spaceMono font-semibold text-end whitespace-nowrap text-gray-900 ">A-{item?.absent}</p>
                              </div>
                            </div>
                          ))}
                        </div>

                        <div className="formBackground  h-fit w-full md:w-1/3 bg-white rounded-lg shadow-md border border-gray-200 dark:bg-gray-800 dark:border-gray-700 p-2 flex flex-col gap-2 mt-1">
                          <div className="">
                            <label htmlFor="emp-code" className="block font-spaceMono font-semibold text-purple-800 ">
                              EMP Code
                            </label>
                            <input
                              id="emp-code"
                              className="textInput w-full border border-gray-300 rounded-md p-2 mt-1"
                              value={empcode}
                              onChange={(e) => setempcode(e.target.value)}
                              type="text"
                            />
                          </div>
                          <div className="">
                            <label htmlFor="emp-name" className="block font-spaceMono font-semibold text-purple-800 ">
                              EMP Name
                            </label>
                            <input
                              id="emp-name"
                              className="textInput w-full border border-gray-300 rounded-md p-2 mt-1"
                              value={nameSearch2}
                              onChange={(e) => setNameSearch2(e.target.value)}
                              type="text"
                            />
                          </div>
                          <div className="flex gap-2">
                            <button
                              onClick={() => setModal2(true)}
                              className="buttonClass bg-purple-500 hover:bg-purple-600 text-white py-2 px-4 rounded-lg shadow-md"
                            >
                              Details
                            </button>
                            <button
                              onClick={() => setModal2(true)}
                              className="buttonClass bg-purple-500 hover:bg-purple-600 text-white py-2 px-4 rounded-lg shadow-md"
                            >
                              Show Info
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="rounded-lg shadow-lg w-full relative hidden">
                  <div className="max-w-full p-4 h-full mx-auto border border-black rounded-lg">
                    <h2 className="text-2xl font-robotoSlab  uppercase py-4 font-bold text-center bg-gradient-to-r from-purple-300 via-purple-400 to-purple-500 text-white rounded-lg shadow-md">
                      Fee Status
                    </h2>
                    <div className="w-full mt-2">
                      <div className="relative overflow-x-auto">
                        <div className="w-full">
                          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4  w-full py-1">
                            {FeeList?.map((item, index) => (
                              <div
                                key={item?.Slno}
                                onClick={() => {
                                  item.Head1 === "Total Demand" && navigate(`/admin/u/${customId}/QuickInfo/fee_summary`);
                                }}
                                className={`border border-white m-2 mt-1 p-6 flex flex-col justify-between items-start ${
                                  item.Head1 === "Total Demand" ? "cursor-pointer" : "cursor-default"
                                } transform transition-transform duration-200 ease-in-out hover:scale-105 rounded-lg shadow-lg bg-gradient-to-r ${
                                  index % 4 === 0
                                    ? "from-blue-200 to-blue-400 hover:from-blue-300 hover:to-blue-500"
                                    : index % 4 === 1
                                    ? "from-green-200 to-green-400 hover:from-green-300 hover:to-green-500"
                                    : index % 4 === 2
                                    ? "from-yellow-100 to-yellow-300 hover:from-yellow-200 hover:to-yellow-400"
                                    : "from-red-200 to-red-400 hover:from-red-300 hover:to-red-500"
                                } dark:bg-gray-800 dark:border-gray-700 `}
                              >
                                <div className="flex justify-between items-center w-full mb-4">
                                  <h2 className="lg:text-lg text-base font-spaceMono font-semibold text-gray-800 ">{item?.Head1}</h2>
                                  <svg
                                    className="w-6 h-6 text-gray-900 "
                                    fill="none"
                                    stroke="currentColor"
                                    viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="2"
                                      d="M13 16h-1v-4h-1m1-4h.01M21 12c0 4.418-3.582 8-8 8s-8-3.582-8-8 3.582-8 8-8 8 3.582 8 8z"
                                    ></path>
                                  </svg>
                                </div>
                                <p className="lg:text-xl text-lg font-spaceMono font-semibold text-end text-gray-800 ">{item?.Col1}</p>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-full">
                <div className=" justify-evenly  mx-auto">
                  <Accordion>
                    <Accordion.Panel className="p-2 rounded-lg shadow-lg w-full  relative bg-sky-50">
                      <Accordion.Title className="mt-2 !text-white uppercase bg-gradient-to-r from-cyan-500 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-cyan-300 dark:focus:ring-cyan-800 font-medium rounded-lg text-sm px-5 py-1.5 mr-4">
                        Students Strength
                      </Accordion.Title>
                      <Accordion.Content className="p-0 rounded-lg shadow-lg mt-1">
                        <div className="max-w-full px-5 py-3 md:mx-0 mx-auto border border-gray-200 rounded-lg shadow-lg">
                          <div className="w-full">
                            <div className="relative flex-col gap-1 overflow-x-auto">
                              <div className="bg-gradient-to-r from-blue-300 via-blue-400 to-blue-500 w-full backdrop-blur-md backdrop-brightness-50 border-b dark:bg-gray-800 dark:border-gray-700 flex justify-between items-center flex-col rounded-lg shadow-md p-4">
                                <div className="grid grid-cols-1 md:grid-cols-2 w-full justify-start">
                                  <div className="p-3 w-full md:flex justify-start gap-3 relative">
                                    <label
                                      htmlFor="name-search"
                                      className="block font-spaceMono md:whitespace-nowrap font-semibold text-purple-800"
                                    >
                                      Student Name
                                    </label>
                                    <div className="relative w-full">
                                      <input
                                        id="name-search"
                                        className="textInput border border-gray-300 rounded-md p-2 mt-1"
                                        value={nameSearch}
                                        autoComplete="off"
                                        onChange={(e) => {
                                          setNameSearch(e.target.value);
                                          STDSearch(e.target.value);
                                        }}
                                        type="text"
                                      />
                                      {showSearchItems()}
                                    </div>
                                  </div>
                                  <div className="p-3 text-slate-800 font-normal w-full md:flex block gap-3 justify-start">
                                    <label htmlFor="" className="font-spaceMono md:whitespace-nowrap text-purple-800 font-semibold ">
                                      Admission Number
                                    </label>
                                    <input
                                      className="textInput p-2 rounded-md mt-2"
                                      value={admno}
                                      onChange={(e) => setAdmno(e.target.value)}
                                      type="text"
                                    />
                                  </div>
                                </div>

                                <div className="pb-1 flex md:gap-2 gap-1 mt-2">
                                  <button
                                    onClick={() =>
                                      //    modal
                                      setModal(true)
                                    }
                                    className="buttonClass bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded-md transition duration-200"
                                  >
                                    Details
                                  </button>
                                  <button
                                    onClick={() =>
                                      //    modal
                                      handleStudnetModal()
                                    }
                                    className="buttonClass bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded-md transition duration-200"
                                  >
                                    Show Info
                                  </button>
                                </div>
                              </div>
                              <div className="grid grid-cols-1 sm:grid-cols-2 z-10 md:grid-cols-3 lg:grid-cols-4 gap-1 mt-2">
                                {studentsList?.map((item, index) => (
                                  <div
                                    onClick={() =>
                                      navigate(`/admin/u/${customId}/QuickInfo/school_strength`, {
                                        state: {
                                          sumType: item?.sumType ?? "",
                                        },
                                      })
                                    }
                                    key={item?.Slno}
                                    className={`border  border-white h-fit mt-1 p-4 flex justify-between items-start cursor-pointer transform transition-transform duration-200 ease-in-out  rounded-lg shadow-lg ${
                                      index % 4 === 0
                                        ? "bg-gradient-to-r from-blue-200 to-blue-400 hover:from-blue-300 hover:to-blue-500"
                                        : index % 4 === 1
                                        ? "bg-gradient-to-r from-green-200 to-green-400 hover:from-green-300 hover:to-green-500"
                                        : index % 4 === 2
                                        ? "bg-gradient-to-r from-yellow-100 to-yellow-300 hover:from-yellow-200 hover:to-yellow-400"
                                        : "bg-gradient-to-r from-red-200 to-red-400 hover:from-red-300 hover:to-red-500"
                                    } dark:bg-gray-800 dark:border-gray-700`}
                                  >
                                    <h2 className="font-spaceMono font-semibold text-black ">{item?.Head1}</h2>
                                    <p className="font-spaceMono font-semibold text-end text-black ">{item?.Col1}</p>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      </Accordion.Content>
                    </Accordion.Panel>
                    <Accordion.Panel className="p-2 rounded-lg shadow-lg w-full  relative bg-sky-50">
                      <Accordion.Title className="mt-2 !text-white uppercase bg-gradient-to-r from-cyan-500 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-cyan-300 dark:focus:ring-cyan-800 font-medium rounded-lg text-sm px-5 py-1.5 mr-4">
                        Class Strength
                      </Accordion.Title>
                      <Accordion.Content className="p-0 rounded-lg shadow-lg mt-1">
                        <div className="max-w-full px-5 py-3 md:mx-0 mx-auto border border-gray-200 rounded-lg shadow-lg">
                          <div className="w-full">
                            <div className="relative flex-col gap-1 overflow-x-auto">
                              <div className="grid grid-cols-1 sm:grid-cols-2 z-10 md:grid-cols-3 lg:grid-cols-4 gap-1 mt-2">
                                {erpClass?.map((item, index) => (
                                  <div
                                    onClick={() => selectedERPClass(item?.Location)}
                                    key={item?.Code}
                                    className={`border border-white h-fit mt-1 p-4 flex flex-col justify-between items-start cursor-pointer transform transition-transform duration-200 ease-in-out  rounded-lg shadow-lg ${
                                      index % 4 === 0
                                        ? "bg-gradient-to-r from-blue-200 to-blue-400 hover:from-blue-300 hover:to-blue-500"
                                        : index % 4 === 1
                                        ? "bg-gradient-to-r from-green-200 to-green-400 hover:from-green-300 hover:to-green-500"
                                        : index % 4 === 2
                                        ? "bg-gradient-to-r from-yellow-100 to-yellow-300 hover:from-yellow-200 hover:to-yellow-400"
                                        : "bg-gradient-to-r from-red-200 to-red-400 hover:from-red-300 hover:to-red-500"
                                    } dark:bg-gray-800 dark:border-gray-700`}
                                  >
                                    <h2 className="font-spaceMono font-semibold text-gray-800 ">{item?.Location}</h2>
                                    <div className="flex justify-between items-center flex-row w-full">
                                      <p className="font-spaceMono font-semibold text-end text-black ">Total-{item?.NofStd}</p>
                                      <p className="font-spaceMono font-semibold text-end text-black ">Active-{item?.NofStdA}</p>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      </Accordion.Content>
                    </Accordion.Panel>
                    <Accordion.Panel className="p-2 rounded-lg shadow-lg w-full  relative bg-sky-50">
                      <Accordion.Title className="mt-2 !text-white uppercase bg-gradient-to-r from-cyan-500 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-cyan-300 dark:focus:ring-cyan-800 font-medium rounded-lg text-sm px-5 py-1.5 mr-4">
                        Staff Strength
                      </Accordion.Title>
                      <Accordion.Content className="p-0 rounded-lg shadow-lg mt-1">
                        <div className="max-w-full px-5 py-3 md:mx-0 mx-auto border border-gray-200 rounded-lg shadow-lg ">
                          <div className="w-full">
                            <div className="relative overflow-x-auto flex-col gap-1 justify-around">
                              <div className="bg-gradient-to-r from-blue-300 via-blue-400 to-blue-500 w-full backdrop-blur-md backdrop-brightness-50 border-b dark:bg-gray-800 dark:border-gray-700 flex justify-between items-center flex-col rounded-lg shadow-md p-4">
                                <div className="grid grid-cols-1 md:grid-cols-2 w-full justify-start">
                                  <div className="w-full p-2 md:flex block justify-start gap-3">
                                    <label
                                      htmlFor="emp-name"
                                      className="block font-spaceMono md:whitespace-nowrap font-semibold text-purple-800 "
                                    >
                                      EMP Name
                                    </label>
                                    <div className="relative w-full">
                                      <input
                                        id="emp-name"
                                        className="textInput border border-gray-300 rounded-md p-2"
                                        value={nameSearch2}
                                        autoComplete="off"
                                        onChange={(e) => {
                                          setNameSearch2(e.target.value);
                                          EMPSearch(e.target.value);
                                        }}
                                        type="text"
                                      />
                                      {showSearchItems2()}
                                    </div>
                                  </div>
                                  <div className="p-2 w-full md:flex block gap-3 justify-start">
                                    <label htmlFor="" className="block font-spaceMono md:whitespace-nowrap font-semibold text-purple-800 ">
                                      EMP Code
                                    </label>
                                    <input
                                      className="textInput p-2 rounded-md"
                                      value={empcode}
                                      onChange={(e) => setempcode(e.target.value)}
                                      type="text"
                                    />
                                  </div>
                                </div>

                                <div className="pb-1 flex md:gap-2 gap-1 mt-2">
                                  <button
                                    onClick={() =>
                                      //    modal
                                      setModal2(true)
                                    }
                                    className="buttonClass bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded-md transition duration-200"
                                  >
                                    Details
                                  </button>
                                  <button
                                    onClick={() =>
                                      //    modal
                                      setModal2(true)
                                    }
                                    className="buttonClass bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded-md transition duration-200"
                                  >
                                    Show Info
                                  </button>
                                </div>
                              </div>
                              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2 mt-4">
                                {StaffList?.map((item, index) => (
                                  <div
                                    key={index}
                                    onClick={() =>
                                      navigate(`/admin/u/${customId}/QuickInfo/staff_strength`, {
                                        state: {
                                          group: item?.Head1,
                                        },
                                      })
                                    }
                                    className={`border border-white p-4 mt-1 flex flex-col justify-between items-center cursor-pointer transform transition-transform duration-200 ease-in-out  rounded-lg shadow-lg ${
                                      index % 4 === 0
                                        ? "bg-gradient-to-r from-blue-200 to-blue-400 hover:from-blue-300 hover:to-blue-500"
                                        : index % 4 === 1
                                        ? "bg-gradient-to-r from-green-200 to-green-400 hover:from-green-300 hover:to-green-500"
                                        : index % 4 === 2
                                        ? "bg-gradient-to-r from-yellow-100 to-yellow-300 hover:from-yellow-200 hover:to-yellow-400"
                                        : "bg-gradient-to-r from-red-200 to-red-400 hover:from-red-300 hover:to-red-500"
                                    } dark:bg-gray-800 dark:border-gray-700`}
                                  >
                                    <div className="text-center w-full">
                                      <h2 className="font-spaceMono text-base font-semibold text-gray-800 ">{item?.Head1}</h2>
                                    </div>
                                    <div className="flex justify-around gap-2 w-full p-1">
                                      <p className="font-spaceMono font-semibold text-black ">T-{item?.Col1}</p>
                                      <p className="font-spaceMono font-semibold text-black ">A-{item?.absent}</p>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      </Accordion.Content>
                    </Accordion.Panel>
                    <Accordion.Panel className="p-2 rounded-lg shadow-lg w-full  relative bg-sky-50">
                      <Accordion.Title className="mt-2 !text-white uppercase bg-gradient-to-r from-cyan-500 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-cyan-300 dark:focus:ring-cyan-800 font-medium rounded-lg text-sm px-5 py-1.5 mr-4">
                        Fee Status
                      </Accordion.Title>
                      <Accordion.Content className="p-0 rounded-lg shadow-lg mt-1">
                        <div className="max-w-full h-full mx-auto border border-gray-200 rounded-lg shadow-lg ">
                          <div className="w-full">
                            <div className="relative overflow-x-auto">
                              <div className="w-full">
                                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2 p-4">
                                  {FeeList?.map((item, index) => (
                                    <div
                                      key={item?.Slno}
                                      onClick={() => {
                                        item.Head1 === "Total Demand" && navigate(`/admin/u/${customId}/QuickInfo/fee_summary`);
                                      }}
                                      className={`border border-white p-4 h-fit mt-1 flex flex-col justify-between items-start ${
                                        item.Head1 === "Total Demand" ? "cursor-pointer" : "cursor-default"
                                      } transform transition-transform duration-200 ease-in-out  rounded-lg shadow-lg ${
                                        index % 4 === 0
                                          ? "bg-gradient-to-r from-green-200 to-green-400 hover:from-green-300 hover:to-green-400"
                                          : index % 4 === 1
                                          ? "bg-gradient-to-r from-blue-200 to-blue-400 hover:from-blue-300 hover:to-blue-500"
                                          : index % 4 === 2
                                          ? "bg-gradient-to-r from-yellow-100 to-yellow-300 hover:from-yellow-200 hover:to-yellow-400"
                                          : "bg-gradient-to-r from-red-200 to-red-400 hover:from-red-300 hover:to-red-500"
                                      } dark:bg-gray-800 dark:border-gray-700`}
                                    >
                                      <div className="text-center w-full">
                                        <h2 className="font-spaceMono font-semibold text-gray-800 ">{item?.Head1}</h2>
                                      </div>
                                      <p className="text-end text-lg font-spaceMono font-semibold p-1 text-black ">{item?.Col1}</p>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Accordion.Content>
                    </Accordion.Panel>
                  </Accordion>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default QuickInfo;
