import React, { useState, useEffect } from "react";
import Navbar from "../../components/Navbar";
import Sidebar from '../../components/Sidebar';
import { useSidebar } from "../../context/SidebarContext";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import Spinner from "../../components/Spinner";
import { AiOutlineClose } from "react-icons/ai";
import { IoPersonSharp } from "react-icons/io5";
import { getUserDataFromLocalByKey } from "../../API/getStudentInfoByKeyLocal";
import { BsClockFill } from "react-icons/bs";
import greenTickIcon from "../../assets/png/greenTickIcon.png";
import { getAdminCommSearchList } from "../../API/getAdminCommSearchListAPI";
function AdminCommListPage() {
  const [userType, setUserType] = useState("");
  const [userDetails, setUserDetails] = useState(null);
  const [data, setData] = useState(true);
  const { customId, FromDate, ToDate, Message, Class, Section } = useParams();
  const [loading, setLoading] = useState(true);
  const [commList, setCommList] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [audio, setAudio] = useState(new Audio());
  const [audioteacher, setAudioTeacher] = useState(new Audio());
  const [isPlaying, setIsPlaying] = useState(false);
  const [isPlayingTeacher, setIsPlayingTeacher] = useState(false);
  const [requestMessage, setRequestMessage] = useState("");
  const [selectedComm, setSelectedComm] = useState(null);
  const [initialResourceUrl, setInitialResourceUrl] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredNotifications, setFilteredNotifications] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const users = JSON.parse(localStorage.getItem("registeredUsers")) || [];

      if (customId) {
        const decodedData = JSON.parse(atob(customId));
        let matchedItem = [];

        users.forEach((innerArray) => {
          const [userData] = innerArray;

          if (
            userData.userId === decodedData.userId &&
            userData.userType === decodedData.userType &&
            userData.schoolId === decodedData.schoolId &&
            userData.year === decodedData.year
          ) {
            matchedItem.push(userData);
          }
        });

        const result = await getAdminCommSearchList(
          decodedData.userId,
          decodedData.schoolId,
          decodedData.userType,
          decodedData.year,
          FromDate == 'null' ? "" : FromDate,
          ToDate == 'null' ? "" : ToDate,
          Message == 'null' ?  "" : Message,
          Class == 'null' ? "" : Class,
          Section == 'null' ? "" : Section
        );

        const keyval = "webResourceURL";
        const dataUrl = await getUserDataFromLocalByKey(
          decodedData.userId,
          decodedData.schoolId,
          decodedData.userType,
          decodedData.year,
          keyval
        );
        setInitialResourceUrl(dataUrl);

        setCommList(result);

        console.log(result);
        setLoading(false);
        setUserDetails(matchedItem);
      }
    };

    fetchData();
  }, [customId]);

  //console.log(commList);

  const handleSearch = (value) => {
    setSearchTerm(value);

    // If the search term is empty, reset filtered notifications
    if (value.trim() === "") {
      setFilteredNotifications([]);
    } else {
      // Filter notifications based on the search term
      const filtered = commList.filter((notification) =>
        notification.HWSubject.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredNotifications(filtered);
    }
  };

  const openPopup = (item) => {
    setSelectedComm(item);
    setShowPopup(true);
  };
  
  const closePopup = () => {
    setShowPopup(false);
    setSelectedComm(null);
  };

  const togglePlay = (studentAudio) => {
   audio.src = studentAudio;
   console.log(studentAudio);
    if (isPlaying) {
      audio.pause();
    } else {
      audio.play();
    }
    setIsPlaying(!isPlaying);
  };

  const togglePlayTeacher = (TechAudio) => {
    audioteacher.src = TechAudio;
    if (isPlayingTeacher) {
      audioteacher.pause();
    } else {
      audioteacher.play();
    }
    setIsPlayingTeacher(!isPlayingTeacher);
  };

  const { isSidebarOpen, currentSidebarTab, toggleSidebar,setIsSidebarOpen } = useSidebar();

  const notificationsToRender = searchTerm ? filteredNotifications : commList;

  return (
    <>
      {data && (
        <Navbar
          data={userDetails}
          // isOpen={sidebarVisible}
          toggleSidebar={toggleSidebar}
        />
      )}

      <div className="flex">
        {data && <Sidebar data={userDetails} />}

        {loading ? (
          <Spinner />
        ):(
          <div className={`w-full absolute ${isSidebarOpen?"lg:w-4/6 xl:w-9/12":"w-full"} sm:w-11/12 right-0 top-16 mx-auto   p-4 md:p-6 lg:p-8 xl:p-10`}>
          <div className="lg:flex items-center justify-between mb-4">
            <div className="w-2/3">
              <h2 className=" md:text-2xl font-bold text-xl mb-2 md:mb-0">
                Admin :
                {userDetails &&
                  userDetails.length > 0 &&
                  userDetails[0].schoolId}
              </h2>
              <h4 className="mb-2 font-bold">Communication List</h4>
            </div>
            <div className=" w-2/6">
              <input
                type="text"
                placeholder="Search..."
                value={searchTerm}
                onChange={(e) => handleSearch(e.target.value)}
                className="border border-gray-300 px-3 py-2 rounded-md mr-2 focus:border-blue-500 focus:outline-none"
              />
            </div>
          </div>

          {notificationsToRender &&
            notificationsToRender.map((item) => (
              <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-4 mb-2">
                <div key={item.id}  onClick={() => openPopup(item)} className="cursor-pointer relative">
                  <div className="border border-gray-300 rounded-lg p-4 hover:shadow-md transition duration-300">
                    <p className="text-lg  mb-2">Subject : {item.HWSubject}</p>
                    <p className="text-lg  mb-2">Meassage : {item.HWRemarks}</p>
                    <p className="text-lg  mb-2">Date : {item.HWDateStr}</p>
                    <p className="text-lg  mb-2">Reply : {item.TchReply}</p>
                    <div className="absolute top-0 right-0 p-2 flex items-center">
                      {item.TchReply ? (
                        <>
                          <img
                            src={greenTickIcon}
                            alt="Thumbnail"
                            className="w-10 h-10 object-cover rounded ml-2"
                          />
                          <span className="text-gray px-2 py-1 rounded">
                            Status: Replied
                          </span>
                        </>
                      ) : (
                        <>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <span className="object-cover rounded ml-2">
                              {<BsClockFill />}
                            </span>
                            <span className="text-gray px-2 py-1 rounded">
                              Status: Pending
                            </span>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
        )}

       
      </div>

      {showPopup && (
            <div className="fixed inset-0 flex items-center justify-center mt-10 bg-black bg-opacity-60">
              <div className="bg-white customPopupHeight relative rounded-lg mt-8 shadow-lg p-8 md:w-1/2 w-full overflow-y-auto ">
                <button
                  onClick={closePopup}
                  className="px-4 py-2  absolute right-5 top-0 mt-8 bg-red-600 text-white rounded"
                >
                  <AiOutlineClose size={24} />
                </button>
                <div className="bg-slate-50 shadow-md  rounded px-8 pt-6 pb-8 mb-4">
            <div className="mb-6 mt-12  flex items-center">
              <IoPersonSharp className="h-16 w-16 mr-4 text-blue-500" />
              <div>
                <label className="block text-gray-700 font-bold mb-2">
                  Teacher Name: 
                </label>
                <label className="block text-gray-700 font-bold mb-2">
                  Class : <span className="font-medium">{selectedComm.HWClass}- {selectedComm.HWSection}</span> 
                  Date: <span className="font-medium">{selectedComm.HWDateStr}</span> 
                  Subject: <span className="font-medium">{selectedComm.HWSubject}</span> 
                </label>
              </div>
            </div>

            <div className="mb-4">
              <div className="border border-red-500 p-4 rounded">
                <label className="block text-red-500 font-semibold mb-2 text-center">
                  Parent's Message
                </label>
                <p className="text-center">{selectedComm.HWRemarks}</p>
              </div>

              {/* Center the button using Flexbox */}
              <div className="flex justify-center mt-4">
                <button
                  onClick={() => togglePlay(selectedComm.SoundClipStudent)}
                  className="bg-green-500 text-white font-semibold px-4 py-2 rounded shadow-md focus:outline-none"
                >
                  {isPlaying ? "Pause" : "Play Student Request"}
                </button>
              </div>

              <div className="mb-4">
                {/* Center the button using Flexbox */}
                <div className="flex justify-center mt-4">
                  {selectedComm.TchReply && selectedComm.TchReply !== "null" && selectedComm.TchReply !== "" && (
                    <button
                    onClick={() => togglePlayTeacher(selectedComm.SoundClipTeacher)}
                      className="bg-green-500 text-white font-semibold px-4 py-2 rounded shadow-md focus:outline-none"
                    >
                      {isPlayingTeacher ? "Pause" : "Play Teacher Reply"}
                    </button>
                  )}
                </div>
              </div>
            </div>

            <div className="mb-4">
              <label className="block text-gray-700 font-semibold mb-2">
                Mentors Response
              </label>
              <textarea
                value={selectedComm.TchReply ? selectedComm.TchReply : "No Reply"}
                className="border border-gray-300 rounded px-3 py-2 w-full focus:outline-none"
                rows="5"
              ></textarea>
            </div>
          </div>
              
              </div>
            </div>
          )}
    </>
  );
}

export default AdminCommListPage;
