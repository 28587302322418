import { Trash2 } from "lucide-react";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { deleteHWSMNotification } from "../../API/deleteHWSMNotification";
import { getAdminNotificationSearchList } from "../../API/getAdminNotificationSearchListAPI";
import { getUserDataFromLocalByKey } from "../../API/getStudentInfoByKeyLocal";
import Excel from "../../assets/Files/excel.svg";
import Images from "../../assets/Files/images.png";
import AudioIcon from "../../assets/Files/musicIcon.png";
import OtherImg from "../../assets/Files/otherico.png";
import PDFImage from "../../assets/Files/pdficconpng.png";
import powerpointIcon from "../../assets/Files/PPTIcon.png";
import textMessage from "../../assets/Files/textmsg.png";
import VideoIcon from "../../assets/Files/Video.png";
import WordIcon from "../../assets/Files/wordicon.png";
import Youtube from "../../assets/Files/youtube.png";
import DeleteModal from "../../components/DeleteModal";
import Navbar from "../../components/Navbar";
import Sidebar from "../../components/Sidebar";
import { useSidebar } from "../../context/SidebarContext";

function AdminNotificationListPage() {
  const [userDetails, setUserDetails] = useState(null);
  const [data, setData] = useState(true);
  const { customId, FromDate, ToDate, Message, Class, Section } = useParams();
  const [loading, setLoading] = useState(true);
  const [notificationList, setNotificationList] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [selectedNotification, setSelectedNotification] = useState(null);
  const [filteredNotifications, setFilteredNotifications] = useState([]);
  const [initialResourceUrl, setInitialResourceUrl] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedDltNotification, setSelectedDltNotification] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const users = JSON.parse(localStorage.getItem("registeredUsers")) || [];

      if (customId) {
        const decodedData = JSON.parse(atob(customId));
        let matchedItem = [];

        users.forEach((innerArray) => {
          const [userData] = innerArray;

          if (
            userData.userId === decodedData.userId &&
            userData.userType === decodedData.userType &&
            userData.schoolId === decodedData.schoolId &&
            userData.year === decodedData.year
          ) {
            matchedItem.push(userData);
          }
        });

        const result = await getAdminNotificationSearchList(
          decodedData.userId,
          decodedData.schoolId,
          decodedData.userType,
          decodedData.year,
          FromDate === "null" ? "" : FromDate,
          ToDate === "null" ? "" : ToDate,
          Message === "null" ? "" : Message,
          Class === "null" ? "" : Class,
          Section === "null" ? "" : Section
        );

        const keyval = "webServiceURLAWSDOTNET";
        const dataUrl = await getUserDataFromLocalByKey(
          decodedData.userId,
          decodedData.schoolId,
          decodedData.userType,
          decodedData.year,
          keyval
        );
        setInitialResourceUrl(dataUrl);
        setNotificationList(result);
        setLoading(false);
        setUserDetails(matchedItem);
      }
    };

    fetchData();
  }, [customId, isDeleteModalOpen]);

  const handleConfirmDelete = async () => {
    try {
      const response = await deleteHWSMNotification(
        userDetails[0].userId,
        userDetails[0].schoolId,
        userDetails[0].userType,
        userDetails[0].year,
        "NOTF",
        selectedDltNotification?.HWID
      );

      toast.success(response[0].InfoField);
    } catch (error) {
      console.log(error);
    }

    setIsDeleteModalOpen(false);
    setSelectedDltNotification(null);
  };

  const handleDeleteClick = (notification, e) => {
    e.stopPropagation();
    setSelectedDltNotification(notification);
    setIsDeleteModalOpen(true);
  };

  const handleCancelDelete = () => {
    setIsDeleteModalOpen(false);
    setSelectedDltNotification(null);
  };

  const handleSearch = (value) => {
    setSearchTerm(value);

    if (value.trim() === "") {
      setFilteredNotifications([]);
    } else {
      const filtered = notificationList.filter((notification) => notification.HWSubject.toLowerCase().includes(value.toLowerCase()));
      setFilteredNotifications(filtered);
    }
  };

  const renderImage = (photoLocation) => {
    console.log(photoLocation);

    if (photoLocation === "") {
      return textMessage;
    }

    if (photoLocation.endsWith(".jpg")) {
      // setContentType('Same')
      return Images;
    } else if (photoLocation.endsWith(".pdf")) {
      // setContentType("Same")
      return PDFImage;
    } else if (photoLocation.includes(".ppt")) {
      // setContentType("Same")
      return powerpointIcon;
    } else if (photoLocation.includes(".doc")) {
      // setContentType("Same")
      return WordIcon;
    } else if (photoLocation.includes(".xls")) {
      // setContentType("Same")
      return Excel;
    } else if (photoLocation.includes(".mp4")) {
      // setContentType("Same")
      return VideoIcon;
    } else if (photoLocation.includes(".mp3")) {
      // setContentType("Same")
      return AudioIcon;
    } else if (photoLocation.includes("v=")) {
      return Youtube;
    } else {
      return OtherImg;
    }
  };

  const checkContentType = () => {
    if (selectedNotification.PhotoLocation.includes("v=")) {
      return (
        <iframe
          title="Notification Content"
          src={`https://www.youtube.com/embed/${selectedNotification.PhotoLocation.split("v=")[1].split("&")[0]}`}
          // src={`https://www.youtube.com/embed/${selectedNotification.PhotoLocation}`}
          className="w-full h-56 border-none"
          style={{ overflow: "auto" }}
        ></iframe>
      );
    }
    if (selectedNotification.PhotoLocation === "") {
      return null;
    } else {
      return (
        <iframe
          title="Notification Content"
          src={`${initialResourceUrl}/Notification/${selectedNotification.PhotoLocation}`}
          className="w-full h-56 border-none"
          style={{ overflow: "auto" }}
        ></iframe>
      );
    }
  };

  const openPopup = (notification) => {
    setSelectedNotification(notification);
    setShowPopup(true);
  };

  const closePopup = () => {
    setShowPopup(false);
    setSelectedNotification(null);
  };

  const notificationsToRender = searchTerm ? filteredNotifications : notificationList;

  const { isSidebarOpen, toggleSidebar } = useSidebar();

  return (
    <>
      <DeleteModal isOpen={isDeleteModalOpen} onClose={handleCancelDelete} onConfirm={handleConfirmDelete} title={"Notification"} />
      {data && (
        <Navbar
          data={userDetails}
          // isOpen={sidebarVisible}
          toggleSidebar={toggleSidebar}
        />
      )}

      <div className="flex">
        {data && <Sidebar data={userDetails} />}

        <div
          className={`w-full absolute ${
            isSidebarOpen ? "lg:w-4/6 xl:w-9/12" : "w-full"
          } sm:w-11/12 right-0 top-16 mx-auto   p-4 md:p-6 lg:p-8 xl:p-10`}
        >
          <div className="lg:flex items-center justify-between mb-4">
            <div className="w-2/3">
              <h2 className=" md:text-2xl font-bold text-xl mb-2 md:mb-0">
                Admin :{userDetails && userDetails.length > 0 && userDetails[0].schoolId}
              </h2>
              <h4 className="mb-2 font-bold">Notification List</h4>
            </div>
            <div className=" w-2/6">
              <input
                type="text"
                placeholder="Search..."
                value={searchTerm}
                onChange={(e) => handleSearch(e.target.value)}
                className="border border-gray-300 px-3 py-2 rounded-md mr-2 focus:border-blue-500 focus:outline-none"
              />
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 max-h-[500px] overflow-auto gap-4">
            {notificationsToRender && notificationsToRender.length > 0 ? (
              notificationsToRender.map((notification) => (
                <div
                  className="flex justify-between items-center border border-gray-200 rounded-lg  hover:shadow-md transition duration-300"
                  key={notification.HWID}
                >
                  <Link
                    to={""}
                    onClick={() => openPopup(notification)}
                    className="flex-grow " // Ensures the Link takes available space
                  >
                    <div className=" p-4 ">
                      <div className="flex items-center">
                        <div className="w-1/3 sm:w-auto mr-4">
                          <img
                            src={renderImage(notification.PhotoLocation)}
                            alt={notification.title}
                            className="w-20 h-20 sm:w-32 sm:h-32 object-cover rounded"
                          />
                        </div>

                        <div className="w-2/3">
                          <h2 className="text-lg font-semibold mb-2">{notification.HWSubject}</h2>
                          <h4 className="text-lg font-semibold mb-2">{notification.HWDateStr}</h4>
                          <p className="text-gray-600">{notification.HWRemarks.substring(0, 50)}</p>
                        </div>
                      </div>
                    </div>
                  </Link>

                  <button
                    onClick={(e) => handleDeleteClick(notification, e)}
                    className="text-red-500 hover:text-red-700 p-2 rounded-full hover:bg-red-50"
                  >
                    <Trash2 size={20} />
                  </button>
                </div>
              ))
            ) : (
              <p>No data found</p>
            )}
          </div>

          {showPopup && (
            <div className="fixed inset-0 flex items-center justify-center mt-10 bg-black bg-opacity-60">
              <div className="bg-white customPopupHeight relative rounded-lg mt-8 shadow-lg p-8 md:w-1/2">
                <div className="w-1/3 sm:w-auto mr-4">
                  <img src={renderImage(selectedNotification.PhotoLocation)} alt="" className="w-20  sm:w-32  object-cover rounded" />
                </div>

                <button onClick={closePopup} className="px-4 py-2  absolute right-5 top-0 mt-8 bg-red-600 text-white rounded">
                  Close
                </button>

                <div className="w-2/3 ">
                  <h2 className="text-lg font-semibold mb-2">{selectedNotification.HWSubject}</h2>
                  <h4 className="text-lg font-semibold mb-2">{selectedNotification.HWDateStr}</h4>
                  <p className="text-gray-600">{selectedNotification.HWRemarks}</p>
                </div>

                {checkContentType()}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default AdminNotificationListPage;
