import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import Spinner from "../../components/Spinner";
import useSidebar from "../../hooks/useSidebar";
import students from "../../assets/Dashboard/students.jpg";
import Teacher from "../../assets/Dashboard/Teacher.jpg";
import Calendar from "../../assets/png/calendar_clock_icon.png";
import Notification from "../../assets/Dashboard/notification.svg";
import { getUserDataFromLocalByKey } from "../../API/getStudentInfoByKeyLocal";

function FeesAnalysesDashboard() {
    const { customId } = useParams();
    const [userDetails, setUserDetails] = useState(null);
    const [loading, setLoading] = useState(true);
    const [initialResourceUrl, setInitialResourceUrl] = useState(null);
    const navigate = useNavigate();

    const menuItems = [
        {
            icon: students,
            keyName: "AdmFeeDCS",
            caption: "Demand and Collection Summary",
            actiontype: "btn",
            type: "menu",
        },
        {
            icon: Teacher,
            keyName: "AdmFeeSDL",
            caption: "Student Wise Defaulters List",
            actiontype: "btn",
            type: "menu",
        },
        {
            icon: Calendar,
            keyName: "AdmFeeDueSumm",
            caption: "Fee Dues Summary As on Date",
            actiontype: "btn",
            type: "menu",
        },
        {
            icon: Notification,
            keyName: "AdmFeeMOP",
            caption: "Date Wise Receipt MOP Summary",
            actiontype: "btn",
            type: "menu",
        },
        {
            icon: Notification,
            keyName: "AdmFeeHead",
            caption: "Date Wise Receipt Headwise Summary",
            actiontype: "btn",
            type: "menu",
        },
        {
            icon: Notification,
            keyName: "AdmFeeMonth",
            caption: "Date Wise Receipt Month wise Summary",
            actiontype: "btn",
            type: "menu",
        },
    ];

    useEffect(() => {
        const fetchData = async () => {
            const users = JSON.parse(localStorage.getItem("registeredUsers")) || [];

            if (customId) {
                const decodedData = JSON.parse(atob(customId));
                let matchedItem = [];

                users.forEach((innerArray) => {
                    const [userData] = innerArray;

                    if (
                        userData.userId === decodedData.userId &&
                        userData.userType === decodedData.userType &&
                        userData.schoolId === decodedData.schoolId &&
                        userData.year === decodedData.year
                    ) {
                        matchedItem.push(userData);
                    }
                });

                const keyval = "webResourceURL";
                const dataUrl = await getUserDataFromLocalByKey(
                    decodedData.userId,
                    decodedData.schoolId,
                    decodedData.userType,
                    decodedData.year,
                    keyval
                );
                setInitialResourceUrl(dataUrl);
                setLoading(false);
                setUserDetails(matchedItem);
            }
        };

        fetchData();
    }, [customId]);

    const { sidebarVisible, toggleSidebar } = useSidebar();

    const handleMenuItemClick = (keyName) => {
        if (keyName === "AdmFeeDCS") {
            navigate(`/admin/u/${keyName}/${customId}/FeeDCS`);
        } else if (keyName === "AdmFeeSDL") {
            navigate(`/admin/u/${keyName}/${customId}/FeeStuWiseDL`);
        } else if (keyName === "AdmFeeDueSumm") {
            navigate(`/admin/u/${keyName}/${customId}/FeeDuesDate`);
        } else if (keyName === "AdmFeeMOP") {
            navigate(`/admin/u/${keyName}/${customId}/FeeDateWiseMOP`);
        } else if (keyName === "AdmFeeHead") {
            navigate(`/admin/u/${keyName}/${customId}/FeeHeadWise`);
        } else if (keyName === "AdmFeeMonth") {
            navigate(`/admin/u/${keyName}/${customId}/FeeMonthWise`);
        }
        // Add more conditions for other menu items if needed
    };
    return (
        <>
           <div className="md:mt-6 mt-10">
                <div className="p-2 mb-3 flex items-center w-fit flex-wrap">
                    <ul className="flex items-center">
                        <li className="inline-flex items-center cursor-default">
                            <a href="/" className="text-black font-medium hover:scale-105">
                                <svg
                                    className="w-5 cursor-default h-auto fill-current mx-2"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    fill="#000000"
                                >
                                    <path d="M0 0h24v24H0V0z" fill="none" />
                                    <path d="M10 19v-5h4v5c0 .55.45 1 1 1h3c.55 0 1-.45 1-1v-7h1.7c.46 0 .68-.57.33-.87L12.67 3.6c-.38-.34-.96-.34-1.34 0l-8.36 7.53c-.34.3-.13.87.33.87H5v7c0 .55.45 1 1 1h3c.55 0 1-.45 1-1z" />
                                </svg>
                            </a>

                            <span className="mx-4 h-auto font-medium">/</span>
                        </li>
                        <li className="inline-flex items-center">
                            <p className="text-black font-medium cursor-default hover:scale-105">Fee Analyses</p>
                            {/* <span className="mx-4 h-auto text-gray-400 font-medium">/</span> */}
                        </li>
                    </ul>
                </div>
                <div className="container mx-auto p-4 containerBoxShadow">
                    <div className="flex flex-col md:flex-row items-center justify-between mb-4">
                        <div className="md:px-10 px-5">
                            <h2 className=" md:text-2xl font-bold text-xl mb-2 md:mb-0">
                                Admin :{userDetails && userDetails.length > 0 && userDetails[0].schoolId}
                            </h2>
                            <h4 className="mb-2 font-bold">Admin Dashboard </h4>
                        </div>
                    </div>

                    <div className="gridContainer px-2 py-3 grid gap-8 md:gap-5 md:grid-cols-3 sm:grid-cols-2 md:grid-cols grid-cols-2 place-items-center">
                        {menuItems &&
                            menuItems.map((item) => (
                                <div
                                    key={item.keyName}
                                    onClick={() => handleMenuItemClick(item.keyName)}
                                    className="md:h-48 md:w-52 h-36 w-40 hover:bg-gray-100 transition duration-300 ease-in-out transform hover:scale-105 rounded-md flex flex-col justify-center cursor-pointer items-center border border-r-gray-300 bg-white shadow-md"
                                >
                                    <img src={item.icon} className="h-20 w-20" alt="" />

                                    <div>
                                        <h3 className="font-semibold text-center text-md md:text-xl">{item.caption}</h3>
                                    </div>
                                </div>
                            ))}
                    </div>
                </div>
            </div>
        </>
    );
}

export default FeesAnalysesDashboard;
