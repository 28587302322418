export const getInnerDataFromLocalStorage = async (userId, schoolId, userType, year) => {
    try {
      const registeredUsersList = JSON.parse(localStorage.getItem("registeredUsers")) || [];
  
      let matchedItems = [];
  
      registeredUsersList.forEach(innerArray => {
        const [userData, innerData] = innerArray;
  
        if (userData.userId === userId && userData.year === year && userData.schoolId === schoolId && userData.userType === userType) {
          innerData.forEach(innerItem => {   
              matchedItems.push(innerItem);
            
          });
        }
      });
  
      // Return the value of InfoField from the first matched item, or null if there are no matches
      return matchedItems.length > 0 ? matchedItems : null;
  
    } catch (error) {
      console.error("An error occurred:", error);
      // Handle error here
      return null;
    }
  };
  