import React, { useState, useEffect, useCallback, useRef } from "react";
import { FaCheckCircle, FaCloudUploadAlt, FaTimesCircle } from "react-icons/fa";
import { useParams } from "react-router-dom";
import { getAttendanceListDetail } from "../../API/getAttendanceList";
import Spinner from "../../components/Spinner";
import useSidebar from "../../hooks/useSidebar";
import { getSubjectWiseMarksDataAPI } from "../../API/getSubjectWiseMarks";
import { toast } from "react-toastify";

const CTMarkEntry = () => {
  // const inputRef = useRef(null);

  // const handleFocus = () => {
  //   // Select the contents of the input box
  //   if (inputRef.current) {
  //     inputRef.current.select();
  //   }
  // };

  const [arrayInput, setArrayInput] = useState([]);

  const [userDetails, setUserDetails] = useState(null);
  const [inputVal, setInputVal] = useState("");
  const [ActivityList, setActivityList] = useState([]);
  const [studentList, setStudentList] = useState([]);
  const [loading, setLoading] = useState(true);
  const { customId, keyName } = useParams();
  const { sidebarVisible, toggleSidebar } = useSidebar();

  useEffect(() => {
    const fetchData = async () => {
      const users = JSON.parse(localStorage.getItem("registeredUsers")) || [];

      if (customId) {
        const decodedData = JSON.parse(atob(customId));
        let matchedItem = [];

        users.forEach((innerArray) => {
          const [userData] = innerArray;

          if (
            userData.userId === decodedData.userId &&
            userData.userType === decodedData.userType &&
            userData.schoolId === decodedData.schoolId &&
            userData.year === decodedData.year
          ) {
            matchedItem.push(userData);
          }
        });

        const { firstObject, secondObject } = await getSubjectWiseMarksDataAPI(
          decodedData.userId,
          decodedData.schoolId,
          decodedData.userType,
          decodedData.year
        );

        console.log("STUDENT ACTIVITIES DATA", firstObject);
        console.log("STUDENT LIST DATA", secondObject);
        setActivityList(firstObject);
        setStudentList(secondObject);

        setUserDetails(matchedItem);
        setLoading(false); // Set loading to false when data is fetched
      }
    };

    fetchData();
  }, [customId]);

  const handleChange = (value, stdindex, actvindex) => {
    // Assuming you have a function to update the studentList state
    // You might use setStudentList or any other function to update the state
    const updatedStudentList = studentList.map((item, index) => {
      if (stdindex === index) {
        return {
          ...item,
          [`OBTM${actvindex + 1}`]: value, // Update the specific item value
        };
      }
      return item;
    });

    console.log("stdindex", stdindex);
    console.log("actvindx", actvindex);
    console.log("VALue", value);
    console.log("UPDATED LIST", updatedStudentList);
    setStudentList(updatedStudentList);

    // Set the updated state with the new studentList
    setStudentList(updatedStudentList);
  };

  const saveData = () => {

   console.log("Save")

  };

 
 const handleClick = () => {

  console.log("Clicked")

 }

  const handleInputMarkList = (stdindex) =>{

      let canupdate = true;

      console.log("Index",stdindex)

    ActivityList.map((actvitem, actvindex) => {

      
      let obtm = studentList[stdindex][`OBTM${actvindex + 1}`].toString();

      console.log("Obtained Mark in string",obtm)

      let mxmflt = parseFloat(studentList[stdindex][`MXM${actvindex + 1}`]);


      console.log("Mxm value in FLoat",mxmflt)

      if (obtm.toUpperCase() === "ML") {
      } else if (obtm.toUpperCase() === "AB") {
      } else if (obtm.toUpperCase() === "-") {
      } else {
        let obtmflt = parseFloat(studentList[stdindex][`OBTM${actvindex + 1}`]);
        console.log("Obtained Mark in Float",obtmflt)


        if (obtmflt > mxmflt) {
          alert(
            `Obtained Mark of student ${studentList[stdindex]["Sname"]} for ${actvitem.Activities} Greater than Maximum Mark!`
          );
          canupdate = false;
        } else if (obtmflt < 0) {
          alert("Obtained Mark cannot be Negative!");
          canupdate = false;
        }
      }
    });

    if (canupdate === true) {
      saveData();
    }else{

        alert("Error in data saving")
    }

  }


  const TableContentListing = () => {
    return studentList.map((stditem, stdindex) => (
      <>
        <tr>
          <td>
            <table className="inner-table">
              <thead>
                <tr>
                  <th className="border-cell">Admno</th>
                  <th className="border-cell">SlNo</th>
                  <th className="border-cell">Name</th>
                </tr>
              </thead>
              <tbody>
                <tr className="border-row">
                  <td className="border-cell">{stditem.adno}</td>
                  <td className="border-cell">{stditem.Rno}</td>
                  <td className="border-cell">{stditem.Sname}</td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>

        <tr>
          <td>
            <table className="inner-table">
              <thead>
                <tr>
                  {ActivityList &&
                    ActivityList.map((actvitem) => (
                      <th className="border-cell">{actvitem.Activities}</th>
                    ))}

                  <th className="border-cell">Total</th>
                </tr>
              </thead>
              <tbody>
                <tr className="border-row">
                  {ActivityList.map((actvitem, actvindex) => (
                    <>
                      <td className="border-cell">
                        {stditem[`MXM${actvindex + 1}`]}
                      </td>
                    </>
                  ))}
                  <td className="border-cell">{stditem.SMX}</td>
                </tr>
                <tr className="border-row">
                  {ActivityList.map((actvitem, actvindex) => (
                    <>
                      <td className="border-cell">
                        <input
                          className="text-center border-2 w-16 border-slate-300 focus:outline-slate-500"
                          type="text"
                          onChange={(e) =>
                            handleChange(e.target.value, stdindex, actvindex)
                          }
                          // onFocus={handleFocus}
                          value={stditem[`OBTM${actvindex + 1}`]}
                        />
                      </td>
                    </>
                  ))}
                  <td className="border-cell">{stditem.OBTSM}</td>
                </tr>
                <tr className="flex justify-center">
                  <button
                  
                   onClick={() => handleInputMarkList(stdindex)}
                    // onClick={handleInputMarks(stdindex)}
                    className="bg-red-500  px-4 py-2 flex justify-end text-white"
                  >
                    Save
                  </button>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </>
    ));
  };

  return (


  <div>

    {
      loading ? (
        <Spinner />
      ):(

    <>
      {
        <div className="table-container">
          <div className="shadow overflow-hidden sm:rounded-lg">
            <table className="custom-table">
              <tbody>
                <tr>
                  <td>
                    <table className="inner-table">
                      <thead>
                        <tr>
                          <th colSpan={4}>Subject Wise Mark Entry</th>
                        </tr>
                        <tr className="border-row">
                          <td className="border-cell">Class</td>
                          <td className="border-cell">I-A</td>
                          <td className="border-cell">Subject</td>
                          <td className="border-cell">English</td>
                        </tr>
                      </thead>
                    </table>
                  </td>
                </tr>

                {TableContentListing()}
              </tbody>
            </table>
          </div>
        </div>
      }
    </>

    
    
    )}


  </div>









  );
};

export default CTMarkEntry;
