import React from "react";
import axios from "axios";
import toast from "react-toastify";
import { getUserInfoKey } from "../API/GetUserInfoByKey";

export const adminSyncEmployees = async (year, preUrl) => {
  try {
    let userdata = [];

    // const Corskey = process.env.REACT_APP_CORS_URL;

    const postData = {
      year: year,
    };

    const result = await axios.post(`${preUrl}employeeUpdate`, postData, {
      headers: {
        "Content-Type": "application/json", // Specify content type as JSON
      },
    });

    // userdata = JSON.parse(result.data.substring(0, result.data.indexOf("||JasonEnd", 0)));

    return result;
  } catch (error) {
    console.error("An error occurred:", error);
    // Handle error here
  }
  // Placeholder JSX, replace with actual JSX
};
