import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import Spinner from "../../components/Spinner";
import useSidebar from "../../hooks/useSidebar";
import DemoStudentImage from "../../assets/png/avatar.png";
import { getUserDataFromLocalByKey } from "../../API/getStudentInfoByKeyLocal";
import { getCTStudentsListAPI } from "../../API/getCTStudentsListAPI";

const CTStudentDetails = () => {
  const { customId } = useParams();
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredStudent, setFilteredStudent] = useState([]);
  const [userDetails, setUserDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [ctName, setCtName] = useState("");
  const [ctStudentList, setCtStudentList] = useState([]);
  const [initialResourceUrl, setInitialResourceUrl] = useState(null);

  const handleSearch = (value) => {
    setSearchTerm(value);

    if (value.trim() === "") {
      setFilteredStudent([]);
    } else {
      const filtered = ctStudentList.filter((notification) =>
        notification.SNAME.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredStudent(filtered);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const users = JSON.parse(localStorage.getItem("registeredUsers")) || [];

      if (customId) {
        const decodedData = JSON.parse(atob(customId));
        let matchedItem = [];

        users.forEach((innerArray) => {
          const [userData] = innerArray;

          if (
            userData.userId === decodedData.userId &&
            userData.userType === decodedData.userType &&
            userData.schoolId === decodedData.schoolId &&
            userData.year === decodedData.year
          ) {
            matchedItem.push(userData);
          }
        });

        const namekeyval = "EmployeeName";
        const username = await getUserDataFromLocalByKey(
          decodedData.userId,
          decodedData.schoolId,
          decodedData.userType,
          decodedData.year,
          namekeyval
        );

        const modifiedUsername = username.replace(/(?:MS|MR)\s*/g, "");

        setCtName(modifiedUsername);

        const result = await getCTStudentsListAPI(
          decodedData.userId,
          decodedData.schoolId,
          decodedData.userType,
          decodedData.year
        );

        const keyval = "webResourceURL";
        const dataUrl = await getUserDataFromLocalByKey(
          decodedData.userId,
          decodedData.schoolId,
          decodedData.userType,
          decodedData.year,
          keyval
        );
        setInitialResourceUrl(dataUrl);

        console.log("Student List RESULT", result);
        setCtStudentList(result);
        setLoading(false);
        setUserDetails(matchedItem);
      }
    };

    fetchData();
  }, [customId]);

  const studentsToRender = searchTerm ? filteredStudent : ctStudentList;

  const { sidebarVisible, toggleSidebar } = useSidebar();

  return (
    <div className="container mx-auto p-4">
      {loading ? (
        <Spinner />
      ) : (
        <>
          <div className="flex flex-col md:flex-row items-center justify-between mb-4">
            <h3 className="md:text-3xl font-bold text-xl mb-2 md:mb-0">
              Teacher : {ctName}
            </h3>
            <input
              type="text"
              placeholder="Enter Keyword"
              value={searchTerm}
              onChange={(e) => handleSearch(e.target.value)}
              className="border border-gray-600 md:w-80 w-full md:max-w-sm h-12 md:ml-4 md:px-5 px-2 py-1 rounded-md"
            />
          </div>
          <h3 className="md:text-3xl font-bold text-xl mb-2 md:mb-2">Student List</h3>
          <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-4">
            {studentsToRender && studentsToRender.length > 0 ? (
              studentsToRender.map((item) => (
                <Link to={`/classteacher/u/${customId}/${item.ADMNO}/CTStudentProfileDetails`} onClick={""} key={item.id}>
                  <div className="border border-gray-300 rounded-lg p-4 hover:shadow-md transition duration-300">
                    <div className="flex items-center">
                      <div className="w-1/3 sm:w-auto mr-4">
                        <img
                          src={DemoStudentImage}
                          alt={item.SNAME}
                          className="w-20 h-20 sm:w-32 sm:h-32 object-cover rounded"/>
                      </div>

                      <div className="w-2/3">
                        <h2 className="text-lg font-semibold mb-2">
                          {item.SNAME}
                        </h2>
                        <h4 className="text-lg font-semibold mb-2">
                          {item.ADMNO}
                        </h4>
                        <p className="text-gray-600 mb-2"> Class {item.CLNAME}-{item.SECNAME} </p>
                        <p className="text-gray-600 mb-2"> {item.FATHERNAME} </p>
                        <p className="text-gray-600 mb-2"> {item.SMSPHONE} </p>
                      </div>
                    </div>
                  </div>
                </Link>
              ))
            ) : (
              <p>No data found</p>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default CTStudentDetails;
