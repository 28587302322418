import axios from "axios";

export const getQuickInfoDetail = async (
  year,
  selectedClass,
  selectedClassCat,
  selectedSection,
  dispType,
  selectedClassR1,
  selectedClassR2,
  sumType,
  concId
) => {
  try {
    const returnUrl = process.env.REACT_APP_ERP_QUICKINFO_PAGE;
    const formData = new FormData();

    formData.append("title", "GetERPClassStrength");
    formData.append("description", "Checking");
    formData.append("ReqAcastart", year);
    formData.append("Reqdisptype", dispType);
    formData.append("ReqDDLClassR1", selectedClassR1);
    formData.append("ReqDDLClassR2", selectedClassR2);
    formData.append("ReqDDLAccCat", selectedClassCat);
    formData.append("ReqDDLClass", selectedClass);
    formData.append("ReqDDLSection", selectedSection);
    formData.append("ReqSummType", sumType);
    formData.append("ReqConcessionID", concId);

    let userdata = [];
    const result = await axios.post(returnUrl, formData);
    userdata = JSON.parse(result.data.substring(0, result.data.indexOf("||JasonEnd", 0)));
    return userdata;
  } catch (error) {
    console.error("An error occurred:", error);
  }
};
