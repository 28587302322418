import axios from "axios";
import React, { useState } from "react";

const FCMTestForm = () => {
  const url = process.env.REACT_APP_AWS_WEBSERVICE_URL;
  const [token, setToken] = useState("");
  const [title, setTitle] = useState("Sample Notification Title");
  const [body, setBody] = useState("This is a test notification body");
  const [data, setData] = useState('{"key1": "value1", "key2": "value2"}');
  const [link, setLink] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = { token, title, body, data, link }; // Renamed to payload

    try {
      const response = await axios.post(`${url}send-message`, payload); // Pass payload directly

      console.log(response, 4545455445);

      console.log("Message sent successfully!");
    } catch (error) {
      console.log("Error sending message:", error);
      alert(error.message);
    }
  };

  return (
    <div className="max-w-lg mx-auto mt-10 p-6 bg-white rounded-lg shadow-lg">
      <h2 className="text-2xl font-semibold text-gray-800 mb-4">
        Test Firebase Cloud Messaging
      </h2>
      <form onSubmit={handleSubmit} className="space-y-6">
        <div>
          <label className="block text-gray-600 font-medium">FCM Token:</label>
          <input
            type="text"
            value={token}
            onChange={(e) => setToken(e.target.value)}
            className="w-full mt-1 px-4 py-2 border rounded-md focus:ring focus:ring-indigo-300"
            placeholder="Enter FCM Token"
          />
        </div>
        <div>
          <label className="block text-gray-600 font-medium">Title:</label>
          <input
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            className="w-full mt-1 px-4 py-2 border rounded-md focus:ring focus:ring-indigo-300"
            placeholder="Enter Notification Title"
          />
        </div>
        <div>
          <label className="block text-gray-600 font-medium">Body:</label>
          <input
            type="text"
            value={body}
            onChange={(e) => setBody(e.target.value)}
            className="w-full mt-1 px-4 py-2 border rounded-md focus:ring focus:ring-indigo-300"
            placeholder="Enter Notification Body"
          />
        </div>

        <div>
          <label className="block text-gray-600 font-medium">Link</label>
          <input
            type="text"
            value={link}
            onChange={(e) => setLink(e.target.value)}
            className="w-full mt-1 px-4 py-2 border rounded-md focus:ring focus:ring-indigo-300"
            placeholder="Enter Notification Body"
          />
        </div>

        <div>
          <label className="block text-gray-600 font-medium">
            Data (JSON):
          </label>
          <textarea
            value={data}
            onChange={(e) => setData(e.target.value)}
            className="w-full mt-1 px-4 py-2 border rounded-md focus:ring focus:ring-indigo-300"
            placeholder='{"key1": "value1"}'
          />
        </div>
        <button
          type="submit"
          className="w-full px-4 py-2 bg-indigo-600 text-white font-medium rounded-md hover:bg-indigo-500 transition"
        >
          Send FCM
        </button>
      </form>
    </div>
  );
};

export default FCMTestForm;
