import React, { useEffect, useState } from "react";
import useSidebar from "../../hooks/useSidebar";
import { useParams } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import { SchoolListAdminData, SingleStudentDataList } from "../../data/getQuickInfoListAdminData";
import { getUserDataFromLocalByKey } from "../../API/getStudentInfoByKeyLocal";

import StudentImage from "../../assets/Dashboard/StudentProfile.jpg";
import { getQuickInfoClassWiseDetails } from "../../API/getQuickInfoClassWiseDetails";
import Spinner from "../../components/Spinner";
import { useGlobalContext } from "../../context/GlobalContext";
import Sidebar from "../../components/Sidebar";
import Navbar from "../../components/Navbar";
import { getQuickInfoDetail } from "../../API/getQuickInfoDetail";

const QuickInfoStudentDetailsList = () => {
    const [data, setData] = useState(true);
    const [userDetails, setUserDetails] = useState(null);
    const [loading, setLoading] = useState(false);
    const [quickInfoList, setQuickInfoList] = useState([]);
    const [initialResourceUrl, setInitialResourceUrl] = useState(null);

    const { sidebarVisible, toggleSidebar } = useSidebar();
    const { activeMenu } = useGlobalContext();

    const { customId } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    let classSelected = location.state.class;
    let wholeClassSection = location.state.wholeClassSection;
    const ClassSelectData = [
        {
            index: 1,
            label: "Class",
            SelectOption: ["--Select--", ...wholeClassSection],
        },
        {
            index: 2,
            label: "Section",
            SelectOption: ["--Select--", "A", "B", "C", "D"],
        },
    ];
    const ClassDetailTypeData = [
        {
            index: 1,
            label: "Display Type",
            SelectOption: ["All", "Active", "Stuck Off", "TC Given"],
        },
    ];

    const tableHead = [
        "Select",
        "Student",
        "Roll No.",
        "Adm No.",
        "Name",
        "Class",
        "Section",
        "SMS Ph. No.",
        "Status",
        "Father Name",
        "Mother Name",
        "New",
        "RTE",
        "Concession",
        "Show Profile",
    ];

    useEffect(() => {
        const fetchData = async () => {
            const users = JSON.parse(localStorage.getItem("registeredUsers")) || [];

            if (customId) {
                const decodedData = JSON.parse(atob(customId));
                let matchedItem = [];

                users.forEach((innerArray) => {
                    const [userData] = innerArray;

                    if (
                        userData.userId === decodedData.userId &&
                        userData.userType === decodedData.userType &&
                        userData.schoolId === decodedData.schoolId &&
                        userData.year === decodedData.year
                    ) {
                        matchedItem?.push(userData);
                    }
                });

                const result = await getQuickInfoClassWiseDetails(
                    decodedData.userId,
                    decodedData.schoolId,
                    decodedData.userType,
                    decodedData.year,
                    classSelected
                );

                const keyval = "webResourceURL";
                const dataUrl = await getUserDataFromLocalByKey(
                    decodedData.userId,
                    decodedData.schoolId,
                    decodedData.userType,
                    decodedData.year,
                    keyval
                );
                setInitialResourceUrl(dataUrl);

                setQuickInfoList(result);
                setLoading(false);
                setUserDetails(matchedItem);
            }
        };

        fetchData();
    }, [customId, classSelected]);

    const TableData = quickInfoList.map((student) => ({
        AdmissionNo: student.ADMNO,
        StudentName: student.SNAME,
        FatherName: student.FATHERNAME,
        MotherName: student.MNAME,
        SMSPhone: student.SMSPHONE,
        RollNo: student.RNO,
        Class: student.CLNAME,
        Section: student.SECNAME,
        New: student.NEWSTDYESNO,
        Status: student.STS,
        RTE: student.RTIYN,
        Concession: student.CONCETYPE,
    }));

    const findStudentByADMNO = (admno) => {
        return quickInfoList.find((student) => student.ADMNO === admno);
    };

    return (
        <>
            <div className="flex relative dark:bg-main-dark-bg">
                {activeMenu ? (
                    <div className="w-full lg:w-72  left-0 top-0 transition-transform duration-500 ease-in fixed sidebar bg-black/20 ">
                        <div className="w-72 lg:w-full bg-white">
                            <Sidebar />
                        </div>
                    </div>
                ) : (
                    <div className="w-0">
                        <Sidebar />
                    </div>
                )}

                <div className={activeMenu ? "min-h-screen lg:ml-72 w-full" : "w-full min-h-screen flex-2"}>
                    <div className={`fixed lg:static navbar w-full`}>
                        <Navbar data={userDetails} />
                    </div>

                    <div className="lg:mt-0 mt-5">
                        <div className="p-2 mb-3 flex items-center w-fit flex-wrap">
                            <ul className="flex items-center">
                                <li className="inline-flex items-center cursor-default">
                                    <a href="/" className="text-black font-medium hover:scale-105">
                                        <svg
                                            className="w-5 cursor-default h-auto fill-current mx-2"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 24 24"
                                            fill="#000000"
                                        >
                                            <path d="M0 0h24v24H0V0z" fill="none" />
                                            <path d="M10 19v-5h4v5c0 .55.45 1 1 1h3c.55 0 1-.45 1-1v-7h1.7c.46 0 .68-.57.33-.87L12.67 3.6c-.38-.34-.96-.34-1.34 0l-8.36 7.53c-.34.3-.13.87.33.87H5v7c0 .55.45 1 1 1h3c.55 0 1-.45 1-1z" />
                                        </svg>
                                    </a>

                                    <span className="mx-4 h-auto font-medium">/</span>
                                </li>
                                <li className="inline-flex items-center">
                                    <p className="text-black font-medium cursor-default hover:scale-105">Quick Info</p>
                                    <span className="mx-4 h-auto text-gray-400 font-medium">/</span>
                                </li>
                                <li className="inline-flex items-center">
                                    <p className="text-black font-medium cursor-default hover:scale-105">Class Details</p>
                                    {/* <span className="mx-4 h-auto text-gray-400 font-medium">/</span> */}
                                </li>
                            </ul>
                        </div>

                        <div className="container mx-auto p-4 containerBoxShadow">
                            <div className="">
                                <h2 className="w-full text-center text-2xl uppercase text-black font-mono font-semibold">
                                    School Strength
                                </h2>
                                <div className="flex items-start flex-col justify-between gap-5 flex-wrap shadow-lg rounded">
                                    <div className="flex flex-col w-full p-5">
                                        <div className="flex md:flex-row p-3 px-3 md:px-7 lg:px-10 gap-5 formBackground w-full justify-between flex-col">
                                            <div className="w-full md:w-1/2">
                                                {ClassSelectData?.map((item) => {
                                                    return (
                                                        <div
                                                            key={item?.index}
                                                            className=" flex flex-row items-center gap-2 mb-1 justify-between"
                                                        >
                                                            <label
                                                                className="block uppercase tracking-wide text-center text-grey-darker text-xs font-bold mb-2"
                                                                for="grid-state"
                                                            >
                                                                {item?.label}
                                                            </label>
                                                            <div className="relative flex flex-row ">
                                                                <select className="textInput w-[150px] md:w-[200px]">
                                                                    {item?.SelectOption?.map((data) => (
                                                                        <>
                                                                            {data === classSelected ? (
                                                                                <option selected>{data}</option>
                                                                            ) : (
                                                                                <option>{data}</option>
                                                                            )}
                                                                        </>
                                                                    ))}
                                                                </select>
                                                                <div className="pointer-events-none absolute right-2 top-3.5 flex items-center text-grey">
                                                                    <svg
                                                                        className="h-4 w-4"
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 20 20"
                                                                    >
                                                                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                                                    </svg>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                            <div className="w-full md:w-1/2">
                                                {ClassDetailTypeData?.map((item) => {
                                                    return (
                                                        <div
                                                            key={item?.index}
                                                            className=" flex flex-row items-center gap-2 mb-1 justify-between"
                                                        >
                                                            <label
                                                                className="block uppercase tracking-wide text-center text-grey-darker text-xs font-bold mb-2"
                                                                for="grid-state"
                                                            >
                                                                {item?.label}
                                                            </label>
                                                            <div className="relative flex flex-row ">
                                                                <select className="textInput w-[150px] md:w-[200px]">
                                                                    {item?.SelectOption?.map((data) => (
                                                                        <option>{data}</option>
                                                                    ))}
                                                                </select>
                                                                <div className="pointer-events-none absolute right-2 top-3.5 flex items-center text-grey">
                                                                    <svg
                                                                        className="h-4 w-4"
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 20 20"
                                                                    >
                                                                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                                                    </svg>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                        <button className="buttonClass w-fit -mb-2 mt-2">Refresh</button>
                                    </div>
                                </div>
                                <div className="formBackground my-3 py-3 px-2 uppercase grid md:grid-cols-4 sm:grid-cols-3 grid-cols-2 justify-evenly tracking-wide text-center text-grey-darker text-xs font-bold mb-2 gap-y-3">
                                    <div className="flex justify-center items-center gap-4">
                                        <h2>Active:</h2>
                                        <h2>2833</h2>
                                    </div>
                                    <div className="flex justify-center items-center gap-4">
                                        <h2>Stuck Off:</h2>
                                        <h2>29</h2>
                                    </div>
                                    <div className="flex justify-center items-center gap-4">
                                        <h2>TC Given:</h2>
                                        <h2>79</h2>
                                    </div>
                                    <div className="flex justify-center items-center gap-4">
                                        <h2>Total:</h2>
                                        <h2>2933</h2>
                                    </div>
                                </div>
                            </div>
                            <hr className="w-full pb-2" />
                            {loading ? (
                                <Spinner />
                            ) : (
                                <div className="p-1 md:p-2 w-full shadow-lg">
                                    <div className="relative overflow-x-auto max-h-screen rounded-sm">
                                        <table className="w-full h-screen overflow-y-auto bg-white text-sm text-left rtl:text-right">
                                            <thead className="tableHead sticky top-0 z-50 blur-0">
                                                <tr>
                                                    {tableHead?.map((data) => {
                                                        return (
                                                            <th
                                                                scope="col"
                                                                className="p-1 border border-solid border-black uppercase text-sm"
                                                            >
                                                                {data}
                                                            </th>
                                                        );
                                                    })}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {TableData?.map((item, index) => {
                                                    return (
                                                        <tr
                                                            key={index}
                                                            className={`border-b ${
                                                                index % 2 === 0 ? "bg-white" : "bg-slate-50"
                                                            } dark:bg-gray-800 dark:border-gray-700`}
                                                        >
                                                            <td className="border border-solid border-black p-1 w-fit h-full">
                                                                <input
                                                                    type="radio"
                                                                    className="mx-auto ms-3"
                                                                    name="group1"
                                                                />
                                                            </td>
                                                            <td className="border border-solid border-black p-1 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                                                <img
                                                                    src={StudentImage}
                                                                    className="h-7 w-7"
                                                                    alt="Student Profile"
                                                                />
                                                            </td>
                                                            <td className="border border-solid border-black p-1 font-medium">
                                                                {item?.RollNo}
                                                            </td>
                                                            <td className="border border-solid border-black p-1 font-medium">
                                                                {item?.AdmissionNo}
                                                            </td>
                                                            <td className="border border-solid border-black p-1 font-medium">
                                                                {item?.StudentName}
                                                            </td>
                                                            <td className="border border-solid border-black p-1 font-medium">
                                                                {item?.Class}
                                                            </td>
                                                            <td className="border border-solid border-black p-1 font-medium">
                                                                {item?.Section}
                                                            </td>
                                                            <td className="border border-solid border-black p-1 font-medium">
                                                                {item?.SMSPhone}
                                                            </td>
                                                            <td className="border border-solid border-black p-1 font-medium">
                                                                {item?.Status}
                                                            </td>
                                                            <td className="border border-solid border-black p-1 font-medium">
                                                                {item?.FatherName}
                                                            </td>
                                                            <td className="border border-solid border-black p-1 font-medium">
                                                                {item?.MotherName}
                                                            </td>
                                                            <td className="border border-solid border-black p-1 font-medium">
                                                                {item?.New ? (
                                                                    <>
                                                                        <input type="checkbox" checked />
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <input type="checkbox" />
                                                                    </>
                                                                )}
                                                            </td>
                                                            <td className="border border-solid border-black p-1 font-medium">
                                                                {item?.RTE ? (
                                                                    <>
                                                                        <input type="checkbox" checked />
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <input type="checkbox" />
                                                                    </>
                                                                )}
                                                            </td>
                                                            <td className="border border-solid border-black p-1 font-medium">
                                                                {item?.Concession}
                                                            </td>
                                                            <td className="border border-solid border-black p-1 font-medium">
                                                                <p
                                                                    onClick={() => {
                                                                        let studentData = findStudentByADMNO(
                                                                            item?.AdmissionNo
                                                                        );
                                                                        navigate(
                                                                            `/admin/u/${customId}/QuickInfoSingleStudentProfileDetails`,
                                                                            {
                                                                                state: {
                                                                                    studentData,
                                                                                },
                                                                            }
                                                                        );
                                                                    }}
                                                                    className="text-blue-500 cursor-pointer text-sm capitalize"
                                                                >
                                                                    Show Details
                                                                </p>
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default QuickInfoStudentDetailsList;
