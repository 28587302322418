import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { getNotificationListDetail } from "../../API/getNotificationList";
import Spinner from "../../components/Spinner";
import useSidebar from "../../hooks/useSidebar";
import plusicon from "../../assets/png/plusicon.png";
import Images from "../../assets/Files/images.png";
import PDFImage from "../../assets/Files/pdficconpng.png";
import powerpointIcon from "../../assets/Files/PPTIcon.png";
import WordIcon from "../../assets/Files/wordicon.png";
import VideoIcon from "../../assets/Files/videoicon.png";
import Youtube from "../../assets/Files/youtube.png";
import Excel from "../../assets/Files/excel.svg";
import textMessage from "../../assets/Files/textmsg.png";
import OtherImg from "../../assets/Files/otherico.png";
import { getUserDataFromLocalByKey } from "../../API/getStudentInfoByKeyLocal";
import { getCTNotificationListDetails } from "../../API/getCTNotificationList";
import AudioIcon from "../../assets/Files/musicIcon.png";
import { Trash2 } from "lucide-react";
import DeleteModal from "../../components/DeleteModal";
import { toast } from "react-toastify";
import { deleteHWSMNotification } from "../../API/deleteHWSMNotification";

const CTNotification = () => {
  const { customId } = useParams();
  const [searchTerm, setSearchTerm] = useState("");
  const [ctName, setCtName] = useState("");
  const [filteredNotifications, setFilteredNotifications] = useState([]);
  const [userDetails, setUserDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [ctNotificationList, setCtNotificationList] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [selectedNotification, setSelectedNotification] = useState(null);
  const [contentType, setContentType] = useState("default");
  const [initialResourceUrl, setInitialResourceUrl] = useState(null);
  const [resourceUrl, setResourceUrl] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const handleSearch = (value) => {
    setSearchTerm(value);

    if (value.trim() === "") {
      setFilteredNotifications([]);
    } else {
      const filtered = ctNotificationList.filter((notification) =>
        notification.HWSubject.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredNotifications(filtered);
    }
  };

  const renderImage = (photoLocation) => {
    console.log(photoLocation);

    if (photoLocation === "") {
      return textMessage;
    }

    if (photoLocation.endsWith(".jpg")) {
      // setContentType('Same')
      return Images;
    } else if (photoLocation.endsWith(".pdf")) {
      // setContentType("Same")
      return PDFImage;
    } else if (photoLocation.includes(".ppt")) {
      // setContentType("Same")
      return powerpointIcon;
    } else if (photoLocation.includes(".doc")) {
      // setContentType("Same")
      return WordIcon;
    } else if (photoLocation.includes(".xls")) {
      // setContentType("Same")
      return Excel;
    } else if (photoLocation.includes(".mp4")) {
      // setContentType("Same")
      return VideoIcon;
    } else if (photoLocation.includes(".mp3")) {
      // setContentType("Same")
      return AudioIcon;
    } else if (photoLocation.includes("v=")) {
      return Youtube;
    } else {
      return OtherImg;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const users = JSON.parse(localStorage.getItem("registeredUsers")) || [];

      if (customId) {
        const decodedData = JSON.parse(atob(customId));
        let matchedItem = [];

        users.forEach((innerArray) => {
          const [userData] = innerArray;

          if (
            userData.userId === decodedData.userId &&
            userData.userType === decodedData.userType &&
            userData.schoolId === decodedData.schoolId &&
            userData.year === decodedData.year
          ) {
            matchedItem.push(userData);
          }
        });

        const result = await getCTNotificationListDetails(
          decodedData.userId,
          decodedData.schoolId,
          decodedData.userType,
          decodedData.year
        );

        const namekeyval = "EmployeeName";
        const username = await getUserDataFromLocalByKey(
          decodedData.userId,
          decodedData.schoolId,
          decodedData.userType,
          decodedData.year,
          namekeyval
        );

        const modifiedUsername = username.replace(/(?:MS|MR)\s*/g, "");

        setCtName(modifiedUsername);

        const key = "WebResourceAWSDOTNETURL";

        const dataResourceUrl = await getUserDataFromLocalByKey(
          decodedData.userId,
          decodedData.schoolId,
          decodedData.userType,
          decodedData.year,
          key
        );
        setResourceUrl(dataResourceUrl);

        const keyval = "webServiceURLAWSDOTNET";
        const dataUrl = await getUserDataFromLocalByKey(
          decodedData.userId,
          decodedData.schoolId,
          decodedData.userType,
          decodedData.year,
          keyval
        );
        setInitialResourceUrl(dataUrl);

        console.log("URL RESULT", dataUrl);

        console.log("NOTIFICATION RESULT", result);
        setCtNotificationList(result);
        setLoading(false);
        setUserDetails(matchedItem);
      }
    };

    fetchData();
  }, [customId, isDeleteModalOpen]);

  const checkContentType = () => {
    // console.log( selectedNotification.PhotoLocation.split("v=")[1],"photo location")

    if (selectedNotification.PhotoLocation.includes("v=")) {
      return (
        <iframe
          title="Notification Content"
          src={`https://www.youtube.com/embed/${
            //  selectedNotification.PhotoLocation
            selectedNotification.PhotoLocation.split("v=")[1]
          }`}
          className="w-full h-56 border-none"
          style={{ overflow: "auto" }}
        ></iframe>
      );
    }
    if (selectedNotification.PhotoLocation === "") {
      return null;
    } else {
      return (
        <iframe
          title="Notification Content"
          src={`${resourceUrl}/Notification/${selectedNotification.PhotoLocation}`}
          className="w-full h-56 border-none"
          style={{ overflow: "auto" }}
        ></iframe>
      );
    }
  };

  const openPopup = (notification) => {
    setSelectedNotification(notification);
    setShowPopup(true);
  };

  const closePopup = () => {
    setShowPopup(false);
    setSelectedNotification(null);
  };

  const handleConfirmDelete = async () => {
    try {
      const response = await deleteHWSMNotification(
        userDetails[0].userId,
        userDetails[0].schoolId,
        userDetails[0].userType,
        userDetails[0].year,
        "NOTF",
        selectedNotification?.HWID
      );

      toast.success(response[0].InfoField);
    } catch (error) {
      console.log(error);
    }

    setIsDeleteModalOpen(false);
    setSelectedNotification(null);
  };

  const handleCancelDelete = () => {
    setIsDeleteModalOpen(false);
    setSelectedNotification(null);
  };

  const handleDeleteClick = (notification, e) => {
    e.stopPropagation();
    setSelectedNotification(notification);
    setIsDeleteModalOpen(true);
  };

  const notificationsToRender = searchTerm
    ? filteredNotifications
    : ctNotificationList;

  const { sidebarVisible, toggleSidebar } = useSidebar();
  return (
    <>
      <DeleteModal
        isOpen={isDeleteModalOpen}
        onClose={handleCancelDelete}
        onConfirm={handleConfirmDelete}
        title={"Notification"}
      />
      <div className="flex flex-col md:flex-row items-center justify-between mb-4">
        <h1 className="md:text-3xl font-bold text-xl mb-2 md:mb-0">
          Notification List
        </h1>
        <input
          type="text"
          placeholder="Enter Keyword"
          value={searchTerm}
          onChange={(e) => handleSearch(e.target.value)}
          className="border border-gray-600 md:w-80 w-full md:max-w-sm h-12 md:ml-4 md:px-5 px-2 py-1 rounded-md"
        />
      </div>
      <h3 className="text-md font-semibold mb-4">Teacher Name: {ctName}</h3>
      <Link
        to={`/classteacher/u/:keyName/${customId}/CTNotificationSubmitPage`}
        onClick={""}
      >
        <div
          className="border border-gray-300 cursor-pointer rounded-lg p-4 hover:shadow-md transition duration-300"
          style={{
            boxShadow: "5px 5px 12px 0px rgba(173, 216, 230, 0.9)",
          }}
        >
          <div className="flex items-center">
            <div className="w-1/3 sm:w-auto mr-4">
              <img
                src={plusicon}
                alt="icon"
                className="w-20 h-20 sm:w-15 sm:h-15 object-cover rounded"
              />
            </div>

            <div className="w-2/3">
              <h3 className="text-md font-semibold mb-2">
                Click to Add New Notice
              </h3>
            </div>
          </div>
        </div>
      </Link>
      <div className="container mx-auto p-4">
        {loading ? (
          <Spinner />
        ) : (
          <>
            <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-4">
              {notificationsToRender && notificationsToRender.length > 0 ? (
                notificationsToRender.map((notification) => (
                  <div
                    onClick={() => openPopup(notification)}
                    key={notification.id}
                    className="flex justify-between items-center border border-gray-200 rounded-lg p-4 hover:shadow-md transition duration-300 "
                  >
                    <div className="flex items-center w-full">
                      <div className="w-1/3 sm:w-auto mr-4">
                        <img
                          src={renderImage(notification.PhotoLocation)}
                          alt={notification.title}
                          className="w-20 h-20 sm:w-32 sm:h-32 object-cover rounded"
                        />
                      </div>

                      <div className="w-2/3">
                        <h2 className="text-lg font-semibold mb-2">
                          {notification.HWSubject}
                        </h2>
                        <h4 className="text-lg font-semibold mb-2">
                          {notification.HWDateStr}
                        </h4>
                        <p className="text-gray-600">
                          {notification.HWRemarks.substring(0, 50)}
                        </p>
                      </div>
                    </div>

                    <div className="flex gap-2">
                      <button
                        onClick={(e) => handleDeleteClick(notification, e)}
                        className="text-red-500 hover:text-red-700 p-2 rounded-full hover:bg-red-50"
                      >
                        <Trash2 size={20} />
                      </button>
                    </div>
                  </div>
                ))
              ) : (
                <p className="text-center text-gray-500 mt-4">No data found</p>
              )}
            </div>

            {showPopup && (
              <div className="fixed inset-0 flex items-center justify-center mt-10 bg-black bg-opacity-60">
                <div className="bg-white customPopupHeight relative rounded-lg mt-8 shadow-lg p-8 md:w-1/2 w-full ">
                  <div className="w-1/3 sm:w-auto mr-4">
                    <img
                      src={renderImage(selectedNotification.PhotoLocation)}
                      alt=""
                      className="w-20  sm:w-32  object-cover rounded"
                    />
                  </div>

                  <button
                    onClick={closePopup}
                    className="px-4 py-2  absolute right-5 top-0 mt-8 bg-red-600 text-white rounded"
                  >
                    Close
                  </button>

                  <div className="w-2/3 ">
                    <h2 className="text-lg font-semibold mb-2">
                      {selectedNotification.HWSubject}
                    </h2>
                    <h4 className="text-lg font-semibold mb-2">
                      {selectedNotification.HWDateStr}
                    </h4>
                    <p className="text-gray-600">
                      {selectedNotification.HWRemarks}
                    </p>
                  </div>

                  {checkContentType()}
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default CTNotification;
