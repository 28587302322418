import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { BsClockFill } from "react-icons/bs";
import { getCTReplyToParentListDetails } from "../../API/getCTReplyToParentList";
import greenTickIcon from "../../assets/png/greenTickIcon.png";
import { getUserDataFromLocalByKey } from "../../API/getStudentInfoByKeyLocal";
import Spinner from "../../components/Spinner";
import useSidebar from "../../hooks/useSidebar";
const CTReplyToParent = () => {
  const { customId } = useParams();
  const [userDetails, setUserDetails] = useState(null);
  const [ctName, setCtName] = useState("");
  const [loading, setLoading] = useState(true);
  const [requestList, setRequestList] = useState([]);
  const [initialResourceUrl, setInitialResourceUrl] = useState(null);

  const encodeValue = (value) => (value ? encodeURIComponent(value) : "null");


  useEffect(() => {
    const fetchData = async () => {
      const users = JSON.parse(localStorage.getItem("registeredUsers")) || [];

      if (customId) {
        const decodedData = JSON.parse(atob(customId));
        let matchedItem = [];

        users.forEach((innerArray) => {
          const [userData] = innerArray;

          if (
            userData.userId === decodedData.userId &&
            userData.userType === decodedData.userType &&
            userData.schoolId === decodedData.schoolId &&
            userData.year === decodedData.year
          ) {
            matchedItem.push(userData);
          }
        });

        const namekeyval = "EmployeeName";
        const username = await getUserDataFromLocalByKey(
          decodedData.userId,
          decodedData.schoolId,
          decodedData.userType,
          decodedData.year,
          namekeyval
        );

        const modifiedUsername = username.replace(/(?:MS|MR)\s*/g, "");

        setCtName(modifiedUsername);

        console.log(23423423242)

        const result = await getCTReplyToParentListDetails(
          decodedData.userId,
          decodedData.schoolId,
          decodedData.userType,
          decodedData.year
        );

        console.log(result,777)

        const keyval = "webServiceURLAWSDOTNET";
        const dataUrl = await getUserDataFromLocalByKey(
          decodedData.userId,
          decodedData.schoolId,
          decodedData.userType,
          decodedData.year,
          keyval
        );
        setInitialResourceUrl(dataUrl);

        setRequestList(result);

        console.log(result);
        setLoading(false);
        setUserDetails(matchedItem);
      }
    };

    fetchData();
  }, [customId]);

  const notificationsToRender = requestList;

  const { sidebarVisible, toggleSidebar } = useSidebar();

  return (
    <>
      <div className="container mx-auto p-4">
        {loading ? (
          <Spinner />
        ) : (
          <>
            <div className="flex flex-col md:flex-row items-center justify-between mb-4">
              <div className="w-2/3">
                <h2 className="md:text-3xl font-bold text-xl mb-2 md:mb-0">
                  Teacher : {ctName}
                </h2>
                <h4 className="mb-2 font-bold">Replay To Parent</h4>
              </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-4">
            {notificationsToRender?.map((notification) => (
              <Link
              to={`/classteacher/u/${customId}/${encodeValue(notification.HWSname)}/${encodeValue(notification.HWClass)}/${encodeValue(notification.HWID)}/${encodeValue(notification.HWRemarks)}/${encodeValue(notification.TchReply)}/${encodeValue(notification.SoundClipStudent)}/${encodeValue(notification.SoundClipTeacher)}/CTReplyToParentSubmit`}
              onClick={""}
              >
                  <div
                    key={notification.id}
                    className="cursor-pointer relative"
                  >
                    <div className="border border-gray-300 rounded-lg p-4 hover:shadow-md transition duration-300">
                      <p className="text-lg  mb-2">
                        Subject : {notification.HWSubject}
                      </p>
                      <p className="text-lg  mb-2">
                        Meassage : {notification.HWRemarks}
                      </p>
                      <p className="text-lg  mb-2">
                        Date : {notification.HWDateStr}
                      </p>
                      <p className="text-lg  mb-2">
                        Reply : {notification.TchReply}
                      </p>
                      <div className="absolute top-0 right-0 p-2 flex items-center">
                        {notification.TchReply ? (
                          <>
                            <img
                              src={greenTickIcon}
                              alt="Thumbnail"
                              className="w-10 h-10 object-cover rounded ml-2"
                            />
                            <span className="text-gray px-2 py-1 rounded">
                              Status: Replied
                            </span>
                          </>
                        ) : (
                          <>
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <span className="object-cover rounded ml-2">
                                {<BsClockFill />}
                              </span>
                              <span className="text-gray px-2 py-1 rounded">
                                Status: Pending
                              </span>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
              </Link>
            ))}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default CTReplyToParent;
