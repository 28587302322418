import axios from "axios";
import { getUserDataFromLocalByKey } from "./getStudentInfoByKeyLocal";

let UserName;
let UserCode;
const user = localStorage.getItem("UserRights");
const parts = user?.split("_");
if (parts?.length > 0) {
  UserName = parts?.[0];
  UserCode = parts?.[2];
}

export const GetClassSectionMasterLists = async (userId, schoolId, userType, year) => {
  try {
    const key = "webServiceURL";
    const returnUrl = await getUserDataFromLocalByKey(userId, schoolId, userType, year, key);
    const formData = new FormData();

    formData.append("title", "GetClassSectionMasterLists");
    formData.append("description", "get Class and Section List");
    formData.append("ReqAcastart", year);
    formData.append("ReqUserName", UserName);

    let userdata = [];
    const Corskey = process.env.REACT_APP_CORS_URL;
    const result = await axios.post(`${Corskey}${returnUrl}`, formData);
    userdata = JSON.parse(result.data.substring(0, result.data.indexOf("||JasonEnd", 0)));
    return userdata;
  } catch (error) {
    console.error("An error occurred:", error);
  }
};
