import React, { useState, useEffect } from "react";
import DemoStudentImage from "../../assets/png/avatar.png";
import { useParams } from "react-router-dom";
import Navbar from "../../components/Navbar";
import Sidebar from "../../components/Sidebar";
import HomeWorkSubject from "./HomeWorkSubject";
import STeacherGallery from "./STeacherGallery";
import NoticeBoardSubTeacher from "./NoticeBoardSubTeacher";
import ProfileSubTeacher from "./ProfileSubTeacher";
import STTimeTable from "./STTimeTable";
import CLAttendance from "../ClassTeacher/CTAttendance";
import { useSidebar } from "../../context/SidebarContext";
import STSubjectList from "./SubjectList";
import CTComBWTeachersList from "../ClassTeacher/CTComBWTeachersList";
import CTComBWTeachers from "../ClassTeacher/CTComBWTeachers";
import CTHomework from "../ClassTeacher/CTHomework";
import CTGallery from "../ClassTeacher/CTGallery";
import CTNoticeBoard from "../ClassTeacher/CTNoticeBoard";
import CTProfile from "../ClassTeacher/CTProfile";
import CTTeacherTimeTable from "../ClassTeacher/CTTeacherTimeTable";
import CTSubjectList from "../ClassTeacher/CTSubjectList";
import CTReplyToParent from "../ClassTeacher/CTReplyToParent";
import CTPolls from "../ClassTeacher/CTPolls";

const CustomSTeacherPage = () => {
  const { customId, keyName } = useParams();
  const [userDetails, setUserDetails] = useState(null);
  const [sidebarVisible, setSidebarVisible] = useState(true);
  const { isSidebarOpen, currentSidebarTab, toggleSidebar, setIsSidebarOpen } = useSidebar();
  const [data, setData] = useState(true);

  // const toggleSidebar = () => {
  //   setSidebarVisible(!sidebarVisible);
  // };

  useEffect(() => {
    if (customId) {
      const decodedData = JSON.parse(atob(customId));
      setUserDetails(decodedData);
    }
  }, [customId, keyName]);

  return (
    <>
      {data && <Navbar data={userDetails} isOpen={sidebarVisible} toggleSidebar={toggleSidebar} />}

      <div className="flex">
        {data && <Sidebar data={userDetails} />}

        <div
          className={`w-full absolute ${
            isSidebarOpen ? "lg:w-4/6 xl:w-9/12" : "w-full"
          } sm:w-11/12 right-0 top-16 mx-auto   p-4 md:p-6 lg:p-8 xl:p-10`}
        >
          {keyName === "STHomeWork" && <CTHomework />}
          {keyName === "STGallery" && <CTGallery />}
          {keyName === "STNoticeBoard" && <CTNoticeBoard />}
          {keyName === "STMyProfile" && <CTProfile />}
          {keyName === "STTimeTable" && <CTTeacherTimeTable />}
          {keyName === "STSubjectList" && <CTSubjectList />}
          {keyName === "STCom" && <CTComBWTeachers />}
          {keyName === "STParentReply" && <CTReplyToParent />}
          {keyName === "STPoll" && <CTPolls />}
        </div>
      </div>
    </>
  );
};

export default CustomSTeacherPage;
