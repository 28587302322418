import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getFullStudentInfoValues } from "../API/getFullStudentInfo";
import { getFullTeacherInfoValues } from "../API/getFullTeacherInfo";
import { getStringValueRecordFromArray } from "../API/getStringValueFromArray";
import { getUserDataFromLocalByKey } from "../API/getStudentInfoByKeyLocal";
import { getCentralServiceDetails } from "../API/GetWebUrlAPI";
import { getUserValidity } from "../API/UserValidityAPI";
import UserImage from "../assets/png/avataruser.png";
import StudentImage from "../assets/png/studentUsers.jpg";
import { useGlobalContext } from "../context/GlobalContext";
import NewRegistration from "./NewRegistration";

const PreLoginPage = () => {
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [clickedButtons, setClickedButtons] = useState([]);
  const navigate = useNavigate();
  const [userType, setUserType] = useState("");
  const [userId, setUserId] = useState("");
  const [year, setYear] = useState("");
  const [adminUsers, setAdminUsers] = useState("");
  const [combinedUsers, setCombinedUsers] = useState([]);
  const [registeredUsers, setRegisteredUsers] = useState([]);
  const [tchType, setTechType] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(null);
  const [loading, setLoading] = useState(false);
  const [userDp, setUserDp] = useState([]);

  const { globalVariable, setGlobalVariable } = useGlobalContext();

  useEffect(() => {
    // Load registered users from storage or API
    const registeredUsersList = JSON.parse(localStorage.getItem("registeredUsers")) || [];

    const userId = "school";
    const year = "2023";
    const key = "webServiceURL";

    let matchedItems = [];
    registeredUsersList?.forEach((innerArray) => {
      const [userData, innerData] = innerArray;

      if (userData?.userId === userId && userData?.year === year) {
        innerData?.forEach((innerItem) => {
          if (innerItem?.ItemKeyName === key) {
            matchedItems?.push(innerItem);
          }
        });
      }
    });

    const registeredDisplay = [];

    for (let i = 0; i < registeredUsersList?.length; i++) {
      if (registeredUsersList[i][0]) {
        registeredDisplay?.push({
          ...registeredUsersList[i][0],
          parentIndex: i,
        });
      }
    }

    setRegisteredUsers(registeredDisplay);
  }, []);

  useEffect(() => {
    const initialRecords = JSON.parse(localStorage?.getItem("registeredUsers")) || [];

    setCombinedUsers(initialRecords);
  }, []);

  const handleCardClick = async (userId, schoolId, userType, year, index) => {
    setLoading(true);

    if (clickedButtons?.includes(index)) {
      return;
    }
    setClickedButtons([...clickedButtons, index]);
    const Key = "webServiceURLAWSDOTNET";

    // Fetch the web service URL using getCentralServiceDetails(schoolId)
    const res = await getCentralServiceDetails(schoolId);
    const matchedItem = res?.find((item) => item?.ItemKeyName === Key);

    if (matchedItem) {
      const webServiceURL = matchedItem?.InfoField;
      const registeredUsersList = JSON.parse(localStorage.getItem("registeredUsers")) || [];
      let updatedRegisteredUsersList = registeredUsersList.map((innerArray) => {
        const [userData, innerData] = innerArray;

        console.log(innerData, "innerData");

        if (userData?.userId === userId && userData?.schoolId === schoolId) {
          const updatedInnerData =
            innerData?.length > 0 &&
            innerData?.map((innerItem) => {
              if (innerItem?.ItemKeyName === Key) {
                return { ...innerItem, InfoField: webServiceURL }; // Update InfoField with 'rojin'
              }
              return innerItem;
            });

          return [userData, updatedInnerData];
        }
        return innerArray;
      });

      // Store the updated data back into localStorage
      localStorage.setItem("registeredUsers", JSON.stringify(updatedRegisteredUsersList));
      const Uservalidityresult = await getUserValidity(userId, schoolId, userType, year);

      if (Uservalidityresult[0]?.InfoField1 === "1") {
        toast.success("Successfully Logged In");

        const userDetails = {
          userType,
          schoolId,
          userId,
          year,
          thType: "",
        };

        if (userType === "Student") {
          const getFullValues = await getFullStudentInfoValues(userId, schoolId, userType, year);
          const combinedValues = res?.concat(getFullValues);
          let registeredUsers = JSON.parse(localStorage.getItem("registeredUsers")) || [];

          // Specify the userId to remove
          let insertIndex = 0;

          // Filter out the record with the specified userId
          registeredUsers = registeredUsers
            .map((innerArray, index) => {
              // Assuming the userId is found in the first element of each innerArray
              if (
                innerArray[0]?.userId === userId &&
                innerArray[0]?.userType === userType &&
                innerArray[0]?.year === year &&
                innerArray[0]?.schoolId === schoolId
              ) {
                insertIndex = index;
                return null; // Exclude this record
              }
              return innerArray;
            })
            .filter(Boolean);

          const combinedUpdated = [userDetails, combinedValues];

          registeredUsers?.splice(insertIndex, 0, combinedUpdated);

          localStorage.setItem("registeredUsers", JSON.stringify(registeredUsers));

          const encodedData = btoa(JSON.stringify(userDetails));

          setTimeout(() => {
            navigate(`/dashboard/u/${encodedData}`);
            // navigate("/verify", { state: { userDetails: newUser, webServiceResult:webService } });
          }, 1000);
        } else if (userType === "Teacher") {
          const getFullTeacherValues = await getFullTeacherInfoValues(userId, schoolId, userType, year);

          const combinedTeacherValues = res?.concat(getFullTeacherValues);

          let registeredUsers = JSON.parse(localStorage.getItem("registeredUsers")) || [];

          // Specify the userId to remove
          let insertIndex = 0;

          // Filter out the record with the specified userId
          registeredUsers = registeredUsers
            .map((innerArray, index) => {
              // Assuming the userId is found in the first element of each innerArray
              if (
                innerArray[0]?.userId === userId &&
                innerArray[0]?.userType === userType &&
                innerArray[0]?.year === year &&
                innerArray[0]?.schoolId === schoolId
              ) {
                insertIndex = index;
                return null; // Exclude this record
              }
              return innerArray;
            })
            .filter(Boolean);

          const key = "EMPLOYEECATEGORY";

          const resultantTeacherType = await getStringValueRecordFromArray(combinedTeacherValues, key);

          const userDetailsNew = {
            userType,
            schoolId,
            userId,
            year,
            thType: resultantTeacherType === "Class Teacher" ? "CT" : "ST",
          };

          const combinedUpdated = [userDetailsNew, combinedTeacherValues];

          registeredUsers?.splice(insertIndex, 0, combinedUpdated);

          localStorage.setItem("registeredUsers", JSON.stringify(registeredUsers));

          const encodedData = btoa(JSON.stringify(userDetailsNew));

          setTimeout(() => {
            navigate(`/dashboard/u/${encodedData}`, {
              state: { userDetailsData: userDetailsNew },
            });
            // navigate("/verify", { state: { userDetails: newUser, webServiceResult:webService } });
          }, 1000);
        } else {
          let registeredUsers = JSON.parse(localStorage.getItem("registeredUsers")) || [];

          // Specify the userId to remove
          let insertIndex = 0;

          // Filter out the record with the specified userId
          registeredUsers = registeredUsers
            .map((innerArray, index) => {
              // Assuming the userId is found in the first element of each innerArray
              if (
                innerArray[0]?.userId === userId &&
                innerArray[0]?.userType === userType &&
                innerArray[0]?.year === year &&
                innerArray[0]?.schoolId === schoolId
              ) {
                insertIndex = index;
                return null; // Exclude this record
              }
              return innerArray;
            })
            .filter(Boolean);

          const combinedUpdated = [userDetails, res];

          registeredUsers?.splice(insertIndex, 0, combinedUpdated);

          localStorage.setItem("registeredUsers", JSON.stringify(registeredUsers));

          const encodedData = btoa(JSON.stringify(userDetails));

          setTimeout(() => {
            navigate(`/dashboard/u/${encodedData}`, {
              state: { userDetailsData: userDetails },
            });
            // navigate("/verify", { state: { userDetails: newUser, webServiceResult:webService } });
          }, 1000);
        }
      } else {
        const val = Uservalidityresult[0]?.InfoField;
        toast.error("Permission denied " + val);
        alert(val);
      }
    } else {
      console.log("No item found with the specified key name.");
    }
    setLoading(false);
    // Assuming this is the URL you want to store

    // Replace this with your actual key name
  };

  const onDeleteUser = (index) => {
    // Set the delete index and show the modal for confirmation
    setDeleteIndex(index);
    setShowModal(true);
  };

  const closeModal = () => {
    // Close the modal without performing the deletion
    setShowModal(false);
    setDeleteIndex(null);
  };

  const confirmDelete = () => {
    // Perform delete action if confirmed
    const res = JSON.parse(localStorage.getItem("registeredUsers"));
    res?.splice(registeredUsers[deleteIndex].parentIndex, 1);
    localStorage.setItem("registeredUsers", JSON.stringify(res));

    // Update the state to remove the deleted user
    setRegisteredUsers((prev) => {
      const updatedUsers = [...prev];
      updatedUsers.splice(deleteIndex, 1);
      return updatedUsers;
    });

    // Hide the modal after deletion
    setShowModal(false);
  };

  const fetchDp = async () => {
    if (registeredUsers?.length > 0) {
      // Create an array of promises

      const userDpPromises = registeredUsers?.map(async (data) => {
        const key = "PHOTOFILE";

        const rekey = "WebResourceAWSDOTNETURL";
        const dataResourceUrl = await getUserDataFromLocalByKey(data.userId, data.schoolId, data.userType, data.year, rekey);
        const returSrc = await getUserDataFromLocalByKey(data.userId, data.schoolId, data.userType, data.year, key);
        let folder;
        if (data?.userType === "Teacher") {
          folder = "PICPAY";
        } else {
          folder = "PICS";
        }

        return {
          image: returSrc,

          resourceUrl: dataResourceUrl,
          folder: folder,
          userId: data.userId,
          schoolId: data.schoolId,
          userType: data.userType,
          year: data.year,
        };
      });

      const usersDp = await Promise.all(userDpPromises);

      setUserDp(usersDp);
    }
  };

  useEffect(() => {
    fetchDp();
  }, [registeredUsers]);

  return (
    <>
      {registeredUsers && registeredUsers?.length === 0 ? (
        <NewRegistration />
      ) : (
        <div className="h-screen bg-gradient-to-br from-blue-50 to-indigo-100 py-6 px-2 sm:px-4 lg:px-6 flex justify-center items-center">
          <div className="max-w-5xl mx-auto">
            <div className="bg-white rounded-xl shadow-lg overflow-hidden">
              <div className="flex flex-col lg:flex-row">
                {" "}
                {/* Reduced height */}
                {/* Left Side - Image Section */}
                <div className="lg:w-1/2 relative">
                  <img src={StudentImage} className="h-full w-full object-cover" alt="Students studying" />
                  <div className="absolute inset-0 bg-blue-900/20 flex items-center justify-center">
                    <div className="text-center text-white p-4 backdrop-blur-sm bg-blue-900/30 rounded-lg">
                      <h1 className="text-2xl font-bold mb-1">Welcome Back!</h1> {/* Reduced font size */}
                      <p className="text-base">Access your school portal</p> {/* Reduced font size */}
                    </div>
                  </div>
                </div>
                {/* Right Side - Content Section */}
                <div className="lg:w-1/2 p-2 lg:p-4">
                  <div className="text-center mb-6">
                    <h2 className="text-2xl font-bold text-gray-800 mb-1">St.Arnold School {globalVariable}</h2>
                    <div className="w-16 h-1 bg-blue-600 mx-auto rounded-full"></div> {/* Reduced width */}
                    <p className="mt-2 text-base font-medium text-gray-600">Select Your Account</p>
                  </div>

                  {/* User Grid */}
                  <div className="grid grid-cols-2 sm:grid-cols-3 gap-3 mb-6">
                    {userDp.length > 0 &&
                      userDp.map((item, index) => (
                        <div key={index} className="relative group">
                          <Link
                            to={``}
                            className={`block p-3 rounded-lg transition-all duration-200
                            ${
                              clickedButtons.includes(index)
                                ? "opacity-50 pointer-events-none"
                                : "hover:shadow-md hover:transform hover:-translate-y-1"
                            }
                            bg-gradient-to-br from-white to-blue-50 border border-blue-100`}
                            onClick={() => handleCardClick(item.userId, item.schoolId, item.userType, item.year, index)}
                          >
                            <div className="flex flex-col items-center">
                              <div className="w-16 h-16 bg-blue-100 rounded-full flex items-center justify-center mb-2">
                                <img
                                  className="h-14 w-14 object-cover rounded-full"
                                  src={item?.image ? `${item?.resourceUrl}/${item?.folder}/01/${item?.image}` : UserImage}
                                  alt=""
                                />
                              </div>
                              <div className="text-center">
                                <p className="text-xs font-semibold text-gray-800 mb-1">ID: {item.userId}</p>
                                <p className="text-xs font-medium text-blue-600 mb-1">{item.userType}</p>
                                <p className="text-xs text-gray-500">Year: {item.year}</p>
                              </div>
                            </div>
                          </Link>

                          {/* Delete Button - Appears on Hover */}
                          <button
                            className="absolute -top-2 -right-2 w-5 h-5 bg-red-500 text-white rounded-full 
                            flex items-center justify-center shadow-md transform 
                            transition-all duration-200 hover:bg-red-600 hover:scale-110"
                            onClick={() => onDeleteUser(index)}
                          >
                            ×
                          </button>
                        </div>
                      ))}
                  </div>

                  {/* Register New User Button */}
                  <div className="text-center">
                    <Link
                      to="/register"
                      className="inline-flex items-center px-4 py-2 rounded-lg
                        bg-gradient-to-r from-blue-600 to-indigo-600 
                        text-white font-semibold transition-all duration-200
                        hover:from-blue-700 hover:to-indigo-700 
                        focus:ring-2 focus:ring-blue-300 focus:ring-offset-2"
                    >
                      <svg className="w-4 h-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 4v16m8-8H4" />
                      </svg>
                      Register New User
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Modal */}
          {showModal && (
            <div className="fixed inset-0 flex items-center justify-center z-50">
              <div className="absolute inset-0 bg-black/50 backdrop-blur-sm"></div>
              <div className="relative bg-white rounded-lg p-6 max-w-xs w-full mx-4 shadow-lg">
                <div className="text-center">
                  <div className="w-14 h-14 bg-red-100 rounded-full flex items-center justify-center mx-auto mb-3">
                    <svg className="w-6 h-6 text-red-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                      />
                    </svg>
                  </div>
                  <h3 className="text-lg font-semibold text-gray-900 mb-2">Confirm Deletion</h3>
                  <p className="text-gray-600 mb-4">Are you sure you want to delete this user? This action cannot be undone.</p>
                  <div className="flex space-x-4 justify-center">
                    <button
                      onClick={confirmDelete}
                      className="px-4 py-2 bg-red-600 text-white rounded-md font-semibold
                        hover:bg-red-700 focus:ring-2 focus:ring-red-300 transition-all duration-200"
                    >
                      Delete
                    </button>
                    <button
                      onClick={closeModal}
                      className="px-4 py-2 bg-gray-200 text-gray-800 rounded-md font-semibold
                        hover:bg-gray-300 focus:ring-2 focus:ring-gray-300 transition-all duration-200"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default PreLoginPage;
