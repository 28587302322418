import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import Spinner from "../../components/Spinner";
import Navbar from "../../components/Navbar";
import Sidebar from "../../components/Sidebar";
import { useSidebar } from "../../context/SidebarContext";
import Images from "../../assets/Files/images.png";
import PDFImage from "../../assets/Files/pdficconpng.png";
import powerpointIcon from "../../assets/Files/PPTIcon.png";
import WordIcon from "../../assets/Files/wordicon.png";
import VideoIcon from "../../assets/Files/videoicon.png";
import Youtube from "../../assets/Files/youtube.png";
import Excel from "../../assets/Files/excel.svg";
import textMessage from "../../assets/Files/textmsg.png";
import OtherImg from "../../assets/Files/otherico.png";
import { getUserDataFromLocalByKey } from "../../API/getStudentInfoByKeyLocal";
import AudioIcon from "../../assets/Files/musicIcon.png";
import { FaPlus } from "react-icons/fa";
import { getAdminClassNoticeList } from "../../API/getAdminClassNoticeList";
import { Trash2 } from "lucide-react";
import { deleteHWSMNotification } from "../../API/deleteHWSMNotification";
import { toast } from "react-toastify";
import DeleteModal from "../../components/DeleteModal";

const AdminClassNoticeList = () => {
  const { customId, className } = useParams();
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredNotice, setFilteredNotice] = useState([]);
  const [userDetails, setUserDetails] = useState(null);
  const [data, setData] = useState(true);
  const [loading, setLoading] = useState(true);
  const [noticeList, setNoticeList] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [selectedNotice, setSelectedNotice] = useState(null);
  const [contentType, setContentType] = useState("default");
  const [initialResourceUrl, setInitialResourceUrl] = useState(null);
  const [resourceUrl, setResourceUrl] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedDltNotice, setSelectedDltNotice] = useState(null);

  const handleSearch = (value) => {
    setSearchTerm(value);

    if (value.trim() === "") {
      setFilteredNotice([]);
    } else {
      const filtered = noticeList.filter((notice) =>
        notice.HWSubject.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredNotice(filtered);
    }
  };

  const renderImage = (photoLocation) => {
    if (photoLocation === "") {
      return textMessage;
    }

    if (photoLocation.endsWith(".jpg")) {
      // setContentType('Same')
      return Images;
    } else if (photoLocation.endsWith(".pdf")) {
      // setContentType("Same")
      return PDFImage;
    } else if (photoLocation.includes(".ppt")) {
      // setContentType("Same")
      return powerpointIcon;
    } else if (photoLocation.includes(".doc")) {
      // setContentType("Same")
      return WordIcon;
    } else if (photoLocation.includes(".xls")) {
      // setContentType("Same")
      return Excel;
    } else if (photoLocation.includes(".mp4")) {
      // setContentType("Same")
      return VideoIcon;
    } else if (photoLocation.includes(".mp3")) {
      // setContentType("Same")
      return AudioIcon;
    } else if (photoLocation.includes("v=")) {
      return Youtube;
    } else {
      return OtherImg;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const users = JSON.parse(localStorage.getItem("registeredUsers")) || [];

      if (customId) {
        const decodedData = JSON.parse(atob(customId));
        let matchedItem = [];

        users.forEach((innerArray) => {
          const [userData] = innerArray;

          if (
            userData.userId === decodedData.userId &&
            userData.userType === decodedData.userType &&
            userData.schoolId === decodedData.schoolId &&
            userData.year === decodedData.year
          ) {
            matchedItem.push(userData);
          }
        });

        const result = await getAdminClassNoticeList(
          decodedData.userId,
          decodedData.schoolId,
          decodedData.userType,
          decodedData.year,
          className
        );

        const key = "WebResourceAWSDOTNETURL";

        const dataResourceUrl = await getUserDataFromLocalByKey(
          decodedData.userId,
          decodedData.schoolId,
          decodedData.userType,
          decodedData.year,
          key
        );

        setResourceUrl(dataResourceUrl);

        const keyval = "webResourceURL";
        const dataUrl = await getUserDataFromLocalByKey(
          decodedData.userId,
          decodedData.schoolId,
          decodedData.userType,
          decodedData.year,
          keyval
        );
        setInitialResourceUrl(dataUrl);

        console.log("NOTICE LIST  RESULT", result);
        setNoticeList(result);
        setLoading(false);
        setUserDetails(matchedItem);
      }
    };

    fetchData();

  }, [customId,isDeleteModalOpen]);

  //console.log(noticeList);

  const checkContentType = () => {
    if (selectedNotice.PhotoLocation.includes("v=")) {
      return (
        <iframe
          title="Notification Content"
          src={`https://www.youtube.com/embed/${
            selectedNotice.PhotoLocation.split("v=")[1]
          }`}
          className="w-full h-56 border-none"
          style={{ overflow: "auto" }}
        ></iframe>
      );
    }
    if (selectedNotice.PhotoLocation === "") {
      return null;
    } else {
      return (
        <iframe
          title="Notification Content"
          src={`${resourceUrl}/StudyMaterials/${selectedNotice.PhotoLocation}`}
          className="w-full h-56 border-none"
          style={{ overflow: "auto" }}
        ></iframe>
      );
    }
  };

  const handleConfirmDelete = async () => {
    try {
      const response = await deleteHWSMNotification(
        userDetails[0].userId,
        userDetails[0].schoolId,
        userDetails[0].userType,
        userDetails[0].year,
        "NOTBRD",
        selectedDltNotice?.HWID
      );

      toast.success(response[0].InfoField);
    } catch (error) {
      console.log(error);
    }

    setIsDeleteModalOpen(false);
    setSelectedDltNotice(null);
  };

  const handleDeleteClick = (notification, e) => {
    e.stopPropagation();
    setSelectedDltNotice(notification);
    setIsDeleteModalOpen(true);
  };

  const handleCancelDelete = () => {
    setIsDeleteModalOpen(false);
    setSelectedDltNotice(null);
  };

  const openPopup = (notification) => {
    setSelectedNotice(notification);
    setShowPopup(true);
  };

  const closePopup = () => {
    setShowPopup(false);
    setSelectedNotice(null);
  };

  const noticeToRender = searchTerm ? filteredNotice : noticeList;

  const { isSidebarOpen, currentSidebarTab, toggleSidebar, setIsSidebarOpen } =
    useSidebar();

  return (
    <>
      <DeleteModal
        isOpen={isDeleteModalOpen}
        onClose={handleCancelDelete}
        onConfirm={handleConfirmDelete}
        title={"Notice Board"}
      />

      {data && (
        <Navbar
          data={userDetails}
          // isOpen={sidebarVisible}
          toggleSidebar={toggleSidebar}
        />
      )}

      <div className="flex">
        {data && <Sidebar data={userDetails} />}
        <div className="container mx-auto p-4">
          {loading ? (
            <Spinner />
          ) : (
            <>
              <div
                className={`w-full absolute ${
                  isSidebarOpen ? "lg:w-4/6 xl:w-9/12" : "w-full"
                } sm:w-11/12 right-0 top-16 mx-auto   p-4 md:p-6 lg:p-8 xl:p-10`}
              >
                <div className="lg:flex items-center justify-between mb-4">
                  <div className="w-2/3">
                    <h2 className=" md:text-2xl font-bold text-xl mb-2 md:mb-0">
                      Admin :
                      {userDetails &&
                        userDetails.length > 0 &&
                        userDetails[0].schoolId}
                    </h2>
                    <h4 className="mb-2 font-bold">
                      Class wise Notice List New {className}
                    </h4>
                  </div>
                  <div className=" w-2/6">
                    <input
                      type="text"
                      placeholder="Search by title"
                      value={searchTerm}
                      onChange={(e) => handleSearch(e.target.value)}
                      className="border border-gray-600 w-80  h-12 px-5 py-1 rounded-md"
                    />
                  </div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-4">
                  <Link
                    to={`/admin/u/${customId}/${className}/NoticeSubmit`}
                    className="bg-red-500 text-white px-5 text-center py-4 rounded hover:bg-red-500 transition duration-300 flex items-center justify-center"
                  >
                    <FaPlus className="mr-2 " />
                    Create New Notice
                  </Link>
                  <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-4">
                    {noticeToRender &&
                      noticeToRender.map((notice) => (
                        <div
                          className="flex justify-between items-center border border-gray-200 rounded-lg  hover:shadow-md transition duration-300"
                          key={notice.HWID}
                        >
                          <Link
                            to={``}
                            onClick={() => openPopup(notice)}
                            className="flex-grow"
                          >
                            <div className="p-4">
                              <div className="flex items-center">
                                <div className="w-1/3 sm:w-auto mr-4">
                                  <img
                                    src={renderImage(notice.PhotoLocation)}
                                    alt={notice.title}
                                    className="w-20 h-20 sm:w-32 sm:h-32 object-cover rounded"
                                  />
                                </div>

                                <div className="w-2/3">
                                  <h2 className="text-lg font-semibold mb-2">
                                    {notice.HWSubject}
                                  </h2>
                                  <h4 className="text-lg font-semibold mb-2">
                                    {notice.HWDateStr}
                                  </h4>
                                  <p className="text-gray-600">
                                    {notice.HWRemarks.substring(0, 50)}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </Link>

                          <button
                            className="text-red-500 hover:text-red-700 p-2 ml-2 rounded-full hover:bg-red-50"
                            title="Delete Notice"
                            onClick={(e) => handleDeleteClick(notice, e)}
                          >
                            <Trash2 className="w-5 h-5" />{" "}
                            {/* Lucide icon for trash */}
                          </button>
                        </div>
                      ))}
                  </div>
                </div>

                {showPopup && (
                  <div className="fixed inset-0 flex items-center justify-center mt-10 bg-black bg-opacity-60">
                    <div className="bg-white customPopupHeight relative rounded-lg mt-8 shadow-lg p-8 md:w-1/2 w-full ">
                      <div className="w-1/3 sm:w-auto mr-4">
                        <img
                          src={renderImage(selectedNotice.PhotoLocation)}
                          alt=""
                          className="w-20  sm:w-32  object-cover rounded"
                        />
                      </div>

                      <button
                        onClick={closePopup}
                        className="px-4 py-2  absolute right-5 top-0 mt-8 bg-red-600 text-white rounded"
                      >
                        Close
                      </button>

                      <div className="w-2/3 ">
                        <h2 className="text-lg font-semibold mb-2">
                          {selectedNotice.HWSubject}
                        </h2>
                        <h4 className="text-lg font-semibold mb-2">
                          {selectedNotice.HWDateStr}
                        </h4>
                        <p className="text-gray-600">
                          {selectedNotice.HWRemarks}
                        </p>
                      </div>

                      {checkContentType()}
                    </div>
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default AdminClassNoticeList;
