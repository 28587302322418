import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker"; // Import DatePicker
import "react-datepicker/dist/react-datepicker.css";
import { useParams } from "react-router-dom";
import { getClassSelectionList } from "../../API/getClassSelectionListAPI";
import { getUserDataFromLocalByKey } from "../../API/getStudentInfoByKeyLocal";

function AdminNoticeSearchForm({ onSubmit }) {
  const { customId } = useParams();
  const [userDetails, setUserDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [initialResourceUrl, setInitialResourceUrl] = useState(null);
  const [sectionList, setSectionList] = useState([]);
  const [selectedFromDate, setSelectedFromDate] = useState(null);
  const [selectedToDate, setSelectedToDate] = useState(null);
  const [message, setMessage] = useState("");
  const [selectedClass, setSelectedClass] = useState(null);
  const [selectedSection, setSelectedSection] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const users = JSON.parse(localStorage.getItem("registeredUsers")) || [];

      if (customId) {
        const decodedData = JSON.parse(atob(customId));
        let matchedItem = [];

        users.forEach((innerArray) => {
          const [userData] = innerArray;

          if (
            userData.userId === decodedData.userId &&
            userData.userType === decodedData.userType &&
            userData.schoolId === decodedData.schoolId &&
            userData.year === decodedData.year
          ) {
            matchedItem.push(userData);
          }
        });

        const result = await getClassSelectionList(decodedData.userId, decodedData.schoolId, decodedData.userType, decodedData.year);

        const keyval = "webResourceURL";
        const dataUrl = await getUserDataFromLocalByKey(
          decodedData.userId,
          decodedData.schoolId,
          decodedData.userType,
          decodedData.year,
          keyval
        );
        setInitialResourceUrl(dataUrl);
        setSectionList(result);
        setLoading(false);
        setUserDetails(matchedItem);
      }
    };

    fetchData();
  }, [customId]);

  useEffect(() => {
    const userYear = userDetails && userDetails[0] && userDetails[0].year;
    const initialFromDate = new Date(userYear, 3, 1); // Month index starts from 0, so 3 corresponds to April
    setSelectedFromDate(initialFromDate);
  }, [userDetails]);

  useEffect(() => {
    const today = new Date();
    const formattedToday = formatDate(today);
    setSelectedToDate(today);
  }, []);

  const formatDate = (date) => {
    if (!date) return ""; // Handle null or undefined dates

    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    // Ensure leading zeros for day and month
    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = month < 10 ? `0${month}` : month;

    return `${formattedDay}-${formattedMonth}-${year}`;
  };

  // Variables to store formatted date strings
  const formattedFromDate = formatDate(selectedFromDate);
  const formattedToDate = formatDate(selectedToDate);

  const head1Options = sectionList
    .slice(1)
    .map((section) => section.Head1)
    .filter(Boolean);

  const highestTotNosSection = () => {
    let highestSection = null;
    let highestTotNos = 0;

    sectionList.forEach((section) => {
      const totNos = parseInt(section.TotNos, 10);
      if (!isNaN(totNos) && totNos > highestTotNos) {
        highestTotNos = totNos;
        highestSection = section;
      }
    });

    return highestSection;
  };

  const highestTotNosSectionValue = highestTotNosSection();
  const head2Options = highestTotNosSectionValue ? highestTotNosSectionValue.Head2.split(",") : [];

  const handleSearch = () => {
    // Add your search logic here
    // console.log("Search clicked!");
    console.log(formattedFromDate, formattedToDate, message, selectedSection, selectedClass);

    const formData = {
      formattedFromDate,
      formattedToDate,
      message,
      selectedClass,
      selectedSection,
    };

    onSubmit(formData);
  };

  return (
    <>
      <div className="w-full lg:flex items-center justify-center mt-4">
        <label className="block mb-2  text-sm font-bold text-gray-700 mr-2 ml-3 lg:ml-0">From Date :</label>
        <DatePicker
          selected={selectedFromDate}
          onChange={(date) => setSelectedFromDate(date)}
          placeholderText="Select From Date"
          wrapperClassName="lg:w-1/2 w-full"
          dateFormat="dd/MM/yyyy"
          className="textInput w-full"
        />
      </div>

      <div className="w-full lg:flex items-center justify-center mt-3">
        <label className="block mb-2  text-sm font-bold text-gray-700 mr-2 ml-3 lg:ml-0">To Date :</label>
        <DatePicker
          selected={selectedToDate}
          onChange={(date) => setSelectedToDate(date)}
          placeholderText="Select To Date"
          wrapperClassName="lg:w-1/2 w-full"
          dateFormat="dd/MM/yyyy"
          className="textInput w-full"
        />
      </div>

      <div className="w-full lg:flex items-center justify-center mt-3">
        <label className="block mb-2  text-sm font-bold text-gray-700 mr-2 ml-3 lg:ml-0">Notice Type :</label>
        <select value={message} onChange={(e) => setMessage(e.target.value)} className="textInput lg:w-1/2 w-full">
          <option value="">--select--</option>
          <option value="all">ALL</option>
          <option value="admin">ADMIN</option>
          <option value="class-wise">CLASS WISE</option>
          <option value="class-teacher">CLASS TEACHER</option>
          <option value="parents-and-teacher">PARENTS AND TEACHER</option>
        </select>
      </div>

      <div className="w-full lg:flex items-center justify-center mt-3">
        <label className="block mb-2 text-sm font-bold text-gray-700 mr-2 ml-3 lg:ml-0">Select Class:</label>
        <select value={selectedClass} onChange={(e) => setSelectedClass(e.target.value)} className="textInput lg:w-1/2 w-full">
          <option value="">🧍 Select Class</option>
          {head1Options.map((option) => (
            <option key={option} value={option}>
              🧍 {option}
            </option>
          ))}
        </select>
      </div>

      <div className="w-full lg:flex items-center justify-center mt-3">
        <label className="block mb-2 text-sm font-bold text-gray-700 mr-2 ml-3 lg:ml-0">Select Section:</label>
        <select value={selectedSection} onChange={(e) => setSelectedSection(e.target.value)} className="textInput lg:w-1/2 w-full">
          <option value="Not Selected"> 🧍Not Selected</option>
          {head2Options.map((option, index) => (
            <option key={index} value={option}>
              🧍 {option}
            </option>
          ))}
        </select>
      </div>

      <div className="w-full flex items-center justify-center mt-4">
        <button className="buttonClass mb-3" onClick={handleSearch}>
          Search
        </button>
      </div>
    </>
  );
}

export default AdminNoticeSearchForm;
