export function convertDateString(dateString) {
    const regex = /\/Date\((\d+)([+-]\d{4})\)\//;
    const match = dateString.match(regex);
    if (!match) {
      throw new Error("Invalid date format");
    }
    const timestamp = parseInt(match[1], 10);
    const date = new Date(timestamp);
    const day = String(date.getUTCDate()).padStart(2, "0");
    const month = String(date.getUTCMonth() + 1).padStart(2, "0");
    const year = date.getUTCFullYear();
  
    return `${day}-${month}-${year}`;
  }