import { getUserInfoKey } from '../API/GetUserInfoByKey'
import axios from "axios";


export const getCTStudentProfileInfo = async (userId,schoolId,userType,year,stuID) => {
    

    const key='webServiceURLAWSDOTNET'

   const returnUrl= await getUserInfoKey(userId, schoolId, userType, year, key)


     

   console.log(key)

    try {
          
        try {

            const formData = new FormData();
            formData.append("title", "GetStudentsInfoFull");
            formData.append("description", "");
            formData.append("ReqAcastart", year);
            formData.append("ReqUserID", stuID);
        
        
            let userdata = [];
        
            const Corskey=process.env.REACT_APP_CORS_URL;
        
            // const result = await axios.post(
            //   "https://cors-anywhere.herokuapp.com/http://apkarnold.newgentechnology.net/WebDataProcessingServer.aspx",
            //   formData
            // );
        
            const result = await axios.post(
              `${Corskey}${returnUrl}`,
              formData
            );
        
            const responseData = result.data;

           
            userdata = JSON.parse(
              result.data.substring(0, result.data.indexOf("||JasonEnd", 0))
            );
        
            return userdata;
        
          
          } catch (error) {
            console.error("An error occurred:", error);
            // Handle error here
          }
       
        
    
  
        
         

    } catch (error) {
      console.error("An error occurred:", error);
      // Handle error here
    }
    // Placeholder JSX, replace with actual JSX
  };