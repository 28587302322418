import React, { useCallback, useEffect, useRef, useState } from "react";
// import Calendar from "react-calendar";
import { useLocation, useParams } from "react-router-dom";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import stdProfile from "../../assets/png/avatar.png";

import { Accordion } from "flowbite-react";

import { toast } from "react-toastify";
import { RiMenu3Fill } from "react-icons/ri";
import Spinner from "../../components/Spinner";
import { getStaffIndividualDataAPI, GetStaffAttByMonth, GetEmployeeMasterByCode } from "../../API/getStaffIndividualDataAPI";
import ModalFunctionComponent from "../../components/ModalFunctionComponent";
import { GetStaffListERPQI } from "../../API/GetStaffListERPQI";

const generateSundayEvents = (startDate, endDate) => {
  const events = [];
  let currentDate = new Date(startDate);

  while (currentDate <= endDate) {
    if (currentDate.getDay() === 0) {
      // Sunday
      events.push({
        title: "",
        start: currentDate.toISOString().split("T")[0],
        allDay: true,
        classNames: ["bg-red-500"],
      });
    }
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return events;
};

function transformData(apiResponse, selectedMonth) {
  const currentYear = new Date().getFullYear();
  const month = selectedMonth.toString().padStart(2, "0"); // Format month as MM

  return apiResponse.flatMap((item) => {
    const events = [];

    for (let i = 1; i <= 7; i++) {
      const day = item[`Hour${i}`];
      const val = item[`Teacher${i}`];
      if (day) {
        const formattedDate = `${currentYear}-${month}-${day.padStart(2, "0")}`;
        events.push({
          title: val ? val : "",
          date: formattedDate,
          color: val === "ABPR" || val === "ABCL" || val === "ABAB" || val === "ABWL" ? "#E72929" : "#4ade80",
        });
      }
    }

    return events;
  });
}

function getCurrentMonth() {
  const currentDate = new Date(); // Get the current date
  return currentDate.getMonth() + 1; // Months are 0-indexed, so add 1
}

const QuickInfoStaffProfile = () => {
  const currMonth = getCurrentMonth();
  const [loading, setLoading] = useState(false);
  const [staffProfile, setStaffProfile] = useState([]);
  const calendarRef = useRef(null);
  const [eventData, setEventData] = useState([]);
  const [isAccordionOpen, setAccordionOpen] = useState(false);

  const [students, setStudents] = useState([]);
  const [selectedName, setSelectedName] = useState("");
  const [classList, setClassList] = useState([]);
  const [showListModal, setShowListModal] = useState(false);
  const [paymentData, setPaymentData] = useState([]);
  const [paymentDetails, setPaymentDetails] = useState([]);
  const [currentMonth, setCurrentMonth] = useState(currMonth);

  const location = useLocation();
  const { customId } = useParams();
  const [selectedEmp, setSelectedEmp] = useState(location.state.empCode);
  const [selectedEmpId, setSelectedEmpId] = useState(location.state.empId);
  const [userDetails, setUserDetails] = useState(null);

  useEffect(() => {
    // setLoading(true)
    const fetchData = async () => {
      setLoading(true);
      const users = JSON.parse(localStorage.getItem("registeredUsers")) || [];

      if (customId) {
        const decodedData = JSON.parse(atob(customId));
        let matchedItem = [];

        users.forEach((innerArray) => {
          const [userData] = innerArray;

          if (
            userData.userId === decodedData.userId &&
            userData.userType === decodedData.userType &&
            userData.schoolId === decodedData.schoolId &&
            userData.year === decodedData.year
          ) {
            matchedItem.push(userData);
          }
        });

        setUserDetails(matchedItem);
        setLoading(false);
      }
    };

    fetchData();
  }, [customId]);

  const fetchCurrentMonthData = useCallback(async () => {
    try {
      const response = await GetStaffAttByMonth(userDetails?.[0]?.year, currentMonth, selectedEmpId);
      const action = response[0]?.ActionType;
      if (action > 0) {
        const JSONData1 = JSON.parse(response[0]?.JSONData1);
        const data = transformData(JSONData1, currentMonth);
        setEventData(data);
      }
    } catch (error) {
      console.log(error);
    }
  }, [currentMonth, selectedEmpId]);

  useEffect(() => {
    fetchCurrentMonthData();
  }, [fetchCurrentMonthData]);

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      const result = await GetEmployeeMasterByCode(userDetails?.[0]?.year, selectedEmp, selectedEmpId);
      console.log(result);
      setStaffProfile(result[0]?.EmpMaster);
      setPaymentData(JSON.parse(result[0]?.JSONData2));
      setPaymentDetails(JSON.parse(result[0]?.JSONData1));
      // setClassList(result);
      setLoading(false);
    };

    fetchData();
  }, [selectedEmpId, selectedEmp]);

  useEffect(() => {
    // Generate events for all Sundays in the current year
    const startDate = new Date(new Date().getFullYear(), 0, 1); // January 1st of the current year
    const endDate = new Date(new Date().getFullYear(), 11, 31); // December 31st of the current year
    const sundays = generateSundayEvents(startDate, endDate);
    setEventData(sundays);
  }, []);

  const handleDateClick = async (info) => {
    console.log(info);
  };

  const handleMonthChange = () => {
    if (calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();
      const currentDate = calendarApi.getDate();
      const currentMonth = currentDate.getMonth() + 1;
      console.log("Current month:", currentMonth);
      setCurrentMonth(currentMonth);
      return currentMonth;
    }
  };

  useEffect(() => {
    // Force a resize of the FullCalendar component after it has been rendered
    if (isAccordionOpen && calendarRef.current) {
      calendarRef.current.getApi().updateSize();
      calendarRef.current.getApi().render();
    }
  }, [isAccordionOpen]);

  let studentInfoDetails1 = [
    { "Special Duties": staffProfile[0]?.SplDuties },
    { "Father Occupation": staffProfile[0]?.FatherOccupation },
    { GENDER: staffProfile[0]?.Gender },
    { "Age / Date of Birth": staffProfile[0]?.DateofBirth },
    { Designation: staffProfile[0]?.Designation },
    { "Date of Join": staffProfile[0]?.DOFJOIN },
    { Quialification: staffProfile[0]?.Qualification },
    { "Employee Type": staffProfile[0]?.EmployeeType },
    { Department: staffProfile[0]?.Department },
    { "Account Category": staffProfile[0]?.BG },
    { "Mobile No": staffProfile[0]?.PhoneNo },
    { "Email Address": staffProfile[0]?.EmailAddress },
    { "Present Address": `${staffProfile[0]?.PrsAddress0},${staffProfile[0]?.PrsAddress2}` },
    { "Permanent Address": staffProfile[0]?.PerAddress1 },
    { Religion: staffProfile[0]?.Religion },
    { Community: staffProfile[0]?.Community },
    { "Res. Category": staffProfile[0]?.Category },
    { "PF No": staffProfile[0]?.AADHARIDS },
    { "UAN No": "" },
    { "ESI No": staffProfile[0]?.ESIAccountNo },
    { "AADHAR No.": staffProfile[0]?.ADHARNO },
    { "PAN No": staffProfile[0]?.PANNo },
    { "Bank Acc. No": staffProfile[0]?.BankAccno },
    { "Bank Name": staffProfile[0]?.BankName },
    { Married: staffProfile[0]?.Unmarried },
    { "Spouse Occupation": staffProfile[0]?.SPOUSEOCC },
    { "Spouse Phone": staffProfile[0]?.SPOUSEPHONE },
    { "Loan Due": "" },
    { "Advance Due": "" },
    { "Salary Earned Current Year\n(Net+Loan ded.+Advance Ded.)": "" },
  ];

  const handleMouseEnter = () => {
    setTimeout(() => {
      setAccordionOpen(!isAccordionOpen);
    }, 200);
  };

  const handleMouseLeave = () => {
    setTimeout(() => {
      setAccordionOpen(!isAccordionOpen);
    }, 200);
  };

  const handleSearch = async () => {
    try {
      setLoading(true);
      const response = await GetStaffListERPQI();
      setStudents(response);
      setClassList(response);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
      toast.error("Error");
    }
  };

  const handleClear = () => {
    setSelectedName("");
    setStudents([]);
  };

  const handleSelect = (stud) => {
    setSelectedEmp(stud);
    setShowListModal(false);
  };

  const handleInputFilter = (value) => {
    const data = [...classList];
    const fil = data?.filter((item) => item.EmployeeName.toLowerCase().includes(value.toLowerCase()));
    setStudents(fil);
  };

  return (
    <>
      <div className="lg:mt-0 mt-5">
        {showListModal && (
          <ModalFunctionComponent close={setShowListModal} title={"Employee List"}>
            <div className="flex justify-center w-full pe-2 mb-2 md:mb-0 ">
              <div className="grid lg:grid-cols-5 md:grid-cols-3 grid-cols-1 gap-1 justify-center w-full formBackground p-2">
                <div className="w-full flex flex-row md:flex-col gap-1">
                  <label htmlFor="" className="basis-[40%] align-middle flex items-center text-sm text-start font-semibold">
                    New
                  </label>
                  <input
                    value={selectedName}
                    onChange={(e) => {
                      setSelectedName(e.target.value);
                      handleInputFilter(e.target.value);
                    }}
                    type="text"
                    className="w-full textInput"
                  />
                </div>
                <div className="w-full flex flex-row md:flex-col gap-1">
                  <label htmlFor="" className="basis-[40%] align-middle flex items-center text-sm text-start font-semibold">
                    Father Name
                  </label>
                  <input type="text" className="w-full textInput" />
                </div>

                <div className="flex justify-between mt-2 w-full">
                  <div className="flex gap-2">
                    <button onClick={handleSearch} type="button" className="buttonClass">
                      Search
                    </button>
                    <button onClick={handleClear} type="button" className="buttonClass">
                      Clear
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div>
              {students?.length > 0 ? (
                <>
                  <div className="w-full max-h-[500px] h-full overflow-auto">
                    <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 ">
                      <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400 sticky top-0 z-10">
                        <tr className="tableHead">
                          <th scope="col" className="p-1">
                            Emp Code.
                          </th>
                          <th scope="col" className="p-1">
                            Emp Name
                          </th>
                          <th scope="col" className="p-1">
                            Category
                          </th>
                          <th scope="col" className="p-1">
                            Desig.
                          </th>
                          <th scope="col" className="p-1">
                            Department
                          </th>
                          <th scope="col" className="p-1"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {students.map((student, index) => {
                          return (
                            <tr key={index} className={`${index % 2 === 0 ? "bg-white" : "bg-slate-400"}`}>
                              <td className="p-1 font-medium text-black">{student?.EMPCODE}</td>
                              <td className="p-1 font-medium text-black">{student?.EmployeeName}</td>
                              <td className="p-1 font-medium text-black">{student?.EMPLOYEECATEGORY}</td>
                              <td className="p-1 font-medium text-black">{student?.Designation}</td>
                              <td className="p-1 font-medium text-black"> {student?.Department}</td>
                              <td className="p-1 font-medium text-black">
                                <p
                                  onClick={() => handleSelect(student?.EMPCODE)}
                                  className="text-blue-500 cursor-pointer lowercase text-base"
                                >
                                  Select
                                </p>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </>
              ) : (
                <>
                  <div>
                    <h1>No Records Found</h1>
                  </div>
                </>
              )}
            </div>
          </ModalFunctionComponent>
        )}
        <h2 className="text-center md:text-start bg-emerald-300 mx-auto md:w-fit px-3 my-2 rounded-md shadow-lg md:hidden block text-xl font-mono font-semibold uppercase py-4 w-[80%]">
          Staff Profile
        </h2>

        <div className="container mx-auto p-4 containerBoxShadow">
          {loading ? (
            <Spinner />
          ) : (
            <>
              <div className="flex justify-center mb-2 -mt-2 uppercase">
                <p className="rounded font-medium  cursor-default sub-headings">{staffProfile[0]?.EmployeeName} Profile </p>
              </div>
              <div className="w-full">
                <div className="w-full formBackground flex flex-col gap-2 p-2 md:px-5">
                  <div className="w-full flex md:flex-row flex-col-reverse gap-1">
                    <div className="flex w-full flex-col gap-1 md:basis-[50%] justify-start">
                      <div className="flex gap-1 w-full">
                        <label htmlFor="" className="w-full basis-[30%] align-middle flex items-center text-sm text-start font-semibold">
                          EMPLOYEE CODE
                        </label>
                        <input type="text" value={staffProfile[0]?.EMPCODE} className="max-w-60 w-full textInput " readOnly />
                      </div>

                      <div className="flex gap-1 w-full">
                        <label htmlFor="" className="w-full basis-[30%] align-middle flex items-center text-sm text-start font-semibold">
                          EMPLOYEE NAME
                        </label>
                        <input type="text" value={staffProfile[0]?.EmployeeName} className="max-w-60 w-full textInput " readOnly />
                      </div>

                      <div className="flex gap-1 w-full">
                        <label htmlFor="" className="w-full basis-[30%] align-middle flex items-center text-sm text-start font-semibold">
                          NATURE OF WORK
                        </label>
                        <input
                          type="text"
                          value={`${staffProfile[0]?.EMPLOYEECATEGORY}: ${staffProfile[0]?.EMPCLASS}-${staffProfile[0]?.EMPSECTION}`}
                          className="max-w-60 w-full textInput "
                          readOnly
                        />
                      </div>

                      <div className="flex gap-1 w-full">
                        <label htmlFor="" className="w-full basis-[30%] align-middle flex items-center text-sm text-start font-semibold">
                          FATHER NAME
                        </label>
                        <input type="text" value={staffProfile[0]?.FatherName} className="max-w-60 w-full textInput " readOnly />
                      </div>

                      <div className="flex gap-1 w-full">
                        <label htmlFor="" className="w-full basis-[30%] align-middle flex items-center text-sm text-start font-semibold">
                          MOTHER NAME
                        </label>
                        <input type="text" value={staffProfile[0]?.MOTHERNAME} className="max-w-60 w-full textInput " readOnly />
                      </div>
                    </div>
                    <div className="w-full flex flex-col gap-1 md:basis-[50%] justify-start">
                      <div className="flex bg-transparent justify-center w-fit items-center m-auto">
                        {/* img */}
                        <img src={stdProfile} className="size-40" alt="profile" />
                      </div>
                    </div>
                    <div className="w-fit flex flex-col gap-1 justify-start">
                      <div className="flex bg-transparent justify-center w-fit items-end h-full m-auto">
                        <button onClick={() => setShowListModal(true)} className="buttonClass !w-fit">
                          <RiMenu3Fill />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <Accordion collapseAll className="w-full">
                <Accordion.Panel className="w-full">
                  <Accordion.Title className="accordian-title !text-white mb-2 focus:outline-none uppercase border-0 focus:ring-0">
                    Personal Info
                  </Accordion.Title>
                  <Accordion.Content className="bg-transparent -mt-2 !p-0 transition-all duration-300 ease-custom-ease rounded-lg shadow-lg">
                    <div className="w-full formBackground flex flex-col gap-2 p-2 md:px-5">
                      <div className="w-full flex md:flex-row flex-col gap-1">
                        <div className="w-full sm:grid md:grid-cols-3 sm:grid-cols-2 flex flex-col gap-1 justify-start">
                          {studentInfoDetails1?.map((data, index) => {
                            const key = Object.keys(data)[0];
                            const value = Object.values(data)[0];
                            return (
                              <div className="flex gap-1 w-full">
                                <label
                                  htmlFor=""
                                  className="w-full basis-[30%] align-middle flex items-center text-sm text-start font-semibold"
                                >
                                  {key}
                                </label>
                                <input type="text" value={value} className="max-w-60 w-full textInput " readOnly />
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </Accordion.Content>
                </Accordion.Panel>
                <Accordion.Panel onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} className="w-full">
                  <Accordion.Title className="accordian-title !text-white mb-2 focus:outline-none uppercase border-0 focus:ring-0">
                    Attendance Info
                  </Accordion.Title>
                  <Accordion.Content
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    className="bg-transparent -mt-2 !p-0 transition-all duration-300 ease-custom-ease rounded-lg shadow-lg"
                  >
                    <div className="w-full formBackground p-2 md:px-5">
                      <div className="flex w-full md:flex-row flex-col gap-2">
                        <div className="w-full">
                          {/* calender */}
                          <div className="flex justify-center h-[400px] overflow-auto md:w-[70%] w-full">
                            <FullCalendar
                              plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin]}
                              headerToolbar={{
                                left: "prev,next today",
                                right: "title",
                              }}
                              ref={calendarRef}
                              viewClassNames="text-base"
                              height={600}
                              dayCellClassNames="hover:scale-110 hover:font-semibold mx-auto w-full bg-white"
                              initialView="dayGridMonth"
                              dateClick={handleDateClick}
                              nowIndicator={true}
                              editable={true}
                              events={eventData}
                              selectable={true}
                              selectMirror={true}
                              datesSet={handleMonthChange}
                            />
                          </div>
                        </div>
                        <div className="flex justify-center w-full my-5 px-3 h-fit">
                          <table className="w-full mx-auto text-sm text-left rtl:text-right text-gray-900 ">
                            <thead>
                              <tr className="bg-white tableHead border-b">
                                <th scope="row" className="p-3 border border-solid border-black/10 font-medium "></th>
                                <th scope="row" className="p-3 border border-solid border-black/10 font-medium ">
                                  Payroll
                                </th>
                                <th scope="row" className="p-3 border border-solid border-black/10 font-medium ">
                                  Absent
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {paymentData?.length > 0 &&
                                paymentData?.map((items, ind) => {
                                  return (
                                    <>
                                      <tr key={ind} className="bg-white border-b">
                                        <td className="p-3 border border-solid border-black/10">{items?.Head1}</td>
                                        <td className="p-3 border border-solid border-black/10">{items?.TotNos}</td>
                                        <td className="p-3 border border-solid border-black/10"></td>
                                      </tr>
                                    </>
                                  );
                                })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </Accordion.Content>
                </Accordion.Panel>
                <Accordion.Panel className="w-full">
                  <Accordion.Title className="accordian-title !text-white mb-2 focus:outline-none uppercase border-0 focus:ring-0">
                    Payment Details
                  </Accordion.Title>
                  <Accordion.Content className="bg-transparent -mt-2 !p-0 transition-all duration-300 ease-custom-ease rounded-lg shadow-lg">
                    <div className="w-full formBackground p-2 md:px-5">
                      <div className="relative shadow-xl overflow-x-auto">
                        <table className="w-full mx-auto text-sm text-left rtl:text-right text-gray-900 ">
                          <thead>
                            <tr className="bg-white tableHead border-b">
                              <th scope="row" className="p-3 border border-solid border-black/10 font-medium ">
                                Month
                              </th>
                              <th scope="row" className="p-3 border border-solid border-black/10 font-medium ">
                                Year
                              </th>
                              <th scope="row" className="p-3 border border-solid border-black/10 font-medium ">
                                Paid Days
                              </th>
                              <th scope="row" className="p-3 border border-solid border-black/10 font-medium ">
                                Gross Sal
                              </th>
                              <th scope="row" className="p-3 border border-solid border-black/10 font-medium ">
                                Net Paid
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {paymentDetails?.length > 0 &&
                              paymentDetails?.map((items, ind) => {
                                return (
                                  <>
                                    <tr key={ind} className="bg-white border-b">
                                      <td className="p-3 border border-solid border-black/10">
                                        {items?.PayMntid}-{items?.PayMonth}
                                      </td>
                                      <td className="p-3 border border-solid border-black/10">{items?.PayYear}</td>
                                      <td className="p-3 border border-solid border-black/10">{items?.DaysWkd}</td>
                                      <td className="p-3 border border-solid border-black/10">{items?.FULLGROSSSAL}</td>
                                      <td className="p-3 border border-solid border-black/10">{items?.NetSalary}</td>
                                    </tr>
                                  </>
                                );
                              })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </Accordion.Content>
                </Accordion.Panel>
              </Accordion>

              {/* <div className="container w-full mx-auto">
                <button className="buttonClass w-full">print</button>
              </div> */}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default QuickInfoStaffProfile;
