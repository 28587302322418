import React from "react";
import axios from "axios";
import toast from "react-toastify";
import { getUserDataFromLocalByKey } from "./getStudentInfoByKeyLocal";

export const UpdateReplyToParentAPI = async (userId,schoolId,userType,year,MessageToParent,HWID,audioValue,fileName) => {
  try {
    
   
    
    const key = "webServiceURLAWSDOTNET";

  

  
  

    const returnUrl = await getUserDataFromLocalByKey(userId,schoolId,userType,year,key);


   

    const formData = new FormData();
    formData.append("title", "UpdateTeachersReply");
    formData.append("description", '');
    formData.append("ReqHWID",HWID )
    formData.append("ReqPMessage", MessageToParent);
    formData.append("ReqAddedBy",userId );
    formData.append("photoLocation",fileName );
    formData.append("imagestring", audioValue);


    let userdata = [];

    const Corskey = process.env.REACT_APP_CORS_URL;

    const result = await axios.post(`${Corskey}${returnUrl}`, formData);

    console.log("RESULT RESPONSE",result)
    userdata = JSON.parse(
      result.data.substring(0, result.data.indexOf("||JasonEnd", 0))
    );


    // console.log("RESULT RESPONSE",result)
    console.log("RESULT RESPONSE DATA",userdata)

    return userdata;

    
  } catch (error) {
    console.error("An error occurred:", error);
    // Handle error here
  }
  // Placeholder JSX, replace with actual JSX
};
