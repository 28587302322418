import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import { GlobalProvider } from "./context/GlobalContext";
import CreateNotice from "./pages/Admin/CreateNotice";
import CustomAdminPage from "./pages/Admin/CustomAdminPage";
import ClassList from "./pages/ClassList";
import CTAddGallery from "./pages/ClassTeacher/CTAddGallery";
import CTAddHomeWork from "./pages/ClassTeacher/CTAddHomeWork";
import CTGallerySubmitPage from "./pages/ClassTeacher/CTGallerySubmitPage";
import CTHomeWorkSubmitPage from "./pages/ClassTeacher/CTHomeWorkSubmitPage";
import CTNotificationSubmitPage from "./pages/ClassTeacher/CTNotificationSubmitPage";
import CustomClassTeacherPage from "./pages/ClassTeacher/CustomClassTeacherPage";
import Home from "./pages/Home";
import NewRegistration from "./pages/NewRegistration";
import NotFoundPage from "./pages/NotFoundPage";
import Registration from "./pages/PreLoginPage";
import SectionList from "./pages/SectionList";
import CustomPage from "./pages/Student/CustomPage";
import MentorPage from "./pages/Student/MentorPage";
import StudentList from "./pages/StudentList";
import CustomSTeacherPage from "./pages/SubjectTeacher/CustomSTeacherPage";
import Verification from "./pages/Verification";
// import AskMyMentorCreate from "./pages/Student/AskMyMentorCreate";
import AdminCDCommList from "./pages/Admin/AdminCDCommList";
import AdminCDGallery from "./pages/Admin/AdminCDGallery";
import AdminCDHomeWork from "./pages/Admin/AdminCDHomeWork";
import AdminCDNotification from "./pages/Admin/AdminCDNotification";
import AdminCDStuDetails from "./pages/Admin/AdminCDStuDetails";
import AdminCDTeacherList from "./pages/Admin/AdminCDTeacherList";
import AdminCDTimeTable from "./pages/Admin/AdminCDTimeTable";
import AdminClassDetailsDashboard from "./pages/Admin/AdminClassDetailsDashboard";
import AdminClassDetailsSectionList from "./pages/Admin/AdminClassDetailsSectionList";
import AdminCommListPage from "./pages/Admin/AdminCommListPage";
import AdminGalleryListPage from "./pages/Admin/AdminGalleryListPage";
import AdminHomeWorkListPage from "./pages/Admin/AdminHomeWorkListPage";
import AdminNotificationListPage from "./pages/Admin/AdminNotificationListPage";
import AdminStuSearchList from "./pages/Admin/AdminStuSearchList";
import ClassSectionPage from "./pages/Admin/ClassSectionPage";
import CSTCReport from "./pages/Admin/CSTCReport";
import CTCReport from "./pages/Admin/CTCReport";
import CWSReport from "./pages/Admin/CWSReport";
import GWCReport from "./pages/Admin/GWCReport";
import GWSReport from "./pages/Admin/GWSReport";
import StudentDetailsList from "./pages/Admin/StudentDetailsList";
import SWSReport from "./pages/Admin/SWSReport";
import CTComBWTeachersList from "./pages/ClassTeacher/CTComBWTeachersList";
import CTCPollSubmit from "./pages/ClassTeacher/CTCPollSubmit";
import CTNoticeBoardSubmitPage from "./pages/ClassTeacher/CTNoticeBoardSubmitPage";
import CTReplyToParentSubmit from "./pages/ClassTeacher/CTReplyToParentSubmit";
import CTStudentProfileDetails from "./pages/ClassTeacher/CTStudentProfileDetails";
import CTupdateComBWTeachersPage from "./pages/ClassTeacher/CTupdateComBWTeachersPage";
import AskMyMentor from "./pages/Student/AskMyMentor";
import StuFeeDatewiseReceipts from "./pages/Student/StuFeeDatewiseReceipts";
import StuFeeDemandCollection from "./pages/Student/StuFeeDemandCollection";
import StuFeeSummHeadwise from "./pages/Student/StuFeeSummHeadwise";
// import { requestForToken } from "./firebase/firebase";
import FCMTestForm from "./components/FCMTestForm";
import { SidebarProvider } from "./context/SidebarContext";
import AdminClassNoticeList from "./pages/Admin/AdminClassNoticeList";
import AdminClassNoticeSubmit from "./pages/Admin/AdminClassNoticeSubmit";
import AdminPollSubmit from "./pages/Admin/AdminPollSubmit";
import AdminScrollNoticeSubmit from "./pages/Admin/AdminScrollNoticeSubmit";
import FeeDateSearch from "./pages/Admin/FeeDateSearch";
import FeeDateSearchList from "./pages/Admin/FeeDateSearchList";
import FeeDCS from "./pages/Admin/FeeDCS";
import FeeDetailsstudentWise from "./pages/Admin/FeeDetailsStudentWise";
import FeeHeadWiseSearch from "./pages/Admin/FeeHeadWiseSearch";
import FeeHeadWiseSearchList from "./pages/Admin/FeeHeadWiseSearchList";
import FeeMonthWiseSearch from "./pages/Admin/FeeMonthWiseSearch";
import FeeMonthWiseSearchList from "./pages/Admin/FeeMonthWiseSearchList";
import FeeMOPSearch from "./pages/Admin/FeeMOPSearch";
import FeeMOPSearchList from "./pages/Admin/FeeMOPSearchList";
import FeeStudentWiseDList from "./pages/Admin/FeeStudentWiseDList";
import QuickInfoCustomPage from "./pages/Admin/QuickInfoCustomPage";
import StudentDetailsView from "./pages/Admin/StudentDetailsView";
import HomeWorkList from "./pages/ClassTeacher/HomeWorkList";
import MarkEntryPage from "./pages/ClassTeacher/MarkEntryPage";
import MarkEntrySubjectList from "./pages/ClassTeacher/MarkEntrySubjectList";
import TaskSubmit from "./pages/Student/TaskSubmit";
import AdminNoticeListPage from "./pages/Admin/AdminNoticeListPage";

function App() {
  // requestForToken();
  return (
    <GlobalProvider>
      <SidebarProvider>
        <>
          <Router>
            <Routes>
              <Route path="/" element={<Registration />} />
              <Route path="/register" element={<NewRegistration />} />
              <Route path="/testFCM" element={<FCMTestForm />} />
              <Route path="/dashboard/u/:customId" element={<Home />} />
              <Route path="/class/u/:customId" element={<ClassList />} />
              <Route path="/class/u/:customId/:className" element={<SectionList />} />
              <Route path="/admin/u/:keyName/:customId" element={<CustomAdminPage />} />
              <Route path="/verify" element={<Verification />} />
              <Route path="admin/u/create-notice/:customId" element={<CreateNotice />} />
              <Route path="admin/u/:customId/CWSReport" element={<CWSReport />} />
              <Route path="admin/u/:customId/SWSReport" element={<SWSReport />} />
              <Route path="admin/u/:customId/CTCReport" element={<CTCReport />} />
              <Route path="admin/u/:customId/CSTCReport" element={<CSTCReport />} />
              <Route path="admin/u/:customId/GWSReport" element={<GWSReport />} />
              <Route path="admin/u/:customId/GWCReport" element={<GWCReport />} />
              <Route path="admin/u/:customId/:className/ClassSectionPage" element={<ClassSectionPage />} />
              <Route path="admin/u/:customId/:className/:sec/StudentDetailsList" element={<StudentDetailsList />} />
              <Route path="admin/u/:customId/:ADMNO/StudentDetailsView" element={<StudentDetailsView />} />
              <Route path="admin/u/:customId/AdminScrollNoticelSubmit" element={<AdminScrollNoticeSubmit />} />
              <Route path="admin/u/:customId/AdminPollSubmit" element={<AdminPollSubmit />} />
              <Route path="admin/u/:customId/:NID?/scrollNoticeUpdate" element={<AdminScrollNoticeSubmit />} />
              <Route
                path="admin/u/:customId/:Rcdid?/:SubjTo?/:Title?/:Question?/:NofOpt?/:Opt1?/:Opt2?/:Opt3?/:Opt4?/:Locked?/:Over?/:Dropped?/pollUpdateForm"
                element={<AdminPollSubmit />}
              />
              <Route
                path="admin/u/:customId/:FromDate?/:ToDate?/:Message?/:Class?/:Section?/NotificationList"
                element={<AdminNotificationListPage />}
              />
              <Route
                path="admin/u/:customId/:FromDate?/:ToDate?/:Message?/:Class?/:Section?/NoticeList"
                element={<AdminNoticeListPage />}
              />
              <Route
                path="admin/u/:customId/:FromDate?/:ToDate?/:Message?/:Class?/:Section?/HomeWorkListPage"
                element={<AdminHomeWorkListPage />}
              />
              <Route
                path="admin/u/:customId/:FromDate?/:ToDate?/:Message?/:Class?/:Section?/GalleryList"
                element={<AdminGalleryListPage />}
              />
              <Route
                path="admin/u/:customId/:FromDate?/:ToDate?/:Message?/:Class?/:Section?/CommSearchPage"
                element={<AdminCommListPage />}
              />
              <Route path="admin/u/:customId/:className/ClassDetailsSectionList" element={<AdminClassDetailsSectionList />} />
              <Route path="admin/u/:customId/:className/AdminClassNoticeList" element={<AdminClassNoticeList />} />
              <Route path="admin/u/:customId/:className/NoticeSubmit" element={<AdminClassNoticeSubmit />} />
              <Route path="admin/u/:customId/:className/:sec/ClassTeacherDashBoard" element={<AdminClassDetailsDashboard />} />
              <Route path="admin/u/:keyName/:customId/:className/:sec/AdmCSStuDetails" element={<AdminCDStuDetails />} />
              <Route path="admin/u/:keyName/:customId/:className/:sec/CDTeachersList" element={<AdminCDTeacherList />} />
              <Route path="admin/u/:keyName/:customId/:className/:sec/AdmCDTimeTable" element={<AdminCDTimeTable />} />
              <Route path="admin/u/:keyName/:customId/:className/:sec/AdmCDNotification" element={<AdminCDNotification />} />
              <Route path="admin/u/:keyName/:customId/:className/:sec/AdmCDHomeWork" element={<AdminCDHomeWork />} />
              <Route path="admin/u/:keyName/:customId/:className/:sec/AdmCDGallery" element={<AdminCDGallery />} />
              <Route path="admin/u/:keyName/:customId/:className/:sec/AdmCDComm" element={<AdminCDCommList />} />
              <Route path="admin/u/:customId/:StuAdmNo?/:StuName?/:Class?/:Section?/StudentList" element={<AdminStuSearchList />} />
              <Route path="admin/u/:keyName/:customId/FeeDCS" element={<FeeDCS />} />
              <Route path="admin/u/:keyName/:customId/FeeStuWiseDL" element={<FeeDetailsstudentWise />} />
              <Route path="admin/u/:keyName/:customId/FeeDuesDate" element={<FeeDateSearch />} />
              <Route path="admin/u/:keyName/:customId/FeeHeadWise" element={<FeeHeadWiseSearch />} />
              <Route path="admin/u/:keyName/:customId/FeeMonthWise" element={<FeeMonthWiseSearch />} />
              <Route path="admin/u/:keyName/:customId/FeeDateWiseMOP" element={<FeeMOPSearch />} />
              <Route
                path="admin/u/:customId/:FromDate?/:ToDate?/:Rcptcat?/:Clscat?/:Class?/:Section?/:Admno?/Studentwise"
                element={<FeeStudentWiseDList />}
              />
              <Route
                path="admin/u/:customId/:FromDate?/:ToDate?/:Rcptcat?/:Clscat?/:Class?/:Section?/:Admno?/DateWiseSearchList"
                element={<FeeDateSearchList />}
              />
              <Route
                path="admin/u/:customId/:FromDate?/:ToDate?/:Rcptcat?/:Clscat?/:Class?/:Section?/:Admno?/HeadWiseSearchList"
                element={<FeeHeadWiseSearchList />}
              />
              <Route
                path="admin/u/:customId/:FromDate?/:ToDate?/:Rcptcat?/:Clscat?/:Class?/:Section?/:Admno?/MonthWiseSearchList"
                element={<FeeMonthWiseSearchList />}
              />
              <Route
                path="admin/u/:customId/:FromDate?/:ToDate?/:Rcptcat?/:Clscat?/:Class?/:Section?/:Admno?/:MOP?/MOPSearchList"
                element={<FeeMOPSearchList />}
              />
              {/* <Route path="/create-notice/u/:customId" element={<CreateNotice />} /> */}
              <Route path="/class/u/:customId/:className/:section" element={<StudentList />} />
              <Route path="/student/u/:keyName/:customId" element={<CustomPage />} />
              <Route path="/student/u/:keyName/:customId/demandCollection" element={<StuFeeDemandCollection />} />
              <Route path="/student/u/:keyName/:customId/datewiseReceipts" element={<StuFeeDatewiseReceipts />} />
              <Route path="/student/u/:keyName/:customId/summHeadWise" element={<StuFeeSummHeadwise />} />

              <Route path="/student/u/:customId/:HWID/:HWSubject" element={<TaskSubmit />} />
              <Route path="/classteacher/u/:customId/:HWID/:HWSubject" element={<HomeWorkList />} />

              {/* ////////////////////////////////////// */}
              <Route path="/subjectTeacher/u/:keyName/:customId" element={<CustomSTeacherPage />} />
              <Route path="/student/u/mentor/:customId/:EmpName/:TClass" element={<MentorPage />} />
              {/* <Route path="/classteacher/u/:keyName/:customId" element={<CustomClassTeacherPage />} />  */}
              <Route path="/classteacher/u/:customId/:EmpName/:SUBJ/:TClass/:TSection" element={<CTAddHomeWork />} />
              <Route path="/classteacher/u/:customId/:SUBJ/:TClass/:TSection/CTHomeWorkSubmitPage" element={<CTHomeWorkSubmitPage />} />
              <Route path="/classteacher/u/:keyName/:customId/CTNotificationSubmitPage" element={<CTNotificationSubmitPage />} />
              <Route path="/classteacher/u/:customId/:SUBJ/:TClass/:TSection" element={<CTAddGallery />} />
              <Route path="/classteacher/u/:customId/:SUBJ/:TClass/:TSection/CTGallerySubmitPage" element={<CTGallerySubmitPage />} />
              <Route
                path="/classteacher/u/:customId/:HWSname?/:HWClass?/:HWID?/:HWRemarks?/:TeachReply?/:SoundClipStudent?/:SoundClipTeacher?/CTReplyToParentSubmit"
                element={<CTReplyToParentSubmit />}
              />
              <Route path="/classteacher/u/:customId/CTNoticeBoardSubmitPage" element={<CTNoticeBoardSubmitPage />} />
              <Route path="/classteacher/u/:customId/CTComBWTeachersList" element={<CTComBWTeachersList />} />
              <Route
                path="/classteacher/u/:customId/:ToName/:ToEmpCode/:EmpType/CTupdateComBWTeachersPage"
                element={<CTupdateComBWTeachersPage />}
              />
              <Route path="/classteacher/u/:customId/:ExamName/:ExamTrmID/ClassTeacherSubjectList" element={<MarkEntrySubjectList />} />

              <Route
                path="/classteacher/u/:customId/:subject/:SvrExmid/:Class/:section/ClassTeacherMarkEntry"
                element={<MarkEntryPage />}
              />
              <Route path="/classteacher/u/:customId/:ADMNO/CTStudentProfileDetails" element={<CTStudentProfileDetails />} />
              <Route path="/classteacher/u/:customId/CTCPollSubmit" element={<CTCPollSubmit />} />
              <Route
                path="classteacher/u/:customId/:Rcdid?/:SubjTo?/:Title?/:Question?/:NofOpt?/:Opt1?/:Opt2?/:Opt3?/:Opt4?/:Locked?/:Over?/:Dropped?/pollUpdateForm"
                element={<CTCPollSubmit />}
              />
              <Route path="/student/u/mentor/:customId/:EmpName/:TClass" element={<MentorPage />} />
              <Route
                path="/student/u/:customId/:HWSname?/:HWClass?/:HWDateStr?/:HWRemarks?/:TchReply?/:HWSection?/:HWSubject?/:SoundClipStudent?/:SoundClipTeacher?/MentorPage"
                element={<MentorPage />}
              />
              <Route path="/student/u/mentor/:customId/mentorslist" element={<AskMyMentor />} />
              <Route path="/student/u/mentor/:customId/:EmpName/:Subject/:TClass" element={<MentorPage />} />
              <Route path="/classteacher/u/:keyName/:customId" element={<CustomClassTeacherPage />} />

              <Route path="admin/u/:customId/QuickInfo/:keyName" element={<QuickInfoCustomPage />} />

              {/* <Route path="/student/u/mentor/:customId/:EmpName/:TClass" element={<MentorPage />} /> */}

              <Route path="/*" element={<NotFoundPage />} />
            </Routes>
          </Router>

          <ToastContainer />
        </>
      </SidebarProvider>
    </GlobalProvider>
  );
}

export default App;
