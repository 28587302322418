import axios from "axios";
import { getUserDataFromLocalByKey } from "./getStudentInfoByKeyLocal";

export const getAdminClassNoticeList = async (userId, schoolId, userType, year, className) => {
  try {
    const key = "webServiceURLAWSDOTNET";

    const returnUrl = await getUserDataFromLocalByKey(userId, schoolId, userType, year, key);
    const formData = new FormData();

    formData.append("title", "NoticeBoardClassWise");
    formData.append("description", "");
    formData.append("ReqClass", className);
    formData.append("ReqSection", "");
    formData.append("ReqYear", year);
    formData.append("ReqPara1", "");
    formData.append("ReqAdmNo", "");

    let userdata = [];
    const Corskey = process.env.REACT_APP_CORS_URL;
    const result = await axios.post(`${Corskey}${returnUrl}`, formData);
    userdata = JSON.parse(result.data.substring(0, result.data.indexOf("||JasonEnd", 0)));
    return userdata;
  } catch (error) {
    console.error("An error occurred:", error);
  }
};
