import "datatables.net-buttons-dt/css/buttons.dataTables.css";
import "datatables.net-buttons-dt/css/buttons.dataTables.min.css";
import "datatables.net-buttons-dt/js/buttons.dataTables.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import "datatables.net-buttons/js/dataTables.buttons.js";
import DataTable from "datatables.net-dt";
import "datatables.net-dt/css/dataTables.dataTables.css";
import "datatables.net-responsive-dt";
import "datatables.net-scroller";
import "datatables.net-scroller-dt/css/scroller.dataTables.css";
import "datatables.net-scroller-dt/css/scroller.dataTables.min.css";
import "datatables.net-scroller-dt/js/scroller.dataTables.js";
import "datatables.net-scroller/js/dataTables.scroller.js";
import "datatables.net-select/js/dataTables.select.js";

import JSZip from "jszip";
import PDFMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

import React, { useEffect, useRef } from "react";
import ModalFunctionComponent from "./ModalFunctionComponent";


window.JSZip = JSZip;
PDFMake.vfs = pdfFonts.pdfMake?.vfs;

const PrintTableModal = ({ close, open, tableRow, tableData, title }) => {
  let tableRef = useRef(null);
  let tableInstance = useRef(null);

  useEffect(() => {
    // Initialize DataTable when the component mounts
    if (tableData && tableRow) {
      tableInstance.current = new DataTable("#studentDetails", {
        responsive: false,
        // dom: "Bfrtip",
        layout: {
          topStart: {
            buttons: [
              "excel",
              "csv",
              "xls",
              "pdf",
              {
                extend: "print",
                exportOptions: {
                  columns: ":visible",
                },
              },
              "colvis",
            ],
          },
        },
        select: false,
        info: "",
        scrollY: true,
        scrollX: true,
        deferRender: true,
        scroller: true,
        data: tableRow,
        columns: tableData,
      });
    }
    // Cleanup DataTable instance when the component unmounts
    return () => {
      if (tableInstance.current !== null) {
        tableInstance.current.destroy();
      }
    };
  }, [tableData, tableRow]);

  return (
    <ModalFunctionComponent close={close} title={title}>
    <div className="relative max-h-full overflow-x-auto border min-w-[80%] border-black border-solid rounded-sm p-1 bg-white">
        {tableRow && tableRow?.length > 0 ? (
          <table
            ref={tableRef}
            id="studentDetails"
            style={{ width: "100%" }}
            className="h-full w-fit max-h-[500px] responsive extraCSS display nowrap"
          ></table>
        ) : (
          <div className="h-auto p-5">
            <h1 className="text-2xl font-bold">Record Not Found</h1>
          </div>
        )}
      </div>
    </ModalFunctionComponent>
  );
};

export default PrintTableModal;
