import React from "react";
import axios from "axios";
import toast from "react-toastify";
import { getUserDataFromLocalByKey } from "./getStudentInfoByKeyLocal";


export const getStuAbsentList  = async (userId,schoolId, userType, year) => {
 

  try {

    const key='webServiceURL'

   const returnUrl= await getUserDataFromLocalByKey(userId,schoolId,userType, year,key)

   
   




  

   const formData = new FormData();
   formData.append("title","GetStudentWiseAbsentList");
   formData.append("description", "Class Section Wise Student List");
   formData.append("ReqAdmno", userId);
   formData.append("ReqAcastart", year);
   
   

    let userdata = [];

    const Corskey=process.env.REACT_APP_CORS_URL;

    const result = await axios.post(
      `${Corskey}${returnUrl}`,
      formData
    );

    userdata = JSON.parse(
      result.data.substring(0, result.data.indexOf("||JasonEnd", 0))
    );

    return userdata;

  
  } catch (error) {
    console.error("An error occurred:", error);
    // Handle error here
  }
  // Placeholder JSX, replace with actual JSX
};
