import React, { useState, useEffect } from "react";
import plusicon from "../../assets/png/plusicon.png";
import iconP from "../../assets/png/iconP.png";
import { BsClockFill } from "react-icons/bs";
import Spinner from "../../components/Spinner";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import greenTickIcon from "../../assets/png/greenTickIcon.png";
import { getParentMessagesAPI } from "../../API/getParentsMessages";
import useSidebar from "../../hooks/useSidebar";

const AskMyMentorCreate = () => {
  const { customId } = useParams();
  const [requestList, setRequestList] = useState([]);
  const [loading, setLoading] = useState(false);

  const encodeValue = (value) => (value ? encodeURIComponent(value) : "null");

  useEffect(() => {
    const fetchData = async () => {
      const decodedData = JSON.parse(atob(customId));

      const getList = await getParentMessagesAPI(
        decodedData.userId,
        decodedData.schoolId,
        decodedData.userType,
        decodedData.year
      );

      setRequestList(getList);

      console.log(getList);
    };

    fetchData();
  }, []);

  

  return (
    <>
      <div className="flex items-center justify-between mb-4">
        <h2 className="text-3xl font-bold">Ask My Mentors</h2>
      </div>
      <div
        className="border border-gray-300 cursor-pointer rounded-lg p-4 hover:shadow-md transition duration-300"
        style={{
          boxShadow: "5px 5px 12px 0px rgba(173, 216, 230, 0.9)",
        }}
      >
        <Link to={`/student/u/mentor/${customId}/mentorslist`}>
          <div className="flex items-center">
            <div className="w-1/3 sm:w-auto mr-4">
              <img
                src={plusicon}
                alt="icon"
                className="w-20 h-20 sm:w-15 sm:h-15 object-cover rounded"
              />
            </div>

            <div className="w-2/3">
              <h3 className="text-md font-semibold mb-2">
                Click to Add New Request
              </h3>
            </div>
          </div>
        </Link>
      </div>
      <div className="container mx-auto p-4">
        {loading ? (
          <Spinner />
        ) : (
          <>
            {requestList &&
              requestList.map((item) => (
                <Link
                  to={`/student/u/${customId}/${encodeValue(
                    item.HWSname
                  )}/${encodeValue(item.HWClass)}/${encodeValue(
                    item.HWDateStr
                  )}/${encodeValue(item.HWRemarks)}/${encodeValue(
                    item.TchReply
                  )}/${encodeValue(item.HWSection)}/${encodeValue(
                    item.HWSubject
                  )}/${encodeValue(item.SoundClipStudent)}/${encodeValue(
                    item.SoundClipTeacher
                  )}/MentorPage`}
                  onClick={""}
                >
                  <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-4 mb-2">
                    <div key={item.id} className="cursor-pointer relative">
                      <div className="border border-gray-300 rounded-lg p-4 hover:shadow-md transition duration-300">
                        <p className="text-lg  mb-2">
                          Subject : {item.HWSubject}
                        </p>
                        <p className="text-lg  mb-2">
                          Meassage : {item.HWRemarks}
                        </p>
                        <p className="text-lg  mb-2">Date : {item.HWDateStr}</p>
                        <p className="text-lg  mb-2">Reply : {item.TchReply}</p>
                        <div className="absolute top-0 right-0 p-2 flex items-center">
                          {item.TchReply ? (
                            <>
                              <img
                                src={greenTickIcon}
                                alt="Thumbnail"
                                className="w-10 h-10 object-cover rounded ml-2"
                              />
                              <span className="text-gray px-2 py-1 rounded">
                                Status: Replied
                              </span>
                            </>
                          ) : (
                            <>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <span className="object-cover rounded ml-2">
                                  {<BsClockFill />}
                                </span>
                                <span className="text-gray px-2 py-1 rounded">
                                  Status: Pending
                                </span>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              ))}
          </>
        )}
      </div>
    </>
  );
};

export default AskMyMentorCreate;
