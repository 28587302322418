import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import ClassStandard from '../assets/png/icons8-cutting-board-96.png';
import Navbar from '../components/Navbar';
import Sidebar from '../components/SidebarOld';

const SectionList = () => {
  const { className } = useParams();
  const sections = ['A', 'B', 'C', 'D', 'E'];
  const [sidebarVisible, setSidebarVisible] = useState(true);

  const toggleSidebar = () => {
    setSidebarVisible(!sidebarVisible);
  };

  return (
    <>
      <Navbar isOpen={sidebarVisible} toggleSidebar={toggleSidebar} />

      <div className="flex">
        {sidebarVisible && <Sidebar />}

        <div className="w-full absolute right-0 top-16 md:w-3/4 lg:w-4/5 xl:w-4/5 p-4 md:p-6 lg:p-8 xl:p-10">
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
            {sections.map((section, index) => (
              <Link
                key={index}
                to={`/class/${className}/${section}`}
                className="rounded-lg overflow-hidden shadow-md border border-gray-200 bg-white hover:bg-gray-100 transition duration-300 ease-in-out transform hover:scale-105"
              >
                <div className="p-4">
                  <img
                    src={ClassStandard}
                    alt="Demo Avatar"
                    className="mx-auto h-16 w-16 rounded-full mb-4"
                  />
                  <span className="block text-center text-lg font-semibold">{`Class: ${className} - Section: ${section}`}</span>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default SectionList;
