import axios from "axios";

let UserName;
let UserCode;
const user = localStorage.getItem("UserRights");
const parts = user?.split("_");
if (parts?.length > 0) {
  UserName = parts?.[0] ?? "";
  UserCode = parts?.[2] ?? "";
}

export const GetERPClassStrength = async (year) => {
  try {
    const returnUrl = process.env.REACT_APP_ERP_QUICKINFO_PAGE;
    const formData = new FormData();

    formData.append("title", "GetERPClassStrength");
    formData.append("description", "Get ERP Class strength");
    formData.append("ReqAcaStart", year);
    formData.append("Reqdisptype", 0);
    formData.append("ReqUserAccessRight", "");
    formData.append("ReqMode", 1);
    formData.append("ReqUserName", UserName);
    formData.append("ReqUserCode", UserCode);

    let userdata = [];
    const result = await axios.post(returnUrl, formData);
    userdata = JSON.parse(result.data.substring(0, result.data.indexOf("||JasonEnd", 0)));
    return userdata;
  } catch (error) {
    console.error("An error occurred:", error);
  }
};
