import React from "react";
import axios from "axios";
import toast from "react-toastify";
import { getUserDataFromLocalByKey } from "./getStudentInfoByKeyLocal";

export const updateCTGalleryAPI = async (userId, schoolId, userType, year, filedata, subject, className, ClassSection, remarks) => {
  try {
    const key = "webServiceURLAWSDOTNET";

    //console.log(filedata);

    const returnUrl = await getUserDataFromLocalByKey( userId, schoolId, userType, year, key);

    const SMSType = process.env.REACT_APP_SMS_TYPE;

   

    const formData = new FormData();
    formData.append("title", "UpdateStudyMaterials");
    formData.append("description", "");
    formData.append("HWID", "");
    formData.append("HWClass", className);
    formData.append("HWSection", ClassSection);
    formData.append("HWSubject", subject);
    formData.append("HWRemarks", remarks);
    formData.append("ReqAcastart", year);
    formData.append("AddedBy", userId);
    formData.append("ReqSMSType", SMSType);

    filedata.forEach((fileInfo, index) => {
      const fileType = fileInfo.fileType;

      formData.append(`PhotoLocation`, fileInfo.newFileName);
      formData.append(`imagestring`, fileInfo.base64String);

      if (fileType === "image") {

        formData.append("MediaType", fileType);

      } else if (fileType === "PDF") {

        formData.append("MediaType", fileType);

      } else {

        formData.append("MediaType", fileType);

      } 
    });

    let userdata = [];

    const Corskey = process.env.REACT_APP_CORS_URL;

    const result = await axios.post(`${Corskey}${returnUrl}`, formData);

    userdata = JSON.parse(
      result.data.substring(0, result.data.indexOf("||JasonEnd", 0))
    );

    return userdata;
  } catch (error) {
    console.error("An error occurred:", error);
    // Handle error here
  }
  // Placeholder JSX, replace with actual JSX
};
