
export const getStudentInfoByKeyLocal = async (userId,schoolId,userType, year,keyval) => {

 

    const registeredUsersList = JSON.parse(localStorage.getItem("registeredUsers")) || [];

    let matchedItems = [];
 
    registeredUsersList.forEach(innerArray => {
      const [userData, innerData] = innerArray;

      if (userData.userId === userId && userData.year === year && userData.schoolId === schoolId && userData.userType === userType) {
        innerData.forEach(innerItem => {   
            matchedItems.push(innerItem);
          
        });
      }
    });

   

    const filteredData = matchedItems.filter(obj => obj.ItemKeyName === keyval);


    return filteredData.length > 0 ? filteredData[0] : null;


};
