import React, { useState, useEffect } from "react";
import Navbar from "../../components/Navbar";
import Sidebar from '../../components/Sidebar';
import { useSidebar } from "../../context/SidebarContext";

import { useParams } from "react-router-dom";
import { Link, useNavigate } from "react-router-dom";
import Spinner from "../../components/Spinner";
import students from "../../assets/Dashboard/students.jpg";
import Teacher from "../../assets/Dashboard/Teacher.jpg";
import Calendar from "../../assets/Dashboard/calendar2.svg";
import Notification from "../../assets/Dashboard/notification.svg";
import CheckList from "../../assets/Dashboard/checklist.svg";
import Gallery from "../../assets/Dashboard/gallery.svg";
import OnlineSession from "../../assets/Dashboard/online-lession.svg";
import { getUserDataFromLocalByKey } from "../../API/getStudentInfoByKeyLocal";
import { getAdminStaffDetailsList } from "../../API/getStaffDetailsListAPI";

function AdminClassDetailsDashboard() {
  const [userType, setUserType] = useState("");
  const [userDetails, setUserDetails] = useState(null);
  const [data, setData] = useState(true);
  const { customId, className, sec } = useParams();
  const [staffDetailsList, setStaffDetailsList] = useState([]);
  const [sectionList, setSectionList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [initialResourceUrl, setInitialResourceUrl] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const users = JSON.parse(localStorage.getItem("registeredUsers")) || [];

      if (customId) {
        const decodedData = JSON.parse(atob(customId));
        let matchedItem = [];

        users.forEach((innerArray) => {
          const [userData] = innerArray;

          if (
            userData.userId === decodedData.userId &&
            userData.userType === decodedData.userType &&
            userData.schoolId === decodedData.schoolId &&
            userData.year === decodedData.year
          ) {
            matchedItem.push(userData);
          }
        });

        const passkey = "Class Teacher";
        const result = await getAdminStaffDetailsList(
          decodedData.userId,
          decodedData.schoolId,
          decodedData.userType,
          decodedData.year,
          passkey
        );

        const keyval = "webResourceURL";
        const dataUrl = await getUserDataFromLocalByKey(
          decodedData.userId,
          decodedData.schoolId,
          decodedData.userType,
          decodedData.year,
          keyval
        );
        setInitialResourceUrl(dataUrl);
        setStaffDetailsList(result);
        setLoading(false);
        setUserDetails(matchedItem);
      }
    };

    fetchData();
  }, [customId]);

  // console.log(staffDetailsList);
  //

  const filteredStaffList = staffDetailsList.filter(
    (item) => item.Col2 === className && item.Col3 === sec
  );

  //   console.log(filteredStaffList);

  const handleMenuItemClick = (keyName) => {
    if (keyName === "AdmCDStuDetails") {
      navigate(`/admin/u/${keyName}/${customId}/${className}/${sec}/AdmCSStuDetails`);
    } else if (keyName === "AdmCDTeacher") {
        navigate(`/admin/u/${keyName}/${customId}/${className}/${sec}/CDTeachersList`);
    } else if (keyName === "AdmCDTimeTable") {
        navigate(`/admin/u/${keyName}/${customId}/${className}/${sec}/AdmCDTimeTable`);
    } else if (keyName === "AdmCDNotification") {
        navigate(`/admin/u/${keyName}/${customId}/${className}/${sec}/AdmCDNotification`);
    }else if (keyName === "AdmCDHomeWork") {
        navigate(`/admin/u/${keyName}/${customId}/${className}/${sec}/AdmCDHomeWork`);
    }else if (keyName === "AdmCDGallery") {
        navigate(`/admin/u/${keyName}/${customId}/${className}/${sec}/AdmCDGallery`);
    }else if (keyName === "AdmCDComm") {
        navigate(`/admin/u/${keyName}/${customId}/${className}/${sec}/AdmCDComm`);
    }
    // Add more conditions for other menu items if needed
  };

  let menuItems = [
    {
      icon: students,
      keyName: "AdmCDStuDetails",
      caption: "Student Details",
      actiontype: "btn",
      redirect: "AdminCDStuDetails",
      type: "menu",
    },
    {
      icon: Teacher,
      keyName: "AdmCDTeacher",
      caption: "Teachers List",
      actiontype: "btn",
      redirect: "AdminCDTeachersList",
      type: "menu",
    },
    {
      icon: Calendar,
      keyName: "AdmCDTimeTable",
      caption: "Class Time Table",
      actiontype: "btn",
      redirect: "AdminCDClassTimeTable",
      type: "menu",
    },
    {
      icon: Notification,
      keyName: "AdmCDNotification",
      caption: "Notification",
      actiontype: "btn",
      redirect: "AdminCDNotification",
      type: "menu",
    },
    {
      icon: CheckList,
      keyName: "AdmCDHomeWork",
      caption: "Home Work",
      actiontype: "btn",
      redirect: "AdminCDHomeWork",
      type: "menu",
    },
    {
      icon: Gallery,
      keyName: "AdmCDGallery",
      caption: "Gallery",
      actiontype: "btn",
      redirect: "AdminCDGallery",
      type: "menu",
    },
    {
      icon: OnlineSession,
      keyName: "AdmCDComm",
      caption: "Communication",
      actiontype: "btn",
      redirect: "AdminCDCommunication",
      type: "menu",
    },
  ];

  const staffName = filteredStaffList.map((item) => item.Head2);
  const staffAdmNo = filteredStaffList.map((item) => item.Head1);

  //console.log(staffAdmNo);

  // const { sidebarVisible, toggleSidebar } = useSidebar();
  const { isSidebarOpen, currentSidebarTab, toggleSidebar,setIsSidebarOpen } = useSidebar();

  return (
    <>
      {data && (
        <Navbar
          data={userDetails}
          // isOpen={sidebarVisible}
          toggleSidebar={toggleSidebar}
        />
      )}

      <div className="flex">
        {data && <Sidebar data={userDetails} />}
        <div className={`w-full absolute ${isSidebarOpen?"lg:w-4/6 xl:w-9/12":"w-full"} sm:w-11/12 right-0 top-16 mx-auto   p-4 md:p-6 lg:p-8 xl:p-10`}>
          <div className="flex items-center justify-between mb-4">
            <div className="w-2/3">
              <h2 className=" md:text-2xl font-bold text-xl mb-2 md:mb-0">
                Teacher Name : {staffName}
              </h2>
              <h3 className=" md:text-2xl font-bold text-xl mb-2 md:mb-0">
                Class : {className} - {sec}
              </h3>
              <h4 className="mb-2 font-bold">DashBoard: Class Teacher </h4>
            </div>
          </div>

          <div className="gridContainer px-2 py-3 grid gap-8 md:gap-5 md:grid-cols-3 sm:grid-cols-2 md:grid-cols grid-cols-2 place-items-center">
            {menuItems &&
              menuItems.map((item) => (
                <div
                  key={item.keyName}
                  onClick={() => handleMenuItemClick(item.keyName)}
                  className="md:h-48 md:w-52 h-36 w-40 hover:bg-gray-100 transition duration-300 ease-in-out transform hover:scale-105 rounded-md flex flex-col justify-center cursor-pointer items-center border border-r-gray-300 bg-white shadow-md"
                >
                  <img src={item.icon} className="h-20 w-20" alt="" />

                  <div>
                    <h3 className="font-semibold text-md md:text-xl">
                      {item.caption}
                    </h3>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default AdminClassDetailsDashboard;
