import axios from "axios";

export const sentTeacherOtp = async (userId, year, preRegisterUrl) => {
  try {
    const formData = new FormData();

    formData.append("title", "SendVerificationTeacher");
    formData.append("description", "");
    formData.append("ReqAcastart", year);
    formData.append("ReqUserID", userId);
    formData.append("ReqSMSType", "Testing");

    let userdata = [];

    const Corskey = process.env.REACT_APP_CORS_URL;
    const result = await axios.post(`${Corskey}${preRegisterUrl}`, formData);

    console.log("This is the result", result);

    userdata = JSON.parse(result.data.substring(0, result.data.indexOf("||JasonEnd", 0)));
    return userdata;
  } catch (error) {
    console.error("An error occurred:", error);
  }
};
