import axios from "axios";
import { getUserDataFromLocalByKey } from "./getStudentInfoByKeyLocal";

export const createNoticeDataAPI = async (
  userId,
  schoolId,
  userType,
  year,
  newFileName,
  base64String,
  contentType,
  remark,
  toUser,
  NoticeType
) => {
  try {
    const key = "webServiceURLAWSDOTNET";
    const returnUrl = await getUserDataFromLocalByKey(userId, schoolId, userType, year, key);

    if (contentType === "Youtube Link") {
      const SMSType = process.env.REACT_APP_SMS_TYPE;

      const formData = new FormData();
      formData.append("title", "UpdateNoticeBoard");
      formData.append("description", remark || "");
      formData.append("HWID", "");
      formData.append("HWClass", "");
      formData.append("HWSection", "");
      formData.append("HWSubject", toUser || "Parents");
      formData.append("MediaType", contentType);
      formData.append("HWRemarks", remark || "");
      formData.append("ReqAcastart", year);
      formData.append("AddedBy", userId);
      formData.append("PhotoLocation", newFileName ? `https://www.youtube.com/watch?${newFileName}` : "");
      formData.append("imagestring", base64String);
      formData.append("ReqSMSType", SMSType);
      formData.append("ReqNoticeType", NoticeType);

      let userdata = [];
      const Corskey = process.env.REACT_APP_CORS_URL;
      const result = await axios.post(`${Corskey}${returnUrl}`, formData);
      userdata = JSON.parse(result.data.substring(0, result.data.indexOf("||JasonEnd", 0)));
      return userdata;
    } else {
      const SMSType = process.env.REACT_APP_SMS_TYPE;

      const formData = new FormData();
      formData.append("title", "UpdateNoticeBoard");
      formData.append("description", "");
      formData.append("HWID", "");
      formData.append("HWClass", "");
      formData.append("HWSection", "");
      formData.append("HWSubject", toUser || "Parents");
      formData.append("MediaType", contentType);
      formData.append("HWRemarks", remark || "");
      formData.append("ReqAcastart", year);
      formData.append("AddedBy", userId);
      formData.append("PhotoLocation", newFileName);
      formData.append("imagestring", base64String);
      formData.append("ReqSMSType", SMSType);
      formData.append("ReqNoticeType", NoticeType);

      let userdata = [];
      const Corskey = process.env.REACT_APP_CORS_URL;
      const result = await axios.post(`${Corskey}${returnUrl}`, formData);
      userdata = JSON.parse(result.data.substring(0, result.data.indexOf("||JasonEnd", 0)));
      return userdata;
    }
  } catch (error) {
    console.error("An error occurred:", error);
  }
};
