import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import Spinner from "../../components/Spinner";
import { getUserDataFromLocalByKey } from "../../API/getStudentInfoByKeyLocal";
import { getClassSelectionList } from "../../API/getClassSelectionListAPI";

function AdminStuSearch() {
    const { customId } = useParams();
    const [userDetails, setUserDetails] = useState(null);
    const [loading, setLoading] = useState(true);
    const [initialResourceUrl, setInitialResourceUrl] = useState(null);
    const [stuAdmNo, setStuAdmNo] = useState(null);
    const [stuName, setStuName] = useState("");
    const [selectedClass, setSelectedClass] = useState(null);
    const [selectedSection, setSelectedSection] = useState(null);
    const [sectionList, setSectionList] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            const users = JSON.parse(localStorage.getItem("registeredUsers")) || [];

            if (customId) {
                const decodedData = JSON.parse(atob(customId));
                let matchedItem = [];

                users.forEach((innerArray) => {
                    const [userData] = innerArray;

                    if (
                        userData.userId === decodedData.userId &&
                        userData.userType === decodedData.userType &&
                        userData.schoolId === decodedData.schoolId &&
                        userData.year === decodedData.year
                    ) {
                        matchedItem.push(userData);
                    }
                });

                const result = await getClassSelectionList(
                    decodedData.userId,
                    decodedData.schoolId,
                    decodedData.userType,
                    decodedData.year
                );

                const keyval = "webResourceURL";
                const dataUrl = await getUserDataFromLocalByKey(
                    decodedData.userId,
                    decodedData.schoolId,
                    decodedData.userType,
                    decodedData.year,
                    keyval
                );
                setInitialResourceUrl(dataUrl);
                setLoading(false);
                setSectionList(result);
                setUserDetails(matchedItem);
            }
        };

        fetchData();
    }, [customId]);

    const head1Options = sectionList
        .slice(1)
        .map((section) => section.Head1)
        .filter(Boolean);

    const highestTotNosSection = () => {
        let highestSection = null;
        let highestTotNos = 0;

        sectionList.forEach((section) => {
            const totNos = parseInt(section.TotNos, 10);
            if (!isNaN(totNos) && totNos > highestTotNos) {
                highestTotNos = totNos;
                highestSection = section;
            }
        });

        return highestSection;
    };

    const highestTotNosSectionValue = highestTotNosSection();
    const head2Options = highestTotNosSectionValue ? highestTotNosSectionValue.Head2.split(",") : [];

    const handleSearch = () => {
        const urlBase = `/admin/u/${customId}/`;
        const queryParams = [];

        // Check and add parameters based on conditions
        queryParams.push(stuAdmNo ? stuAdmNo : "null");

        queryParams.push(stuName ? stuName : "null");
        queryParams.push(encodeURIComponent(selectedClass ? selectedClass : "null"));
        queryParams.push(encodeURIComponent(selectedSection ? selectedSection : "null"));

        // Add more conditions as needed...

        queryParams.push("StudentList");

        const url = urlBase + queryParams.join("/");

        navigate(url);
    };

    return (
        <>
           <div className="md:mt-6 mt-10">
                <div className="p-2 mb-3 flex items-center w-fit flex-wrap">
                    <ul className="flex items-center">
                        <li className="inline-flex items-center cursor-default">
                            <a href="/" className="text-black font-medium hover:scale-105">
                                <svg
                                    className="w-5 cursor-default h-auto fill-current mx-2"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    fill="#000000"
                                >
                                    <path d="M0 0h24v24H0V0z" fill="none" />
                                    <path d="M10 19v-5h4v5c0 .55.45 1 1 1h3c.55 0 1-.45 1-1v-7h1.7c.46 0 .68-.57.33-.87L12.67 3.6c-.38-.34-.96-.34-1.34 0l-8.36 7.53c-.34.3-.13.87.33.87H5v7c0 .55.45 1 1 1h3c.55 0 1-.45 1-1z" />
                                </svg>
                            </a>

                            <span className="mx-4 h-auto font-medium">/</span>
                        </li>
                        <li className="inline-flex items-center">
                            <p className="text-black font-medium cursor-default hover:scale-105">Student Search</p>
                            {/* <span className="mx-4 h-auto text-gray-400 font-medium">/</span> */}
                        </li>
                    </ul>
                </div>
                <div className="container mx-auto p-4 containerBoxShadow">
                    <div className="flex flex-col md:flex-row items-center justify-between mb-4">
                        <div className="md:px-10 px-5">
                            <h2 className=" md:text-2xl font-bold text-xl mb-2 md:mb-0">
                                Admin :{userDetails && userDetails.length > 0 && userDetails[0].schoolId}
                            </h2>
                            <h4 className="mb-2 font-bold">Student Info Search </h4>
                        </div>
                    </div>

                    <div className=" md:px-10 px-5 md:w-[70%] w-full shadow-md shadow-gray-400 rounded-md mt-28 lg:mt-12 bg-white mx-auto ">
                        <div className="w-full bg-blue-700 text-white font-semibold text-xl  text-center px-3 py-4">
                            Search In Student Info
                        </div>

                        <div className="w-full lg:flex items-center justify-center mt-3">
                            <label className="block mb-2  text-sm font-bold text-gray-700 mr-2 ml-3 lg:ml-0">
                                Admission No :
                            </label>
                            <input
                                type="text"
                                placeholder="Admission No"
                                value={stuAdmNo}
                                onChange={(e) => setStuAdmNo(e.target.value)}
                                className="border lg:w-96 w-11/12 ml-3 lg:ml-0 border-gray-300 px-3 py-2 rounded-md mb-2 focus:border-blue-500 focus:outline-none"
                            />
                        </div>

                        <div className="w-full lg:flex items-center justify-center mt-3">
                            <label className="block mb-2  text-sm font-bold text-gray-700 mr-2 ml-3 lg:ml-0">
                                Student Name :
                            </label>
                            <input
                                type="text"
                                placeholder="Student Name"
                                value={stuName}
                                onChange={(e) => setStuName(e.target.value.toUpperCase())}
                                className="border lg:w-96 w-11/12 ml-3 lg:ml-0 border-gray-300 px-3 py-2 rounded-md mb-2 focus:border-blue-500 focus:outline-none"
                            />
                        </div>

                        <div className="w-full lg:flex items-center justify-center mt-3">
                            <label className="block mb-2 text-sm font-bold text-gray-700 mr-2 ml-3 lg:ml-0">
                                Select Class:
                            </label>
                            <select
                                value={selectedClass}
                                onChange={(e) => setSelectedClass(e.target.value)}
                                className="border lg:w-96 w-fit  ml-3 lg:ml-0  border-gray-300 px-3 py-2 rounded-md mb-2 focus:border-blue-500 focus:outline-none"
                            >
                                <option value="">🧍 Select Class</option>
                                {head1Options.map((option) => (
                                    <option key={option} value={option}>
                                        🧍 {option}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div className="w-full lg:flex items-center justify-center mt-3">
                            <label className="block mb-2 text-sm font-bold text-gray-700 mr-2 ml-3 lg:ml-0">
                                Select Section:
                            </label>
                            <select
                                value={selectedSection}
                                onChange={(e) => setSelectedSection(e.target.value)}
                                className="border lg:w-96 w-fit ml-3 lg:ml-0  border-gray-300 px-3 py-2 rounded-md mb-2 focus:border-blue-500 focus:outline-none"
                            >
                                <option value="Not Selected"> 🧍Not Selected</option>
                                {head2Options.map((option, index) => (
                                    <option key={index} value={option}>
                                        🧍 {option}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div className="w-full flex items-center justify-center mt-4">
                            <button
                                className="bg-green-500 text-white font-semibold py-2 px-4 rounded focus:outline-none mb-2 focus:shadow-outline-blue"
                                onClick={handleSearch}
                            >
                                Search
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AdminStuSearch;
