import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import useSidebar from "../../hooks/useSidebar";
import Spinner from "../../components/Spinner";
import personicon from "../../assets/png/person-icon.png";
import { FaPhone } from "react-icons/fa";
import { getUserDataFromLocalByKey } from "../../API/getStudentInfoByKeyLocal";
import { getBlackListStudentList } from "../../API/getBlackListStudentsListAPI";

function BlacklistStudentList() {
  const { customId } = useParams();
  const [userDetails, setUserDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [initialResourceUrl, setInitialResourceUrl] = useState(null);
  const [studentList, setStudentList] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [resourceUrl, setResourceUrl] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const users = JSON.parse(localStorage.getItem("registeredUsers")) || [];

      if (customId) {
        const decodedData = JSON.parse(atob(customId));
        let matchedItem = [];

        users.forEach((innerArray) => {
          const [userData] = innerArray;

          if (
            userData.userId === decodedData.userId &&
            userData.userType === decodedData.userType &&
            userData.schoolId === decodedData.schoolId &&
            userData.year === decodedData.year
          ) {
            matchedItem.push(userData);
          }
        });

        const key = "WebResourceAWSDOTNETURL";

        const dataResourceUrl = await getUserDataFromLocalByKey(
          decodedData.userId,
          decodedData.schoolId,
          decodedData.userType,
          decodedData.year,
          key
        );
        setResourceUrl(dataResourceUrl);

        const result = await getBlackListStudentList(decodedData.userId, decodedData.schoolId, decodedData.userType, decodedData.year);

        const keyval = "webResourceURL";
        const dataUrl = await getUserDataFromLocalByKey(
          decodedData.userId,
          decodedData.schoolId,
          decodedData.userType,
          decodedData.year,
          keyval
        );
        setInitialResourceUrl(dataUrl);
        setStudentList(result);
        setLoading(false);
        setUserDetails(matchedItem);
      }
    };

    fetchData();
  }, [customId]);

  console.log(studentList);

  const handleSearch = (value) => {
    setSearchTerm(value);

    // If the search term is empty, reset filtered notifications
    if (value.trim() === "") {
      setFilteredList([]);
    } else {
      // Filter notifications based on the search term
      const filtered = studentList.filter(
        (item) => item.SNAME.toLowerCase().includes(value.toLowerCase()) || item.CLNAME.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredList(filtered);
    }
  };

  const studentsToRender = searchTerm ? filteredList : studentList;

  const { sidebarVisible, toggleSidebar } = useSidebar();

  return (
    <>
      <div className="md:mt-6 mt-10">
        <div className="p-2 mb-3 flex items-center w-fit flex-wrap">
          <ul className="flex items-center">
            <li className="inline-flex items-center cursor-default">
              <a href="/" className="text-black font-medium hover:scale-105">
                <svg
                  className="w-5 cursor-default h-auto fill-current mx-2"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="#000000"
                >
                  <path d="M0 0h24v24H0V0z" fill="none" />
                  <path d="M10 19v-5h4v5c0 .55.45 1 1 1h3c.55 0 1-.45 1-1v-7h1.7c.46 0 .68-.57.33-.87L12.67 3.6c-.38-.34-.96-.34-1.34 0l-8.36 7.53c-.34.3-.13.87.33.87H5v7c0 .55.45 1 1 1h3c.55 0 1-.45 1-1z" />
                </svg>
              </a>

              <span className="mx-4 h-auto font-medium">/</span>
            </li>
            <li className="inline-flex items-center">
              <p className="text-black font-medium cursor-default hover:scale-105">Black Listed Students</p>
              {/* <span className="mx-4 h-auto text-gray-400 font-medium">/</span> */}
            </li>
          </ul>
        </div>
        <div className="container mx-auto p-4 containerBoxShadow">
          <div className="md:flex flex-col md:flex-row items-center justify-between mb-4">
            <div className="md:px-10 px-5">
              <h2 className=" md:text-2xl font-bold text-xl mb-2 md:mb-0">
                Admin :{userDetails && userDetails.length > 0 && userDetails[0].schoolId}
              </h2>
              <h4 className="mb-2 font-bold">BlackListed Student List </h4>
            </div>
            <div className=" w-2/6">
              <input
                type="text"
                placeholder="Search..."
                value={searchTerm}
                onChange={(e) => handleSearch(e.target.value)}
                className="textInput w-60"
              />
            </div>
          </div>

          <div className="bg-white p-6 shadow-md mt-2 rounded-md overflow-y-auto md:max-h-96">
            {/* Display the content for the specific tab */}
            {studentsToRender?.map((item, index) => {
              let ur = `${process.env.REACT_APP_ERP_STUDENT_IMAGE_URL}${item?.PHOTOFILE}`;
              return (
                <Link
                  to={`/admin/u/${customId}/${item.ADMNO}/StudentDetailsView`}
                  key={index}
                  className=" lg:flex items-center border border-gray-300 mb-2"
                >
                  <img
                    src={item.PHOTOFILE === "noPhoto.jpg" && (item?.PHOTOFILE === undefined || item?.PHOTOFILE === null) ? personicon : ur}
                    alt={`Staff ${index + 1}`}
                    className="w-16 h-16 object-cover rounded-full mr-4"
                  />
                  <div className="ml-2">
                    <h3 className="text-lg font-semibold">{item.SNAME}</h3>
                    <p className=" text-green-500">Admission No:{item.ADMNO}</p>
                    <p className=" text-gray-500">
                      Class: {item.CLNAME}-{item.SECNAME}
                    </p>
                    <div className="flex items-center">
                      <FaPhone className="text-blue-500 mr-2" />
                      <p className="text-orange-500">{item.SMSPHONE}</p>
                    </div>
                    <p className="text-green-500 mb-2">Father: {item.FATHERNAME}</p>
                  </div>
                </Link>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}

export default BlacklistStudentList;
