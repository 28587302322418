import React from "react";
import axios from "axios";
import toast from "react-toastify";
import { getUserDataFromLocalByKey } from "./getStudentInfoByKeyLocal";

export const getCBSEExamTermMasterList = async (
  userId,
  schoolId,
  userType,
  year
) => {
  try {
    const key = "webServiceURL";

    const url = process.env.REACT_APP_ERP_CBSE_EXAM_PAGE;


    

    const returnUrl = await getUserDataFromLocalByKey(
      userId,
      schoolId,
      userType,
      year,
      key
    );

    const formData = new FormData();
    formData.append("title", "GetCBSEExamTermMasterList");
    formData.append("description", "Checking");

    let userdata = [];

    const Corskey = process.env.REACT_APP_CORS_URL;

    // const result = await axios.post(
    //   "https://cors-anywhere.herokuapp.com/http://apkarnold.newgentechnology.net/WebDataProcessingServer.aspx",
    //   formData
    // );

    const result = await axios.post(url, formData);


    userdata = JSON.parse(
      result.data.substring(0, result.data.indexOf("||JasonEnd", 0))
    );

    
    const jsonData1=JSON.parse(userdata[0].JSONData1)

    return jsonData1;
  } catch (error) {
    console.error("An error occurred:", error);
    // Handle error here
  }
  // Placeholder JSX, replace with actual JSX
};
