import React from "react";
import axios from "axios";
import toast from "react-toastify";
import { getUserDataFromLocalByKey } from "./getStudentInfoByKeyLocal";

export const getFeeSummaryStudentMonthWiseAPI = async (
  userId,
  schoolId,
  userType,
  year,
  admn
) => {
  try {
    const key = "webServiceURL";
    //const classKey='EMPCODE'

    const returnUrl = await getUserDataFromLocalByKey(
      userId,
      schoolId,
      userType,
      year,
      key
    );

    // const returnUrl = process.env.REACT_APP_ERP_QUICKINFO_PAGE;

    //console.log(userId,schoolId,userType,year);

    //const empcode=await getUserDataFromLocalByKey(userId, schoolId, userType, year, classKey)

    //
    // const sectionName= await getUserDataFromLocalByKey(userId, schoolId, userType, year, sectionKey)

    const formData = new FormData();
    formData.append("title", "GetQIStudentDemandCollectionList");
    formData.append("description", "Get Class wise Head wise Data");
    formData.append("ReqSearchType", "3"); // month wise 3
    formData.append("Reqdisptype", "0"); // month wise 0
    formData.append("ReqAcaStart", year);
    formData.append("ReqAdmno", admn);

    let userdata = [];

    const Corskey = process.env.REACT_APP_CORS_URL;

    // const result = await axios.post(
    //   "https://cors-anywhere.herokuapp.com/http://apkarnold.newgentechnology.net/WebDataProcessingServer.aspx",
    //   formData
    // );

    const result = await axios.post(`${Corskey}${returnUrl}`, formData);

    userdata = JSON.parse(
      result.data.substring(0, result.data.indexOf("||JasonEnd", 0))
    );

    return userdata;
  } catch (error) {
    console.error("An error occurred:", error);
    // Handle error here
  }
  // Placeholder JSX, replace with actual JSX
};
