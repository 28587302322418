import { Trash2 } from "lucide-react";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { deleteHWSMNotification } from "../../API/deleteHWSMNotification";
import { getCTNoticeListDetails } from "../../API/getCTNoticeListAPI";
import { getUserDataFromLocalByKey } from "../../API/getStudentInfoByKeyLocal";
import plusicon from "../../assets/png/plusicon.png";
import DeleteModal from "../../components/DeleteModal";
import Spinner from "../../components/Spinner";
import useSidebar from "../../hooks/useSidebar";

const CTNoticeBoard = () => {
  const { customId } = useParams();
  const [userDetails, setUserDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [myMentorsList, setMyMentorsList] = useState([]);
  const [ctName, setCtName] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [selectedHomeWork, setSelectedHomeWork] = useState(null);
  const [initialResourceUrl, setInitialResourceUrl] = useState(null);
  const [resourceUrl, setResourceUrl] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedNotice, setSelectedNotice] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const users = JSON.parse(localStorage.getItem("registeredUsers")) || [];

      if (customId) {
        const decodedData = JSON.parse(atob(customId));
        let matchedItem = [];

        users.forEach((innerArray) => {
          const [userData] = innerArray;

          if (
            userData.userId === decodedData.userId &&
            userData.userType === decodedData.userType &&
            userData.schoolId === decodedData.schoolId &&
            userData.year === decodedData.year
          ) {
            matchedItem.push(userData);
          }
        });

        const namekeyval = "EmployeeName";
        const username = await getUserDataFromLocalByKey(
          decodedData.userId,
          decodedData.schoolId,
          decodedData.userType,
          decodedData.year,
          namekeyval
        );

        const modifiedUsername = username.replace(/(?:MS|MR)\s*/g, "");

        setCtName(modifiedUsername);

        const result = await getCTNoticeListDetails(decodedData.userId, decodedData.schoolId, decodedData.userType, decodedData.year);

        const key = "WebResourceAWSDOTNETURL";

        const dataResourceUrl = await getUserDataFromLocalByKey(
          decodedData.userId,
          decodedData.schoolId,
          decodedData.userType,
          decodedData.year,
          key
        );

        setResourceUrl(dataResourceUrl);

        const keyval = "webServiceURLAWSDOTNET";
        const dataUrl = await getUserDataFromLocalByKey(
          decodedData.userId,
          decodedData.schoolId,
          decodedData.userType,
          decodedData.year,
          keyval
        );
        setInitialResourceUrl(dataUrl);

        console.log("Home Work RESULT", result);
        setMyMentorsList(result);
        setLoading(false);
        setUserDetails(matchedItem);
      }
    };

    fetchData();
  }, [customId, selectedNotice]);

  const openPopup = (notification) => {
    setSelectedHomeWork(notification);
    setShowPopup(true);
  };

  const closePopup = () => {
    setShowPopup(false);
    setSelectedHomeWork(null);
  };

  const checkContentType = () => {
    console.log(selectedHomeWork.PhotoLocation, "photo location");

    if (selectedHomeWork.PhotoLocation.includes("v=")) {
      const videoId = selectedHomeWork.PhotoLocation.split("v=")[1].split("&")[0];
      // const videoId = selectedHomeWork.PhotoLocation.split("v=")[1].split("&")[0];

      console.log(videoId, "videoI");

      return (
        <>
          <iframe
            title="Notification Content"
            src={`https://www.youtube.com/embed/${videoId}`}
            className="w-full h-96 border-none"
            style={{ overflow: "auto" }}
          ></iframe>
          <button
            onClick={() => window.open(`https://www.youtube.com/watch?v=${videoId}`, "_blank")}
            className="mt-2 bg-red-500 text-white px-4 py-2 rounded"
          >
            Watch on YouTube
          </button>
        </>
      );
    }
    if (selectedHomeWork.PhotoLocation === "") {
      return null;
    } else {
      return (
        <iframe
          title="Notification Content"
          src={`${resourceUrl}/StudyMaterials/${selectedHomeWork.PhotoLocation}`}
          className="w-full h-56 border-none"
          style={{ overflow: "auto" }}
        ></iframe>
      );
    }
  };

  const handleConfirmDelete = async () => {
    try {
      const response = await deleteHWSMNotification(
        userDetails[0].userId,
        userDetails[0].schoolId,
        userDetails[0].userType,
        userDetails[0].year,
        "NOTBRD",
        selectedNotice?.HWID
      );

      toast.success(response[0].InfoField);
    } catch (error) {
      console.log(error);
    }

    setIsDeleteModalOpen(false);
    setSelectedNotice(null);
  };

  const handleDeleteClick = (notification, e) => {
    e.stopPropagation();
    setSelectedNotice(notification);
    setIsDeleteModalOpen(true);
  };

  const handleCancelDelete = () => {
    setIsDeleteModalOpen(false);
    setSelectedNotice(null);
  };

  const notificationsToRender = myMentorsList;

  const { sidebarVisible, toggleSidebar } = useSidebar();

  return (
    <>
      <DeleteModal isOpen={isDeleteModalOpen} onClose={handleCancelDelete} onConfirm={handleConfirmDelete} title={"Notice Board"} />
      <div className="flex flex-col md:flex-row items-center justify-between mb-4">
        <div className="w-2/3">
          <h2 className="md:text-3xl font-bold text-xl mb-2 md:mb-0">Notice List</h2>
          <h4 className="mb-2 font-bold">Teacher : {ctName}</h4>
        </div>
      </div>
      <Link to={`/classteacher/u/${customId}/CTNoticeBoardSubmitPage`} onClick={""}>
        <div
          className="border border-gray-300 cursor-pointer rounded-lg p-4 hover:shadow-md transition duration-300"
          style={{
            boxShadow: "5px 5px 12px 0px rgba(173, 216, 230, 0.9)",
          }}
        >
          <div className="flex items-center">
            <div className="w-1/3 sm:w-auto mr-4">
              <img src={plusicon} alt="icon" className="w-20 h-20 sm:w-15 sm:h-15 object-cover rounded" />
            </div>

            <div className="w-2/3">
              <h3 className="text-md font-semibold mb-2">Click to Add New Notice</h3>
            </div>
          </div>
        </div>
      </Link>
      <div className="container mx-auto p-4">
        {loading ? (
          <Spinner />
        ) : (
          <>
            <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-4">
              {notificationsToRender && notificationsToRender.length > 0 ? (
                notificationsToRender.map((item) => (
                  <div
                    className="flex justify-between items-center border border-gray-200 rounded-lg p-4 hover:shadow-md transition duration-300"
                    key={item.id}
                  >
                    <Link onClick={() => openPopup(item)} className="w-full">
                      <div className="">
                        <div className="flex items-center">
                          <div className="w-1/3 sm:w-auto mr-4">
                            <div className={`w-20 flex justify-center items-center h-20 sm:w-32 sm:h-32 rounded-full`}>
                              <div
                                className="rounded-full flex items-center justify-center font-semibold text-xl h-20 w-20 text-white"
                                style={{ backgroundColor: "#00008B" }}
                              >
                                {item.HWSubject.substring(0, 1)}
                              </div>
                            </div>
                          </div>

                          <div className="w-2/3">
                            <h4 className="text-md font-semibold mb-2">
                              To {item.HWSubject} {item.HWDateStr}
                            </h4>
                            <p className="text-md font-normal mb-2 text-gray-500">{item.HWRemarks}</p>
                          </div>
                        </div>
                      </div>
                    </Link>

                    <div className="flex gap-2">
                      <button
                        onClick={(e) => handleDeleteClick(item, e)}
                        className="text-red-500 hover:text-red-700 p-2 rounded-full hover:bg-red-50"
                      >
                        <Trash2 size={20} />
                      </button>
                    </div>
                  </div>
                ))
              ) : (
                <p>No data found</p>
              )}
            </div>

            {showPopup && (
              <div className="fixed inset-0 flex items-center justify-center mt-10 bg-black bg-opacity-60">
                <div className="bg-white customPopupHeight relative rounded-lg mt-8 shadow-lg p-8 md:w-1/2 w-full overflow-x-auto ">
                  {/* <div className="w-1/3 sm:w-auto mr-4">
                        <img
                          src={renderImage(selectedHomeWork.PhotoLocation)}
                          alt=""
                          className="w-20  sm:w-32  object-cover rounded"
                        />
                      </div> */}

                  <button onClick={closePopup} className="px-4 py-2  absolute right-5 top-0 mt-8 bg-red-600 text-white rounded">
                    Close
                  </button>

                  <div className="w-2/3 ">
                    <h2 className="text-lg font-semibold mb-2">{selectedHomeWork.HWSubject}</h2>
                    <h4 className="text-lg font-semibold mb-2">{selectedHomeWork.HWDateStr}</h4>
                    <p className="text-gray-600">{selectedHomeWork.HWRemarks}</p>
                  </div>

                  {checkContentType()}
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default CTNoticeBoard;
