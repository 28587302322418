import React from "react";
import axios from "axios";
import toast from "react-toastify";
import { getUserDataFromLocalByKey } from "./getStudentInfoByKeyLocal";

export const updateStudentHomeWork = async (
  userId,
  schoolId,
  userType,
  year,
  filedata,
  imageStrings,
  fileTypes,
  HWID,
 
  HWSTDREMARKS
) => {
  try {
  

    const key = "webServiceURLAWSDOTNET";
    const STDMSIDKey = "STDMSTID";
    const classKey = "EMPCLASS";
    const STDYRIDKey="STDYRID"



    const returnUrl = await getUserDataFromLocalByKey(
      userId,
      schoolId,
      userType,
      year,
      key
    );

    const HWSTDMSTID = await getUserDataFromLocalByKey(
      userId,
      schoolId,
      userType,
      year,
      STDMSIDKey
    );
    const HWSTDYRID = await getUserDataFromLocalByKey(
      userId,
      schoolId,
      userType,
      year,
      STDYRIDKey
    );

    const SMSType = process.env.REACT_APP_SMS_TYPE;


    const formData = new FormData();
    formData.append("title", "UpdateHomeWorkByStudent");
    formData.append("description", "");
   
    formData.append("HWSTDYRID", HWSTDYRID);
    formData.append("HWSTDMSTID", HWSTDMSTID);
    formData.append("HWID", HWID);
    formData.append("HWSTDREMARKS", HWSTDREMARKS);
    formData.append("ReqAcastart", year);
    formData.append("AddedBy", userId);
    formData.append("ReqSMSType", SMSType);

   

    if (filedata.length > 1) {
      filedata.forEach((file, index) => {
        
        if (index === 0) {
          formData.append(`PhotoLocation`, file);
          formData.append(`imagestring`, imageStrings[index]);
        } else {
        
          formData.append(`PhotoLocation${index+1}`, file);
          formData.append(`imagestring${index+1}`, imageStrings[index]);
        }
      });
      formData.append(`MediaType`, "Multiple Files");
    } else {
      formData.append(`PhotoLocation`, filedata[0]);
      formData.append(`imagestring`, imageStrings[0]);
      formData.append("MediaType", fileTypes[0]);
    }

    // filedata.forEach((fileInfo, index) => {
    //   const fileType = fileInfo.fileType;

    //   formData.append(`PhotoLocation`, fileInfo.newFileName);
    //   formData.append(`imagestring`, fileInfo.base64String);

    //   if (fileType === "image") {
    //     formData.append("MediaType", fileType);
    //   } else if (fileType === "PDF") {
    //     formData.append("MediaType", fileType);
    //   } else if (fileType === "Video") {
    //     formData.append("MediaType", fileType);
    //   } else if (fileType === "Excel") {
    //     formData.append("MediaType", fileType);
    //   } else {
    //     formData.append("MediaType", "Multiple Files");
    //   }
    // });

   

    let userdata = [];

    const Corskey = process.env.REACT_APP_CORS_URL;

    const result = await axios.post(`${Corskey}${returnUrl}`, formData);

    userdata = JSON.parse(
      result.data.substring(0, result.data.indexOf("||JasonEnd", 0))
    );

    return userdata;
  } catch (error) {
    console.error("An error occurred:", error);
    // Handle error here
  }
  // Placeholder JSX, replace with actual JSX
};
