import React, { useState, useEffect } from "react";
import Navbar from "../../components/Navbar";
import Sidebar from "../../components/Sidebar";
import { Link, useParams } from "react-router-dom";
import Spinner from "../../components/Spinner";
import { useSidebar } from "../../context/SidebarContext";
import image from "../../assets/png/avatar.png";
import { EyeIcon, EyeOffIcon } from "@heroicons/react/outline";
// import useSidebar from "../../hooks/useSidebar";
import { getClassWiseSubjectWiseMarkEntryListCBSE } from "../../API/GetClassWiseSubjectWiseMarkEntryListCBSE";
import ToggleVisibilityComponent from "../../components/ToggleVisibilityComponent";
import { toast } from "react-toastify";
import { UpdateClassWiseSubjectWiseMarkEntryCBSE } from "../../API/UpdateClassWiseSubjectWiseMarkEntryCBSE";

function MarkEntryPage() {
  const [examList, setExamList] = useState([]);
  const [userDetails, setUserDetails] = useState(null);
  const { customId, Class, section, subject, SvrExmid } = useParams();
  const [loading, setLoading] = useState(true);
  const [group, setGroup] = useState(false);
  const [groupList, setGroupList] = useState([]);
  const [submit, setSubmit] = useState(false);
  const [showAll, setShowAll] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  // const { sidebarVisible, toggleSidebar } = useSidebar();
  const [tableData, setTableData] = useState([]);
  const [exam, setExamName] = useState("");
  const { isSidebarOpen, currentSidebarTab, toggleSidebar, setIsSidebarOpen } =
    useSidebar();

  const fetchData = async () => {
    if (customId) {
      setLoading(true);
      const decodedData = JSON.parse(atob(customId));
      const users = JSON.parse(localStorage.getItem("registeredUsers")) || [];

      const matchedItem = users.flatMap((innerArray) =>
        innerArray.filter(
          (userData) =>
            userData.userId === decodedData.userId &&
            userData.userType === decodedData.userType &&
            userData.schoolId === decodedData.schoolId &&
            userData.year === decodedData.year
        )
      );

      const result = await getClassWiseSubjectWiseMarkEntryListCBSE(
        decodedData.userId,
        decodedData.schoolId,
        decodedData.userType,
        decodedData.year,
        SvrExmid,
        Class,
        section,
        subject
      );
      setExamName(result[0]?.ExamTerm);

      setTableData(result[0]);
      setGroupList(JSON.parse(result[0]?.GradeList));
      setLoading(false);
      setUserDetails(matchedItem);
    }
  };

  useEffect(() => {
    fetchData();
  }, [customId]);

  const TableHead = tableData?.SubjectList?.map((data, index) => {
    if (data?.Typeid > 10) {
      return {
        Activities: "Grade",
        SVRSubjID: data?.SVRSubjID,
        SvrExmSbjID: data?.SvrExmSbjID,
        SvrExmid: data?.SvrExmid,
        exam: data?.exam,
        group: true,
        setGroupList: tableData?.GradeList,
      };
    } else {
      return {
        Activities: data?.Activities,
        SVRSubjID: data?.SVRSubjID,
        SvrExmSbjID: data?.SvrExmSbjID,
        SvrExmid: data?.SvrExmid,
        exam: data?.exam,
        group: false,
        setGroupList: "",
      };
    }
  });

  const groupValue = tableData?.SubjectList?.some((data) => {
    console.log(data?.Typeid);
    return data?.Typeid > 10;
  });

  useEffect(() => {
    if (groupValue) setGroup(true);
    else setGroup(false);
  }, [groupValue]);

  const TableBody = tableData?.StudentsMarks?.map((item, index) => ({
    FullName: item.Fname,
    Grade: item.SGrade,
    Rno: item.Rno,
    ATTSTS: item.ATTSTS,
    MXM: item.MXM !== 0 ? item.MXM : null,
    MXM1: item.MXM1 !== 0 ? item.MXM1 : null,
    MXM2: item.MXM2 !== 0 ? item.MXM2 : null,
    OBTM1: item.OBTM1,
    OBTM2: item.OBTM2,
    OBTM3: item.OBTM3,
    OBTM4: item.OBTM4,
    OBTSM: item.OBTSM,
    OBTSMP: item.OBTSMP,
    RCDID1: item.RCDID1,
    Religion: item.Religion,
    STDMSTID: item.STDMSTID,
    Sname: item.Sname,
    adno: item.adno,
    SvrStdExmSbjid: item.SvrStdExmSbjid,
    SvrExmid: item.SvrExmid,
    STDYRID: item.STDYRID,
    SvrExmSbjID: item.SvrExmSbjID,
  }));

  const handleInputChange = (e, index, key) => {
    let value = e.target.value;

    if (key.startsWith("OBTM")) {
      // Convert the value to a number
      value = Number(value);
    }

    setTableData((prevState) => {
      const updatedStudentsMarks = prevState.StudentsMarks.map((item, i) => {
        if (i === index) {
          return {
            ...item,
            [key]: value,
          };
        }
        return item;
      });

      return {
        ...prevState,
        StudentsMarks: updatedStudentsMarks,
      };
    });
  };

  const handleTableSubmit = async (item, id) => {
    try {
      tableData.StudentsMarks = [item];
      setSubmit(true);
      const response = await UpdateClassWiseSubjectWiseMarkEntryCBSE(
        tableData,
        id
      );
      let actionCode = response[0]?.ActionType;
      if (actionCode > 0) {
        toast.success("update success");
        fetchData();
        // handleSubjectModalSelecet(selectSubject, selectedSvrId);
      } else {
        let err = response[0]?.ErrorMessage;
        toast.error("Error in updation");
        toast.error(`${err}`);
      }
      setSubmit(false);
    } catch (error) {
      setSubmit(false);
      toast.warn("Something went wrong");
      console.log(error);
    }
  };

  const handleGlobalSave = async () => {
    try {
      const response = await UpdateClassWiseSubjectWiseMarkEntryCBSE(
        tableData,
        ""
      );
      console.log(response, "response");
    } catch (error) {
      console.log(error);
    }
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <div>
      <Navbar
        data={userDetails}
        // isOpen={sidebarVisible}
        toggleSidebar={toggleSidebar}
      />
      <div className="flex flex-grow">
        {<Sidebar data={userDetails} />}
        <main
          className={`w-full absolute ${
            isSidebarOpen ? "lg:w-4/6 xl:w-9/12" : "w-full"
          } sm:w-11/12 right-0 top-16 mx-auto   md:p-2 lg:p-4 xl:p-6`}
        >
          {loading ? (
            <Spinner />
          ) : (
            <>
              <div className="mt-5 p-2 md:mt-2 lg:p-0 lg:mt-0 border border-black">
                <div
                  onClick={() => setIsVisible(!isVisible)}
                  className="cursor-pointer shadow-lg w-fit absolute top-6 right-6 p-2 rounded-full bg-white border border-gray-300"
                >
                  {isVisible ? (
                    <EyeOffIcon className="h-6 w-6 text-gray-700" />
                  ) : (
                    <EyeIcon className="h-6 w-6 text-gray-700" />
                  )}
                </div>
                <h2 className="w-full text-center text-2xl uppercase text-black font-mono font-semibold">
                  Subject wise Mark Entry Exam Category
                </h2>

                <div
                  className={`flex flex-col gap-3 shadow-lg md:px-3 rounded p-3 ${
                    isVisible ? "block" : "hidden"
                  }`}
                >
                  <div className="flex py-2 formBackground justify-between md:flex-row flex-col px-3 sm:px-5 gap-10 items-center">
                    <div className="w-full  gap-2 md:gap-5 flex justify-between flex-col lg:flex-row">
                      <div className="w-full grid grid-cols-2 gap-4">
                        <div className="flex flex-col">
                          <label
                            className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2"
                            htmlFor="class-select"
                          >
                            Class
                          </label>
                          <select
                            id="class-select"
                            className="textInput w-full"
                          >
                            <option>{Class}</option>
                          </select>
                        </div>

                        <div className="flex flex-col">
                          <label
                            className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2"
                            htmlFor="section-select"
                          >
                            Section
                          </label>
                          <select
                            id="section-select"
                            className="textInput w-full"
                          >
                            <option>{section}</option>
                          </select>
                        </div>

                        <div className="flex flex-col">
                          <label
                            className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2"
                            htmlFor="exam-select"
                          >
                            Exam
                          </label>
                          <div className="relative">
                            <select
                              id="exam-select"
                              className="textInput w-full appearance-none"
                            >
                              <option>{exam}</option>
                            </select>
                          </div>
                        </div>

                        <div className="flex flex-col">
                          <label
                            className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2"
                            htmlFor="subject-select"
                          >
                            Subject
                          </label>
                          <select
                            id="subject-select"
                            className="textInput w-full"
                          >
                            <option>{subject}</option>
                          </select>
                        </div>
                        {/* <div>
                              <div>
                                <input id="generateMarksheet" type="checkbox" />
                                <label
                                  className="block uppercase tracking-wide text-start text-grey-darker text-xs font-bold mb-2"
                                  htmlFor="generateMarksheet"
                                >
                                  Generate Marksheet
                                </label>
                              </div>
                              <button className="buttonClass me-3">Save</button>
                              <button className="buttonClass mx-3">
                                Cancel
                              </button>
                            </div> */}
                      </div>

                      {/* <div className="w-full h-auto md:gap-5 flex justify-evenly flex-col">
                            <button className="buttonClass md:mt-0 mt-3">
                              Recalculate
                            </button>

                            <button className="flex">
                              <p className="ps-2 linkClass md:mt-0 mt-3">
                                Select Subject
                              </p>
                            </button>
                            <button className="flex">
                              <p className="ps-2 linkClass md:mt-0 mt-3">
                                Select Student
                              </p>
                            </button>
                          </div> */}
                    </div>

                    {/* <div className="w-full md:w-1/2 flex justify-between flex-col">
                          <div className="border border-solid border-black p-2 rounded-md">
                            <label
                              className="block uppercase tracking-wide bg-sky-200 w-fit px-2 -mt-4 text-start text-grey-darker text-xs font-bold mb-2"
                              for="file_input"
                            >
                              Upload file
                            </label>
                            <input
                              className="fileInput"
                              aria-describedby="file_input_help"
                              id="file_input"
                              type="file"
                            />
                            <button className="buttonClass mt-3 mb-1">
                              upload
                            </button>
                          </div>
                          <div className="flex flex-wrap">
                            <div className="relative flex me-3 my-3 w-fit flex-row">
                              <select className="textInput min-w-20 w-[150px] md:w-[190px]">
                                <option value="">Mark Entry List</option>
                                <option value="">
                                  Mark Entry List With Data
                                </option>
                                <option value="">
                                  Mark Entry List All Subjects
                                </option>
                              </select>
                              <div className="pointer-events-none absolute right-2 top-3 flex items-center text-grey">
                                <svg
                                  className="h-4 w-4"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                                >
                                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                </svg>
                              </div>
                            </div>
                            <div>
                              <button className="buttonClass w-fit m-3">
                                Download
                              </button>
                              <button className="buttonClass w-fit m-3">
                                Show All
                              </button>
                            </div>
                          </div>
                        </div> */}
                  </div>

                  {/* <div className="flex flex-wrap gap-3 justify-evenly items-start ">
                        <div>
                          <p className="block uppercase tracking-wide text-start text-grey-darker text-xs font-bold mb-2">
                            Replace Mark
                          </p>
                          <div>
                            <input id="ApplyMaxtoall" type="checkbox" />
                            <label
                              className="block uppercase cursor-pointer tracking-wide text-start text-grey-darker text-xs font-bold mb-2"
                              htmlFor="ApplyMaxtoall"
                            >
                              Apply Max. to all
                            </label>
                          </div>
                        </div>
                        <div className="flex gap-5 lg:flex-row flex-col">
                          <div>
                            <p className="block uppercase tracking-wide text-start text-grey-darker text-xs font-bold mb-2">
                              Col.
                            </p>
                            <input type="text" className="textInput w-20" />
                          </div>
                          <div>
                            <p className="block uppercase tracking-wide text-start text-grey-darker text-xs font-bold mb-2">
                              Mark
                            </p>
                            <input type="text" className="textInput w-20" />
                          </div>
                          <button className="buttonClass">Replace</button>
                        </div>
                        <div>
                          <p className="block uppercase tracking-wide text-start text-grey-darker text-xs font-bold mb-2">
                            Sort Order
                          </p>
                          <div className="relative flex my-3 w-fit flex-row">
                            <select className="textInput min-w-20 w-[150px] md:w-[190px]">
                              <option value="Default">Default</option>
                              <option value="Student Name">Student Name</option>
                              <option value="Roll No">Roll No</option>
                              <option value="Admission No">Admission No</option>
                              <option value="Board Reg. No">
                                Board Reg. No
                              </option>
                              <option value="Exam Roll Number">
                                Exam Roll Number
                              </option>
                              <option value="Last Name">Last Name</option>
                              <option value="Gender (B-G), Name">
                                Gender (B-G), Name
                              </option>
                              <option value="Last Name, First Name, Middle Name">
                                Last Name, First Name, Middle Name
                              </option>
                              <option value="Gender (B-G), Last Name, First Name, Middle Name">
                                Gender (B-G), Last Name, First Name, Middle Name
                              </option>
                              <option value="Gender (G-B), Last Name, First Name, Middle Name">
                                Gender (G-B), Last Name, First Name, Middle Name
                              </option>
                              <option value="Gender (G-B), Name">
                                Gender (G-B), Name
                              </option>
                            </select>
                            <div className="pointer-events-none absolute right-2 top-3 flex items-center text-grey">
                              <svg
                                className="h-4 w-4"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                              >
                                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                              </svg>
                            </div>
                          </div>
                        </div>
                        <div>
                          <button className="buttonClass">
                            Term Wise Mark Entry Status
                          </button>
                        </div>
                      </div> */}
                </div>

                {TableBody && TableHead && (
                  <div className="md:p-2 md:mt-0 mt-3 w-full shadow-lg">
                    <div className="relative max-h-[450px] overflow-x-auto border md:block hidden border-black border-solid rounded-sm">
                      <table className="w-full bg-white text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                        <thead className="tableHead sticky top-0  blur-0">
                          <tr>
                            <th
                              scope="col"
                              className="p-3 uppercase text-sm md:text-base"
                            >
                              R. No.
                            </th>
                            <th
                              scope="col"
                              className="p-3 uppercase text-sm md:text-base"
                            >
                              Adm. No
                            </th>
                            <th
                              scope="col"
                              className="p-3 uppercase text-sm md:text-base"
                            >
                              Name
                            </th>

                            {TableHead?.map((data, index) => {
                              return (
                                <th
                                  key={index}
                                  scope="col"
                                  className="p-3 uppercase text-sm md:text-base"
                                >
                                  {data?.Activities}
                                </th>
                              );
                            })}

                            {!group && (
                              <>
                                <th
                                  scope="col"
                                  className="p-3 uppercase text-sm md:text-base"
                                >
                                  Total
                                </th>
                                <th
                                  scope="col"
                                  className="p-3 uppercase text-sm md:text-base"
                                >
                                  Tot%
                                </th>
                                <th
                                  scope="col"
                                  className="p-3 uppercase text-sm md:text-base"
                                >
                                  Grade
                                </th>
                              </>
                            )}
                            <th
                              scope="col"
                              className="p-3 uppercase text-sm md:text-base"
                            >
                              TCSTS
                            </th>
                            <th
                              scope="col"
                              className="p-3 uppercase text-sm md:text-base"
                            >
                              Save
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {tableData?.StudentsMarks?.map((item, index) => {
                            return (
                              <React.Fragment key={index}>
                                {showAll ? (
                                  <>
                                    <tr
                                      className={`border-b ${
                                        index % 2 === 0
                                          ? "bg-white"
                                          : "bg-slate-200"
                                      } dark:bg-gray-800 dark:border-gray-700`}
                                    >
                                      <td className="p-3 border border-solid border-black/10">
                                        {item?.Rno}
                                      </td>
                                      <td className="p-3 border border-solid border-black/10 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                        {item?.adno}
                                      </td>
                                      <td className="p-3 border border-solid border-black/10 font-medium text-gray-800">
                                        {item?.Sname}
                                      </td>
                                      {TableHead?.map((data, i) => (
                                        <React.Fragment key={i}>
                                          {i !== undefined && i !== null ? (
                                            <>
                                              {item?.ATTSTS === "AB" ? (
                                                <td className="p-3 border border-solNofDefStdd border-black/10 font-medium text-gray-800">
                                                  <input
                                                    className="textInput w-16"
                                                    type="text"
                                                    onChange={(e) =>
                                                      handleInputChange(
                                                        e,
                                                        index,
                                                        "ATTSTS"
                                                      )
                                                    }
                                                    value={item?.ATTSTS}
                                                  />
                                                </td>
                                              ) : (
                                                <>
                                                  {group ? (
                                                    <>
                                                      <td className="p-3 border border-solid border-black/10 font-medium text-gray-800">
                                                        <select
                                                          value={item?.ATTSTS1}
                                                          onChange={(e) =>
                                                            handleInputChange(
                                                              e,
                                                              index,
                                                              "ATTSTS1"
                                                            )
                                                          }
                                                          name=""
                                                          id=""
                                                          className="textInput w-20"
                                                        >
                                                          {groupList?.length >
                                                            0 &&
                                                            groupList?.map(
                                                              (item, index) => {
                                                                return (
                                                                  <option
                                                                    key={index}
                                                                    value={
                                                                      item?.GradeName
                                                                    }
                                                                  >
                                                                    {
                                                                      item?.GradeName
                                                                    }
                                                                  </option>
                                                                );
                                                              }
                                                            )}
                                                        </select>
                                                      </td>
                                                    </>
                                                  ) : (
                                                    <>
                                                      {item?.[`ATTSTS${i + 1}`] &&
                                                      TableHead.length > i ? (
                                                        <>
                                                          <td className="p-3 border border-solNofDefStdd border-black/10 font-medium text-gray-800">
                                                            <input
                                                              onChange={(e) =>
                                                                handleInputChange(
                                                                  e,
                                                                  index,
                                                                  `ATTSTS${i + 1}`
                                                                )
                                                              }
                                                              className="textInput w-16"
                                                              type="text"
                                                              value={
                                                                item?.[
                                                                  `ATTSTS${i + 1}`
                                                                ]
                                                              }
                                                            />
                                                          </td>
                                                        </>
                                                      ) : (
                                                        <>
                                                          <td className="p-3 border border-solNofDefStdd border-black/10 font-medium text-gray-800">
                                                            <input
                                                              onChange={(e) =>
                                                                handleInputChange(
                                                                  e,
                                                                  index,
                                                                  `ATTSTS${i + 1}`
                                                                )
                                                              }
                                                              className="textInput w-16"
                                                              type="text"
                                                              value={
                                                                item?.[
                                                                  `ATTSTS${i + 1}`
                                                                ]
                                                              }
                                                              // value={0}
                                                            />
                                                          </td>
                                                        </>
                                                      )}
                                                    </>
                                                  )}
                                                </>
                                              )}
                                            </>
                                          ) : null}
                                        </React.Fragment>
                                      ))}

                                      {!group && (
                                        <>
                                          {" "}
                                          <td className="p-3 border border-solNofDefStdd border-black/10 font-medium text-gray-800">
                                            {item?.OBTSM}
                                          </td>
                                          <td className="p-3 border border-solid border-black/10 font-medium text-gray-800">
                                            {item?.OBTSMP}
                                          </td>
                                          <td className="p-3 border border-solid border-black/10 font-medium text-gray-800">
                                            {item?.Grade}
                                          </td>
                                        </>
                                      )}

                                      <td className="p-3 border border-solid border-black/10 font-medium text-gray-800">
                                        {item?.TCSTS}
                                      </td>

                                      <td className="p-3 border border-solid border-black/10 font-medium">
                                        <button
                                          onClick={() =>
                                            handleTableSubmit(
                                              item,
                                              item.STDYRID
                                            )
                                          }
                                          type="button"
                                          disabled={submit}
                                          className="buttonClass !uppercase !text-xs !bg-gradient-to-br !from-pink-600 !via-red-500 !to-rose-600"
                                        >
                                          Save
                                        </button>
                                      </td>
                                    </tr>
                                  </>
                                ) : (
                                  <>
                                    {item?.TCSTS === "A" && (
                                      <tr
                                        className={`border-b ${
                                          index % 2 === 0
                                            ? "bg-white"
                                            : "bg-slate-200"
                                        } dark:bg-gray-800 dark:border-gray-700`}
                                      >
                                        <td className="p-3 border border-solid border-black/10">
                                          {item?.Rno}
                                        </td>
                                        <td className="p-3 border border-solid border-black/10 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                          {item?.adno}
                                        </td>
                                        <td className="p-3 border border-solid border-black/10 font-medium text-gray-800">
                                          {item?.Sname}
                                        </td>
                                        {TableHead?.map((data, i) => (
                                          <React.Fragment key={i}>
                                            {/* {item?.STDYRID === 31502 ? console.log(item) : ""} */}
                                            {i !== undefined && i !== null ? (
                                              <>
                                                {item?.ATTSTS === "AB" ? (
                                                  <td className="p-3 border border-solNofDefStdd border-black/10 font-medium text-gray-800">
                                                    <input
                                                      className="textInput w-16"
                                                      type="text"
                                                      onChange={(e) =>
                                                        handleInputChange(
                                                          e,
                                                          index,
                                                          "ATTSTS"
                                                        )
                                                      }
                                                      value={item?.ATTSTS}
                                                    />
                                                  </td>
                                                ) : (
                                                  <>
                                                    {group ? (
                                                      <>
                                                        <td className="p-3 border border-solNofDefStdd border-black/10 font-medium text-gray-800">
                                                          <select
                                                            name=""
                                                            value={
                                                              item?.ATTSTS1
                                                            }
                                                            onChange={(e) =>
                                                              handleInputChange(
                                                                e,
                                                                index,
                                                                "ATTSTS1"
                                                              )
                                                            }
                                                            id=""
                                                            className="textInput w-20"
                                                          >
                                                            {groupList?.length >
                                                              0 &&
                                                              groupList?.map(
                                                                (
                                                                  item,
                                                                  index
                                                                ) => {
                                                                  return (
                                                                    <option
                                                                      key={
                                                                        index
                                                                      }
                                                                      value={
                                                                        item?.GradeName
                                                                      }
                                                                    >
                                                                      {
                                                                        item?.GradeName
                                                                      }
                                                                    </option>
                                                                  );
                                                                }
                                                              )}
                                                          </select>
                                                        </td>
                                                      </>
                                                    ) : (
                                                      <>
                                                        {item?.[
                                                          `ATTSTS${i + 1}`
                                                        ] &&
                                                        TableHead.length > i ? (
                                                          <>
                                                            <td className="p-3 border border-solNofDefStdd border-black/10 font-medium text-gray-800">
                                                              <input
                                                                onChange={(e) =>
                                                                  handleInputChange(
                                                                    e,
                                                                    index,
                                                                    `ATTSTS${
                                                                      i + 1
                                                                    }`
                                                                  )
                                                                }
                                                                className="textInput w-16"
                                                                type="text"
                                                                value={
                                                                  item?.[
                                                                    `ATTSTS${
                                                                      i + 1
                                                                    }`
                                                                  ]
                                                                }
                                                              />
                                                            </td>
                                                          </>
                                                        ) : (
                                                          <>
                                                            <td className="p-3 border border-solNofDefStdd border-black/10 font-medium text-gray-800">
                                                              <input
                                                                onChange={(e) =>
                                                                  handleInputChange(
                                                                    e,
                                                                    index,
                                                                    `ATTSTS${
                                                                      i + 1
                                                                    }`
                                                                  )
                                                                }
                                                                className="textInput w-16"
                                                                type="text"
                                                                value={
                                                                  item?.[
                                                                    `ATTSTS${
                                                                      i + 1
                                                                    }`
                                                                  ]
                                                                }
                                                                // value={0}
                                                              />
                                                            </td>
                                                          </>
                                                        )}
                                                      </>
                                                    )}
                                                  </>
                                                )}
                                              </>
                                            ) : null}
                                          </React.Fragment>
                                        ))}
                                        {!group && (
                                          <>
                                            <td className="p-3 border border-solNofDefStdd border-black/10 font-medium text-gray-800">
                                              {item?.OBTSM}
                                            </td>
                                            <td className="p-3 border border-solid border-black/10 font-medium text-gray-800">
                                              {item?.OBTSMP}
                                            </td>
                                            <td className="p-3 border border-solid border-black/10 font-medium text-gray-800">
                                              {item?.Grade}
                                            </td>
                                          </>
                                        )}
                                        <td className="p-3 border border-solid border-black/10 font-medium text-gray-800">
                                          {item?.TCSTS}
                                        </td>
                                        <td className="p-3 border border-solid border-black/10 font-medium">
                                          <button
                                            onClick={() =>
                                              handleTableSubmit(
                                                item,
                                                item.STDYRID
                                              )
                                            }
                                            type="button"
                                            disabled={submit}
                                            className="buttonClass !uppercase !text-xs !bg-gradient-to-br !from-pink-600 !via-red-500 !to-rose-600"
                                          >
                                            Save
                                          </button>
                                        </td>
                                      </tr>
                                    )}
                                  </>
                                )}
                              </React.Fragment>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                    <div className="md:hidden block">
                      <div className="block md:hidden h-screen overflow-auto max-w-2xl mx-auto w-full bg-white shadow-md p-2 rounded-lg">
                        <h2 className="text-lg cardHeadingClass mb-4 w-full text-center">
                          Students List
                        </h2>
                        <div className="overflow-x-auto">
                          {tableData?.StudentsMarks?.map((item, index) => {
                            return (
                              <>
                                {showAll ? (
                                  <div
                                    className="border-2 bg-gradient-to-b from-sky-200 to-cyan-400 rounded-lg mb-4 shadow-lg overflow-hidden"
                                    key={index}
                                  >
                                    <div className="flex students-center justify-between tabButton py-2 px-4 rounded-t-lg shadow-md">
                                      <input
                                        className="radioButton"
                                        type="radio"
                                        name="employeesRadio"
                                        id={`employeesRadio-${index}`}
                                      />
                                      <span className="font-bold text-center w-full">
                                        {" "}
                                        {item?.Sname}
                                      </span>
                                    </div>
                                    <div className="flex justify-center mt-4">
                                      <img
                                        src={image}
                                        alt="Student"
                                        className=" h-28 w-28 object-cover p-4 rounded-full"
                                      />
                                    </div>
                                    <table className="w-full table-auto mt-2">
                                      <tbody>
                                        <tr>
                                          <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                                            <strong>Adm. No</strong>
                                          </td>
                                          <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                                            {item?.adno}
                                          </td>
                                        </tr>
                                        {TableHead?.map((data, ind) => (
                                          <tr key={ind}>
                                            <React.Fragment>
                                              {ind !== undefined &&
                                              ind !== null ? (
                                                <>
                                                  {item?.ATTSTS === "AB" ? (
                                                    <>
                                                      <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                                                        <strong>
                                                          {data?.Activities}
                                                        </strong>
                                                      </td>
                                                      <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                                                        <input
                                                          className="textInput w-16"
                                                          type="text"
                                                          value={item?.ATTSTS}
                                                          onChange={(e) =>
                                                            handleInputChange(
                                                              e,
                                                              index,
                                                              `ATTSTS`
                                                            )
                                                          }
                                                        />
                                                      </td>
                                                    </>
                                                  ) : (
                                                    <>
                                                      {group ? (
                                                        <>
                                                          <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                                                            <strong>
                                                              {data?.Activities}
                                                            </strong>
                                                          </td>
                                                          <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                                                            <select
                                                              value={
                                                                item?.ATTSTS1
                                                              }
                                                              onChange={(e) =>
                                                                handleInputChange(
                                                                  e,
                                                                  index,
                                                                  "ATTSTS1"
                                                                )
                                                              }
                                                              name=""
                                                              id=""
                                                              className="textInput w-20"
                                                            >
                                                              {groupList?.length >
                                                                0 &&
                                                                groupList?.map(
                                                                  (
                                                                    item,
                                                                    index
                                                                  ) => {
                                                                    return (
                                                                      <option
                                                                        key={
                                                                          index
                                                                        }
                                                                        value={
                                                                          item?.GradeName
                                                                        }
                                                                      >
                                                                        {
                                                                          item?.GradeName
                                                                        }
                                                                      </option>
                                                                    );
                                                                  }
                                                                )}
                                                            </select>
                                                          </td>
                                                        </>
                                                      ) : (
                                                        <>
                                                          {item?.[
                                                            `OBTM${ind + 1}`
                                                          ] &&
                                                          TableHead.length >
                                                            ind ? (
                                                            <>
                                                              <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                                                                <strong>
                                                                  {
                                                                    data?.Activities
                                                                  }
                                                                </strong>
                                                              </td>
                                                              <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                                                                <input
                                                                  className="textInput w-16"
                                                                  type="text"
                                                                  value={
                                                                    item?.[
                                                                      `ATTSTS${
                                                                        ind + 1
                                                                      }`
                                                                    ]
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) =>
                                                                    handleInputChange(
                                                                      e,
                                                                      index,
                                                                      `ATTSTS${
                                                                        ind + 1
                                                                      }`
                                                                    )
                                                                  }
                                                                />
                                                              </td>
                                                            </>
                                                          ) : (
                                                            <>
                                                              <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                                                                <strong>
                                                                  {
                                                                    data?.Activities
                                                                  }
                                                                </strong>
                                                              </td>
                                                              <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                                                                <input
                                                                  className="textInput w-16"
                                                                  type="text"
                                                                  value={
                                                                    item?.[
                                                                      `ATTSTS${
                                                                        ind + 1
                                                                      }`
                                                                    ]
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) =>
                                                                    handleInputChange(
                                                                      e,
                                                                      index,
                                                                      `ATTSTS${
                                                                        ind + 1
                                                                      }`
                                                                    )
                                                                  }
                                                                />
                                                              </td>
                                                            </>
                                                          )}
                                                        </>
                                                      )}
                                                    </>
                                                  )}
                                                </>
                                              ) : null}
                                            </React.Fragment>
                                          </tr>
                                        ))}
                                        <tr>
                                          <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                                            <strong>Total</strong>
                                          </td>
                                          <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                                            {item?.OBTSM}
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                                            <strong>Tot%</strong>
                                          </td>
                                          <td className="border border-solid border-gray-400 text-start capitalize p-2 w-1/2">
                                            {item?.OBTSMP}
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                                            <strong> Grade</strong>
                                          </td>
                                          <td className="border border-solid border-gray-400 text-start capitalize p-2 w-1/2">
                                            {item?.Grade}
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                                            <strong> TCSTS</strong>
                                          </td>
                                          <td className="border border-solid border-gray-400 text-start capitalize p-2 w-1/2">
                                            {item?.TCSTS}
                                          </td>
                                        </tr>
                                        <tr>
                                          <button
                                            onClick={() =>
                                              handleTableSubmit(
                                                item,
                                                item.STDYRID
                                              )
                                            }
                                            type="button"
                                            disabled={submit}
                                            className="buttonClass !uppercase !text-xs !bg-gradient-to-br !from-pink-600 !via-red-500 !to-rose-600"
                                          >
                                            Save
                                          </button>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                ) : (
                                  <>
                                    {item?.TCSTS === "A" && (
                                      <div
                                        className="border-2 bg-gradient-to-b from-sky-200 to-cyan-400 rounded-lg mb-4 shadow-lg overflow-hidden"
                                        key={index}
                                      >
                                        <div className="flex students-center justify-between tabButton py-2 px-4 rounded-t-lg shadow-md">
                                          <input
                                            className="radioButton"
                                            type="radio"
                                            name="employeesRadio"
                                            id={`employeesRadio-${index}`}
                                          />
                                          <span className="font-bold text-center w-full">
                                            {" "}
                                            {item?.Sname}
                                          </span>
                                        </div>
                                        <div className="flex justify-center mt-4">
                                          <img
                                            src={image}
                                            alt="Student"
                                            className=" h-28 w-28 object-cover p-4 rounded-full"
                                          />
                                        </div>
                                        <table className="w-full table-auto mt-2">
                                          <tbody>
                                            <tr>
                                              <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                                                <strong>Adm. No</strong>
                                              </td>
                                              <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                                                {item?.adno}
                                              </td>
                                            </tr>
                                            {TableHead?.map((data, ind) => (
                                              <tr key={ind}>
                                                <React.Fragment>
                                                  {ind !== undefined &&
                                                  ind !== null ? (
                                                    <>
                                                      {item?.ATTSTS === "AB" ? (
                                                        <>
                                                          <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                                                            <strong>
                                                              {data?.Activities}
                                                            </strong>
                                                          </td>
                                                          <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                                                            <input
                                                              className="textInput w-16"
                                                              type="text"
                                                              value={
                                                                item?.ATTSTS
                                                              }
                                                              onChange={(e) =>
                                                                handleInputChange(
                                                                  e,
                                                                  index,
                                                                  `ATTSTS`
                                                                )
                                                              }
                                                            />
                                                          </td>
                                                        </>
                                                      ) : (
                                                        <>
                                                          {group ? (
                                                            <>
                                                              <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                                                                <strong>
                                                                  {
                                                                    data?.Activities
                                                                  }
                                                                </strong>
                                                              </td>
                                                              <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                                                                <select
                                                                  value={
                                                                    item?.ATTSTS1
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) =>
                                                                    handleInputChange(
                                                                      e,
                                                                      index,
                                                                      "ATTSTS1"
                                                                    )
                                                                  }
                                                                  name=""
                                                                  id=""
                                                                  className="textInput w-20"
                                                                >
                                                                  {groupList?.length >
                                                                    0 &&
                                                                    groupList?.map(
                                                                      (
                                                                        item,
                                                                        index
                                                                      ) => {
                                                                        return (
                                                                          <option
                                                                            key={
                                                                              index
                                                                            }
                                                                            value={
                                                                              item?.GradeName
                                                                            }
                                                                          >
                                                                            {
                                                                              item?.GradeName
                                                                            }
                                                                          </option>
                                                                        );
                                                                      }
                                                                    )}
                                                                </select>
                                                              </td>
                                                            </>
                                                          ) : (
                                                            <>
                                                              {item?.[
                                                                `OBTM${ind + 1}`
                                                              ] &&
                                                              TableHead.length >
                                                                ind ? (
                                                                <>
                                                                  <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                                                                    <strong>
                                                                      {
                                                                        data?.Activities
                                                                      }
                                                                    </strong>
                                                                  </td>
                                                                  <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                                                                    <input
                                                                      className="textInput w-16"
                                                                      type="text"
                                                                      value={
                                                                        item?.[
                                                                          `ATTSTS${
                                                                            ind +
                                                                            1
                                                                          }`
                                                                        ]
                                                                      }
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleInputChange(
                                                                          e,
                                                                          index,
                                                                          `ATTSTS${
                                                                            ind +
                                                                            1
                                                                          }`
                                                                        )
                                                                      }
                                                                    />
                                                                  </td>
                                                                </>
                                                              ) : (
                                                                <>
                                                                  <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                                                                    <strong>
                                                                      {
                                                                        data?.Activities
                                                                      }
                                                                    </strong>
                                                                  </td>
                                                                  <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                                                                    <input
                                                                      className="textInput w-16"
                                                                      type="text"
                                                                      value={
                                                                        item?.[
                                                                          `ATTSTS${
                                                                            ind +
                                                                            1
                                                                          }`
                                                                        ]
                                                                      }
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleInputChange(
                                                                          e,
                                                                          index,
                                                                          `ATTSTS${
                                                                            ind +
                                                                            1
                                                                          }`
                                                                        )
                                                                      }
                                                                    />
                                                                  </td>
                                                                </>
                                                              )}
                                                            </>
                                                          )}
                                                        </>
                                                      )}
                                                    </>
                                                  ) : null}
                                                </React.Fragment>
                                              </tr>
                                            ))}
                                            <tr>
                                              <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                                                <strong>Total</strong>
                                              </td>
                                              <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                                                {item?.OBTSM}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                                                <strong>Tot%</strong>
                                              </td>
                                              <td className="border border-solid border-gray-400 text-start capitalize p-2 w-1/2">
                                                {item?.OBTSMP}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                                                <strong> Grade</strong>
                                              </td>
                                              <td className="border border-solid border-gray-400 text-start capitalize p-2 w-1/2">
                                                {item?.Grade}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                                                <strong> TCSTS</strong>
                                              </td>
                                              <td className="border border-solid border-gray-400 text-start capitalize p-2 w-1/2">
                                                {item?.TCSTS}
                                              </td>
                                            </tr>
                                            <tr>
                                              <button
                                                onClick={() =>
                                                  handleTableSubmit(
                                                    item,
                                                    item.STDYRID
                                                  )
                                                }
                                                type="button"
                                                disabled={submit}
                                                className="buttonClass !uppercase !text-xs !bg-gradient-to-br !from-pink-600 !via-red-500 !to-rose-600"
                                              >
                                                Save
                                              </button>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>
                                    )}
                                  </>
                                )}
                              </>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </>
          )}
        </main>
      </div>
    </div>
  );
}

export default MarkEntryPage;
