import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Spinner from "../../components/Spinner";
import useSidebar from "../../hooks/useSidebar";
import personicon from "../../assets/png/person-icon.png";
import { FaPhone } from "react-icons/fa";
import { getUserDataFromLocalByKey } from "../../API/getStudentInfoByKeyLocal";
import { getAdminStaffDetailsList } from "../../API/getStaffDetailsListAPI";

const StaffTabContent = ({ staffDetailsList }) => {
    const filteredList = staffDetailsList.slice(1);
    return (
        <div className="bg-white p-6 shadow-md rounded-md">
            {/* Display the content for the specific tab */}
            {filteredList.map((item, index) => (
                <div key={index} className="lg:flex items-center border border-gray-300 mb-2">
                    <img
                        src={item.Col5 === "noPhoto.jpg" ? personicon : item.Col5}
                        alt={`Staff ${index + 1}`}
                        className="w-16 h-16 object-cover rounded-full mr-4"
                    />
                    <div>
                        <h3 className="text-lg font-semibold">{item.Head2}</h3>
                        <p className=" text-green-500">Designation:{item.Col4}</p>
                        <div className="flex items-center">
                            <FaPhone className="text-blue-500 mr-2" />
                            <p className="text-orange-500">{item.Col1}</p>
                        </div>
                        <p className="text-green-500 mb-2">
                            {item.Col4} {item.Col2} {item.Col3}
                        </p>
                    </div>
                </div>
            ))}
        </div>
    );
};

function StaffDetails() {
    const { customId } = useParams();
    const [userDetails, setUserDetails] = useState(null);
    const [loading, setLoading] = useState(true);
    const [initialResourceUrl, setInitialResourceUrl] = useState(null);
    const [activeTab, setActiveTab] = useState("ClassTeacher"); // Initial active tab
    const [passkey, setPassKey] = useState("Class Teacher");
    const [staffDetailsList, setStaffDetailsList] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const users = JSON.parse(localStorage.getItem("registeredUsers")) || [];

            if (customId) {
                const decodedData = JSON.parse(atob(customId));
                let matchedItem = [];

                users.forEach((innerArray) => {
                    const [userData] = innerArray;

                    if (
                        userData.userId === decodedData.userId &&
                        userData.userType === decodedData.userType &&
                        userData.schoolId === decodedData.schoolId &&
                        userData.year === decodedData.year
                    ) {
                        matchedItem.push(userData);
                    }
                });

                const result = await getAdminStaffDetailsList(
                    decodedData.userId,
                    decodedData.schoolId,
                    decodedData.userType,
                    decodedData.year,
                    passkey
                );

                const keyval = "webResourceURL";
                const dataUrl = await getUserDataFromLocalByKey(
                    decodedData.userId,
                    decodedData.schoolId,
                    decodedData.userType,
                    decodedData.year,
                    keyval
                );
                setInitialResourceUrl(dataUrl);
                setStaffDetailsList(result);
                setLoading(false);
                setUserDetails(matchedItem);
            }
        };

        fetchData();
    }, [customId, passkey]);

    //console.log(staffDetailsList);

    const handleTabChange = (tabName) => {
        setActiveTab(tabName);
    };

    const { sidebarVisible, toggleSidebar } = useSidebar();

    return (
        <>
           <div className="md:mt-6 mt-10">
                <div className="p-2 mb-3 flex items-center w-fit flex-wrap">
                    <ul className="flex items-center">
                        <li className="inline-flex items-center cursor-default">
                            <a href="/" className="text-black font-medium hover:scale-105">
                                <svg
                                    className="w-5 cursor-default h-auto fill-current mx-2"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    fill="#000000"
                                >
                                    <path d="M0 0h24v24H0V0z" fill="none" />
                                    <path d="M10 19v-5h4v5c0 .55.45 1 1 1h3c.55 0 1-.45 1-1v-7h1.7c.46 0 .68-.57.33-.87L12.67 3.6c-.38-.34-.96-.34-1.34 0l-8.36 7.53c-.34.3-.13.87.33.87H5v7c0 .55.45 1 1 1h3c.55 0 1-.45 1-1z" />
                                </svg>
                            </a>

                            <span className="mx-4 h-auto font-medium">/</span>
                        </li>
                        <li className="inline-flex items-center">
                            <p className="text-black font-medium cursor-default hover:scale-105">Staff Details</p>
                            {/* <span className="mx-4 h-auto text-gray-400 font-medium">/</span> */}
                        </li>
                    </ul>
                </div>
                <div className="container mx-auto p-4 containerBoxShadow">
                    <div className="flex flex-col md:flex-row items-center justify-between mb-6">
                        <div className="w-full md:w-2/3">
                            <h2 className="md:text-3xl font-bold text-xl mb-2 md:mb-0">
                                Admin:
                                {userDetails && userDetails.length > 0 && userDetails[0].schoolId}
                            </h2>
                            <h4 className="mb-2 font-bold text-xl">Staff Details</h4>
                        </div>
                    </div>

                    <div className="md:flex sticky top-0 lg:z-50 bg-white mb-4">
                        <div
                            className={`flex-1 cursor-pointer text-center text-xl pb-2 focus:outline-none ${
                                activeTab === "ClassTeacher" ? "border-b-2 border-blue-500 text-blue-500" : "text-gray-700"
                            } transition duration-300 ease-in-out focus:border-blue-500`}
                            onClick={() => {
                                handleTabChange("ClassTeacher");
                                setPassKey("Class Teacher");
                            }}
                        >
                            Class Teacher
                        </div>
                        <div
                            className={`flex-1 cursor-pointer text-center text-xl pb-2 focus:outline-none ${
                                activeTab === "Teacher" ? "border-b-2 border-blue-500 text-blue-500" : "text-gray-700"
                            } transition duration-300 ease-in-out focus:border-blue-500`}
                            onClick={() => {
                                handleTabChange("Teacher");
                                setPassKey("Teacher");
                            }}
                        >
                            Teacher
                        </div>
                        <div
                            className={`flex-1 cursor-pointer text-center text-xl pb-2 focus:outline-none ${
                                activeTab === "NonTeaching" ? "border-b-2 border-blue-500 text-blue-500" : "text-gray-700"
                            } transition duration-300 ease-in-out focus:border-blue-500`}
                            onClick={() => {
                                handleTabChange("NonTeaching");
                                setPassKey("Non Teaching");
                            }}
                        >
                            Non-Teaching
                        </div>
                        <div
                            className={`flex-1 cursor-pointer text-center text-xl pb-2 focus:outline-none ${
                                activeTab === "Helpers" ? "border-b-2 border-blue-500 text-blue-500" : "text-gray-700"
                            } transition duration-300 ease-in-out focus:border-blue-500`}
                            onClick={() => {
                                handleTabChange("Helpers");
                                setPassKey("Helpers");
                            }}
                        >
                            Helpers
                        </div>
                    </div>

                    <div className="w-full overflow-y-auto md:max-h-96">
                        {/* Render content based on the active tab */}
                        {activeTab === "ClassTeacher" && (
                            <div className="bg-gray-100 p-4 rounded-md">
                                <StaffTabContent staffDetailsList={staffDetailsList} />
                            </div>
                        )}
                        {activeTab === "Teacher" && (
                            <div className="bg-gray-100 p-4 rounded-md">
                                {/* Content for Teacher tab */}
                                <StaffTabContent staffDetailsList={staffDetailsList} />
                            </div>
                        )}
                        {activeTab === "NonTeaching" && (
                            <div className="bg-gray-100 p-4 rounded-md">
                                {/* Content for Non-Teaching tab */}
                                <StaffTabContent staffDetailsList={staffDetailsList} />
                            </div>
                        )}
                        {activeTab === "Helpers" && (
                            <div className="bg-gray-100 p-4 rounded-md">
                                {/* Content for Helpers tab */}
                                <StaffTabContent staffDetailsList={staffDetailsList} />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default StaffDetails;
