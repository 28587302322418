import React, { useState, useEffect, useCallback } from "react";
import { FaCheckCircle, FaCloudUploadAlt, FaTimesCircle } from "react-icons/fa";
import { useParams } from "react-router-dom";
import { getAttendanceListDetail } from "../../API/getAttendanceList";
import Spinner from "../../components/Spinner";
import useSidebar from "../../hooks/useSidebar";

import { getStudentAbsentList } from "../../API/getStudentAbsentList";

const CTAbsantees = () => {
  const [studentsList, setStudentsList] = useState([]);

  const [absantees, setAbsantees] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const [loading, setLoading] = useState(true);

  useState(null);
  const [filteredAttendance, setFilteredAttendance] = useState([]);

  const { customId, keyName } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      const users = JSON.parse(localStorage.getItem("registeredUsers")) || [];

      if (customId) {
        const decodedData = JSON.parse(atob(customId));
        let matchedItem = [];

        users.forEach((innerArray) => {
          const [userData] = innerArray;

          if (
            userData.userId === decodedData.userId &&
            userData.userType === decodedData.userType &&
            userData.schoolId === decodedData.schoolId &&
            userData.year === decodedData.year
          ) {
            matchedItem.push(userData);
          }
        });

        const result = await getStudentAbsentList(
          decodedData.userId,
          decodedData.schoolId,
          decodedData.userType,
          decodedData.year
        );

        console.log("STUDENT ATTENDANCE RESULT", result);
        setAbsantees(result);

        setLoading(false); // Set loading to false when data is fetched
      }
    };

    fetchData();
  }, [customId]);

  const handleSearch = (value) => {
    setSearchTerm(value);

    if (value.trim() === "") {
      setFilteredAttendance([]);
    } else {
      const filtered = studentsList.filter(
        (student) =>
          student.SNAME.toLowerCase().includes(value.toLowerCase()) ||
          student.ADMNO.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredAttendance(filtered);
    }
  };

  return (
    <>
      <div className="container mx-auto p-4">
        {loading ? (
          <Spinner />
        ) : (
          <>
            <div className="flex justify-between items-center">
              <h2 className="md:text-xl text-sm font-semibold mb-4">
                Attendance Marking System
              </h2>
              <input
                type="text"
                placeholder="Search"
                className="border md:w-80 w-44 border-gray-300 rounded px-1 py-1   md:px-3 md:py-3"
                value={searchTerm}
                onChange={(e) => handleSearch(e.target.value)}
              />
            </div>

            <div className="bg-white shadow-md rounded-lg overflow-x-auto">
              <table className="min-w-full mt-4 overflow-x-auto">
                <thead>
                  <tr className="bg-blue-500 px-3 py-6 text-white">
                    <th className="md:py-3 md:px-4 py-1 px-2 text-left text-sm font-semibold">
                      Adm No
                    </th>
                    <th className="md:py-3 md:px-4 py-1 px-2 text-left font-semibold text-sm">
                      Student Name
                    </th>
                    {/* <th className="py-3 px-4 text-left font-semibold">Father Name</th> */}
                    <th className="md:py-3 md:px-4 py-1 px-2 text-left font-semibold text-sm">
                      Attendance
                    </th>
                  </tr>
                </thead>
                <tbody className="relative">
                  {absantees.length > 0 &&
                    absantees.map((student) => (
                      <tr key={student.id} className="border-b">
                        <td className="py-2 text-xs md:px-4 px-2 font-semibold">
                          {student.ADMNO}
                        </td>
                        <td className="py-2 text-xs md:px-4 px-2 font-semibold">
                          {student.SNAME} <br /> / {student.FATHERNAME}
                        </td>
                        {/* <td className="py-2 text-xs px-4 font-semibold">{student.FATHERNAME}</td> */}
                        <td className="py-2 text-xs md:px-4 px-2 font-bold">
                          {student.AttType}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default CTAbsantees;
