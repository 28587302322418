import React, { useState, useRef, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getUserDataFromLocalByKey } from "../../API/getStudentInfoByKeyLocal";
import { IoCloseCircle } from "react-icons/io5";
import Navbar from "../../components/Navbar";
import Sidebar from "../../components/Sidebar";
import { useSidebar } from "../../context/SidebarContext";
import Spinner from "../../components/Spinner";
import { adminUpdatePoll } from "../../API/adminUpdatePollAPI";

function AdminPollSubmit() {
  const {
    customId,
    Rcdid,
    SubjTo,
    Title,
    Question,
    NofOpt,
    Opt1,
    Opt2,
    Opt3,
    Opt4,
    Locked,
    Over,
    Dropped,
    Opt1Count,
    Opt2Count,
    Opt3Count,
    Opt4Count,
  } = useParams();
  const [userDetails, setUserDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [initialResourceUrl, setInitialResourceUrl] = useState(null);
  const [data, setData] = useState(true);
  const [pollTitle, setPollTitle] = useState("");
  const [pollQuestion, setPollQuestion] = useState("");
  const [pollNoOption, setPollNoOption] = useState("");
  const [optionOne, setOptionOne] = useState("");
  const [optionTwo, setOptionTwo] = useState("");
  const [optionThree, setOptionThree] = useState("");
  const [optionFour, setOptionFour] = useState("");
  const [pollOver, setPollOver] = useState(false);
  const [pollLocked, setPollLocked] = useState(false);
  const [pollDropped, setPollDropped] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [rcdid, setRcdid] = useState("");
  const { isSidebarOpen, currentSidebarTab, toggleSidebar, setIsSidebarOpen } =
    useSidebar();

  const navigate = useNavigate();

  const [checkboxes, setCheckboxes] = useState({
    checkbox1: false,
    checkbox2: false,
    checkbox3: false,
  });

  useEffect(() => {
    if (
      Rcdid ||
      SubjTo ||
      Title ||
      Question ||
      NofOpt ||
      Opt1 ||
      Opt2 ||
      Opt3 ||
      Opt4 ||
      Locked ||
      Over ||
      Dropped
    ) {
      setRcdid(Rcdid);
      setSelectedOption(SubjTo);
      setPollTitle(Title);
      setPollQuestion(Question);
      setPollNoOption(NofOpt);
      setOptionOne(Opt1 == "null" ? "" : Opt1);
      setOptionTwo(Opt2 == "null" ? "" : Opt2);
      setOptionThree(Opt3 == "null" ? "" : Opt3);
      setOptionFour(Opt4 == "null" ? "" : Opt4);

      if (Locked === "YES") {
        setPollLocked(true);
      } else {
        setPollLocked(false);
      }
      if (Over === "YES") {
        setPollOver(true);
      } else {
        setPollOver(false);
      }
      if (Dropped === "YES") {
        setPollDropped(true);
      } else {
        setPollDropped(false);
      }
    }
  }, []);

  const toggleCheckbox = (checkboxName) => {
    // Update the corresponding state variable based on checkboxName
    switch (checkboxName) {
      case "checkbox1":
        setPollLocked((prevValue) => !prevValue);
        break;
      case "checkbox2":
        setPollOver((prevValue) => !prevValue);
        break;
      case "checkbox3":
        setPollDropped((prevValue) => !prevValue);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const users = JSON.parse(localStorage.getItem("registeredUsers")) || [];

      if (customId) {
        const decodedData = JSON.parse(atob(customId));
        let matchedItem = [];

        users.forEach((innerArray) => {
          const [userData] = innerArray;

          if (
            userData.userId === decodedData.userId &&
            userData.userType === decodedData.userType &&
            userData.schoolId === decodedData.schoolId &&
            userData.year === decodedData.year
          ) {
            matchedItem.push(userData);
          }
        });

        const keyval = "webResourceURL";
        const dataUrl = await getUserDataFromLocalByKey(
          decodedData.userId,
          decodedData.schoolId,
          decodedData.userType,
          decodedData.year,
          keyval
        );
        setInitialResourceUrl(dataUrl);
        setLoading(false);
        setUserDetails(matchedItem);
      }
    };

    fetchData();
  }, [customId]);

  const validateOptions = () => {
    const selectedOptionNumber = parseInt(pollNoOption, 10);

    // Check if the selected number of options is greater than or equal to 2
    if (selectedOptionNumber >= 2) {
      // Create an array of options
      const options = [optionOne, optionTwo, optionThree, optionFour].slice(
        0,
        selectedOptionNumber
      );

      // Check if all selected options are filled
      const isOptionsFilled = options.every((option, index) => {
        return option.trim() !== "";
      });

      // If any option is not filled, show a warning and clear values for options beyond the selected number
      if (!isOptionsFilled) {
        toast.warning("Fill in all the selected options.");

        return false;
      }
    } else {
      // Show a warning if the selected number of options is less than 2
      toast.warning("Select at least 2 options.");
      return false;
    }

    // If the selected number of options is 3, check for additional conditions
    if (selectedOptionNumber === 2) {
      // Check if user has provided values for only two options
      if (optionThree && optionFour) {
        toast.warning("Provide values only for the selected options.");
        setOptionThree("");
        setOptionFour("");
        return false;
      }
    }

    if (selectedOptionNumber === 3) {
      if (optionFour) {
        toast.warning("Provide values only for the selected options.");
        setOptionFour(""); // Clear the value for option4
        return false;
      }
    }

    return true;
  };

  console.log(selectedOption, 994646);

  const handleClearInput = (setter) => {
    setter(""); // Clear the corresponding state variable
  };

  const handleSubmit = async () => {
    if (selectedOption == "") {
      return toast.warning("Select any options");
    }
    if (pollTitle == "") {
      return toast.warning("Enter Poll Title");
    }
    if (pollQuestion == "") {
      return toast.warning("Enter Poll Question");
    }
    if (pollNoOption == "") {
      return toast.warning("Enter No of Options");
    }

    const isValidOptions = validateOptions();

    if (!isValidOptions) {
      return;
    }

    // console.log(optionOne, optionTwo, optionThree, optionFour);

    if (userDetails && userDetails.length > 0) {
      const { userId, schoolId, userType, year } = userDetails[0];

      //console.log(optionOne, optionTwo, optionThree, optionFour);

      const result = await adminUpdatePoll(
        userId,
        schoolId,
        userType,
        year,
        selectedOption,
        pollTitle,
        pollQuestion,
        pollNoOption,
        optionOne ? optionOne : "",
        optionTwo ? optionTwo : "",
        optionThree ? optionThree : "",
        optionFour ? optionFour : "",
        pollLocked ? "YES" : "NO",
        pollOver ? "YES" : "NO",
        pollDropped ? "YES" : "NO",
        rcdid == "null" ? "" : rcdid
      );
      toast.success(result[0].ItemKeyName);
      console.log(`Result after Submit:`, result);
      navigate(-1);
      setSelectedOption("");
      setPollTitle("");
      setPollQuestion("");
      setPollNoOption("");
      setOptionOne("");
      setOptionTwo("");
      setOptionThree("");
      setOptionFour("");
      setPollDropped(false);
      setPollLocked(false);
      setPollOver(false);
    }
  };

  return (
    <>
      {data && (
        <Navbar
          data={userDetails}
          // isOpen={sidebarVisible}
          toggleSidebar={toggleSidebar}
        />
      )}

      <div className="flex">
        {data && <Sidebar data={userDetails} />}

        <div
          className={`w-full absolute ${
            isSidebarOpen ? "lg:w-4/6 xl:w-9/12" : "w-full"
          } sm:w-11/12 right-0 top-16 mx-auto   p-4 md:p-6 lg:p-8 xl:p-10`}
        >
          <div className="flex items-center justify-between mb-4">
            <div className="w-2/3">
              <h3 className="md:text-3xl font-semibold mb-4">
                Admin :
                {userDetails &&
                  userDetails.length > 0 &&
                  userDetails[0].schoolId}
              </h3>
              <h3 className="md:text-3xl font-semibold mb-4">Add Poll</h3>
            </div>
          </div>

          <div className="border border-black shadow-lg p-3 ">
            <div className="mb-4 text-center  ">
              <div className="relative inline-block">
                <select
                  value={selectedOption}
                  onChange={(e) => setSelectedOption(e.target.value)}
                  required
                  className="border border-gray-200 p-2 rounded-md appearance-none focus:border-blue-500 w-48"
                >
                  {/* Add options here */}
                  <option selected value=" ">
                    --Select--
                  </option>
                  <option value="Parent">Parents</option>
                  <option value="Teachers">Teachers</option>
                  <option value="ParentsAndTeachers">
                    Parent And Teachers
                  </option>
                  {/* Add more options as needed */}
                </select>
                <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
                  <svg
                    className="h-5 w-5 text-gray-700"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M19 9l-7 7-7-7"
                    />
                  </svg>
                </div>
              </div>
            </div>

            {/* Input Fields */}
            <div className="mb-4 relative">
              <label className="block text-gray-700 text-sm font-bold mb-2">
                Enter Poll Title
              </label>
              <div className="relative">
                <input
                  type="text"
                  value={pollTitle}
                  required
                  onChange={(e) => setPollTitle(e.target.value)}
                  placeholder="Type Poll Title"
                  className="w-full border border-gray-300 p-2 rounded-md pr-10"
                />
                <button
                  onClick={() => handleClearInput(setPollTitle)}
                  className="absolute inset-y-0 right-0 flex items-center  pr-2 text-red-500"
                >
                  <IoCloseCircle size={24} />
                </button>
              </div>
            </div>
            <div className="mb-4 relative">
              <label className="block text-gray-700 text-sm font-bold mb-2">
                Enter Polls Question
              </label>
              <div className="relative">
                <input
                  type="text"
                  value={pollQuestion}
                  required
                  onChange={(e) => setPollQuestion(e.target.value)}
                  placeholder="Type polls question"
                  className="w-full border border-gray-300 p-2 rounded-md pr-10"
                />
                <button
                  onClick={() => handleClearInput(setPollQuestion)}
                  className="absolute inset-y-0 right-0 flex items-center  pr-2 text-red-500"
                >
                  <IoCloseCircle size={24} />
                </button>
              </div>
            </div>
            <div className="mb-4 relative">
              <label className="block text-gray-700 text-sm font-bold mb-2">
                No of Options
              </label>
              <div className="relative">
                <input
                  type="number"
                  min={2}
                  max={4}
                  required
                  value={pollNoOption}
                  onChange={(e) => setPollNoOption(e.target.value)}
                  placeholder="No of Options"
                  className="w-full border border-gray-300 p-2 rounded-md pr-10"
                />
                <button
                  onClick={() => handleClearInput(setPollNoOption)}
                  className="absolute inset-y-0 right-0 flex items-center  pr-2 text-red-500"
                >
                  <IoCloseCircle size={24} />
                </button>
              </div>
            </div>
            <div className="mb-4 relative">
              <label className="block text-gray-700 text-sm font-bold mb-2">
                Option 1
              </label>
              <div className="relative">
                <input
                  type="text"
                  value={optionOne}
                  onChange={(e) => setOptionOne(e.target.value)}
                  placeholder="Enter Option 1"
                  className="w-full border border-gray-300 p-2 rounded-md pr-10"
                />
                <button
                  onClick={() => handleClearInput(setOptionOne)}
                  className="absolute inset-y-0 right-0 flex items-center  pr-2 text-red-500"
                >
                  <IoCloseCircle size={24} />
                </button>
              </div>
            </div>
            <div className="mb-4 relative">
              <label className="block text-gray-700 text-sm font-bold mb-2">
                Option 2
              </label>
              <div className="relative">
                <input
                  type="text"
                  value={optionTwo}
                  onChange={(e) => setOptionTwo(e.target.value)}
                  placeholder="Enter Option 2"
                  className="w-full border border-gray-300 p-2 rounded-md pr-10"
                />
                <button
                  onClick={() => handleClearInput(setOptionTwo)}
                  className="absolute inset-y-0 right-0 flex items-center  pr-2 text-red-500"
                >
                  <IoCloseCircle size={24} />
                </button>
              </div>
            </div>
            <div className="mb-4 relative">
              <label className="block text-gray-700 text-sm font-bold mb-2">
                Option 3
              </label>
              <div className="relative">
                <input
                  type="text"
                  value={optionThree}
                  onChange={(e) => setOptionThree(e.target.value)}
                  placeholder="Enter Option 3"
                  className="w-full border border-gray-300 p-2 rounded-md pr-10"
                />
                <button
                  onClick={() => handleClearInput(setOptionThree)}
                  className="absolute inset-y-0 right-0 flex items-center  pr-2 text-red-500"
                >
                  <IoCloseCircle size={24} />
                </button>
              </div>
            </div>
            <div className="mb-4 relative">
              <label className="block text-gray-700 text-sm font-bold mb-2">
                Option 4
              </label>
              <div className="relative">
                <input
                  type="text"
                  value={optionFour}
                  onChange={(e) => setOptionFour(e.target.value)}
                  placeholder="Enter Option 4"
                  className="w-full border border-gray-300 p-2 rounded-md pr-10"
                />
                <button
                  onClick={() => handleClearInput(setOptionFour)}
                  className="absolute inset-y-0 right-0 flex items-center  pr-2 text-red-500"
                >
                  <IoCloseCircle size={24} />
                </button>
              </div>
            </div>

            {/* Checkboxes */}
            <div className="mb-4 text-center">
              <label className="block text-gray-700 text-sm font-bold mb-2">
                Select Options
              </label>
              <div className="md:flex items-center justify-center">
                <div className="mr-4">
                  <input
                    type="checkbox"
                    id="checkbox1"
                    checked={pollLocked}
                    onChange={() => toggleCheckbox("checkbox1")}
                  />
                  <label htmlFor="checkbox1" className="ml-2 text-lg">
                    Poll Locked
                  </label>
                </div>

                <div className="mr-4">
                  <input
                    type="checkbox"
                    id="checkbox2"
                    checked={pollOver}
                    onChange={() => toggleCheckbox("checkbox2")}
                  />
                  <label htmlFor="checkbox2" className="ml-2 text-lg">
                    Poll Over
                  </label>
                </div>

                <div>
                  <input
                    type="checkbox"
                    id="checkbox3"
                    checked={pollDropped}
                    onChange={() => toggleCheckbox("checkbox3")}
                  />
                  <label htmlFor="checkbox3" className="ml-2 text-lg">
                    Poll Dropped
                  </label>
                </div>
              </div>
            </div>

            <div className="flex justify-center">
              <button
                onClick={handleSubmit}
                className="bg-green-500 text-white font-semibold px-4 py-2 rounded shadow-md mr-4 focus:outline-none"
              >
                Submit
              </button>
            </div>
          </div>

          {/* Dropdown */}
        </div>
      </div>
    </>
  );
}

export default AdminPollSubmit;
