import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import useSidebar from "../../hooks/useSidebar";
import Spinner from "../../components/Spinner";
import { getUserDataFromLocalByKey } from "../../API/getStudentInfoByKeyLocal";
import AdminSearchForm from "../Admin/AdminSearchForm";

function AdminNotificationSearchPage() {
  const { customId } = useParams();
  const [userDetails, setUserDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [initialResourceUrl, setInitialResourceUrl] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const users = JSON.parse(localStorage.getItem("registeredUsers")) || [];

      if (customId) {
        const decodedData = JSON.parse(atob(customId));
        let matchedItem = [];

        users.forEach((innerArray) => {
          const [userData] = innerArray;

          if (
            userData.userId === decodedData.userId &&
            userData.userType === decodedData.userType &&
            userData.schoolId === decodedData.schoolId &&
            userData.year === decodedData.year
          ) {
            matchedItem.push(userData);
          }
        });

        const keyval = "webResourceURL";
        const dataUrl = await getUserDataFromLocalByKey(
          decodedData.userId,
          decodedData.schoolId,
          decodedData.userType,
          decodedData.year,
          keyval
        );
        setInitialResourceUrl(dataUrl);
        setLoading(false);
        setUserDetails(matchedItem);
      }
    };

    fetchData();
  }, [customId]);

  const handleFormData = (formData) => {
    const urlBase = `/admin/u/${customId}/`;
    const queryParams = [];

    // Check and add parameters based on conditions
    queryParams.push(formData.formattedFromDate ? formData.formattedFromDate : "null");

    queryParams.push(formData.formattedToDate ? formData.formattedToDate : "null");
    queryParams.push(encodeURIComponent(formData.message ? formData.message : "null"));
    queryParams.push(encodeURIComponent(formData.selectedClass ? formData.selectedClass : "null"));
    queryParams.push(encodeURIComponent(formData.selectedSection ? formData.selectedSection : "null"));
    queryParams.push("NotificationList");

    const url = urlBase + queryParams.join("/");
    console.log(url);
    navigate(url);
  };

  return (
    <>
      <div className="md:mt-6 mt-10">
        <div className="p-2 mb-3 flex items-center w-fit flex-wrap">
          <ul className="flex items-center">
            <li className="inline-flex items-center cursor-default">
              <a href="/" className="text-black font-medium hover:scale-105">
                <svg
                  className="w-5 cursor-default h-auto fill-current mx-2"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="#000000"
                >
                  <path d="M0 0h24v24H0V0z" fill="none" />
                  <path d="M10 19v-5h4v5c0 .55.45 1 1 1h3c.55 0 1-.45 1-1v-7h1.7c.46 0 .68-.57.33-.87L12.67 3.6c-.38-.34-.96-.34-1.34 0l-8.36 7.53c-.34.3-.13.87.33.87H5v7c0 .55.45 1 1 1h3c.55 0 1-.45 1-1z" />
                </svg>
              </a>

              <span className="mx-4 h-auto font-medium">/</span>
            </li>
            <li className="inline-flex items-center">
              <p className="text-black font-medium cursor-default hover:scale-105">Notification</p>
              {/* <span className="mx-4 h-auto text-gray-400 font-medium">/</span> */}
            </li>
          </ul>
        </div>
        <div className="container mx-auto p-4 containerBoxShadow">
          <div className="flex flex-col md:flex-row items-center justify-between mb-4">
            <div className="md:px-10 px-5">
              <h2 className=" md:text-2xl font-bold text-xl mb-2 md:mb-0">
                Admin :{userDetails && userDetails.length > 0 && userDetails[0].schoolId}
              </h2>
              <h4 className="mb-2 font-bold">Notification Search </h4>
            </div>
          </div>

          <div className=" md:px-10 px-5 md:w-[70%] w-full shadow-md shadow-gray-400 rounded-md mt-2 lg:mt-12 bg-white mx-auto ">
            <div className="w-full bg-blue-700 text-white font-semibold text-xl  text-center px-3 py-4">Search In Notification</div>
            <AdminSearchForm onSubmit={handleFormData} />
          </div>
        </div>
      </div>
    </>
  );
}

export default AdminNotificationSearchPage;
