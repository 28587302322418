// src/components/Accordion.js
import React, { useEffect, useState } from "react";
import VerificationModal from "../pages/ClassTeacher/HomeWorkVerficationModal";
import { acceptHomeWork } from "../API/AcceptHomeWork";
import acceptIcon from "../assets/png/checked.png";
import auditIcon from "../assets/png/audit.png";
import { toast } from "react-toastify";
import { updateStudentHomeWorkVerify } from "../API/updateStudentHomeWorkVerify";

const Accordion = ({
  title,
  data,
  isActive,
  onToggle,
  preUrl,
  resourceUrl,
  setIsSubmit,
  isSubmit
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleAccept = async (STDHWID) => {
    try {
      setLoading(true);
      const response = await acceptHomeWork(preUrl, STDHWID);
    
      toast.success(response[0].InfoField);
      setLoading(false);
      setIsSubmit(!isSubmit)
    } catch (error) {
      console.log(error);
    }
  };

  const handleVerification = (item) => {
    setSelectedFile(item);
    setIsModalOpen(true);
  };

 

  const handleModalSubmit = async (data) => {
    setLoading(true);
    const { remarks, marks, stdhwid, grade } = data;
    console.log("Verification data:", data);
    try {
      const response = await updateStudentHomeWorkVerify(
        remarks,
        grade,
        marks,
        stdhwid,
        preUrl
      );
      toast.success(response[0].InfoField);
      setLoading(false);
      setIsModalOpen(false);
      setIsSubmit(!isSubmit)
    } catch (error) {}
    // Handle submission logic here
  };

  return (
    <div className="bg-white shadow-md rounded-lg mb-4 overflow-hidden">
      <div
        className="flex justify-between items-center p-4 bg-blue-600 cursor-pointer hover:bg-blue-800 transition-colors duration-300"
        onClick={onToggle}
      >
        <h2 className="text-lg font-semibold text-white">{title} {`(${data.length})`}</h2>
        <span className="text-2xl text-white">{isActive ? "−" : "+"}</span>
      </div>
      {isActive && (
        <div className="p-4 border">
          <table className="min-w-full divide-y divide-gray-200 border border-black">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Adm No
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Student Name
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Upload Status
                </th>
                {title === "Accept Pending" && (
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Accept
                  </th>
                )}
                {title === "Verification Pending" && (
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Verify
                  </th>
                )}
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {data.map((item, index) => (
                <tr
                  key={index}
                  className={index % 2 === 0 ? "bg-white" : "bg-gray-50"}
                >
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                    {item.AdmNo}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {item.Sname}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    <span
                      className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${getStatusColor(
                        item.UploadSts
                      )}`}
                    >
                      {item.UploadSts}
                    </span>
                  </td>
                  {title === "Accept Pending" && (
                    <td className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    <div className="relative inline-block">
                      <div className="absolute inset-0 flex items-center justify-center rounded-full border-4 p-2 border-green-500 opacity-50 animate-bounce hover:opacity-75 transition-opacity duration-300"></div>
                      <img
                        onClick={() => handleAccept(item.STDHWID)}
                        className="relative h-8 w-8 animate-bounce cursor-pointer transition-transform duration-300 hover:scale-105"
                        src={acceptIcon}
                        alt="Accept"
                      />
                    </div>
                  </td>
                  
                  )}
                  {title === "Verification Pending" && (
                  <td className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  <div className="relative inline-block">
                    <div className="absolute inset-0 flex items-center justify-center rounded-full border-4 border-yellow-500 opacity-50 animate-bounce hover:opacity-75 transition-opacity duration-300"></div>
                    <img
                      onClick={() => handleVerification(item)}
                      className="relative h-8 w-8 animate-bounce cursor-pointer transition-transform duration-300 hover:scale-105"
                      src={auditIcon}
                      alt="Verify"
                    />
                  </div>
                </td>
                
                 
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      <VerificationModal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        file={selectedFile}
        onSubmit={handleModalSubmit}
        resourceUrl={resourceUrl}
      />
    </div>
  );
};

export default Accordion;
const getStatusColor = (status) => {
  switch (status.toLowerCase()) {
    case "uploaded":
      return "bg-green-100 text-green-800";
    case "pending":
      return "bg-yellow-100 text-yellow-800";
    case "failed":
      return "bg-red-100 text-red-800";
    default:
      return "bg-gray-100 text-gray-800";
  }
};
