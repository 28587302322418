import React, { useState, useRef, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { AiOutlineClose, AiOutlineSearch } from "react-icons/ai";
import Spinner from "../../components/Spinner";
import Navbar from "../../components/Navbar";
import Sidebar from "../../components/Sidebar";
// import useSidebar from "../../hooks/useSidebar";
import personicon from "../../assets/png/person-icon.png";
import { getUserDataFromLocalByKey } from "../../API/getStudentInfoByKeyLocal";
import { getStaffDetailsList } from "../../API/getStaffListAPI";
import { useSidebar } from "../../context/SidebarContext";

function CTComBWTeachersList({type}) {
  const { customId } = useParams();
  const [userDetails, setUserDetails] = useState(null);
  const [data, setData] = useState(true);
  const [ctName, setCtName] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredTeachersList, setFilteredTeachersList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [ctTeachersList, setCtTeacherskList] = useState([]);
  const [initialResourceUrl, setInitialResourceUrl] = useState(null);
  // const { sidebarVisible, toggleSidebar } = useSidebar();
  const { isSidebarOpen, currentSidebarTab, toggleSidebar,setIsSidebarOpen } = useSidebar();

  useEffect(() => {
    const fetchData = async () => {
      const users = JSON.parse(localStorage.getItem("registeredUsers")) || [];

      if (customId) {
        const decodedData = JSON.parse(atob(customId));
        let matchedItem = [];

        users.forEach((innerArray) => {
          const [userData] = innerArray;

          if (
            userData.userId === decodedData.userId &&
            userData.userType === decodedData.userType &&
            userData.schoolId === decodedData.schoolId &&
            userData.year === decodedData.year
          ) {
            matchedItem.push(userData);
          }
        });

        const result = await getStaffDetailsList(
          decodedData.userId,
          decodedData.schoolId,
          decodedData.userType,
          decodedData.year
        );

        const namekeyval = "EmployeeName";
        const username = await getUserDataFromLocalByKey(
          decodedData.userId,
          decodedData.schoolId,
          decodedData.userType,
          decodedData.year,
          namekeyval
        );

        const modifiedUsername = username.replace(/(?:MS|MR)\s*/g, "");

        setCtName(modifiedUsername);

        const keyval = "webResourceURL";
        const dataUrl = await getUserDataFromLocalByKey(
          decodedData.userId,
          decodedData.schoolId,
          decodedData.userType,
          decodedData.year,
          keyval
        );
        setInitialResourceUrl(dataUrl);

        console.log("URL RESULT", dataUrl);

        console.log("NOTIFICATION RESULT", result);
        const slicedResult = result.slice(1);

        setCtTeacherskList(slicedResult);
        setLoading(false);
        setUserDetails(matchedItem);
      }
    };

    fetchData();
  }, [customId]);

  const handleSearch = (value) => {
    setSearchTerm(value);

    if (value.trim() === "") {
      setFilteredTeachersList([]);
    } else {
      const filtered = ctTeachersList.filter((notification) =>
        notification.Head2.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredTeachersList(filtered);
    }
  };

  
  const notificationsToRender = searchTerm
    ? filteredTeachersList
    : ctTeachersList;

  return (
    <>
      {data && (
        <Navbar
          data={userDetails}
          // isOpen={sidebarVisible}
          toggleSidebar={toggleSidebar}
        />
      )}

      <div className="flex">
        { type!=="subTeacher" && <Sidebar data={userDetails} />}

        <div className={`w-full absolute ${isSidebarOpen?"lg:w-4/6 xl:w-9/12":"w-full"} sm:w-11/12 right-0 top-16 mx-auto   p-4 md:p-6 lg:p-8 xl:p-10`}>
          <div className="flex flex-col md:flex-row items-center justify-between mb-4">
            <div className="w-2/3">
              <h3 className="md:text-3xl font-bold text-xl mb-2 md:mb-0">
                Teacher : {ctName}
              </h3>
              <h4 className="mb-2 font-bold">Teachers List</h4>
            </div>

            <input
              type="text"
              placeholder="Enter Keyword"
              value={searchTerm}
              onChange={(e) => handleSearch(e.target.value)}
              className="border border-gray-600 md:w-80 w-full md:max-w-sm h-12 md:ml-4 md:px-5 px-2 py-1 rounded-md"
            />
          </div>
          <div className="container mx-auto p-4">
            {loading ? (
              <Spinner />
            ) : (
              <>
                <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-4">
                  {notificationsToRender && notificationsToRender.length > 0 ? (
                    notificationsToRender.map((notification) => (
                      <Link
                        to={`/classteacher/u/${customId}/${notification.Head2}/${notification.Head1}/${notification.Col4}/CTupdateComBWTeachersPage`}
                        onClick={""}
                        key={notification.id}
                      >
                        <div className="border border-gray-300 rounded-lg p-4 hover:shadow-md transition duration-300">
                          <div className="flex items-center">
                            <div className="w-1/3 sm:w-auto mr-4">
                              <img
                                // src={notification?.Col5 ? notification?.Col5 : personicon}
                                src={personicon}
                                alt={notification?.Head2}
                                className="w-20 h-20 sm:w-32 sm:h-32 object-cover rounded"
                              />
                            </div>

                            <div className="w-2/3">
                              <h3 className="text-lg font-semibold mb-2">
                                {notification?.Head2}
                              </h3>
                              <h4 className="text-lg font-semibold mb-2">
                                {notification?.Head1}
                              </h4>
                              <p className="text-gray-600">
                                {notification?.Col4}
                              </p>
                              <p className="text-gray-600">
                                {notification?.Head3}
                              </p>
                            </div>
                          </div>
                        
                        </div>
                      </Link>
                    ))
                  ) : (
                    <p className="text-center text-gray-500 mt-4">
                      No Data Found
                    </p>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default CTComBWTeachersList;
