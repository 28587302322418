import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Spinner from "../../components/Spinner";
import Navbar from "../../components/Navbar";
import Sidebar from "../../components/Sidebar";
import { useSidebar } from "../../context/SidebarContext";
import { getUserDataFromLocalByKey } from "../../API/getStudentInfoByKeyLocal";
import { getAdminFeeDCSList } from "../../API/getAdminFeeDCSAPI"; 

function FeeDCS() {
  const { customId } = useParams();
  const [userType, setUserType] = useState("");
  const [userDetails, setUserDetails] = useState(null);
  const [data, setData] = useState(true);
  const [loading, setLoading] = useState(true);
  const [initialResourceUrl, setInitialResourceUrl] = useState(null);
  const [ feeSummList, setFeeSummList ] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const users = JSON.parse(localStorage.getItem("registeredUsers")) || [];

      if (customId) {
        const decodedData = JSON.parse(atob(customId));
        let matchedItem = [];

        users.forEach((innerArray) => {
          const [userData] = innerArray;

          if (
            userData.userId === decodedData.userId &&
            userData.userType === decodedData.userType &&
            userData.schoolId === decodedData.schoolId &&
            userData.year === decodedData.year
          ) {
            matchedItem.push(userData);
          }
        });

        const result = await getAdminFeeDCSList(
            decodedData.userId,
            decodedData.schoolId,
            decodedData.userType,
            decodedData.year
          );

        const keyval = "webResourceURL";
        const dataUrl = await getUserDataFromLocalByKey(
          decodedData.userId,
          decodedData.schoolId,
          decodedData.userType,
          decodedData.year,
          keyval
        );
        setInitialResourceUrl(dataUrl);
        setLoading(false);
        setFeeSummList(result);
        setUserDetails(matchedItem);
      }
    };

    fetchData();
  }, [customId]);

  console.log(feeSummList);

  const { isSidebarOpen, currentSidebarTab, toggleSidebar,setIsSidebarOpen } = useSidebar();

  return (
    <>
      {data && (
        <Navbar
          data={userDetails}
          // isOpen={sidebarVisible}
          toggleSidebar={toggleSidebar}
        />
      )}

      <div className="flex">
        { data && <Sidebar data={userDetails} />}

        <div className="">
          {loading ? (
            <Spinner />
          ) : (
            <>
               <div className={`w-full absolute ${isSidebarOpen?"lg:w-4/6 xl:w-9/12":"w-full"} sm:w-11/12 right-0 top-16 mx-auto   p-4 md:p-6 lg:p-8 xl:p-10`}>
                <div className="flex items-center justify-between mb-4">
                  <div className="w-2/3">
                    <h2 className=" md:text-2xl font-bold text-xl mb-2 md:mb-0">
                      Admin :
                      {userDetails &&
                        userDetails.length > 0 &&
                        userDetails[0].schoolId}
                    </h2>
                    <h4 className="mb-2 font-bold">
                      Fee Summary
                    </h4>
                  </div>
                </div>

                <div className="table-container">
                {feeSummList.slice(1).map((category, index) => (
                    <table key={index} className="w-full table-auto border-collapse border border-gray-300">
                      <tbody>
                        <tr>
                          <td colSpan="2" className="bg-blue-500 border border-gray-500 p-2 text-center text-white font-bold">
                            {category.Head1 || "N/A"}
                          </td>
                        </tr>
                        <tr>
                          <th className="border border-gray-500 text-left w-2/4">Total Demand</th>
                          <td className="border border-gray-500 text-right">{category.Col1 || "-"}</td>
                        </tr>
                        <tr>
                          <th className="border border-gray-500 text-left">Total Collection</th>
                          <td className="border border-gray-500 text-right">{category.Col2 || "-"}</td>
                        </tr>
                        <tr>
                          <th className="border border-gray-500 text-left">Total Concession</th>
                          <td className="border border-gray-500 text-right">{category.Col3 || "-"}</td>
                        </tr>
                        <tr>
                          <th className="border border-gray-500 text-left">Total Dues</th>
                          <td className="border border-gray-500 text-right">{category.Col4 || "-"}</td>
                        </tr>
                      </tbody>
                    </table>
                  ))}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default FeeDCS;
