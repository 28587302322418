import { useState, useEffect } from 'react';

export default function useSidebar() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [sidebarVisible, setSidebarVisible] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    }

  }, []);

  useEffect(() => {
    if(windowWidth <= 768) {
      setSidebarVisible(false); 
    } else {
      setSidebarVisible(true);
    }
  }, [windowWidth]);
 
  return {
    windowWidth,
    sidebarVisible,
    toggleSidebar: () => setSidebarVisible(!sidebarVisible) 
  };
}