import React, { useEffect, useState } from "react";
import Navbar from "../../components/Navbar";
// import useSidebar from "../../hooks/useSidebar";
import { useParams } from "react-router-dom";

import Accordion from "../../components/Accordion";
import { getStudentHomeWorkListByHWID } from "../../API/GetStudentHomeWorkListByHWID";
import { getUserDataFromLocalByKey } from "../../API/getStudentInfoByKeyLocal";
import Spinner from "../../components/Spinner"


import { useSidebar } from "../../context/SidebarContext";
import Sidebar from "../../components/Sidebar";


const HomeWorkList = () => {
  const { customId, HWID } = useParams();
  const [data, setData] = useState(true);
  // const { sidebarVisible, toggleSidebar } = useSidebar();
  const [userDetails, setUserDetails] = useState(null);
  const [accordionData, setAccordionData] = useState([]);
  const [examList, setExamList] = useState([]);
  const [activeAccordion, setActiveAccordion] = useState(0);
  const [preUrl, setPreUrl] = useState("");
  const [resourceUrl, setResourceUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isSubmit,setIsSubmit]=useState(false)
  const { isSidebarOpen, currentSidebarTab, toggleSidebar,setIsSidebarOpen } = useSidebar();

  useEffect(() => {
    let isMounted = true; // to prevent state updates if the component is unmounted
    setLoading(true);
    const fetchData = async () => {
      try {
        const users = JSON.parse(localStorage.getItem("registeredUsers")) || [];

        if (customId) {
          const decodedData = JSON.parse(atob(customId));

          const matchedItem = users.find((innerArray) => {
            const [userData] = innerArray;
            return (
              userData.userId === decodedData.userId &&
              userData.userType === decodedData.userType &&
              userData.schoolId === decodedData.schoolId &&
              userData.year === decodedData.year
            );
          });

          if (matchedItem) {
            const response = await getStudentHomeWorkListByHWID(
              decodedData.userId,
              decodedData.schoolId,
              decodedData.userType,
              decodedData.year,
              HWID
            );
            if (isMounted) {
              setExamList(response[0]?.StdHomeWorkList || []);
            }

            const key = "webServiceURLAWSDOTNET";
            const returnUrl = await getUserDataFromLocalByKey(
              decodedData.userId,
              decodedData.schoolId,
              decodedData.userType,
              decodedData.year,
              key
            );

            if (isMounted) {
              setPreUrl(returnUrl);
            }

            const keyResource = "WebResourceAWSDOTNETURL";
            const dataResourceUrl = await getUserDataFromLocalByKey(
              decodedData.userId,
              decodedData.schoolId,
              decodedData.userType,
              decodedData.year,
              keyResource
            );
            if (isMounted) {
              setResourceUrl(dataResourceUrl);
            }

            if (isMounted) {
              setUserDetails(decodedData);
            }
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        // Handle the error (e.g., set an error state or show a message to the user)
      }
    };

    fetchData();
    setLoading(false);
    return () => {
      isMounted = false; // Cleanup function to prevent state updates on unmounted component
    };
  }, [customId, HWID,isSubmit]);

  const handleAccordionToggle = (index) => {
    setActiveAccordion(activeAccordion === index ? null : index);
  };

  useEffect(() => {
    setAccordionData([
      {
        index: 1,
        title: "Accept Pending",
        data: examList.filter(
          (item) => item.TchAccepted === false && item.UploadSts === "Found"
        ),
      },
      {
        index: 1,
        title: "Verification Pending",
        data: examList.filter(
          (item) => item.TchVerified === false && item.TchAccepted === true
        ),
      },
      {
        index: 1,
        title: "Verified",
        data: examList.filter(
          (item) => item.TchVerified === true
        ),
      },
      { index: 0, title: "All", data: examList },
      {
        index: 1,
        title: "Uploaded",
        data: examList.filter((item) => item.UploadSts === "Found"),
      },
      {
        index: 1,
        title: "Not Uploaded",
        data: examList.filter((item) => item.UploadSts === "No Found"),
      },
    ]);
  }, [examList]);

  console.log(examList,555555);
  


  return (
    <>
      {data && (
        <Navbar
          data={userDetails}        
          toggleSidebar={toggleSidebar}
        />
      )}
      {data && <Sidebar data={userDetails} />}

      <div className={`w-full absolute ${isSidebarOpen?"lg:w-4/6 xl:w-4/5":"w-full"} sm:w-11/12 right-0 top-16 mx-auto   p-4 md:p-6 lg:p-8 xl:p-10`}>
        <div className="accordion w-11/12">
          {loading ? (
            <Spinner />
          ) : (
            accordionData.map((section, index) => (
              <Accordion
                key={index}
                title={section.title}
                data={section.data}
                isActive={activeAccordion === index}
                onToggle={() => handleAccordionToggle(index)}
                preUrl={preUrl}
                resourceUrl={resourceUrl}
                setIsSubmit={setIsSubmit}
                isSubmit={isSubmit}
                
              />
            ))
          )}
        </div>
        <style jsx>{`
          .accordion {
            max-width: 1000px;
            margin: 2rem auto;
            border-radius: 5px;
            overflow: hidden;
            // box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
          }
        `}</style>
      </div>
    </>
  );
};

export default HomeWorkList;
