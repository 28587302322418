import React, { useState, useEffect, useRef, useMemo } from "react";
import { useParams } from "react-router-dom";
import { getStudentTimeTableListDetail } from "../../API/getStudentTimeTableList";
import { getUserDataFromLocalByKey } from "../../API/getStudentInfoByKeyLocal";
import Spinner from "../../components/Spinner";
import useSidebar from "../../hooks/useSidebar";
function StudentTimeTable() {
  const { customId } = useParams();
  const [userDetails, setUserDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [timeTableList, setTimeTableList] = useState([]);
  const [initialResourceUrl, setInitialResourceUrl] = useState(null);
  const daysOfWeek = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  const prevCustomIdRef = useRef();

  useEffect(() => {
    const fetchData = async () => {
      const users = JSON.parse(localStorage.getItem("registeredUsers")) || [];

      if (customId && customId !== prevCustomIdRef.current) {
        prevCustomIdRef.current = customId;

        const decodedData = JSON.parse(atob(customId));
        let matchedItem = [];

        users.forEach((innerArray) => {
          const [userData] = innerArray;

          if (
            userData.userId === decodedData.userId &&
            userData.userType === decodedData.userType &&
            userData.schoolId === decodedData.schoolId &&
            userData.year === decodedData.year
          ) {
            matchedItem.push(userData);
          }
        });

        const result = await getStudentTimeTableListDetail(
          decodedData.userId,
          decodedData.schoolId,
          decodedData.userType,
          decodedData.year
        );

        console.log("RESULT TIME TABLE", result);

        const keyval = "webResourceURL";
        const dataUrl = await getUserDataFromLocalByKey(
          decodedData.userId,
          decodedData.schoolId,
          decodedData.userType,
          decodedData.year,
          keyval
        );
        setInitialResourceUrl(dataUrl);

        setTimeTableList(result);
        setLoading(false);
        setUserDetails(matchedItem);
      }
    };

    fetchData();
  }, [customId]);

  const { sidebarVisible, toggleSidebar } = useSidebar();

  return (
    <div className="">
      <h1 className="md:text-3xl sm:text-xl font-semibold">
        Student Time Table
      </h1>


      {
        loading ? (

           <Spinner />

        ) : (
         <>
        
         <div className="table-container overflow-x-auto mt-4 ">
        {timeTableList.length > 0 ? (
          <table className="table-auto">
            <thead>
              <tr>
                <th className="border sticky left-0 top-0 bg-blue-500 text-white px-4 py-2"></th>
                <th className="border bg-blue-500 text-white px-4 py-2 h-16 w-40">
                  1
                </th>
                <th className="border bg-blue-500 text-white px-4 py-2 h-16 w-40">
                  2
                </th>
                <th className="border bg-blue-500 text-white px-4 py-2 h-16 w-40">
                  3
                </th>
                <th className="border bg-blue-500 text-white px-4 py-2 h-16 w-40">
                  4
                </th>
                <th className="border bg-blue-500 text-white px-4 py-2 h-16 w-40">
                  5
                </th>
                <th className="border bg-blue-500 text-white px-4 py-2 h-16 w-40">
                  6
                </th>
                <th className="border bg-blue-500 text-white px-4 py-2 h-16 w-40">
                  7
                </th>
                <th className="border bg-blue-500 text-white px-4 py-2 h-16 w-40">
                  8
                </th>
              </tr>
            </thead>
            <tbody>
              {/* Rows */}
              {daysOfWeek &&
                daysOfWeek.map((day, rowIndex) => (
                  <tr
                    key={rowIndex}
                    className={`h-16 ${
                      rowIndex % 2 === 0 ? "bg-gray-400" : ""
                    }`}
                  >
                    <td className="sticky left-0 top-0 border px-4 py-2 bg-blue-500 text-white">
                      {day}
                    </td>
                    {Array.from({ length: 8 }, (_, columnIndex) => (
                      <td key={columnIndex} className="border px-4 py-2">
                        {/* Your content for each cell */}
                        {timeTableList.length > 0 &&
                          timeTableList
                            .filter((item) => item.Day === day) // Filter items related to the current day
                            .map((item) => (
                              <div key={item.ActionType}>
                                {item[`Col${columnIndex + 1}`]}{" "}
                                {/* Display the value from Col1, Col2, etc. */}
                              </div>
                            ))}
                      </td>
                    ))}
                  </tr>
                ))}
            </tbody>
          </table>
        ) : (
          <div>No timetable available</div>
        )}
      </div>
         
         </>
        )

      






        }


    </div>
  );
}

export default StudentTimeTable;
