import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import DatePicker from "react-datepicker"; // Import DatePicker
import "react-datepicker/dist/react-datepicker.css";
import Spinner from "../../components/Spinner";
import { getUserDataFromLocalByKey } from "../../API/getStudentInfoByKeyLocal";
import { getClassSelectionList } from "../../API/getClassSelectionListAPI";
import { getAdminFeeCategory } from "../../API/getAdminFeeCategoryAPI";

function FeeSearchForm({ customId, onSubmit, MOPList }) {
  const [userDetails, setUserDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [initialResourceUrl, setInitialResourceUrl] = useState(null);
  const [sectionList, setSectionList] = useState([]);
  const [selectedFromDate, setSelectedFromDate] = useState(null);
  const [selectedToDate, setSelectedToDate] = useState(null);
  const [selectedReCat, setSelectedReCat] = useState(null);
  const [selectedClassCat, setSelectedClassCat] = useState(null);
  const [selectedClass, setSelectedClass] = useState(null);
  const [selectedSection, setSelectedSection] = useState(null);
  const [categoryList, setCategoryList] = useState([]);
  const [selectedModPay, setSelectedModPay] = useState(null);
  const [adminNo, setAdminNo] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const users = JSON.parse(localStorage.getItem("registeredUsers")) || [];

      if (customId) {
        const decodedData = JSON.parse(atob(customId));
        let matchedItem = [];

        users.forEach((innerArray) => {
          const [userData] = innerArray;

          if (
            userData.userId === decodedData.userId &&
            userData.userType === decodedData.userType &&
            userData.schoolId === decodedData.schoolId &&
            userData.year === decodedData.year
          ) {
            matchedItem.push(userData);
          }
        });

        const userYear = userDetails && userDetails[0] && userDetails[0].year;
        const initialFromDate = new Date(`04/01/${userYear}`);
        setSelectedFromDate(initialFromDate);

        const result = await getClassSelectionList(
          decodedData.userId,
          decodedData.schoolId,
          decodedData.userType,
          decodedData.year
        );

        const category = await getAdminFeeCategory(
          decodedData.userId,
          decodedData.schoolId,
          decodedData.userType,
          decodedData.year
        );

        const keyval = "webResourceURL";
        const dataUrl = await getUserDataFromLocalByKey(
          decodedData.userId,
          decodedData.schoolId,
          decodedData.userType,
          decodedData.year,
          keyval
        );
        setInitialResourceUrl(dataUrl);
        setSectionList(result);
        setCategoryList(category);
        setLoading(false);
        setUserDetails(matchedItem);

        console.log(category);
      }
    };

    fetchData();
  }, [customId]);

  useEffect(() => {
    const userYear = userDetails && userDetails[0] && userDetails[0].year;
    const initialFromDate = new Date(userYear, 3, 1); // Month index starts from 0, so 3 corresponds to April
    setSelectedFromDate(initialFromDate);
  }, [userDetails]);

  useEffect(() => {
    const today = new Date();
    const formattedToday = formatDate(today);
    setSelectedToDate(today);
  }, []);

  //console.log(categoryList);

  const formatDate = (date) => {
    if (!date) return ""; // Handle null or undefined dates

    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    // Ensure leading zeros for day and month
    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = month < 10 ? `0${month}` : month;

    return `${formattedDay}-${formattedMonth}-${year}`;
  };

  // Variables to store formatted date strings
  const formattedFromDate = formatDate(selectedFromDate);
  const formattedToDate = formatDate(selectedToDate);

  const showModOfPayDropdown = MOPList && MOPList.length > 0;

  const head1Options = sectionList
    .slice(1)
    .map((section) => section.Head1)
    .filter(Boolean);

  const highestTotNosSection = () => {
    let highestSection = null;
    let highestTotNos = 0;

    sectionList.forEach((section) => {
      const totNos = parseInt(section.TotNos, 10);
      if (!isNaN(totNos) && totNos > highestTotNos) {
        highestTotNos = totNos;
        highestSection = section;
      }
    });

    return highestSection;
  };

  const highestTotNosSectionValue = highestTotNosSection();
  const head2Options = highestTotNosSectionValue
    ? highestTotNosSectionValue.Head2.split(",")
    : [];

    const receiptCategoryOptions = categoryList && categoryList.length > 0
  ? categoryList[0].Head3.split(",")
  : [];

const ClassCategoryOptions = categoryList && categoryList.length > 0
  ? categoryList[0].Head2.split(",")
  : [];


  const handleSearch = () => {
    // Add your search logic here
    // console.log("Search clicked!");
    console.log(
      formattedFromDate,
      formattedToDate,
      selectedReCat,
      selectedClassCat,
      selectedSection,
      selectedClass,
      adminNo
    );

    const formData = {
      formattedFromDate,
      formattedToDate,
      selectedReCat,
      selectedClassCat,
      selectedClass,
      selectedSection,
      adminNo,
      ...(selectedModPay && { selectedModPay }),
    };

    onSubmit(formData);
  };

  return (
    <>
      <div className="w-full lg:flex items-center justify-center mt-4">
        <label className="block mb-2  text-sm font-bold text-gray-700 mr-2 ml-3 lg:ml-0">
          From Date :
        </label>
        <DatePicker
          selected={selectedFromDate}
          onChange={(date) => setSelectedFromDate(date)}
          placeholderText="Select From Date"
          dateFormat="dd/MM/yyyy"
          maxDate={new Date()}
          className="border lg:w-96 w-full ml-3 lg:ml-0  border-gray-300 px-3 py-2 rounded-md mb-2 focus:border-blue-500 focus:outline-none"
        />
      </div>

      <div className="w-full lg:flex items-center justify-center mt-3">
        <label className="block mb-2  text-sm font-bold text-gray-700 mr-2 ml-3 lg:ml-0">
          To Date :
        </label>
        <DatePicker
          selected={selectedToDate}
          onChange={(date) => setSelectedToDate(date)}
          placeholderText="Select To Date"
          dateFormat="dd/MM/yyyy"
          maxDate={new Date()}
          className="border lg:w-96 w-full ml-3 lg:ml-4  border-gray-300 px-3 py-2 rounded-md mb-2 focus:border-blue-500 focus:outline-none"
        />
      </div>

      {showModOfPayDropdown && (
        <div className="w-full lg:flex items-center justify-center mt-3">
          <label className="block mb-2 text-sm font-bold text-gray-700 mr-2 ml-3 lg:ml-0">
            Select Mod of Pay:
          </label>
          <select
            value={selectedModPay}
            onChange={(e) => setSelectedModPay(e.target.value)}
            className="border lg:w-96 w-full ml-3 lg:ml-0  border-gray-300 px-3 py-2 rounded-md mb-2 focus:border-blue-500 focus:outline-none"
          >
            <option value="">🧍 Select Mod of Pay</option>
            {MOPList.map((option) => (
              <option key={option} value={option}>
                🧍 {option}
              </option>
            ))}
          </select>
        </div>
      )}

      <div className="w-full lg:flex items-center justify-center mt-3">
        <label className="block mb-2 text-sm font-bold text-gray-700 mr-2 ml-3 lg:ml-0">
          Select Receipt Category :
        </label>
        <select
          value={selectedReCat}
          onChange={(e) => setSelectedReCat(e.target.value)}
          className="border lg:w-96 w-3/5  ml-3 lg:ml-0  border-gray-300 px-3 py-2 rounded-md mb-2 focus:border-blue-500 focus:outline-none"
        >
          <option value="">🧍 Select Receipt Category</option>
          {receiptCategoryOptions.map((option) => (
            <option key={option} value={option}>
              🧍 {option}
            </option>
          ))}
        </select>
      </div>

      <div className="w-full lg:flex items-center justify-center mt-3">
        <label className="block mb-2 text-sm font-bold text-gray-700 mr-2 ml-3 lg:ml-0">
          Select Class Category :
        </label>
        <select
          value={selectedClassCat}
          onChange={(e) => setSelectedClassCat(e.target.value)}
          className="border lg:w-96 w-3/5 ml-3 lg:ml-0  border-gray-300 px-3 py-2 rounded-md mb-2 focus:border-blue-500 focus:outline-none"
        >
          <option value="">🧍 Select Class Category </option>
          {ClassCategoryOptions.map((option) => (
            <option key={option} value={option}>
              🧍 {option}
            </option>
          ))}
        </select>
      </div>

      <div className="w-full lg:flex items-center justify-center mt-3">
        <label className="block mb-2 text-sm font-bold text-gray-700 mr-2 ml-3 lg:ml-0">
          Select Class:
        </label>
        <select
          value={selectedClass}
          onChange={(e) => setSelectedClass(e.target.value)}
          className="border lg:w-96 w-fit  ml-3 lg:ml-0  border-gray-300 px-3 py-2 rounded-md mb-2 focus:border-blue-500 focus:outline-none"
        >
          <option value="">🧍 Select Class</option>
          {head1Options.map((option) => (
            <option key={option} value={option}>
              🧍 {option}
            </option>
          ))}
        </select>
      </div>

      <div className="w-full lg:flex items-center justify-center mt-3">
        <label className="block mb-2 text-sm font-bold text-gray-700 mr-2 ml-3 lg:ml-0">
          Select Section:
        </label>
        <select
          value={selectedSection}
          onChange={(e) => setSelectedSection(e.target.value)}
          className="border lg:w-96 w-fit ml-3 lg:ml-0  border-gray-300 px-3 py-2 rounded-md mb-2 focus:border-blue-500 focus:outline-none"
        >
          <option value="Not Selected"> 🧍Not Selected</option>
          {head2Options.map((option, index) => (
            <option key={index} value={option}>
              🧍 {option}
            </option>
          ))}
        </select>
      </div>

      <div className="w-full lg:flex items-center justify-center mt-3">
        <label className="block mb-2  text-sm font-bold text-gray-700 mr-2 ml-3 lg:ml-0">
          Admi/Register No:
        </label>
        <input
          type="text"
          placeholder="Enter your Admi/Register No"
          value={adminNo}
          onChange={(e) => setAdminNo(e.target.value)}
          className="border lg:w-96 w-fit ml-3 lg:ml-0 border-gray-300 px-3 py-2 rounded-md mb-2 focus:border-blue-500 focus:outline-none"
        />
      </div>

      <div className="w-full flex items-center justify-center mt-4">
        <button
          className="bg-green-500 text-white font-semibold py-2 px-4 rounded focus:outline-none mb-2 focus:shadow-outline-blue"
          onClick={handleSearch}
        >
          Search
        </button>
      </div>
    </>
  );
}

export default FeeSearchForm;
