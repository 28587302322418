import React, { useEffect, useState } from "react";
import {
  Bell,
  ChevronRight,
  Calendar,
  Clock,
  CheckCircle,
  AlertTriangle,
  Info,
  AlertCircle,
  X,
  ArrowLeft,
} from "lucide-react";

const NoticeListystem = ({ noticeList = [] }) => {
 

  const [currentIndex, setCurrentIndex] = useState(0);
  const [selectedNotification, setSelectedNotification] = useState(null);
  const [isPaused, setIsPaused] = useState(false);

  useEffect(() => {
    if (noticeList.length <= 1 || isPaused || selectedNotification) return;

    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === noticeList.length - 1 ? 0 : prevIndex + 1
      );
    }, 5000);

    return () => clearInterval(interval);
  }, [noticeList.length, isPaused, selectedNotification]);

  const getNotificationIcon = (type, size = 5) => {
    const iconProps = { className: `h-${size} w-${size}` };
    switch (type) {
      case "success":
        return (
          <CheckCircle
            {...iconProps}
            className={`${iconProps.className} text-green-500`}
          />
        );
      case "warning":
        return (
          <AlertTriangle
            {...iconProps}
            className={`${iconProps.className} text-yellow-500`}
          />
        );
      case "error":
        return (
          <AlertCircle
            {...iconProps}
            className={`${iconProps.className} text-red-500`}
          />
        );
      default:
        return (
          <Info
            {...iconProps}
            className={`${iconProps.className} text-blue-500`}
          />
        );
    }
  };

  const formatDate = (date) => {
    return new Date(date).toLocaleDateString("en-US", {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  const formatTime = (date) => {
    return new Date(date).toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  const getNotificationBg = (type) => {
    switch (type) {
      case "success":
        return "bg-green-50 border-green-200";
      case "warning":
        return "bg-yellow-50 border-yellow-200";
      case "error":
        return "bg-red-50 border-red-200";
      default:
        return "bg-blue-50 border-blue-200";
    }
  };

  return (
    <div className="relative w-full">
      {/* Main Ticker */}
      <div
        className={`w-full bg-gradient-to-r from-indigo-600 to-purple-600 text-white shadow-lg transition-all duration-300 ${
          selectedNotification
            ? "opacity-0 pointer-events-none h-0"
            : "opacity-100 h-12"
        }`}
      >
        <div className="max-w-7xl mx-auto">
          <div className="relative overflow-hidden h-12">
            <div className="flex items-center h-full">
              <div className="flex-shrink-0 px-4 flex items-center h-full border-r border-white/10">
                <Bell className="h-5 w-5 animate-swing" />
              </div>

              <div className="flex-1 relative overflow-hidden">
                <div
                  className="transition-transform duration-1000 ease-in-out flex whitespace-nowrap"
                  style={{
                    transform: `translateX(-${currentIndex * 100}%)`,
                  }}
                >
                  {noticeList.map((notification) => (
                    <div
                      key={notification.NID}
                      className="flex-shrink-0 w-full px-4 py-3 flex items-center gap-3 hover:bg-white/10 transition-colors cursor-pointer"
                      onClick={() => setSelectedNotification(notification)}
                    >
                      <div className="h-2 w-2 rounded-full bg-white animate-pulse" />
                      <span className="font-medium">
                        {notification.NRemarks}
                      </span>
                      <ChevronRight className="h-4 w-4 opacity-50" />
                      {/* <span className="opacity-90 truncate">
                        {notification.NRemarks2}
                      </span> */}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Details View */}
      <div
        className={`absolute top-0 left-0 w-full bg-white shadow-lg transition-all duration-500 ease-in-out transform ${
          selectedNotification
            ? "translate-y-0 opacity-100 z-[200]"
            : "translate-y-full opacity-0 pointer-events-none"
        }`}
      >
        {selectedNotification && (
          <div className="max-w-4xl mx-auto z-[200]">
            {/* Header */}
            <div className="bg-gradient-to-r from-indigo-600 to-purple-600 p-4 z-[100000]">
              <button
                onClick={() => setSelectedNotification(null)}
                className="flex items-center gap-2 text-white/80 hover:text-white transition-colors mb-4"
              >
                <ArrowLeft className="h-5 w-5" />
                <span>Back to noticeList</span>
              </button>
              <div className="flex items-start gap-4">
                {getNotificationIcon(selectedNotification.type, 6)}
                <div className="flex-1">
                  <h3 className="text-xl font-semibold text-white">
                    {selectedNotification.NRemarks}
                  </h3>
                  <p className="text-white/80 mt-1">
                    {selectedNotification.NRemarks2 || "General Notification"}
                  </p>
                </div>
              </div>
            </div>

            {/* Content */}
            <div className="p-6">
              <div
                className={`rounded-lg border p-4 mb-6 ${getNotificationBg(
                  selectedNotification.NNoticeLink
                )}`}
              >
                {/* Render Attached Link */}
                {selectedNotification.NNoticeLink && (
                  <div className="mb-4">
                    <h4 className="text-lg font-medium text-gray-800 mb-2">
                      Attached Link
                    </h4>
                    <a
                      href={selectedNotification.NNoticeLink}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-600 underline"
                    >
                      {selectedNotification.NNoticeLink}
                    </a>
                  </div>
                )}

                {/* Render Attachment if available */}
                {selectedNotification?.attachment && (
                  <div className="mt-4">
                    <h4 className="text-lg font-medium text-gray-800 mb-2">
                      Attachment
                    </h4>
                    {/* Conditionally check attachment type (e.g., image, PDF) */}
                    {selectedNotification?.attachment.type === "image" ? (
                      <img
                        src={selectedNotification.attachment.url}
                        alt="Attachment"
                        className="rounded-lg shadow-md"
                      />
                    ) : selectedNotification.attachment.type === "pdf" ? (
                      <a
                        href={selectedNotification.attachment.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-600 underline"
                      >
                        View PDF
                      </a>
                    ) : (
                      <p className="text-gray-500">
                        Unsupported attachment format
                      </p>
                    )}
                  </div>
                )}
              </div>

              {/* Meta Information */}
              <div className="mt-8 flex flex-wrap gap-6 text-sm text-gray-500 border-t pt-4">
                <div className="flex items-center gap-2">
                  <Calendar className="h-4 w-4" />
                  <span>{formatDate(selectedNotification.NDate)}</span>
                </div>
                <div className="flex items-center gap-2">
                  <Clock className="h-4 w-4" />
                  <span>{formatTime(selectedNotification.NDate)}</span>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

// Add animations
const style = document.createElement("style");
style.textContent = `
  @keyframes swing {
    0%, 100% { transform: rotate(0deg); }
    25% { transform: rotate(-10deg); }
    75% { transform: rotate(10deg); }
  }
  .animate-swing {
    animation: swing 2s ease-in-out infinite;
  }
`;
document.head.appendChild(style);

export default NoticeListystem;
