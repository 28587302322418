import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import Spinner from "../../components/Spinner";
import useSidebar from "../../hooks/useSidebar";
import { getUserDataFromLocalByKey } from "../../API/getStudentInfoByKeyLocal";
import { getCTGalleryDetailsList } from "../../API/getCTGalleryList";

function CTGallery() {
  const { customId } = useParams();
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredMyMentors, setFilteredMyMentors] = useState([]);
  const [userDetails, setUserDetails] = useState(null);
  const [ctName, setCtName] = useState('');
  const [loading, setLoading] = useState(true);
  const [myMentorsList, setMyMentorsList] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [selectedMyMentors, setSelectedMyMentors] = useState(null);
  const [contentType, setContentType] = useState("default");
  const [initialResourceUrl, setInitialResourceUrl] = useState(null);

  const handleSearch = (value) => {
    setSearchTerm(value);

    if (value.trim() === "") {
      setFilteredMyMentors([]);
    } else {
      const filtered = myMentorsList.filter((mentor) =>
        mentor.TClass.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredMyMentors(filtered);
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      const users = JSON.parse(localStorage.getItem("registeredUsers")) || [];

      if (customId) {
        const decodedData = JSON.parse(atob(customId));
        let matchedItem = [];

        users.forEach((innerArray) => {
          const [userData] = innerArray;

          if (
            userData.userId === decodedData.userId &&
            userData.userType === decodedData.userType &&
            userData.schoolId === decodedData.schoolId &&
            userData.year === decodedData.year
          ) {
            matchedItem.push(userData);
          }
        });

        const namekeyval = "EmployeeName";
        const username = await getUserDataFromLocalByKey(
          decodedData.userId,
          decodedData.schoolId,
          decodedData.userType,
          decodedData.year,
          namekeyval
        );

        const modifiedUsername = username.replace(/(?:MS|MR)\s*/g, "");

        setCtName(modifiedUsername);

        const result = await getCTGalleryDetailsList(
          decodedData.userId,
          decodedData.schoolId,
          decodedData.userType,
          decodedData.year
        );

        console.log("Home Work RESULT", result);
        setMyMentorsList(result);
        setLoading(false);
        setUserDetails(matchedItem);
      }
    };

    fetchData();
  }, [customId]);

  const MentorsToRender = searchTerm ? filteredMyMentors : myMentorsList;

  const { sidebarVisible, toggleSidebar } = useSidebar();

  return ( 
    <div className="container mx-auto p-4">
      {loading ? (
        <Spinner />
      ) : (
        <>
          <div className="flex flex-col md:flex-row items-center justify-between mb-4">
          <div className="w-2/3">
              <h2 className="md:text-3xl font-bold text-xl mb-2 md:mb-0">
                Gallery
              </h2>
              <h4 className="mb-2 font-bold">
                Teacher : {ctName}
              </h4>
            </div>
            <input
              type="text"
              placeholder="Search by Class"
              value={searchTerm}
              onChange={(e) => handleSearch(e.target.value)}
              className="border border-gray-600 md:w-80 w-full md:max-w-sm h-12 md:ml-4 md:px-5 px-2 py-1 rounded-md"
            />
          </div>
          <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-4">
            {MentorsToRender && MentorsToRender.length > 0 ? (
              MentorsToRender.map((item) => (
                <Link
                  to={`/classteacher/u/${customId}/${item.SUBJ}/${item.TClass}/${item.TSection}`}
                  onClick={""}
                  key={item.id}
                >
                  <div className="border border-gray-200 rounded-lg p-4 hover:shadow-md transition duration-300">
                    <div className="flex items-center">
                      <div className="w-1/3 sm:w-auto mr-4">
                        <div
                          className={`w-20 flex justify-center items-center h-20  sm:w-32 sm:h-32 object-cover rounded-ful`}
                        >
                          <div
                            className="rounded-full flex items-center justify-center font-semibold text-xl h-20 w-20 text-white"
                            style={{ backgroundColor: "#00008B" }}
                          >
                            {item.SUBJ.substring(0, 1)}
                          </div>
                        </div>
                      </div>

                      <div className="w-2/3 ">
                        <h4 className="text-md font-normal mb-2">
                          Subject : {item.SUBJ}
                        </h4>
                        <p className="text-md font-normal mb-2">
                          Class : {item.TClass}- {item.TSection}
                        </p>
                      </div>
                    </div>
                  </div>
                </Link>
              ))
            ) : (
              <p>No data found</p>
            )}
          </div>
        </>
      )}
    </div>
   );
}

export default CTGallery;