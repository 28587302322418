import React from "react";
import axios from "axios";
import toast from "react-toastify";
import { getUserDataFromLocalByKey } from "./getStudentInfoByKeyLocal";

let UserName;
let UserCode;
const user = localStorage.getItem("UserRights");
const parts = user?.split("_");
if (parts?.length > 0) {
  UserName = parts?.[0];
  UserCode = parts?.[2];
}

export const GetERPClassStrengthClasswise = async (year,cls) => {
  try {
    const key = "webServiceURL";
    // const classKey='CLNAME'
    // const sectionKey='SECNAME'

    const returnUrl = process.env.REACT_APP_ERP_QUICKINFO_PAGE

   
    

    // const returnUrl = await getUserDataFromLocalByKey(
    //   userId,
    //   schoolId,
    //   userType,
    //   year,
    //   key
    // );

 

    // const className=await getUserDataFromLocalByKey(userId, schoolId, userType, year, classKey)

    // const sectionName= await getUserDataFromLocalByKey(userId, schoolId, userType, year, classKey)

    const formData = new FormData();
    formData.append("title", "GetERPClassStrength");
    formData.append("description", "Get ERP Class strength");
    formData.append("ReqAcaStart", year);
    formData.append("ReqDDLClass", cls);
    formData.append("Reqdisptype", 1);
    formData.append("ReqUserAccessRight", "");
    formData.append("ReqMode", 1);
    formData.append("ReqUserName", UserName);
    formData.append("ReqUserCode", UserCode);
    let userdata = [];

    const Corskey = process.env.REACT_APP_CORS_URL;

    const result = await axios.post(`${Corskey}${returnUrl}`, formData);

    userdata = JSON.parse(
      result.data.substring(0, result.data.indexOf("||JasonEnd", 0))
    );

    return userdata;
  } catch (error) {
    console.error("An error occurred:", error);
    // Handle error here
  }
  // Placeholder JSX, replace with actual JSX
};
