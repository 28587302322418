import React, { useState, useEffect } from "react";
import Navbar from "../../components/Navbar";
import Sidebar from '../../components/Sidebar';
import { useSidebar } from "../../context/SidebarContext";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import Spinner from "../../components/Spinner";
import { getUserDataFromLocalByKey } from "../../API/getStudentInfoByKeyLocal";
import { getAdminCDTimeTable } from "../../API/getAdminCDTimeTableAPI";

function AdminCDTimeTable() {
  const [userType, setUserType] = useState("");
  const [userDetails, setUserDetails] = useState(null);
  const [data, setData] = useState(true);
  const { customId, className, sec } = useParams();
  const [itemList, setItemList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [initialResourceUrl, setInitialResourceUrl] = useState(null);
  const daysOfWeek = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  useEffect(() => {
    const fetchData = async () => {
      const users = JSON.parse(localStorage.getItem("registeredUsers")) || [];

      if (customId) {
        const decodedData = JSON.parse(atob(customId));
        let matchedItem = [];

        users.forEach((innerArray) => {
          const [userData] = innerArray;

          if (
            userData.userId === decodedData.userId &&
            userData.userType === decodedData.userType &&
            userData.schoolId === decodedData.schoolId &&
            userData.year === decodedData.year
          ) {
            matchedItem.push(userData);
          }
        });

        const result = await getAdminCDTimeTable(
          decodedData.userId,
          decodedData.schoolId,
          decodedData.userType,
          decodedData.year,
          className,
          sec
        );

        const keyval = "webResourceURL";
        const dataUrl = await getUserDataFromLocalByKey(
          decodedData.userId,
          decodedData.schoolId,
          decodedData.userType,
          decodedData.year,
          keyval
        );
        setInitialResourceUrl(dataUrl);
        setLoading(false);
        setItemList(result);
        setUserDetails(matchedItem);
      }
    };

    fetchData();
  }, [customId]);

  //console.log(itemList);

  const { isSidebarOpen, currentSidebarTab, toggleSidebar,setIsSidebarOpen } = useSidebar();

  return (
    <>
      {data && (
        <Navbar
          data={userDetails}
          // isOpen={sidebarVisible}
          toggleSidebar={toggleSidebar}
        />
      )}
      <div className="flex">
        { data && <Sidebar data={userDetails} />}

        <div className={`w-full absolute ${isSidebarOpen?"lg:w-4/6 xl:w-9/12":"w-full"} sm:w-11/12 right-0 top-16 mx-auto   p-4 md:p-6 lg:p-8 xl:p-10`}>
          <div className="flex items-center justify-between mb-4">
            <div className="w-2/3">
              <h2 className=" md:text-2xl font-bold text-xl mb-2 md:mb-0">
                Admin :
                {userDetails &&
                  userDetails.length > 0 &&
                  userDetails[0].schoolId}
              </h2>
              <h4 className="mb-2 font-bold">
                Class Time Table : - {className}-{sec}
              </h4>
            </div>
          </div>

          <div className="table-container overflow-x-auto mt-4 ">
        {itemList.length > 0 ? (
          <table className="table-auto">
            <thead>
              <tr>
                <th className="border sticky left-0 top-0 bg-blue-500 text-white px-4 py-2"></th>
                <th className="border bg-blue-500 text-white px-4 py-2 h-16 w-40">
                  1
                </th>
                <th className="border bg-blue-500 text-white px-4 py-2 h-16 w-40">
                  2
                </th>
                <th className="border bg-blue-500 text-white px-4 py-2 h-16 w-40">
                  3
                </th>
                <th className="border bg-blue-500 text-white px-4 py-2 h-16 w-40">
                  4
                </th>
                <th className="border bg-blue-500 text-white px-4 py-2 h-16 w-40">
                  5
                </th>
                <th className="border bg-blue-500 text-white px-4 py-2 h-16 w-40">
                  6
                </th>
                <th className="border bg-blue-500 text-white px-4 py-2 h-16 w-40">
                  7
                </th>
                <th className="border bg-blue-500 text-white px-4 py-2 h-16 w-40">
                  8
                </th>
              </tr>
            </thead>
            <tbody>
              {/* Rows */}
              {daysOfWeek &&
                daysOfWeek.map((day, rowIndex) => (
                  <tr
                    key={rowIndex}
                    className={`h-16 ${
                      rowIndex % 2 === 0 ? "bg-gray-400" : ""
                    }`}
                  >
                    <td className="sticky left-0 top-0 border px-4 py-2 bg-blue-500 text-white">
                      {day}
                    </td>
                    {Array.from({ length: 8 }, (_, columnIndex) => (
                      <td key={columnIndex} className="border px-4 py-2">
                        {/* Your content for each cell */}
                        {itemList.length > 0 &&
                          itemList
                            .filter((item) => item.Day === day) // Filter items related to the current day
                            .map((item) => (
                              <div key={item.ActionType}>
                                {item[`Col${columnIndex + 1}`]}{" "}
                                {/* Display the value from Col1, Col2, etc. */}
                              </div>
                            ))}
                      </td>
                    ))}
                  </tr>
                ))}
            </tbody>
          </table>
        ) : (
          <div>No timetable available</div>
        )}
      </div>
        </div>
      </div>
    </>
  );
}

export default AdminCDTimeTable;
