import React, { useEffect, useState } from "react";
import Spinner from "../../components/Spinner";
import { getUserDataFromLocalByKey } from "../../API/getStudentInfoByKeyLocal";
import { useParams } from "react-router-dom";
import { getAdminPollList } from "../../API/getAdminPollListAPI";
import { updatePollVote } from "../../API/updatePollVote";
import { toast } from "react-toastify";

const StudentPolls = () => {
  const [loading, setLoading] = useState(false);
  const [ctName, setCtName] = useState("");
  const [userDetails, setUserDetails] = useState(null);
  const [pollList, setPollList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedPoll, setSelectedPoll] = useState({});
  const [alertModal, setAlertModal] = useState(false);
  const [selectedOption, setSelectedOption] = useState({
    option: "",
    index: "",
    pollId: "",
  });

  const { TClass, SUBJ, TSection, customId } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      const users = JSON.parse(localStorage.getItem("registeredUsers")) || [];

      if (customId) {
        const decodedData = JSON.parse(atob(customId));
        let matchedItem = [];

        users.forEach((innerArray) => {
          const [userData] = innerArray;

          if (
            userData.userId === decodedData.userId &&
            userData.userType === decodedData.userType &&
            userData.schoolId === decodedData.schoolId &&
            userData.year === decodedData.year
          ) {
            matchedItem.push(userData);
          }
        });
        const namekeyval = "EmployeeName";
        const username = await getUserDataFromLocalByKey(
          decodedData.userId,
          decodedData.schoolId,
          decodedData.userType,
          decodedData.year,
          namekeyval
        );

        const modifiedUsername = username?.replace(/(?:MS|MR)\s*/g, "");

        setCtName(modifiedUsername);

        const result = await getAdminPollList(
          decodedData.userId,
          decodedData.schoolId,
          decodedData.userType,
          decodedData.year
        );
        setPollList(result.length > 0 && result.slice(1));

        setLoading(false);
        setUserDetails(matchedItem);
      }
    };

    fetchData();
  }, [customId, showModal]);

  console.log(userDetails, 999);

  const PollModal = ({ showModal, setShowModal }) => {
    const relevantKeys = ["Col6", "Col7", "Col8", "Col9"];

    const handleOptionClick = (option, index, pollId) => {
      setSelectedOption({
        option: option,
        index: index + 1,
        pollId: pollId,
      });
      setAlertModal(true);
    };

    const closeModal = () => {
      setAlertModal(false);
      setSelectedOption(null); // Reset selected option when closing modal
    };

    const confirmAction = async () => {
      try {
        const response = await updatePollVote(
          userDetails[0]?.userId,
          userDetails[0]?.schoolId,
          userDetails[0]?.userType,
          userDetails[0]?.year,
          selectedOption?.pollId,
          selectedOption.index
        );
        toast.success(response[0].ItemKeyName);
        setAlertModal(false);
        setShowModal(false);
        setLoading(false);
        console.log(response);
      } catch (error) {
        setAlertModal(false); // Close modal after confirmation
      } finally {
        setAlertModal(false);
      }

      setSelectedOption(null); // Optionally, reset the selected option
    };

    console.log(selectedPoll);

    const validOptions = relevantKeys
      .map((key) => selectedPoll[key])
      .filter(
        (option) => option !== null && option !== undefined && option !== ""
      );
    return (
      <>
        {showModal ? (
          <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white rounded-lg shadow-lg max-w-lg w-full p-6">
              {/* Poll Question Section */}
              <div className="bg-blue-600 text-white rounded-t-lg py-4 text-center">
                <h2 className="text-xl font-bold">{selectedPoll.Head2}</h2>
                <p className="text-lg">{selectedPoll.Head3}</p>
              </div>

              {/* Poll Options */}
              <div className="bg-gray-100 p-6">
                {validOptions.map((option, index) => (
                  <div
                    onClick={() =>
                      handleOptionClick(option, index, selectedPoll.Rcdid)
                    }
                    key={index}
                    className="my-2 py-2 px-4 bg-white rounded-full shadow-md text-center text-green-500 font-semibold text-lg cursor-pointer hover:bg-green-100"
                  >
                    {option}
                  </div>
                ))}

                {alertModal && (
                  <div className="fixed inset-0 bg-black bg-opacity-40 backdrop-blur-sm flex items-center justify-center z-50">
                    <div className="bg-white rounded-2xl shadow-2xl max-w-md w-full mx-4 transform transition-all duration-300 ease-out scale-100 opacity-100">
                      <div className="p-6 space-y-4">
                        <h2 className="text-2xl font-bold text-gray-800 border-b pb-2">
                          Confirm Selection
                        </h2>
                        {selectedOption && (
                          <p className="text-lg text-gray-600">
                            You selected:{" "}
                            <span className="font-semibold text-indigo-600">
                              {selectedOption.option}
                            </span>
                          </p>
                        )}
                        <div className="flex justify-end space-x-3 pt-4">
                          <button
                            className="px-4 py-2 rounded-lg border border-gray-500 text-gray-600 hover:bg-gray-100 transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-gray-300"
                            onClick={closeModal}
                          >
                            Cancel
                          </button>
                          <button
                            className="px-6 py-2 bg-indigo-500 text-white rounded-lg hover:bg-indigo-600 transition-colors duration-200 shadow-md hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-indigo-300"
                            onClick={confirmAction}
                          >
                            Yes, Confirm
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              {/* Close Button */}
              <button
                onClick={() => setShowModal(false)}
                className="w-full bg-red-500 text-white py-2 px-4 rounded hover:bg-red-600"
              >
                Close
              </button>
            </div>
          </div>
        ) : null}
      </>
    );
  };

  const PollCard = ({ poll }) => {
    const hasVoted = poll.Col18 != 0; // Check if the user has already voted

    console.log(poll.Col18, hasVoted, 666);

    const dynamicLeftSideConfig = {
      1: [{ value: (item) => item.Col6 }],
      2: [{ value: (item) => item.Col6 }, { value: (item) => item.Col7 }],
      3: [
        { value: (item) => item.Col6 },
        { value: (item) => item.Col7 },
        { value: (item) => item.Col8 },
      ],
      4: [
        { value: (item) => item.Col6 },
        { value: (item) => item.Col7 },
        { value: (item) => item.Col8 },
        { value: (item) => item.Col9 },
      ],
    };

    // Define a configuration object for dynamic rendering of right side
    const dynamicRightSideConfig = {
      1: [{ value: (item) => item.Col14 }],
      2: [{ value: (item) => item.Col14 }, { value: (item) => item.Col15 }],
      3: [
        { value: (item) => item.Col14 },
        { value: (item) => item.Col15 },
        { value: (item) => item.Col16 },
      ],
      4: [
        { value: (item) => item.Col14 },
        { value: (item) => item.Col15 },
        { value: (item) => item.Col16 },
        { value: (item) => item.Col17 },
      ],
    };

    return (
      <>
        {poll?.Col12 === "NO" && (
          <div className="bg-gradient-to-r from-blue-500 to-purple-600 rounded-lg shadow-md p-4 mb-4 text-white">
            <h2 className="text-xl font-bold mb-2">{poll.Head2}</h2>
            <p className="text-sm mb-3">{poll.Head3}</p>

            {poll?.Col11 === "YES" ? (
              <div className="flex">
                {/* Left side */}
                <div className="w-1/2 pr-2 space-y-2">
                  {dynamicLeftSideConfig[poll.Col5]?.map((config, index) => (
                    <div
                      key={index}
                      className="bg-white bg-opacity-10 rounded p-2 text-sm"
                    >
                      {config.value(poll)}
                    </div>
                  ))}
                </div>
                {/* Right side */}
                <div className="w-1/2 pl-2 space-y-2">
                  {dynamicRightSideConfig[poll.Col5]?.map((config, index) => (
                    <div
                      key={index}
                      className="bg-white bg-opacity-10 rounded p-2 text-sm"
                    >
                      {config.value(poll)}
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <div className="space-y-2">
                <p className="text-sm font-semibold">
                  {poll.Col11 === "YES" ? "Result declared" : "Result pending"}
                </p>
                {hasVoted ? (
                  <div className="bg-green-500 bg-opacity-20 rounded p-2">
                    <span className="text-sm">You've voted</span>
                  </div>
                ) : (
                  <button
                    onClick={() => {
                      setSelectedPoll(poll);
                      setShowModal(true);
                    }}
                    disabled={poll.Col10 === "YES"} // Conditionally disable the button
                    className={`w-full py-2 px-4 rounded text-sm font-semibold transition duration-300 ${
                      hasVoted
                        ? "bg-gray-400 cursor-not-allowed"
                        : "bg-blue-400 text-white hover:bg-blue-500"
                    }`}
                  >
                    Vote Now
                  </button>
                )}
              </div>
            )}
          </div>
        )}
      </>
    );
  };

  return (
    <>
      <div className="container mx-auto p-4 space-y-2">
        {loading ? (
          <Spinner />
        ) : (
          <>
            <div className="bg-white p-3 shadow-md rounded-lg overflow-x-auto">
              <PollModal showModal={showModal} setShowModal={setShowModal} />

              <div className="w-2/3 mb-4">
                <h3 className="md:text-3xl font-bold text-xl mb-2 md:mb-0">
                  Polls
                </h3>
                <h4 className="mb-2 font-bold">
                  {/* Gallery {TClass} {TSection} {SUBJ} */}
                </h4>
              </div>
              <div className="max-w-4xl mx-auto">
                {/* Header Section */}

                {/* Poll Cards */}
                {pollList.map((poll, index) => (
                  <PollCard key={index} poll={poll} />
                ))}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default StudentPolls;
