import React, { useState, useEffect } from 'react';
import DemoStudentImage from '../../assets/png/avatar.png';
import { useParams } from 'react-router-dom';
import { getStudentsListDetail } from '../../API/getStudentsList';
import Spinner from '../../components/Spinner';
import useSidebar from '../../hooks/useSidebar';

const ClassmatesPage = () => {
  const { customId, keyName } = useParams();
  const [userDetails, setUserDetails] = useState(null);
  const [studentsList, setStudentsList] = useState([]);
  const [searchVisible, setSearchVisible] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [originalStudentsList, setOriginalStudentsList] = useState([]);
  const [data, setData] = useState(true);
  const [loading, setLoading] = useState(true);

  const toggleSearch = () => {
    setSearchVisible(!searchVisible);
  };

  const handleSearch = (value) => {
    setSearchTerm(value);

    if (value.trim() === '') {
      setStudentsList(originalStudentsList); // Reset to the original list when search input is empty
      return;
    }

    const filteredStudents = originalStudentsList.filter((student) =>
      student.SNAME.toLowerCase().includes(value.toLowerCase())
    );

    setStudentsList(filteredStudents);
  };

  useEffect(() => {
    const fetchData = async () => {
      const users = JSON.parse(localStorage.getItem('registeredUsers')) || [];

      if (customId) {
        const decodedData = JSON.parse(atob(customId));
        let matchedItem = [];

        users.forEach(innerArray => {
          const [userData] = innerArray;

          if (
            userData.userId === decodedData.userId &&
            userData.userType === decodedData.userType &&
            userData.schoolId === decodedData.schoolId &&
            userData.year === decodedData.year
          ) {
            matchedItem.push(userData);
          }
        });

        const result = await getStudentsListDetail(decodedData.userId, decodedData.schoolId, decodedData.userType, decodedData.year);

        setStudentsList(result);
        setUserDetails(matchedItem);
        setLoading(false); // Set loading to false when data is fetched
      }
    };

    fetchData();
  }, [customId]);

  useEffect(() => {
    if (studentsList.length > 0 && originalStudentsList.length === 0) {
      setOriginalStudentsList(studentsList); // Preserve the original list when studentsList gets populated for the first time
    }
  }, [originalStudentsList,studentsList]);

  const { sidebarVisible, toggleSidebar } = useSidebar();

  return (
    <>
      {loading ? ( // Display Spinner when loading is true
        <Spinner />
      ) : (
        <div className="max-w-6xl mx-auto bg-slate-50 shadow-sm px-6 py-3">
          <div className="flex justify-between items-center">
            <h1 className="text-3xl mt-4  text-transparent  font-bold  bg-clip-text bg-gradient-to-r from-purple-500 to-indigo-600 ">Classmates
            + List</h1>
            <button onClick={toggleSearch} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
              {searchVisible ? 'Close Search' : 'Search'}
            </button>
          </div>
          {searchVisible && (
            <div className="mt-4  px-3 py-2 border-slate-100 focus:outline-none border-none">
              <input
                type="text"
                placeholder="Search by Name"
                onChange={(e) => handleSearch(e.target.value)}
                className="border border-gray-300 w-80  h-12 px-5 py-1 rounded-md"
              />
            </div>
          )}
          {/* Display list of students with their details */}
          <div className="overflow-y-auto mt-6 customHeightStudentsList">
            {
            
            studentsList &&
            
            studentsList.map(student => (
              <div
                key={student.id}
                className="bg-white rounded-lg flex justify-center flex-col overflow-hidden shadow-md border border-gray-200 mb-2"
              >
                <div className="flex items-center p-4">
                  <img
                    src={DemoStudentImage}
                    alt="Student"
                    className="w-14 h-14 object-cover rounded-full mr-4"
                  />
                  <div>
                    <div className="font-bold text-xl mb-1">{student.SNAME}</div>
                    <p>
                      <span className="font-semibold">Admission No:</span> {student.ADMNO}
                    </p>
                    <p>
                      <span className="font-semibold">Father's Name</span>: {student.FATHERNAME}
                    </p>
                    <p>
                      <span className="font-semibold">Mother's Name:</span> {student.MNAME}
                    </p>
                    <p>
                      <span className="font-semibold">Phone Number:</span> {student.SMSPHONE}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default ClassmatesPage;
