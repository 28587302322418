import React from "react";
import axios from "axios";
import toast from "react-toastify";
import { getUserDataFromLocalByKey } from "./getStudentInfoByKeyLocal";

export const getClassWiseSubjectWiseMarkEntryListCBSE = async (
  userId,
  schoolId,
  userType,
  year,
  SvrExmid,
  Class,
  section,
  subject,
) => {
  try {
    const key = "webServiceURL";
   const classKey='EMPCODE'
    const url = process.env.REACT_APP_ERP_CBSE_EXAM_PAGE;
    // const empcode=await getUserDataFromLocalByKey(userId, schoolId, userType, year, classKey)

    

    // const returnUrl = await getUserDataFromLocalByKey(
    //   userId,
    //   schoolId,
    //   userType,
    //   year,
    //   key
    // );

    const formData = new FormData();
    formData.append("title", "GetClassWiseSubjectWiseMarkEntryListCBSE");
    formData.append("description", "Get Markentry List");
    formData.append("ReqAcastart", year);
    formData.append("ReqSvrExmid", SvrExmid);
    formData.append("ReqSubject",subject);
    formData.append("ReqClName",Class);
    formData.append("ReqSecName",section);

    let userdata = [];

    const Corskey = process.env.REACT_APP_CORS_URL;

    // const result = await axios.post(
    //   "https://cors-anywhere.herokuapp.com/http://apkarnold.newgentechnology.net/WebDataProcessingServer.aspx",
    //   formData
    // );

    const result = await axios.post(url, formData);


    userdata = JSON.parse(
      result.data.substring(0, result.data.indexOf("||JasonEnd", 0))
    );

    


    return userdata;
  } catch (error) {
    console.error("An error occurred:", error);
    // Handle error here
  }
  // Placeholder JSX, replace with actual JSX
};
