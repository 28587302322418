import React, { useState, useRef, useEffect } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { AiOutlineClose } from "react-icons/ai";
import { BsFillClipboard2XFill } from "react-icons/bs";
import { useGlobalContext } from "../../context/GlobalContext";
import Webcam from "react-webcam";
import { FaCamera } from "react-icons/fa";
import {
  RiGalleryFill,
  RiFileExcel2Fill,
  RiFilePpt2Fill,
} from "react-icons/ri";
import { MdPictureAsPdf, MdVideoLibrary, MdFolderOpen } from "react-icons/md";
import { IoCloseCircleOutline } from "react-icons/io5";
import { updateCTHomeWorkAPI } from "../../API/updateCTHomeWorkAPI";
import { getUserDataFromLocalByKey } from "../../API/getStudentInfoByKeyLocal";
import Navbar from "../../components/Navbar";
import Sidebar from '../../components/Sidebar';
import { useSidebar } from "../../context/SidebarContext";
import Spinner from "../../components/Spinner";
import { updateStudentHomeWork } from "../../API/updateStudentHomeWork";


const generateTabContent = (tabs) => (
  <div className="border border-gray-300  rounded-lg p-4 hover:shadow-md transition duration-300 mt-4">
    <div className="flex justify-around items-center">
      {tabs.map((tab, index) => (
        <div
          key={index}
          className="flex flex-col cursor-pointer items-center mx-2"
          onClick={() => tab.onClick()}
        >
          <div className="bg-gray-300 rounded-full p-3">{tab.icon}</div>
          <p className="mt-1 text-center">{tab.label}</p>
        </div>
      ))}
    </div>
  </div>
);

function WebcamModal({ onClose, onCaptureClick }) {
  const videoConstraints = {
    facingMode: "user",
  };

  const webcamRef = React.useRef(null);

  const dataURLtoFile = (dataurl, filename) => {
    const arr = dataurl.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  };

  const handleCaptureClick = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    const capturedFile = dataURLtoFile(imageSrc, "capturedImage.png");
    onCaptureClick(capturedFile);
    onClose();
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center mt-10 bg-black bg-opacity-60">
      <div className="bg-white customPopupHeight relative rounded-lg mt-8 shadow-lg p-8 md:w-1/2 w-full overflow-y-auto">
        <div className="w-1/3 sm:w-auto mr-4">
          <AiOutlineClose
            className="absolute top-4 right-4 cursor-pointer"
            onClick={onClose}
          />

          {/* Content of the YouTube Link modal */}
          {/* Add your YouTube link input field and submit button here */}
          <Webcam
            audio={false}
            ref={webcamRef}
            screenshotFormat="image/png"
            videoConstraints={videoConstraints}
          />
          <button
            onClick={handleCaptureClick}
            className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded mt-4"
          >
            Take Snapshot
          </button>
        </div>
      </div>
    </div>
  );
}
function TaskSubmit() {
  const {  HWID, HWSubject, customId } = useParams();
  const [userDetails, setUserDetails] = useState(null);
  const [ctName, setCtName] = useState("");
  const [data, setData] = useState(true);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [webcamActive, setWebcamActive] = useState(false);
  const [remarkMessage, setRemarkMessage] = useState("");
  const [pdfFiles, setPdfFiles] = useState([]);
  const [imageFiles, setImageFiles] = useState([]);
  const [videoFiles, setVideoFiles] = useState([]);
  const [excelFiles, setExcelFiles] = useState([]);
  const [pptFiles, setPptFiles] = useState([]);
  const { globalVariable } = useGlobalContext();
  const [isWebcamModalOpen, setWebcamModalOpen] = useState(false);
  const { isSidebarOpen, currentSidebarTab, toggleSidebar,setIsSidebarOpen } = useSidebar();
  const [loading,setLoading]=useState(false)
  

  console.log(useParams())

  useEffect(() => {
    const fetchData = async () => {
      if (customId) {
        const decodedData = JSON.parse(atob(customId));
        setUserDetails(decodedData);

        // const namekeyval = "EmployeeName";
        // const username = await getUserDataFromLocalByKey(
        //   decodedData.userId,
        //   decodedData.schoolId,
        //   decodedData.userType,
        //   decodedData.year,
        //   namekeyval
        // );

        // const modifiedUsername = username.replace(/(?:MS|MR)\s*/g, "");

        // setCtName(modifiedUsername);
      }
    };
    fetchData();
  }, [customId]);

  const handleCameraClick = () => {
    setWebcamModalOpen(true);
  };

  const closeWebcamModal = () => {
    setWebcamModalOpen(false);
  };


  

  const handleCaptureClick = (capturedFile) => {
    // Update selectedFiles array
    setImageFiles([...imageFiles, capturedFile]);

    // Update selectedFiles array
    setSelectedFiles([...selectedFiles, capturedFile]);
  };

  const handleGalleryClick = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/*, video/*";
    input.multiple = true;
    input.click();

    input.addEventListener("change", (event) => {
      const files = event.target.files;
      const newImageFiles = [];
      const newVideoFiles = [];

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        if (file.type.startsWith("image/")) {
          newImageFiles.push(file);
        } else if (file.type.startsWith("video/")) {
          newVideoFiles.push(file);
        }
      }

      // Update state only with new image files
      setImageFiles([...imageFiles, ...newImageFiles]);

      // Optionally, update the general selectedFiles array
      setSelectedFiles([...selectedFiles, ...files]);
    });
  };

  const handlePdfClick = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = ".pdf";
    input.multiple = true; // Allow multiple file selection
    input.click();

    input.addEventListener("change", (event) =>
      handlePdfFileSelection(event.target.files)
    );
  };

  const handlePdfFileSelection = (files) => {
    const pdfFilesArray = Array.from(files).filter(
      (file) => file.type === "application/pdf"
    );
    setPdfFiles([...pdfFiles, ...pdfFilesArray]);

    // Optionally, you can also update the general selectedFiles array
    setSelectedFiles([...selectedFiles, ...pdfFilesArray]);
  };
  const handleVideoClick = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "video/*"; // Specify the allowed video formats
    input.multiple = true;
    input.click();

    input.addEventListener("change", (event) => {
      const videoFilesArray = Array.from(event.target.files);
      setVideoFiles([...videoFiles, ...videoFilesArray]);

      // Optionally, update the general selectedFiles array
      setSelectedFiles([...selectedFiles, ...videoFilesArray]);
    });
  };

  const handleExcelClick = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = ".xls, .xlsx"; // Specify the allowed Excel file formats
    input.multiple = true;
    input.click();

    input.addEventListener("change", (event) =>
      handleExcelFileSelection(event.target.files)
    );
  };

  const handleExcelFileSelection = (files) => {
    const excelFilesArray = Array.from(files).filter(
      (file) =>
        file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    );
    setExcelFiles([...excelFiles, ...excelFilesArray]);

    // Optionally, you can also update the general selectedFiles array
    setSelectedFiles([...selectedFiles, ...excelFilesArray]);
  };

  const handlePptClick = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = ".ppt, .pptx"; // Specify the allowed PowerPoint file formats
    input.multiple = true;
    input.click();

    input.addEventListener("change", (event) =>
      handlePptFileSelection(event.target.files)
    );
  };

  const handlePptFileSelection = (files) => {
    const pptFilesArray = Array.from(files).filter(
      (file) =>
        file.type ===
        "application/vnd.openxmlformats-officedocument.presentationml.presentation"
    );
    setPptFiles([...pptFiles, ...pptFilesArray]);

    // Optionally, you can also update the general selectedFiles array
    setSelectedFiles([...selectedFiles, ...pptFilesArray]);
  };

  const handleMultipleFilesClick = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = ".pdf, .mp4, .xlsx, .ppt, .docx, .png, .jpg, .jpeg, .gif"; // Add image file formats as well
    input.multiple = true; // Allow selecting multiple files
    input.click();

    input.addEventListener("change", (event) => {
      handleMultipleFileSelection(event.target.files);
    });
  };

  const handleMultipleFileSelection = (files) => {
    const imageFilesArray = [];
    const pdfFilesArray = [];
    const pptFilesArray = [];
    const videoFilesArray = [];
    const excelFilesArray = [];

    for (let i = 0; i < files.length; i++) {
      const file = files[i];

      if (file.type.startsWith("image/")) {
        imageFilesArray.push(file);
      } else if (file.type === "application/pdf") {
        pdfFilesArray.push(file);
      } else if (
        file.type ===
        "application/vnd.openxmlformats-officedocument.presentationml.presentation"
      ) {
        pptFilesArray.push(file);
      } else if (file.type.startsWith("video/")) {
        videoFilesArray.push(file);
      } else if (
        file.type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
        file.type === "application/vnd.ms-excel"
      ) {
        excelFilesArray.push(file);
      }
    }

    setImageFiles([...imageFiles, ...imageFilesArray]);
    setPdfFiles([...pdfFiles, ...pdfFilesArray]);
    setPptFiles([...pptFiles, ...pptFilesArray]);
    setVideoFiles([...videoFiles, ...videoFilesArray]);
    setExcelFiles([...excelFiles, ...excelFilesArray]);

    // Optionally, update the general selectedFiles array
    setSelectedFiles([...selectedFiles, ...files]);
  };

  const clearSelectedFiles = () => {
    setSelectedFiles([]);
  };

  // Call this function where needed, for example, in handleResetAttachmentClick
  const handleResetAttachmentClick = () => {
    clearSelectedFiles();
    setPdfFiles([]);
    setImageFiles([]);
    setVideoFiles([]);
    setExcelFiles([]);
    setPptFiles([]);
    setWebcamActive(false);
    setRemarkMessage("");
    // Your other logic here
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (selectedFiles.length === 0) {
      toast.error("There are no attachments");
      return;
    } else if (selectedFiles.length > 5) {
      toast.warning("Please select only up to 5 files");
      return;
    }

    if (remarkMessage === "") {
      toast.warning("Please enter the remark");
      return;
    }
 setLoading(true)
    if (selectedFiles.length > 0) {
      const filesToProcess = [];

      filesToProcess.push(
        ...imageFiles.map((file) => ({ file, fileType: "image" }))
      );
      filesToProcess.push(
        ...pdfFiles.map((file) => ({ file, fileType: "PDF" }))
      );
      filesToProcess.push(
        ...videoFiles.map((file) => ({ file, fileType: "Video" }))
      );
      filesToProcess.push(
        ...excelFiles.map((file) => ({ file, fileType: "Excel" }))
      );
      filesToProcess.push(
        ...pptFiles.map((file) => ({ file, fileType: "PPT" }))
      );

      const processFiles = async (filesToProcess) => {
        const fileName = [];
        const imageStrings = [];
        const fileTypes = [];
      
        const promises = filesToProcess.map(({ file, fileType }) => {
          return new Promise((resolve, reject) => {
            const reader = new FileReader();
            fileTypes.push(fileType);
      
            reader.onload = (event) => {
              const fileBytes = event.target.result;
              const newFileName = file.name.replace(/\s/g, "");
              fileName.push(newFileName);
              const base64String = arrayBufferToBase64(fileBytes);
              imageStrings.push(base64String);
      
              resolve({ newFileName, base64String, fileType });
            };
      
            reader.onerror = reject;
            reader.readAsArrayBuffer(file);
          });
        });
      
        const allFiles = await Promise.all(promises);
        console.log(allFiles);
      
        return { fileName, imageStrings, fileTypes };
      };
      
      // Usage
      processFiles(filesToProcess)
        .then(async({ fileName, imageStrings, fileTypes }) => {
          // Do something with the arrays
        
          
                 const result =await  updateStudentHomeWork(
                  userDetails.userId,
                  userDetails.schoolId,
                  userDetails.userType,
                  userDetails.year,
                  fileName,
                  imageStrings,
                  fileTypes,
                  HWID,
                  remarkMessage
                );

                if (result[0]?.InfoField==="Updated") {
                  toast.success(result[0].InfoField)
                }
                setLoading(false)
                handleResetAttachmentClick()
               
        })
        .catch((err) => {
          console.error(err);
        });


      // const result = await updateCTHomeWorkAPI(
      //   userDetails.userId,
      //   userDetails.schoolId,
      //   userDetails.userType,
      //   userDetails.year,
      //   allFiles,
      //   SUBJ,
      //   TClass,
      //   TSection,
      //   remarkMessage
      // );

      // console.log(`Result after Submit:`, result);
    }
  };
  const handleReset = () => {
    // Logic to reset the form
    setRemarkMessage("");
  };

  const arrayBufferToBase64 = (buffer) => {
    let binary = "";
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  };

  return (
    <>
      {data && (
        <Navbar
          data={userDetails}
          // isOpen={sidebarVisible}
          toggleSidebar={toggleSidebar}
        />
      )}

      {loading?
      <Spinner/>:
      <div className="flex">
      {data && <Sidebar data={userDetails} />}

      <div className={`w-full absolute ${isSidebarOpen?"lg:w-4/6 xl:w-9/12":"w-full"} sm:w-11/12 right-0 top-16 mx-auto   p-4 md:p-6 lg:p-8 xl:p-10`}>
        <div className="flex items-center justify-between mb-4">
          <div className="w-2/3">
            <h3 className="text-md font-semibold mb-2">
               Subject: {HWSubject}
            </h3>
            <h5 className="mb-2">Home Work Submit
            </h5>
          </div>
        </div>

        {generateTabContent([
          {
            icon: (
              <FaCamera style={{ color: "#0EA5E9" }} className="text-2xl" />
            ),
            label: "Camera",
            onClick: handleCameraClick,
          },
          {
            icon: (
              <RiGalleryFill
                style={{ color: "#0EA5E9" }}
                className="text-2xl"
              />
            ),
            label: "Gallery",
            onClick: handleGalleryClick,
          },
          {
            icon: (
              <MdPictureAsPdf
                style={{ color: "#0EA5E9" }}
                className="text-2xl"
              />
            ),
            label: "PDF",
            onClick: handlePdfClick,
          },
        ])}
        {generateTabContent([
          {
            icon: (
              <MdVideoLibrary
                style={{ color: "#0EA5E9" }}
                className="text-2xl"
              />
            ),
            label: "Video",
            onClick: handleVideoClick,
          },
          {
            icon: (
              <RiFileExcel2Fill
                style={{ color: "#0EA5E9" }}
                className="text-2xl"
              />
            ),
            label: "Excel",
            onClick: handleExcelClick,
          },
          {
            icon: (
              <RiFilePpt2Fill
                style={{ color: "#0EA5E9" }}
                className="text-2xl"
              />
            ),
            label: "PPT",
            onClick: handlePptClick,
          },
        ])}
        {generateTabContent([
          {
            icon: (
              <MdFolderOpen
                style={{ color: "#0EA5E9" }}
                className="text-2xl"
              />
            ),
            label: "Multiple Files",
            onClick: handleMultipleFilesClick,
          },
          {
            icon: (
              <IoCloseCircleOutline
                style={{ color: "#0EA5E9" }}
                className="text-2xl"
              />
            ),
            label: "Reset Attachment",
            onClick: handleResetAttachmentClick,
          },
        ])}
        {isWebcamModalOpen && (
          <WebcamModal
            onClose={closeWebcamModal}
            onCaptureClick={handleCaptureClick}
          />
        )}
        <div className="flex flex-wrap">
          {selectedFiles.map((file, index) => (
            <div key={index} className="m-2">
              {file.name}
            </div>
          ))}
        </div>
        {/* Input field */}
        <div className="mb-4">
          <label className="block text-gray-700 font-semibold mb-2">
            Remark Message
          </label>
          <textarea
            value={remarkMessage}
            onChange={(e) => setRemarkMessage(e.target.value)}
            placeholder="Enter Remark Message here..."
            className="border border-gray-300 rounded px-3 py-2 w-full focus:outline-none"
            rows="5"
          ></textarea>
        </div>
        {/* Submit button */}
        <div className="flex justify-center">
          <button
            onClick={handleSubmit}
            className="bg-green-500 text-white font-semibold px-4 py-2 rounded shadow-md mr-4 focus:outline-none"
          >
            Submit
          </button>
          <button
            onClick={handleReset}
            className="bg-red-500 text-white font-semibold px-4 py-2 rounded shadow-md focus:outline-none"
          >
            Reset
          </button>
        </div>
      </div>
    </div>
      }

     
    </>
  );
}

export default TaskSubmit;
