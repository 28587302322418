import React from "react";
import axios from "axios";
import toast from "react-toastify";
import { getUserDataFromLocalByKey } from "./getStudentInfoByKeyLocal";

export const getCTTeacherList = async (userId, schoolId, userType, year) => {
  try {
    const key = "webServiceURL";
    const classKey = "EMPCODE";
    const secKey = "EMPCLASS";

    const returnUrl = await getUserDataFromLocalByKey(
      userId,
      schoolId,
      userType,
      year,
      key
    );

    //console.log(userId,schoolId,userType,year);

   

    const empcode = await getUserDataFromLocalByKey(
      userId,
      schoolId,
      userType,
      year,
      classKey
    );

    const className = await getUserDataFromLocalByKey(
      userId,
      schoolId,
      userType,
      year,
      secKey
    );

    const [classNumber, classSection] = className.split("-");

    // 
    // console.log(classSection);
    // const sectionName= await getUserDataFromLocalByKey(userId, schoolId, userType, year, sectionKey)

    console.log("USER ID IN API  ", userId);

   

    const formData = new FormData();
    formData.append("title", "TeachersSubjects");
    formData.append("description", "");
    formData.append("ReqClass", classNumber);
    formData.append("ReqSection", classSection);
    formData.append("ReqYear", year);

    let userdata = [];

    const Corskey = process.env.REACT_APP_CORS_URL;

    // const result = await axios.post(
    //   "https://cors-anywhere.herokuapp.com/http://apkarnold.newgentechnology.net/WebDataProcessingServer.aspx",
    //   formData
    // );

    const result = await axios.post(`${Corskey}${returnUrl}`, formData);

    userdata = JSON.parse(
      result.data.substring(0, result.data.indexOf("||JasonEnd", 0))
    );

    return userdata;
  } catch (error) {
    console.error("An error occurred:", error);
    // Handle error here
  }
  // Placeholder JSX, replace with actual JSX
};
