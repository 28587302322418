import React, { useState, useEffect } from 'react';
import DemoStudentImage from '../../assets/png/avatar.png';
import { useParams } from 'react-router-dom';
import Navbar from '../../components/Navbar';
import Sidebar from '../../components/Sidebar';
// import useSidebar from '../../hooks/useSidebar';
import { useSidebar } from "../../context/SidebarContext";
import CTNotification from './CTNotification';
import CTAttendance from './CTAttendance';
import CTHomework from './CTHomework' 
import CTGallery from './CTGallery';
import CTReplyToParent from './CTReplyToParent';
import CTNoticeBoard from './CTNoticeBoard';
import CTProfile from './CTProfile';
import CTClassTimeTable from './CTClassTimeTable';
import CTMarkEntry from './CTMarkEntry';
import CTComBWTeachers from './CTComBWTeachers';
import CTTeacherTimeTable from './CTTeacherTimeTable';
import CTSubjectList from './CTSubjectList';
import CTTodaySchedule from './CTTodaySchedule';
import CTStudentDetails from './CTStudentDetails';
import CTClassWisePoll from './CTClassWisePoll';
import CTTeachersList from './CTTeachersList';
import CLAttendance from './CTAttendance';
import CTAbsantees from './CTAbsantees';
import SubjectWiseMarkEntry from './SubjectWiseMarkEntry';
import CTPolls from './CTPolls';



const CustomClassTeacherPage = () => {


  const { customId, keyName } = useParams();
  const [userDetails, setUserDetails] = useState(null);

  const [data, setData] = useState(true);

  // const { sidebarVisible, toggleSidebar } = useSidebar();

  const { isSidebarOpen, currentSidebarTab, toggleSidebar,setIsSidebarOpen } = useSidebar();

  useEffect(() => {
    

      if (customId) {
       
        const decodedData = JSON.parse(atob(customId));
        setUserDetails(decodedData)
   
      }

      
  }, [customId,keyName])


 
  return (
    <>
      {data && <Navbar data={userDetails} toggleSidebar={toggleSidebar} />}

      <div className="flex">
        {data  && <Sidebar data={userDetails} />}

         <div className={`w-full absolute ${isSidebarOpen?"lg:w-4/6 xl:w-9/12":"w-full"} sm:w-11/12 right-0 top-16 mx-auto   p-4 md:p-6 lg:p-8 xl:p-10`}>
          
          {keyName === 'CTNotification' && <CTNotification />}
          {keyName === 'CTAttendance' && <CTAttendance />}
          {keyName === 'CTHomeWork' && <CTHomework />}
          {keyName === 'CTGallery' && <CTGallery />}
          {keyName === 'CTCommunication' && <CTReplyToParent />}
          {keyName === 'CTNoticeBoard' && <CTNoticeBoard />}
          {keyName === 'CTMyProfile' && <CTProfile />}
          {keyName === 'CTTimeTable' && <CTClassTimeTable />}
          {keyName === 'CTMarkEntry' && <CTMarkEntry /> }
          {keyName === 'CTCBWTeachers' && <CTComBWTeachers /> }
          {keyName === 'CTTTimeTable' && <CTTeacherTimeTable />}
          {keyName === 'CTSubjectList' && <CTSubjectList />}
          {keyName === 'CTTodaySch' && <CTTodaySchedule />}
          {keyName === 'CTStudents' && <CTStudentDetails/>}
          {keyName === 'CTCPoll' && <CTClassWisePoll/>}
          {keyName === 'CTTeachers' && <CTTeachersList/>}
          
          {keyName === 'CTAbsantees' && <CTAbsantees />}
          {keyName === "SubjectMarkEntry" && <SubjectWiseMarkEntry />}
          {keyName === "CTPoll" && <CTPolls />}
          
          
          {/* CTTimeTable
          CTTTimeTable
          CTTodaySch
          CTPoll
          CTCPoll
          CTStudents */}

        </div>
      </div>
    </>
  );
};

export default CustomClassTeacherPage;
