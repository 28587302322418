import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import Notification from "../../assets/Dashboard/notification.svg";
import { getUserDataFromLocalByKey } from "../../API/getStudentInfoByKeyLocal";
import Spinner from "../../components/Spinner";
import useSidebar from "../../hooks/useSidebar";
function StudentFeeAnalyses() {
  const { customId } = useParams();
  const navigate = useNavigate();
  const [userDetails, setUserDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [initialResourceUrl, setInitialResourceUrl] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const users = JSON.parse(localStorage.getItem("registeredUsers")) || [];

      if (customId) {
        const decodedData = JSON.parse(atob(customId));
        let matchedItem = [];

        users.forEach((innerArray) => {
          const [userData] = innerArray;

          if (
            userData.userId === decodedData.userId &&
            userData.userType === decodedData.userType &&
            userData.schoolId === decodedData.schoolId &&
            userData.year === decodedData.year
          ) {
            matchedItem.push(userData);
          }
        });

        const keyval = "webResourceURL";
        const dataUrl = await getUserDataFromLocalByKey(
          decodedData.userId,
          decodedData.schoolId,
          decodedData.userType,
          decodedData.year,
          keyval
        );
        setInitialResourceUrl(dataUrl);
        setLoading(false);
        setUserDetails(matchedItem);
      }
    };

    fetchData();
  }, [customId]);

  const handleMenuItemClick = (keyName) => {
    if (keyName === "StdFeeDC") {
      navigate(`/student/u/${keyName}/${customId}/demandCollection`);
    } else if (keyName === "StdFeeDR") {
      navigate(`/student/u/${keyName}/${customId}/datewiseReceipts`);
    } else if (keyName === "StdSHW") {
      navigate(`/student/u/${keyName}/${customId}/summHeadWise`);
    }
    // Add more conditions for other menu items if needed
  };

  let menuItems = [
    {
      icon: Notification,
      keyName: "StdFeeDC",
      caption: "Demand Collection",
      actiontype: "btn",
      redirect: "studentFeeDemand",
      type: "menu",
    },
    {
      icon: Notification,
      keyName: "StdFeeDR",
      caption: "Datewise Receipts",
      actiontype: "btn",
      redirect: "studentFeeDatewise",
      type: "menu",
    },
    {
      icon: Notification,
      keyName: "StdSHW",
      caption: "SummHead Wise",
      actiontype: "btn",
      redirect: "studentFeeHeadwise",
      type: "menu",
    },
  ];

  const { sidebarVisible, toggleSidebar } = useSidebar();

  return (
    <>
      <div className="flex flex-col md:flex-row items-center justify-between mb-4">
        <div className="w-2/3">
          <h2 className=" md:text-2xl font-bold text-xl mb-2 md:mb-0">
            Fees Dashboard
          </h2>
        </div>
      </div>

      <div className="gridContainer px-2 py-3 grid gap-8 md:gap-5 md:grid-cols-4 sm:grid-cols-3 md:grid-cols grid-cols-2 place-items-center">
        {menuItems &&
          menuItems.map((item) => (
            <div
              key={item.keyName}
              onClick={() => handleMenuItemClick(item.keyName)}
              className="md:h-48 md:w-52 h-36 w-40 hover:bg-gray-100 transition duration-300 ease-in-out transform hover:scale-105 rounded-md flex flex-col justify-center cursor-pointer items-center border border-r-gray-300 bg-white shadow-md"
            >
              <img src={item.icon} className="h-20 w-20" alt="" />

              <div>
                <h3 className="font-semibold text-md md:text-xl">
                  {item.caption}
                </h3>
              </div>
            </div>
          ))}
      </div>
    </>
  );
}

export default StudentFeeAnalyses;
