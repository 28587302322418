
import axios from "axios";

import { getUserDataFromLocalByKey } from "./getStudentInfoByKeyLocal";

export const updatePollVote = async (userId, schoolId, userType, year,pollId,opt) => {
  try {
    const key = "webServiceURLAWSDOTNET";

    const returnUrl = await getUserDataFromLocalByKey( userId, schoolId, userType, year, key);

    

   

  

    const formData = new FormData();
        formData.append("title", 'UpdatePollVote');
        formData.append("ReqPollID", pollId);
        formData.append("ReqAcastart", year);
        formData.append("ReqVoteUser", userId);
        formData.append("ReqOpt", opt);
      
    

    let userdata = [];

    const Corskey = process.env.REACT_APP_CORS_URL;

    // const result = await axios.post(
    //   "https://cors-anywhere.herokuapp.com/http://apkarnold.newgentechnology.net/WebDataProcessingServer.aspx",
    //   formData
    // );

    const result = await axios.post(`${Corskey}${returnUrl}`, formData);

    userdata = JSON.parse(
      result.data.substring(0, result.data.indexOf("||JasonEnd", 0))
    );

    return userdata;
  } catch (error) {
    console.error("An error occurred:", error);
    // Handle error here
  }
  // Placeholder JSX, replace with actual JSX
};
