

export const 

getUserInfoKey = async (userId, schoolId, userType, year, key) => {

  // if (key === "webServiceURLAWS" || key=== "webResourceURLAWS") {
  //   let awsWeb = process.env.REACT_APP_AWS_WEBSERVICE_URL;
  //   return awsWeb;
  // }


    try {
      const registeredUsersList = JSON.parse(localStorage.getItem("registeredUsers")) || [];

 console.log(registeredUsersList,"registeerrr userrr list")
 console.log(key,"key")

       let matchedItems = [];
  
      registeredUsersList.forEach(innerArray => {
        const [userData, innerData] = innerArray;
  
        if (userData.userId === userId && userData.year === year && userData.schoolId === schoolId && userData.userType === userType) {
          innerData?.forEach(innerItem => {
            console.log("Inner Item OutSide", innerItem?.ItemKeyName)
            if (innerItem?.ItemKeyName === key) {
              console.log("Inside Inner ITem", innerItem?.ItemKeyName)
              
              matchedItems.push(innerItem);
            }
          });
        }
      });

      console.log(matchedItems,"matched item")
 
      return matchedItems.length > 0 ? matchedItems[0].InfoField : null;
   
    } catch (error) {
      console.error("An error occurred:", error);
      // Handle error here
      return null;
    }
  };
  