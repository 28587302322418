import axios from "axios";

export const registerTeacherUsers = async (userType, schoolId, userId, year, preRegisterUrl) => {
  try {
    const formData = new FormData();
    formData.append("title", "GetTeacherInfo");
    formData.append("description", "registerTeacherChecking");
    formData.append("ReqAcastart", year);
    formData.append("ReqUserID", userId);

    const Corskey = process.env.REACT_APP_CORS_URL;
    const response = await axios.post(`${Corskey}${preRegisterUrl}`, formData);
    const responseData = response.data; 
    const startIndex = responseData.indexOf("[");
    const endIndex = responseData.indexOf("]");

    if (startIndex !== -1 && endIndex !== -1) {
      const jsonData = responseData.substring(startIndex, endIndex + 1);
      const parsedData = JSON.parse(jsonData);
      console.log(parsedData);
      return parsedData;
    } else {
      console.log("JSON data not found in the response.");
    }
  } catch (error) {
    console.error("An error occurred:", error);
  }
};
