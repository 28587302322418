import React from "react";
import axios from "axios";
import toast from "react-toastify";
import { getUserDataFromLocalByKey } from "./getStudentInfoByKeyLocal";

export const getAdminStuSearchList = async (
  userId,
  schoolId,
  userType,
  year,
  AdmnNo,
  SName,
  className,
  sec
) => {
  try {
    const key = "webServiceURLAWSDOTNET";

    const returnUrl = await getUserDataFromLocalByKey(
      userId,
      schoolId,
      userType,
      year,
      key
    );

   

    console.log("USER ID IN API  ", userId);

   

      const formData = new FormData();
      formData.append("title", "StudentList");
      formData.append("description", "");
      formData.append("ReqClass", className ? className : "");
      formData.append("ReqSection", sec ? sec : "");
      formData.append("ReqYear", year);
      formData.append("ReqPara1", "");
      formData.append("ReqAdmNo", AdmnNo ? AdmnNo : "" );
      formData.append("ReqSName", SName ? SName : "");

      let userdata = [];

      const Corskey = process.env.REACT_APP_CORS_URL;

      // const result = await axios.post(
      //   "https://cors-anywhere.herokuapp.com/http://apkarnold.newgentechnology.net/WebDataProcessingServer.aspx",
      //   formData
      // );

      const result = await axios.post(`${Corskey}${returnUrl}`, formData);

      userdata = JSON.parse(
        result.data.substring(0, result.data.indexOf("||JasonEnd", 0))
      );

      return userdata;
  
  } catch (error) {
    console.error("An error occurred:", error);
    // Handle error here
  }
  // Placeholder JSX, replace with actual JSX
};
