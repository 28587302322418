import React from "react";
import axios from "axios";
import toast from "react-toastify";
import { getUserDataFromLocalByKey } from "./getStudentInfoByKeyLocal";

export const acceptHomeWork = async (
    preUrl,
  STDHWID
) => {

  try {
  
  
    const formData = new FormData();
    formData.append("title", "UpdateStudentHomeWorkAccepted");
    formData.append("ReqAcceptType", "1");
    formData.append("STDHWID", STDHWID);

    let userdata = [];

    const Corskey = process.env.REACT_APP_CORS_URL;

    // const result = await axios.post(
    //   "https://cors-anywhere.herokuapp.com/http://apkarnold.newgentechnology.net/WebDataProcessingServer.aspx",
    //   formData
    // );

    const result = await axios.post(preUrl, formData);

    userdata = JSON.parse(
      result.data.substring(0, result.data.indexOf("||JasonEnd", 0))
    );

    return userdata;
  } catch (error) {
    console.error("An error occurred:", error);
    // Handle error here
  }
  // Placeholder JSX, replace with actual JSX
};
