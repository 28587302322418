import axios from "axios";
import { getUserDataFromLocalByKey } from "./getStudentInfoByKeyLocal";

export const updateAdminNotice = async (userId, schoolId, userType, year, className, sectionName, filedata, remarks, NoticeType) => {
  try {
    const key = "webServiceURLAWSDOTNET";

    const returnUrl = await getUserDataFromLocalByKey(userId, schoolId, userType, year, key);
    const SMSType = process.env.REACT_APP_SMS_TYPE;

    const formData = new FormData();
    formData.append("title", "UpdateNoticeBoard");
    formData.append("description", "");
    formData.append("HWID", "");
    formData.append("HWClass", className);
    formData.append("HWSection", "");
    formData.append("HWSubject", className);
    formData.append("HWRemarks", remarks);
    formData.append("ReqAcastart", year);
    formData.append("AddedBy", userId);
    formData.append("ReqSMSType", SMSType);
    formData.append("ReqNoticeType", NoticeType);

    filedata.forEach((fileInfo) => {
      const fileType = fileInfo.fileType;

      formData.append(`PhotoLocation`, fileInfo.newFileName);
      formData.append(`imagestring`, fileInfo.base64String);

      if (fileType === "image") {
        formData.append("MediaType", fileType);
      } else if (fileType === "PDF") {
        formData.append("MediaType", fileType);
      } else {
        formData.append("MediaType", fileType);
      }
    });

    let userdata = [];
    const Corskey = process.env.REACT_APP_CORS_URL;
    const result = await axios.post(`${Corskey}${returnUrl}`, formData);
    userdata = JSON.parse(result.data.substring(0, result.data.indexOf("||JasonEnd", 0)));
    return userdata;
  } catch (error) {
    console.error("An error occurred:", error);
  }
};
