import React, { useEffect, useState } from "react";
import Navbar from "../../components/Navbar";
import Sidebar from "../../components/Sidebar";
import { useSidebar } from "../../context/SidebarContext";
import { useParams } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import { getQuickInfoClassWiseDetails } from "../../API/getQuickInfoClassWiseDetails";
import { getUserDataFromLocalByKey } from "../../API/getStudentInfoByKeyLocal";
import { getSingleStudentInfoDetails } from "../../API/getSingleStudentInfoDetails";

const QuickInfoSingleStudentProfileDetails = () => {
    const [data, setData] = useState(true);
    const [userDetails, setUserDetails] = useState(null);
    const [loading, setLoading] = useState(false);
    const [initialResourceUrl, setInitialResourceUrl] = useState(null);
    const [quickInfoList, setQuickInfoList] = useState([]);
    const location = useLocation();

    let StudentFullDetails = location.state.studentData;
    // console.log(StudentFullDetails);
    const { customId } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            const users = JSON.parse(localStorage.getItem("registeredUsers")) || [];

            if (customId) {
                const decodedData = JSON.parse(atob(customId));
                let matchedItem = [];

                users.forEach((innerArray) => {
                    const [userData] = innerArray;

                    if (
                        userData.userId === decodedData.userId &&
                        userData.userType === decodedData.userType &&
                        userData.schoolId === decodedData.schoolId &&
                        userData.year === decodedData.year
                    ) {
                        matchedItem?.push(userData);
                    }
                });

                const result = await getSingleStudentInfoDetails(
                    decodedData.userId,
                    decodedData.schoolId,
                    decodedData.userType,
                    decodedData.year
                );

                const keyval = "webResourceURL";
                const dataUrl = await getUserDataFromLocalByKey(
                    decodedData.userId,
                    decodedData.schoolId,
                    decodedData.userType,
                    decodedData.year,
                    keyval
                );
                setInitialResourceUrl(dataUrl);

                setQuickInfoList(result);
                setLoading(false);
                setUserDetails(matchedItem);
            }
        };

        fetchData();
    }, [customId]);

    let studentInfoDetails1 = [
        { "ADMISSION NUMBER": StudentFullDetails?.ADMNO },
        { "STUDENTS NAME": StudentFullDetails?.SNAME },
        { "CLASS/SECTION": `${StudentFullDetails?.CLNAME}-${StudentFullDetails?.SECNAME}` },
        { "ROLL NO.": StudentFullDetails?.RNO },
        { "DATE OF BIRTH": StudentFullDetails?.DOB },
        { "ADMISSION DATE": StudentFullDetails?.DOA },
        { GENDER: StudentFullDetails?.GENDER },
        { "FATHER NAME": StudentFullDetails?.FATHERNAME },
        { "MOTHER NAME": StudentFullDetails?.MNAME },
        { "MOBILE NO.": StudentFullDetails?.PHONE },
        { ADDRESS: StudentFullDetails?.ADDRESS },
        { RELIGION: StudentFullDetails?.RELIGION },
        { CATEGORY: StudentFullDetails?.CATEGORY },
        { "BLOOD GROUP": StudentFullDetails?.BG },
        { "AADHAR NO.": StudentFullDetails?.AADHARIDS },
        { "SAMAGRA ID": "" },
        { "FATHER OCCUPATION": StudentFullDetails?.FOCC },
        { "OCCU. ADDRESS": StudentFullDetails?.FOADDRESS },
        { "MOTHER OCCUPATION": StudentFullDetails?.MOCC },
        { "OCCU. ADDRESS": StudentFullDetails?.MOADD },
    ];

    const { isSidebarOpen, currentSidebarTab, toggleSidebar,setIsSidebarOpen } = useSidebar();
    return (
        <>
            {data && <Navbar data={userDetails} toggleSidebar={toggleSidebar} />}
            <div className="flex">
                { data && <Sidebar data={userDetails} />}
                <div className={`w-full absolute ${isSidebarOpen?"lg:w-4/6 xl:w-9/12":"w-full"} sm:w-11/12 right-0 top-16 mx-auto   p-4 md:p-6 lg:p-8 xl:p-10`}>
                    <div className="absolute inset-0 -z-10 h-full w-full bg-white bg-[linear-gradient(to_right,#8080800a_1px,transparent_1px),linear-gradient(to_bottom,#8080800a_1px,transparent_1px)] bg-[size:14px_24px]"></div>

                    <div className="container mx-auto my-5 mb-10">
                        <div className="md:flex no-wrap md:-mx-2 ">
                            {/* stud data table */}
                            <div className="relative overflow-x-auto w-full flex flex-row flex-wrap">
                                <table className="text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 w-[70%]">
                                    <tbody className="w-full">
                                        {studentInfoDetails1?.map((data, index) => {
                                            const key = Object.keys(data)[0];
                                            const value = Object.values(data)[0];
                                            return (
                                                <div key={index} className="flex w-full">
                                                    <tr className="border-b dark:bg-gray-800 dark:border-gray-700 md:w-[30%] w-[40%]">
                                                        <th
                                                            scope="row"
                                                            className="p-2 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                                        >
                                                            {key}
                                                        </th>
                                                    </tr>
                                                    <tr className="border-b dark:bg-gray-800 dark:border-gray-700 md:w-[70%] w-[60%]">
                                                        <th
                                                            scope="row"
                                                            className="p-2 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                                        >
                                                            {value}
                                                        </th>
                                                    </tr>
                                                </div>
                                            );
                                        })}
                                    </tbody>
                                </table>
                                {/* profile & calender */}
                                <div></div>
                            </div>
                        </div>
                    </div>
                    {/* student details fee details */}
                    <div className="container mx-auto my-5">
                        <div className="flex flex-col">
                            <h2 className="text-base md:text-2xl uppercase font-mono md:py-3 py-2 text-black md:font-bold font-semibold">
                                Fee Details
                            </h2>
                            <div className="flex flex-col flex-1 md:flex-row">
                                {/* <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                        <tr>
                                            <th scope="col" className="px-6 py-3">
                                                INSTALLMENTS
                                            </th>
                                            <th scope="col" className="px-6 py-3">
                                                TOTAL FINE AND MISC FEES
                                            </th>
                                            <th scope="col" className="px-6 py-3">
                                                0.00
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className="font-medium text-gray-900">
                                            <th className="font-medium text-gray-900">
                                                    <table className="w-full ">
                                                        <tbody>
                                                            <tr className="">
                                                                <td className="p-3 w-[40%] border border-solid border-black">
                                                                    1st INSTALLMENT
                                                                </td>
                                                                <td className="p-3 border border-solid border-black">
                                                                    0.00
                                                                </td>

                                                                <td className="p-3 border border-solid border-black"></td>
                                                                <td className="p-3 border border-solid border-black"></td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                            </th>
                                            <td className="p-3 border border-solid border-black">TOTAL CONCESSION</td>
                                            <td className="p-3 border border-solid border-black">0.00</td>
                                        </tr>
                                    </tbody>
                                </table> */}
                                <div className="w-[60%]">
                                    <div className="relative overflow-x-auto">
                                        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                                            <thead className="font-medium">
                                                <tr>
                                                    <th scope="col" className="p-3">
                                                        Product name
                                                    </th>
                                                    
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                                    <th
                                                        scope="row"
                                                        className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                                    >
                                                        Apple MacBook Pro 17"
                                                    </th>
                                                    <td className="px-6 py-4">Silver</td>
                                                    <td className="px-6 py-4">Laptop</td>
                                                    <td className="px-6 py-4">$2999</td>
                                                </tr>
                                                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                                    <th
                                                        scope="row"
                                                        className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                                    >
                                                        Microsoft Surface Pro
                                                    </th>
                                                    <td className="px-6 py-4">White</td>
                                                    <td className="px-6 py-4">Laptop PC</td>
                                                    <td className="px-6 py-4">$1999</td>
                                                </tr>
                                                <tr className="bg-white dark:bg-gray-800">
                                                    <th
                                                        scope="row"
                                                        className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                                    >
                                                        Magic Mouse 2
                                                    </th>
                                                    <td className="px-6 py-4">Black</td>
                                                    <td className="px-6 py-4">Accessories</td>
                                                    <td className="px-6 py-4">$99</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="w-[40%]">
                                    <div className="relative overflow-x-auto">
                                        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                                            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                                <tr>
                                                    <th scope="col" className="px-6 py-3">
                                                        Product name
                                                    </th>
                                                    <th scope="col" className="px-6 py-3">
                                                        Color
                                                    </th>
                                                    <th scope="col" className="px-6 py-3">
                                                        Category
                                                    </th>
                                                    <th scope="col" className="px-6 py-3">
                                                        Price
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                                    <th
                                                        scope="row"
                                                        className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                                    >
                                                        Apple MacBook Pro 17"
                                                    </th>
                                                    <td className="px-6 py-4">Silver</td>
                                                    <td className="px-6 py-4">Laptop</td>
                                                    <td className="px-6 py-4">$2999</td>
                                                </tr>
                                                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                                    <th
                                                        scope="row"
                                                        className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                                    >
                                                        Microsoft Surface Pro
                                                    </th>
                                                    <td className="px-6 py-4">White</td>
                                                    <td className="px-6 py-4">Laptop PC</td>
                                                    <td className="px-6 py-4">$1999</td>
                                                </tr>
                                                <tr className="bg-white dark:bg-gray-800">
                                                    <th
                                                        scope="row"
                                                        className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                                    >
                                                        Magic Mouse 2
                                                    </th>
                                                    <td className="px-6 py-4">Black</td>
                                                    <td className="px-6 py-4">Accessories</td>
                                                    <td className="px-6 py-4">$99</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default QuickInfoSingleStudentProfileDetails;
