import React from "react";
import axios from "axios";
import toast from "react-toastify";
import { getUserDataFromLocalByKey } from "./getStudentInfoByKeyLocal";

export const updateCTNotificationAPI = async (userId, schoolId, userType, year, newFileName, base64String,contentType,subject,remarks) => {
  try {
    const key = "webServiceURLAWSDOTNET";
    const classKey = "EMPCLASS"

   
  
    const returnUrl = await getUserDataFromLocalByKey(userId,schoolId,userType,year,key);
    const className=await getUserDataFromLocalByKey(userId, schoolId, userType, year, classKey)

     const [classNumber, classSection] = className.split('-');


    if(contentType === 'Youtube Link'){

      const SMSType= process.env.REACT_APP_SMS_TYPE

   

    const formData = new FormData();
    formData.append("title", "UpdateNotification");
    formData.append("description", "");
    formData.append("HWID", "");
    formData.append("HWClass", classNumber);
    formData.append("HWSection", classSection);
    formData.append("HWSubject", subject);
    formData.append("MediaType", contentType);
    formData.append("HWRemarks", remarks);
    formData.append("ReqAcastart", year);
    formData.append("AddedBy", userId);
    formData.append("PhotoLocation", `https://www.youtube.com/watch?${newFileName}`);
    formData.append("imagestring", base64String);
    formData.append("ReqSMSType", SMSType);



    let userdata = [];

    const Corskey = process.env.REACT_APP_CORS_URL;

    const result = await axios.post(`${Corskey}${returnUrl}`, formData);

    userdata = JSON.parse(
      result.data.substring(0, result.data.indexOf("||JasonEnd", 0))
    );

    return userdata;


    }else{

      const SMSType= process.env.REACT_APP_SMS_TYPE

   

    const formData = new FormData();
    formData.append("title", "UpdateNotification");
    formData.append("description", "");
    formData.append("HWID", "");
    formData.append("HWClass", classNumber);
    formData.append("HWSection", classSection);
    formData.append("HWSubject", subject);
    formData.append("MediaType", contentType);
    formData.append("HWRemarks", remarks);
    formData.append("ReqAcastart", year);
    formData.append("AddedBy", userId);
    formData.append("PhotoLocation", newFileName);
    formData.append("imagestring", base64String);
    formData.append("ReqSMSType", SMSType);



    let userdata = [];

    const Corskey = process.env.REACT_APP_CORS_URL;

    const result = await axios.post(`${Corskey}${returnUrl}`, formData);

    userdata = JSON.parse(
      result.data.substring(0, result.data.indexOf("||JasonEnd", 0))
    );

    return userdata;

    }



  } catch (error) {
    console.error("An error occurred:", error);
    // Handle error here
  }
  // Placeholder JSX, replace with actual JSX
};
