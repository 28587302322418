import React, { useState, useEffect, useRef, useMemo } from "react";
import { useParams } from "react-router-dom";
import useSidebar from "../../hooks/useSidebar";
import { getUserDataFromLocalByKey } from "../../API/getStudentInfoByKeyLocal";
import { getCTClassTimeTableListDetail } from "../../API/getCTClassTimeTableList";
function CTClassTimeTable() {
  const { customId } = useParams();
  const [userDetails, setUserDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [ctName, setCtName] = useState("");
  const [timeTableList, setTimeTableList] = useState([]);
  const [initialResourceUrl, setInitialResourceUrl] = useState(null);
  const daysOfWeek = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  const prevCustomIdRef = useRef();

  useEffect(() => {
    const fetchData = async () => {
      const users = JSON.parse(localStorage.getItem("registeredUsers")) || [];

      if (customId && customId !== prevCustomIdRef.current) {
        prevCustomIdRef.current = customId;

        const decodedData = JSON.parse(atob(customId));
        let matchedItem = [];

        users.forEach((innerArray) => {
          const [userData] = innerArray;

          if (
            userData.userId === decodedData.userId &&
            userData.userType === decodedData.userType &&
            userData.schoolId === decodedData.schoolId &&
            userData.year === decodedData.year
          ) {
            matchedItem.push(userData);
          }
        });

        const namekeyval = "EmployeeName";
        const username = await getUserDataFromLocalByKey(
          decodedData.userId,
          decodedData.schoolId,
          decodedData.userType,
          decodedData.year,
          namekeyval
        );

        const modifiedUsername = username.replace(/(?:MS|MR)\s*/g, "");

        setCtName(modifiedUsername);

        const result = await getCTClassTimeTableListDetail(
          decodedData.userId,
          decodedData.schoolId,
          decodedData.userType,
          decodedData.year
        );

        console.log(result,2343434324)

        const keyval = "webResourceURL";
        const dataUrl = await getUserDataFromLocalByKey(
          decodedData.userId,
          decodedData.schoolId,
          decodedData.userType,
          decodedData.year,
          keyval
        );
        setInitialResourceUrl(dataUrl);

        setTimeTableList(result);

        console.log(result);
        setLoading(false);
        setUserDetails(matchedItem);
      }
    };

    fetchData();
  }, [customId]);

  const { sidebarVisible, toggleSidebar } = useSidebar();

  return (
    <>
      <div className="flex flex-col md:flex-row items-center justify-between mb-4">
        <div className="w-2/3">
          <h3 className="md:text-3xl font-bold text-xl mb-2 md:mb-0">
            Teacher : {ctName}
          </h3>
          <h3 className="mb-2 font-bold">Time Table</h3>
        </div>
      </div>

      <div className="table-container overflow-x-auto">
        {timeTableList.length > 0 ? (
          <table className="table-auto">
            <thead>
              <tr>
                <th className="border sticky left-0 top-0 bg-blue-500 text-white px-4 py-2"></th>
                <th className="border bg-blue-500 text-white px-4 py-2 h-16 w-40">
                  1
                </th>
                <th className="border bg-blue-500 text-white px-4 py-2 h-16 w-40">
                  2
                </th>
                <th className="border bg-blue-500 text-white px-4 py-2 h-16 w-40">
                  3
                </th>
                <th className="border bg-blue-500 text-white px-4 py-2 h-16 w-40">
                  4
                </th>
                <th className="border bg-blue-500 text-white px-4 py-2 h-16 w-40">
                  5
                </th>
                <th className="border bg-blue-500 text-white px-4 py-2 h-16 w-40">
                  6
                </th>
                <th className="border bg-blue-500 text-white px-4 py-2 h-16 w-40">
                  7
                </th>
                <th className="border bg-blue-500 text-white px-4 py-2 h-16 w-40">
                  8
                </th>
              </tr>
            </thead>
            <tbody>
              {/* Rows */}
              {daysOfWeek.map((day, rowIndex) => (
                <tr
                  key={rowIndex}
                  className={`h-16 ${rowIndex % 2 === 0 ? "bg-gray-400" : ""}`}
                >
                  <td className="sticky left-0 top-0 border px-4 py-2 bg-blue-500 text-white">
                    {day}
                  </td>
                  {Array.from({ length: 8 }, (_, columnIndex) => (
                    <td key={columnIndex} className="border px-4 py-2">
                      {/* Your content for each cell */}
                      {timeTableList
                      .filter((item) => item.Day === day) // Filter items related to the current day
                        .map((item) => (
                          <div key={item.ActionType}>
                            {item[`Col${columnIndex + 1}`]}{" "}
                            {/* Display the value from Col1, Col2, etc. */}
                          </div>
                        ))}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <div>No timetable available</div>
        )}
      </div>
    </>
  );
}

export default CTClassTimeTable;
