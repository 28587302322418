import { createContext, useContext, useState } from "react";

const GlobalContext = createContext();

export const useGlobalContext = () => {
    return useContext(GlobalContext);
};

export const GlobalProvider = ({ children }) => {
    const [globalVariable, setGlobalVariable] = useState("");
    const [screenSize, setScreenSize] = useState(undefined);
    const [activeMenu, setActiveMenu] = useState(false);

    return (
        <GlobalContext.Provider
            value={{ globalVariable, setGlobalVariable, activeMenu, screenSize, setScreenSize, setActiveMenu }}
        >
            {children}
        </GlobalContext.Provider>
    );
};
