/* eslint-disable no-unused-vars */

import "datatables.net-buttons-dt/css/buttons.dataTables.css";
import "datatables.net-buttons-dt/css/buttons.dataTables.min.css";
import "datatables.net-buttons-dt/js/buttons.dataTables.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import "datatables.net-buttons/js/dataTables.buttons.js";
import DataTable from "datatables.net-dt";
import "datatables.net-dt/css/dataTables.dataTables.css";
import "datatables.net-responsive-dt";
import "datatables.net-scroller";
import "datatables.net-scroller-dt/css/scroller.dataTables.css";
import "datatables.net-scroller-dt/css/scroller.dataTables.min.css";
import "datatables.net-scroller-dt/js/scroller.dataTables.js";
import "datatables.net-scroller/js/dataTables.scroller.js";
import "datatables.net-select/js/dataTables.select.js";

import JSZip from "jszip";
import PDFMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import {
  MdOutlineKeyboardArrowDown,
  MdOutlineKeyboardArrowUp,
} from "react-icons/md";

import { toast } from "react-toastify";
import Spinner from "../../components/Spinner";
import { getFeeSummaryDetailsAPI } from "../../API/getFeeSummaryDetailsAPI";
import { getFeeSummaryHeadwiseDetailsAPI } from "../../API/getFeeSummaryHeadwiseDetailsAPI";
import ModalComponent from "../../components/Modal";
import { GetClassSectionMasterLists } from "../../API/GetClassSectionMasterLists";

window.JSZip = JSZip;
PDFMake.vfs = pdfFonts.pdfMake?.vfs;

const columns = [
  { index: 0, title: "select", data: "select", visible: true },
  { index: 1, title: "Group", data: "Group", visible: true },
  { index: 2, title: "Nof Def Std", data: "NofDefStd", visible: true },
  { index: 3, title: "Nof Std", data: "NofStd", visible: true },
  { index: 4, title: "Demand", data: "Demand", visible: true },
  { index: 5, title: "Concession", data: "Concession", visible: true },
  { index: 6, title: "Actual Demand", data: "ActualDemand", visible: true },
  { index: 7, title: "Collect", data: "Collect", visible: true },
  { index: 8, title: "Due Amount", data: "DueAmount", visible: true },
  // { index: 9, title: "", data: "al", visible: true },
  { index: 9, title: "", data: "sl", visible: true },
  { index: 10, title: "", data: "hw", visible: true },
  { index: 11, title: "", data: "dl", visible: true },
];
const columns2 = [
  { index: 0, title: "HEAD", data: "Head", visible: true },
  { index: 1, title: "DEMAND", data: "Demand", visible: true },
  { index: 2, title: "CONC", data: "Concession", visible: true },
  { index: 3, title: "ACTUAL DEMAND", data: "ActualDemand", visible: true },
  { index: 4, title: "COLLECT", data: "Collect", visible: true },
  { index: 4, title: "DUE AMOUNT", data: "DueAmount", visible: true },
];

const QuickInfoFeeSummary = () => {
  const [loading, setLoading] = useState(false);
  const [feeSummaryDetails, setFeeSummaryDetails] = useState([]);
  const [headWiseDetails, setHeadWiseDetails] = useState([]);
  const [headwiseSelectedClass, setHeadwiseSelectedClass] = useState("");
  const [demand, setDemand] = useState(null);
  const [concession, setConcession] = useState(null);
  const [actualDemand, setActualDemand] = useState(null);
  const [collection, setCollection] = useState(null);
  const [dueAmount, setDueAmount] = useState(null);
  const [nofStudents, setNofStudents] = useState(null);
  const [nofDefaulters, setNofDefaulters] = useState(null);
  const [tableLoad, setTableLoad] = useState(false);
  const [isClosed, setIsClosed] = useState(false);
  const [spin, setSpin] = useState(false);
  const [classAndSub, setClassAndSub] = useState([]);
  const [classList, setClassList] = useState([]);
  const [classCatList, setClassCatList] = useState([]);
  const [sectionList, setSectionList] = useState(["A", "B", "C", "D"]);
  const [selectedClass, setSelectedClass] = useState("");
  const [selectedClass1, setSelectedClass1] = useState("");
  const [selectedClass2, setSelectedClass2] = useState("");
  const [selectedSection, setSelectedSection] = useState("");
  const [selectedClassCat, setSelectedClassCat] = useState("");
  const [modalComponent, setModalComponent] = useState(false);
  const [userDetails, setUserDetails] = useState(null);

  const [tableCol, setTableCol] = useState(columns);
  const [tableData, setTableData] = useState([]);
  let tableRef = useRef(null);
  let tableInstance = useRef(null);
  const [tableCol2, setTableCol2] = useState(columns2);
  const [tableData2, setTableData2] = useState([]);
  let tableRef2 = useRef(null);
  let tableInstance2 = useRef(null);

  const { customId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    // setLoading(true)
    const fetchData = async () => {
      setLoading(true);
      const users = JSON.parse(localStorage.getItem("registeredUsers")) || [];

      if (customId) {
        const decodedData = JSON.parse(atob(customId));
        let matchedItem = [];

        users.forEach((innerArray) => {
          const [userData] = innerArray;

          if (
            userData.userId === decodedData.userId &&
            userData.userType === decodedData.userType &&
            userData.schoolId === decodedData.schoolId &&
            userData.year === decodedData.year
          ) {
            matchedItem.push(userData);
          }
        });
        // handleClassAndSectionFetch(
        //   decodedData.userId,
        //   decodedData.schoolId,
        //   decodedData.userType,
        //   decodedData.year
        // );
        setUserDetails(matchedItem);

        setLoading(false);
      }
    };

    fetchData();
  }, [customId]);

  const fetchData = async () => {
    setLoading(true);

    if (userDetails) {
      try {
        const result = await getFeeSummaryDetailsAPI(
          userDetails[0].year,
          selectedClass,
          selectedSection,
          selectedClass1,
          selectedClass2,
          selectedClassCat
        );
        setFeeSummaryDetails(result);
      } catch (error) {
        console.log(error);
      }
      
    }
    setLoading(false);
  };

  const fetchHeadwise = async (cls) => {
    setLoading(true);
    if (userDetails) {
      try {
        const result2 = await getFeeSummaryHeadwiseDetailsAPI(
          userDetails[0].year,
          cls,
          selectedSection
        );
        console.log(result2, "response");
        setHeadWiseDetails(result2);
      } catch (error) {
        console.log(error);
      }
      
    }
    setLoading(false);
  };

  const fetchHeadwiseButt = async () => {
    setLoading(true);
    if (userDetails) {

      try {
        const result2 = await getFeeSummaryHeadwiseDetailsAPI( userDetails[0].year,"", "");
        console.log(result2, "response");
        setHeadWiseDetails(result2);
      } catch (error) {
        console.log(error);
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [userDetails]);

  const TableData = feeSummaryDetails?.map((data) => ({
    Group: data.ClName,
    NofDefStd: data.StdYrId,
    NofStd: data.StdMstid,
    Demand: data.Demand,
    Concession: data.Conc,
    ActualDemand: data.ToBePay,
    Collect: data.Collect,
    DueAmount: data.DueAmt,
  }));

  const HeadWiseTable2 = headWiseDetails?.map((data) => ({
    Head: data?.ClName,
    Demand: data?.Demand,
    Concession: data?.Conc,
    ActualDemand: data?.ToBePay,
    Collect: data?.Collect,
    DueAmount: data?.DueAmt,
  }));

  const calculateSums = useCallback(() => {
    let demandSum = 0;
    let concessionSum = 0;
    let actualDemandSum = 0;
    let collectionSum = 0;
    let dueAmountSum = 0;
    let nofStudentsSum = 0;
    let nofDefaultersSum = 0;
    TableData?.forEach((data) => {
      demandSum += data?.Demand;
      concessionSum += data?.Concession;
      actualDemandSum += data?.ActualDemand;
      collectionSum += data?.Collect;
      dueAmountSum += data?.DueAmount;
      nofStudentsSum += data?.NofStd;
      nofDefaultersSum += data?.NofDefStd;
    });

    setDemand(demandSum);
    setConcession(concessionSum);
    setActualDemand(actualDemandSum);
    setCollection(collectionSum);
    setDueAmount(dueAmountSum);
    setNofStudents(nofStudentsSum);
    setNofDefaulters(nofDefaultersSum);
  }, [TableData]);

  useEffect(() => {
    calculateSums();
  }, [calculateSums]);

  const handleClassAndSectionFetchGlobal = async () => {
    setLoading(true);
    if (userDetails) {
        
        try {
          const respone = await GetClassSectionMasterLists(
            userDetails[0].userId,
            userDetails[0].schoolId,
            userDetails[0].userType,
            userDetails[0].year
          );
          const ClassList = respone.filter(
            (item) => item.MasterType === "ClassMaster"
          );
          const ClassCatList = respone.filter(
            (item) => item.MasterType === "ClassCategory"
          );
    
          setClassAndSub(ClassList);
          setClassCatList(ClassCatList.map((item) => item.Head1));
          setClassList(ClassList.map((item) => item.Head1));
          setLoading(false);
        } catch (error) {
          console.log(error);
          toast.error("Error");
          setLoading(false);
        }
    }
  };

  const handleClassChange = (e) => {
    if (classAndSub.length > 0) {
      const userSelectedClass = classAndSub.filter(
        (items) => items.Head1 === e.target.value
      );
      const sectionList = userSelectedClass.flatMap((item) =>
        item.Head2.split(",")
      );
      if (sectionList?.length > 0) {
        setSectionList(sectionList);
      } else {
        setSectionList(["A", "B", "C", "D"]);
      }
    }
    setSelectedClass(e.target.value);
  };

  const handleSectionChange = useCallback(
    (e) => {
      setSelectedSection(e.target.value);
    },
    [setSelectedSection]
  );

  const handleClassCatChange = useCallback(
    (e) => {
      setSelectedSection(e.target.value);
    },
    [setSelectedSection]
  );

  useEffect(() => {
    handleClassAndSectionFetchGlobal();
  }, [userDetails]);

  const handleHeadwise = async (cls) => {
    setHeadwiseSelectedClass(cls);
    await fetchHeadwise(cls);
    setModalComponent(!modalComponent);
  };

  const handleHeadwiseButton = async () => {
    setHeadwiseSelectedClass(null);
    await fetchHeadwiseButt();
    setModalComponent(!modalComponent);
  };

  window.handleShowDetails = (cls, num) => {
    const Class = cls;
    setSelectedClass(Class);
    const sectionOfClass = classAndSub.filter((items) => items.Head1 === cls);
    const sectionList = sectionOfClass.flatMap((item) => item.Head2.split(","));
    navigate(`/admin/u/${customId}/QuickInfo/fee_summary_student_list`, {
      state: {
        sectionList: sectionList,
        selectedClass: Class,
        dispType: num,
      },
    });
  };

  window.handleHeadwise = (cls) => {
    setHeadwiseSelectedClass(cls);
    handleHeadwise(cls);
  };

  const TableData2 = feeSummaryDetails?.map((data) => ({
    Group: data.ClName,
    NofDefStd: data.StdYrId,
    NofStd: data.StdMstid,
    Demand: data.Demand,
    Concession: data.Conc,
    ActualDemand: data.ToBePay,
    Collect: data.Collect,
    DueAmount: data.DueAmt,
    sl: `<p onClick="handleShowDetails('${data.ClName}',0)" class="text-blue-500 cursor-pointer text-sm font-medium capitalize">
             Student List 
           </p>`,
    hw: `<p onClick="handleHeadwise('${data.ClName}')" class="text-blue-500 cursor-pointer text-sm font-medium capitalize">
             Head wise List 
           </p>`,
    dl: `<p onClick="handleShowDetails('${data.ClName}',1)" class="text-blue-500 cursor-pointer text-sm font-medium capitalize">
             Defaulters List
           </p>`,
    select: `<input type="radio" class="radioButton" name="group1" />`,
  }));

  useEffect(() => {
    // Initialize DataTable when the component mounts
    if (tableCol && TableData2) {
      tableInstance.current = new DataTable("#studentDetails", {
        responsive: false,
        // dom: "Bfrtip",
        layout: {
          topStart: {
            buttons: [
              "excel",
              "csv",
              "xls",
              "pdf",
              {
                extend: "print",
                exportOptions: {
                  columns: ":visible",
                },
              },
              "colvis",
            ],
          },
        },
        // select: true,
        info: "",
        scrollY: 500,
        scrollX: true,
        deferRender: true,
        scroller: true,
        data: TableData2,
        columns: tableCol,
      });
    }
    // Cleanup DataTable instance when the component unmounts
    return () => {
      if (tableInstance.current !== null) {
        tableInstance.current.destroy();
      }
    };
  }, [TableData2, tableCol]);

  useEffect(() => {
    // Initialize DataTable when the component mounts
    if (tableCol2 && HeadWiseTable2) {
      tableInstance2.current = new DataTable("#studentDetails2", {
        responsive: false,
        // dom: "Bfrtip",
        layout: {
          topStart: {
            buttons: [
              "excel",
              "csv",
              "xls",
              "pdf",
              {
                extend: "print",
                exportOptions: {
                  columns: ":visible",
                },
              },
              "colvis",
            ],
          },
        },
        // select: true,
        info: "",
        scrollY: 400,
        scrollX: true,
        deferRender: true,
        scroller: true,
        data: HeadWiseTable2,
        columns: tableCol2,
      });
    }
    // Cleanup DataTable instance when the component unmounts
    return () => {
      if (tableInstance2.current !== null) {
        tableInstance2.current.destroy();
      }
    };
  }, [HeadWiseTable2, tableCol2]);

  const handleCloseClick = () => {
    setTimeout(() => setIsClosed(!isClosed), 250);
  };

  return (
    <>
      {modalComponent && (
        <ModalComponent
          title={"Head wise Demand and Collection Details"}
          setModalComponent={setModalComponent}
        >
          {headwiseSelectedClass ? (
            <h2 className="text-black">
              Selected Class: {headwiseSelectedClass}
            </h2>
          ) : (
            ""
          )}
          <div className="relative max-h-full overflow-x-auto border border-black border-solid rounded-sm">
            {headWiseDetails && headWiseDetails?.length > 0 ? (
              <table
                ref={tableRef2}
                id="studentDetails2"
                style={{ width: "100%" }}
                className="h-fit w-fit max-h-[500px] responsive extraCSS display nowrap"
              ></table>
            ) : (
              <div className="h-auto p-5">
                <h1 className="text-2xl font-bold">Record Not Found</h1>
              </div>
            )}
          </div>
        </ModalComponent>
      )}
      <div className="lg:mt-0 mt-5">
        <div className="container mx-auto p-2 md:p-4 containerBoxShadow">
          <>
            {loading ? (
              <Spinner />
            ) : (
              <>
                <div className="flex justify-start w-full rounded-md bg-gradient-to-r from-cyan-400 to-blue-400 hover:bg-gradient-to-bl">
                  <h2 className="w-full text-center text-2xl p-0 my-auto uppercase text-black font-mono font-semibold">
                    Class wise Demand and Collection Summary
                  </h2>
                  <button
                    onClick={handleCloseClick}
                    className={`w-fit rounded-md p-2 `}
                  >
                    <MdOutlineKeyboardArrowUp
                      className={`duration-500 ease-ease-in-out text-black transition-all ${
                        isClosed ? "rotate-180" : ""
                      } `}
                      size={25}
                    />
                  </button>
                </div>
                <div
                  className={`transition-all duration-700 ease-ease-in ${
                    !isClosed ? "opacity-100 max-h-screen" : "opacity-0 max-h-0"
                  }`}
                >
                  <div className="flex flex-col gap-3 my-2 rounded">
                    <div className="flex py-2 formBackground justify-between md:flex-row flex-col md:px-10 px-3 sm:px-5 gap-0 md:gap-10 items-center">
                      <div className="w-full grid grid-cols-1 items-center justify-between gap-1">
                        <div className="flex md:flex-row flex-col md:gap-5 gap-1 justify-between w-full">
                          <div className=" flex flex-row md:basis-[50%] w-full items-center gap-2 mb-1 justify-between">
                            <label
                              className="block uppercase tracking-wide text-start text-grey-darker text-xs font-bold mb-2"
                              htmlFor="grid-state"
                            >
                              Class
                            </label>
                            <div className="relative flex flex-row ">
                              <select
                                onChange={handleClassChange}
                                value={selectedClass}
                                className="textInput min-w-20 w-[150px] md:w-[190px]"
                              >
                                {classList?.map((data, i) => (
                                  <option key={i} value={data}>
                                    {data}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>

                          <div className=" flex flex-row md:basis-[50%] w-full items-center gap-2 mb-1 justify-between">
                            <label
                              className="block uppercase tracking-wide text-start text-grey-darker text-xs font-bold mb-2"
                              htmlFor="grid-state"
                            >
                              Display type
                            </label>
                            <div className="relative flex flex-row ">
                              <select className="textInput min-w-20 w-[150px] md:w-[190px]">
                                <option value="">Class wise</option>
                                <option value="">Class/Section wise</option>
                                <option value="">Class Category wise</option>
                              </select>
                            </div>
                          </div>
                        </div>

                        <div className="flex md:flex-row flex-col md:gap-5 gap-1 justify-between w-full">
                          <div className=" flex flex-row md:basis-[50%] w-full items-center gap-2 mb-1 justify-between">
                            <label
                              className="block uppercase tracking-wide text-start text-grey-darker text-xs font-bold mb-2"
                              htmlFor="grid-state"
                            >
                              Section
                            </label>
                            <div className="relative flex flex-row ">
                              <select
                                onChange={handleSectionChange}
                                value={selectedSection}
                                className="textInput min-w-20 w-[150px] md:w-[190px]"
                              >
                                {sectionList?.map((data, i) => (
                                  <option key={i} value={data}>
                                    {data}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>

                          <div className=" flex flex-row md:basis-[50%] w-full items-center gap-2 mb-1 justify-between">
                            <label
                              className="block uppercase tracking-wide text-start text-grey-darker text-xs font-bold mb-2"
                              htmlFor="grid-state"
                            >
                              Class Category
                            </label>
                            <div className="relative flex flex-row ">
                              <select
                                onChange={handleClassCatChange}
                                value={selectedClassCat}
                                className="textInput min-w-20 w-[150px] md:w-[190px]"
                              >
                                {classCatList?.map((data, i) => (
                                  <option key={i} value={data}>
                                    {data}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>

                        <div className="flex md:flex-row flex-col md:gap-5 gap-1 justify-between w-full">
                          <div className=" flex flex-row md:basis-[50%] w-full items-center gap-2 mb-1 justify-between">
                            <label
                              className="block uppercase tracking-wide text-start text-grey-darker text-xs font-bold mb-2"
                              htmlFor="grid-state"
                            >
                              Class Range From
                            </label>
                            <div className="relative flex flex-row ">
                              <select
                                value={selectedClass1}
                                onChange={(e) =>
                                  setSelectedClass1(e.target.value)
                                }
                                className="textInput min-w-20 w-[150px] md:w-[190px]"
                              >
                                {classList?.map((data, i) => (
                                  <option value={data} key={i}>
                                    {data}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>

                          <div className=" flex flex-row md:basis-[50%] w-full items-center gap-2 mb-1 justify-between">
                            <label
                              className="block uppercase tracking-wide text-start text-grey-darker text-xs font-bold mb-2"
                              htmlFor="grid-state"
                            >
                              Upto
                            </label>
                            <div className="relative flex flex-row ">
                              <select
                                value={selectedClass2}
                                onChange={(e) =>
                                  setSelectedClass2(e.target.value)
                                }
                                className="textInput min-w-20 w-[150px] md:w-[190px]"
                              >
                                {classList?.map((data, i) => (
                                  <option value={data} key={i}>
                                    {data}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>

                        <div className="flex md:gap-5 gap-1 justify-between w-full">
                          <div className=" flex flex-row md:basis-[50%] w-full items-center gap-2 mb-1 justify-start">
                            <button onClick={fetchData} className="buttonClass">
                              Refresh
                            </button>
                          </div>
                          <div className=" flex flex-row md:basis-[50%] w-full items-center gap-2 mb-1 justify-end">
                            <button
                              onClick={() => {
                                handleHeadwiseButton();
                                // give the function to fetch data for selected Class
                              }}
                              className="buttonClass"
                            >
                              Head wise
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="formBackground shapedividers my-3 py-3 px-2 uppercase grid md:grid-cols-4 sm:grid-cols-3 grid-cols-2 justify-evenly tracking-wide text-center text-grey-darker text-xs font-bold mb-2 gap-y-3">
                  <div className="flex justify-center items-center gap-4">
                    <h2>Demand:</h2>
                    <h2>{demand}</h2>
                  </div>
                  <div className="flex justify-center items-center gap-4">
                    <h2>Concession:</h2>
                    <h2>{concession}</h2>
                  </div>
                  <div className="flex justify-center items-center gap-4">
                    <h2>Actual Demand:</h2>
                    <h2>{actualDemand}</h2>
                  </div>
                  <div className="flex justify-center items-center gap-4">
                    <h2>Collection:</h2>
                    <h2>{collection}</h2>
                  </div>
                  <div className="flex justify-center items-center gap-4">
                    <h2>Due Amount:</h2>
                    <h2>{dueAmount}</h2>
                  </div>
                  <div className="flex justify-center items-center gap-4">
                    <h2>Nof Students:</h2>
                    <h2>{nofStudents}</h2>
                  </div>
                  <div className="flex justify-center items-center gap-4">
                    <h2>Nof Defaulters:</h2>
                    <h2>{nofDefaulters}</h2>
                  </div>
                </div>

                <div className="p-1 md:p-2 w-full shadow-lg">
                  <div className="relative max-h-96 overflow-x-auto border md:block hidden border-black border-solid rounded-sm">
                    {feeSummaryDetails && feeSummaryDetails?.length > 0 ? (
                      <table
                        ref={tableRef}
                        id="studentDetails"
                        style={{ width: "100%" }}
                        className="h-fit w-fit max-h-[500px] responsive extraCSS display nowrap"
                      ></table>
                    ) : (
                      <div className="h-auto p-5">
                        <h1 className="text-2xl font-bold">Record Not Found</h1>
                      </div>
                    )}
                  </div>

                  <div className="md:hidden block">
                    <div className="block md:hidden max-w-xl mx-auto bg-white shadow-md p-2 rounded-lg">
                      <div className="overflow-x-auto">
                        {TableData?.map((item, index) => (
                          <div
                            className="border-2 bg-gradient-to-b from-sky-200 to-cyan-400 rounded-lg mb-4 shadow-lg overflow-hidden"
                            key={index}
                          >
                            <div className="flex items-center  justify-between tabButton py-2 px-4 rounded-t-lg shadow-md">
                              <input
                                className="radioButton"
                                type="radio"
                                name="employeesRadio"
                                id={`employeesRadio-${index}`}
                              />
                              <span className="font-bold text-center w-full">
                                {" "}
                                {item?.Group}
                              </span>
                            </div>
                            <table className="w-full table-auto">
                              <tbody>
                                <tr>
                                  <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                                    <strong>Nof Def Std</strong>
                                  </td>
                                  <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                                    {item?.NofDefStd}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                                    <strong>Nof Std</strong>
                                  </td>
                                  <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                                    {item?.NofStd}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                                    <strong>Demand</strong>
                                  </td>
                                  <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                                    {item?.Demand}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                                    <strong>Concession</strong>
                                  </td>
                                  <td className="border border-solid border-gray-400 text-start capitalize p-2 w-1/2">
                                    {item?.Concession}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                                    <strong>Actual Demand</strong>
                                  </td>
                                  <td className="border border-solid border-gray-400 text-start capitalize p-2 w-1/2">
                                    {item?.ActualDemand}
                                  </td>
                                </tr>

                                <tr>
                                  <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                                    <strong>Collect</strong>
                                  </td>
                                  <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                                    {item?.Collect}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                                    <strong>Due Amount</strong>
                                  </td>
                                  <td className="border border-solid border-gray-400 text-start capitalize p-2 w-1/2">
                                    {item?.DueAmount}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                                    <strong></strong>
                                  </td>
                                  <td className="border border-solid border-gray-400 text-start capitalize p-2 w-1/2">
                                    <p
                                      onClick={() => {
                                        const Class = item?.Group;
                                        setSelectedClass(Class);
                                        const sectionOfClass =
                                          classAndSub.filter(
                                            (items) =>
                                              items.Head1 === item?.Group
                                          );
                                        const sectionList =
                                          sectionOfClass.flatMap((item) =>
                                            item.Head2.split(",")
                                          );
                                        navigate(
                                        `/admin/u/${customId}/QuickInfo/fee_summary_student_list`,
                                          {
                                            state: {
                                              sectionList: sectionList,
                                              selectedClass: Class,
                                            },
                                          }
                                        );
                                      }}
                                      className="text-white tracking-wider font-medium cursor-pointer text-sm capitalize"
                                    >
                                      Student List &nbsp;
                                    </p>
                                    <p
                                      onClick={() => {
                                        setHeadwiseSelectedClass(item?.Group);
                                        handleHeadwise(item?.Group);
                                      }}
                                      className="text-white tracking-wider font-medium cursor-pointer text-sm capitalize"
                                    >
                                      Head wise List &nbsp;
                                    </p>
                                    <p
                                      onClick={() => {
                                        const Class = item?.Group;
                                        setSelectedClass(Class);
                                        const sectionOfClass =
                                          classAndSub.filter(
                                            (items) =>
                                              items.Head1 === item?.Group
                                          );
                                        const sectionList =
                                          sectionOfClass.flatMap((item) =>
                                            item.Head2.split(",")
                                          );
                                        navigate(
                                          `/admin/u/${customId}/QuickInfo/fee_summary_student_list`,
                                          {
                                            state: {
                                              sectionList: sectionList,
                                              selectedClass: Class,
                                            },
                                          }
                                        );
                                      }}
                                      className="text-white tracking-wider text-start font-medium cursor-pointer text-sm capitalize"
                                    >
                                      Defaulters List
                                    </p>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </>
        </div>
      </div>
    </>
  );
};

export default QuickInfoFeeSummary;
