import React from "react";
import axios from "axios";
import toast from "react-toastify";
import { getUserDataFromLocalByKey } from "./getStudentInfoByKeyLocal";

export const getCTSubjectListAPI = async (userId, schoolId, userType, year) => {
  try {
    const key = "webServiceURLAWSDOTNET";
    const classKey = "EMPCLASS";



    const returnUrl = await getUserDataFromLocalByKey(
      userId,
      schoolId,
      userType,
      year,
      key
    );

    

    const className = await getUserDataFromLocalByKey(
      userId,
      schoolId,
      userType,
      year,
      classKey
    );

    
    let secName;

    if (className) {
      const [classNumber, classSection] = className?.split("-");
      secName = classSection;
    }

   

    // console.log(className);
    // console.log(sectionName);

    const formData = new FormData();
    formData.append("title", "TeachersSubjectsList");
    formData.append("Rdescription", "");
    formData.append("ReqAcastart", year);
    formData.append("ReqClass", userId);
    formData.append("ReqSection", secName || "");

    let userdata = [];

    const Corskey = process.env.REACT_APP_CORS_URL;

    // const result = await axios.post(
    //   "https://cors-anywhere.herokuapp.com/http://apkarnold.newgentechnology.net/WebDataProcessingServer.aspx",
    //   formData
    // );

    const result = await axios.post(`${Corskey}${returnUrl}`, formData);

    console.log(result, 5555);

    userdata = JSON.parse(
      result.data.substring(0, result.data.indexOf("||JasonEnd", 0))
    );

    return userdata;
  } catch (error) {
    console.error("An error occurred:", error);
    // Handle error here
  }
  // Placeholder JSX, replace with actual JSX
};
