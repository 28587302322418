import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import Spinner from "../../components/Spinner";
import paymentIcon from "../../assets/Dashboard/paymentmethod.svg";
import { getUserDataFromLocalByKey } from "../../API/getStudentInfoByKeyLocal";
import { getClassSelectionList } from "../../API/getClassSelectionListAPI";

function AdminClassDetailsList() {
    const { customId, keyName } = useParams();
    const [userDetails, setUserDetails] = useState(null);
    const [loading, setLoading] = useState(true);
    const [initialResourceUrl, setInitialResourceUrl] = useState(null);
    const [sectionList, setSectionList] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const users = JSON.parse(localStorage.getItem("registeredUsers")) || [];

            if (customId) {
                const decodedData = JSON.parse(atob(customId));
                let matchedItem = [];

                users.forEach((innerArray) => {
                    const [userData] = innerArray;

                    if (
                        userData.userId === decodedData.userId &&
                        userData.userType === decodedData.userType &&
                        userData.schoolId === decodedData.schoolId &&
                        userData.year === decodedData.year
                    ) {
                        matchedItem.push(userData);
                    }
                });

                const result = await getClassSelectionList(
                    decodedData.userId,
                    decodedData.schoolId,
                    decodedData.userType,
                    decodedData.year
                );

                const keyval = "webResourceURL";
                const dataUrl = await getUserDataFromLocalByKey(
                    decodedData.userId,
                    decodedData.schoolId,
                    decodedData.userType,
                    decodedData.year,
                    keyval
                );
                setInitialResourceUrl(dataUrl);
                setSectionList(result);
                setLoading(false);
                setUserDetails(matchedItem);
            }
        };

        fetchData();
    }, [customId]);

    //console.log(sectionList);

    return (
        <>
           <div className="md:mt-6 mt-10">
                <div className="p-2 mb-3 flex items-center w-fit flex-wrap">
                    <ul className="flex items-center">
                        <li className="inline-flex items-center cursor-default">
                            <a href="/" className="text-black font-medium hover:scale-105">
                                <svg
                                    className="w-5 cursor-default h-auto fill-current mx-2"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    fill="#000000"
                                >
                                    <path d="M0 0h24v24H0V0z" fill="none" />
                                    <path d="M10 19v-5h4v5c0 .55.45 1 1 1h3c.55 0 1-.45 1-1v-7h1.7c.46 0 .68-.57.33-.87L12.67 3.6c-.38-.34-.96-.34-1.34 0l-8.36 7.53c-.34.3-.13.87.33.87H5v7c0 .55.45 1 1 1h3c.55 0 1-.45 1-1z" />
                                </svg>
                            </a>

                            <span className="mx-4 h-auto font-medium">/</span>
                        </li>
                        <li className="inline-flex items-center">
                            <p className="text-black font-medium cursor-default hover:scale-105">Class Detail</p>
                            {/* <span className="mx-4 h-auto text-gray-400 font-medium">/</span> */}
                        </li>
                    </ul>
                </div>
                <div className="container mx-auto p-4 containerBoxShadow">
                    <div className="flex flex-col md:flex-row items-center justify-between mb-4">
                        <div className="w-2/3">
                            <h2 className=" md:text-2xl font-bold text-xl mb-2 md:mb-0">
                                Admin :{userDetails && userDetails.length > 0 && userDetails[0].schoolId}
                            </h2>
                            <h4 className="mb-2 font-bold">Class Selection List </h4>
                        </div>
                    </div>

                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                        {/* Loop through your data and create a box for each item */}
                        {sectionList.slice(1).map((section, index) => (
                            <Link
                                key={index}
                                to={`/admin/u/${customId}/${section.Head1}/${
                                    keyName === "AdmClassDetails" ? "ClassDetailsSectionList" : "AdminClassNoticeList"
                                }`}
                            >
                                <div
                                    className="bg-gray-100 border border-gray-300 cursor-pointer p-4 rounded-md flex flex-col items-center justify-center"
                                    style={{
                                        boxShadow: "5px 5px 12px 0px rgba(173, 216, 230, 0.9)",
                                    }}
                                >
                                    {/* Customize the content based on your data */}
                                    <img src={paymentIcon} alt="Icon" className="w-12 h-12 mb-2" />
                                    <h3 className="text-lg font-semibold text-center">{section.Head1}</h3>
                                    {/* Add more details or customize as needed */}
                                </div>
                            </Link>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
}

export default AdminClassDetailsList;
