import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import { Accordion } from "flowbite-react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { RiMenu3Fill } from "react-icons/ri";
import { useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { getClassSectionList } from "../../API/getClassSectionList";
import { StudentsSearchAPI } from "../../API/StudentsSearchAPI";
import Spinner from "../../components/Spinner";
import PdfViewer from "../../components/PdfViewer";
import { GetStudentProfileJson } from "../../API/GetStudentProfileJson";
import ModalFunctionComponent from "../../components/ModalFunctionComponent";
import { convertDateString } from "../../utility/dateParse";

const generateSundayEvents = (startDate, endDate) => {
  const events = [];
  let currentDate = new Date(startDate);

  while (currentDate <= endDate) {
    if (currentDate.getDay() === 0) {
      // Sunday
      events.push({
        title: "",
        start: currentDate.toISOString().split("T")[0],
        allDay: true,
        classNames: ["bg-red-500"],
      });
    }
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return events;
};

const QuicKInfoStudentProfile = () => {
  const location = useLocation();
  const { customId } = useParams();
  const [userDetails, setUserDetails] = useState(null);
  const calendarRef = useRef(null);
  const contentRef = useRef(null);
  const [eventData, setEventData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [studentProfile, setStudentProfile] = useState([]);
  const [studentId, setStudentId] = useState(location?.state?.admno);
  const [feesDuesState, setFeesDuesState] = useState([]);
  const [AttendanceState, setAttendanceState] = useState([]);
  const [HeightWeightState, setHeightWeightState] = useState([]);
  const [Term1MarkState, setTerm1MarkState] = useState([]);
  const [Term2MarkState, setTerm2MarkState] = useState([]);
  const [WEState, setWEState] = useState([]);
  const [AEState, setAEState] = useState([]);
  const [isAccordionOpen, setAccordionOpen] = useState(false);
  const [term1, setTerm1] = useState("");
  const [term2, setTerm2] = useState("");
  const [MonthWiseBelowTable, setMonthWiseBelowTable] = useState([]);
  const [PaymentHistory, setPaymentHistory] = useState([]);
  const [transportFee, setTransportFee] = useState([]);
  const [transportFeeReceipt, setTransportFeeReceipt] = useState([]);
  const [activityTab, setActivityTab] = useState([]);
  const [showListModal, setShowListModal] = useState(false);

  const [students, setStudents] = useState([]);
  const [selectedClass, setSelectedClass] = useState("");
  const [selectedSection, setSelectedSection] = useState("");
  const [classAndSub, setClassAndSub] = useState([]);
  const [classList, setClassList] = useState([]);
  const [sectionList, setSectionList] = useState(["A", "B", "C", "D"]);

  useEffect(() => {
    // setLoading(true)
    const fetchData = async () => {
      setLoading(true);
      const users = JSON.parse(localStorage.getItem("registeredUsers")) || [];

      if (customId) {
        const decodedData = JSON.parse(atob(customId));
        let matchedItem = [];

        users.forEach((innerArray) => {
          const [userData] = innerArray;

          if (
            userData.userId === decodedData.userId &&
            userData.userType === decodedData.userType &&
            userData.schoolId === decodedData.schoolId &&
            userData.year === decodedData.year
          ) {
            matchedItem.push(userData);
          }
        });
        handleClassAndSectionFetch(
          decodedData.userId,
          decodedData.schoolId,
          decodedData.userType,
          decodedData.year
        );
        setUserDetails(matchedItem);

        setLoading(false);
      }
    };

    fetchData();
  }, [customId]);

  const getFirstDayOfCurrentMonth = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth(); // January is 0, February is 1, and so on.
    const firstDay = new Date(year, month, 1);

    const day = String(firstDay.getDate()).padStart(2, "0");
    const monthFormatted = String(firstDay.getMonth() + 1).padStart(2, "0"); // Adding 1 because getMonth() returns 0-based month index
    const yearFormatted = firstDay.getFullYear();

    return `${day}-${monthFormatted}-${yearFormatted}`;
  };

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      if (studentId && userDetails) {
        const today = getFirstDayOfCurrentMonth();
        console.log(userDetails, studentId, today);

        const result = await GetStudentProfileJson(
          userDetails[0].year,
          studentId,
          today
        );
        // console.log(result, "result");
        const JSONData1 = JSON.parse(result[0]?.JSONData1);
        const JSONData2 = JSON.parse(result[0]?.JSONData2);
        const JSONData3 = JSON.parse(result[0]?.JSONData3);
        const JSONData4 = JSON.parse(result[0]?.JSONData4);
        const JSONData5 = JSON.parse(result[0]?.JSONData5);
        const JSONData6 = JSON.parse(result[0]?.JSONData6);
        const JSONData7 = JSON.parse(result[0]?.JSONData7);
        // console.log(JSONData7, "0000");
        setMonthWiseBelowTable(JSONData3);
        setPaymentHistory(JSONData4);
        setTransportFee(JSONData5);
        setTransportFeeReceipt(JSONData6);
        setActivityTab(JSONData7);
        const feesDuesData = JSONData2.filter(
          (item) => item.MasterType === "FeesDues"
        ).reduce((acc, item) => {
          acc[item.Head1] = item.Col1;
          return acc;
        }, {});
        const AttendanceData = JSONData2.filter(
          (item) => item.MasterType === "Attendance"
        ).reduce((acc, item) => {
          acc[item.Head1] = item.Col1;
          acc[item.Head2] = item.Col2;
          return acc;
        }, {});
        setAttendanceState(AttendanceData);
        const HeightWeightData = JSONData2.filter(
          (item) => item.MasterType === "Height&Weight"
        ).reduce((acc, item) => {
          acc[item.Head1] = item.Col1;
          acc[item.Head2] = item.Col2;
          return acc;
        }, {});
        setHeightWeightState(HeightWeightData);
        const Term1MarkData = JSONData2.filter(
          (item) => item.MasterType === "Term 1 Mark"
        ).reduce((acc, item) => {
          acc[item.Head1] = item.Col1;
          acc[item.Head2] = item.Col2;
          return acc;
        }, {});
        setTerm1MarkState(Term1MarkData);
        const Term2MarkData = JSONData2.filter(
          (item) => item.MasterType === "Term 2 Mark"
        ).reduce((acc, item) => {
          acc[item.Head1] = item.Col1;
          acc[item.Head2] = item.Col2;
          return acc;
        }, {});
        setTerm2MarkState(Term2MarkData);
        const WEData = JSONData2.filter(
          (item) => item.MasterType === "WE"
        ).reduce((acc, item) => {
          acc[item.Head1] = item.Col1;
          acc[item.Head2] = item.Col2;
          return acc;
        }, {});
        setWEState(WEData);
        const AEData = JSONData2.filter(
          (item) => item.MasterType === "AE"
        ).reduce((acc, item) => {
          acc[item.Head1] = item.Col1;
          acc[item.Head2] = item.Col2;
          return acc;
        }, {});
        setAEState(AEData);
        // console.log(AttendanceData, HeightWeightData, Term1MarkData, Term2MarkData, WEData, AEData);
        setFeesDuesState(feesDuesData);
        // console.log(JSONData4, "888888888");
        // console.log(JSONData1, "json data");
        setStudentProfile(JSONData1);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
    setLoading(false);
  }, [studentId,userDetails]);

  // console.log(feesDuesState,'fee');

  useEffect(() => {
    fetchData();
  }, [studentId, fetchData,userDetails]);

  // console.log(studentProfile[0]);
  // const handleDateClick = async (info) => {
  //   setShowForm(true);
  //   if (modalContentRef?.current) {
  //     document.querySelector(".modal-content").scrollTop = 0;
  //     modalContentRef.current.scrollTop = 0;
  //   }
  //   setDate(info.date);
  // };

  // let url = `${process.env.REACT_APP_ERP_STUDENT_IMAGE_URL}${items?.ImageFile}`;

  useEffect(() => {
    // Generate events for all Sundays in the current year
    const startDate = new Date(new Date().getFullYear(), 0, 1); // January 1st of the current year
    const endDate = new Date(new Date().getFullYear(), 11, 31); // December 31st of the current year
    const sundays = generateSundayEvents(startDate, endDate);
    setEventData(sundays);
  }, []);

  useEffect(() => {
    // Force a resize of the FullCalendar component after it has been rendered
    if (isAccordionOpen && calendarRef.current) {
      calendarRef.current.getApi().updateSize();
      calendarRef.current.getApi().render();
    }
  }, [isAccordionOpen]);

  let studentInfoDetails1 = [
    { "ROLL NO.": studentProfile[0]?.RNO },
    { "DATE OF BIRTH": studentProfile[0]?.DOB },
    { "ADMISSION DATE": studentProfile[0]?.DOA },
    { GENDER: studentProfile[0]?.GENDER },
    { "MOBILE NO.": studentProfile[0]?.PHONE },
    { ADDRESS: studentProfile[0]?.ADDRESS },
    { RELIGION: studentProfile[0]?.RELIGION },
    { CATEGORY: studentProfile[0]?.CATEGORY },
    { "BLOOD GROUP": studentProfile[0]?.BG },
    { "AADHAR NO.": studentProfile[0]?.AADHARIDS },
    { "SAMAGRA ID": "" },
    { "FATHER OCCUPATION": studentProfile[0]?.FOCC },
    { "OCCU. ADDRESS": studentProfile[0]?.FOADDRESS },
    { "MOTHER OCCUPATION": studentProfile[0]?.MOCC },
    { "OCCU. ADDRESS": studentProfile[0]?.MOADD },
  ];

  let url = `${process.env.REACT_APP_ERP_STUDENT_IMAGE_URL}${studentProfile[0]?.ImageFile}`;

  useEffect(() => {
    if (studentProfile.length > 0) {
      const { ACASTART, ADMNO } = studentProfile[0];
      const pdfUrlTerm1 = `${process.env.REACT_APP_ERP_SERVICE_URL}/MarkSheetPDF/${ACASTART}/Term1/${ADMNO}.PDF`;
      setTerm1(pdfUrlTerm1);
      const pdfUrlTerm2 = `${process.env.REACT_APP_ERP_SERVICE_URL}/MarkSheetPDF/${ACASTART}/Term2/${ADMNO}.PDF`;
      setTerm2(pdfUrlTerm2);
    }
  }, [studentProfile]);

  const handleMouseEnter = () => {
    setTimeout(() => {
      setAccordionOpen(!isAccordionOpen);
    }, 200);
  };

  const handleMouseLeave = () => {
    setTimeout(() => {
      setAccordionOpen(!isAccordionOpen);
    }, 200);
  };

  const handleClassAndSectionFetch = async (
    userId,
    schoolId,
    userType,
    year
  ) => {
    setLoading(true);
    try {
      const respone = await getClassSectionList(
        userId,
        schoolId,
        userType,
        year
      );
      const filteredClassAndSub = respone.filter((item) => item.Clid !== -15);
      setClassAndSub(filteredClassAndSub);
      setClassList(filteredClassAndSub.map((item) => item.Head1));
      setLoading(false);
    } catch (error) {
      console.log(error);
      toast.error("Error");
      setLoading(false);
    }
  };

  // useEffect(() => {
  //   handleClassAndSectionFetch();
  // }, []);

  const handleClassChange = useCallback(
    (e) => {
      if (classAndSub.length > 0) {
        const userSelectedClass = classAndSub.filter(
          (items) => items.Head1 === e.target.value
        );
        const sectionList = userSelectedClass.flatMap((item) =>
          item.Head2.split(",")
        );
        if (sectionList?.length > 0) {
          setSectionList(sectionList);
        } else {
          setSectionList(["A", "B", "C", "D"]);
        }
      }
      setSelectedClass(e.target.value);
    },
    [setSelectedClass, classAndSub]
  );

  const handleSectionChange = useCallback(
    (e) => {
      setSelectedSection(e.target.value);
    },
    [setSelectedSection]
  );

  const handleSearch = async () => {
    try {
      setLoading(true);
      const response = await StudentsSearchAPI(userDetails[0].year,selectedClass,selectedSection);
      const JsonData1 = JSON.parse(response[0]?.JSONData1);
      setStudents(JsonData1);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
      toast.error("Error");
    }
  };

  const handleClear = () => {
    setSelectedClass("");
    setSelectedSection("");
    setStudents([]);
  };

  const handleSelect = (stud) => {
    setStudentId(stud);
    setShowListModal(false);
  };

  return (
    <div>
      {showListModal && (
        <ModalFunctionComponent close={setShowListModal} title={"Student List"}>
          <div className="flex justify-center w-full pe-2 mb-2 md:mb-0 ">
            <div className="grid lg:grid-cols-5 md:grid-cols-3 grid-cols-1 gap-1 justify-center w-full formBackground p-2">
              <div className="w-full flex flex-row md:flex-col gap-1">
                <label
                  htmlFor=""
                  className="basis-[40%] align-middle flex items-center text-sm text-start font-semibold"
                >
                  New
                </label>
                <input type="text" className="w-full textInput" />
              </div>
              <div className="w-full flex flex-row md:flex-col gap-1">
                <label
                  htmlFor=""
                  className="basis-[40%] align-middle flex items-center text-sm text-start font-semibold"
                >
                  Father Name
                </label>
                <input type="text" className="w-full textInput" />
              </div>
              <div className="w-full flex flex-row md:flex-col gap-1">
                <label
                  htmlFor=""
                  className="basis-[40%] align-middle flex items-center text-sm text-start font-semibold"
                >
                  Class
                </label>
                <select
                  className="w-full textInput "
                  value={selectedClass}
                  onChange={handleClassChange}
                >
                  <option value="">Select Class</option>
                  {classList.length &&
                    classList.length > 0 &&
                    classList.map((cls, ind) => (
                      <option key={ind} value={cls}>
                        {cls}
                      </option>
                    ))}
                </select>
              </div>
              <div className="w-full flex flex-row md:flex-col gap-1">
                <label
                  htmlFor=""
                  className="basis-[40%] align-middle flex items-center text-sm text-start font-semibold"
                >
                  Section
                </label>
                <select
                  value={selectedSection}
                  onChange={handleSectionChange}
                  className="w-full textInput "
                >
                  <option value="">Select Section</option>
                  {sectionList.length &&
                    sectionList.length > 0 &&
                    sectionList.map((cls, ind) => (
                      <option key={ind} value={cls}>
                        {cls}
                      </option>
                    ))}
                </select>
              </div>

              <div className="flex justify-between mt-2 w-full">
                <div className="flex gap-2">
                  <button
                    onClick={handleSearch}
                    type="button"
                    className="buttonClass"
                  >
                    Search
                  </button>
                  <button
                    onClick={handleClear}
                    type="button"
                    className="buttonClass"
                  >
                    Clear
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div>
            {students?.length > 0 ? (
              <>
                <div className="w-full max-h-[500px] h-full overflow-auto">
                  <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 ">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400 sticky top-0 z-10">
                      <tr className="tableHead">
                        <th scope="col" className="p-1">
                          Admission No
                        </th>
                        <th scope="col" className="p-1">
                          Name
                        </th>
                        <th scope="col" className="p-1">
                          Class
                        </th>
                        <th scope="col" className="p-1">
                          Section
                        </th>
                        <th scope="col" className="p-1">
                          Father Name
                        </th>
                        <th scope="col" className="p-1"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {students.map((student, index) => {
                        return (
                          <tr
                            key={student?.ADMNO}
                            className={`${
                              index % 2 === 0 ? "bg-white" : "bg-slate-400"
                            }`}
                          >
                            <td className="p-1 font-medium text-black">
                              {student?.ADMNO}
                            </td>
                            <td className="p-1 font-medium text-black">
                              {student?.SNAME}
                            </td>
                            <td className="p-1 font-medium text-black">
                              {student?.CLNAME}
                            </td>
                            <td className="p-1 font-medium text-black">
                              {student?.SECNAME}
                            </td>
                            <td className="p-1 font-medium text-black">
                              {" "}
                              {student?.FATHERNAME}
                            </td>
                            <td className="p-1 font-medium text-black">
                              <p
                                onClick={() => handleSelect(student?.ADMNO)}
                                className="text-blue-500 cursor-pointer lowercase text-base"
                              >
                                Select
                              </p>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </>
            ) : (
              <>
                <div>
                  <h1>No Records Found</h1>
                </div>
              </>
            )}
          </div>
        </ModalFunctionComponent>
      )}
      <div className="lg:mt-0 mt-5">
        <div className="container mx-auto p-4 containerBoxShadow">
          {loading ? (
            <Spinner />
          ) : (
            <>
              <div className="flex justify-center mb-2 -mt-2 uppercase">
                <p className="rounded font-medium  cursor-default sub-headings">
                  {studentProfile[0]?.SNAME} Profile{" "}
                </p>
              </div>
              <div className="w-full">
                <div className="w-full formBackground flex flex-col gap-2 p-2 md:px-5">
                  <div className="w-full flex md:flex-row flex-col-reverse gap-1">
                    <div className="flex w-full flex-col gap-1 md:basis-[50%] justify-start">
                      <div className="flex gap-1 w-full">
                        <label
                          htmlFor=""
                          className="w-full basis-[30%] align-middle flex items-center text-sm text-start font-semibold"
                        >
                          ADMISSION NUMBER
                        </label>
                        <input
                          type="text"
                          value={studentProfile[0]?.ADMNO}
                          className="max-w-60 w-full textInput "
                          readOnly
                        />
                      </div>
                      <div className="flex gap-1 w-full">
                        <label
                          htmlFor=""
                          className="w-full basis-[30%] align-middle flex items-center text-sm text-start font-semibold"
                        >
                          STUDENTS NAME
                        </label>
                        <input
                          type="text"
                          value={studentProfile[0]?.SNAME}
                          className="max-w-60 w-full textInput "
                          readOnly
                        />
                      </div>
                      <div className="flex gap-1 w-full">
                        <label
                          htmlFor=""
                          className="w-full basis-[30%] align-middle flex items-center text-sm text-start font-semibold"
                        >
                          CLASS & SECTION
                        </label>
                        <input
                          type="text"
                          value={`${studentProfile[0]?.CLNAME}-${studentProfile[0]?.SECNAME}`}
                          className="max-w-60 w-full textInput "
                          readOnly
                        />
                      </div>

                      <div className="flex gap-1 w-full">
                        <label
                          htmlFor=""
                          className="w-full basis-[30%] align-middle flex items-center text-sm text-start font-semibold"
                        >
                          FATHER NAME
                        </label>
                        <input
                          type="text"
                          value={studentProfile[0]?.FATHERNAME}
                          className="max-w-60 w-full textInput "
                          readOnly
                        />
                      </div>
                      <div className="flex gap-1 w-full">
                        <label
                          htmlFor=""
                          className="w-full basis-[30%] align-middle flex items-center text-sm text-start font-semibold"
                        >
                          MOTHER NAME
                        </label>
                        <input
                          type="text"
                          value={studentProfile[0]?.MNAME}
                          className="max-w-60 w-full textInput "
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="w-full flex flex-col gap-1 md:basis-[50%] justify-start">
                      <div className="flex bg-transparent justify-center w-fit items-center m-auto">
                        {/* img */}
                        <img src={url} className="size-40" alt="profile" />
                      </div>
                    </div>
                    <div className="w-fit flex flex-col gap-1 justify-start">
                      <div className="flex bg-transparent justify-center h-full w-fit items-end m-auto">
                        <button
                          onClick={() => setShowListModal(true)}
                          className="buttonClass !w-fit"
                        >
                          <RiMenu3Fill />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container mx-auto my-5 mb-10">
                <Accordion collapseAll className="w-full">
                  <Accordion.Panel className="w-full">
                    <Accordion.Title className="accordian-title mb-2 focus:outline-none uppercase border-0 focus:ring-0">
                      Student Info
                    </Accordion.Title>
                    <Accordion.Content className="bg-transparent -mt-2 !p-0 transition-all duration-300 ease-custom-ease rounded-lg shadow-lg">
                      <div className="w-full formBackground flex flex-col gap-2 p-2 md:px-5">
                        <div className="w-full flex md:flex-row flex-col gap-1">
                          <div className="w-full sm:grid md:grid-cols-3 sm:grid-cols-2 flex flex-col gap-1 justify-start">
                            {studentInfoDetails1?.map((data, index) => {
                              const key = Object.keys(data)[0];
                              const value = Object.values(data)[0];
                              return (
                                <div className="flex gap-1 w-full">
                                  <label
                                    htmlFor=""
                                    className="w-full basis-[30%] align-middle flex items-center text-sm text-start font-semibold"
                                  >
                                    {key}
                                  </label>
                                  <input
                                    type="text"
                                    value={value}
                                    className="max-w-60 w-full textInput "
                                    readOnly
                                  />
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </Accordion.Content>
                  </Accordion.Panel>
                  <Accordion.Panel
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    ref={contentRef}
                    className="w-full"
                  >
                    <Accordion.Title
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={handleMouseLeave}
                      className="accordian-title mb-2 focus:outline-none uppercase border-0 focus:ring-0"
                    >
                      Attendance Info
                    </Accordion.Title>
                    <Accordion.Content
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={handleMouseLeave}
                      className="bg-transparent -mt-2 !p-0 transition-all duration-300 ease-custom-ease rounded-lg shadow-lg"
                    >
                      <div
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                        className="w-full formBackground p-2 md:px-5"
                      >
                        <div className="flex w-full md:flex-row flex-col gap-2">
                          <div className="w-full">
                            <div className="flex justify-center h-[400px] overflow-auto md:w-[70%] w-full">
                              <FullCalendar
                                plugins={[
                                  dayGridPlugin,
                                  interactionPlugin,
                                  timeGridPlugin,
                                ]}
                                headerToolbar={{
                                  left: "prev,next today",
                                  center: "",
                                  right: "title",
                                }}
                                ref={calendarRef}
                                viewClassNames="text-base"
                                aspectRatio={1}
                                dayCellClassNames="bg-white"
                                initialView="dayGridMonth"
                                // dateClick={handleDateClick}
                                nowIndicator={true}
                                editable={true}
                                events={eventData}
                                selectable={true}
                                selectMirror={true}
                              />
                            </div>
                          </div>
                          <div className="flex justify-center w-full my-5 px-3">
                            <table className="text-sm bg-white h-fit text-left rtl:text-right text-gray-500  dark:text-gray-400 w-full md:w-3/4 lg:w-1/2">
                              <tbody className="w-full p-3">
                                <div className="flex w-full ">
                                  <tr className="border-b w-full">
                                    <th
                                      scope="row"
                                      className="p-2 font-medium text-gray-900 block"
                                    >
                                      PRESENT DAYS
                                    </th>
                                  </tr>
                                  <tr className="border-b w-full">
                                    <th
                                      scope="row"
                                      className="p-2 font-medium text-gray-900 block"
                                    >
                                      350
                                    </th>
                                  </tr>
                                </div>
                                <div className="flex w-full ">
                                  <tr className="border-b w-full">
                                    <th
                                      scope="row"
                                      className="p-2 font-medium text-gray-900 block"
                                    >
                                      ABSENT DAYS
                                    </th>
                                  </tr>
                                  <tr className="border-b w-full">
                                    <th
                                      scope="row"
                                      className="p-2 font-medium text-gray-900 block"
                                    >
                                      5
                                    </th>
                                  </tr>
                                </div>
                                <div className="flex w-full ">
                                  <tr className="border-b w-full">
                                    <th
                                      scope="row"
                                      className="p-2 font-medium text-gray-900 block"
                                    >
                                      LEAVES DAYS
                                    </th>
                                  </tr>
                                  <tr className="border-b w-full">
                                    <th
                                      scope="row"
                                      className="p-2 font-medium text-gray-900 block"
                                    ></th>
                                  </tr>
                                </div>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </Accordion.Content>
                  </Accordion.Panel>
                  <Accordion.Panel className="w-full">
                    <Accordion.Title className="accordian-title mb-2 focus:outline-none uppercase border-0 focus:ring-0">
                      Fee Summary
                    </Accordion.Title>
                    <Accordion.Content className="bg-transparent -mt-2 !p-0 transition-all duration-300 ease-custom-ease rounded-lg shadow-lg">
                      <div className="w-full formBackground p-2 md:px-5">
                        <div className="flex flex-col flex-1 gap-5 lg:flex-row">
                          <div className=" w-full lg:w-[60%]">
                            <div className="relative shadow-xl overflow-x-auto">
                              <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                                <tbody>
                                  <tr className="bg-white w-full">
                                    <th className="p-3 text-xl w-full uppercase font-medium border border-solid border-black/10 text-gray-900">
                                      Installment
                                    </th>
                                  </tr>
                                  <tr className="bg-white w-full">
                                    <th className="font-medium md:grid md:grid-cols-4 p-0 m-0 w-full text-gray-900 ">
                                      <td className="p-3 border border-solid md:w-full w-1/2 border-black/10">
                                        1st INSTALLMENT
                                      </td>
                                      <td className="p-3 border border-solid md:w-full w-1/2 border-black/10">
                                        {feesDuesState?.Inst1Demand}
                                      </td>
                                      <td className="p-3 border border-solid md:w-full w-1/2 border-black/10"></td>
                                      <td className="p-3 border border-solid md:w-full w-1/2 border-black/10"></td>
                                    </th>
                                  </tr>
                                  <tr className="bg-white w-full">
                                    <th className="font-medium md:grid md:grid-cols-4 p-0 m-0 w-full text-gray-900 ">
                                      <td className="p-3 border border-solid md:w-full w-1/2 border-black/10">
                                        2nd INSTALLMENT
                                      </td>
                                      <td className="p-3 border border-solid md:w-full w-1/2 border-black/10">
                                        {feesDuesState?.Inst2Demand}
                                      </td>
                                      <td className="p-3 border border-solid md:w-full w-1/2 border-black/10"></td>
                                      <td className="p-3 border border-solid md:w-full w-1/2 border-black/10"></td>
                                    </th>
                                  </tr>
                                  <tr className="bg-white w-full">
                                    <th className="font-medium md:grid md:grid-cols-4 p-0 m-0 w-full text-gray-900 ">
                                      <td className="p-3 border border-solid md:w-full w-1/2 border-black/10">
                                        3rd INSTALLMENT
                                      </td>
                                      <td className="p-3 border border-solid md:w-full w-1/2 border-black/10">
                                        {feesDuesState?.Inst3Demand}
                                      </td>
                                      <td className="p-3 border border-solid md:w-full w-1/2 border-black/10"></td>
                                      <td className="p-3 border border-solid md:w-full w-1/2 border-black/10"></td>
                                    </th>
                                  </tr>
                                  <tr className="bg-white w-full">
                                    <th className="font-medium md:grid md:grid-cols-4 p-0 m-0 w-full text-gray-900 ">
                                      <td className="p-3 border border-solid md:w-full w-1/2 border-black/10">
                                        4th INSTALLMENT
                                      </td>
                                      <td className="p-3 border border-solid md:w-full w-1/2 border-black/10">
                                        {feesDuesState?.Inst4Demand}
                                      </td>
                                      <td className="p-3 border border-solid md:w-full w-1/2 border-black/10"></td>
                                      <td className="p-3 border border-solid md:w-full w-1/2 border-black/10"></td>
                                    </th>
                                  </tr>
                                  <tr className="bg-white w-full">
                                    <th className="font-medium md:grid md:grid-cols-4 p-0 m-0 w-full text-gray-900 ">
                                      <td className="p-3 border border-solid md:w-full w-1/2 border-black/10">
                                        5th INSTALLMENT
                                      </td>
                                      <td className="p-3 border border-solid md:w-full w-1/2 border-black/10">
                                        {feesDuesState?.Inst5Demand}
                                      </td>
                                      <td className="p-3 border border-solid md:w-full w-1/2 border-black/10"></td>
                                      <td className="p-3 border border-solid md:w-full w-1/2 border-black/10"></td>
                                    </th>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                          <div className="w-full lg:w-[38%]">
                            <div className="relative shadow-xl overflow-x-auto">
                              <table className="w-full text-sm text-left rtl:text-right text-gray-900 ">
                                <tbody>
                                  <tr className="bg-white border-b">
                                    <th
                                      scope="row"
                                      className="p-3 border border-solid border-black/10 font-medium "
                                    >
                                      TOTAL FINE AND MISC FEES
                                    </th>
                                    <td className="p-3 border border-solid border-black/10">
                                      {feesDuesState?.Fine}
                                    </td>
                                  </tr>
                                  <tr className="bg-white border-b">
                                    <th
                                      scope="row"
                                      className="p-3 border border-solid border-black/10"
                                    >
                                      TOTAL CONCESSION
                                    </th>
                                    <td className="p-3 border border-solid border-black/10">
                                      {feesDuesState?.LblConc}
                                    </td>
                                  </tr>
                                  <tr className="bg-white dark:bg-gray-800">
                                    <th
                                      scope="row"
                                      className="p-3 border border-solid border-black/10 font-medium"
                                    >
                                      TOTAL AMOUNT TO PAY
                                    </th>
                                    <td className="p-3 border border-solid border-black/10">
                                      {feesDuesState?.TotDemand}
                                    </td>
                                  </tr>
                                  <tr className="bg-white dark:bg-gray-800">
                                    <th
                                      scope="row"
                                      className="p-3 border border-solid border-black/10 font-medium"
                                    >
                                      TOTAL PAID AMOUNT
                                    </th>
                                    <td className="p-3 border border-solid border-black/10">
                                      {feesDuesState?.TotPaid}
                                    </td>
                                  </tr>
                                  <tr className="bg-white dark:bg-gray-800">
                                    <th
                                      scope="row"
                                      className="p-3 border border-solid border-black/10 font-medium"
                                    >
                                      DUE AMOUNT
                                    </th>
                                    <td className="p-3 border border-solid border-black/10">
                                      {feesDuesState?.Balance}
                                    </td>
                                  </tr>
                                  <tr className="bg-white h-full dark:bg-gray-800">
                                    <th
                                      scope="row"
                                      className="p-3 h-full border border-solid border-black/10 font-medium"
                                    >
                                      &nbsp;
                                    </th>
                                    <td className="p-3 border h-full border-solid border-black/10">
                                      &nbsp;
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Accordion.Content>
                  </Accordion.Panel>
                  <Accordion.Panel className="w-full">
                    <Accordion.Title className="accordian-title mb-2 focus:outline-none uppercase border-0 focus:ring-0">
                      EXAMINATION TERM I
                    </Accordion.Title>
                    <Accordion.Content className="bg-transparent -mt-2 !p-0 transition-all duration-300 ease-custom-ease rounded-lg shadow-lg">
                      {/* <div className="w-full formBackground p-2 md:px-5">
                        <div className="flex flex-1 gap-5 md:flex-row flex-col">
                          <div className=" w-full lg:w-[50%]">
                            <div className="relative shadow-xl overflow-x-auto">
                              <table className="w-full text-[12px] leading-[14px] md:text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                                <tbody>
                                  <tr className="bg-white w-full">
                                    <th className="p-3 w-full flex justify-evenly items-center uppercase font-medium border border-solid border-black/10 text-gray-900">
                                      <span className="sm:text-base text-sm md:text-xl">TERM-I</span>
                                      <button className="buttonClass">Print Graph</button>
                                    </th>
                                  </tr>
                                  <tr className="bg-white w-full">
                                    <th className="font-medium grid grid-cols-2 p-0 m-0 w-full text-gray-900 ">
                                      <td className="p-3 border border-solid border-black/10">
                                        <button className="buttonClass">Show Marksheet</button>
                                      </td>
                                      <td className="p-3 border border-solid border-black/10"></td>
                                    </th>
                                    <th className="font-medium grid grid-cols-2 p-0 m-0 w-full text-gray-900 ">
                                      <td className="p-3 border border-solid border-black/10">OUT OF MARKS</td>
                                      <td className="p-3 border border-solid border-black/10">{Term1MarkState?.["Term 1 Max"]}</td>
                                    </th>
                                    <th className="font-medium grid grid-cols-2 p-0 m-0 w-full text-gray-900 ">
                                      <td className="p-3 border border-solid border-black/10">OBTAINED MARKS</td>
                                      <td className="p-3 border border-solid border-black/10">{Term1MarkState?.["Term 1 OBT"]}</td>
                                    </th>
                                    <th className="font-medium grid grid-cols-2 p-0 m-0 w-full text-gray-900 ">
                                      <td className="p-3 border border-solid border-black/10">TOTAL GRADE</td>
                                      <td className="p-3 border border-solid border-black/10">0.00</td>
                                    </th>
                                    <th className="font-medium grid grid-cols-2 p-0 m-0 w-full text-gray-900 ">
                                      <td className="p-3 border border-solid border-black/10">OVER ALL PERCENTAGE</td>
                                      <td className="p-3 border border-solid border-black/10">0.00</td>
                                    </th>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                          <div className=" w-full lg:w-[50%]">
                            <div className="relative shadow-xl overflow-x-auto">
                              <table className="w-full text-[12px] leading-[14px] md:text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                                <tbody>
                                  <tr className="bg-white w-full">
                                    <th className="p-3 w-full flex justify-evenly items-center uppercase font-medium border border-solid border-black/10 text-gray-900">
                                      <span className="sm:text-base text-sm md:text-xl">TERM-II</span>
                                      <button className="buttonClass">Print Graph</button>
                                    </th>
                                  </tr>
                                  <tr className="bg-white w-full">
                                    <th className="font-medium grid grid-cols-2 p-0 m-0 w-full text-gray-900 ">
                                      <td className="p-3 border border-solid border-black/10">
                                        <button className="buttonClass">Show Marksheet</button>
                                      </td>
                                      <td className="p-3 border border-solid border-black/10"></td>
                                    </th>
                                    <th className="font-medium grid grid-cols-2 p-0 m-0 w-full text-gray-900 ">
                                      <td className="p-3 border border-solid border-black/10">OUT OF MARKS</td>
                                      <td className="p-3 border border-solid border-black/10">{Term2MarkState?.["Term 1 Max"]}</td>
                                    </th>
                                    <th className="font-medium grid grid-cols-2 p-0 m-0 w-full text-gray-900 ">
                                      <td className="p-3 border border-solid border-black/10">OBTAINED MARKS</td>
                                      <td className="p-3 border border-solid border-black/10">{Term2MarkState?.["Term 1 OBT"]}</td>
                                    </th>
                                    <th className="font-medium grid grid-cols-2 p-0 m-0 w-full text-gray-900 ">
                                      <td className="p-3 border border-solid border-black/10">TOTAL GRADE</td>
                                      <td className="p-3 border border-solid border-black/10">0.00</td>
                                    </th>
                                    <th className="font-medium grid grid-cols-2 p-0 m-0 w-full text-gray-900 ">
                                      <td className="p-3 border border-solid border-black/10">OVER ALL PERCENTAGE</td>
                                      <td className="p-3 border border-solid border-black/10">0.00</td>
                                    </th>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div> */}
                      <div className="max-w-full formBackground h-full mx-auto border border-black border-solid rounded-sm">
                        <div className="w-full p-4">
                          {term1 && <PdfViewer url={term1} />}
                          {/* <PdfViewer url={term1} /> */}
                        </div>
                      </div>
                    </Accordion.Content>
                  </Accordion.Panel>

                  <Accordion.Panel className="w-full">
                    <Accordion.Title className="accordian-title mb-2 focus:outline-none uppercase border-0 focus:ring-0">
                      EXAMINATION TERM II
                    </Accordion.Title>
                    <Accordion.Content className="bg-transparent -mt-2 !p-0 transition-all duration-300 ease-custom-ease rounded-lg shadow-lg">
                      <div className="max-w-full formBackground h-full mx-auto border border-black border-solid rounded-sm">
                        <div className="w-full h-full p-4">
                          {term2 && <PdfViewer url={term2} />}
                          {/* <PdfViewer url={term2} /> */}
                        </div>
                      </div>
                    </Accordion.Content>
                  </Accordion.Panel>
                  <Accordion.Panel>
                    <Accordion.Title className="accordian-title mb-2 focus:outline-none uppercase border-0 focus:ring-0">
                      school fees installment vise
                    </Accordion.Title>
                    <Accordion.Content className="bg-transparent -mt-2 !p-0 transition-all duration-300 ease-custom-ease rounded-lg shadow-lg">
                      <div className="max-w-full formBackground p-2 h-full flex flex-wrap justify-between mx-auto border border-black border-solid rounded-sm">
                        <div className="relative max-h-[300px] overflow-auto border md:max-w-[500px] w-fit border-black shadow-md mx-auto sm:rounded-lg md:min-w-[600px] h-full">
                          <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 border-collapse">
                            <thead className="h-12 text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400 sticky top-0 z-10">
                              <tr className="tableHead">
                                <th scope="col" className="p-1">
                                  Installment
                                </th>
                                <th scope="col" className="p-1">
                                  Tot. Demand
                                </th>
                                <th scope="col" className="p-1">
                                  Concession
                                </th>
                                <th scope="col" className="p-1">
                                  To Pay
                                </th>
                                <th scope="col" className="p-1">
                                  Paid
                                </th>
                              </tr>
                            </thead>
                            <tbody className="text-gray-900">
                              {MonthWiseBelowTable?.map((item, i) => {
                                return (
                                  <tr
                                    key={i}
                                    className={`text-gray-900 ${
                                      i % 2 === 0 ? "bg-white" : "bg-gray-100"
                                    }`}
                                  >
                                    <td className="p-1 border">
                                      {item?.FEESHEAD}
                                    </td>
                                    <td className="p-1 border">
                                      {item?.DEMAND}
                                    </td>
                                    <td className="p-1 border">
                                      {item?.CONCESSION}
                                    </td>
                                    <td className="p-1 border">
                                      {item?.OLDDEMAND}
                                    </td>
                                    <td className="p-1 border">
                                      {item?.COLLECTION}
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>

                        <div className="relative max-h-[300px] overflow-auto border md:max-w-[500px] border-black shadow-md mx-auto sm:rounded-lg md:min-w-[600px] w-fit h-full">
                          <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 border-collapse">
                            <thead className="h-12 text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400 sticky top-0 z-10">
                              <tr className="tableHead">
                                <th scope="col" className="p-1">
                                  Sl No.
                                </th>
                                <th scope="col" className="p-1">
                                  Date
                                </th>
                                <th scope="col" className="p-1">
                                  Receipt No
                                </th>
                                <th scope="col" className="p-1">
                                  Amount
                                </th>
                                <th scope="col" className="p-1">
                                  Mode Of Pay
                                </th>
                                <th scope="col" className="p-1">
                                  Bounce
                                </th>
                                <th scope="col" className="p-1">
                                  Cncl
                                </th>
                                <th scope="col" className="p-1">
                                  CHQ. DATE
                                </th>
                                <th scope="col" className="p-1">
                                  Nature
                                </th>
                              </tr>
                            </thead>
                            <tbody className="text-gray-900">
                              {PaymentHistory?.map((item, i) => {
                                return (
                                  <tr
                                    key={i}
                                    className={`text-gray-900 ${
                                      i % 2 === 0 ? "bg-white" : "bg-gray-100"
                                    }`}
                                  >
                                    <td className="p-1 border">{item?.SLNO}</td>
                                    <td className="p-1 border">
                                      {convertDateString(item?.MDFDATE)}
                                    </td>
                                    <td className="p-1 border">
                                      {item?.RECEIPTNO}
                                    </td>
                                    <td className="p-1 border">
                                      {item?.CASHAMT}
                                    </td>
                                    <td className="p-1 border">
                                      {item?.MODEOFPY}
                                    </td>
                                    <td className="p-1 border">
                                      <input
                                        type="checkbox"
                                        className="size-5 me-2"
                                        checked={item?.CHQBNC}
                                      />
                                    </td>
                                    <td className="p-1 border">
                                      <input
                                        type="checkbox"
                                        className="size-5 me-2"
                                        checked={item?.CNCL}
                                      />
                                    </td>
                                    <td className="p-1 border">
                                      {convertDateString(item?.CHQDATE)}
                                    </td>
                                    <td className="p-1 border">
                                      {item?.CHQREMARK}
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </Accordion.Content>
                  </Accordion.Panel>
                  <Accordion.Panel>
                    <Accordion.Title className="accordian-title mb-2 focus:outline-none uppercase border-0 focus:ring-0">
                      transport fees installment vise
                    </Accordion.Title>
                    <Accordion.Content className="bg-transparent -mt-2 !p-0 transition-all duration-300 ease-custom-ease rounded-lg shadow-lg">
                      <div className="max-w-full formBackground p-2 h-full flex flex-wrap justify-between mx-auto border border-black border-solid rounded-sm">
                        <div className="relative max-h-[300px] overflow-auto border md:max-w-[500px] w-fit border-black shadow-md mx-auto sm:rounded-lg md:min-w-[600px] h-full">
                          <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 border-collapse">
                            <thead className="h-12 text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400 sticky top-0 z-10">
                              <tr className="tableHead">
                                <th scope="col" className="p-1">
                                  Installment
                                </th>
                                <th scope="col" className="p-1">
                                  Tot. Demand
                                </th>
                                <th scope="col" className="p-1">
                                  Concession
                                </th>
                                <th scope="col" className="p-1">
                                  To Pay
                                </th>
                                <th scope="col" className="p-1">
                                  Paid
                                </th>
                              </tr>
                            </thead>
                            <tbody className="text-gray-900">
                              {transportFee?.map((item, i) => {
                                return (
                                  <tr
                                    key={i}
                                    className={`text-gray-900 ${
                                      i % 2 === 0 ? "bg-white" : "bg-gray-100"
                                    }`}
                                  >
                                    <td className="p-1 border">
                                      {item?.FEESHEAD}
                                    </td>
                                    <td className="p-1 border">
                                      {item?.DEMAND}
                                    </td>
                                    <td className="p-1 border">
                                      {item?.CONCESSION}
                                    </td>
                                    <td className="p-1 border">
                                      {item?.OLDDEMAND}
                                    </td>
                                    <td className="p-1 border">
                                      {item?.COLLECTION}
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>

                        <div className="relative max-h-[300px] overflow-auto border md:max-w-[500px] border-black shadow-md mx-auto sm:rounded-lg md:min-w-[600px] w-fit h-full">
                          <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 border-collapse">
                            <thead className="h-12 text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400 sticky top-0 z-10">
                              <tr className="tableHead">
                                <th scope="col" className="p-1">
                                  Sl No.
                                </th>
                                <th scope="col" className="p-1">
                                  Date
                                </th>
                                <th scope="col" className="p-1">
                                  Receipt No
                                </th>
                                <th scope="col" className="p-1">
                                  Amount
                                </th>
                                <th scope="col" className="p-1">
                                  Mode Of Pay
                                </th>
                                <th scope="col" className="p-1">
                                  Bounce
                                </th>
                                <th scope="col" className="p-1">
                                  Cncl
                                </th>
                                <th scope="col" className="p-1">
                                  CHQ. DATE
                                </th>
                                <th scope="col" className="p-1">
                                  Nature
                                </th>
                              </tr>
                            </thead>
                            <tbody className="text-gray-900">
                              {transportFeeReceipt?.map((item, i) => {
                                return (
                                  <tr
                                    key={i}
                                    className={`text-gray-900 ${
                                      i % 2 === 0 ? "bg-white" : "bg-gray-100"
                                    }`}
                                  >
                                    <td className="p-1 border">{item?.SLNO}</td>
                                    <td className="p-1 border">
                                      {convertDateString(item?.MDFDATE)}
                                    </td>
                                    <td className="p-1 border">
                                      {item?.RECEIPTNO}
                                    </td>
                                    <td className="p-1 border">
                                      {item?.CASHAMT}
                                    </td>
                                    <td className="p-1 border">
                                      {item?.MODEOFPY}
                                    </td>
                                    <td className="p-1 border">
                                      <input
                                        type="checkbox"
                                        className="size-5 me-2"
                                        checked={item?.CHQBNC}
                                      />
                                    </td>
                                    <td className="p-1 border">
                                      <input
                                        type="checkbox"
                                        className="size-5 me-2"
                                        checked={item?.CNCL}
                                      />
                                    </td>
                                    <td className="p-1 border">
                                      {convertDateString(item?.CHQDATE)}
                                    </td>
                                    <td className="p-1 border">
                                      {item?.CHQREMARK}
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </Accordion.Content>
                  </Accordion.Panel>
                  <Accordion.Panel>
                    <Accordion.Title className="accordian-title mb-2 focus:outline-none uppercase border-0 focus:ring-0">
                      Activity Details
                    </Accordion.Title>
                    <Accordion.Content className="bg-transparent -mt-2 !p-0 transition-all duration-300 ease-custom-ease rounded-lg shadow-lg">
                      <div className="max-w-full formBackground p-2 h-full flex flex-wrap justify-between mx-auto border border-black border-solid rounded-sm">
                        <div className="relative max-h-[300px] overflow-auto border md:max-w-full border-black shadow-md mx-auto sm:rounded-lg md:min-w-full w-fit h-full">
                          <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 border-collapse">
                            <thead className="h-12 text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400 sticky top-0 z-10">
                              <tr className="tableHead">
                                <th scope="col" className="p-1">
                                  Sl No.
                                </th>
                                <th scope="col" className="p-1">
                                  Activty
                                </th>
                                <th scope="col" className="p-1">
                                  Level
                                </th>
                                <th scope="col" className="p-1">
                                  Grade
                                </th>
                                <th scope="col" className="p-1">
                                  Year
                                </th>
                                <th scope="col" className="p-1">
                                  Remarks
                                </th>
                              </tr>
                            </thead>
                            <tbody className="text-gray-900">
                              {activityTab?.map((item, i) => {
                                return (
                                  <tr
                                    key={i}
                                    className={`text-gray-900 ${
                                      i % 2 === 0 ? "bg-white" : "bg-gray-100"
                                    }`}
                                  >
                                    <td className="p-1 border">{i}</td>
                                    <td className="p-1 border">
                                      <input
                                        type="text"
                                        className="textInput !w-fit"
                                        value={item?.Activity}
                                      />
                                    </td>
                                    <td className="p-1 border">
                                      <input
                                        type="text"
                                        className="textInput !w-fit"
                                        value={item?.ActLevel}
                                      />
                                    </td>
                                    <td className="p-1 border">
                                      <input
                                        type="text"
                                        className="textInput !w-fit"
                                        value={item?.ActGradePosition}
                                      />
                                    </td>
                                    <td className="p-1 border">
                                      <input
                                        type="text"
                                        className="textInput !w-fit"
                                        value={item?.ActYear}
                                      />
                                    </td>
                                    <td className="p-1 border">
                                      <input
                                        type="text"
                                        className="textInput !w-fit"
                                        value={item?.ActRemarks}
                                      />
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </Accordion.Content>
                  </Accordion.Panel>
                  {/* <Accordion.Panel className="w-full">
                    <Accordion.Title className="accordian-title mb-2 focus:outline-none uppercase border-0 focus:ring-0">
                      EXAMINATION (CO-SCHOLOSTIC AREA)
                    </Accordion.Title>
                    <Accordion.Content className="bg-transparent -mt-2 !p-0 transition-all duration-300 ease-custom-ease rounded-lg shadow-lg">
                      <div className="w-full formBackground p-2 md:px-5">
                        <div className="flex flex-1 gap-5 md:flex-row flex-col">
                          <div className=" w-full lg:w-[50%]">
                            <div className="relative shadow-xl overflow-x-auto">
                              <table className="w-full text-[12px] leading-[14px] md:text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                                <tbody>
                                  <tr className="bg-white w-full">
                                    <th className="p-3 w-full flex justify-evenly items-center uppercase font-medium border border-solid border-black/10 text-gray-900">
                                      <span className="sm:text-base text-sm md:text-xl">TERM-I</span>
                                    </th>
                                  </tr>
                                  <tr className="bg-white w-full">
                                    <th className="font-medium grid grid-cols-2 p-0 m-0 w-full text-gray-900 ">
                                      <td className="p-3 border border-solid border-black/10">WORK EDUCATION</td>
                                      <td className="p-3 border border-solid border-black/10">{WEState?.["Term 1 Grade"]}</td>
                                    </th>
                                    <th className="font-medium grid grid-cols-2 p-0 m-0 w-full text-gray-900 ">
                                      <td className="p-3 border border-solid border-black/10">ART EDUCATION</td>
                                      <td className="p-3 border border-solid border-black/10">{AEState?.["Term 1 Grade"]}</td>
                                    </th>
                                    <th className="font-medium grid border border-solid border-black/10 grid-cols-2 p-0 m-0 w-full text-gray-900 ">
                                      <td className="p-3 ">&nbsp;</td>
                                    </th>
                                    <th className="font-medium grid grid-cols-2 p-0 m-0 w-full text-gray-900 ">
                                      <td className="p-3 border border-solid border-black/10">TERM-I ATTENDANCE</td>
                                      <td className="p-3 border border-solid border-black/10">{AttendanceState?.["Term 1 Att"]}</td>
                                    </th>
                                    <th className="font-medium grid grid-cols-2 p-0 m-0 w-full text-gray-900 ">
                                      <td className="p-3 border border-solid border-black/10">TERM-II ATTENDANCE</td>
                                      <td className="p-3 border border-solid border-black/10">{AttendanceState?.["Term 2 Att"]}</td>
                                    </th>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                          <div className=" w-full lg:w-[50%]">
                            <div className="relative shadow-xl overflow-x-auto">
                              <table className="w-full text-[12px] leading-[14px] md:text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                                <tbody>
                                  <tr className="bg-white w-full">
                                    <th className="p-3 w-full flex justify-evenly items-center uppercase font-medium border border-solid border-black/10 text-gray-900">
                                      <span className="sm:text-base text-sm md:text-xl">TERM-II</span>
                                    </th>
                                  </tr>
                                  <tr className="bg-white w-full">
                                    <th className="font-medium grid grid-cols-2 p-0 m-0 w-full text-gray-900 ">
                                      <td className="p-3 border border-solid border-black/10">WORK EDUCATION</td>
                                      <td className="p-3 border border-solid border-black/10">{WEState?.["Term 2 Grade"]}</td>
                                    </th>
                                    <th className="font-medium grid grid-cols-2 p-0 m-0 w-full text-gray-900 ">
                                      <td className="p-3 border border-solid border-black/10">ART EDUCATION</td>
                                      <td className="p-3 border border-solid border-black/10">{AEState?.["Term 2 Grade"]}</td>
                                    </th>
                                    <th className="font-medium grid border border-solid border-black/10 grid-cols-2 p-0 m-0 w-full text-gray-900 ">
                                      <td className="p-3 ">&nbsp;</td>
                                    </th>
                                    <th className="font-medium grid grid-cols-2 p-0 m-0 w-full text-gray-900 ">
                                      <td className="p-3 border border-solid border-black/10">HEIGHT</td>
                                      <td className="p-3 border border-solid border-black/10">{HeightWeightState?.["Height"]}</td>
                                    </th>
                                    <th className="font-medium grid grid-cols-2 p-0 m-0 w-full text-gray-900 ">
                                      <td className="p-3 border border-solid border-black/10">WEIGH</td>
                                      <td className="p-3 border border-solid border-black/10">{HeightWeightState?.["Weight"]}</td>
                                    </th>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Accordion.Content>
                  </Accordion.Panel> */}
                </Accordion>
                <div className="md:flex no-wrap md:-mx-2 ">
                  {/* stud data table */}
                  <div className="relative gap-5 overflow-x-auto w-full flex py-2 flex-row flex-wrap">
                    {/* profile & calender */}
                    <div className="mx-auto h-fit bg-sky-100 rounded-xl shadow-lg md:w-1/2 w-full"></div>
                  </div>
                </div>
              </div>
              {/* student details fee details */}
              {/* <div className="container bg-sky-100 shadow-xl rounded-md p-3 mx-auto my-5">
                <StudentProfileFeeDetailsTable />
                <StudentProfileExaminationTable />
                <StudentProfileExaminationSCHOLOSTICTable />
              </div> */}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default QuicKInfoStudentProfile;
