import React, { createContext, useState, useContext } from 'react';

const SidebarContext = createContext();

export const SidebarProvider = ({ children }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [currentSidebarTab, setCurrentSidebarTab] = useState(null);

  const toggleSidebar = (tab) => {
    if (isSidebarOpen && currentSidebarTab === tab) {
      setIsSidebarOpen(false);
    } else {
      setIsSidebarOpen(true);
      setCurrentSidebarTab(tab);
    }
  };

  return (
    <SidebarContext.Provider value={{ isSidebarOpen, currentSidebarTab, toggleSidebar,setIsSidebarOpen }}>
      {children}
    </SidebarContext.Provider>
  );
};

export const useSidebar = () => useContext(SidebarContext);
