import React, { useState } from "react";
import { Link } from "react-router-dom";
import { IoIosArrowDropdown } from "react-icons/io";
import Navbar from "./Navbar";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  AdminArray,
  StudentArray,
  userDetailsDataValues,
} from "../data/adminMenu";

const SidebarOld = ({ data }) => {
  const [isOpen, setIsOpen] = useState(true);
  const [visible, setVisible] = useState(false);
  const [visibleNav, setVisibleNav] = useState(false);
  const [showad, setShowAd] = useState(true);
  const [userDetails, setUserDetails] = useState(null);
  const [isOpenHamberg, setIsOpenHamberg] = useState(false);
  const [resultArray, setResultArray] = useState([]);
  const [menuItems, setMenuItems] = useState([]);
  const [qlItems, setqltems] = useState([]);
  const [sidebarVisible, setSidebarVisible] = useState(true);

  const changeShow = () => {
    setShowAd(true);
  };

  const { customId } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      if (customId) {
        const decodedData = JSON.parse(atob(customId));

        const result = await userDetailsDataValues(decodedData);

        const menuItems = result.filter((item) => item.type === "menu");
        setMenuItems(menuItems);

        const qlItems = result.filter((item) => item.type === "ql");

        setqltems(qlItems);

        setResultArray(result);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const decodedData = JSON.parse(atob(customId));

    setUserDetails(decodedData);
  }, [customId]);

  return (
    <>
      {/* <div class="bg-slate-700 overflow-y-auto hidden md:inline-block fixed  left-0 text-white shadow-md  customHeight  customWidth rounded-sm"> */}

      {/* <div className='mt-8 mx-7'>

   <h3 className=' text-lg font-semibold'>Menu</h3>
     
        <ul className='bg-red-500 mt-5 h-96'>
             <div>
                  <li>
                    
                    <span>Dashboard</span>

                  </li>
                  <li>

                    <span>Calendar</span>

                  </li>
             </div>
        </ul>
 </div> */}

      <div
        className={`fixed ${
          isOpenHamberg ? "" : " md:block  "
        }  bg-slate-700 shadow-md overflow-y-hidden md:w-[20%]  md:block text-white customWidth    justify-center px-3 py-3  top-20 customHeightSidebar  left-0 z-50 md:z-0`}
      >
        <h3 className="font-semibold text-3xl customFont shadow-md text-center px-3 py-2">
          {userDetails && userDetails.userType}

          <span className="ml-1"> Login</span>
        </h3>
        {/* Your content here */}

        <div className="overflow-y-hidden content overflow-x-hidden flex-grow">
          <ul className="flex flex-col py-2 space-y-1">
            <li className="px-3"></li>

            <Link to={`/dashboard/u/${customId}`} onClick={changeShow}>
              <a
                href="#"
                className="relative flex flex-row items-center h-11 focus:outline-none  text-white  border-l-4 border-transparent hover:border-indigo-500 pr-6"
              >
                <span className="inline-flex justify-center items-center ml-4">
                  <svg
                    className="w-5 h-5"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
                    ></path>
                  </svg>
                </span>
                <span className="ml-2 text-sm tracking-wide  font-semibold truncate">
                  Dashboard
                </span>
              </a>
            </Link>

            <Link onClick={() => setIsOpen(!isOpen)} to="">
              <a
                href="#"
                className="relative flex flex-row items-center h-11 focus:outline-none  text-white  border-l-4 border-transparent hover:border-indigo-500 pr-6"
              >
                <span className="inline-flex justify-center items-center ml-4">
                  <svg
                    className="w-5 h-5"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"
                    ></path>
                  </svg>
                </span>
                <span className="ml-2  font-semibold text-sm tracking-wide truncate">
                  Options
                </span>
                <div className="absolute right-2">
                  <IoIosArrowDropdown className="h-6 w-6 ml-3" />
                </div>
              </a>
            </Link>

            {isOpen && userDetails && userDetails.userType === "Admin" && (
              <div className="ml-6 cursor-pointer bg-slate-700 px-4 py-1 border-1 shadow-inner shadow-slate-500 items-center max-h-64 overflow-y-auto">
                {menuItems &&
                  menuItems.map((item, index) => (
                    <Link
                      to={`/admin/u/${item.keyName}/${customId}`}
                      key={index}
                      className="my-2 text-sm font-medium flex items-center"
                    >
                      <span className="">{item.caption}</span>
                    </Link>
                  ))}
              </div>
            )}

            {isOpen && userDetails && userDetails.userType === "Student" && (
              <div className="ml-6 cursor-pointer bg-slate-700 px-4 py-1 border-1 shadow-inner shadow-slate-500 items-center max-h-64 overflow-y-auto">
                {menuItems &&
                  menuItems.map((item, index) => (
                    <Link
                      to={`/student/u/${item.keyName}/${customId}`}
                      key={index}
                      className="my-2 text-sm font-medium flex items-center"
                    >
                      <span className="">{item.caption}</span>
                    </Link>
                  ))}
              </div>
            )}

            {isOpen && userDetails && userDetails.thType === "CT" && (
              <div className="ml-6 cursor-pointer bg-slate-700 px-4 py-1 border-1 shadow-inner shadow-slate-500 items-center max-h-64 overflow-y-auto">
                {menuItems &&
                  menuItems.map((item, index) => (
                    <Link
                      to={`/classteacher/u/${item.keyName}/${customId}`}
                      key={index}
                      className="my-2 text-sm font-medium flex items-center"
                    >
                      <span className="">{item.caption}</span>
                    </Link>
                  ))}
              </div>
            )}

            {isOpen && userDetails && userDetails.thType === "ST" && (
              <div className="ml-6 cursor-pointer bg-slate-700 px-4 py-1 border-1 shadow-inner shadow-slate-500 items-center max-h-64 overflow-y-auto">
                {menuItems &&
                  menuItems.map((item, index) => (
                    <Link
                      to={`/subjectteacher/u/${item.keyName}/${customId}`}
                      key={index}
                      className="my-2 text-sm font-medium flex items-center"
                    >
                      <span className="">{item.caption}</span>
                    </Link>
                  ))}
              </div>
            )}

            {qlItems && qlItems.length > 0 && (
              <Link onClick={() => setVisible(!visible)} to="">
                <a
                  href="#"
                  className="relative flex flex-row items-center h-11 focus:outline-none  text-white  border-l-4 border-transparent hover:border-indigo-500 pr-6"
                >
                  <span className="inline-flex justify-center items-center ml-4">
                    <svg
                      className="w-5 h-5"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
                      ></path>
                    </svg>
                  </span>
                  <span className="ml-2 font-semibold text-sm tracking-wide truncate">
                    Quick Links
                  </span>

                  <div className="absolute right-2">
                    <IoIosArrowDropdown className="h-6 w-6 ml-3" />
                  </div>
                </a>
              </Link>
            )}

            {visible &&
              userDetails &&
              userDetails.userType === "Admin" &&
              qlItems &&
              qlItems.length > 0 && (
                <div className="ml-6 cursor-pointer bg-slate-700 px-4 py-1 border-1 shadow-inner shadow-slate-500 items-center">
                  {qlItems.map((item, index) => (
                    <Link
                      to={item.redirect}
                      key={index}
                      className="my-3 text-sm font-medium cursor-pointer"
                    >
                      {item.caption}
                    </Link>
                  ))}
                </div>
              )}

            {visible &&
              userDetails &&
              userDetails.userType === "Student" &&
              qlItems &&
              qlItems.length > 0 && (
                <div className="ml-6 cursor-pointer bg-slate-700 px-4 py-1 border-1 shadow-inner shadow-slate-500 items-center">
                  {qlItems.map((item, index) => (
                    <Link
                      to={item.redirect}
                      key={index}
                      className="my-3 text-sm font-medium cursor-pointer"
                    >
                      {item.caption}
                    </Link>
                  ))}
                </div>
              )}

            {visible &&
              userDetails &&
              userDetails.thType === "CT" &&
              qlItems &&
              qlItems.length > 0 && (
                <div className="ml-6 cursor-pointer bg-slate-700 px-4 py-1 border-1 shadow-inner shadow-slate-500 items-center">
                  {qlItems.map((item, index) => (
                    <Link
                      to={item.redirect}
                      key={index}
                      className="my-3 text-sm font-medium cursor-pointer"
                    >
                      {item.caption}
                    </Link>
                  ))}
                </div>
              )}

            {visible &&
              userDetails &&
              userDetails.thType === "ST" &&
              qlItems &&
              qlItems.length > 0 && (
                <div className="ml-6 cursor-pointer bg-slate-700 px-4 py-1 border-1 shadow-inner shadow-slate-500 items-center">
                  {qlItems.map((item, index) => (
                    <Link
                      to={item.redirect}
                      key={index}
                      className="my-3 text-sm font-medium cursor-pointer"
                    >
                      {item.caption}
                    </Link>
                  ))}
                </div>
              )}

            <li>
              <a
                href="#"
                className="relative flex flex-row items-center h-11 focus:outline-none  text-white  border-l-4 border-transparent hover:border-indigo-500 pr-6"
              >
                <span className="inline-flex justify-center items-center ml-4">
                  <svg
                    className="w-5 h-5"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
                    ></path>
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                    ></path>
                  </svg>
                </span>
                <span className="ml-2 text-sm  font-semibold tracking-wide truncate">
                  Settings
                </span>
              </a>
            </li>
            <li>
              <Link
                to="/"
                className="relative flex flex-row items-center h-11 focus:outline-none  text-white  border-l-4 border-transparent hover:border-indigo-500 pr-6"
              >
                <span className="inline-flex justify-center items-center ml-4">
                  <svg
                    className="w-5 h-5"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                    ></path>
                  </svg>
                </span>
                <span className="ml-2 text-sm  font-semibold tracking-wide truncate">
                  Logout
                </span>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default SidebarOld;
