import React from "react";
import axios from "axios";
import toast from "react-toastify";
import { getUserDataFromLocalByKey } from "./getStudentInfoByKeyLocal";

let UserName;
let UserCode;
const user = localStorage.getItem("UserRights");
const parts = user?.split("_");
if (parts?.length > 0) {
  UserName = parts?.[0];
  UserCode = parts?.[2];
}

export const getQuickInfoStaffStrengthAPI = async (dept = "", desgn = "", accCat = "", type = "", disp = "0", empCat = "") => {
  try {
    const returnUrl = process.env.REACT_APP_ERP_QUICKINFO_PAGE;

    const formData = new FormData();
    formData.append("title", "GetStaffSummaryERPQI");
    formData.append("description", "Checking");
    formData.append("Reqdisptype", disp);
    formData.append("ReqEmpType", type);
    formData.append("ReqEmpDept", dept);
    formData.append("ReqEmpAccCat", accCat);
    formData.append("ReqDesig", desgn);
    formData.append("ReqEmpCategory", empCat);

    let userdata = [];
    const Corskey = process.env.REACT_APP_CORS_URL;
    const result = await axios.post(`${Corskey}${returnUrl}`, formData);
    userdata = JSON.parse(result.data.substring(0, result.data.indexOf("||JasonEnd", 0)));
    return userdata;
  } catch (error) {
    console.error("An error occurred:", error);
  }
};
