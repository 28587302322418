import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import DemoStudentImage from '../assets/png/avatar.png'; // Import a demo student image
import Navbar from '../components/Navbar';
import Sidebar from '../components/SidebarOld';

const StudentList = () => {
  const { className, section } = useParams();
  // Fetch or use data for students in this class and section
  const [sidebarVisible, setSidebarVisible] = useState(true);

  const toggleSidebar = () => {
    setSidebarVisible(!sidebarVisible);
  };
  // Create a JSON array with different sets of students for each section
  const studentLists = {
    A: [
      {
        id: 1,
        name: 'Student A1',
        admissionNo: 'A1234',
        fatherName: 'Father A1',
        phoneNumber: '1234567890',
      },
      // Add more students for section A
    ],
    B: [
      {
        id: 1,
        name: 'Student B1',
        admissionNo: 'B1234',
        fatherName: 'Father B1',
        phoneNumber: '1234567890',
      },
      // Add more students for section B
    ],
    C: [
        {
          id: 1,
          name: 'Student C1',
          admissionNo: 'C1234',
          fatherName: 'Father C1',
          phoneNumber: '1234567890',
        },
        // Add more students for section B
      ],
      D: [
        {
          id: 1,
          name: 'Student D1',
          admissionNo: 'D1234',
          fatherName: 'Father D1',
          phoneNumber: '1234567890',
        },
        // Add more students for section B
      ],
      E: [
        {
          id: 1,
          name: 'Student E1',
          admissionNo: 'E1234',
          fatherName: 'Father E1',
          phoneNumber: '1234567890',
        },
        // Add more students for section B
      ],
    // Define similar structures for sections C, D, and E
    // ...
  };

  // Get the list of students based on the selected section
  const students = studentLists[section] || [];

  return (
   <>

    <Navbar isOpen={sidebarVisible} toggleSidebar={toggleSidebar} />
    
    
    <div className='flex'>

    {sidebarVisible && <Sidebar />}

    <div className='w-full absolute right-0 top-16 md:w-3/4 lg:w-4/5 xl:w-4/5 p-4 md:p-6 lg:p-8 xl:p-10'>
      <div className="max-w-5xl mx-auto bg-slate-50 shadow-sm px-6 py-3">
        <h1 className="text-3xl mt-4 font-semibold mb-2">{`Class: ${className} - Section: ${section}`}</h1>
        {/* Display list of students with their details */}
        <div className="overflow-y-auto customHeightStudentsList">
          {students.map((student) => (
            <div
              key={student.id}
              className="bg-white rounded-lg flex justify-center flex-col overflow-hidden shadow-md border border-gray-200 mb-2"
            >
              <div className="flex items-center p-4">
                <img
                  src={DemoStudentImage}
                  alt="Student"
                  className="w-14 h-14 object-cover rounded-full mr-4"
                />
                <div>
                  <div className="font-bold text-xl mb-1">{student.name}</div>
                  <p>Admission No: {student.admissionNo}</p>
                  <p>Father's Name: {student.fatherName}</p>
                  <p>Phone Number: {student.phoneNumber}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>

    </div>
    
  



    

   </>
   
  );
};

export default StudentList;
