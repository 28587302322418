/* eslint-disable no-unused-vars */

import "datatables.net-buttons-dt/css/buttons.dataTables.css";
import "datatables.net-buttons-dt/css/buttons.dataTables.min.css";
import "datatables.net-buttons-dt/js/buttons.dataTables.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import "datatables.net-buttons/js/dataTables.buttons.js";
import DataTable from "datatables.net-dt";
import "datatables.net-dt/css/dataTables.dataTables.css";
import "datatables.net-responsive-dt";
import "datatables.net-scroller";
import "datatables.net-scroller-dt/css/scroller.dataTables.css";
import "datatables.net-scroller-dt/css/scroller.dataTables.min.css";
import "datatables.net-scroller-dt/js/scroller.dataTables.js";
import "datatables.net-scroller/js/dataTables.scroller.js";
import "datatables.net-select/js/dataTables.select.js";

import JSZip from "jszip";
import PDFMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  MdOutlineKeyboardArrowDown,
  MdOutlineKeyboardArrowUp,
} from "react-icons/md";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

// import { getQuickInfoClassWiseDetails } from "../../../../API/Project/Admin/QuickInfo/getQuickInfoClassWiseDetails";
// import StudentImage from "../../../../assets/Dashboard/StudentProfile.jpg";
// import stdProfile from "../../../../assets/icons/project/studentProfile.svg";

import { GetClassSectionMasterLists } from "../../API/GetClassSectionMasterLists";
import { getQuickInfoClassWiseDetails } from "../../API/getQuickInfoClassWiseDetails";
import Spinner from "../../components/Spinner";

window.JSZip = JSZip;
PDFMake.vfs = pdfFonts.pdfMake?.vfs;

const columns = [
  { index: 0, title: "Select", data: "select", visible: true },
  { index: 1, title: "Student", data: "ImageFile", visible: true },
  { index: 2, title: "Roll No.", data: "RollNo", visible: true },
  { index: 3, title: "Adm No.", data: "AdmissionNo", visible: true },
  { index: 4, title: "Name", data: "StudentName", visible: true },
  { index: 4, title: "Class", data: "Class", visible: true },
  { index: 4, title: "Section", data: "Section", visible: true },
  { index: 4, title: "SMS Ph. No.", data: "SMSPhone", visible: true },
  { index: 4, title: "Status", data: "Status", visible: true },
  { index: 4, title: "Father Name", data: "FatherName", visible: true },
  { index: 4, title: "Mother Name", data: "MotherName", visible: true },
  { index: 4, title: "New", data: "New", visible: true },
  { index: 4, title: "RTE", data: "RTE", visible: true },
  { index: 4, title: "Concession", data: "Concession", visible: true },
  { index: 5, title: "Show Profile", data: "sp", visible: true },
];

const QuickInfoStudentsList = () => {
  const { customId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [active, setActive] = useState("0");
  const [stuckOff, setStuckOff] = useState("0");
  const [quickInfoList, setQuickInfoList] = useState([]);
  const [classAndSub, setClassAndSub] = useState([]);
  const [classList, setClassList] = useState([]);
  const [sectionList, setSectionList] = useState(location?.state?.sectionList);
  const [selectedClass, setSelectedClass] = useState(
    location?.state?.selectedClass
  );
  const [selectedSection, setSelectedSection] = useState("");
  const [isClosed, setIsClosed] = useState(false);
  const [spin, setSpin] = useState(false);
  const [dispType, setDispType] = useState("0");
  const [userDetails, setUserDetails] = useState(null);

  const [tableCol, setTableCol] = useState(columns);
  const [tableData, setTableData] = useState([]);
  let tableRef = useRef(null);
  let tableInstance = useRef(null);

  const tableHead = [
    "Select",
    "Student",
    "Roll No.",
    "Adm No.",
    "Name",
    "Class",
    "Section",
    "SMS Ph. No.",
    "Status",
    "Father Name",
    "Mother Name",
    "New",
    "RTE",
    "Concession",
    "Show Profile",
  ];

  useEffect(() => {
    // setLoading(true)
    const fetchDataUser= async () => {
      setLoading(true);
      const users = JSON.parse(localStorage.getItem("registeredUsers")) || [];

      if (customId) {
        const decodedData = JSON.parse(atob(customId));
        let matchedItem = [];

        users.forEach((innerArray) => {
          const [userData] = innerArray;

          if (
            userData.userId === decodedData.userId &&
            userData.userType === decodedData.userType &&
            userData.schoolId === decodedData.schoolId &&
            userData.year === decodedData.year
          ) {
            matchedItem.push(userData);
          }
        });
        handleClassAndSectionFetchGlobal(
          decodedData.userId,
          decodedData.schoolId,
          decodedData.userType,
          decodedData.year
        );
        setUserDetails(matchedItem);

        setLoading(false);
      }
    };

    fetchDataUser();
  }, [customId]);

  const fetchData = async () => {
    try {
      setLoading(true);
      if (selectedClass) {
        const result = await getQuickInfoClassWiseDetails(
          userDetails[0].year,
          selectedClass,
          selectedSection,
          dispType
        );
        setQuickInfoList(result);
        // console.log(result[0]);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleClassAndSectionFetchGlobal = async (
    userId,
    schoolId,
    userType,
    year
  ) => {
    setLoading(true);
    try {
      const respone = await GetClassSectionMasterLists(
        userId,
        schoolId,
        userType,
        year
      );
      const ClassList = respone.filter(
        (item) => item.MasterType === "ClassMaster"
      );

      setClassAndSub(ClassList);
      setClassList(ClassList.map((item) => item.Head1));
      setLoading(false);
    } catch (error) {
      console.log(error);
      toast.error("Error");
      setLoading(false);
    }
  };

  const handleClassChange = (e) => {
    if (classAndSub.length > 0) {
      const userSelectedClass = classAndSub.filter(
        (items) => items.Head1 === e.target.value
      );
      const sectionList = userSelectedClass.flatMap((item) =>
        item.Head2.split(",")
      );
      if (sectionList?.length > 0) {
        setSectionList(sectionList);
      } else {
        setSectionList(["A", "B", "C", "D"]);
      }
    }
    setSelectedClass(e.target.value);
  };

  const handleSectionChange = useCallback(
    (e) => {
      setSelectedSection(e.target.value);
    },
    [setSelectedSection]
  );

//   useEffect(() => {
//     handleClassAndSectionFetchGlobal();
//   }, []);

  useEffect(() => {
    if (selectedClass&&userDetails) fetchData();
  }, [userDetails]);

  const calculateSums = useCallback(() => {
    let activeCount = 0;
    let struckOffCount = 0;

    quickInfoList.forEach((item) => {
      if (item.STS === "A") {
        activeCount++;
      } else if (item.STS === "T") {
        struckOffCount++;
      }
    });

    setActive(activeCount);
    setStuckOff(struckOffCount);
  }, [quickInfoList]);

  const TableData = quickInfoList.map((student) => ({
    AdmissionNo: student.ADMNO,
    StudentName: student.SNAME,
    FatherName: student.FATHERNAME,
    MotherName: student.MNAME,
    SMSPhone: student.SMSPHONE,
    RollNo: student.RNO,
    Class: student.CLNAME,
    Section: student.SECNAME,
    New: student.NEWSTDYESNO,
    Status: student.STS,
    RTE: student.RTIYN,
    Concession: student.CONCETYPE,
    ImageFile: student?.ImageFile,
  }));

  window.handleShowDetails = (logourl) => {
    // a function on datatable
    navigate(`/admin/u/${customId}/QuickInfo/student_profile`, {
      state: {
        admno: logourl,
      },
    });
  };

  const TableData2 = quickInfoList.map((student) => {
    let url = `${process.env.REACT_APP_ERP_STUDENT_IMAGE_URL}${student?.ImageFile}`;
    return {
      AdmissionNo: `<div class="flex flex-col gap-1">
      <p>${student.ADMNO}</p>
      <button onClick="handleShowDetails('${student.ADMNO}')" class="buttonClass text-xs uppercase font-montserrat font-medium">view</button>
      </div>`,
      StudentName: student.SNAME,
      select: `<input type="radio" class="radioButton" name="group1" />`,
      FatherName: student.FATHERNAME,
      MotherName: student.MNAME,
      SMSPhone: student.SMSPHONE,
      RollNo: student.RNO,
      Class: student.CLNAME,
      Section: student.SECNAME,
      New: `<input type="checkbox" ${student?.NEWSTDYESNO ? "checked" : ""} />`,
      Status: student.STS,
      RTE: `<input type="checkbox" ${student?.RTIYN ? "checked" : ""} />`,
      Concession: student.CONCETYPE,
      sp: `<p
            onClick="handleShowDetails('${student.ADMNO}')"
            class='text-blue-500 cursor-pointer text-sm font-medium capitalize'
          >
            Show Details
          </p>`,
      ImageFile: `<img src="${url}" alt="Student Imae" className="rounded-full !size-4" />`,
    };
  });

  useEffect(() => {
    // Initialize DataTable when the component mounts
    if (tableCol && TableData2) {
      tableInstance.current = new DataTable("#studentDetails", {
        responsive: false,
        // dom: "Bfrtip",
        layout: {
          topStart: {
            buttons: [
              "excel",
              "csv",
              "xls",
              "pdf",
              {
                extend: "print",
                exportOptions: {
                  columns: ":visible",
                },
              },
              "colvis",
            ],
          },
          bottomEnd: {
            paging: {
              type: "simple_numbers",
            },
          },
        },
        pageLength: 10,
        lengthMenu: [10, 25, 50],
        select: false,
        info: "",
        scrollY: 500,
        scrollX: true,
        deferRender: true,
        scroller: true,
        data: TableData2,
        columns: tableCol,
      });
    }
    // Cleanup DataTable instance when the component unmounts
    return () => {
      if (tableInstance.current !== null) {
        tableInstance.current.destroy();
      }
    };
  }, [TableData2, tableCol]);

  const handleCloseClick = () => {
    setTimeout(() => setIsClosed(!isClosed), 250);
  };

  return (
    <>
        {loading && <Spinner />}
      <div className="lg:mt-0 mt-5">
        <div className="container mx-auto p-2 md:p-4 containerBoxShadow">
          <div className="">
            <div className="flex justify-start w-full rounded-md bg-gradient-to-r from-cyan-400 to-blue-400 hover:bg-gradient-to-bl">
              <h2 className="w-full text-center text-2xl p-0 my-auto uppercase text-black font-mono font-semibold">
                Students List
              </h2>
              <button
                onClick={handleCloseClick}
                className={`w-fit rounded-md p-2 `}
              >
                <MdOutlineKeyboardArrowUp
                  className={`duration-500 ease-ease-in-out text-black transition-all ${
                    isClosed ? "rotate-180" : ""
                  } `}
                  size={25}
                />
              </button>
            </div>
            <div
              className={`transition-all duration-700 ease-ease-in ${
                !isClosed ? "opacity-100 max-h-screen" : "opacity-0 max-h-0"
              }`}
            >
              <div className="flex items-start flex-col justify-between gap-5 flex-wrap rounded">
                <div className="flex flex-col w-full mt-2">
                  <div className="flex md:flex-row p-3 px-3 md:px-7 lg:px-10 gap-0 md:gap-5 formBackground w-full justify-between flex-col">
                    <div className="w-full md:w-1/2">
                      <div className=" flex flex-row items-center gap-2 mb-1 justify-between">
                        <label
                          className="block uppercase tracking-wide text-start text-grey-darker text-xs font-bold mb-2"
                          htmlFor="grid-state"
                        >
                          Class
                        </label>
                        <div className="relative flex flex-row ">
                          <select
                            onChange={handleClassChange}
                            value={selectedClass}
                            className="textInput min-w-20 w-[150px] md:w-[190px]"
                          >
                            <option value="">--select--</option>
                            {classList?.map((data, i) => (
                              <option key={i} value={data}>
                                {data}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className=" flex flex-row items-center gap-2 mb-1 justify-between">
                        <label
                          className="block uppercase tracking-wide text-start text-grey-darker text-xs font-bold mb-2"
                          htmlFor="grid-state"
                        >
                          Section
                        </label>
                        <div className="relative flex flex-row ">
                          <select
                            onChange={handleSectionChange}
                            value={selectedSection}
                            className="textInput min-w-20 w-[150px] md:w-[190px]"
                          >
                            <option value="">--select--</option>
                            {sectionList?.map((data, i) => (
                              <option key={i} value={data}>
                                {data}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="w-full md:w-1/2">
                      <div className=" flex flex-row items-center gap-2 mb-1 justify-between">
                        <label
                          className="block uppercase tracking-wide text-start text-grey-darker text-xs font-bold mb-2"
                          htmlFor="grid-state"
                        >
                          Display Type
                        </label>
                        <div className="relative flex flex-row ">
                          <select
                            onChange={(e) => setDispType(e.target.value)}
                            value={dispType}
                            className="textInput min-w-20 w-[150px] md:w-[190px]"
                          >
                            <option value="0">All</option>
                            <option value="1">Active</option>
                            <option value="2">Stuck Off</option>
                            <option value="2">TC Given</option>
                          </select>
                        </div>
                      </div>
                      <div className="flex w-full justify-end items-center">
                        <button
                          type="button"
                          onClick={fetchData}
                          className="buttonClass w-fit"
                        >
                          Refresh
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="formBackground my-2 py-3 px-2 uppercase grid md:grid-cols-4 sm:grid-cols-3 grid-cols-2 justify-evenly tracking-wide text-center text-grey-darker text-xs font-bold gap-y-3">
              <div className="flex justify-center items-center gap-4">
                <h2>Active:</h2>
                <h2>{quickInfoList?.length}</h2>
              </div>
              <div className="flex justify-center items-center gap-4">
                <h2>Stuck Off:</h2>
                <h2>{active}</h2>
              </div>
              <div className="flex justify-center items-center gap-4">
                <h2>TC Given:</h2>
                <h2>{stuckOff}</h2>
              </div>
              <div className="flex justify-center items-center gap-4">
                <h2>Total:</h2>
                <h2>{quickInfoList?.length}</h2>
              </div>
            </div>
          </div>

          <div className="p-1 md:p-2 w-full shadow-lg">
            {/* <div className="relative max-h-96 md:block hidden overflow-x-auto border border-solid border-black rounded-sm">
              <table className="w-full overflow-y-auto bg-white text-sm text-left rtl:text-right">
                <thead className="tableHead sticky top-0 z-50 blur-0">
                  <tr>
                    {tableHead?.map((data) => {
                      return (
                        <th scope="col" className="p-1 uppercase text-sm">
                          {data}
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  {TableData?.map((item, index) => {
                    let url = `${process.env.REACT_APP_ERP_STUDENT_IMAGE_URL}${item?.ImageFile}`;
                    return (
                      <tr
                        key={index}
                        className={`border-b ${index % 2 === 0 ? "bg-white" : "bg-slate-200"} dark:bg-gray-800 dark:border-gray-700`}
                      >
                        <td className="border text-center border-solid border-black/10 p-1 w-fit h-full">
                          <input type="radio" className="radioButton" name="group1" />
                        </td>
                        <td className="border border-solid text-center border-black/10 p-1 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                          <img src={url} className="size-10" alt="Student Profile" />
                        </td>
                        <td className="border border-solid border-black/10 p-1 font-medium">{item?.RollNo}</td>
                        <td className="border border-solid border-black/10 p-1 font-medium">{item?.AdmissionNo}</td>
                        <td className="border border-solid border-black/10 p-1 font-medium">{item?.StudentName}</td>
                        <td className="border border-solid border-black/10 p-1 font-medium">{item?.Class}</td>
                        <td className="border text-center border-solid border-black/10 p-1 font-medium">{item?.Section}</td>
                        <td className="border border-solid border-black/10 p-1 font-medium">{item?.SMSPhone}</td>
                        <td className="border text-center border-solid border-black/10 p-1 font-medium">{item?.Status}</td>
                        <td className="border border-solid border-black/10 p-1 font-medium">{item?.FatherName}</td>
                        <td className="border border-solid border-black/10 p-1 font-medium">{item?.MotherName}</td>
                        <td className="border border-solid border-black/10 p-1 font-medium">
                          <input type="checkbox" checked={item?.New} />
                        </td>
                        <td className="border border-solid border-black/10 p-1 font-medium">
                          <input type="checkbox" checked={item?.RTE} />
                        </td>
                        <td className="border border-solid border-black/10 p-1 font-medium">{item?.Concession}</td>
                        <td className="border border-solid border-black/10 p-1 font-medium">
                          <p
                            onClick={() => {
                              navigate(`/admin/u/quick_info/student_profile/${customId}`, {
                                state: {
                                  admno: item?.AdmissionNo,
                                },
                              });
                            }}
                            className="text-blue-500 cursor-pointer text-sm capitalize"
                          >
                            Show Details
                          </p>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div> */}
            <div className="md:hidden block">
              <div className="block md:hidden max-w-xl mx-auto max-h-[600px] h-full overflow-auto bg-white shadow-md p-2 rounded-lg">
                {/* <h2 className="text-lg mb-4 text-center cardHeadingClass">Students</h2> */}
                <div className="overflow-x-auto">
                  {TableData.map((item, index) => {
                    let url = `${process.env.REACT_APP_ERP_STUDENT_IMAGE_URL}${item?.ImageFile}`;
                    return (
                      <div
                        className="border-2 bg-gradient-to-b from-sky-200 to-cyan-400 rounded-lg mb-4 shadow-lg overflow-hidden"
                        key={index}
                      >
                        <div className="flex items-center justify-between tabButton py-2 px-4 rounded-t-lg shadow-md">
                          <input
                            className="radioButton"
                            type="radio"
                            name="employeesRadio"
                            id={`employeesRadio-${index}`}
                          />
                          <span className="font-bold w-full text-center">
                            {" "}
                            {item?.StudentName}
                          </span>
                        </div>
                        <div className="flex justify-center mt-4">
                          <img
                            src={url}
                            alt="Employee img"
                            className="rounded-full h-20 w-20"
                          />
                        </div>
                        <table className="w-full table-auto mt-2">
                          <tbody>
                            <tr>
                              <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                                <strong>Roll No.</strong>
                              </td>
                              <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                                {item?.RollNo}
                              </td>
                            </tr>
                            <tr>
                              <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                                <strong>Adm No.</strong>
                              </td>
                              <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                                {item?.AdmissionNo}
                              </td>
                            </tr>
                            <tr>
                              <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                                <strong>Class</strong>
                              </td>
                              <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                                {item?.Class}
                              </td>
                            </tr>
                            <tr>
                              <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                                <strong>Section</strong>
                              </td>
                              <td className="border border-solid border-gray-400 text-start capitalize p-2 w-1/2">
                                {item?.Section}
                              </td>
                            </tr>
                            <tr>
                              <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                                <strong>SMS Ph. No.</strong>
                              </td>
                              <td className="border border-solid border-gray-400 text-start capitalize p-2 w-1/2">
                                {item?.SMSPhone}
                              </td>
                            </tr>
                            <tr>
                              <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                                <strong>Status</strong>
                              </td>
                              <td className="border border-solid border-gray-400 text-start capitalize p-2 w-1/2">
                                {item?.Status}
                              </td>
                            </tr>
                            <tr>
                              <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                                <strong>Father Name</strong>
                              </td>
                              <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                                {item?.FatherName}
                              </td>
                            </tr>
                            <tr>
                              <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                                <strong>Mother Name</strong>
                              </td>
                              <td className="border border-solid border-gray-400 text-start capitalize p-2 w-1/2">
                                {item?.MotherName}
                              </td>
                            </tr>
                            <tr>
                              <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                                <strong>New</strong>
                              </td>
                              <td className="border border-solid border-gray-400 text-start capitalize p-2 w-1/2">
                                <input type="checkbox" checked={item?.New} />
                              </td>
                            </tr>
                            <tr>
                              <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                                <strong>RTE</strong>
                              </td>
                              <td className="border border-solid border-gray-400 text-start capitalize p-2 w-1/2">
                                <input type="checkbox" checked={item?.RTE} />
                              </td>
                            </tr>
                            <tr>
                              <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                                <strong>Concession</strong>
                              </td>
                              <td className="border border-solid border-gray-400 text-start capitalize p-2 w-1/2">
                                {item?.Concession}
                              </td>
                            </tr>
                            <tr>
                              <td className="border border-solid border-gray-400 text-start capitalize p-2 w-1/2">
                                <strong>Show Profile</strong>
                              </td>
                              <td className="border border-solid border-gray-400 text-start capitalize p-2 w-1/2 px-6 py-4">
                                <p
                                  onClick={() => {
                                    navigate(
                                      `/admin/u/${customId}/QuickInfo/student_profile`,
                                      {
                                        state: {
                                          admno: item?.AdmissionNo,
                                        },
                                      }
                                    );
                                  }}
                                  className="text-blue-500 cursor-pointer text-sm capitalize"
                                >
                                  Show Details
                                </p>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="relative max-h-full overflow-x-auto border md:block hidden border-black border-solid rounded-sm">
              {quickInfoList && quickInfoList?.length > 0 ? (
                <table
                  ref={tableRef}
                  id="studentDetails"
                  style={{ width: "100%" }}
                  className="h-fit w-fit max-h-[500px] responsive extraCSS display nowrap"
                ></table>
              ) : (
                <div className="h-auto p-5">
                  <h1 className="text-2xl font-bold">Record Not Found</h1>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default QuickInfoStudentsList;
