import React from "react";
import axios from "axios";
import toast from "react-toastify";
import { getUserInfoKey } from "../API/GetUserInfoByKey";

export const adminSyncStudent = async (year,preUrl) => {
    try {
        // const formData = new FormData();
        // formData.append("title", "adminSyncStudent");
        // formData.append("ReqUserID", userId);
        // formData.append("ReqSMSType", "Testing");

        let userdata = [];

        const postData = {
            year: year, 
          };

        // const Corskey = process.env.REACT_APP_CORS_URL;
        

        const result = await axios.post(`${preUrl}studentUpdate`, postData, {
            headers: {
              "Content-Type": "application/json", // Specify content type as JSON
            },
          });

  

        // userdata = JSON.parse(result.data.substring(0, result.data.indexOf("||JasonEnd", 0)));

        return result;
    } catch (error) {
        console.error("An error occurred:", error);
        // Handle error here
    }
    // Placeholder JSX, replace with actual JSX
};
