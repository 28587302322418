import React from "react";
import axios from "axios";
import toast from "react-toastify";
import { getUserInfoKey } from "../API/GetUserInfoByKey";
import { getUserDataFromLocalByKey } from "./getStudentInfoByKeyLocal";

export const updateStudentHomeWorkVerify = async (remark,grade,mark,STDHWID,preUrl) => {
  try {
    const formData = new FormData();
    formData.append("title", "UpdateStudentHomeWorkVerify");
    formData.append("description", "UpdateStudentHomeWorkVerify");
    formData.append("STDHWID", STDHWID);
    formData.append("ReqTchVerified", "1");
    formData.append("ReqGrade", grade);
    formData.append("ReqMark", mark);
    formData.append("ReqRemarks",remark);

    let userdata = [];

    // const url="https://ccapk.cypherinfosolution.com/WebDataProcessingServer.aspx"

    const Corskey = process.env.REACT_APP_CORS_URL;
    const result = await axios.post(preUrl, formData);

    userdata = JSON.parse(
      result.data.substring(0, result.data.indexOf("||JasonEnd", 0))
    );

    return userdata;
  } catch (error) {
    console.error("An error occurred:", error);
    // Handle error here
  }
  // Placeholder JSX, replace with actual JSX
};
