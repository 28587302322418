import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import ClassStandard from '../assets/png/icons8-cutting-board-96.png'
import Navbar from '../components/Navbar';
import Sidebar from '../components/SidebarOld';

const classes = ['LKG', 'UKG', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'];
const avatarPlaceholder = 'https://via.placeholder.com/150'; // Demo avatar placeholder URL



 


const ClassList = () => {

  const [sidebarVisible, setSidebarVisible] = useState(true);

  const toggleSidebar = () => {
    setSidebarVisible(!sidebarVisible);
  };



  return (


 <>
 
 <Navbar isOpen={sidebarVisible} toggleSidebar={toggleSidebar} />




  <div className='flex'>

  {sidebarVisible && <Sidebar />}

  <div className='min-h-screen bg-gradient-to-r from-slate-100 to-slate-50'>

<div className='w-full absolute right-0 top-16 md:w-3/4 lg:w-4/5 xl:w-4/5 p-4 md:p-6 lg:p-8 xl:p-10'>

<div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 p-6">
      {classes.map((className, index) => (
        <Link
          key={index}
          to={`/class/${className}`}
          className="rounded-lg overflow-hidden shadow-md border border-gray-200 bg-white hover:bg-gray-100 transition duration-300 ease-in-out transform hover:scale-105"
        >
          <div className="p-4">
            <div className="mb-4">
              <img
                src={ClassStandard}
                alt={`Avatar for ${className}`}
                className="mx-auto h-24 w-24 rounded-full"
              />
            </div>
            <span className="block text-center text-lg font-bold">{`Class: ${className}`}</span>
          </div>
        </Link>
      ))}
    </div>
   
    </div>


</div>



</div>


 
 
 
 </>


 

  );
};

export default ClassList;
