import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import Spinner from "../../components/Spinner";
import Navbar from "../../components/Navbar";
import Sidebar from "../../components/Sidebar";
import { useSidebar } from "../../context/SidebarContext";
import { getUserDataFromLocalByKey } from "../../API/getStudentInfoByKeyLocal";
import FeeSearchForm from "./FeeSearchForm";

function FeeMOPSearch() {
  const { customId } = useParams();
  const [userType, setUserType] = useState("");
  const [userDetails, setUserDetails] = useState(null);
  const [data, setData] = useState(true);
  const [loading, setLoading] = useState(true);
  const [initialResourceUrl, setInitialResourceUrl] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const users = JSON.parse(localStorage.getItem("registeredUsers")) || [];

      if (customId) {
        const decodedData = JSON.parse(atob(customId));
        let matchedItem = [];

        users.forEach((innerArray) => {
          const [userData] = innerArray;

          if (
            userData.userId === decodedData.userId &&
            userData.userType === decodedData.userType &&
            userData.schoolId === decodedData.schoolId &&
            userData.year === decodedData.year
          ) {
            matchedItem.push(userData);
          }
        });

        const keyval = "webResourceURL";
        const dataUrl = await getUserDataFromLocalByKey(
          decodedData.userId,
          decodedData.schoolId,
          decodedData.userType,
          decodedData.year,
          keyval
        );
        setInitialResourceUrl(dataUrl);
        setLoading(false);
        setUserDetails(matchedItem);
      }
    };

    fetchData();
  }, [customId]);

  const handleFormData = (formData) => {
    const urlBase = `/admin/u/${customId}/`;
    const queryParams = [];

    // Check and add parameters based on conditions
    queryParams.push(
      formData.formattedFromDate ? formData.formattedFromDate : "null"
    );

    queryParams.push(
      formData.formattedToDate ? formData.formattedToDate : "null"
    );

    queryParams.push(
      encodeURIComponent(
        formData.selectedReCat ? formData.selectedReCat : "null"
      )
    );

    queryParams.push(
      encodeURIComponent(
        formData.selectedClassCat ? formData.selectedClassCat : "null"
      )
    );

    queryParams.push(
      encodeURIComponent(
        formData.selectedClass ? formData.selectedClass : "null"
      )
    );
    queryParams.push(
      encodeURIComponent(
        formData.selectedSection ? formData.selectedSection : "null"
      )
    );

    queryParams.push(
      encodeURIComponent(
        formData.adminNo ? formData.adminNo : "null"
      )
    );

    queryParams.push(
        encodeURIComponent(
          formData.selectedModPay ? formData.selectedModPay : "null"
        )
      );

    // Add more conditions as needed...

    queryParams.push("MOPSearchList");

    const url = urlBase + queryParams.join("/");
    navigate(url);
  };

  const MOPList = ['Not Selected','Cash', 'Bank', 'Cheque', 'Demand Draft', 'Card', 'Online', 'Gateway'];

  const { isSidebarOpen, currentSidebarTab, toggleSidebar,setIsSidebarOpen } = useSidebar();

  return (
    <>
      {data && (
        <Navbar
          data={userDetails}
          // isOpen={sidebarVisible}
          toggleSidebar={toggleSidebar}
        />
      )}

      <div className="flex">
        {data && <Sidebar data={userDetails} />}

        <div className={`w-full absolute ${isSidebarOpen?"lg:w-4/6 xl:w-9/12":"w-full"} sm:w-11/12 right-0 top-16 mx-auto   p-4 md:p-6 lg:p-8 xl:p-10`}>
          <div className="flex flex-col md:flex-row items-center justify-between mb-4">
            <div className="w-2/3">
              <h2 className=" md:text-2xl font-bold text-xl mb-2 md:mb-0">
                Admin :
                {userDetails &&
                  userDetails.length > 0 &&
                  userDetails[0].schoolId}
              </h2>
              <h4 className="mb-2 font-bold">Fees Details Search </h4>
            </div>
          </div>

          <div className=" w-2/3   shadow-md shadow-gray-400 rounded-md mt-28 lg:mt-12 bg-white mx-auto ">
            <div className="w-full bg-blue-700 text-white font-semibold text-xl  text-center px-3 py-4">
              Fees Details Search
            </div>
          </div>
          <FeeSearchForm customId={customId} MOPList = {MOPList} onSubmit={handleFormData} />
        </div>
      </div>
    </>
  );
}

export default FeeMOPSearch;
