import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { getMyPerformanceList } from "../../API/getMyPerformanceList";
import { getUserDataFromLocalByKey } from "../../API/getStudentInfoByKeyLocal";
import { getStudentInfoByKeyLocal } from "../../API/getStudentExamInfoByKeyLocal";
import { AiOutlineClose } from "react-icons/ai";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import useSidebar from "../../hooks/useSidebar";

function MyPerformance() {
  const { customId } = useParams();
  const [userDetails, setUserDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [galleryList, setGalleryList] = useState([]);
  const [initialResourceUrl, setInitialResourceUrl] = useState(null);
  const [examTerms, setExamTerms] = useState([]);
  const [examTermsArrays, setExamTermsArrays] = useState([]);
  const [selectedTerm, setSelectedTerm] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const users = JSON.parse(localStorage.getItem("registeredUsers")) || [];

      if (customId) {
        const decodedData = JSON.parse(atob(customId));
        let matchedItem = [];

        users.forEach((innerArray) => {
          const [userData] = innerArray;

          if (
            userData.userId === decodedData.userId &&
            userData.userType === decodedData.userType &&
            userData.schoolId === decodedData.schoolId &&
            userData.year === decodedData.year
          ) {
            matchedItem.push(userData);
          }
        });

        const result = await getMyPerformanceList(
          decodedData.userId,
          decodedData.schoolId,
          decodedData.userType,
          decodedData.year
        );

        const keyval = "webResourceURL";
        const dataUrl = await getUserDataFromLocalByKey(
          decodedData.userId,
          decodedData.schoolId,
          decodedData.userType,
          decodedData.year,
          keyval
        );
        setInitialResourceUrl(dataUrl);

        const examTermsKey = "ExamTerms";
        const examterms = await getStudentInfoByKeyLocal(
          decodedData.userId,
          decodedData.schoolId,
          decodedData.userType,
          decodedData.year,
          examTermsKey
        );

        const examTermsArray = examterms.InfoField.split(",");
        setGalleryList(result);
        setExamTerms(examterms);
        setExamTermsArrays(examTermsArray);

        console.log(examterms);
        setLoading(false);
        setUserDetails(matchedItem);
      }
    };

    fetchData();
  }, [customId]);

  const openModal = (term) => {
    setSelectedTerm(term);
    // console.log(examTerms.InfoField2);
    const infoField2 = examTerms?.InfoField2;

    if (infoField2 && infoField2.includes("NA")) {
      // Show a notification if "NA" is present in any part
      toast.error("Exam Result Not Available", {
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      // Open the modal if "NA" is not present
      setIsModalOpen(true);
    }
  };

  const closeModal = () => {
    setSelectedTerm(null);
    setIsModalOpen(false);
  };

  const { sidebarVisible, toggleSidebar } = useSidebar();

  return (
    <div className="flex items-center justify-center h-screen flex-col">
      <ToastContainer />

      {examTermsArrays.map((term, index) => (
        <div key={index} className="mb-4">
          <button
            className="bg-blue-500 text-white px-6 py-2 rounded w-64"
            onClick={() => openModal(term)}
          >
            {term}
          </button>
        </div>
      ))}

      {isModalOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center">
          <div className="absolute inset-0 bg-black opacity-50"></div>
          <div className="bg-white p-6 rounded-md z-10 relative">
            <div
              onClick={closeModal}
              className="absolute top-0 right-0 cursor-pointer p-2"
            >
              <AiOutlineClose />
            </div>
            <h2>{selectedTerm} Modal Content</h2>
            {/* Add your modal content here */}
            <button
              className="mt-4 bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
              onClick={closeModal}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default MyPerformance;
