import React, { useState, useEffect } from "react";
import Navbar from "../../components/Navbar";
import Sidebar from "../../components/Sidebar";
import { useSidebar } from "../../context/SidebarContext";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import Spinner from "../../components/Spinner";
import Images from "../../assets/Files/images.png";
import PDFImage from "../../assets/Files/pdficconpng.png";
import powerpointIcon from "../../assets/Files/PPTIcon.png";
import WordIcon from "../../assets/Files/wordicon.png";
import VideoIcon from "../../assets/Files/Video.png";
import Youtube from "../../assets/Files/youtube.png";
import Excel from "../../assets/Files/excel.svg";
import textMessage from "../../assets/Files/textmsg.png";
import OtherImg from "../../assets/Files/otherico.png";
import AudioIcon from "../../assets/Files/musicIcon.png";
import { getUserDataFromLocalByKey } from "../../API/getStudentInfoByKeyLocal";
import { getAdminCDHomeworkList } from "../../API/getAdminCDHomeworkListAPI";
function AdminCDHomeWork() {
  const [userType, setUserType] = useState("");
  const [userDetails, setUserDetails] = useState(null);
  const [data, setData] = useState(true);
  const { customId, className, sec } = useParams();
  const [loading, setLoading] = useState(true);
  const [homeWrokList, setHomeWrokList] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [selectedHomeWork, setSelectedHomeWork] = useState(null);
  const [filteredHomeWork, setFilteredHomeWork] = useState([]);
  const [initialResourceUrl, setInitialResourceUrl] = useState(null);
  const [resourceUrl, setResourceUrl] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      const users = JSON.parse(localStorage.getItem("registeredUsers")) || [];

      if (customId) {
        const decodedData = JSON.parse(atob(customId));
        let matchedItem = [];

        users.forEach((innerArray) => {
          const [userData] = innerArray;

          if (
            userData.userId === decodedData.userId &&
            userData.userType === decodedData.userType &&
            userData.schoolId === decodedData.schoolId &&
            userData.year === decodedData.year
          ) {
            matchedItem.push(userData);
          }
        });

        const result = await getAdminCDHomeworkList(
          decodedData.userId,
          decodedData.schoolId,
          decodedData.userType,
          decodedData.year,
          className,
          sec
        );

        const key = "WebResourceAWSDOTNETURL";

        const dataResourceUrl = await getUserDataFromLocalByKey(
          decodedData.userId,
          decodedData.schoolId,
          decodedData.userType,
          decodedData.year,
          key
        );
        setResourceUrl(dataResourceUrl);

        const keyval = "webServiceURLAWSDOTNET";
        const dataUrl = await getUserDataFromLocalByKey(
          decodedData.userId,
          decodedData.schoolId,
          decodedData.userType,
          decodedData.year,
          keyval
        );
        setInitialResourceUrl(dataUrl);
        setHomeWrokList(result);
        setLoading(false);
        setUserDetails(matchedItem);
      }
    };

    fetchData();
  }, [customId]);

  console.log(homeWrokList);

  const handleSearch = (value) => {
    setSearchTerm(value);

    if (value.trim() === "") {
      setFilteredHomeWork([]);
    } else {
      const filtered = homeWrokList.filter((notification) =>
        notification.HWSubject.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredHomeWork(filtered);
    }
  };

  const renderImage = (photoLocation) => {
    //console.log(photoLocation);

    if (photoLocation === "") {
      return textMessage;
    }

    if (photoLocation.endsWith(".jpg")) {
      // setContentType('Same')
      return Images;
    } else if (photoLocation.endsWith(".pdf")) {
      // setContentType("Same")
      return PDFImage;
    } else if (photoLocation.includes(".ppt")) {
      // setContentType("Same")
      return powerpointIcon;
    } else if (photoLocation.includes(".doc")) {
      // setContentType("Same")
      return WordIcon;
    } else if (photoLocation.includes(".xls")) {
      // setContentType("Same")
      return Excel;
    } else if (photoLocation.includes(".mp4")) {
      // setContentType("Same")
      return VideoIcon;
    } else if (photoLocation.includes(".mp3")) {
      // setContentType("Same")
      return AudioIcon;
    } else if (photoLocation.includes("v=")) {
      return Youtube;
    } else {
      return OtherImg;
    }
  };

  const checkContentType = () => {
    if (selectedHomeWork.PhotoLocation.includes("v=")) {
      return (
        <iframe
          title="Notification Content"
          src={`https://www.youtube.com/embed/${
            selectedHomeWork.PhotoLocation.split("v=")[1]
          }`}
          className="w-full h-56 border-none"
          style={{ overflow: "auto" }}
        ></iframe>
      );
    }
    if (selectedHomeWork.PhotoLocation === "") {
      return null;
    } else {
      return (
        <iframe
          title="Notification Content"
          src={`${resourceUrl}/HomeWork/${selectedHomeWork.PhotoLocation}`}
          className="w-full h-56 border-none"
          style={{ overflow: "auto" }}
        ></iframe>
      );
    }
  };

  const openPopup = (notification) => {
    setSelectedHomeWork(notification);
    setShowPopup(true);
  };

  const closePopup = () => {
    setShowPopup(false);
    setSelectedHomeWork(null);
  };

  const homeWorksToRender = searchTerm ? filteredHomeWork : homeWrokList;

  const { isSidebarOpen, currentSidebarTab, toggleSidebar, setIsSidebarOpen } =
    useSidebar();

  return (
    <>
      {data && (
        <Navbar
          data={userDetails}
          // isOpen={sidebarVisible}
          toggleSidebar={toggleSidebar}
        />
      )}

      <div className="flex">
        {data && <Sidebar data={userDetails} />}

        {loading ? (
          <Spinner />
        ) : (
          <div
            className={`w-full absolute ${
              isSidebarOpen ? "lg:w-4/6 xl:w-9/12" : "w-full"
            } sm:w-11/12 right-0 top-16 mx-auto   p-4 md:p-6 lg:p-8 xl:p-10`}
          >
            <div className="lg:flex items-center justify-between mb-4">
              <div className="w-2/3">
                <h2 className=" md:text-2xl font-bold text-xl mb-2 md:mb-0">
                  Admin :
                  {userDetails &&
                    userDetails.length > 0 &&
                    userDetails[0].schoolId}
                </h2>
                <h4 className="mb-2 font-bold">Home Work List</h4>
              </div>
              <div className=" w-2/6">
                <input
                  type="text"
                  placeholder="Search..."
                  value={searchTerm}
                  onChange={(e) => handleSearch(e.target.value)}
                  className="border border-gray-300 px-3 py-2 rounded-md mr-2 focus:border-blue-500 focus:outline-none"
                />
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-4">
              {homeWorksToRender &&
                homeWorksToRender.map((notification) => (
                  <Link
                    to={""}
                    onClick={() => openPopup(notification)}
                    key={notification.id}
                  >
                    <div className="border border-gray-200 rounded-lg p-4 hover:shadow-md transition duration-300">
                      <div className="flex items-center">
                        <div className="w-1/3 sm:w-auto mr-4">
                          <img
                            src={renderImage(notification.PhotoLocation)}
                            alt={notification.title}
                            className="w-20 h-20 sm:w-32 sm:h-32 object-cover rounded"
                          />
                        </div>

                        <div className="w-2/3 ">
                          <h2 className="text-lg font-semibold mb-2">
                            {notification.HWSubject}
                          </h2>
                          <h4 className="text-lg font-semibold mb-2">
                            {notification.HWDateStr}
                          </h4>
                          <p className="text-gray-600">
                            {notification.HWRemarks}
                          </p>
                          <p className="text-gray-600">
                            Class : {notification.HWClass} -{" "}
                            {notification.HWSection} Subject :{" "}
                            {notification.HESubject}
                          </p>
                        </div>
                        
                      </div>
                    </div>
                  </Link>
                ))}
            </div>

            {showPopup && (
              <div className="fixed inset-0 flex items-center justify-center mt-10 bg-black bg-opacity-60">
                <div className="bg-white customPopupHeight relative rounded-lg mt-8 shadow-lg p-8 md:w-1/2 w-full ">
                  <div className="w-1/3 sm:w-auto mr-4">
                    <img
                      src={renderImage(selectedHomeWork.PhotoLocation)}
                      alt=""
                      className="w-20  sm:w-32  object-cover rounded"
                    />
                  </div>

                  <button
                    onClick={closePopup}
                    className="px-4 py-2  absolute right-5 top-0 mt-8 bg-red-600 text-white rounded"
                  >
                    Close
                  </button>

                  <div className="w-2/3 ">
                    <h2 className="text-lg font-semibold mb-2">
                      {selectedHomeWork.HWSubject}
                    </h2>
                    <h4 className="text-lg font-semibold mb-2">
                      {selectedHomeWork.HWDateStr}
                    </h4>
                    <p className="text-gray-600">
                      {selectedHomeWork.HWRemarks}
                    </p>
                  </div>

                  {checkContentType()}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
}

export default AdminCDHomeWork;
