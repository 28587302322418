import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import Spinner from "../../components/Spinner";
import Navbar from "../../components/Navbar";
import Sidebar from "../../components/Sidebar";
import { useSidebar } from "../../context/SidebarContext";
import personicon from "../../assets/png/person-icon.png";
import { getUserDataFromLocalByKey } from "../../API/getStudentInfoByKeyLocal";
import { getAdminFeeStuWiseSList } from "../../API/getAdminFeeStuWiseDListAPI";

function FeeStudentWiseDList() {
  const { customId, FromDate, ToDate, Rcptcat, Clscat, Class, Section, Admno } =
    useParams();
  const [userType, setUserType] = useState("");
  const [userDetails, setUserDetails] = useState(null);
  const [data, setData] = useState(true);
  const [loading, setLoading] = useState(true);
  const [initialResourceUrl, setInitialResourceUrl] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchList, setSearchList] = useState([]);
  const [filteredList, setfilteredList] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const users = JSON.parse(localStorage.getItem("registeredUsers")) || [];

      if (customId) {
        const decodedData = JSON.parse(atob(customId));
        let matchedItem = [];

        users.forEach((innerArray) => {
          const [userData] = innerArray;

          if (
            userData.userId === decodedData.userId &&
            userData.userType === decodedData.userType &&
            userData.schoolId === decodedData.schoolId &&
            userData.year === decodedData.year
          ) {
            matchedItem.push(userData);
          }
        });

        const result = await getAdminFeeStuWiseSList(
          decodedData.userId,
          decodedData.schoolId,
          decodedData.userType,
          decodedData.year,
          FromDate == 'null' ? "" : FromDate,
          ToDate == 'null' ? "" : ToDate,
          Rcptcat == 'null' ?  "" : Rcptcat,
          Clscat == 'null' ? "" : Clscat,
          Class == 'null' ? "" : Class,
          Section == 'null' ? "" : Section,
          Admno == 'null' ? "" : Admno
        );

        const keyval = "webResourceURL";
        const dataUrl = await getUserDataFromLocalByKey(
          decodedData.userId,
          decodedData.schoolId,
          decodedData.userType,
          decodedData.year,
          keyval
        );
        setInitialResourceUrl(dataUrl);
        setLoading(false);
        setSearchList(result);
        setUserDetails(matchedItem);

      }
    };

    fetchData();
  }, [customId]);

  console.log(searchList);

  const handleSearch = (value) => {
    setSearchTerm(value);
    if (value.trim() === "") {
        setfilteredList([]);
      } else {
        const filtered = searchList.filter((item) =>
          item.Head2.toLowerCase().includes(value.toLowerCase())
        );
        setfilteredList(filtered);
      }
  };

  const itemsToRender = searchTerm
  ? filteredList
  : searchList;

  const { isSidebarOpen, currentSidebarTab, toggleSidebar,setIsSidebarOpen } = useSidebar();

  return (
    <>
      {data && (
        <Navbar
          data={userDetails}
          // isOpen={sidebarVisible}
          toggleSidebar={toggleSidebar}
        />
      )}

      <div className="flex">
        {data && <Sidebar data={userDetails} />}

        <div className="container mx-auto p-4">
          {loading ? (
            <Spinner />
          ) : (
            <>
            <div className="w-full absolute right-0 top-16 mx-auto md:w-3/5 lg:w-4/5 xl:w-4/5 p-4 md:p-6 lg:p-8 xl:p-10">
          <div className="lg:flex items-center justify-between mb-4">
            <div className="w-2/3">
              <h2 className=" md:text-2xl font-bold text-xl mb-2 md:mb-0">
                Admin :
                {userDetails &&
                  userDetails.length > 0 &&
                  userDetails[0].schoolId}
              </h2>
              <h4 className="mb-2 font-bold">Student Wise Defaulters List</h4>
            </div>
            <div className=" w-2/6">
              <input
                type="text"
                placeholder="Search..."
                value={searchTerm}
                onChange={(e) => handleSearch(e.target.value)}
                className="border border-gray-300 px-3 py-2 rounded-md mr-2 focus:border-blue-500 focus:outline-none"
              />
            </div>
          </div>

          <div className="bg-white p-6 shadow-md rounded-md overflow-y-auto max-h-96">
            {/* Display the content for the specific tab */}
            {itemsToRender.slice(1).map((item, index) => (
              <div
                key={index}
                className=" lg:flex items-center border border-gray-300 mb-2"
              >
                <img
                  src={item.Col12 === "noPhoto.jpg" ? personicon : item.Col12}
                  alt={item.Col12}
                  className="w-20 h-16 object-cover rounded-full mr-4"
                />
                <div className="ml-2">
                  <h3 className="text-lg font-semibold mb-2">{item.Head2}</h3>
                  <p className=" text-gray-400">{item.Head1}</p>
                  <p className=" text-gray-400">class {item.Head3}</p>
                  <p className="text-green-500">
                     Due: {item.Col4}
                  </p>
                  <p className="text-gray-400 mb-2">{item.Col10}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
            
            </>
          )}

        
      </div>
      </div>
    </>
  );
}

export default FeeStudentWiseDList;
