import axios from "axios";

export const sentOtp = async (userId, preRegisterUrl) => {
  try {
    const formData = new FormData();

    formData.append("title", "SendVerificationUser");
    formData.append("ReqUserID", userId);
    formData.append("ReqSMSType", process.env.REACT_APP_SMS_TYPE);

    const Corskey = process.env.REACT_APP_CORS_URL;
    const result = await axios.post(`${Corskey}${preRegisterUrl}`, formData);
    return JSON.parse(result.data.substring(0, result.data.indexOf("||JasonEnd", 0)));
  } catch (error) {
    console.error("An error occurred:", error);
  }
};
