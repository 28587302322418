import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import Spinner from "../../components/Spinner";
import Navbar from "../../components/Navbar";
import Sidebar from "../../components/Sidebar";
import { useSidebar } from "../../context/SidebarContext";
import personicon from "../../assets/png/person-icon.png";
import { IoMdClose } from "react-icons/io";
import { getUserDataFromLocalByKey } from "../../API/getStudentInfoByKeyLocal";
import { getAdminMonthWiseSearchList } from "../../API/getAdminFeeMonthWiseSearchListAPI";


function FeeMonthWiseSearchList() {
  const { customId, FromDate, ToDate, Rcptcat, Clscat, Class, Section, Admno } =
    useParams();
  const [userType, setUserType] = useState("");
  const [userDetails, setUserDetails] = useState(null);
  const [data, setData] = useState(true);
  const [loading, setLoading] = useState(true);
  const [initialResourceUrl, setInitialResourceUrl] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchList, setSearchList] = useState([]);
  const [filteredList, setfilteredList] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const users = JSON.parse(localStorage.getItem("registeredUsers")) || [];

      if (customId) {
        const decodedData = JSON.parse(atob(customId));
        let matchedItem = [];

        users.forEach((innerArray) => {
          const [userData] = innerArray;

          if (
            userData.userId === decodedData.userId &&
            userData.userType === decodedData.userType &&
            userData.schoolId === decodedData.schoolId &&
            userData.year === decodedData.year
          ) {
            matchedItem.push(userData);
          }
        });

        const result = await getAdminMonthWiseSearchList(
          decodedData.userId,
          decodedData.schoolId,
          decodedData.userType,
          decodedData.year,
          FromDate == "null" ? "" : FromDate,
          ToDate == "null" ? "" : ToDate,
          Rcptcat == "null" ? "" : Rcptcat,
          Clscat == "null" ? "" : Clscat,
          Class == "null" ? "" : Class,
          Section == "null" ? "" : Section,
          Admno == "null" ? "" : Admno
        );

        const keyval = "webResourceURL";
        const dataUrl = await getUserDataFromLocalByKey(
          decodedData.userId,
          decodedData.schoolId,
          decodedData.userType,
          decodedData.year,
          keyval
        );
        setInitialResourceUrl(dataUrl);
        setLoading(false);
        setSearchList(result);
        setUserDetails(matchedItem);
      }
    };

    fetchData();
  }, [customId]);

  console.log(searchList);

  const handleSearch = (value) => {
    setSearchTerm(value);
    if (value.trim() === "") {
      setfilteredList([]);
    } else {
      const filtered = searchList.filter((item) =>
        item.Col8.toLowerCase().includes(value.toLowerCase())
      );
      setfilteredList(filtered);
    }
  };


  const itemsToRender = searchTerm ? filteredList : searchList;

  const { isSidebarOpen, currentSidebarTab, toggleSidebar,setIsSidebarOpen } = useSidebar();

  return (
    <>
      {data && (
        <Navbar
          data={userDetails}
          // isOpen={sidebarVisible}
          toggleSidebar={toggleSidebar}
        />
      )}

      <div className="flex">
        {data && <Sidebar data={userDetails} />}

        <div className="container mx-auto p-4">
          {loading ? (
            <Spinner />
          ) : (
            <>
              <div className={`w-full absolute ${isSidebarOpen?"lg:w-4/6 xl:w-9/12":"w-full"} sm:w-11/12 right-0 top-16 mx-auto   p-4 md:p-6 lg:p-8 xl:p-10`}>
                <div className="flex items-center justify-between mb-4">
                  <div className="w-2/3">
                    <h2 className=" md:text-2xl font-bold text-xl mb-2 md:mb-0">
                      Admin :
                      {userDetails &&
                        userDetails.length > 0 &&
                        userDetails[0].schoolId}
                    </h2>
                    <h4 className="mb-2 font-bold">
                      Receipt Summary (Mod of Pay)
                    </h4>
                  </div>
                  <div className=" w-2/6">
                    <input
                      type="text"
                      placeholder="Search..."
                      value={searchTerm}
                      onChange={(e) => handleSearch(e.target.value)}
                      className="border border-gray-300 px-3 py-2 rounded-md mr-2 focus:border-blue-500 focus:outline-none"
                    />
                  </div>
                </div>

                <div className="table-container overflow-y-auto h-96">
                  <table className="w-full table-auto ">
                    <thead>
                      <tr className="bg-blue-500">
                        <th className="sticky top-0 border bg-blue-500 lg:z-10 p-2  left-0">
                          Sl No
                        </th>
                        <th className="sticky top-0 border bg-blue-500 lg:z-10 p-2">
                          Fees Head
                        </th>
                        <th className="sticky top-0 border bg-blue-500 lg:z-10 p-2 ">
                          Amount
                        </th>
                        {/* Add more columns as needed */}
                      </tr>
                    </thead>
                    <tbody>
                      {/* Populate table rows based on classWiseStrengthList */}
                      {itemsToRender.slice(1).map((item, index) => (
                        <tr
                          key={index}
                          className="bg-gray-300"
                        >
                          <td className="p-2 text-lg border font-semibold text-left">
                            {index + 1}
                          </td>
                          <td className="p-2 font-semibold border  text-left">
                            {item.Col8}
                          </td>
                          <td className="p-2 font-semibold border  text-right">
                            {item.Col1}
                          </td>
                          {/* Add more columns as needed */}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default FeeMonthWiseSearchList;
