import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { AiOutlineClose } from "react-icons/ai";
import { getGalleryListDetails } from "../../API/getGalleryList";
import FolderImageIcon from "../../assets/Files/folderimageicon.png";
import FolderListIcon from "../../assets/Files/folderlisticon.png";
import { getUserDataFromLocalByKey } from "../../API/getStudentInfoByKeyLocal";
import Spinner from "../../components/Spinner";
import useSidebar from "../../hooks/useSidebar";

function Gallery() {
  const { customId } = useParams();
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredNotifications, setFilteredNotifications] = useState([]);
  const [userDetails, setUserDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [galleryList, setGalleryList] = useState([]);
  const [selectedNotification, setSelectedNotification] = useState(null);
  const [initialResourceUrl, setInitialResourceUrl] = useState(null);
  const [resourceUrl,setResourceUrl] = useState(null);
  const handleSearch = (value) => {
    setSearchTerm(value);

    // If the search term is empty, reset filtered notifications
    if (value.trim() === "") {
      setFilteredNotifications([]);
    } else {
      // Filter notifications based on the search term
      const filtered = galleryList.filter((notification) =>
        notification.HWSubject.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredNotifications(filtered);
    }
  };

  const handleOpenImageModal = (notification) => {
    setSelectedNotification({
      ...notification,
      photoLocation: notification.PhotoLocation,
    });
  };

  const handleCloseImageModal = () => {
    setSelectedNotification(null);
  };

  useEffect(() => {
    const fetchData = async () => {
      const users = JSON.parse(localStorage.getItem("registeredUsers")) || [];

      if (customId) {
        const decodedData = JSON.parse(atob(customId));
        let matchedItem = [];

        users.forEach((innerArray) => {
          const [userData] = innerArray;

          if (
            userData.userId === decodedData.userId &&
            userData.userType === decodedData.userType &&
            userData.schoolId === decodedData.schoolId &&
            userData.year === decodedData.year
          ) {
            matchedItem.push(userData);
          }
        });

        const result = await getGalleryListDetails(
          decodedData.userId,
          decodedData.schoolId,
          decodedData.userType,
          decodedData.year
        );

         const key="WebResourceAWSDOTNETURL"

         const dataResourceUrl = await getUserDataFromLocalByKey(
          decodedData.userId,
          decodedData.schoolId,
          decodedData.userType,
          decodedData.year,
          key
        );


setResourceUrl(dataResourceUrl)

        const keyval = "webServiceURLAWSDOTNET";
        const dataUrl = await getUserDataFromLocalByKey(
          decodedData.userId,
          decodedData.schoolId,
          decodedData.userType,
          decodedData.year,
          keyval
        );


        console.log(dataUrl,"data url")

        setInitialResourceUrl(dataUrl);

        setGalleryList(result);

        console.log(result);
        setLoading(false);
        setUserDetails(matchedItem);
      }
    };

    fetchData();
  }, [customId]);

  const checkContentType = () => {
    
    if (selectedNotification.PhotoLocation.includes("v=")) {
      const videoId =  selectedNotification.PhotoLocation.split("v=")[1].split("&")[0]

      return (
        <>
          <iframe
            title="Notification Content"
            src={`https://www.youtube.com/embed/${videoId}`}
            className="w-full h-96 border-none"
            style={{ overflow: "auto" }}
          ></iframe>
          <button
            onClick={() =>
              window.open(
                `https://www.youtube.com/watch?v=${videoId}`,
                "_blank"
              )
            }
            className="mt-2 bg-red-500 text-white px-4 py-2 rounded"
          >
            Watch on YouTube
          </button>
        </>
      );
    }

    if (selectedNotification.PhotoLocation === "") {
      return null;
    } else {
      return (
        <iframe
          title="Notification Content"
          src={`${resourceUrl}/StudyMaterials/${selectedNotification.PhotoLocation}`}
          className="w-full h-96 border-none"
          style={{ overflow: "auto" }}
        ></iframe>
      );
    }
  };

  const notificationsToRender = searchTerm
    ? filteredNotifications
    : galleryList;

    const { sidebarVisible, toggleSidebar } = useSidebar();

  return (
    <>
      <div className="container mx-auto p-4">
        {loading ? (
          <Spinner />
        ) : (
          <>
            <div className="flex items-center justify-between mb-4">
              <h1 className="text-3xl font-bold">Gallery</h1>
              <input
                type="text"
                placeholder="Search by title"
                value={searchTerm}
                onChange={(e) => handleSearch(e.target.value)}
                className="border border-gray-300 px-3 py-1 rounded-md w-64"
              />
            </div>
            <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-4">
              {notificationsToRender?.map((notification) => (
                <div
                  key={notification.id}
                  onClick={() => handleOpenImageModal(notification)}
                  className="cursor-pointer relative"
                >
                  <div className="border border-gray-300 rounded-lg p-4 hover:shadow-md transition duration-300">
                    <h2 className="text-lg font-semibold mb-2">
                      {notification.HWSubject}
                    </h2>
                    <h4 className="text-lg font-semibold mb-2">
                      {notification.HWDateStr}
                    </h4>
                    <p className="text-gray-600">{notification.HWRemarks}</p>
                    <div className="absolute top-0 right-0 p-2 flex items-center">
                      {notification.MediaType ? (
                        <>
                          <img
                            src={FolderImageIcon}
                            alt="Thumbnail"
                            className="w-10 h-10 object-cover rounded ml-2"
                          />
                          <span className="text-gray px-2 py-1 rounded">
                            Media: {notification.MediaType}
                          </span>
                        </>
                      ) : (
                        <>
                          <img
                            src={FolderListIcon}
                            alt="Thumbnail"
                            className="w-10 h-10 object-cover rounded ml-2"
                          />
                          <span className="text-gray px-2 py-1 rounded">
                            Media: N/A
                          </span>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
            {selectedNotification && (
              <div className="fixed inset-0  bg-black bg-opacity-75 flex justify-center items-center">
                <div
                  style={{ width: "80%", height: "75%" }}
                  className="bg-white p-4 rounded-lg relative "
                >
                  {checkContentType()}
                  <div
                    onClick={handleCloseImageModal}
                    className="absolute top-0 right-0 cursor-pointer p-2"
                  >
                    <AiOutlineClose />
                  </div>
                  <button
                    onClick={handleCloseImageModal}
                    className="mt-4 ml-4 bg-blue-500 text-white px-4 py-2 rounded"
                  >
                    Close
                  </button>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
}

export default Gallery;
