import React from "react";
import axios from "axios";
import toast from "react-toastify";
import { getUserDataFromLocalByKey } from "./getStudentInfoByKeyLocal";

export const UpdateAskToMentorAPI = async (userId,schoolId,userType,year,MessageTomentor,audioValue,dateData,fileName,Subject) => {
  try {
    
    // decodedData.userId,
    //         decodedData.schoolId,
    //         decodedData.userType,
    //         decodedData.year,
    //         formData.messageToMentor,
    //         audiobase64,
    //         formattedDate,
    //         audioFilename
    
    const key = "webServiceURLAWSDOTNET";

  
    console.log("User ID",userId)
    console.log("School ID",schoolId)
    console.log("User Type",userType)
    console.log("year",year)
    console.log("message",MessageTomentor)
    console.log("Audio",audioValue)
    console.log("Date",dateData)
    console.log("FileName",fileName)
    console.log("Subject",Subject)
  
  

    const returnUrl = await getUserDataFromLocalByKey(userId,schoolId,userType,year,key);


    const SMSType= process.env.REACT_APP_SMS_TYPE

   

    const formData = new FormData();
    formData.append("title", "UpdatePM");
    formData.append("description", '');
    formData.append("ReqAcastart", year)
    formData.append("ReqAdmno", userId);
    formData.append("ReqPMDate", dateData);
    formData.append("ReqPMessage", MessageTomentor);
    formData.append("ReqSubject", Subject);
    formData.append("photoLocation",fileName );
    formData.append("imagestring", audioValue);
    formData.append("ReqSMSType", SMSType);
    // // nvc.Add("title", "UpdateDailyAtt");
    // // nvc.Add("ReqStdYrid", "29721");
    // // nvc.Add("ReqAttType", TxtClass.Text);
    // // nvc.Add("ReqAttDate", TxtSection.Text);


    let userdata = [];

    const Corskey = process.env.REACT_APP_CORS_URL;

    const result = await axios.post(`${Corskey}${returnUrl}`, formData);

    console.log("RESULT RESPONSE",result)
    userdata = JSON.parse(
      result.data.substring(0, result.data.indexOf("||JasonEnd", 0))
    );


    // console.log("RESULT RESPONSE",result)
    console.log("RESULT RESPONSE DATA",userdata)

    return userdata;

    
  } catch (error) {
    console.error("An error occurred:", error);
    // Handle error here
  }
  // Placeholder JSX, replace with actual JSX
};
