import React from "react";
import axios from "axios";
import toast from "react-toastify";
import { getUserDataFromLocalByKey } from "./getStudentInfoByKeyLocal";


export const getAdminFeeStuWiseSList  = async (userId,schoolId, userType, year, FromDate, ToDate, Rcptcat, Clscat, Cls, Section, Admno ) => {
 

  try {

    const key='webServiceURL'

   const returnUrl= await getUserDataFromLocalByKey(userId,schoolId,userType, year,key)

  

    const formData = new FormData();
    formData.append("title", "GetFeesStudentWiseDefaulterList");
    formData.append("description", "");
    formData.append("ReqAcastart", year);
    formData.append("ReqClassCategory", Clscat);
    formData.append("ReqRcptCategory", Rcptcat);
    formData.append("ReqClass", Cls);
    formData.append("ReqSection", Section);
    formData.append("ReqDate1", FromDate);
    formData.append("ReqDate2", ToDate);
    formData.append("ReqAdmno", Admno);
    // ReqAttDate

    let userdata = [];

    const Corskey=process.env.REACT_APP_CORS_URL;

    // const result = await axios.post(
    //   "https://cors-anywhere.herokuapp.com/http://apkarnold.newgentechnology.net/WebDataProcessingServer.aspx",
    //   formData
    // );

    const result = await axios.post(
      `${Corskey}${returnUrl}`,
      formData
    );

  

    userdata = JSON.parse(
      result.data.substring(0, result.data.indexOf("||JasonEnd", 0))
    );

  

    return userdata;

  
  } catch (error) {
    console.error("An error occurred:", error);
    // Handle error here
  }
  // Placeholder JSX, replace with actual JSX
};
