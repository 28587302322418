import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { getHomeWorkListDetail } from "../../API/getHomeWorkList";
import Spinner from "../../components/Spinner";
import useSidebar from "../../hooks/useSidebar";
import Images from "../../assets/Files/images.png";
import PDFImage from "../../assets/Files/pdficconpng.png";
import powerpointIcon from "../../assets/Files/PPTIcon.png";
import WordIcon from "../../assets/Files/wordicon.png";
import VideoIcon from "../../assets/Files/videoicon.png";
import Youtube from "../../assets/Files/youtube.png";
import Excel from "../../assets/Files/excel.svg";
import textMessage from "../../assets/Files/textmsg.png";
import OtherImg from "../../assets/Files/otherico.png";
import { getUserDataFromLocalByKey } from "../../API/getStudentInfoByKeyLocal";
import AudioIcon from "../../assets/Files/musicIcon.png";
import { getStudentHomeWorkStaus } from "../../API/getStudentHomeWorkStaus";

const MyTask = () => {
  const { customId } = useParams();
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredTasks, setFilteredTasks] = useState([]);
  const [userDetails, setUserDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [taskList, setTaskList] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [selectedTasks, setSelectedTasks] = useState(null);
  const [contentType, setContentType] = useState("default");
  const [initialResourceUrl, setInitialResourceUrl] = useState(null);
  const [resourceUrl, setResourceUrl] = useState(null);
  const [homeWorkStatus, setHomeWorkStatus] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState(null);
  const navigate = useNavigate();

  const handleSearch = (value) => {
    setSearchTerm(value);

    if (value.trim() === "") {
      setFilteredTasks([]);
    } else {
      const filtered = taskList.filter((task) =>
        task.HWSubject.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredTasks(filtered);
    }
  };

  const renderImage = (photoLocation) => {
    if (photoLocation === "") {
      return textMessage;
    }

    if (photoLocation.endsWith(".jpg")) {
      // setContentType('Same')
      return Images;
    } else if (photoLocation.endsWith(".pdf")) {
      // setContentType("Same")
      return PDFImage;
    } else if (photoLocation.includes(".ppt")) {
      // setContentType("Same")
      return powerpointIcon;
    } else if (photoLocation.includes(".doc")) {
      // setContentType("Same")
      return WordIcon;
    } else if (photoLocation.includes(".xls")) {
      // setContentType("Same")
      return Excel;
    } else if (photoLocation.includes(".mp4")) {
      // setContentType("Same")
      return VideoIcon;
    } else if (photoLocation.includes(".mp3")) {
      // setContentType("Same")
      return AudioIcon;
    } else if (photoLocation.includes("v=")) {
      return Youtube;
    } else {
      return OtherImg;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const users = JSON.parse(localStorage.getItem("registeredUsers")) || [];

      if (customId) {
        const decodedData = JSON.parse(atob(customId));
        let matchedItem = [];

        users.forEach((innerArray) => {
          const [userData] = innerArray;

          if (
            userData.userId === decodedData.userId &&
            userData.userType === decodedData.userType &&
            userData.schoolId === decodedData.schoolId &&
            userData.year === decodedData.year
          ) {
            matchedItem.push(userData);
          }
        });

        // const homeWorkStatus = await getStudentHomeWorkStaus(
        //   decodedData.userId,
        //   decodedData.schoolId,
        //   decodedData.userType,
        //   decodedData.year
        // );
        // setHomeWorkStatus(homeWorkStatus);

        const result = await getHomeWorkListDetail(
          decodedData.userId,
          decodedData.schoolId,
          decodedData.userType,
          decodedData.year
        );

        console.log(result);

        const key = "WebResourceAWSDOTNETURL";

        const dataResourceUrl = await getUserDataFromLocalByKey(
          decodedData.userId,
          decodedData.schoolId,
          decodedData.userType,
          decodedData.year,
          key
        );

        setResourceUrl(dataResourceUrl);

        const keyval = "webServiceURLAWSDOTNET";
        const dataUrl = await getUserDataFromLocalByKey(
          decodedData.userId,
          decodedData.schoolId,
          decodedData.userType,
          decodedData.year,
          keyval
        );
        setInitialResourceUrl(dataUrl);

        setTaskList(result);
        setLoading(false);
        setUserDetails(matchedItem);
      }
    };

    fetchData();
  }, [customId]);
  const Modal = ({ onClose }) => {
    console.log(modalData, 32323);

    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
        <div className="bg-white p-8 rounded-lg shadow-lg max-w-lg w-full mx-4 sm:mx-auto">
          {/* Modal Header */}
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-2xl font-bold">Homework Details</h2>
            <button
              onClick={onClose}
              className="text-gray-600 hover:text-gray-900 text-2xl font-bold focus:outline-none"
            >
              &times;
            </button>
          </div>

          {/* Modal Body */}
          <div className="mb-4">
            <div className="flex items-center justify-between mb-3">
              <strong className="text-lg">Remarks:</strong>
              <span className="text-gray-700">
                {modalData?.HWTEACHERREMARKS || "N/A"}
              </span>
            </div>

            <div className="flex items-center justify-between mb-3">
              <strong className="text-lg">Grade:</strong>
              <span className="text-gray-700">
                {modalData?.HWGRADE || "N/A"}
              </span>
            </div>

            <div className="flex items-center justify-between">
              <strong className="text-lg">Mark:</strong>
              <span className="text-gray-700">
                {modalData?.HWMARK || "N/A"}
              </span>
            </div>
          </div>

          {/* Modal Footer */}
          <div className="flex justify-end mt-6">
            <button
              onClick={onClose}
              className="bg-red-600 text-white px-5 py-2 rounded-lg hover:bg-red-700 transition duration-200"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    );
  };

  const checkContentType = () => {
    if (selectedTasks.PhotoLocation.includes("v=")) {
      return (
        <iframe
          title="Notification Content"
          src={`https://www.youtube.com/embed/${
            selectedTasks.PhotoLocation.split("v=")[1].split("&")[0]
          }`}
          className="w-full h-56 border-none"
          style={{ overflow: "auto" }}
        ></iframe>
      );
    }
    if (selectedTasks.PhotoLocation === "") {
      return null;
    } else {
      return (
        <iframe
          title=" Task Content"
          src={`${resourceUrl}/HomeWork/${selectedTasks.PhotoLocation}`}
          className="w-full h-56 border-none"
          style={{ overflow: "auto" }}
        ></iframe>
      );
    }
  };

  const openPopup = (task) => {
    setSelectedTasks(task);
    setShowPopup(true);
  };
  // const fetchExamStatus = (HWID) => {
  //   const filteredData = homeWorkStatus.filter((data) => data.HWID === HWID);

  //   if (filteredData.length > 0) {
  //     const { TchAccepted, TchVerified } = filteredData[0];

  //     // Check if the homework is verified
  //     if (TchVerified) {
  //       return (
  //         <button
  //           className="ml-4 text-white px-3 py-1 rounded-lg"
  //           onClick={() => {
  //             setIsModalOpen(true);
  //             setModalData(filteredData[0]); // Set modal data to show homework details
  //           }}
  //         >
  //           View Status
  //         </button>
  //       );
  //     } else if (TchAccepted) {
  //       return "Accepted";
  //     } else {
  //       return "Submitted";
  //     }
  //   } else {
  //     return "Not Submitted";
  //   }
  // };

  const getStatusStyle = (TchAccepted, TchVerified) => {
    if (TchAccepted === null && TchVerified === null) {
      if (TchVerified) {
        return "bg-green-500 text-sm"; // Green for verified
      } else if (TchAccepted) {
        return "bg-blue-500 text-sm"; // Blue for accepted
      } else {
        return "bg-yellow-500 text-sm"; // Yellow for submitted
      }
    } else {
      return "bg-red-500 text-sm"; // Red for not submitted
    }
  };

  const closePopup = () => {
    setShowPopup(false);
    setSelectedTasks(null);
  };

  const TasksToRender = searchTerm ? filteredTasks : taskList;

  const { sidebarVisible, toggleSidebar } = useSidebar();

  return (
    <div className="container mx-auto p-4">
      {loading ? (
        <Spinner />
      ) : (
        <>
          <div className="flex items-center justify-between mb-4">
            <h1 className="text-3xl font-bold">My Task</h1>
            <input
              type="text"
              placeholder="Search by title"
              value={searchTerm}
              onChange={(e) => handleSearch(e.target.value)}
              className="border border-gray-600 w-80  h-12 px-5 py-1 rounded-md"
            />
          </div>
          <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-4">
            {TasksToRender &&
              TasksToRender.map((task) => (
                <div className="flex border border-gray-200 hover:shadow-md transition duration-300 px-2">
                  <div
                    className="sm:w-5/6 w-4/6"
                    onClick={() => openPopup(task)}
                    key={task.id}
                  >
                    <div className=" rounded-lg p-4 ">
                      <div className="flex items-center">
                        <div className="w-1/3 sm:w-auto mr-4">
                          <img
                            src={renderImage(task.PhotoLocation)}
                            alt={task.title}
                            className="w-20 h-20 sm:w-32 sm:h-32 object-cover rounded"
                          />
                        </div>

                        <div className="w-2/3 ">
                          <h2 className="text-lg font-semibold mb-2">
                            {task.HWSubject}
                          </h2>
                          <h4 className="text-lg font-semibold mb-2">
                            {task.HWDateStr}
                          </h4>
                          <p className="text-gray-600">
                            {task.HWRemarks.substring(0, 50)}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex items-start justify-end py-2 space-x-2  md:w-1/6 w-2/6">
                    <span className="px-2 py-1 rounded-lg text-white">
                    <button
  className={`ml-4 text-white px-3 py-1 rounded-lg ${
    task.HWSTATUS === "Verified" ? "bg-green-600" :
    task.HWSTATUS === "Accepted" ? "bg-blue-600" :
    task.HWSTATUS === "Submitted" ? "bg-yellow-600" :
    "bg-gray-400" // For "Not Submitted" or any other statuses
  }`}
  onClick={() => {
    setIsModalOpen(true);
    setModalData(task); // Set modal data to show homework details
  }}
  disabled={task.HWSTATUS !== "Verified"} // Only enable if status is "Verified"
>
  {task.HWSTATUS === "Verified"
    ? "View Status"
    : task.HWSTATUS}
</button>

                    </span>
                  </div>
                </div>
              ))}
          </div>

          {showPopup && (
            <div className="fixed inset-0 flex items-center justify-center mt-10 bg-black bg-opacity-60">
              <div className="bg-white customPopupHeight relative rounded-lg mt-8 shadow-lg p-8 md:w-1/2 w-full ">
                <div className="w-1/3 sm:w-auto mr-4">
                  <img
                    src={renderImage(selectedTasks.PhotoLocation)}
                    alt=""
                    className="w-20  sm:w-32  object-cover rounded"
                  />
                </div>
                <button
                  onClick={() =>
                    navigate(
                      `/student/u/${customId}/${selectedTasks?.HWID}/${selectedTasks?.HWSubject}`
                    )
                  }
                  className="px-4 py-2  absolute right-28 top-0 mt-8 bg-green-600 text-white rounded"
                >
                  Submit Homework
                </button>

                <button
                  onClick={closePopup}
                  className="px-4 py-2  absolute right-5 top-0 mt-8 bg-red-600 text-white rounded"
                >
                  Close
                </button>

                <div className="w-2/3 ">
                  <h2 className="text-lg font-semibold mb-2">
                    {selectedTasks.HWSubject}
                  </h2>
                  <h4 className="text-lg font-semibold mb-2">
                    {selectedTasks.HWDateStr}
                  </h4>
                  <p className="text-gray-600">{selectedTasks.HWRemarks}</p>
                </div>

                {checkContentType()}
              </div>
            </div>
          )}
        </>
      )}

      {isModalOpen && <Modal onClose={() => setIsModalOpen(false)} />}
    </div>
  );
};

export default MyTask;
