import { adminSyncClass } from "../API/adminSyncClassMaster";
import { adminSyncEmployees } from "../API/adminSyncEmployees";
import { adminSyncMasters } from "../API/adminSyncMasters";
import { adminSyncStudent } from "../API/adminSyncStudent";
import { getInnerDataFromLocalStorage } from "../API/getInnerDataLocalStore";
import AttendanceAbsent from "../assets/Dashboard/absent.png";
import Attendance from "../assets/Dashboard/attendance.png";
import BlackList from "../assets/Dashboard/blacklist.png";
import bookshelf from "../assets/Dashboard/bookshelf.svg";
import { default as Calendar, default as calendar2 } from "../assets/Dashboard/calendar2.svg";
import Canvas from "../assets/Dashboard/canvas.svg";
import Chat from "../assets/Dashboard/chat.svg";
import CheckList from "../assets/Dashboard/checklist.svg";
import classSvgrepo from "../assets/Dashboard/class-svgrepo-com.svg";
import Crowd from "../assets/Dashboard/crowd.png";
import Gallery from "../assets/Dashboard/gallery.svg";
import GoogleClassRoom from "../assets/Dashboard/googleClassRoomUrl.svg";
import GoogleForms from "../assets/Dashboard/googleFormsUrl.svg";
import GoogleMeet from "../assets/Dashboard/googlemeet.svg";
import Graph from "../assets/Dashboard/graph.svg";
import IdCard from "../assets/Dashboard/id-card2.svg";
import MarkEntry from "../assets/Dashboard/markEntry.jpg";
import MicrosoftTeam from "../assets/Dashboard/microsoft.svg";
import Notification from "../assets/Dashboard/notification.svg";
import NotificationSearch from "../assets/Dashboard/notificationSearch.png";
import OnlineSession from "../assets/Dashboard/online-lession.svg";
import PaymentMethod from "../assets/Dashboard/paymentmethod.svg";
import quickInfo from "../assets/Dashboard/quickInfo.svg";
import { default as SmartClass, default as TempNewsLinks } from "../assets/Dashboard/smartClassUrl.svg";
import Staff from "../assets/Dashboard/staffs.png";
import { default as Schoolwebsite, default as StudentPortal } from "../assets/Dashboard/studenPortalUrl.svg";
import students from "../assets/Dashboard/students.jpg";
import sync from "../assets/Dashboard/sync.svg";
import Teacher from "../assets/Dashboard/Teacher.jpg";
import YoutubeChannelLink from "../assets/Dashboard/teacherChannelUrl.svg";
import today_calender from "../assets/Dashboard/today_calender.svg";
import Zoom from "../assets/Dashboard/zoom.svg";
import Poll from "../assets/png/poll.png";

const handleSyncStudent = async (year, initialResourceUrl) => {
  const res = await adminSyncStudent(year, initialResourceUrl);

  return res;
};
const handleSyncTeachers = async (year, initialResourceUrl) => {
  const res = await adminSyncMasters(year, initialResourceUrl);
  return res;
};

const handleSyncEmployees = async (year, initialResourceUrl) => {
  const res = await adminSyncEmployees(year, initialResourceUrl);
  return res;
};

const handleSyncClass = async (year, initialResourceUrl) => {
  const res = await adminSyncClass(year, initialResourceUrl);
  return res;
};

let AdminInitialArray = [
  {
    icon: quickInfo,
    keyName: "QuickInfo",
    caption: "Quick Info",
    actiontype: "btn",
    redirect: "quickinfo",
    type: "menu",
  },
  {
    icon: Canvas,
    keyName: "AdmNoticeBoard",
    caption: "Notice Board",
    actiontype: "btn",
    redirect: "noticeboard",
    type: "menu",
  },
  {
    icon: Crowd,
    keyName: "AdmSA",
    caption: "Strength Analyses",
    actiontype: "btn",
    redirect: "strength",
    type: "menu",
  },
  {
    icon: Staff,
    keyName: "AdmStaff",
    caption: "Staff Details",
    actiontype: "btn",
    redirect: "staff",
    type: "menu",
  },
  {
    icon: Poll,
    keyName: "AdmPoll",
    caption: "Poll",
    actiontype: "btn",
    redirect: "poll",
    type: "menu",
  },
  {
    icon: SmartClass,
    keyName: "AdmStudents",
    caption: "Student Details",
    actiontype: "btn",
    redirect: "smartclass",
    type: "menu",
  },
  {
    icon: BlackList,
    keyName: "AdmBlackListed",
    caption: "Blacklisted Students",
    actiontype: "btn",
    redirect: "blacklist",
    type: "menu",
  },
  {
    icon: Notification,
    keyName: "AdmNotification",
    caption: "Notification",
    actiontype: "btn",
    redirect: "notification",
    type: "menu",
  },
  {
    icon: NotificationSearch,
    keyName: "AdmNoticeSearch",
    caption: "Notice Search",
    actiontype: "btn",
    redirect: "noticeSearch",
    type: "menu",
  },
  {
    icon: CheckList,
    keyName: "AdmHomeWork",
    caption: "Home Work List",
    actiontype: "btn",
    redirect: "homework",
    type: "menu",
  },
  {
    icon: Gallery,
    keyName: "AdmGallery",
    caption: "Gallery List",
    actiontype: "btn",
    redirect: "gallery",
    type: "menu",
  },
  {
    icon: OnlineSession,
    keyName: "AdmCommunication",
    caption: "Communication List",
    actiontype: "btn",
    redirect: "onlinesession",
    type: "menu",
  },
  {
    icon: classSvgrepo,
    keyName: "AdmClassDetails",
    caption: "Class Details",
    actiontype: "btn",
    redirect: "classdetails",
    type: "menu",
  },
  {
    icon: Crowd,
    keyName: "AdmFeesAnalyses",
    caption: "Fees Analyses",
    actiontype: "btn",
    redirect: "feesanalyses",
    type: "menu",
  },
  {
    icon: Canvas,
    keyName: "AdmClassNoticeBoard",
    caption: "Class Notice Board",
    actiontype: "btn",
    redirect: "adminnoticeboard",
    type: "menu",
  },
  {
    icon: SmartClass,
    keyName: "AdmStudentSearch",
    caption: "Student Search",
    actiontype: "btn",
    redirect: "studentsearch",
    type: "menu",
  },
  {
    icon: Canvas,
    keyName: "AdmScrollNoticeBoard",
    caption: "Notice Scroll Board",
    actiontype: "btn",
    redirect: "AdmScrollNoticeBoard",
    type: "menu",
  },
  {
    icon: sync,
    keyName: "Sync Data",
    caption: "Sync Data",
    actiontype: "btn",
    redirect: "studentsearch",
    type: "menu",
    modalContent: [
      {
        index: 1,
        icon: SmartClass,
        keyname: "SyncStudents",
        caption: "Sync Students",
        redirect: "1",
        action: "",
        type: "",
        onclick: handleSyncStudent,
      },
      {
        index: 2,
        icon: SmartClass,
        keyname: "SyncTeachers",
        caption: "Sync Teachers",
        redirect: "1",
        action: "",
        type: "",
        onclick: handleSyncTeachers,
      },
      {
        index: 3,
        icon: SmartClass,
        keyname: "SyncEmployees",
        caption: "Sync Employees",
        redirect: "1",
        action: "",
        type: "",
        onclick: handleSyncEmployees,
      },
      {
        index: 4,
        icon: SmartClass,
        keyname: "SyncClassMaster",
        caption: "Sync Class Master",
        redirect: "1",
        action: "",
        type: "",
        onclick: handleSyncClass,
      },
    ],
  },
  // Add more objects as needed
];

let StudentInitialArray = [
  {
    icon: Notification,
    keyName: "StdNotification",
    caption: "Notification",
    actiontype: "btn",
    redirect: "studentnotification",
    type: "menu",
  },
  {
    icon: CheckList,
    keyName: "StdMyTask",
    caption: "My Task",
    actiontype: "btn",
    redirect: "studenttask",
    type: "menu",
  },
  {
    icon: Gallery,
    keyName: "StdGallery",
    caption: "Gallery",
    actiontype: "btn",
    redirect: "studentgallery",
    type: "menu",
  },
  {
    icon: OnlineSession,
    keyName: "StdMyMentors",
    caption: "My Mentors",
    actiontype: "btn",
    redirect: "studentmentors",
    type: "menu",
  },

  {
    icon: Calendar,
    keyName: "StdTimeTable",
    caption: "Time Table",
    actiontype: "btn",
    redirect: "studenttimetable",
    type: "menu",
  },
  {
    icon: AttendanceAbsent,
    keyName: "StdAttendance",
    caption: "Leave Days",
    actiontype: "btn",
    type: "menu",
  },
  {
    icon: Chat,
    keyName: "StdAskMyMentor",
    caption: "Ask My Mentor",
    actiontype: "btn",
    redirect: "studentaskmentor",
    type: "menu",
  },
  {
    icon: IdCard,
    keyName: "StdThisisMe",
    caption: "This is Me",
    actiontype: "btn",
    redirect: "studentme",
    type: "menu",
  },
  {
    icon: Graph,
    keyName: "StdExams",
    caption: "My performance",
    actiontype: "btn",
    redirect: "studentexams",
    type: "menu",
  },
  {
    icon: Canvas,
    keyName: "StdNoticeBoard",
    caption: "Notice Board",
    actiontype: "btn",
    redirect: "studentnoticeboard",
    type: "menu",
  },
  {
    icon: Poll,
    keyName: "StdPoll",
    caption: "Poll",
    actiontype: "btn",
    redirect: "studentpoll",
    type: "menu",
  },
  {
    icon: Crowd,
    keyName: "StdFees",
    caption: "Fee Details",
    actiontype: "btn",
    redirect: "feesanalyses",
    type: "menu",
  },
  {
    icon: students,
    keyName: "StdClassmates",
    caption: "Classmates",
    actiontype: "btn",
    redirect: "studentclassmates",
    type: "menu",
  },
  {
    icon: Canvas,
    keyName: "StdScrollNoticeBoard",
    caption: "Scroll Notice Board",
    actiontype: "btn",
    redirect: "StdScrollNoticeBoard",
    type: "menu",
  },
  // Add more objects as needed
];

let ClassTeacherInitalArray = [
  {
    icon: Notification,
    keyName: "CTNotification",
    caption: "Notification",
    actiontype: "btn",
    type: "menu",
  },
  {
    icon: CheckList,
    keyName: "CTHomeWork",
    caption: "Home Work",
    actiontype: "btn",
    type: "menu",
  },
  {
    icon: Gallery,
    keyName: "CTGallery",
    caption: "Gallery List",
    actiontype: "btn",
    type: "menu",
  },
  {
    icon: OnlineSession,
    keyName: "CTCommunication",
    caption: "Reply To Parent",
    actiontype: "btn",
    type: "menu",
  },
  {
    icon: Canvas,
    keyName: "CTNoticeBoard",
    caption: "Notice Board",
    actiontype: "btn",
    type: "menu",
  },
  {
    icon: IdCard,
    keyName: "CTMyProfile",
    caption: "My Profile",
    actiontype: "btn",
    type: "menu",
  },
  {
    icon: bookshelf,
    keyName: "CTSubjectList",
    caption: "Subject List",
    actiontype: "btn",
    type: "menu",
  },
  {
    icon: calendar2,
    keyName: "CTTimeTable",
    caption: "Class Time Table",
    actiontype: "btn",
    type: "menu",
  },
  {
    icon: calendar2,
    keyName: "CTTTimeTable",
    caption: "Teacher Time Table",
    actiontype: "btn",
    type: "menu",
  },
  {
    icon: today_calender,
    keyName: "CTTodaySch",
    caption: "Today Schedule",
    actiontype: "btn",
    type: "menu",
  },
  {
    icon: Poll,
    keyName: "CTPoll",
    caption: "Poll",
    actiontype: "btn",
    type: "menu",
  },
  {
    icon: Poll,
    keyName: "CTCPoll",
    caption: "Class Wise Poll",
    actiontype: "btn",
    type: "menu",
  },
  {
    icon: OnlineSession,
    keyName: "CTCBWTeachers",
    caption: "Communication Between Teachers",
    actiontype: "btn",
    type: "menu",
  },
  {
    icon: AttendanceAbsent,
    keyName: "CTAbsantees",
    caption: "Absentees",
    actiontype: "btn",
    type: "menu",
  },
  {
    icon: MarkEntry,
    keyName: "CTMarkEntry",
    caption: "Mark Entry",
    actiontype: "btn",
    type: "menu",
  },
  {
    icon: students,
    keyName: "CTStudents",
    caption: "Students",
    actiontype: "btn",
    type: "menu",
  },
  {
    icon: Teacher,
    keyName: "CTTeachers",
    caption: "Teachers List",
    actiontype: "btn",
    type: "menu",
  },
  {
    icon: Attendance,
    keyName: "CTAttendance",
    caption: "Class Attendance",
    actiontype: "btn",
    type: "menu",
  },
  {
    icon: MarkEntry,
    keyName: "SubjectMarkEntry",
    caption: "Subject Wise Mark Entry",
    actiontype: "btn",
    type: "menu",
  },
];

let SubjectTeacherInitialArray = [
  {
    icon: CheckList,
    keyName: "STHomeWork",
    caption: "Home Work",
    actiontype: "btn",
    redirect: "sthomework",
    type: "menu",
  },

  {
    icon: Gallery,
    keyName: "STGallery",
    caption: "Gallery ",
    actiontype: "btn",
    redirect: "stgallery",
    type: "menu",
  },
  {
    icon: OnlineSession,
    keyName: "STParentReply",
    caption: "Reply To Parent ",
    actiontype: "btn",
    redirect: "stonlinesession",
    type: "menu",
  },
  {
    icon: Canvas,
    keyName: "STNoticeBoard",
    caption: "Notice Board",
    actiontype: "btn",
    redirect: "subjectTeachernoticeboard",
    type: "menu",
  },
  {
    icon: IdCard,
    keyName: "STMyProfile",
    caption: "My Profile",
    actiontype: "btn",
    redirect: "subjectteacherprofile",
    type: "menu",
  },
  {
    icon: bookshelf,
    keyName: "STSubjectList",
    caption: "Subject List",
    actiontype: "btn",
    redirect: "subjectlist",
    type: "menu",
  },
  {
    icon: calendar2,
    keyName: "STTimeTable",
    caption: "Time Table",
    actiontype: "btn",
    redirect: "timetable",
    type: "menu",
  },
  {
    icon: Poll,
    keyName: "STPoll",
    caption: "Poll",
    actiontype: "btn",
    redirect: "STPoll",
    type: "menu",
  },
  {
    icon: OnlineSession,
    keyName: "STCom",
    caption: "Communication Between Teachers ",
    actiontype: "btn",
    type: "menu",
  },
];

let StudentArray = [...StudentInitialArray];

let AdminArray = [...AdminInitialArray];

const addNewAdminItem = (newItem) => {
  AdminArray.push(newItem); // Function to add a new item to StudentArray
};

const userDetailsDataValues = async (item) => {
  let student = [...StudentInitialArray];
  let classTeacher = [...ClassTeacherInitalArray];
  let subjectTeacher = [...SubjectTeacherInitialArray];
  let adminData = [...AdminInitialArray];

  const MatchedInnerArray = await getInnerDataFromLocalStorage(item.userId, item.schoolId, item.userType, item.year);

  if (item.userType === "Student") {
    if (MatchedInnerArray) {
      MatchedInnerArray.forEach((item) => {
        // console.log(item,'item menu')

        if (item.InfoField !== "hide" && item.ItemName === "Online Payment") {
          const newOnlinePaymentItem = {
            icon: PaymentMethod, // Replace with your desired icon import
            keyName: "OnlinePayment",
            caption: item.ItemName,
            actiontype: "url",
            redirect: item.InfoField,
            type: "ql",
          };

          student.push(newOnlinePaymentItem);
        } else if (item.InfoField !== "hide" && item.ItemName === "Student Portal") {
          const newStudentPortalItem = {
            icon: StudentPortal, // Replace with your desired icon import
            keyName: "StudentPortal",
            caption: item.ItemName,
            actiontype: "url",
            redirect: item.InfoField,
            type: "ql",
          };

          student.push(newStudentPortalItem);
        } else if (item.InfoField !== "hide" && item.ItemName === "School Website") {
          const newStudentPortalItem = {
            icon: Schoolwebsite, // Replace with your desired icon import
            keyName: "SchoolWebsite",
            caption: item.ItemName,
            actiontype: "url",
            redirect: item.InfoField,
            type: "ql",
          };

          student.push(newStudentPortalItem);
        } else if (item.InfoField !== "hide" && item.ItemName === "Youtube Channel Link") {
          const newStudentPortalItem = {
            icon: YoutubeChannelLink, // Replace with your desired icon import
            keyName: "YoutubeChannelLink",
            caption: item.ItemName,
            actiontype: "url",
            redirect: item.InfoField,
            type: "ql",
          };

          student.push(newStudentPortalItem);
        } else if (item.InfoField !== "hide" && item.ItemName === "Google Class Room") {
          const newStudentPortalItem = {
            icon: GoogleClassRoom, // Replace with your desired icon import
            keyName: "GoogleClassRoom",
            caption: item.ItemName,
            actiontype: "url",
            redirect: item.InfoField,
            type: "ql",
          };

          student.push(newStudentPortalItem);
        } else if (item.InfoField !== "hide" && item.ItemName === "Google Meet") {
          const newStudentPortalItem = {
            icon: GoogleMeet, // Replace with your desired icon import
            keyName: "GoogleMeet",
            caption: item.ItemName,
            actiontype: "url",
            redirect: item.InfoField,
            type: "ql",
          };

          student.push(newStudentPortalItem);
        } else if (item.InfoField !== "hide" && item.ItemName === "Microsoft Team") {
          const newStudentPortalItem = {
            icon: MicrosoftTeam, // Replace with your desired icon import
            keyName: "MicrosoftTeam",
            caption: item.ItemName,
            actiontype: "url",
            redirect: item.InfoField,
            type: "ql",
          };

          student.push(newStudentPortalItem);
        } else if (item.InfoField !== "hide" && item.ItemName === "Google Forms") {
          const newStudentPortalItem = {
            icon: GoogleForms, // Replace with your desired icon import
            keyName: "GoogleForms",
            caption: item.ItemName,
            actiontype: "url",
            redirect: item.InfoField,
            type: "ql",
          };

          student.push(newStudentPortalItem);
        } else if (item.InfoField !== "hide" && item.ItemName === "Zoom") {
          const newStudentPortalItem = {
            icon: Zoom, // Replace with your desired icon import
            keyName: "Zoom",
            caption: item.ItemName,
            actiontype: "url",
            redirect: item.InfoField,
            type: "ql",
          };

          student.push(newStudentPortalItem);
        } else if (item.InfoField !== "hide" && item.ItemName === "Smart Class") {
          const newStudentPortalItem = {
            icon: SmartClass, // Replace with your desired icon import
            keyName: "SmartClass",
            caption: item.ItemName,
            actiontype: "url",
            redirect: item.InfoField,
            type: "ql",
          };

          student.push(newStudentPortalItem);
        } else if (item.InfoField !== "hide" && item.ItemName === "Temp. New Links") {
          const newStudentPortalItem = {
            icon: TempNewsLinks, // Replace with your desired icon import
            keyName: "TempNewLinks",
            caption: item.ItemName,
            actiontype: "url",
            redirect: item.InfoField,
            type: "ql",
          };

          student.push(newStudentPortalItem);
        }
      });
    }

    return student;
  } else if (item.thType === "CT") {
    if (MatchedInnerArray) {
      MatchedInnerArray.forEach((item) => {
        if (item.InfoField !== "hide" && item.ItemName === "Online Payment") {
          const AddItem = {
            icon: PaymentMethod, // Replace with your desired icon import
            keyName: "OnlinePayment",
            caption: item.ItemName,
            actiontype: "url",
            redirect: item.InfoField,
            type: "ql",
          };

          classTeacher.push(AddItem);
        } else if (item.InfoField !== "hide" && item.ItemName === "Student Portal") {
          const AddItem = {
            icon: StudentPortal, // Replace with your desired icon import
            keyName: "StudentPortal",
            caption: item.ItemName,
            actiontype: "url",
            redirect: item.InfoField,
            type: "ql",
          };

          classTeacher.push(AddItem);
        } else if (item.InfoField !== "hide" && item.ItemName === "School Website") {
          const AddItem = {
            icon: Schoolwebsite, // Replace with your desired icon import
            keyName: "SchoolWebsite",
            caption: item.ItemName,
            actiontype: "url",
            redirect: item.InfoField,
            type: "ql",
          };

          classTeacher.push(AddItem);
        } else if (item.InfoField !== "hide" && item.ItemName === "Youtube Channel Link") {
          const AddItem = {
            icon: YoutubeChannelLink, // Replace with your desired icon import
            keyName: "YoutubeChannelLink",
            caption: item.ItemName,
            actiontype: "url",
            redirect: item.InfoField,
            type: "ql",
          };

          classTeacher.push(AddItem);
        } else if (item.InfoField !== "hide" && item.ItemName === "Google Class Room") {
          const AddItem = {
            icon: GoogleClassRoom, // Replace with your desired icon import
            keyName: "GoogleClassRoom",
            caption: item.ItemName,
            actiontype: "url",
            redirect: item.InfoField,
            type: "ql",
          };

          classTeacher.push(AddItem);
        } else if (item.InfoField !== "hide" && item.ItemName === "Google Meet") {
          const AddItem = {
            icon: GoogleMeet, // Replace with your desired icon import
            keyName: "GoogleMeet",
            caption: item.ItemName,
            actiontype: "url",
            redirect: item.InfoField,
            type: "ql",
          };

          classTeacher.push(AddItem);
        } else if (item.InfoField !== "hide" && item.ItemName === "Microsoft Team") {
          const AddItem = {
            icon: MicrosoftTeam, // Replace with your desired icon import
            keyName: "MicrosoftTeam",
            caption: item.ItemName,
            actiontype: "url",
            redirect: item.InfoField,
            type: "ql",
          };

          classTeacher.push(AddItem);
        } else if (item.InfoField !== "hide" && item.ItemName === "Google Forms") {
          const AddItem = {
            icon: GoogleForms, // Replace with your desired icon import
            keyName: "GoogleForms",
            caption: item.ItemName,
            actiontype: "url",
            redirect: item.InfoField,
            type: "ql",
          };

          classTeacher.push(AddItem);
        } else if (item.InfoField !== "hide" && item.ItemName === "Zoom") {
          const AddItem = {
            icon: Zoom, // Replace with your desired icon import
            keyName: "Zoom",
            caption: item.ItemName,
            actiontype: "url",
            redirect: item.InfoField,
            type: "ql",
          };

          classTeacher.push(AddItem);
        } else if (item.InfoField !== "hide" && item.ItemName === "Smart Class") {
          const AddItem = {
            icon: SmartClass, // Replace with your desired icon import
            keyName: "SmartClass",
            caption: item.ItemName,
            actiontype: "url",
            redirect: item.InfoField,
            type: "ql",
          };

          classTeacher.push(AddItem);
        } else if (item.InfoField !== "hide" && item.ItemName === "Temp. New Links") {
          const newStudentPortalItem = {
            icon: TempNewsLinks, // Replace with your desired icon import
            keyName: "TempNewLinks",
            caption: item.ItemName,
            actiontype: "url",
            redirect: item.InfoField,
            type: "ql",
          };

          classTeacher.push(newStudentPortalItem);
        }
      });
    }

    console.log(classTeacher);

    return classTeacher;
  } else if (item.thType === "ST") {
    if (MatchedInnerArray) {
      MatchedInnerArray.forEach((item) => {
        if (item.InfoField !== "hide" && item.ItemName === "Online Payment") {
          const AddItem = {
            icon: PaymentMethod, // Replace with your desired icon import
            keyName: "OnlinePayment",
            caption: item.ItemName,
            actiontype: "url",
            redirect: item.InfoField,
            type: "ql",
          };

          subjectTeacher.push(AddItem);
        } else if (item.InfoField !== "hide" && item.ItemName === "Student Portal") {
          const AddItem = {
            icon: StudentPortal, // Replace with your desired icon import
            keyName: "StudentPortal",
            caption: item.ItemName,
            actiontype: "url",
            redirect: item.InfoField,
            type: "ql",
          };

          subjectTeacher.push(AddItem);
        } else if (item.InfoField !== "hide" && item.ItemName === "School Website") {
          const AddItem = {
            icon: Schoolwebsite, // Replace with your desired icon import
            keyName: "SchoolWebsite",
            caption: item.ItemName,
            actiontype: "url",
            redirect: item.InfoField,
            type: "ql",
          };

          subjectTeacher.push(AddItem);
        } else if (item.InfoField !== "hide" && item.ItemName === "Youtube Channel Link") {
          const AddItem = {
            icon: YoutubeChannelLink, // Replace with your desired icon import
            keyName: "YoutubeChannelLink",
            caption: item.ItemName,
            actiontype: "url",
            redirect: item.InfoField,
            type: "ql",
          };

          subjectTeacher.push(AddItem);
        } else if (item.InfoField !== "hide" && item.ItemName === "Google Class Room") {
          const AddItem = {
            icon: GoogleClassRoom, // Replace with your desired icon import
            keyName: "GoogleClassRoom",
            caption: item.ItemName,
            actiontype: "url",
            redirect: item.InfoField,
            type: "ql",
          };

          subjectTeacher.push(AddItem);
        } else if (item.InfoField !== "hide" && item.ItemName === "Google Meet") {
          const AddItem = {
            icon: GoogleMeet, // Replace with your desired icon import
            keyName: "GoogleMeet",
            caption: item.ItemName,
            actiontype: "url",
            redirect: item.InfoField,
            type: "ql",
          };

          subjectTeacher.push(AddItem);
        } else if (item.InfoField !== "hide" && item.ItemName === "Microsoft Team") {
          const AddItem = {
            icon: MicrosoftTeam, // Replace with your desired icon import
            keyName: "MicrosoftTeam",
            caption: item.ItemName,
            actiontype: "url",
            redirect: item.InfoField,
            type: "ql",
          };

          subjectTeacher.push(AddItem);
        } else if (item.InfoField !== "hide" && item.ItemName === "Google Forms") {
          const AddItem = {
            icon: GoogleForms, // Replace with your desired icon import
            keyName: "GoogleForms",
            caption: item.ItemName,
            actiontype: "url",
            redirect: item.InfoField,
            type: "ql",
          };

          subjectTeacher.push(AddItem);
        } else if (item.InfoField !== "hide" && item.ItemName === "Zoom") {
          const AddItem = {
            icon: Zoom, // Replace with your desired icon import
            keyName: "Zoom",
            caption: item.ItemName,
            actiontype: "url",
            redirect: item.InfoField,
            type: "ql",
          };

          subjectTeacher.push(AddItem);
        } else if (item.InfoField !== "hide" && item.ItemName === "Smart Class") {
          const AddItem = {
            icon: SmartClass, // Replace with your desired icon import
            keyName: "SmartClass",
            caption: item.ItemName,
            actiontype: "url",
            redirect: item.InfoField,
            type: "ql",
          };

          subjectTeacher.push(AddItem);
        } else if (item.InfoField !== "hide" && item.ItemName === "Temp. New Links") {
          const newStudentPortalItem = {
            icon: TempNewsLinks, // Replace with your desired icon import
            keyName: "TempNewLinks",
            caption: item.ItemName,
            actiontype: "url",
            redirect: item.InfoField,
            type: "ql",
          };

          subjectTeacher.push(newStudentPortalItem);
        }
      });
    }

    return subjectTeacher;
  } else {
    return adminData;
  }
};

//    addNewStudentItem(newItem)

export {
  AdminArray,
  AdminInitialArray, ClassTeacherInitalArray, StudentArray, StudentInitialArray, SubjectTeacherInitialArray, userDetailsDataValues
};

