import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import Spinner from "../../components/Spinner";
import useSidebar from "../../hooks/useSidebar";
import Images from "../../assets/Files/images.png";
import PDFImage from "../../assets/Files/pdficconpng.png";
import powerpointIcon from "../../assets/Files/PPTIcon.png";
import WordIcon from "../../assets/Files/wordicon.png";
import VideoIcon from "../../assets/Files/videoicon.png";
import Youtube from "../../assets/Files/youtube.png";
import Excel from "../../assets/Files/excel.svg";
import textMessage from "../../assets/Files/textmsg.png";
import OtherImg from "../../assets/Files/otherico.png";
import { getMyMentorsListDetail } from "../../API/getMyMentorsList";
import AudioIcon from "../../assets/Files/musicIcon.png";

const MyMentors = () => {
  const { customId } = useParams();
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredMyMentors, setFilteredMyMentors] = useState([]);
  const [userDetails, setUserDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [myMentorsList, setMyMentorsList] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [selectedMyMentors, setSelectedMyMentors] = useState(null);
  const [contentType, setContentType] = useState('default');
  const [initialResourceUrl, setInitialResourceUrl] = useState(null);

  const handleSearch = (value) => {
    setSearchTerm(value);

    if (value.trim() === "") {
      setFilteredMyMentors([]);
    } else {
      const filtered = myMentorsList.filter((mentor) =>
        mentor.SUBJ.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredMyMentors(filtered);
    }
  };

  const renderImage = (photoLocation) => {

  
    console.log(photoLocation);

    if (photoLocation === "") {
    
      return textMessage;
    }

    if (photoLocation.endsWith(".jpg")) {
 
      // setContentType('Same')
      return Images;
    } else if (photoLocation.endsWith(".pdf")) {
      // setContentType("Same")
      return PDFImage;
    } else if (photoLocation.includes(".ppt")) {
      // setContentType("Same")
      return powerpointIcon;
    } else if (photoLocation.includes(".doc")) {
      // setContentType("Same")
      return WordIcon;
    } else if (photoLocation.includes(".xls")) {
      // setContentType("Same")
      return Excel;
    } else if (photoLocation.includes(".mp4")) {
      // setContentType("Same")
      return VideoIcon;
    } else if (photoLocation.includes(".mp3")) {
      // setContentType("Same")
      return AudioIcon;
    } else if (photoLocation.includes("v=")) {
    
      return Youtube;
    } else {
      return OtherImg;
      
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const users = JSON.parse(localStorage.getItem("registeredUsers")) || [];

      if (customId) {
        const decodedData = JSON.parse(atob(customId));
        let matchedItem = [];

        users.forEach((innerArray) => {
          const [userData] = innerArray;

          if (
            userData.userId === decodedData.userId &&
            userData.userType === decodedData.userType &&
            userData.schoolId === decodedData.schoolId &&
            userData.year === decodedData.year
          ) {
            matchedItem.push(userData);
          }
        });

        const result = await getMyMentorsListDetail(
          decodedData.userId,
          decodedData.schoolId,
          decodedData.userType,
          decodedData.year
        );

    

        console.log("MENTORS RESULT", result);
         setMyMentorsList(result);
        setLoading(false);
        setUserDetails(matchedItem);
      }
    };

    fetchData();
  }, [customId]);

  const { sidebarVisible, toggleSidebar } = useSidebar();

  const colors = ['#FF6633', '#FFB399', '#FF33FF', '#FFFF99', '#00B3E6', '#E6B333', '#3366E6', '#999966'];
  let colorIndex = 0;

  const getColor = () => {
    const color = colors[colorIndex];
    colorIndex = (colorIndex + 1) % colors.length;
    return color;
  };

 

  const MentorsToRender = searchTerm ? filteredMyMentors : myMentorsList;

  return (
    <div className="container mx-auto p-4">
      {loading ? (
        <Spinner />
      ) : (
        <>
          <div className="flex items-center justify-between mb-4">
            <h1 className="md:text-3xl font-bold text-xl ">My Mentors</h1>
            <input
              type="text"
              placeholder="Search by title"
              value={searchTerm}
              onChange={(e) => handleSearch(e.target.value)}
              className="border border-gray-600 md:w-80  w-48 h-12 md:px-5 px-2 py-1 rounded-md"
            />
          </div>
          <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-4">
            {
            MentorsToRender &&
              MentorsToRender.map((item) => (
                <Link
                  to={``}
                  onClick={''}
                  key={item.id}
                >
                  <div className="border border-gray-200 rounded-lg p-4 hover:shadow-md transition duration-300">
                    <div className="flex items-center">
                      <div className="w-1/3 sm:w-auto mr-4">
                      <div
  className={`w-20 flex justify-center items-center h-20  sm:w-32 sm:h-32 object-cover rounded-ful`}
 
>
                            
                            
                  <div
                        className="rounded-full flex items-center justify-center font-semibold text-xl h-20 w-20 text-white"
                        style={{ backgroundColor: getColor() }}
                      >
                        {item.SUBJ.substring(0, 1)}
                      </div>
                        
                        
                        
                        </div>
                      </div>

                      <div className="w-2/3 ">
                        <h3 className="text-lg font-semibold mb-2">
                          Teacher name: {item.EmpName}
                        </h3>
                        <h4 className="text-md font-normal mb-2">
                          Subject : {item.SUBJ}
                        </h4>
                        <p className="text-md font-normal mb-2">
                           Weekly hours : {item.Cnd}
                        </p>
                      </div>


                    </div>
                  </div>
                </Link>
              ))}


          </div>

        </>
      )}

      
    </div>
  );
};

export default MyMentors;
