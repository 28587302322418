import axios from "axios";
import { getUserDataFromLocalByKey } from "./getStudentInfoByKeyLocal";

export const getAdminNotificationSearchList = async (userId, schoolId, userType, year, FromDate, ToDate, Msg, className, sec) => {
  try {
    const key = "webServiceURLAWSDOTNET";
    const returnUrl = await getUserDataFromLocalByKey(userId, schoolId, userType, year, key);
    const formData = new FormData();

    formData.append("title", "NotificationTeacher");
    formData.append("description", "");
    formData.append("ReqClass", className ? className : "");
    formData.append("ReqSection", sec ? sec : "");
    formData.append("ReqYear", year);
    formData.append("ReqPara1", "");
    formData.append("ReqDate1", FromDate ? FromDate : "");
    formData.append("ReqDate2", ToDate ? ToDate : "");
    formData.append("ReqMessage", Msg ? Msg : "");
    formData.append("ReqAdmNo", "");

    
    let userdata = [];
    const Corskey = process.env.REACT_APP_CORS_URL;
    const result = await axios.post(`${Corskey}${returnUrl}`, formData);
    userdata = JSON.parse(result.data.substring(0, result.data.indexOf("||JasonEnd", 0)));
    return userdata;
  } catch (error) {
    console.error("An error occurred:", error);
  }
};
