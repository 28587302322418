import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { getNotificationListDetail } from "../../API/getNotificationList";
import Spinner from "../../components/Spinner";
import useSidebar from "../../hooks/useSidebar";
import Images from "../../assets/Files/images.png";
import PDFImage from "../../assets/Files/pdficconpng.png";
import powerpointIcon from "../../assets/Files/PPTIcon.png";
import WordIcon from "../../assets/Files/wordicon.png";
import VideoIcon from "../../assets/Files/Video.png";
import Youtube from "../../assets/Files/youtube.png";
import Excel from "../../assets/Files/excel.svg";
import textMessage from "../../assets/Files/textmsg.png";
import OtherImg from "../../assets/Files/otherico.png";
import { getUserDataFromLocalByKey } from "../../API/getStudentInfoByKeyLocal";
import AudioIcon from "../../assets/Files/musicIcon.png";

const Notification = () => {
  const { customId } = useParams();
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredNotifications, setFilteredNotifications] = useState([]);
  const [userDetails, setUserDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [notificationList, setNotificationList] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [selectedNotification, setSelectedNotification] = useState(null);
  const [contentType, setContentType] = useState("default");
  const [initialResourceUrl, setInitialResourceUrl] = useState(null);
  const [resourceUrl,setResourceUrl] = useState(null);

  const handleSearch = (value) => {
    setSearchTerm(value);

    if (value.trim() === "") {
      setFilteredNotifications([]);
    } else {
      const filtered = notificationList.filter((notification) =>
        notification.HWSubject.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredNotifications(filtered);
    }
  };

  const renderImage = (photoLocation) => {
    console.log(photoLocation);

    if (photoLocation === "") {
      return textMessage;
    }

    if (photoLocation.endsWith(".jpg")) {
      // setContentType('Same')
      return Images;
    } else if (photoLocation.endsWith(".pdf")) {
      // setContentType("Same")
      return PDFImage;
    } else if (photoLocation.includes(".ppt")) {
      // setContentType("Same")
      return powerpointIcon;
    } else if (photoLocation.includes(".doc")) {
      // setContentType("Same")
      return WordIcon;
    } else if (photoLocation.includes(".xls")) {
      // setContentType("Same")
      return Excel;
    } else if (photoLocation.includes(".mp4")) {
      // setContentType("Same")
      return VideoIcon;
    } else if (photoLocation.includes(".mp3")) {
      // setContentType("Same")
      return AudioIcon;
    } else if (photoLocation.includes("v=")) {
      return Youtube;
    } else {
      return OtherImg;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const users = JSON.parse(localStorage.getItem("registeredUsers")) || [];

      if (customId) {
        const decodedData = JSON.parse(atob(customId));
        let matchedItem = [];

        users.forEach((innerArray) => {
          const [userData] = innerArray;

          if (
            userData.userId === decodedData.userId &&
            userData.userType === decodedData.userType &&
            userData.schoolId === decodedData.schoolId &&
            userData.year === decodedData.year
          ) {
            matchedItem.push(userData);
          }
        });


         const key="WebResourceAWSDOTNETURL"
         const dataResourceUrl = await getUserDataFromLocalByKey(
          decodedData.userId,
          decodedData.schoolId,
          decodedData.userType,
          decodedData.year,
          key
        );
        setResourceUrl(dataResourceUrl)

        const result = await getNotificationListDetail(
          decodedData.userId,
          decodedData.schoolId,
          decodedData.userType,
          decodedData.year
        );

        const keyval = "webServiceURLAWSDOTNET";
        const dataUrl = await getUserDataFromLocalByKey(
          decodedData.userId,
          decodedData.schoolId,
          decodedData.userType,
          decodedData.year,
          keyval
        );
        setInitialResourceUrl(dataUrl);

        console.log("URL RESULT", dataUrl);

        console.log("NOTIFICATION RESULT", result);
        setNotificationList(result);
        setLoading(false);
        setUserDetails(matchedItem);
      }
    };

    fetchData();
  }, [customId]);

  const { sidebarVisible, toggleSidebar } = useSidebar();

  const checkContentType = () => {
    if (selectedNotification.PhotoLocation.includes("v=")) {
      return (
        <iframe
          title="Notification Content"
          src={`https://www.youtube.com/embed/${
            selectedNotification.PhotoLocation.split("v=")[1].split("&")[0]
          }`}
          className="w-full h-56 border-none"
          style={{ overflow: "auto" }}
        ></iframe>
      );
    }
    if (selectedNotification.PhotoLocation === "") {
      return null;
    } else {
      return (
        <iframe
          title="Notification Content"
          src={`${resourceUrl}/Notification/${selectedNotification.PhotoLocation}`}
          className="w-full h-56 border-none"
          style={{ overflow: "auto" }}
        ></iframe>
      );
    }
  };

  const openPopup = (notification) => {
    setSelectedNotification(notification);
    setShowPopup(true);
  };

  const closePopup = () => {
    setShowPopup(false);
    setSelectedNotification(null);
  };

  const notificationsToRender = searchTerm
    ? filteredNotifications
    : notificationList;

  return (
    <div className="container mx-auto p-4">
      {loading ? (
        <Spinner />
      ) : (
        <>
          <div className="flex items-center justify-between mb-4">
            <h1 className="md:text-3xl text-xl font-bold">Notifications</h1>
            <input
              type="text"
              placeholder="Search by title"
              value={searchTerm}
              onChange={(e) => handleSearch(e.target.value)}
              className="border border-gray-600 md:w-80 w-64  h-12 px-5 py-1 rounded-md"
            />
          </div>
          <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-4">
            {notificationsToRender &&
              notificationsToRender.map((notification) => (
                <Link
                  to={""}
                  onClick={() => openPopup(notification)}
                  key={notification.id}
                >
                  <div className="border border-gray-200 rounded-lg p-4 hover:shadow-md transition duration-300">
                    <div className="flex items-center">
                      <div className="w-1/3 sm:w-auto mr-4">
                        <img
                          src={renderImage(notification.PhotoLocation)}
                          alt={notification.title}
                          className="w-20 h-20 sm:w-32 sm:h-32 object-cover rounded"
                        />
                      </div>

                      <div className="w-2/3 ">
                        <h2 className="text-lg font-semibold mb-2">
                          {notification.HWSubject}
                        </h2>
                        <h4 className="text-lg font-semibold mb-2">
                          {notification.HWDateStr}
                        </h4>
                        <p className="text-gray-600">
                          {notification.HWRemarks.substring(0, 50)}
                        </p>
                      </div>
                    </div>
                  </div>
                </Link>
              ))}
          </div>

          {showPopup && (
            <div className="fixed inset-0 flex items-center justify-center mt-10 bg-black bg-opacity-60">
              <div className="bg-white customPopupHeight relative rounded-lg mt-8 shadow-lg p-8 md:w-1/2 w-full ">
                <div className="w-1/3 sm:w-auto mr-4">
                  <img
                    src={renderImage(selectedNotification.PhotoLocation)}
                    alt=""
                    className="w-20  sm:w-32  object-cover rounded"
                  />
                </div>

                <button
                  onClick={closePopup}
                  className="px-4 py-2  absolute right-5 top-0 mt-8 bg-red-600 text-white rounded"
                >
                  Close
                </button>

                <div className="w-2/3 ">
                  <h2 className="text-lg font-semibold mb-2">
                    {selectedNotification.HWSubject}
                  </h2>
                  <h4 className="text-lg font-semibold mb-2">
                    {selectedNotification.HWDateStr}
                  </h4>
                  <p className="text-gray-600">
                    {selectedNotification.HWRemarks}
                  </p>
                </div>

                {checkContentType()}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Notification;
