import React, { useState, useEffect } from "react";
import Navbar from "../../components/Navbar";
import Sidebar from "../../components/Sidebar";
import { useSidebar } from "../../context/SidebarContext";
import { useParams } from "react-router-dom";
import Spinner from "../../components/Spinner";
import { getUserDataFromLocalByKey } from "../../API/getStudentInfoByKeyLocal";
import { getClassWiseGenderList } from "../../API/getClassWiseGenderListAPI";
function GWCReport() {
  const [userType, setUserType] = useState("");
  const [userDetails, setUserDetails] = useState(null);
  const [data, setData] = useState(true);
  const { customId } = useParams();
  const [classGenderList, setClassGenderList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [initialResourceUrl, setInitialResourceUrl] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const users = JSON.parse(localStorage.getItem("registeredUsers")) || [];

      if (customId) {
        const decodedData = JSON.parse(atob(customId));
        let matchedItem = [];

        users.forEach((innerArray) => {
          const [userData] = innerArray;

          if (
            userData.userId === decodedData.userId &&
            userData.userType === decodedData.userType &&
            userData.schoolId === decodedData.schoolId &&
            userData.year === decodedData.year
          ) {
            matchedItem.push(userData);
          }
        });

        const result = await getClassWiseGenderList(
            decodedData.userId,
            decodedData.schoolId,
            decodedData.userType,
            decodedData.year
          );

        const keyval = "webResourceURL";
        const dataUrl = await getUserDataFromLocalByKey(
          decodedData.userId,
          decodedData.schoolId,
          decodedData.userType,
          decodedData.year,
          keyval
        );
        setInitialResourceUrl(dataUrl);
        setClassGenderList(result);
        setLoading(false);
        setUserDetails(matchedItem);
      }
    };

    fetchData();
  }, [customId]);

  console.log(classGenderList);

  const { isSidebarOpen, currentSidebarTab, toggleSidebar,setIsSidebarOpen } = useSidebar();

  return (
    <>
      {data && (
        <Navbar
          data={userDetails}
          // isOpen={sidebarVisible}
          toggleSidebar={toggleSidebar}
        />
      )}

      <div className="flex">
        {data && <Sidebar data={userDetails} />}

        <div className={`w-full absolute ${isSidebarOpen?"lg:w-4/6 xl:w-9/12":"w-full"} sm:w-11/12 right-0 top-16 mx-auto   p-4 md:p-6 lg:p-8 xl:p-10`}>
          <div className="flex items-center justify-between mb-4">
            <div className="w-2/3">
              <h2 className=" md:text-2xl font-bold text-xl mb-2 md:mb-0">
                Admin :
                {userDetails &&
                  userDetails.length > 0 &&
                  userDetails[0].schoolId}
              </h2>
              <h4 className="mb-2 font-bold">Class Wise Gender Report </h4>
            </div>
          </div>

          <div className="table-container overflow-y-auto">
            <table className="w-full table-auto border-collapse border border-gray-300">
              <thead>
                <tr className="bg-blue-500">
                  <th className="sticky top-0 bg-blue-700 z-10 p-2 border left-0">
                    Sl.No
                  </th>
                  <th className="sticky top-0 bg-blue-700 z-10 p-2 border">
                    Class
                  </th>
                  <th className="sticky top-0 bg-blue-700 z-10 p-2 border">Male</th>
                  <th className="sticky top-0 bg-blue-700 z-10 p-2 border">Female</th>
                  <th className="sticky top-0 bg-blue-700 z-10 p-2 border">Total</th>
                  {/* Add more columns as needed */}
                </tr>
              </thead>
              <tbody>
                {/* Populate table rows based on classWiseStrengthList */}
                {classGenderList.slice(1).map((item, index) => (
                  <tr key={index} className="bg-gray-300">
                    <td className="p-2 border text-center">
                      {item.Slno}
                    </td>
                    <td className="p-2 border text-center">{item.Head1}</td>
                    <td className="p-2 border text-center">{item.Col1}</td>
                    <td className="p-2 border text-center">{item.Col2}</td>
                    <td className="p-2 border text-center">{item.TotNos}</td>
                    {/* Add more columns as needed */}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          </div>

      </div>
    </>
  );
}

export default GWCReport;
