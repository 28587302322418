import React, { useState, useRef, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getUserDataFromLocalByKey } from "../../API/getStudentInfoByKeyLocal";
import { IoCloseCircle } from "react-icons/io5";
import Navbar from "../../components/Navbar";
import Sidebar from "../../components/Sidebar";
import Spinner from "../../components/Spinner";
import { useSidebar } from "../../context/SidebarContext";

import { adminUpdatePoll } from "../../API/adminUpdatePollAPI";
import { GeteScrollingNoticeByCode } from "../../API/geteScrollingNoticeByCode";
import { updateScrollingNotice } from "../../API/updateScrollingNotice";

function AdminScrollNoticeSubmit() {
  const { customId, NID } = useParams();

  const [userDetails, setUserDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [initialResourceUrl, setInitialResourceUrl] = useState(null);
  const [data, setData] = useState(true);
  const [notice, setNotice] = useState({});

  const [rcdid, setRcdid] = useState("");
  const { isSidebarOpen, currentSidebarTab, toggleSidebar, setIsSidebarOpen } =
    useSidebar();

  const navigate = useNavigate();

  //   const [checkboxes, setCheckboxes] = useState({
  //     checkbox1: false,
  //     checkbox2: false,
  //     checkbox3: false,
  //   });

  console.log(notice);

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(value);

    setNotice((prevData) => ({
      ...prevData,
      [name]: name === "IsActive" ? value === "true" : value, // Convert string to boolean
    }));
  };

  useEffect(() => {
    const fetchData = async () => {
      const users = JSON.parse(localStorage.getItem("registeredUsers")) || [];

      if (customId) {
        const decodedData = JSON.parse(atob(customId));
        let matchedItem = [];

        users.forEach((innerArray) => {
          const [userData] = innerArray;

          if (
            userData.userId === decodedData.userId &&
            userData.userType === decodedData.userType &&
            userData.schoolId === decodedData.schoolId &&
            userData.year === decodedData.year
          ) {
            matchedItem.push(userData);
          }
        });

        const keyval = "webResourceURL";
        const dataUrl = await getUserDataFromLocalByKey(
          decodedData.userId,
          decodedData.schoolId,
          decodedData.userType,
          decodedData.year,
          keyval
        );

        const response = await GeteScrollingNoticeByCode(
          decodedData.userId,
          decodedData.schoolId,
          decodedData.userType,
          decodedData.year,
          NID
        );

        if (response[0]?.ActionType === 1) {
          const noticeList = JSON.parse(response[0]?.JSONData1);

          setNotice(noticeList[0]);
        }

        setInitialResourceUrl(dataUrl);
        setLoading(false);
        setUserDetails(matchedItem);
      }
    };

    fetchData();
  }, [customId, NID]);

  const handleChangeCheckBox = (e) => {
    const { checked } = e.target;

    setNotice((prevData) => ({
      ...prevData,
      IsActive: checked, // Directly use the checked value
    }));
  };

  console.log(notice,3424234);
  

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (notice.NID === 0) {
      setNotice((prevData) => ({
        ...prevData,
        AddedBy: userDetails[0].userId, // Directly use value
      }));
    }

  

   

    console.log(notice);

    try {
      const response = await updateScrollingNotice(
        userDetails[0].userId,
        userDetails[0].schoolId,
        userDetails[0].userType,
        userDetails[0].year,
        notice
      );

      if (response[0]?.ActionType === 1) {
        toast.success(response[0].JSONData1);
        navigate(-1);
      } else {
        toast.error(response[0].ErrorMessage);
      }
    } catch (error) {}
  };

  if (loading) return <Spinner />;

  return (
    <>
      {data && (
        <Navbar
          data={userDetails}
          // isOpen={sidebarVisible}
          toggleSidebar={toggleSidebar}
        />
      )}

      <div className="flex">
        {data && <Sidebar data={userDetails} />}

        <div
          className={`w-full absolute ${
            isSidebarOpen ? "lg:w-4/6 xl:w-9/12" : "w-full"
          } sm:w-11/12 right-0 top-16 mx-auto   p-4 md:p-6 lg:p-8 xl:p-10`}
        >
          <div className="flex items-center justify-between mb-4">
            <div className="w-2/3">
              <h3 className="md:text-3xl font-semibold mb-4">
                Admin :
                {userDetails &&
                  userDetails.length > 0 &&
                  userDetails[0].schoolId}
              </h3>
              <h3 className="md:text-3xl font-semibold mb-4">
                Add Scroll Notice
              </h3>
            </div>
          </div>

          <div className="bg-gray-50 p-6 shadow-md rounded-lg max-w-3xl min-w-3xl mx-auto border">
            <form onSubmit={handleSubmit} className="space-y-6">
              {/* N Remarks Field */}
              <div>
                <label
                  className="block text-gray-700 text-sm font-semibold mb-1"
                  htmlFor="NRemarks"
                >
                  N Remarks
                </label>
                <input
                  type="text"
                  id="NRemarks"
                  name="NRemarks"
                  value={notice.NRemarks}
                  onChange={handleChange}
                  className="shadow-sm border border-gray-300 rounded w-full py-2 px-3 text-gray-700 focus:ring-blue-500 focus:border-blue-500 focus:outline-none"
                />
              </div>

              {/* N Remarks2 Field */}
              <div>
                <label
                  className="block text-gray-700 text-sm font-semibold mb-1"
                  htmlFor="NRemarks2"
                >
                  N Remarks 2
                </label>
                <textarea
                  type="text"
                  id="NRemarks2"
                  name="NRemarks2"
                  value={notice.NRemarks2}
                  onChange={handleChange}
                  rows="4"
                  className="shadow-sm border border-gray-300 rounded w-full py-2 px-3 text-gray-700 focus:ring-blue-500 focus:border-blue-500 focus:outline-none"
                />
              </div>

              {/* Is Active Field */}
              <div className="flex items-center">
                <input
                  type="checkbox"
                  id="IsActive"
                  name="IsActive"
                  checked={notice.IsActive}
                  onChange={handleChangeCheckBox}
                  className="form-checkbox h-5 w-5 text-blue-600 border-gray-300 rounded focus:ring-blue-500" // Tailwind styles for checkbox
                />
                <label
                  htmlFor="IsActive"
                  className="ml-2 text-gray-700 font-semibold"
                >
                  Active Type
                </label>
                
              </div>

              {/* Notice Link Field */}
              <div>
                <label
                  className="block text-gray-700 text-sm font-semibold mb-1"
                  htmlFor="NNoticeLink"
                >
                  Notice Link
                </label>
                <input
                  type="text"
                  id="NNoticeLink"
                  name="NNoticeLink"
                  value={notice.NNoticeLink}
                  onChange={handleChange}
                  className="shadow-sm border border-gray-300 rounded w-full py-2 px-3 text-gray-700 focus:ring-blue-500 focus:border-blue-500 focus:outline-none"
                />
              </div>

              {/* Submit Button */}
              <div className="flex justify-end">
                <button
                  type="submit"
                  className="bg-blue-600 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
                >
                  Update
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default AdminScrollNoticeSubmit;
