import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import useSidebar from "../../hooks/useSidebar";
import plusicon from "../../assets/png/plusicon.png";
import pencilIcon from "../../assets/jpg/pencileIcon.jpg";
import { getUserDataFromLocalByKey } from "../../API/getStudentInfoByKeyLocal";
import { getAdminPollList } from "../../API/getAdminPollListAPI";

const PollAddAndList = () => {
    const { customId } = useParams();
    const [searchTerm, setSearchTerm] = useState("");
    const [userDetails, setUserDetails] = useState(null);
    const [loading, setLoading] = useState(true);
    const [pollList, setPollList] = useState([]);
    const [initialResourceUrl, setInitialResourceUrl] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            const users = JSON.parse(localStorage.getItem("registeredUsers")) || [];

            if (customId) {
                const decodedData = JSON.parse(atob(customId));
                let matchedItem = [];

                users.forEach((innerArray) => {
                    const [userData] = innerArray;

                    if (
                        userData.userId === decodedData.userId &&
                        userData.userType === decodedData.userType &&
                        userData.schoolId === decodedData.schoolId &&
                        userData.year === decodedData.year
                    ) {
                        matchedItem.push(userData);
                    }
                });

                const result = await getAdminPollList(
                    decodedData.userId,
                    decodedData.schoolId,
                    decodedData.userType,
                    decodedData.year
                );

                const keyval = "webResourceURL";
                const dataUrl = await getUserDataFromLocalByKey(
                    decodedData.userId,
                    decodedData.schoolId,
                    decodedData.userType,
                    decodedData.year,
                    keyval
                );
                setInitialResourceUrl(dataUrl);

                console.log("URL RESULT", dataUrl);

                setPollList(result);

                setLoading(false);
                setUserDetails(matchedItem);
            }
        };

        fetchData();
    }, [customId]);

    console.log(pollList);

    const handlePencilIconClick = (item) => {
        const urlBase = `/admin/u/${customId}/`;
        const queryParams = [];
    
        // Check and add parameters based on conditions (encoded)
        queryParams.push(item.Rcdid ? encodeURIComponent(item.Rcdid) : "null");
        queryParams.push(item.Head1 ? encodeURIComponent(item.Head1) : "null");
        queryParams.push(item.Head2 ? encodeURIComponent(item.Head2) : "null");
        queryParams.push(item.Head3 ? encodeURIComponent(item.Head3) : "null");
        queryParams.push(item.Col5 ? encodeURIComponent(item.Col5) : "null");
        queryParams.push(item.Col6 ? encodeURIComponent(item.Col6) : "null");
        queryParams.push(item.Col7 ? encodeURIComponent(item.Col7) : "null");
        queryParams.push(item.Col8 ? encodeURIComponent(item.Col8) : "null");
        queryParams.push(item.Col9 ? encodeURIComponent(item.Col9) : "null");
        queryParams.push(item.Col10 ? encodeURIComponent(item.Col10) : "null");
        queryParams.push(item.Col11 ? encodeURIComponent(item.Col11) : "null");
        queryParams.push(item.Col12 ? encodeURIComponent(item.Col12) : "null");
    
        // Add the static part (pollUpdateForm)
        queryParams.push("pollUpdateForm");
    
        // Construct the final URL
        const url = urlBase + queryParams.join("/");
    
        // Debugging
        console.log(url, 777);
    
        // Navigate to the constructed URL
        navigate(url);
    };
    

    const dynamicLeftSideConfig = {
        1: [{ value: (item) => item.Col6 }],
        2: [{ value: (item) => item.Col6 }, { value: (item) => item.Col7 }],
        3: [{ value: (item) => item.Col6 }, { value: (item) => item.Col7 }, { value: (item) => item.Col8 }],
        4: [
            { value: (item) => item.Col6 },
            { value: (item) => item.Col7 },
            { value: (item) => item.Col8 },
            { value: (item) => item.Col9 },
        ],
    };

    // Define a configuration object for dynamic rendering of right side
    const dynamicRightSideConfig = {
        1: [{ value: (item) => item.Col14 }],
        2: [{ value: (item) => item.Col14 }, { value: (item) => item.Col15 }],
        3: [{ value: (item) => item.Col14 }, { value: (item) => item.Col15 }, { value: (item) => item.Col16 }],
        4: [
            { value: (item) => item.Col14 },
            { value: (item) => item.Col15 },
            { value: (item) => item.Col16 },
            { value: (item) => item.Col17 },
        ],
    };

    const { sidebarVisible, toggleSidebar } = useSidebar();

    return (
        <>
           <div className="md:mt-6 mt-10">
                <div className="p-2 mb-3 flex items-center w-fit flex-wrap">
                    <ul className="flex items-center">
                        <li className="inline-flex items-center cursor-default">
                            <a href="/" className="text-black font-medium hover:scale-105">
                                <svg
                                    className="w-5 cursor-default h-auto fill-current mx-2"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    fill="#000000"
                                >
                                    <path d="M0 0h24v24H0V0z" fill="none" />
                                    <path d="M10 19v-5h4v5c0 .55.45 1 1 1h3c.55 0 1-.45 1-1v-7h1.7c.46 0 .68-.57.33-.87L12.67 3.6c-.38-.34-.96-.34-1.34 0l-8.36 7.53c-.34.3-.13.87.33.87H5v7c0 .55.45 1 1 1h3c.55 0 1-.45 1-1z" />
                                </svg>
                            </a>

                            <span className="mx-4 h-auto font-medium">/</span>
                        </li>
                        <li className="inline-flex items-center">
                            <p className="text-black font-medium cursor-default hover:scale-105">Poll & List</p>
                            {/* <span className="mx-4 h-auto text-gray-400 font-medium">/</span> */}
                        </li>
                    </ul>
                </div>
                <div className="container mx-auto p-4 containerBoxShadow">
                    <div className="flex flex-col  mb-4">
                        <h3 className="font-bold text-xl mb-2 md:mb-0">
                            Admin :{userDetails && userDetails.length > 0 && userDetails[0].schoolId}
                        </h3>
                    </div>
                    <h3 className="text-3xl font-semibold mb-4">Poll</h3>
                    <Link to={`/admin/u/${customId}/AdminPollSubmit`} onClick={""}>
                        <div
                            className="border border-gray-300 cursor-pointer rounded-lg p-4 hover:shadow-md transition duration-300"
                            style={{
                                boxShadow: "5px 5px 12px 0px rgba(173, 216, 230, 0.9)",
                            }}
                        >
                            <div className="flex items-center">
                                <div className="w-1/3 sm:w-auto mr-4">
                                    <img
                                        src={plusicon}
                                        alt="icon"
                                        className="w-20 h-20 sm:w-15 sm:h-15 object-cover rounded"
                                    />
                                </div>

                                <div className="w-2/3">
                                    <h3 className="text-md font-semibold mb-2">Click to Add New Poll</h3>
                                </div>
                            </div>
                        </div>
                    </Link>

                    <div className="bg-white p-6 shadow-md mt-2 rounded-md overflow-y-auto md:max-h-96">
                        {pollList.slice(1).map((item, index) => (
                            <div key={index} className="mb-4 p-4 border border-gray-300 rounded-md relative">
                                <h3 className="text-lg font-semibold mb-2">{item.Head2}</h3>
                                <h3 className="text-lg font-semibold mb-2">{item.Head3}</h3>
                                <div className="flex">
                                    <div className="w-1/2 pr-4">
                                        {dynamicLeftSideConfig[item.Col5] &&
                                            dynamicLeftSideConfig[item.Col5].map((config, configIndex) => (
                                                <div key={configIndex} className="mt-2">
                                                    <p className="font-semibold">{config.value(item)}</p>
                                                </div>
                                            ))}
                                    </div>

                                    <div className="w-1/2">
                                        {dynamicRightSideConfig[item.Col5] &&
                                            dynamicRightSideConfig[item.Col5].map((config, configIndex) => (
                                                <div key={configIndex} className="mt-2">
                                                    <p className="font-semibold">{config.value(item)}</p>
                                                </div>
                                            ))}
                                    </div>
                                </div>
                                <img
                                    src={pencilIcon}
                                    alt="pencil icon"
                                    className="absolute top-0 right-0 mt-2 mr-2 w-6 h-6 cursor-pointer"
                                    style={{ filter: "brightness(120%)", zIndex: 10 }}
                                    onClick={() => handlePencilIconClick(item)}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
};

export default PollAddAndList;
