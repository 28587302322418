import React from "react";
import axios from "axios";
import toast from "react-toastify";
import { getUserInfoKey } from "../API/GetUserInfoByKey";
import { getUserDataFromLocalByKey } from "./getStudentInfoByKeyLocal";

export const getStudentHomeWorkListByHWID = async (
  userId,
  schoolId,
  userType,
  year,
  HWID
) => {
  try {
    const key = "webServiceURLAWSDOTNET";

    const returnUrl = await getUserDataFromLocalByKey(
      userId,
      schoolId,
      userType,
      year,
      key
    );
    const formData = new FormData();
    formData.append("title", "GetStudentHomeWorkListByHWID");
    formData.append("description", "Get Student Homework List");
    formData.append("ReqHWID",HWID);
    formData.append("description", "registerChecking");
    formData.append("ReqStdyrid", "0");

    let userdata = [];

    // const url="https://ccapk.cypherinfosolution.com/WebDataProcessingServer.aspx"

    const Corskey = process.env.REACT_APP_CORS_URL;
    const result = await axios.post(returnUrl, formData);

    userdata = JSON.parse(
      result.data.substring(0, result.data.indexOf("||JasonEnd", 0))
    );

    return userdata;
  } catch (error) {
    console.error("An error occurred:", error);
    // Handle error here
  }
  // Placeholder JSX, replace with actual JSX
};
