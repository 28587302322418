import axios from "axios";

export const GetEmployeeDataMasterLists = async (year) => {
  try {
    const returnUrl = process.env.REACT_APP_ERP_PAYROLL_PAGE;
    const formData = new FormData();

    formData.append("title", "GetEmployeeDataMasterLists");
    formData.append("description", "Checking");
    formData.append("ReqAcaStart", year);
    formData.append("ReqType", "1");

    let userdata = [];
    const result = await axios.post(returnUrl, formData);
    userdata = JSON.parse(result.data.substring(0, result.data.indexOf("||JasonEnd", 0)));
    return userdata;
  } catch (error) {
    console.error("An error occurred:", error);
  }
};
