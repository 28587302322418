import "datatables.net-buttons-dt/css/buttons.dataTables.css";
import "datatables.net-buttons-dt/css/buttons.dataTables.min.css";
import "datatables.net-buttons-dt/js/buttons.dataTables.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import "datatables.net-buttons/js/dataTables.buttons.js";
import DataTable from "datatables.net-dt";
import "datatables.net-dt/css/dataTables.dataTables.css";
import "datatables.net-responsive-dt";
import "datatables.net-scroller";
import "datatables.net-scroller-dt/css/scroller.dataTables.css";
import "datatables.net-scroller-dt/css/scroller.dataTables.min.css";
import "datatables.net-scroller-dt/js/scroller.dataTables.js";
import "datatables.net-scroller/js/dataTables.scroller.js";
import "datatables.net-select/js/dataTables.select.js";

import JSZip from "jszip";
import PDFMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

import React, { useEffect, useRef, useState } from "react";
import { MdOutlineKeyboardArrowDown, MdOutlineKeyboardArrowUp } from "react-icons/md";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { GetEmployeeDataMasterLists } from "../../API/GetEmployeeDataMasterLists";
import { getQuickInfoStaffStrengthAPI } from "../../API/getQuickInfoStaffStrengthAPI";
import Spinner from "../../components/Spinner";

window.JSZip = JSZip;
PDFMake.vfs = pdfFonts.pdfMake?.vfs;

const columns = [
  { index: 0, title: "Select", data: "select", visible: true },
  { index: 1, title: "Group", data: "Location", visible: true },
  { index: 2, title: "Total", data: "Total", visible: true },
  { index: 3, title: "Absent", data: "absent", visible: true },
  { index: 4, title: "Present", data: "present", visible: true },
  { index: 4, title: "", data: "pl", visible: true },
  { index: 4, title: "", data: "al", visible: true },
];

const QuickInfoStaffStrength = () => {
  const location = useLocation();
  const groupType = location?.state?.group ?? "";

  const [loading, setLoading] = useState(false);
  const [quickInfoList, setQuickInfoList] = useState([]);
  const [isClosed, setIsClosed] = useState(false);
  const [desgn, setDesgn] = useState([]);
  const [seldesgn, setselDesgn] = useState("");
  const [dept, setDept] = useState([]);
  const [seldept, setselDept] = useState("");
  const [type, setType] = useState([]);
  const [seltype, setselType] = useState("");
  const [accCat, setAccCat] = useState([]);
  const [selaccCat, setselAccCat] = useState("");
  const [selGrp, setSelGrp] = useState("");
  const [disp, setDisp] = useState("0");
  const [userDetails, setUserDetails] = useState(null);

  const [tableCol, setTableCol] = useState(columns);
  let tableRef = useRef(null);
  let tableInstance = useRef(null);

  const { customId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    // setLoading(true)
    const fetchData = async () => {
      setLoading(true);
      const users = JSON.parse(localStorage.getItem("registeredUsers")) || [];

      if (customId) {
        const decodedData = JSON.parse(atob(customId));
        let matchedItem = [];

        users.forEach((innerArray) => {
          const [userData] = innerArray;

          if (
            userData.userId === decodedData.userId &&
            userData.userType === decodedData.userType &&
            userData.schoolId === decodedData.schoolId &&
            userData.year === decodedData.year
          ) {
            matchedItem.push(userData);
          }
        });
        // handleClassAndSectionFetch(
        //   decodedData.userId,
        //   decodedData.schoolId,
        //   decodedData.userType,
        //   decodedData.year
        // );
        setUserDetails(matchedItem);

        setLoading(false);
      }
    };

    fetchData();
  }, [customId]);

  useEffect(() => {
    setLoading(true);
    const fetchData = async (val) => {
      const result = await getQuickInfoStaffStrengthAPI(seldept, seldesgn, selaccCat, seltype, disp, val);
      const response = await GetEmployeeDataMasterLists();
      const JSONData1 = JSON.parse(response[0]?.JSONData1);
      const desgns = JSONData1.filter((item) => item.MasterType === "EmpDesig");
      const dpt = JSONData1.filter((item) => item.MasterType === "EmpDept");
      const types = JSONData1.filter((item) => item.MasterType === "EmpType");
      const accC = JSONData1.filter((item) => item.MasterType === "AccCat");
      setDesgn(desgns);
      setDept(dpt);
      setType(types);
      setAccCat(accC);

      setQuickInfoList(result);
      setLoading(false);
    };
    if (groupType) {
      let val =
        groupType === "Teacher" ? "All Teachers" : groupType === "Helpers" ? "Helpers" : groupType === "Non Teaching" ? "Non Teaching" : "";
      setSelGrp(val);
      fetchData(val);
    } else {
      fetchData("");
    }
  }, [groupType]);

  const searchButton = async () => {
    const response = await getQuickInfoStaffStrengthAPI(seldept, seldesgn, selaccCat, seltype, disp, selGrp);
    setQuickInfoList(response);
  };

  const TableData = quickInfoList.map((data) => ({
    Location: data.Location,
    Logourl: data.Logourl,
    present: data.NofStd,
    Total: data.NofStdA,
    absent: data.NofStdS,
    SlCODE: data.SLCODE,
    SlNo: data.Slno,
    dbName: data.dbname,
  }));

  const handleCloseClick = () => {
    setTimeout(() => setIsClosed(!isClosed), 250);
  };

  window.handleShowDetails = (logourl) => {
    // a function on datatable
    navigate(`/admin/u/${customId}/QuickInfo/staff_list`, {
      state: {
        className: logourl,
        groupType: selGrp,
      },
    });
  };

  const TableData2 = quickInfoList.map((data) => ({
    Location: data.Location,
    Logourl: data.Logourl,
    present: data.NofStd,
    Total: data.NofStdA,
    absent: data.NofStdS,
    SlCODE: data.SLCODE,
    SlNo: data.Slno,
    dbName: data.dbname,
    al: `<p
            onClick="handleShowDetails('${data.Location}')"
            class='text-blue-500 cursor-pointer text-sm font-medium capitalize'
          >
            Absent List
          </p>`,
    pl: `<p
            onClick="handleShowDetails('${data.Location}')"
            class='text-blue-500 cursor-pointer text-sm font-medium capitalize'
          >
            Present List
          </p>`,
    select: `<input type="radio" className="radioButton" name="group1" />`,
  }));

  useEffect(() => {
    // Initialize DataTable when the component mounts
    if (tableCol && TableData2) {
      tableInstance.current = new DataTable("#studentDetails", {
        responsive: false,
        // dom: "Bfrtip",
        layout: {
          topStart: {
            buttons: [
              "excel",
              "csv",
              "xls",
              "pdf",
              {
                extend: "print",
                exportOptions: {
                  columns: ":visible",
                },
              },
              "colvis",
            ],
          },
        },
        select: true,
        info: "",
        scrollY: false,
        scrollX: true,
        deferRender: true,
        scroller: true,
        data: TableData2,
        columns: tableCol,
      });
    }
    // Cleanup DataTable instance when the component unmounts
    return () => {
      if (tableInstance.current !== null) {
        tableInstance.current.destroy();
      }
    };
  }, [TableData2, tableCol]);

  return (
    <div className="lg:mt-0 mt-5">
      <div className="container mx-auto p-2 md:p-4 containerBoxShadow">
        <>
          {loading ? (
            <Spinner />
          ) : (
            <>
              <div className="flex justify-start w-full rounded-md bg-gradient-to-r from-cyan-400 to-blue-400 hover:bg-gradient-to-bl">
                <h2 className="w-full text-center text-2xl p-0 my-auto uppercase text-black font-mono font-semibold">Staff Strength</h2>
                <button onClick={handleCloseClick} className={`w-fit rounded-md p-2 `}>
                  <MdOutlineKeyboardArrowUp
                    className={`duration-500 ease-ease-in-out text-black transition-all ${isClosed ? "rotate-180" : ""} `}
                    size={25}
                  />
                </button>
              </div>
              <div className={`transition-all duration-700 ease-ease-in ${!isClosed ? "opacity-100 max-h-screen" : "opacity-0 max-h-0"}`}>
                <div className="flex flex-col gap-3 my-2 rounded">
                  <div className="flex py-2 formBackground justify-between md:flex-row flex-col md:px-10 px-3 sm:px-5 gap-0 md:gap-10 items-center">
                    <div className="w-full md:w-1/2 flex justify-between flex-col">
                      <div className=" flex flex-row items-center gap-2 mb-1 justify-between md:justify-start">
                        <label
                          className="block uppercase tracking-wide text-start text-grey-darker md:min-w-36 text-xs font-bold mb-2"
                          for="grid-state"
                        >
                          Type
                        </label>
                        <div className="relative flex flex-row ">
                          <select
                            value={seltype}
                            onChange={(e) => setselType(e.target.value)}
                            className="textInput min-w-20 w-[150px] md:w-[190px]"
                          >
                            <option value="">--select--</option>
                            {type?.map((data) => (
                              <option value={data.Head1}>{data.Head1}</option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className=" flex flex-row items-center gap-2 mb-1 justify-between md:justify-start">
                        <label
                          className="block uppercase tracking-wide text-start text-grey-darker md:min-w-36 text-xs font-bold mb-2"
                          for="grid-state"
                        >
                          Account Category
                        </label>
                        <div className="relative flex flex-row ">
                          <select
                            value={selaccCat}
                            onChange={(e) => setselAccCat(e.target.value)}
                            className="textInput min-w-20 w-[150px] md:w-[190px]"
                          >
                            <option value="">--select--</option>
                            {accCat?.map((data) => (
                              <option value={data.Head1}>{data.Head1}</option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className=" flex flex-row items-center gap-2 mb-1 justify-between md:justify-start">
                        <label
                          className="block uppercase tracking-wide text-start text-grey-darker md:min-w-36 text-xs font-bold mb-2"
                          for="grid-state"
                        >
                          DEPARTMENT
                        </label>
                        <div className="relative flex flex-row ">
                          <select
                            value={seldept}
                            onChange={(e) => setselDept(e.target.value)}
                            className="textInput min-w-20 w-[150px] md:w-[190px]"
                          >
                            {" "}
                            <option value="">--select--</option>
                            {dept?.map((data) => (
                              <option value={data.Head1}>{data.Head1}</option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className=" flex flex-row items-center gap-2 mb-1 justify-between md:justify-start">
                        <label
                          className="block uppercase tracking-wide text-start text-grey-darker md:min-w-36 text-xs font-bold mb-2"
                          for="grid-state"
                        >
                          DESIGNATION
                        </label>
                        <div className="relative flex flex-row ">
                          <select
                            value={seldesgn}
                            onChange={(e) => setselDesgn(e.target.value)}
                            className="textInput min-w-20 w-[150px] md:w-[190px]"
                          >
                            {" "}
                            <option value="">--select--</option>
                            {desgn?.map((data) => (
                              <option value={data.Head1}>{data.Head1}</option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className=" flex flex-row items-center gap-2 mb-1 justify-between md:justify-start">
                        <label
                          className="block uppercase tracking-wide text-start text-grey-darker md:min-w-36 text-xs font-bold mb-2"
                          for="grid-state"
                        >
                          GROUP
                        </label>
                        <div className="relative flex flex-row ">
                          <select
                            value={selGrp}
                            onChange={(e) => setSelGrp(e.target.value)}
                            className="textInput min-w-20 w-[150px] md:w-[190px]"
                          >
                            <option value="">-- Select --</option>
                            <option value="Class Teacher">Class Teacher</option>
                            <option value="All Teachers">All Teachers</option>
                            <option value="Helpers">Helpers</option>
                            <option value="Non Teaching">Non Teaching</option>
                            <option value="Teacher">Teacher</option>
                          </select>
                        </div>
                      </div>
                    </div>

                    <div className="w-full md:w-1/2 flex justify-between flex-col">
                      <div className=" flex flex-row items-center gap-2 mb-1 justify-between md:justify-start">
                        <label
                          className="block uppercase tracking-wide text-start text-grey-darker md:min-w-36 text-xs font-bold mb-2"
                          for="grid-state"
                        >
                          Display Type
                        </label>
                        <div className="relative flex flex-row ">
                          <select
                            value={disp}
                            onChange={(e) => {
                              setDisp(e.target.value);
                            }}
                            className="textInput min-w-20 w-[150px] md:w-[190px]"
                          >
                            <option value="0">Group wise</option>
                            <option value="1">Type wise</option>
                            <option value="2">Account Category wise</option>
                            <option value="3">Department wise</option>
                            <option value="4">Designation wise</option>
                          </select>
                        </div>
                      </div>
                      <div className=" flex flex-row items-center gap-2 mb-1 justify-between md:justify-start">
                        <label
                          className="block uppercase tracking-wide text-start text-grey-darker md:min-w-36 text-xs font-bold mb-2"
                          for="grid-state"
                        >
                          Report
                        </label>
                        <div className="relative flex flex-row ">
                          <select className="textInput min-w-20 w-[150px] md:w-[190px]">
                            <option value="0">Employee Type/Date wise</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="flex  justify-between md:flex-col flex-row md:w-fit w-full gap-1 items-start ">
                      <button type="button" onClick={searchButton} className="buttonClass md:w-full w-fit">
                        Refresh
                      </button>
                      <button className="buttonClass md:w-full w-fit">Print</button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="formBackground shapedividers my-3 py-3 px-2 uppercase grid sm:grid-cols-3 grid-cols-2 justify-evenly tracking-wide text-center text-grey-darker text-xs font-bold mb-2 gap-y-3">
                <div className="flex justify-center items-center gap-4">
                  <h2>Present:</h2>
                  <h2>{quickInfoList?.length}</h2>
                </div>
                <div className="flex justify-center items-center gap-4">
                  <h2>Absent:</h2>
                  <h2>0</h2>
                </div>
                <div className="flex justify-center items-center gap-4">
                  <h2>Total:</h2>
                  <h2>{quickInfoList?.length}</h2>
                </div>
              </div>

              <div className="p-1 md:p-2 w-full shadow-lg">
                <div className="md:hidden block">
                  <div className="block md:hidden max-w-xl mx-auto bg-white shadow-md p-2 rounded-lg">
                    <h2 className="text-lg cardHeadingClass mb-4 w-full text-center">Staff Strength List</h2>
                    <div className="overflow-x-auto">
                      {TableData.map((item, index) => (
                        <div
                          className="border-2 bg-gradient-to-b from-sky-200 to-cyan-400 rounded-lg mb-4 shadow-lg overflow-hidden"
                          key={index}
                        >
                          <div className="flex items-center  justify-between tabButton py-2 px-4 rounded-t-lg shadow-md">
                            <input className="radioButton" type="radio" name="employeesRadio" id={`employeesRadio-${index}`} />
                            <span className="font-bold text-center w-full">{item?.Location}</span>
                          </div>
                          <table className="w-full table-auto">
                            <tbody>
                              <tr>
                                <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                                  <strong>Total</strong>
                                </td>
                                <td className="border border-solid border-gray-400 text-start p-2 w-1/2">{item?.Total}</td>
                              </tr>
                              <tr>
                                <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                                  <strong>Absent</strong>
                                </td>
                                <td className="border border-solid border-gray-400 text-start p-2 w-1/2">{item?.absent}</td>
                              </tr>
                              <tr>
                                <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                                  <strong>Present</strong>
                                </td>
                                <td className="border border-solid border-gray-400 text-start p-2 w-1/2">{item?.present}</td>
                              </tr>
                              <tr>
                                <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                                  <strong>Present List</strong>
                                </td>
                                <td className="border border-solid border-gray-400 text-start capitalize p-2 w-1/2">
                                  <p
                                    onClick={() =>
                                      navigate(`/admin/u/${customId}/QuickInfo/staff_list`, {
                                        state: {
                                          className: item?.Location,
                                          groupType: selGrp,
                                        },
                                      })
                                    }
                                    className="text-white tracking-wider font-medium cursor-pointer text-sm capitalize"
                                  >
                                    Click here
                                  </p>
                                </td>
                              </tr>
                              <tr>
                                <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                                  <strong>Absent List</strong>
                                </td>
                                <td className="border border-solid border-gray-400 text-start capitalize p-2 w-1/2">
                                  <p className="text-white tracking-wider font-medium cursor-pointer text-sm capitalize">Click here</p>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>

                <div className="relative max-h-full overflow-x-auto border md:block hidden border-black border-solid rounded-sm">
                  {quickInfoList && quickInfoList?.length > 0 ? (
                    <table
                      ref={tableRef}
                      id="studentDetails"
                      style={{ width: "100%" }}
                      className="h-fit w-fit max-h-[500px] responsive extraCSS display nowrap"
                    ></table>
                  ) : (
                    <div className="h-auto p-5">
                      <h1 className="text-2xl font-bold">Record Not Found</h1>
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
        </>
      </div>
    </div>
  );
};

export default QuickInfoStaffStrength;
