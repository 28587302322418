import React from "react";
import axios from "axios";
import toast from "react-toastify";
import { getUserDataFromLocalByKey } from "./getStudentInfoByKeyLocal";


export const getStudentTimeTableListDetail  = async (userId,schoolId, userType, year) => {
 

  try {

    const key='webServiceURLAWSDOTNET'
    const classKey='CLNAME'
    const sectionKey='SECNAME'



    const returnUrl= await getUserDataFromLocalByKey(userId,schoolId,userType, year,key)
    const className=await getUserDataFromLocalByKey(userId, schoolId, userType, year, classKey)
    const sectionName= await getUserDataFromLocalByKey(userId, schoolId, userType, year, sectionKey)



    

    // console.log(className);
    // console.log(sectionName);

  

    const formData = new FormData();
    formData.append("title", "ClassTimeTable");
    formData.append("Rdescription", "");
    formData.append("ReqClass", className);
    formData.append("ReqSection", sectionName);
    


    let userdata = [];

    const Corskey=process.env.REACT_APP_CORS_URL;

    // const result = await axios.post(
    //   "https://cors-anywhere.herokuapp.com/http://apkarnold.newgentechnology.net/WebDataProcessingServer.aspx",
    //   formData
    // );

    const result = await axios.post(
      `${Corskey}${returnUrl}`,
      formData
    );

    userdata = JSON.parse(
      result.data.substring(0, result.data.indexOf("||JasonEnd", 0))
    );

    return userdata;

  
  } catch (error) {
    console.error("An error occurred:", error);
    // Handle error here
  }
  // Placeholder JSX, replace with actual JSX
};
