import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import Spinner from "../../components/Spinner";
import Navbar from "../../components/Navbar";
import Sidebar from "../../components/Sidebar";
// import useSidebar from "../../hooks/useSidebar";
import { useSidebar } from "../../context/SidebarContext";
import personicon from "../../assets/png/person-icon.png";
import { IoMdClose } from "react-icons/io";
import { getUserDataFromLocalByKey } from "../../API/getStudentInfoByKeyLocal";
import { getAdminDateWiseSearchList } from "../../API/getAdminFeeDateWiseSearchListAPI";

function FeeDateSearchList() {
  const { customId, FromDate, ToDate, Rcptcat, Clscat, Class, Section, Admno } =
    useParams();
  const [userType, setUserType] = useState("");
  const [userDetails, setUserDetails] = useState(null);
  const [data, setData] = useState(true);
  const [loading, setLoading] = useState(true);
  const [initialResourceUrl, setInitialResourceUrl] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchList, setSearchList] = useState([]);
  const [filteredList, setfilteredList] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedClass, setSelectedClass] = useState("");
  const [selectedClassList, setSelectedClassList] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const users = JSON.parse(localStorage.getItem("registeredUsers")) || [];

      if (customId) {
        const decodedData = JSON.parse(atob(customId));
        let matchedItem = [];

        users.forEach((innerArray) => {
          const [userData] = innerArray;

          if (
            userData.userId === decodedData.userId &&
            userData.userType === decodedData.userType &&
            userData.schoolId === decodedData.schoolId &&
            userData.year === decodedData.year
          ) {
            matchedItem.push(userData);
          }
        });

        const result = await getAdminDateWiseSearchList(
          decodedData.userId,
          decodedData.schoolId,
          decodedData.userType,
          decodedData.year,
          FromDate == "null" ? "" : FromDate,
          ToDate == "null" ? "" : ToDate,
          Rcptcat == "null" ? "" : Rcptcat,
          Clscat == "null" ? "" : Clscat,
          Class == "null" ? "" : Class,
          Section == "null" ? "" : Section,
          Admno == "null" ? "" : Admno
        );

        const keyval = "webResourceURL";
        const dataUrl = await getUserDataFromLocalByKey(
          decodedData.userId,
          decodedData.schoolId,
          decodedData.userType,
          decodedData.year,
          keyval
        );
        setInitialResourceUrl(dataUrl);
        setLoading(false);
        setSearchList(result);
        setUserDetails(matchedItem);
      }
    };

    fetchData();
  }, [customId, selectedClass]);

  console.log(searchList);

  const handleSearch = (value) => {
    setSearchTerm(value);
    if (value.trim() === "") {
      setfilteredList([]);
    } else {
      const filtered = searchList.filter((item) =>
        item.Head3.toLowerCase().includes(value.toLowerCase())
      );
      setfilteredList(filtered);
    }
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const handleRowClick = (item) => {
    setSelectedClass(item.Head3);
    setModalOpen(true);
  };

  const itemsToRender = searchTerm ? filteredList : searchList;

  const { isSidebarOpen, currentSidebarTab, toggleSidebar,setIsSidebarOpen } = useSidebar();

  return (
    <>
      {data && (
        <Navbar
          data={userDetails}
          // isOpen={sidebarVisible}
          toggleSidebar={toggleSidebar}
        />
      )}

      <div className="flex">
        {data && <Sidebar data={userDetails} />}

        <div className="">
          {loading ? (
            <Spinner />
          ) : (
            <>
             <div className={`w-full absolute ${isSidebarOpen?"lg:w-4/6 xl:w-9/12":"w-full"} sm:w-11/12 right-0 top-16 mx-auto   p-4 md:p-6 lg:p-8 xl:p-10`}>
                <div className="flex items-center justify-between mb-4">
                  <div className="w-2/3">
                    <h2 className=" md:text-2xl font-bold text-xl mb-2 md:mb-0">
                      Admin :
                      {userDetails &&
                        userDetails.length > 0 &&
                        userDetails[0].schoolId}
                    </h2>
                    <h4 className="mb-2 font-bold">
                      Class Wise Demand & Collection Details
                    </h4>
                  </div>
                  <div className=" w-2/6">
                    <input
                      type="text"
                      placeholder="Search..."
                      value={searchTerm}
                      onChange={(e) => handleSearch(e.target.value)}
                      className="border border-gray-300 px-3 py-2 rounded-md mr-2 focus:border-blue-500 focus:outline-none"
                    />
                  </div>
                </div>

                <div className="table-container overflow-y-auto h-96">
                  <table className="w-full table-auto ">
                    <thead>
                      <tr className="bg-blue-500">
                        <th className="sticky top-0 border bg-blue-500 lg:z-10 p-2  left-0">
                          Sl No
                        </th>
                        <th className="sticky top-0 border bg-blue-500 lg:z-10 p-2">
                          Class
                        </th>
                        <th className="sticky top-0 border bg-blue-500 lg:z-10 p-2 ">
                          Due Amount
                        </th>
                        {/* Add more columns as needed */}
                      </tr>
                    </thead>
                    <tbody>
                      {/* Populate table rows based on classWiseStrengthList */}
                      {itemsToRender.slice(1).map((item, index) => (
                        <tr
                          key={index}
                          className="bg-gray-300 cursor-pointer"
                          onClick={() => handleRowClick(item)}
                        >
                          <td className="p-2 text-lg border font-semibold text-left">
                            {index + 1}
                          </td>
                          <td className="p-2 font-semibold border  text-center">
                            {item.Head3}
                          </td>
                          <td className="p-2 font-semibold border  text-right">
                            {item.Col4}
                          </td>
                          {/* Add more columns as needed */}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </>
          )}
        </div>
      </div>

      {isModalOpen && (
        <div className="fixed  inset-0 z-50 mt-16 h-[75vh] opacity-1 flex items-center justify-center">
          <div className="bg-white overflow-y-scroll relative bottom-3  h-[75vh] w-1/2 mt-10 shadow-md shadow-gray-400 p-8 rounded-lg text-center">
            <button
              className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
              onClick={closeModal}
            >
              <IoMdClose className="bg-red-500 text-white" size={24} />
            </button>

            {searchList
              .filter((item) => item.Head3 === selectedClass)
              .map((item) => (
                <React.Fragment key={item.Clid}>
                  <div className="bg-gradient-to-r from-blue-500 via-indigo-500 to-purple-500 h-[40vh] text-white flex flex-col items-center justify-center">
                    <div className="mt-5 mb-2">
                      <img
                        src={
                          item.Col12 === "noPhoto.jpg" ? personicon : item.Col12
                        }
                        alt={item.Col12}
                        className="mx-auto h-16 w-16 mb-2 rounded-full"
                      />
                      <p className="text-lg font-bold">{item.Head2}</p>
                      <p className="text-md font-semibold">{item.Head1}</p>
                    </div>
                    <div className="bg-white p-4 rounded-lg text-left text-black">
                      <p className="text-md font-bold mb-2">
                        Class: {item.Head3}
                      </p>
                      <p className="text-md font-bold">
                        Phone Number: {item.Col10}
                      </p>
                    </div>
                  </div>

                  <div className="table-container mt-3">
                  <table className="w-full table-auto border-collapse border border-gray-300">
                      <tbody>
                        <tr>
                          <td colSpan="2" className="bg-blue-500 border border-gray-500 p-2 text-center text-white font-bold">
                           Fees Summary
                          </td>
                        </tr>
                        <tr>
                          <th className="border border-gray-500 text-left w-2/4">Total Demand</th>
                          <td className="border border-gray-500 text-right">{item.Col1 || "-"}</td>
                        </tr>
                        <tr>
                          <th className="border border-gray-500 text-left">Total Collection</th>
                          <td className="border border-gray-500 text-right">{item.Col2 || "-"}</td>
                        </tr>
                        <tr>
                          <th className="border border-gray-500 text-left">Total Concession</th>
                          <td className="border border-gray-500 text-right">{item.Col3 || "-"}</td>
                        </tr>
                        <tr>
                          <th className="border border-gray-500 text-left">Total Balance</th>
                          <td className="border border-gray-500 text-right">{item.Col4 || "-"}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </React.Fragment>
              ))}
          </div>
        </div>
      )}
    </>
  );
}

export default FeeDateSearchList;
