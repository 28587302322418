
export const getStringValueRecordFromArray = async (matchedArray, key) => {
 
    try {
          
    
        const result = matchedArray.find(item => item.ItemKeyName === key);
      
        return result ? result.InfoField : null;

    } catch (error) {
      console.error("An error occurred:", error);
      // Handle error here
    }
    // Placeholder JSX, replace with actual JSX
  };
