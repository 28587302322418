import React, { useState, useEffect } from "react";
import Navbar from "../../components/Navbar";
import Sidebar from "../../components/Sidebar";
import { useSidebar } from "../../context/SidebarContext";
import { Link, useParams } from "react-router-dom";
import Spinner from "../../components/Spinner";
import personicon from "../../assets/png/person-icon.png";
import { FaPhone } from "react-icons/fa";
import { getUserDataFromLocalByKey } from "../../API/getStudentInfoByKeyLocal";
import { getAdminStudentDetailsList } from "../../API/getAdminStudentDetailsListAPI";
function StudentDetailsList() {
  const [userType, setUserType] = useState("");
  const [userDetails, setUserDetails] = useState(null);
  const [data, setData] = useState(true);
  const { customId, className, sec } = useParams();
  const [studentList, setStudentList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [initialResourceUrl, setInitialResourceUrl] = useState(null);
  const [resourceUrl, setResourceUrl] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      const users = JSON.parse(localStorage.getItem("registeredUsers")) || [];

      if (customId) {
        const decodedData = JSON.parse(atob(customId));
        let matchedItem = [];

        users.forEach((innerArray) => {
          const [userData] = innerArray;

          if (
            userData.userId === decodedData.userId &&
            userData.userType === decodedData.userType &&
            userData.schoolId === decodedData.schoolId &&
            userData.year === decodedData.year
          ) {
            matchedItem.push(userData);
          }
        });

        const key = "WebResourceAWSDOTNETURL";
        const dataResourceUrl = await getUserDataFromLocalByKey(
          decodedData.userId,
          decodedData.schoolId,
          decodedData.userType,
          decodedData.year,
          key
        );
        setResourceUrl(dataResourceUrl);

        const result = await getAdminStudentDetailsList(
          decodedData.userId,
          decodedData.schoolId,
          decodedData.userType,
          decodedData.year,
          className,
          sec
        );

        const keyval = "webResourceURL";
        const dataUrl = await getUserDataFromLocalByKey(
          decodedData.userId,
          decodedData.schoolId,
          decodedData.userType,
          decodedData.year,
          keyval
        );
        setInitialResourceUrl(dataUrl);
        setStudentList(result);
        setLoading(false);
        setUserDetails(matchedItem);
      }
    };

    fetchData();
  }, [customId]);

  console.log(studentList);

  //

  const { isSidebarOpen, currentSidebarTab, toggleSidebar, setIsSidebarOpen } = useSidebar();

  return (
    <>
      {data && (
        <Navbar
          data={userDetails}
          // isOpen={sidebarVisible}
          toggleSidebar={toggleSidebar}
        />
      )}

      <div className="flex">
        {data && <Sidebar data={userDetails} />}
        <div
          className={`w-full absolute ${
            isSidebarOpen ? "lg:w-4/6 xl:w-9/12" : "w-full"
          } sm:w-11/12 right-0 top-16 mx-auto   p-4 md:p-6 lg:p-8 xl:p-10`}
        >
          <div className="flex items-center justify-between mb-4">
            <div className="w-2/3">
              <h2 className=" md:text-2xl font-bold text-xl mb-2 md:mb-0">
                Admin :{userDetails && userDetails.length > 0 && userDetails[0].schoolId}
              </h2>
              <h4 className="mb-2 font-bold">Student Details</h4>
            </div>
          </div>

          <div
            className="text-center sticky top-0 lg:z-50 shadow-lg text-2xl text-blue-500 border-b-2 border-blue-500 p-4"
            style={{
              boxShadow: "5px 5px 12px 0px rgba(173, 216, 230, 0.9)",
            }}
          >
            Student List of {className} - {sec}
          </div>

          <div className="bg-white p-6 mt-2 shadow-md rounded-md overflow-y-auto md:max-h-96">
            {/* Display the content for the specific tab */}
            {studentList.map((item, index) => {
              let ur = `${process.env.REACT_APP_ERP_STUDENT_IMAGE_URL}${item?.PHOTOFILE}`;
              return (
                <Link
                  to={`/admin/u/${customId}/${item.ADMNO}/StudentDetailsView`}
                  key={index}
                  className=" lg:flex items-center border border-gray-300 mb-2"
                >
                  <img
                    src={item.PHOTOFILE === "noPhoto.jpg" && (item?.PHOTOFILE === undefined || item?.PHOTOFILE === null) ? personicon : ur}
                    alt={`Staff ${index + 1}`}
                    className="w-16 h-16 object-cover rounded-full mr-4"
                  />
                  <div className="ml-2">
                    <h3 className="text-lg font-semibold">{item.SNAME}</h3>
                    <p className=" text-green-500">Admission No:{item.ADMNO}</p>
                    <div className="flex items-center">
                      <FaPhone className="text-blue-500 mr-2" />
                      <p className="text-orange-500">{item.SMSPHONE}</p>
                    </div>
                    <p className="text-green-500 mb-2">Father: {item.FATHERNAME}</p>
                  </div>
                </Link>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}

export default StudentDetailsList;
