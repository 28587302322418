import React, { useState, useRef, useEffect } from "react";
import { IoSettingsOutline } from "react-icons/io5";
import { TbTableOptions } from "react-icons/tb";
import { BiLogOutCircle } from "react-icons/bi";
import { RiDashboardLine } from "react-icons/ri";
import Logo from "../assets/png/pngtree-school-logo.png";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useGlobalContext } from "../context/GlobalContext";
import {
  AdminArray,
  StudentArray,
  userDetailsDataValues,
} from "../data/adminMenu";
import { CgEditBlackPoint } from "react-icons/cg";
import useSidebarHook from "../hooks/useSidebarHook";
import { useSidebar } from "../context/SidebarContext";

const Sidebar = () => {
  const [isLoading, setIsLoading] = useState(true);
  const loadingRef = useRef(null);
  const [menuItems, setMenuItems] = useState([]);
  const [qlItems, setqltems] = useState([]);
  const [resultArray, setResultArray] = useState([]);
  const [userDetails, setUserDetails] = useState(null);
  const [showad, setShowAd] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const { isSidebarOpen, currentSidebarTab, toggleSidebar, setIsSidebarOpen } =
    useSidebar();

  const { customId } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      if (customId) {
        const decodedData = JSON.parse(atob(customId));

        const result = await userDetailsDataValues(decodedData);

        const menuItems = result.filter((item) => item.type === "menu");
        setMenuItems(menuItems);

        const qlItems = result.filter((item) => item.type === "ql");

        setqltems(qlItems);

        setResultArray(result);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const decodedData = JSON.parse(atob(customId));

    setUserDetails(decodedData);
  }, [customId]);

  useEffect(() => {
    // Simulate loading
    setTimeout(() => {
      if (loadingRef.current) {
        loadingRef.current.classList.add("hidden");
      }
      setIsLoading(false);
    }, 1000); // 1 second loading time
  }, []);

  return (
    <div
      className={`flex z-50 fixed h-screen antialiased text-gray-900  dark:bg-dark dark:text-light border-t-0 lg:top-[83px] ${
        isSidebarOpen ? "" : "w-10"
      }`}
    >
      {/* Loading screen */}

      {/* Sidebar */}
      {isSidebarOpen && (
        <div
          className="fixed  inset-0 z-10 bg-black bg-opacity-50 lg:hidden"
          onClick={() => setIsSidebarOpen(false)}
        ></div>
      )}
      <div
        className={`fixed inset-y-0 lg:top-[83px] z-10 w-16 bg-white transition-all ${
          isSidebarOpen ? "block" : "hidden"
        }`}
      ></div>

      {/* Mobile bottom bar */}
      <nav
        aria-label="Options"
        className="fixed lg:top-[83px] inset-x-0 bottom-0 flex flex-row-reverse items-center justify-between px-4 py-2 bg-white border-t border-indigo-100 sm:hidden shadow-t rounded-t-3xl"
      >
        {/* Menu button */}
        <button
          onClick={() => toggleSidebar("optionTab")}
          className={`relative group flex flex-col items-center justify-center w-12 h-12 transition-all duration-300 rounded-lg shadow-md bg-blue-500 text-white hover:bg-blue-600 hover:scale-110 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-offset-2 ${
            isSidebarOpen && currentSidebarTab === "optionTab"
              ? "ring-2 ring-blue-400 ring-offset-2"
              : ""
          }`}
        >
          <svg
            aria-hidden="true"
            className="w-6 h-6"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M4 6h16M4 12h16M4 18h7"
            />
          </svg>
          <span className="mt-1 text-[8px] font-medium">Menu</span>
          <span className="absolute left-full ml-4 w-auto min-w-max p-2 rounded-md shadow-md text-white bg-blue-600 text-xs font-bold transition-all duration-100 scale-0 origin-left group-hover:scale-100">
            Options
          </span>
        </button>

        <button
          onClick={() => {
            navigate(`/dashboard/u/${customId}`);
            toggleSidebar("optionTab");
          }}
          className={`relative group flex flex-col items-center justify-center w-12 h-12 transition-all duration-300 rounded-lg shadow-md bg-green-500 text-white hover:bg-green-600 hover:scale-110 focus:outline-none focus:ring-2 focus:ring-green-400 focus:ring-offset-2 ${
            isSidebarOpen && currentSidebarTab === "optionTab"
              ? "ring-2 ring-green-400 ring-offset-2"
              : ""
          }`}
        >
          <RiDashboardLine className="w-6 h-6" />
          <span className="mt-1 text-[8px] font-medium">Dash</span>
          <span className="absolute left-full ml-4 w-auto min-w-max p-2 rounded-md shadow-md text-white bg-green-600 text-xs font-bold transition-all duration-100 scale-0 origin-left group-hover:scale-100">
            Dashboard
          </span>
        </button>
        {/* 
          <button
            onClick={() => toggleSidebar("optionTab2")}
            className={`relative group flex flex-col items-center justify-center w-12 h-12 transition-all duration-300 rounded-lg shadow-md bg-purple-500 text-white hover:bg-purple-600 hover:scale-110 focus:outline-none focus:ring-2 focus:ring-purple-400 focus:ring-offset-2 ${
              isSidebarOpen && currentSidebarTab === "optionTab2"
                ? "ring-2 ring-purple-400 ring-offset-2"
                : ""
            }`}
          >
            <TbTableOptions className="w-6 h-6" />
            <span className="mt-1 text-[8px] font-medium">Opts</span>
            <span className="absolute left-full ml-4 w-auto min-w-max p-2 rounded-md shadow-md text-white bg-purple-600 text-xs font-bold transition-all duration-100 scale-0 origin-left group-hover:scale-100">
              Options
            </span>
          </button> */}

        <button
          onClick={() => navigate(`/settings`)}
          className="relative group flex flex-col items-center justify-center w-12 h-12 transition-all duration-300 rounded-lg shadow-md bg-yellow-500 text-white hover:bg-yellow-600 hover:scale-110 focus:outline-none focus:ring-2 focus:ring-yellow-400 focus:ring-offset-2"
        >
          <IoSettingsOutline className="w-6 h-6" />
          <span className="mt-1 text-[8px] font-medium">Set</span>
          <span className="absolute left-full ml-4 w-auto min-w-max p-2 rounded-md shadow-md text-white bg-yellow-600 text-xs font-bold transition-all duration-100 scale-0 origin-left group-hover:scale-100">
            Settings
          </span>
        </button>

        <button
          onClick={() => navigate("/")}
          className="relative group flex flex-col items-center justify-center w-12 h-12 transition-all duration-300 rounded-lg shadow-md bg-red-500 text-white hover:bg-red-600 hover:scale-110 focus:outline-none focus:ring-2 focus:ring-red-400 focus:ring-offset-2"
        >
          <BiLogOutCircle className="w-6 h-6" />
          <span className="mt-1 text-[8px] font-medium">Log</span>
          <span className="absolute left-full ml-4 w-auto min-w-max p-2 rounded-md shadow-md text-white bg-red-600 text-xs font-bold transition-all duration-100 scale-0 origin-left group-hover:scale-100">
            Logout
          </span>
        </button>

        {/* Logo */}
        <a href="#">
          <img className="w-10 h-auto" src={Logo} alt="K-UI" />
        </a>

        {/* User avatar button */}
        {/* <div className="relative flex items-center flex-shrink-0 p-2">
          <img
            className="w-8 h-8 rounded-lg shadow-md"
            src="https://avatars.githubusercontent.com/u/57622665?s=460&u=8f581f4c4acd4c18c33a87b3e6476112325e8b38&v=4"
            alt="User"
          />
        </div> */}
      </nav>

      {/* Left mini bar */}
      <nav
        aria-label="Options"
        className="z-20 flex-col items-center flex-shrink-0 hidden w-16 py-4 bg-white border-r-2 border-indigo-100 shadow-md sm:flex rounded-tr-3xl rounded-br-3xl"
      >
        {/* Logo */}
        <div className="flex-shrink-0 py-4">
          <a href="#">
            <img className="w-10 h-auto" src={Logo} alt="logo" />
          </a>
        </div>

        <div className="flex flex-col items-center flex-1 w-full p-2 space-y-3">
          {/* Menu button */}
          <button
            onClick={() => toggleSidebar("optionTab")}
            className={`relative group flex flex-col items-center justify-center w-12 h-12 transition-all duration-300 rounded-lg shadow-md bg-blue-500 text-white hover:bg-blue-600 hover:scale-110 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-offset-2 ${
              isSidebarOpen && currentSidebarTab === "optionTab"
                ? "ring-2 ring-blue-400 ring-offset-2"
                : ""
            }`}
          >
            <svg
              aria-hidden="true"
              className="w-6 h-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16M4 18h7"
              />
            </svg>
            <span className="mt-1 text-[8px] font-medium">Menu</span>
            <span className="absolute left-full ml-4 w-auto min-w-max p-2 rounded-md shadow-md text-white bg-blue-600 text-xs font-bold transition-all duration-100 scale-0 origin-left group-hover:scale-100">
              Options
            </span>
          </button>

          <button
            onClick={() => {
              navigate(`/dashboard/u/${customId}`);
              toggleSidebar("optionTab");
            }}
            className={`relative group flex flex-col items-center justify-center w-12 h-12 transition-all duration-300 rounded-lg shadow-md bg-green-500 text-white hover:bg-green-600 hover:scale-110 focus:outline-none focus:ring-2 focus:ring-green-400 focus:ring-offset-2 ${
              isSidebarOpen && currentSidebarTab === "optionTab"
                ? "ring-2 ring-green-400 ring-offset-2"
                : ""
            }`}
          >
            <RiDashboardLine className="w-6 h-6" />
            <span className="mt-1 text-[8px] font-medium">Dash</span>
            <span className="absolute left-full ml-4 w-auto min-w-max p-2 rounded-md shadow-md text-white bg-green-600 text-xs font-bold transition-all duration-100 scale-0 origin-left group-hover:scale-100">
              Dashboard
            </span>
          </button>
          {/* 
          <button
            onClick={() => toggleSidebar("optionTab2")}
            className={`relative group flex flex-col items-center justify-center w-12 h-12 transition-all duration-300 rounded-lg shadow-md bg-purple-500 text-white hover:bg-purple-600 hover:scale-110 focus:outline-none focus:ring-2 focus:ring-purple-400 focus:ring-offset-2 ${
              isSidebarOpen && currentSidebarTab === "optionTab2"
                ? "ring-2 ring-purple-400 ring-offset-2"
                : ""
            }`}
          >
            <TbTableOptions className="w-6 h-6" />
            <span className="mt-1 text-[8px] font-medium">Opts</span>
            <span className="absolute left-full ml-4 w-auto min-w-max p-2 rounded-md shadow-md text-white bg-purple-600 text-xs font-bold transition-all duration-100 scale-0 origin-left group-hover:scale-100">
              Options
            </span>
          </button> */}

          <button
            onClick={() => navigate(`/settings`)}
            className="relative group flex flex-col items-center justify-center w-12 h-12 transition-all duration-300 rounded-lg shadow-md bg-yellow-500 text-white hover:bg-yellow-600 hover:scale-110 focus:outline-none focus:ring-2 focus:ring-yellow-400 focus:ring-offset-2"
          >
            <IoSettingsOutline className="w-6 h-6" />
            <span className="mt-1 text-[8px] font-medium">Set</span>
            <span className="absolute left-full ml-4 w-auto min-w-max p-2 rounded-md shadow-md text-white bg-yellow-600 text-xs font-bold transition-all duration-100 scale-0 origin-left group-hover:scale-100">
              Settings
            </span>
          </button>

          <button
            onClick={() => navigate("/")}
            className="relative group flex flex-col items-center justify-center w-12 h-12 transition-all duration-300 rounded-lg shadow-md bg-red-500 text-white hover:bg-red-600 hover:scale-110 focus:outline-none focus:ring-2 focus:ring-red-400 focus:ring-offset-2"
          >
            <BiLogOutCircle className="w-6 h-6" />
            <span className="mt-1 text-[8px] font-medium">Log</span>
            <span className="absolute left-full ml-4 w-auto min-w-max p-2 rounded-md shadow-md text-white bg-red-600 text-xs font-bold transition-all duration-100 scale-0 origin-left group-hover:scale-100">
              Logout
            </span>
          </button>
        </div>
      </nav>

      <div
        className={`fixed lg:top-[83px] inset-y-0 left-0 z-10 flex-shrink-0 w-64 bg-white border-r-2 border-indigo-100 shadow-lg sm:left-16 rounded-tr-3xl rounded-br-3xl sm:w-72 lg:static lg:w-64 transition-transform transform ${
          isSidebarOpen ? "translate-x-0" : "-translate-x-full"
        }`}
      >
        {/* Sidebar content based on currentSidebarTab */}
        {currentSidebarTab === "linksTab" && (
          <nav aria-label="Main" className="flex flex-col h-full">
            {/* Logo */}
            <div className="flex items-center justify-center flex-shrink-0 py-10">
              <h3 className="relative font-bold text-xl font-serif bg-clip-text text-transparent bg-gradient-to-r from-emerald-400 to-emerald-600 customFont shadow-lg text-center px-5 py-3 rounded-lg whitespace-nowrap">
                {userDetails && userDetails.userType} Login
                {/* Adding subtle highlights */}
                <div className="absolute inset-0 rounded-lg opacity-30 bg-gradient-to-r from-emerald-300 to-transparent"></div>
              </h3>
            </div>

            {/* Links */}
            <div className="flex-1 px-4 space-y-2 overflow-hidden hover:overflow-auto">
              <a
                href="#"
                className="flex items-center w-full space-x-2 text-gray-600 hover:text-white hover:bg-indigo-600 rounded-lg"
              >
                <span aria-hidden="true" className="p-2 bg-gray-100 rounded-lg">
                  <svg
                    className="w-6 h-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                    />
                  </svg>
                </span>
                <span>omm</span>
              </a>
            </div>
          </nav>
        )}

        {currentSidebarTab === "optionTab" && (
          <nav aria-label="Main" className="flex flex-col h-full">
            <div className="flex items-center justify-center flex-shrink-0 py-4">
              <h3
                className={`relative font-bold text-xl font-serif bg-clip-text text-transparent bg-gradient-to-r ${
                  userDetails?.userType === "Teacher"
                    ? "from-emerald-400 to-emerald-600"
                    : userDetails?.userType === "Admin"
                    ? "from-amber-400 to-amber-600"
                    : "from-sky-400 to-sky-600"
                } customFont shadow-lg text-center px-5 py-3 rounded-lg whitespace-nowrap`}
              >
                {userDetails && userDetails?.userType} Login
                <div
                  className={`absolute inset-0 rounded-lg opacity-30 bg-gradient-to-r ${
                    userDetails?.userType === "Teacher"
                      ? "from-emerald-300"
                      : userDetails?.userType === "Admin"
                      ? "from-amber-300"
                      : "from-sky-300"
                  } to-transparent`}
                ></div>
              </h3>
            </div>
            {userDetails && userDetails.userType === "Admin" && (
              <div className="border border-gray-300  shadow-lg p-4 h-[calc(100vh-150px)] overflow-y-auto custom-scrollbar bg-amber-200">
                {menuItems &&
                  menuItems.map((item, index) => (
                    <Link
                      key={index}
                      to={`/admin/u/${item.keyName}/${customId}`}
                      className={`flex items-center w-full space-x-4 text-gray-700 rounded-lg p-2 transition-all duration-300 transform hover:scale-105 ${
                        location.pathname.includes(item.keyName)
                          ? "text-white bg-amber-400 shadow-lg shadow-indigo-500/50 hover:bg-amber-600"
                          : "hover:text-white hover:bg-amber-500 hover:shadow-lg hover:shadow-indigo-500/50"
                      }`}
                    >
                      <span className="p-2 bg-amber-400 rounded-md flex items-center justify-center">
                        {item.icon ? (
                          <img
                            src={item.icon}
                            alt={item.caption}
                            className="w-6 h-6 object-contain"
                          />
                        ) : (
                          <svg
                            className="w-5 h-5"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M4 6h16M4 12h16M4 18h7"
                            />
                          </svg>
                        )}
                      </span>
                      <span className="font-semibold text-sm font-serif">
                        {item.caption}
                      </span>
                    </Link>
                  ))}
              </div>
            )}

            {userDetails && userDetails.userType === "Student" && (
              <div className="border border-gray-300 shadow-lg p-4 h-[calc(100vh-150px)] overflow-y-auto custom-scrollbar bg-sky-200">
                {menuItems &&
                  menuItems.map((item, index) => (
                    <Link
                      key={index}
                      to={`/student/u/${item.keyName}/${customId}`}
                      className={`flex items-center w-full space-x-4 text-gray-700 rounded-lg p-2 transition-all duration-300 transform hover:scale-105 ${
                        location.pathname.includes(item.keyName)
                          ? "text-white bg-sky-400 shadow-lg shadow-indigo-500/50 hover:bg-sky-600"
                          : "hover:text-white hover:bg-sky-300 hover:shadow-lg hover:shadow-indigo-500/50"
                      }`}
                    >
                      <span className="p-2 bg-sky-400 rounded-md flex items-center justify-center">
                        {item.icon ? (
                          <img
                            src={item.icon}
                            alt={item.caption}
                            className="w-6 h-6 object-contain"
                          />
                        ) : (
                          <svg
                            className="w-5 h-5"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M4 6h16M4 12h16M4 18h7"
                            />
                          </svg>
                        )}
                      </span>
                      <span className="font-semibold text-sm font-serif">
                        {item.caption}
                      </span>
                    </Link>
                  ))}
              </div>
            )}

            {userDetails && userDetails.thType === "CT" && (
              <div className="border border-gray-300 shadow-lg p-4 h-[calc(100vh-150px)] overflow-y-auto custom-scrollbar bg-emerald-100">
                {menuItems &&
                  menuItems.map((item, index) => (
                    <Link
                      key={index}
                      to={`/classteacher/u/${item.keyName}/${customId}`}
                      className={`flex items-center w-full space-x-4 ${
                        location.pathname.includes(item.keyName)
                          ? "bg-emerald-400 text-white"
                          : "text-gray-700 hover:text-white hover:bg-emerald-300"
                      } hover:shadow-lg hover:shadow-indigo-500/50 rounded-lg p-2 transition-all duration-300 transform hover:scale-105`}
                    >
                      <span className="p-2 bg-emerald-400 rounded-md flex items-center justify-center">
                        {item.icon ? (
                          <img
                            src={item.icon}
                            alt={item.caption}
                            className="w-6 h-6 object-contain"
                          />
                        ) : (
                          <svg
                            className="w-5 h-5"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M4 6h16M4 12h16M4 18h7"
                            />
                          </svg>
                        )}
                      </span>
                      <span className="font-semibold text-sm font-serif">
                        {item.caption}
                      </span>
                    </Link>
                  ))}
              </div>
            )}

            {userDetails && userDetails.thType === "ST" && (
             <div className="border border-gray-300 shadow-lg p-4 h-[calc(100vh-150px)] overflow-y-auto custom-scrollbar bg-emerald-100">
             {menuItems &&
               menuItems.map((item, index) => (
                 <Link
                   key={index}
                   to={`/subjectTeacher/u/${item.keyName}/${customId}`}
                   className={`flex items-center w-full space-x-4 ${
                     location.pathname.includes(item.keyName)
                       ? "bg-emerald-400 text-white"
                       : "text-gray-700 hover:text-white hover:bg-emerald-300"
                   } hover:shadow-lg hover:shadow-indigo-500/50 rounded-lg p-2 transition-all duration-300 transform hover:scale-105`}
                 >
                   <span className="p-2 bg-emerald-400 rounded-md flex items-center justify-center">
                     {item.icon ? (
                       <img
                         src={item.icon}
                         alt={item.caption}
                         className="w-6 h-6 object-contain"
                       />
                     ) : (
                       <svg
                         className="w-5 h-5"
                         fill="none"
                         stroke="currentColor"
                         viewBox="0 0 24 24"
                         xmlns="http://www.w3.org/2000/svg"
                       >
                         <path
                           strokeLinecap="round"
                           strokeLinejoin="round"
                           strokeWidth={2}
                           d="M4 6h16M4 12h16M4 18h7"
                         />
                       </svg>
                     )}
                   </span>
                   <span className="font-semibold text-sm font-serif">
                     {item.caption}
                   </span>
                 </Link>
               ))}
           </div>
            )}
          </nav>
        )}

        {/* Add more sidebar tabs if needed */}
      </div>
    </div>
  );
};

export default Sidebar;
