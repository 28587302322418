import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Navbar from "../../components/Navbar";
import Sidebar from "../../components/Sidebar";
import { useSidebar } from "../../context/SidebarContext";
import AdminClassDetailsList from "./AdminClassDetailsList";
import AdminCommSearchPage from "./AdminCommSearchPage";
import AdminGallerySearchPage from "./AdminGallerySearchPage";
import AdminHomeWorkSearchPage from "./AdminHomeWorkSearchPage";
import AdminNoticeSearch from "./AdminNoticeSearch";
import AdminNotificationSearchPage from "./AdminNotificationSearchPage";
import AdminStuSearch from "./AdminStuSearch";
import BlacklistStudentList from "./BlacklistStudentList";
import FeesAnalysesDashboard from "./FeeAnalysesDashboard";
import NoticeBoard from "./NoticeBoard";
import PollAddAndList from "./PollAddAndList";
import QuickInfo from "./QuickInfoNew";
import ScrollNoticeList from "./ScrollNoticeList";
import StaffDetails from "./StaffDetails";
import StrengthAnalyses from "./StrengthAnalyses";
import StudentDetails from "./StudentDetails";

const CustomAdminPage = () => {
  const { customId, keyName } = useParams();
  const [userDetails, setUserDetails] = useState(null);
  const [data, setData] = useState(true);
  const { isSidebarOpen, toggleSidebar } = useSidebar();
  // console.log(customId)
  // console.log(keyName)

  useEffect(() => {
    if (customId) {
      const decodedData = JSON.parse(atob(customId));
      setUserDetails(decodedData);
    }
  }, [customId, keyName]);

  return (
    <>
      {data && <Navbar data={userDetails} toggleSidebar={toggleSidebar} />}
      <div className="flex">
        {data && <Sidebar data={userDetails} />}

        <div className={`w-full mb-8 absolute ${isSidebarOpen ? "lg:w-5/6 xl:w-9/12 right-0" : "md:pl-14"}  top-16 pt-0 lg:pt-8 xl:pt-9`}>
          {keyName === "AdmNoticeBoard" && <NoticeBoard customId={customId} />}
          {keyName === "AdmSA" && <StrengthAnalyses />}
          {keyName === "AdmStaff" && <StaffDetails />}
          {keyName === "AdmStudents" && <StudentDetails />}
          {keyName === "AdmNotification" && <AdminNotificationSearchPage />}
          {keyName === "AdmNoticeSearch" && <AdminNoticeSearch />}
          {keyName === "AdmHomeWork" && <AdminHomeWorkSearchPage />}
          {keyName === "AdmGallery" && <AdminGallerySearchPage />}
          {keyName === "AdmCommunication" && <AdminCommSearchPage />}
          {keyName === "AdmClassDetails" && <AdminClassDetailsList />}
          {keyName === "AdmStudentSearch" && <AdminStuSearch />}
          {keyName === "AdmFeesAnalyses" && <FeesAnalysesDashboard />}
          {keyName === "AdmBlackListed" && <BlacklistStudentList />}
          {keyName === "AdmPoll" && <PollAddAndList />}
          {keyName === "AdmClassNoticeBoard" && <AdminClassDetailsList />}
          {keyName === "QuickInfo" && <QuickInfo />}
          {keyName === "AdmScrollNoticeBoard" && <ScrollNoticeList />}
        </div>
      </div>
    </>
  );
};

export default CustomAdminPage;
