import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import Spinner from "../../components/Spinner";
import useSidebar from "../../hooks/useSidebar";
import { getUserDataFromLocalByKey } from "../../API/getStudentInfoByKeyLocal";
import { getCTTodayScheduleListAPI } from "../../API/getCTTodayScheduleListAPI";

const CTTodaySchedule = () => {
  const { customId } = useParams();
  const [userDetails, setUserDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [ctName, setCtName] = useState("");
  const [ctSubjectList, setCtSubjectList] = useState([]);
  const [initialResourceUrl, setInitialResourceUrl] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const users = JSON.parse(localStorage.getItem("registeredUsers")) || [];

      if (customId) {
        const decodedData = JSON.parse(atob(customId));
        let matchedItem = [];

        users.forEach((innerArray) => {
          const [userData] = innerArray;

          if (
            userData.userId === decodedData.userId &&
            userData.userType === decodedData.userType &&
            userData.schoolId === decodedData.schoolId &&
            userData.year === decodedData.year
          ) {
            matchedItem.push(userData);
          }
        });

        const namekeyval = "EmployeeName";
        const username = await getUserDataFromLocalByKey(
          decodedData.userId,
          decodedData.schoolId,
          decodedData.userType,
          decodedData.year,
          namekeyval
        );

        const modifiedUsername = username.replace(/(?:MS|MR)\s*/g, "");

        setCtName(modifiedUsername);

        const result = await getCTTodayScheduleListAPI(
          decodedData.userId,
          decodedData.schoolId,
          decodedData.userType,
          decodedData.year
        );

        const keyval = "webResourceURL";
        const dataUrl = await getUserDataFromLocalByKey(
          decodedData.userId,
          decodedData.schoolId,
          decodedData.userType,
          decodedData.year,
          keyval
        );
        setInitialResourceUrl(dataUrl);

        console.log("Home Work RESULT", result);
        setCtSubjectList(result);
        setLoading(false);
        setUserDetails(matchedItem);
      }
    };

    fetchData();
  }, [customId]);

  const { sidebarVisible, toggleSidebar } = useSidebar();

  const colors = ['#FF6633',  '#FFB399',  '#FF33FF',  '#FFFF99',  '#00B3E6',  '#E6B333',  '#3366E6',  '#999966'];
  let colorIndex = 0;

  const getColor = () => {
    const color = colors[colorIndex];
    colorIndex = (colorIndex + 2) % colors.length;
    return color;
  };

  return (
    <div className="container mx-auto p-4">
      {loading ? (
        <Spinner />
      ) : (
        <>
          <div className="flex flex-col md:flex-row items-center justify-between mb-4">
            <div className="w-2/3">
              <h3 className="md:text-3xl font-bold text-xl mb-2 md:mb-0">
                Teacher : {ctName}
              </h3>
              <h3 className="mb-2 font-bold">Subject List</h3>
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-4">
            {ctSubjectList && ctSubjectList.length > 0 ? (
              ctSubjectList.map((item) => (
                <Link to={``} onClick={""} key={item.id}>
                  <div className="border border-gray-200 rounded-lg p-4 hover:shadow-md transition duration-300">
                    <div className="flex items-center">
                      <div className="w-1/3 sm:w-auto mr-4">
                        <div
                          className={`w-20 flex justify-center items-center h-20  sm:w-32 sm:h-32 object-cover rounded-ful`}
                        >
                          <div
                            className="rounded-full flex items-center justify-center font-semibold text-xl h-20 w-20 text-white"
                            style={{ backgroundColor: getColor() }}
                          >
                            {item.Cnd}
                          </div>
                        </div>
                      </div>

                      <div className="w-2/3 ">
                        <h3 className="text-lg font-semibold mb-2">
                          Subject : {item.SUBJ}
                        </h3>
                        <h4 className="text-md text-gray-500 font-normal mb-2">
                          {item.TClass}-{item.TSection}
                        </h4>
                      </div>
                    </div>
                  </div>
                </Link>
              ))
            ) : (
              <p>No data found</p>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default CTTodaySchedule;
