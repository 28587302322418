import React, { useState, useRef, useEffect } from "react";
import { useParams } from "react-router-dom";
import { FcAssistant } from "react-icons/fc";
import Navbar from "../../components/Navbar";
import Sidebar from '../../components/Sidebar';
import { useSidebar } from "../../context/SidebarContext";
import { toast } from "react-toastify";

import { BsFillMicFill, BsFillMicMuteFill } from "react-icons/bs";
import { UpdateAskToMentorAPI } from "../../API/UpdateAskToMentor";
import { getUserDataFromLocalByKey } from "../../API/getStudentInfoByKeyLocal";

const MentorPage = () => {
  const {
    EmpName,
    TClass,
    customId,
    Subject,
    HWSname,
    HWClass,
    HWDateStr,
    HWRemarks,
    TchReply,
    HWSection,
    HWSubject,
    SoundClipStudent,
    SoundClipTeacher,
  } = useParams();

  const MentoreName = EmpName ? EmpName.split("").join(" ") : "";

  const addSpaceBetweenLetters = (text) => {
    return text.split("").join(" ");
  };

  useEffect(() => {
    if (customId) {
      fetchData();
      const decodedData = JSON.parse(atob(customId));
      setUserDetails(decodedData);
      setRequestMessage(TchReply == "null" || "" ? "No Reply" : TchReply);
    }
  }, [customId]);

  const [requestMessage, setRequestMessage] = useState("");
  const [audiobase64, setAudiobase64] = useState("");
  const [userDetails, setUserDetails] = useState(null);
  const [audio, setAudio] = useState(new Audio());
  const [audioteacher, setAudioTeacher] = useState(new Audio());
  const [isPlaying, setIsPlaying] = useState(false);
  const [isPlayingTeacher, setIsPlayingTeacher] = useState(false);
  const [resourceUrl, setResourceUrl] = useState(null);
  const [isAudio,setIsAudio]=useState(false)

  const [data, setData] = useState(true);

  // const { sidebarVisible, toggleSidebar } = useSidebar();

  const { isSidebarOpen, currentSidebarTab, toggleSidebar,setIsSidebarOpen } = useSidebar();

  const [audioStream, setAudioStream] = useState(null);
  const [isRecording, setIsRecording] = useState(false);
  const audioRef = useRef(null);

  const fetchData = async () => {
    const users = JSON.parse(localStorage.getItem("registeredUsers")) || [];

    if (customId) {
      const decodedData = JSON.parse(atob(customId));
      let matchedItem = [];

      console.log("DECODED DATA", decodedData);

      users.forEach((innerArray) => {
        const [userData] = innerArray;

        if (
          userData.userId === decodedData.userId &&
          userData.userType === decodedData.userType &&
          userData.schoolId === decodedData.schoolId &&
          userData.year === decodedData.year
        ) {
          matchedItem.push(userData);
        }
      });

      const key = "WebResourceAWSDOTNETURL";
      const dataResourceUrl = await getUserDataFromLocalByKey(
        decodedData.userId,
        decodedData.schoolId,
        decodedData.userType,
        decodedData.year,
        key
      );
      setResourceUrl(dataResourceUrl);
    }
  };

  console.log(resourceUrl);

  useEffect(() => {
    // Update the audio source whenever the audioFile parameter changes

    audio.src = `${resourceUrl}/PARENTCOMMUNICATION/${SoundClipStudent}`

    audioteacher.src = `${resourceUrl}/PARENTCOMMUNICATION/${SoundClipTeacher}`;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [SoundClipStudent, SoundClipTeacher, resourceUrl]);

  const togglePlay = () => {
    if (isPlaying) {
      audio.pause();
    } else {
      audio.play();
    }
    setIsPlaying(!isPlaying);
  };

  const togglePlayTeacher = () => {

    if (isPlayingTeacher) {
      audioteacher.pause();
    } else {
      audioteacher.play();
    }
    setIsPlayingTeacher(!isPlayingTeacher);
  };

  const getCurrentDate = () => {
    let currentDate = new Date();
    let year = currentDate.getFullYear();
    let month = currentDate.getMonth() + 1;
    let day = currentDate.getDate();

    if (month < 10) {
      month = "0" + month;
    }

    if (day < 10) {
      day = "0" + day;
    }

    return day + "/" + month + "/" + year;
  };
  const handleSubmit = async () => {
    if (requestMessage === "") {
      alert("Please enter your request message");
      return;
    }
  
    const formattedDate = getCurrentDate();
  
    try {
      // Convert audio to Base64

     let audioData=""
      
      if (isAudio) {
        
         audioData = await convertAudioToBase64();
      }
      
      
  
      
      setAudiobase64(audioData);
  
      // Determine the audio filename
      let audioFilename = audioData ? "a.wav" : "N/A";
  
      // Create form data
      const formData = {
        messageToMentor: requestMessage,
        base64audio: audioData,
      };
  
      const decodedData = JSON.parse(atob(customId));
      console.log("Form Data:", formData);
  
      // Call the API
      const result = await UpdateAskToMentorAPI(
        decodedData.userId,
        decodedData.schoolId,
        decodedData.userType,
        decodedData.year,
        formData.messageToMentor,
        formData.base64audio,
        formattedDate,
        audioFilename,
        Subject
      );
     setIsAudio(false)
      formData.messageToMentor=""
      formData.base64audio=""
      
    
  
      console.log("RESULT OBTAINED", result);
      toast.success("Success");
  
      // Clear the form
      setRequestMessage("");
      setAudiobase64("");
      if (audioRef.current) {
        audioRef.current.src = "";
        audioRef.current.removeAttribute("name"); // Clears the audio source
      }
    } catch (error) {
      toast.error("Failed");
      console.error("Error in handleSubmit:", error);
    }
  };
  
 
  const convertAudioToBase64 = () => {
    return new Promise((resolve, reject) => {
      if (audioRef.current && audioRef.current.src) {
        fetch(audioRef.current.src)
          .then(response => response.blob())
          .then(audioBlob => {
            const reader = new FileReader();
            reader.readAsDataURL(audioBlob);
            reader.onloadend = () => {
              resolve(reader.result);
            };
            reader.onerror = reject;
          })
          .catch(reject);
      } else {
        resolve(null); // No audio to convert
      }
    });
  };
  

  const handleReset = () => {
    // Logic to reset the form

    setRequestMessage("");
  };

  // const startRecording = async () => {

  //    toast.success("Audio Started recording")

  //   try {
  //     const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
  //     setAudioStream(stream);
  //     const audioRecorder = new MediaRecorder(stream);
  //     const audioChunks = [];

  //     audioRecorder.ondataavailable = (e) => {
  //       audioChunks.push(e.data);
  //     };

  //     audioRecorder.onstop = () => {
  //       const audioBlob = new Blob(audioChunks, { type: "audio/wav" });
  //       const audioUrl = URL.createObjectURL(audioBlob);
  //       audioRef.current.src = audioUrl;
  //     };

  //     audioRecorder.start();
  //     setIsRecording(true);
  //   } catch (error) {
  //     console.error("Error accessing microphone:", error);
  //   }
  // };

  const startRecording = async () => {
    toast.success("Audio Started recording");

    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      setAudioStream(stream);
      const audioRecorder = new MediaRecorder(stream);
      const audioChunks = [];
      let audioFilename = "";

      audioRecorder.ondataavailable = (e) => {
        audioChunks.push(e.data);
      };

      audioRecorder.onstop = () => {
        const audioBlob = new Blob(audioChunks, { type: "audio/wav" });
        const audioUrl = URL.createObjectURL(audioBlob);
        audioRef.current.src = audioUrl;
        audioRef.current.setAttribute("name", audioFilename); // Set the filename as a name attribute
      };

      audioRecorder.start();
      setIsRecording(true);
    } catch (error) {
      console.error("Error accessing microphone:", error);
    }
  };


  

  const stopRecording = () => {
    if (audioStream) {
      const tracks = audioStream.getTracks();
      tracks.forEach((track) => track.stop());
      toast.error("Stopped Recording");
      setIsRecording(false);
      setIsAudio(true

      )
    }
  };

  return (
    <>
      {data && (
        <Navbar
          data={userDetails}
          // isOpen={sidebarVisible}
          toggleSidebar={toggleSidebar}
        />
      )}

   
      <div className="flex">
        {data && <Sidebar data={userDetails} />}

        <div className={`w-full absolute ${isSidebarOpen?"lg:w-4/6 xl:w-9/12":"w-full"} sm:w-11/12 right-0 top-16 mx-auto   p-4 md:p-6 lg:p-8 xl:p-10`}>
          <div className="flex items-center justify-between mb-4">
            <div className="w-2/3">
              {HWSname ? (
                <>
                  <h2 className="mb-2 ml-2 text-3xl font-bold">
                    Student: {HWSname}
                  </h2>
                  <h2 className="mb-2 ml-2 text-3xl font-bold">
                    Class: {HWClass}
                  </h2>
                  <h2 className="mb-2 ml-2 font-bold">Mentor Response</h2>
                </>
              ) : (
                <>
                  <h1 className="mb-2 ml-2 text-3xl font-bold">
                    Mentor Request 
                  </h1>
                </>
              )}
            </div>
          </div>

          <div className="bg-slate-50 shadow-md rounded px-8 pt-6 pb-8 mb-4">
            <div className="mb-6 mt-12 flex items-center">
              <FcAssistant className="h-16 w-16 mr-4" />
              <div>
                <label className="block text-gray-700 font-bold mb-2">
                  Teacher Name: <span className=" font-medium">{EmpName}</span>
                </label>
                <label className="block text-gray-700 font-bold mb-2">
                  {HWSname ? (
                    <>
                      Class :{" "}
                      <span className="font-medium mr-1">
                        {HWClass}-{HWSection}
                      </span>
                      Date :{" "}
                      <span className="font-medium mr-1">{HWDateStr}</span>
                      Subject : <span className="font-medium">{HWSubject}</span>
                    </>
                  ) : (
                    <>
                      Class : <span className="font-medium">{TClass}</span>
                    </>
                  )}
                </label>
              </div>
            </div>

            {HWSname ? (
              <>
                <div className="mb-4">
                  <div className="border border-red-500 p-4 rounded">
                    <label className="block text-red-500 font-semibold mb-2 text-center">
                      Parent's Message
                    </label>
                    <p className="text-center">{HWRemarks}</p>
                  </div>

                  {/* Center the button using Flexbox */}
                  <div className="flex justify-center mt-4">
                    <button
                      onClick={togglePlay}
                      className="bg-green-500 text-white font-semibold px-4 py-2 rounded shadow-md focus:outline-none"
                    >
                      {isPlaying ? "Pause" : "Play Student Request"}
                    </button>
                  </div>

                  <div className="mb-4">
                    {/* Center the button using Flexbox */}
                    <div className="flex justify-center mt-4">
                      {TchReply !== "null" && TchReply !== "" && (
                        <button
                          onClick={togglePlayTeacher}
                          className="bg-green-500 text-white font-semibold px-4 py-2 rounded shadow-md focus:outline-none"
                        >
                          {isPlayingTeacher ? "Pause" : "Play Teacher Reply"}
                        </button>
                      )}
                    </div>
                  </div>
                </div>

                <div className="mb-4">
                  <label className="block text-gray-700 font-semibold mb-2">
                    Mentor Response
                  </label>
                  <textarea
                    value={requestMessage}
                    onChange={(e) => setRequestMessage(e.target.value)}
                    className="border border-gray-300 rounded px-3 py-2 w-full focus:outline-none"
                    rows="5"
                  ></textarea>
                </div>
              </>
            ) : (
              <>
                <div className="mb-4">
                  <label className="block text-gray-700 font-semibold mb-2">
                    Request Message
                  </label>
                  <textarea
                    value={requestMessage}
                    onChange={(e) => setRequestMessage(e.target.value)}
                    placeholder="Enter mentor request here..."
                    className="border border-gray-300 rounded px-3 py-2 w-full focus:outline-none"
                    rows="5"
                  ></textarea>
                </div>

                <div className="flex justify-center">
                  <button
                    onClick={handleSubmit}
                    className="bg-green-500 text-white font-semibold px-4 py-2 rounded shadow-md mr-4 focus:outline-none"
                  >
                    Submit
                  </button>
                  <button
                    onClick={handleReset}
                    className="bg-red-500 text-white font-semibold px-4 py-2 rounded shadow-md focus:outline-none"
                  >
                    Reset
                  </button>
                </div>
              </>
            )}
          </div>

          {HWSname ? (
            ""
          ) : (
            <>
              <div className="bg-white shadow-md mt-12 rounded px-8 pt-6 mx-auto pb-8 mb-4 w-full md:w-1/2 lg:w-1/3">
                <h1 className="md:text-2xl text-lg font-bold text-center mb-4">
                  Audio Recorder
                </h1>

                <div className="mb-4">
                  <audio ref={audioRef} controls className="w-full" />
                </div>
              </div>

              <div className=" px-2 py-2">
                <div className="mb-4 px-4 py-4 rounded-full bg-gradient-to-b from-sky-400 to-indigo-500  fixed right-12 bottom-8">
                  {/* Conditional rendering based on recording state */}
                  {isRecording ? (
                    <BsFillMicMuteFill
                      onClick={stopRecording}
                      className="text-white z-50 h-8 w-8 m-auto cursor-pointer"
                    />
                  ) : (
                    <BsFillMicFill
                      onClick={startRecording}
                      className="text-white h-8 w-8 m-auto cursor-pointer"
                    />
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default MentorPage;
