import React from "react";
import axios from "axios";
import toast from "react-toastify";
import { getUserDataFromLocalByKey } from "./getStudentInfoByKeyLocal";

export const UpdateCTComBWTeachersAPI = async (userId,schoolId,userType,year,MessageToTeacher,Sname,Toname,ToEmpCode,audioValue,fileName) => {
  try {
    
    // decodedData.userId,
    //         decodedData.schoolId,
    //         decodedData.userType,
    //         decodedData.year,
    //         formData.messageToMentor,
    //         audiobase64,
    //         formattedDate,
    //         audioFilename
    
    const key = "webServiceURLAWSDOTNET";

  

  
  

    const returnUrl = await getUserDataFromLocalByKey(userId,schoolId,userType,year,key);



    const SMSType = process.env.REACT_APP_SMS_TYPE;
   

    const formData = new FormData();
    formData.append("title", "UpdateTM");
    formData.append("description", '');
    formData.append("ReqHWID", '');
    formData.append("ReqAcastart", year);
    formData.append("ReqAdmno",userId );
    formData.append("ReqSname", Sname);
    formData.append("ReqAddedCode", ToEmpCode);
    formData.append("ReqAddedBy", Toname);
    formData.append("ReqTMessage", MessageToTeacher);
    formData.append("photoLocation",fileName );
    formData.append("imagestring", audioValue);
    formData.append("ReqSMSType",SMSType );


    let userdata = [];

    const Corskey = process.env.REACT_APP_CORS_URL;

    const result = await axios.post(`${Corskey}${returnUrl}`, formData);

    console.log("RESULT RESPONSE",result)
    userdata = JSON.parse(
      result.data.substring(0, result.data.indexOf("||JasonEnd", 0))
    );


    // console.log("RESULT RESPONSE",result)
    console.log("RESULT RESPONSE DATA",userdata)

    return userdata;

    
  } catch (error) {
    console.error("An error occurred:", error);
    // Handle error here
  }
  // Placeholder JSX, replace with actual JSX
};
