import React from "react";
import axios from "axios";
import toast from "react-toastify";
import { getUserInfoKey } from '../API/GetUserInfoByKey'
import { getUserServerKey } from "./getUserServerKey";

export const getUserValidity = async (userId,schoolId,userType,year) => {
  try {

      const key='webServiceURLAWSDOTNET'

    //  const returnwebServiceUrl= await getUserInfoKey(userId,schoolId,userType,year,key)

     const returnwebServiceUrl= await getUserInfoKey(userId,schoolId,userType,year,key)
     

 
     console.log("Return Web Service Url",returnwebServiceUrl)

    const formData = new FormData();
    formData.append("title", "GetUserValidity");
    formData.append("description", "");
    formData.append("ReqAcastart", year);
    formData.append("ReqUserID", userId);
    formData.append("ReqUserType", userType);
    formData.append("ReqPhoneCode", '---');
    let userdata = [];

    const Corskey=process.env.REACT_APP_CORS_URL;


    const result = await axios.post(
      `${Corskey}${returnwebServiceUrl}`,
      formData
    );

    userdata = JSON.parse(
      result.data.substring(0, result.data.indexOf("||JasonEnd", 0))
    );

    return userdata;

  
  } catch (error) {
    console.error("An error occurred:", error);
    // Handle error here
  }
  // Placeholder JSX, replace with actual JSX
};