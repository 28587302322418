import React, { useState, useEffect, useCallback } from "react";
import { FaCheckCircle, FaCloudUploadAlt, FaTimesCircle } from "react-icons/fa";
import { useParams } from "react-router-dom";
import { getAttendanceListDetail } from "../../API/getAttendanceList";
import Spinner from "../../components/Spinner";
import useSidebar from "../../hooks/useSidebar";
import { UpdateStudentAttendanceAPI } from "../../API/updateStudentAttendance";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

const CLAttendance = () => {
  const [busCardList, setBusCardList] = useState();
  const [userDetails, setUserDetails] = useState(null);
  const [studentsList, setStudentsList] = useState([]);
  const [searchVisible, setSearchVisible] = useState(false);
  const [name, setName] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [originalStudentsList, setOriginalStudentsList] = useState([]);
  const [data, setData] = useState(true);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [selectedStudentSTDYRID, setSelectedStudentSTDYRID] = useState(null);
  const [selectedStudentAttendanceType, setSelectedStudentAttendanceType] =
    useState(null);
  const [filteredAttendance, setFilteredAttendance] = useState([]);
  const [myMentorsList, setMyMentorsList] = useState([]);
  const { customId, keyName } = useParams();

  const handleCheckboxChange = (index, data) => {
    console.log(data, " hhh");
    Swal.fire({
      title: "Attendance Update!",
      text: "You want to update this students Attendance?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Update it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const att = data.AttType === "P" ? "A" : "P";
        const convertDateFormat = (dateString) => {
          if (!dateString) return null;
          const [year, month, day] = dateString.split("-");
          return `${day}-${month}-${year}`;
        };
        const selectedDate = convertDateFormat(name);
        setBusCardList((prevList) =>
          prevList.map((item, i) =>
            i === index
              ? { ...item, AttType: item.AttType === "P" ? "A" : "P" }
              : item
          )
        );
        const formattedDate = getCurrentDate();
        const decodedData = JSON.parse(atob(customId));
        const response = await UpdateStudentAttendanceAPI(
          decodedData.userId,
          decodedData.schoolId,
          decodedData.userType,
          decodedData.year,
          data?.STDYRID,
          formattedDate,
          att
        );

        const Toast = Swal.mixin({
          toast: true,
          position: "top-end", // Change the position here
          showConfirmButton: false,
          timer: 1000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.onmouseenter = Swal.stopTimer;
            toast.onmouseleave = Swal.resumeTimer;
          },
        });

        Toast.fire({
          text: "Attendance is Updated.",
          icon: "success",
        });
        fetchData();
      }
    });
  };

  const fetchData = async () => {
    const users = JSON.parse(localStorage.getItem("registeredUsers")) || [];

    if (customId) {
      const decodedData = JSON.parse(atob(customId));
      let matchedItem = [];

      users.forEach((innerArray) => {
        const [userData] = innerArray;

        if (
          userData.userId === decodedData.userId &&
          userData.userType === decodedData.userType &&
          userData.schoolId === decodedData.schoolId &&
          userData.year === decodedData.year
        ) {
          matchedItem.push(userData);
        }
      });

      const result = await getAttendanceListDetail(
        decodedData.userId,
        decodedData.schoolId,
        decodedData.userType,
        decodedData.year
      );

      setBusCardList(result);

      setUserDetails(matchedItem);

      setLoading(false); // Set loading to false when data is fetched
    }
  };

  useEffect(() => {
    fetchData();
  }, [customId]);

  const onMarkAttendance = (stryd, type) => {
    setSelectedStudentSTDYRID(stryd);
    setSelectedStudentAttendanceType(type);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    handleSearch(searchTerm);
  }, [busCardList]);

  const handleSearch = (value) => {
    setSearchTerm(value);

    if (value.trim() === "") {
      setFilteredAttendance([]); // Clear filtered data if search is empty
    } else {
      // Apply filtering on the updated busCardList
      const filtered = busCardList.filter(
        (student) =>
          student.SNAME.toLowerCase().includes(value.toLowerCase()) ||
          student.ADMNO.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredAttendance(filtered);
    }
  };

  const getCurrentDate = () => {
    let currentDate = new Date();
    let year = currentDate.getFullYear();
    let month = currentDate.getMonth() + 1;
    let day = currentDate.getDate();

    if (month < 10) {
      month = "0" + month;
    }

    if (day < 10) {
      day = "0" + day;
    }

    return day + "-" + month + "-" + year;
  };

  const handleAttendanceChange = useCallback(
    async (stdyrid, dateData, AttType) => {
      try {
        const decodedData = JSON.parse(atob(customId));
        const updateRes = await UpdateStudentAttendanceAPI(
          decodedData.userId,
          decodedData.schoolId,
          decodedData.userType,
          decodedData.year,
          stdyrid,
          dateData,
          AttType
        );

        console.log("RESULT FROM UPDATE ATTENDANCE", updateRes);

        if (updateRes) {
          toast.success("Attendance Updated Successfully");
          console.log(updateRes);

          // After successfully updating attendance, update the studentsList
          const updatedList = studentsList.map((student) => {
            if (student.STDYRID === stdyrid) {
              return { ...student, AttType }; // Assuming the property for attendance type is AttType
            }
            return student;
          });
          setStudentsList(updatedList); // Update the state to trigger a re-render
        }
      } catch (error) {
        // Handle errors, e.g., display an error message or perform other actions
        console.error("Error updating attendance:", error);
      }
    },
    [studentsList, customId]
  );

  useEffect(() => {}, [handleAttendanceChange]);

  const handleInputChange = (index, event) => {
    const { value } = event.target;
    setBusCardList((prevList) =>
      prevList.map((item, i) =>
        i === index ? { ...item, AttType: value } : item
      )
    );
  };

  return (
    <>
      <div className="container mx-auto p-4 space-y-2">
        {loading ? (
          <Spinner />
        ) : (
          <>
            <div className="flex justify-between items-center bg-white shadow-lg p-4 rounded-md">
              <h2 className="md:text-2xl text-lg font-bold text-gray-800">
                Attendance Marking System
              </h2>
              <input
                type="text"
                placeholder="Search by name or ID"
                className="border md:w-80 w-44 border-gray-300 rounded-lg px-3 py-2 shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-300 ease-in-out"
                value={searchTerm}
                onChange={(e) => handleSearch(e.target.value)}
              />
            </div>

            <div className="bg-white shadow-md rounded-lg overflow-x-auto hidden md:block">
              <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 border-collapse">
                <thead className="h-12 text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400 sticky top-0 z-10">
                  <tr className="tableHead">
                    <th scope="col " className="p-1">
                      Att No.
                    </th>
                    <th scope="col " className="p-1">
                      Admission No.
                    </th>
                    <th scope="col " className="p-1">
                      Name
                    </th>
                    <th scope="col " className="p-1">
                      Father Name
                    </th>
                    <th scope="col " className="p-1">
                      Att
                    </th>
                    <th scope="col " className="p-1">
                      Absent
                    </th>
                  </tr>
                </thead>
                <tbody className="text-gray-900">
                  {(filteredAttendance.length > 0
                    ? filteredAttendance
                    : busCardList
                  )?.map((data, i) => {
                    return (
                      <tr
                        key={i}
                        className={`text-gray-900 ${
                          i % 2 === 0 ? "bg-white" : "bg-gray-100"
                        }`}
                      >
                        <td className="p-1 border">{i + 1}</td>
                        <td className="p-1 border">{data?.ADMNO}</td>
                        <td className="p-1 border">{data?.SNAME}</td>
                        <td className="p-1 border">{data?.FATHERNAME}</td>
                        <td className="p-1 border">
                          <input
                            type="text"
                            name="select"
                            value={data?.AttType}
                            onChange={(e) => handleInputChange(i, e)}
                            className="textInput !max-w-16 !w-fit"
                          />
                        </td>
                        <td className="p-1 border">
                          <label className="inline-flex items-center cursor-pointer">
                            <input
                              type="checkbox"
                              value=""
                              checked={data?.AttType === "A"}
                              onChange={(e) => handleCheckboxChange(i, data)}
                              className="sr-only peer"
                            />
                            <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                          </label>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 md:hidden">
  {busCardList?.map((data, i) => (
    <div
      key={i}
      className="bg-white p-6 shadow-md rounded-lg border border-gray-200 transition-all duration-300 hover:shadow-lg"
    >
      <div className="flex justify-between items-center mb-4 pb-2 border-b border-gray-200">
        <span className="text-lg font-semibold text-gray-800">Student {i + 1}</span>
        <span className="text-sm font-medium text-gray-600 bg-gray-100 px-2 py-1 rounded">
          {data.ADMNO}
        </span>
      </div>
      
      <div className="space-y-3">
        <div>
          <label className="block text-sm font-medium text-gray-600 mb-1">Name</label>
          <p className="text-gray-800">{data.SNAME}</p>
        </div>
        
        <div>
          <label className="block text-sm font-medium text-gray-600 mb-1">Father's Name</label>
          <p className="text-gray-800">{data.FATHERNAME}</p>
        </div>
        
        <div className="flex items-center justify-between">
          <label className="block text-sm font-medium text-gray-600">Attendance</label>
          <input
            type="text"
            value={data?.AttType}
            onChange={(e) => handleInputChange(i, e)}
            className="w-16 p-2 text-center border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-300"
          />
        </div>
        
        <div className="flex items-center justify-between pt-3 border-t border-gray-200">
          <span className="text-sm font-medium text-gray-600">Mark as Absent</span>
          <label className="relative inline-flex items-center cursor-pointer">
            <input
              type="checkbox"
              checked={data?.AttType === "A"}
              onChange={(e) => handleCheckboxChange(i, data)}
              className="sr-only peer"
            />
            <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
          </label>
        </div>
      </div>
    </div>
  ))}
</div>

          </>
        )}
      </div>
    </>
  );
};

export default CLAttendance;
