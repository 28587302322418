import React, { useState, useEffect } from "react";
import Navbar from "../../components/Navbar";
import Sidebar from '../../components/Sidebar';
import { useSidebar } from "../../context/SidebarContext";
import { useParams } from "react-router-dom";
import Spinner from "../../components/Spinner";
import { getUserDataFromLocalByKey } from "../../API/getStudentInfoByKeyLocal";
import { getStudentFullDataFromLocal } from "../../API/getDataFromLocalStorage";
import { getStuFeesDC } from "../../API/getStuFeesDemandCollectionAPI";
function StuFeeSummHeadwise() {
  const [userDetails, setUserDetails] = useState(null);
  const [data, setData] = useState(true);
  const { customId } = useParams();
  const [loading, setLoading] = useState(true);
  const [initialResourceUrl, setInitialResourceUrl] = useState(null);
  const [studentInfo, setStudentInfo] = useState([]);
  const [feeDCList, setFeeDCList] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const users = JSON.parse(localStorage.getItem("registeredUsers")) || [];

      if (customId) {
        const decodedData = JSON.parse(atob(customId));
        let matchedItem = [];

        users.forEach((innerArray) => {
          const [userData] = innerArray;

          if (
            userData.userId === decodedData.userId &&
            userData.userType === decodedData.userType &&
            userData.schoolId === decodedData.schoolId &&
            userData.year === decodedData.year
          ) {
            matchedItem.push(userData);
          }
        });

        const result = await getStudentFullDataFromLocal(
          decodedData.userId,
          decodedData.schoolId,
          decodedData.userType,
          decodedData.year
        );

        const title = "FeesSummHeadWise";
        const feeResult = await getStuFeesDC(
          decodedData.userId,
          decodedData.schoolId,
          decodedData.userType,
          decodedData.year,
          title
        );

        const keyval = "webResourceURL";
        const dataUrl = await getUserDataFromLocalByKey(
          decodedData.userId,
          decodedData.schoolId,
          decodedData.userType,
          decodedData.year,
          keyval
        );
        setInitialResourceUrl(dataUrl);
        setStudentInfo(result);
        setFeeDCList(feeResult);
        setLoading(false);
        setUserDetails(matchedItem);
      }
    };

    fetchData();
  }, [customId]);

  // console.log(feeDCList);

  const { isSidebarOpen, currentSidebarTab, toggleSidebar,setIsSidebarOpen } = useSidebar();

  const studentName =
    studentInfo.find((item) => item.ItemKeyName === "SNAME")?.InfoField || "";


  return (
    <>
      {data && (
        <Navbar
          data={userDetails}
          // isOpen={sidebarVisible}
          toggleSidebar={toggleSidebar}
        />
      )}

      <div className="flex">
        {data && <Sidebar data={userDetails} />}
        <div className={`w-full absolute ${isSidebarOpen?"lg:w-4/6 xl:w-9/12":"w-full"} sm:w-11/12 right-0 top-16 mx-auto   p-4 md:p-6 lg:p-8 xl:p-10`}>
          <div className="flex items-center justify-between mb-4">
            <div className="w-2/3">
              <h2 className=" md:text-2xl font-bold text-xl mb-2 md:mb-0">
                Student : {studentName}
              </h2>
            </div>
          </div>

          <div className="container mx-auto p-4">
            {loading ? (
              <Spinner />
            ) : (
              <>
                <div className="w-4/5   shadow-md shadow-gray-400 rounded-md mt-28 lg:mt-12 bg-white mx-auto ">
                  <div className="w-full bg-blue-700 text-white font-semibold text-xl  text-center px-3 py-4">
                    Fees SummHead Wise
                  </div>
                  <div className="table-container overflow-y-auto h-96">
                    <table className="w-full table-auto border-collapse border border-gray-300">
                      <thead>
                        <tr className="bg-blue-500">
                          {/* <th className="sticky top-0 bg-blue-700 z-10 p-2 border left-0">
                  Rcdid
                  </th>
                  <th className="sticky top-0 bg-blue-700 z-10 p-2 border left-0">
                  Sl.No
                  </th> */}
                          <th className="sticky top-0 bg-blue-500 z-10 p-2 border">
                            Head
                          </th>
                          <th className="sticky top-0 bg-blue-500 z-10 p-2 border">
                            Demand
                          </th>
                          <th className="sticky top-0 bg-blue-500 z-10 p-2 border">
                            Concession
                          </th>
                          <th className="sticky top-0 bg-blue-500 z-10 p-2 border">
                            Amount
                          </th>
                          <th className="sticky top-0 bg-blue-500 z-10 p-2 border">
                            Collection
                          </th>
                          <th className="sticky top-0 bg-blue-500 z-10 p-2 border">
                            Balance
                          </th>
                          {/* Add more columns as needed */}
                        </tr>
                      </thead>
                      <tbody>
                        {/* Populate table rows based on classWiseStrengthList */}
                        {feeDCList.map((item, index) => (
                          <tr key={index} className="bg-gray-300">
                            {/* <td className="p-2 border text-center">{item.Rcdid}</td>
                    <td className="p-2 border text-center">{item.Slno}</td> */}
                            <td className="p-2 border text-left">
                              {item.FeesHead}
                            </td>
                            <td className="p-2 border text-right">
                              {item.Demand}
                            </td>
                            <td className="p-2 border text-right">
                              {item.Concession}
                            </td>
                            <td className="p-2 border text-right">
                              {item.ActDemand}
                            </td>
                            <td className="p-2 border text-right">
                              {item.Collection}
                            </td>
                            <td className="p-2 border text-right">
                              {item.Balance}
                            </td>
                            {/* Add more columns as needed */}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default StuFeeSummHeadwise;
