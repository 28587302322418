import React, { useState, useEffect } from 'react';
import DemoStudentImage from '../../assets/png/avatar.png';
import { useParams } from 'react-router-dom';
import Navbar from '../../components/Navbar';
import Sidebar from '../../components/Sidebar';
import { useSidebar } from "../../context/SidebarContext";
// import useSidebar from '../../hooks/useSidebar';
import ClassmatesPage from './ClassMatesPage';
import Notification from './Notification';
import MyTask from './MyTask';
import MyPerformance from './MyPerformance';
import Gallery from './Gallery';
import MyMentors from './MyMentors';
import StudentProfile from './StudentProfile';
import AskMyMentor from './AskMyMentor';
import StudentNoticeBoard from './StudentNoticeBoard';
import StudentTimeTable from './StudentTimeTable';
import AskMyMentorCreate from './AskMyMentorCreate';
import StudentFeeAnalyses from './StudentFeeAnalyses';
import StuLeaveDays from './StuLeaveDays';
import TaskSubmit from './TaskSubmit';
import StudentPolls from './StudentPolls';
import ScrollNoticeList from './ScrollNoticeList';

const CustomPage = () => {
  const { customId, keyName } = useParams();
  const [userDetails, setUserDetails] = useState(null);

  const [data, setData] = useState(true);

  const { isSidebarOpen, currentSidebarTab, toggleSidebar,setIsSidebarOpen } = useSidebar();



  console.log(customId)
  console.log(keyName)

  useEffect(() => {
    

      if (customId) {
       
        const decodedData = JSON.parse(atob(customId));
        setUserDetails(decodedData)
   
      }

      
  }, [customId,keyName])


 

  

  return (
    <>
      {data && <Navbar data={userDetails}  toggleSidebar={toggleSidebar} />}

      <div className="flex">
        { data  && <Sidebar data={userDetails} />}

        <div className={`w-full absolute ${isSidebarOpen?"lg:w-4/6 xl:w-9/12":"w-full"} sm:w-11/12 right-0 top-16 mx-auto   p-4 md:p-6 lg:p-8 xl:p-10`}>
          
          {keyName === 'StdNotification' && <Notification />}
          {keyName === 'StdClassmates' && <ClassmatesPage />}
          {keyName === 'StdMyTask' && <MyTask />}
          {keyName === 'StdGallery' && <Gallery />}
          {keyName === 'StdMyMentors' && <MyMentors />}
          {keyName === 'StdThisisMe' && <StudentProfile />}
          {keyName === 'StdNoticeBoard' && <StudentNoticeBoard />}
          {keyName === 'StdAskMyMentor' && <AskMyMentorCreate />}
          {keyName === 'StdTimeTable' && <StudentTimeTable />}
          {keyName === 'StdExams' && <MyPerformance />}
          {keyName === 'StdFees' && <StudentFeeAnalyses />}
          {keyName === 'StdAttendance' && <StuLeaveDays />}
          {keyName === 'StdPoll' && <StudentPolls />}
          {keyName === 'StdScrollNoticeBoard' && <ScrollNoticeList />}
         
        </div>


        
      </div>
    </>
  );
};

export default CustomPage;
