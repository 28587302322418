import React, { useState, useEffect } from "react";
import DemoStudentImage from "../../assets/png/avatar.png";
import { useParams } from "react-router-dom";
import Navbar from "../../components/Navbar";
import Sidebar from "../../components/Sidebar";
// import useSidebar from '../../hooks/useSidebar';
import { useSidebar } from "../../context/SidebarContext";
import QuickInfoSchoolStrength from "./QuickInfoSchoolStrength";
import QuickInfoStaffStrength from "./QuickInfoStaffStrength";
import QuickInfoFeeSummary from "./QuickInfoFeeSummary";
import QuickInfoFeeSummaryClassList from "./QuickInfoFeeSummaryClassList";
import QuickInfoStaffList from "./QuickInfoStaffList";
import QuickInfoStaffProfile from "./QuickInfoStaffProfile";
import QuicKInfoStudentProfile from "./QuicKInfoStudentProfile";
import QuickInfoStudentsList from "./QuickInfoStudentsList";
import QuickInfoStudentDetailsList from "./QuickInfoStudentDetailsList";
import QuickInfoSingleStudentProfileDetails from "./QuickInfoSingleStudentProfileDetails";
import ErpClassStrengthPage from "./ErpClassStrengthPage";
import QuickInfoErpClassDetailsPage from "./QuickInfoErpClassDetailsPage";

const QuickInfoCustomPage = () => {
  const { customId, keyName } = useParams();
  const [userDetails, setUserDetails] = useState(null);

  const [data, setData] = useState(true);

  // const { sidebarVisible, toggleSidebar } = useSidebar();

  const { isSidebarOpen, toggleSidebar } = useSidebar();

  useEffect(() => {
    if (customId) {
      const decodedData = JSON.parse(atob(customId));
      setUserDetails(decodedData);
    }
  }, [customId, keyName]);

  return (
    <>
      {data && <Navbar data={userDetails} toggleSidebar={toggleSidebar} />}

      <div className="flex">
        {data && <Sidebar data={userDetails} />}

        <div className={`w-full absolute ${isSidebarOpen ? "lg:w-5/6 xl:w-9/12 right-0" : "md:pl-14"}  top-16 pt-0 lg:pt-8 xl:pt-9`}>
          {keyName === "school_strength" && <QuickInfoSchoolStrength />}
          {keyName === "erp_class_strength" && <ErpClassStrengthPage />}
          {keyName === "erp_class_details_page" && <QuickInfoErpClassDetailsPage />}
          {keyName === "staff_strength" && <QuickInfoStaffStrength />}
          {keyName === "fee_summary" && <QuickInfoFeeSummary />}
          {keyName === "fee_summary_student_list" && <QuickInfoFeeSummaryClassList />}
          {keyName === "staff_list" && <QuickInfoStaffList />}
          {keyName === "staff_profile" && <QuickInfoStaffProfile />}
          {keyName === "student_profile" && <QuicKInfoStudentProfile />}
          {keyName === "students_list" && <QuickInfoStudentsList />}
          {/* {keyName === "QuickInfoDetails" && <QuickInfoDetails />} */}
          {keyName === "QuickInfoStudentDetailsList" && <QuickInfoStudentDetailsList />}
          {keyName === "QuickInfoSingleStudentProfileDetails" && <QuickInfoSingleStudentProfileDetails />}
          {/* CTTimeTable
          CTTTimeTable
          CTTodaySch
          CTPoll
          CTCPoll
          CTStudents */}
        </div>
      </div>
    </>
  );
};

export default QuickInfoCustomPage;
