import React, { useState, useEffect } from "react";
import Navbar from "../../components/Navbar";
import Sidebar from '../../components/Sidebar';
import { useSidebar } from "../../context/SidebarContext";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import Spinner from "../../components/Spinner";
import Images from "../../assets/Files/images.png";
import PDFImage from "../../assets/Files/pdficconpng.png";
import powerpointIcon from "../../assets/Files/PPTIcon.png";
import WordIcon from "../../assets/Files/wordicon.png";
import VideoIcon from "../../assets/Files/Video.png";
import Youtube from "../../assets/Files/youtube.png";
import Excel from "../../assets/Files/excel.svg";
import textMessage from "../../assets/Files/textmsg.png";
import OtherImg from "../../assets/Files/otherico.png";
import AudioIcon from "../../assets/Files/musicIcon.png";
import { AiOutlineClose } from "react-icons/ai";
import FolderImageIcon from "../../assets/Files/folderimageicon.png";
import FolderListIcon from "../../assets/Files/folderlisticon.png";
import { getUserDataFromLocalByKey } from "../../API/getStudentInfoByKeyLocal";
import { getAdminCDGalleryList } from "../../API/getAdminCDGalleryListAPI";
function AdminCDGallery() {
  const [userType, setUserType] = useState("");
  const [userDetails, setUserDetails] = useState(null);
  const [data, setData] = useState(true);
  const { customId, className, sec } = useParams();
  const [loading, setLoading] = useState(true);
  const [galleryList, setGalleryList] = useState([]);
  const [selectedNotification, setSelectedNotification] = useState(null);
  const [initialResourceUrl, setInitialResourceUrl] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredNotifications, setFilteredNotifications] = useState([]);
  const [resourceUrl,setResourceUrl] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const users = JSON.parse(localStorage.getItem("registeredUsers")) || [];

      if (customId) {
        const decodedData = JSON.parse(atob(customId));
        let matchedItem = [];

        users.forEach((innerArray) => {
          const [userData] = innerArray;

          if (
            userData.userId === decodedData.userId &&
            userData.userType === decodedData.userType &&
            userData.schoolId === decodedData.schoolId &&
            userData.year === decodedData.year
          ) {
            matchedItem.push(userData);
          }
        });

        const result = await getAdminCDGalleryList(
          decodedData.userId,
          decodedData.schoolId,
          decodedData.userType,
          decodedData.year,
          className,
          sec
        );

         const key="WebResourceAWSDOTNETURL"

         const dataResourceUrl = await getUserDataFromLocalByKey(
          decodedData.userId,
          decodedData.schoolId,
          decodedData.userType,
          decodedData.year,
          key
        );

setResourceUrl(dataResourceUrl)
        const keyval = "webServiceURLAWSDOTNET";
        const dataUrl = await getUserDataFromLocalByKey(
          decodedData.userId,
          decodedData.schoolId,
          decodedData.userType,
          decodedData.year,
          keyval
        );
        console.log(dataUrl,"data url  dknsjkvnk")


        setInitialResourceUrl(dataUrl);

        setGalleryList(result);

        console.log(result);
        setLoading(false);
        setUserDetails(matchedItem);
      }
    };

    fetchData();
  }, [customId]);

  const handleSearch = (value) => {
    setSearchTerm(value);

    // If the search term is empty, reset filtered notifications
    if (value.trim() === "") {
      setFilteredNotifications([]);
    } else {
      // Filter notifications based on the search term
      const filtered = galleryList.filter((notification) =>
        notification.HWSubject.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredNotifications(filtered);
    }
  };

  const handleOpenImageModal = (notification) => {
    setSelectedNotification({
      ...notification,
      photoLocation: notification.PhotoLocation,
    });
  };

  const handleCloseImageModal = () => {
    setSelectedNotification(null);
  };

  const renderImage = (photoLocation) => {
    //console.log(photoLocation);

    if (photoLocation === "") {
      return textMessage;
    }

    if (photoLocation.endsWith(".jpg")) {
      // setContentType('Same')
      return Images;
    } else if (photoLocation.endsWith(".pdf")) {
      // setContentType("Same")
      return PDFImage;
    } else if (photoLocation.includes(".ppt")) {
      // setContentType("Same")
      return powerpointIcon;
    } else if (photoLocation.includes(".doc")) {
      // setContentType("Same")
      return WordIcon;
    } else if (photoLocation.includes(".xls")) {
      // setContentType("Same")
      return Excel;
    } else if (photoLocation.includes(".mp4")) {
      // setContentType("Same")
      return VideoIcon;
    } else if (photoLocation.includes(".mp3")) {
      // setContentType("Same")
      return AudioIcon;
    } else if (photoLocation.includes("v=")) {
      return Youtube;
    } else {
      return OtherImg;
    }
  };

  const checkContentType = () => {
    if (selectedNotification.PhotoLocation.includes("v=")) {
      const videoId = selectedNotification.PhotoLocation.split("v=")[1];

      return (
        <>
          <iframe
            title="Notification Content"
            src={`https://www.youtube.com/embed/${videoId}`}
            className="w-full h-96 border-none"
            style={{ overflow: "auto" }}
          ></iframe>
          <button
            onClick={() =>
              window.open(
                `https://www.youtube.com/watch?v=${videoId}`,
                "_blank"
              )
            }
            className="mt-2 bg-red-500 text-white px-4 py-2 rounded"
          >
            Watch on YouTube
          </button>
        </>
      );
    }

    if (selectedNotification.PhotoLocation === "") {
      return null;
    } else {
      return (
        <iframe
          title="Notification Content"
          src={`${resourceUrl}/StudyMaterials/${selectedNotification.PhotoLocation}`}
          className="w-full h-96 border-none"
          style={{ overflow: "auto" }}
        ></iframe>
      );
    }
  };

  const notificationsToRender = searchTerm
    ? filteredNotifications
    : galleryList;

    const { isSidebarOpen, currentSidebarTab, toggleSidebar,setIsSidebarOpen } = useSidebar();

  return (
    <>
      {data && (
        <Navbar
          data={userDetails}
          // isOpen={sidebarVisible}
          toggleSidebar={toggleSidebar}
        />
      )}

      <div className="flex">
        { data && <Sidebar data={userDetails} />}

        {loading ? (
          <Spinner />
        ) : (
          <div className={`w-full absolute ${isSidebarOpen?"lg:w-4/6 xl:w-9/12":"w-full"} sm:w-11/12 right-0 top-16 mx-auto   p-4 md:p-6 lg:p-8 xl:p-10`}>
            <div className="lg:flex items-center justify-between mb-4">
              <div className="w-2/3">
                <h2 className=" md:text-2xl font-bold text-xl mb-2 md:mb-0">
                  Admin :
                  {userDetails &&
                    userDetails.length > 0 &&
                    userDetails[0].schoolId}
                </h2>
                <h4 className="mb-2 font-bold">Gallery List</h4>
              </div>
              <div className=" w-2/6">
                <input
                  type="text"
                  placeholder="Search..."
                  value={searchTerm}
                  onChange={(e) => handleSearch(e.target.value)}
                  className="border border-gray-300 px-3 py-2 rounded-md mr-2 focus:border-blue-500 focus:outline-none"
                />
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-4">
              {notificationsToRender.map((notification) => (
                <div
                  key={notification.id}
                  onClick={() => handleOpenImageModal(notification)}
                  className="cursor-pointer relative"
                >
                  <div className="border border-gray-200 rounded-lg p-4 hover:shadow-md transition duration-300">
                    <div className="flex items-center">
                      <div className="w-1/3 sm:w-auto mr-4">
                        <img
                          src={renderImage(notification.PhotoLocation)}
                          alt={notification.title}
                          className="w-20 h-20 sm:w-32 sm:h-32 object-cover rounded"
                        />
                      </div>

                      <div className="w-2/3 ">
                        <h2 className="text-lg font-semibold mb-2">
                          {notification.HWSubject}
                        </h2>
                        <h4 className="text-lg font-semibold mb-2">
                          {notification.HWDateStr}
                        </h4>
                        <p className="text-gray-600">
                          {notification.HWRemarks}
                        </p>
                        <p className="text-gray-600">
                           Class : {notification.HWClass} - {notification.HWSection} Subject: {notification.HWSname}
                        </p>
                      </div>


                    </div>
                  </div>
                </div>
              ))}
            </div>
            {selectedNotification && (
              <div className="fixed inset-0  bg-black bg-opacity-75 flex justify-center items-center">
                <div
                  style={{ width: "80%", height: "75%" }}
                  className="bg-white p-4 rounded-lg relative "
                >
                  {checkContentType()}
                  <div
                    onClick={handleCloseImageModal}
                    className="absolute top-0 right-0 cursor-pointer p-2"
                  >
                    <AiOutlineClose />
                  </div>
                  <button
                    onClick={handleCloseImageModal}
                    className="mt-4 ml-4 bg-blue-500 text-white px-4 py-2 rounded"
                  >
                    Close
                  </button>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
}

export default AdminCDGallery;
