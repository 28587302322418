import React, { useState, useEffect } from "react";
import Navbar from "../../components/Navbar";
import { Link, useParams } from "react-router-dom";
import Spinner from "../../components/Spinner";
import { getUserDataFromLocalByKey } from "../../API/getStudentInfoByKeyLocal";
import { getCBSEExamTermMasterList } from "../../API/GetCBSEExamTermMasterList";

function SubjectWiseMarkEntry() {
  const [examList, setExamList] = useState([]);
  const [userDetails, setUserDetails] = useState(null);
  const { customId, className } = useParams();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      if (customId) {
        const decodedData = JSON.parse(atob(customId));
        const users = JSON.parse(localStorage.getItem("registeredUsers")) || [];
        
        const matchedItem = users.flatMap(innerArray => 
          innerArray.filter(userData => 
            userData.userId === decodedData.userId &&
            userData.userType === decodedData.userType &&
            userData.schoolId === decodedData.schoolId &&
            userData.year === decodedData.year
          )
        );

        const result = await getCBSEExamTermMasterList(
          decodedData.userId,
          decodedData.schoolId,
          decodedData.userType,
          decodedData.year
        );

        setExamList(result);
        setLoading(false);
        setUserDetails(matchedItem);
      }
    };

    fetchData();
  }, [customId]);

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className="min-h-screen bg-gray-100">
      <Navbar data={userDetails} />
      
      <main className="container mx-auto px-4 py-8">
        <header className="mb-8">
          <h1 className="text-3xl font-bold text-gray-800">
            Admin: {userDetails && userDetails[0]?.schoolId}
          </h1>
          <h2 className="text-xl font-semibold text-gray-600 mt-2">
            Class Section List for {className}
          </h2>
        </header>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
          {examList.map((item) => (
            <Link
              key={item.ExamTrmID}
              to={`/classteacher/u/${customId}/${item.ExamName}/${item.ExamTrmID}/ClassTeacherSubjectList`}
              className="block"
            >
              <div className="bg-white rounded-lg shadow-lg overflow-hidden hover:shadow-xl transition-shadow duration-300">
                <div className="bg-gradient-to-r from-blue-500 to-indigo-600 p-4">
                  <h3 className="text-xl font-bold text-white text-center">
                    {item.ExamDisplayName}
                  </h3>
                </div>
               
              </div>
            </Link>
          ))}
        </div>
      </main>
    </div>
  );
}

export default SubjectWiseMarkEntry;