import React from "react";
import { AiOutlineMenu } from "react-icons/ai";
import Logo from "../assets/png/pngtree-school-logo.png";
import Avatar from "../assets/png/avataruser.png";
import { Link } from "react-router-dom";
import { useState } from "react";
import CompanyBanner from "../assets/png/Cypher.png";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { useGlobalContext } from "../context/GlobalContext";
import { FiGrid } from "react-icons/fi";
import { RxTextAlignLeft } from "react-icons/rx";


import { getFullStudentInfoValues } from "../API/getFullStudentInfo";
import { getUserDataFromLocalByKey } from "../API/getStudentInfoByKeyLocal";

const Navbar = ({ isOpen, toggleSidebar, data }) => {
  const [value, setValue] = useState(false);
  const [image, setImage] = useState(null);
  const [resourceUrl,setResourceUrl] = useState(null);
  const [folderName,setFolderName]= useState(null);

  const [userDetails, setUserDetails] = useState(null);

  const { globalVariable, setGlobalVariable } = useGlobalContext();

  const { customId } = useParams();

  useEffect(() => {
    const decodedData = JSON.parse(atob(customId));
    setUserDetails(decodedData);

    if (decodedData) {
      const fetchUserData = async () => {
        const rekey="WebResourceAWSDOTNETURL"
        const dataResourceUrl = await getUserDataFromLocalByKey(
         decodedData.userId,
         decodedData.schoolId,
         decodedData.userType,
         decodedData.year,
         rekey
       );
       setResourceUrl(dataResourceUrl)


       if (decodedData?.userType==="Teacher") {
        setFolderName("PICPAY")
       }else{
        setFolderName("PICS")
       }

       
        const key = "PHOTOFILE";
        const returSrc = await getUserDataFromLocalByKey(
          decodedData.userId,
          decodedData.schoolId,
          decodedData.userType,
          decodedData.year,
          key
        );

       



        if (returSrc==="noPhoto.jpg") {
          setImage(Avatar)
        }else{
          setImage(returSrc);
        }


      
        // Do something with returnUrl if needed
      };

      fetchUserData();
    }
  }, [customId]);


  return (
    <>
      <div className="bg-white px-2  fixed top-0 w-full z-40 shadow-sm h-20 flex mx-auto  items-center">
        <div className="flex fixed top-0 px-12 w-full z-50 shadow-sm h-20  justify-between  items-center ">
          {/* <RxTextAlignLeft
            onClick={toggleSidebar}
            className=" mx-2 border-2  md:hidden h-10 w-10 inline-block border-slate-300 rounded-sm   cursor-pointer"
          /> */}

          <div className="flex md:px-32 cursor-pointer items-center ">
            <div>
              <img
                className="h-20 hidden md:inline-block shadow-sm w-20"
                src={Logo}
                alt=""
              />
            </div>
            <h2 className="font-semibold hidden md:inline-block text-lg px-2 ml-1">
              St. Arnold's School
            </h2>
          </div>

          <div
            className={`flex  relative ${
              isOpen ? "mr-0" : "mr-0"
            } items-center`}
          >
            {userDetails && (
              <div className="md:flex-col cursor-pointer flex-row items-center mt-1 px-3 py-3   shadow-sm shadow-slate-50">
                <h2 className="font-bold relative top-1.5  text-custom">
                  {userDetails.userId}{" "}
                </h2>
                <p className="font-bold relative top-0.5  text-orange-500  md:inline-block  text-custom">
                  {userDetails.userType}
                </p>
                <p className="font-bold  text-custom">
                  Year {userDetails.year}
                </p>
              </div>
            )}

            <img
              onClick={() => setValue(!value)}
              
              src={`${resourceUrl}/${folderName}/01/${image}`}
              className="h-10 cursor-pointer object-cover w-10 ml-3 ring-2 ring-gray-200 dark:ring-gray-200 rounded-full"
              alt="image"
            />
            <div className="flex items-center ml-4 ">
              <img
                className="hidden h-10 w-fit rounded-lg shadow-lg md:inline-block shadow-yellow-600-500 px-2"
                src={CompanyBanner}
                alt="Company Banner"
              />
            </div>

            {value && (
              <div className="bg-white shadow-md rounded-md md:mt-10  right-12 md:right-44 px-3 py-2 mt-3 text-black text-center h-24 absolute w-28 top-10  ">
                <div className="px-1 py-2">
                  <Link to="" className="text-center font-semibold mt-2">
                    Profile
                  </Link>
                </div>

                <div>
                  <Link to="/" className="text-center font-semibold mt-2">
                    Logout
                  </Link>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
