import React, { useState, useRef, useEffect } from "react";
import { useParams } from "react-router-dom";
import { FcAssistant } from "react-icons/fc";
import Navbar from "../../components/Navbar";
import Sidebar from "../../components/Sidebar";
import { useSidebar } from "../../context/SidebarContext";
// import useSidebar from "../../hooks/useSidebar";
import { toast } from "react-toastify";
import { getUserDataFromLocalByKey } from "../../API/getStudentInfoByKeyLocal";
import { BsFillMicFill, BsFillMicMuteFill } from "react-icons/bs";
import { FaSquare } from "react-icons/fa6";
import { IoPersonSharp } from "react-icons/io5";
import { UpdateReplyToParentAPI } from "../../API/updateReplyToParent";

const CTReplyToParentSubmit = () => {
  const {
    customId,
    HWSname,
    HWClass,
    HWID,
    HWRemarks,
    TeachReply,
    SoundClipStudent,
    SoundClipTeacher,
  } = useParams();
  const [ctName, setCtName] = useState("");
  const [audio, setAudio] = useState(new Audio());
  const [audioteacher, setAudioTeacher] = useState(new Audio());
  const [isPlaying, setIsPlaying] = useState(false);
  const [isPlayingTeacher, setIsPlayingTeacher] = useState(false);
  const [requestMessage, setRequestMessage] = useState("");
  const [audiobase64, setAudiobase64] = useState("");
  const [userDetails, setUserDetails] = useState(null);
  const [resourceUrl, setResourceUrl] = useState(null);
  const [isAudio, setIsAudio] = useState(false);
  // const { sidebarVisible, toggleSidebar } = useSidebar();
  const { isSidebarOpen, currentSidebarTab, toggleSidebar, setIsSidebarOpen } =
    useSidebar();
  const [data, setData] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const users = JSON.parse(localStorage.getItem("registeredUsers")) || [];

      if (customId) {
        const decodedData = JSON.parse(atob(customId));
        let matchedItem = [];

        users.forEach((innerArray) => {
          const [userData] = innerArray;

          if (
            userData.userId === decodedData.userId &&
            userData.userType === decodedData.userType &&
            userData.schoolId === decodedData.schoolId &&
            userData.year === decodedData.year
          ) {
            matchedItem.push(userData);
          }
        });

        const key = "WebResourceAWSDOTNETURL";

        const dataResourceUrl = await getUserDataFromLocalByKey(
          decodedData.userId,
          decodedData.schoolId,
          decodedData.userType,
          decodedData.year,
          key
        );
        console.log(dataResourceUrl, 888888);
        setResourceUrl(dataResourceUrl);

        const namekeyval = "EmployeeName";
        const username = await getUserDataFromLocalByKey(
          decodedData.userId,
          decodedData.schoolId,
          decodedData.userType,
          decodedData.year,
          namekeyval
        );

        const modifiedUsername = username.replace(/(?:MS|MR)\s*/g, "");

        setCtName(modifiedUsername);
      }
      setRequestMessage(TeachReply == "null" || "" ? "No Reply" : TeachReply);
    };

    fetchData();
  }, [customId]);

  useEffect(() => {
    // Update the audio source whenever the audioFile parameter changes
    audio.src = `${resourceUrl}/PARENTCOMMUNICATION/${SoundClipStudent}`;
    audioteacher.src = SoundClipTeacher;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [SoundClipStudent, SoundClipTeacher, resourceUrl]);

  const togglePlay = () => {
    console.log(audio);
    if (isPlaying) {
      audio.pause();
    } else {
      console.log(123123);
      audio.play();
    }
    setIsPlaying(!isPlaying);
  };

  const togglePlayTeacher = () => {
    if (isPlayingTeacher) {
      audioteacher.pause();
    } else {
      audioteacher.play();
    }
    setIsPlayingTeacher(!isPlayingTeacher);
  };

  const [audioStream, setAudioStream] = useState(null);
  const [isRecording, setIsRecording] = useState(false);
  const audioRef = useRef(null);

  const handleSubmit = async () => {
    if (requestMessage === "") {
      alert("Please enter your reply message");
      return;
    }

    try {
      // Convert audio to Base64
      let audioData;

      if (isAudio) {
        audioData = await convertAudioToBase64();
      }

      console.log(audioData, "audio in Base64");

      // Determine the audio filename
      const audioFilename = audioData ? "a.wav" : "N/A";

      if (audioFilename !== "N/A") {
        const formData = {
          messageToParent: requestMessage,
          base64audio: audioData,
        };
        const decodedData = JSON.parse(atob(customId));
        console.log("Form Data:", formData.base64audio);

        // Call the API
        const result = await UpdateReplyToParentAPI(
          decodedData.userId,
          decodedData.schoolId,
          decodedData.userType,
          decodedData.year,
          formData.messageToParent,
          HWID,
          audioData,
          audioFilename
        );
        toast.success("Updated");
      } else {
        const formData = {
          messageToParent: requestMessage,
          base64audio: "",
        };
        const decodedData = JSON.parse(atob(customId));
        console.log("Form Data:", formData);

        const audioFilenameNew = "Testing";

        // Call the API
        const result = await UpdateReplyToParentAPI(
          decodedData.userId,
          decodedData.schoolId,
          decodedData.userType,
          decodedData.year,
          formData.messageToParent,
          HWID,
          "",
          audioFilenameNew
        );
        toast.success("Updated");
      }

      // Clear the form
      setAudiobase64("")
      setRequestMessage("");
      if (audioRef.current) {
        audioRef.current.src = "";
        audioRef.current.removeAttribute("name"); // Clears the audio source
      }
      setIsAudio(false)
    } catch (error) {
      toast.error("Something went wrong");
      console.error("Error in handleSubmit:", error);
    }
  };

  // Function to convert audio to Base64
  const convertAudioToBase64 = () => {
    return new Promise((resolve, reject) => {
      if (audioRef.current && audioRef.current.src) {
        fetch(audioRef.current.src)
          .then((response) => response.blob())
          .then((audioBlob) => {
            const reader = new FileReader();
            reader.readAsDataURL(audioBlob);
            reader.onloadend = () => {
              resolve(reader.result);
            };
            reader.onerror = reject;
          })
          .catch(reject);
      } else {
        resolve(null); // No audio to convert
      }
    });
  };

  const handleReset = () => {
    // Logic to reset the form

    setRequestMessage("");
  };

  // const startRecording = async () => {

  //    toast.success("Audio Started recording")

  //   try {
  //     const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
  //     setAudioStream(stream);
  //     const audioRecorder = new MediaRecorder(stream);
  //     const audioChunks = [];

  //     audioRecorder.ondataavailable = (e) => {
  //       audioChunks.push(e.data);
  //     };

  //     audioRecorder.onstop = () => {
  //       const audioBlob = new Blob(audioChunks, { type: "audio/wav" });
  //       const audioUrl = URL.createObjectURL(audioBlob);
  //       audioRef.current.src = audioUrl;
  //     };

  //     audioRecorder.start();
  //     setIsRecording(true);
  //   } catch (error) {
  //     console.error("Error accessing microphone:", error);
  //   }
  // };

  const startRecording = async () => {
    // toast.success("Audio Started recording");

    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      setAudioStream(stream);
      const audioRecorder = new MediaRecorder(stream);
      const audioChunks = [];
      let audioFilename = "";

      audioRecorder.ondataavailable = (e) => {
        audioChunks.push(e.data);
      };

      audioRecorder.onstop = () => {
        const audioBlob = new Blob(audioChunks, { type: "audio/wav" });
        const audioUrl = URL.createObjectURL(audioBlob);
        audioRef.current.src = audioUrl;
        audioRef.current.setAttribute("name", audioFilename); // Set the filename as a name attribute
      };

      audioRecorder.start();
      setIsRecording(true);
    } catch (error) {
      console.error("Error accessing microphone:", error);
    }
  };

  const stopRecording = () => {
    if (audioStream) {
      
      const tracks = audioStream.getTracks();
      tracks.forEach((track) => track.stop());
      //   toast.error("Stopped Recording");
      setIsRecording(false);
    }
    setIsAudio(true)
  };

  return (
    <>
      {data && (
        <Navbar
          data={userDetails}
          // isOpen={sidebarVisible}
          toggleSidebar={toggleSidebar}
        />
      )}

      <div className="flex">
        {data && <Sidebar data={userDetails} />}

        <div
          className={`w-full absolute ${
            isSidebarOpen ? "lg:w-4/6 xl:w-9/12" : "w-full"
          } sm:w-11/12 right-0 top-16 mx-auto   p-4 md:p-6 lg:p-8 xl:p-10`}
        >
          <div className="flex items-center justify-between mb-4">
            <div className="w-2/3">
              <h3 className="text-md font-semibold mb-2">
                Teacher Name: {ctName}
              </h3>
              <h5 className="mb-2 font-semibold">Teacher Reply</h5>
            </div>
          </div>

          <div className="bg-slate-50 shadow-md  rounded px-8 pt-6 pb-8 mb-4">
            <div className="mb-6 mt-12  flex items-center">
              <IoPersonSharp className="h-16 w-16 mr-4 text-blue-500" />
              <div>
                <label className="block text-gray-700 font-bold mb-2">
                  Student Name: <span className=" font-medium">{HWSname}</span>
                </label>
                <label className="block text-gray-700 font-bold mb-2">
                  Class : <span className="font-medium">{HWClass}</span>
                </label>
              </div>
            </div>

            <div className="mb-4">
              <div className="border border-red-500 p-4 rounded">
                <label className="block text-red-500 font-semibold mb-2 text-center">
                  Parent's Message
                </label>
                <p className="text-center">{HWRemarks}</p>
              </div>

              {/* Center the button using Flexbox */}
              <div className="flex justify-center mt-4">
                <button
                  onClick={togglePlay}
                  className="bg-green-500 text-white font-semibold px-4 py-2 rounded shadow-md focus:outline-none"
                >
                  {isPlaying ? "Pause" : "Play Student Request"}
                </button>
              </div>

              <div className="mb-4">
                {/* Center the button using Flexbox */}
                <div className="flex justify-center mt-4">
                  {TeachReply !== "null" && TeachReply !== "" && (
                    <button
                      onClick={togglePlayTeacher}
                      className="bg-green-500 text-white font-semibold px-4 py-2 rounded shadow-md focus:outline-none"
                    >
                      {isPlayingTeacher ? "Pause" : "Play Teacher Reply"}
                    </button>
                  )}
                </div>
              </div>
            </div>

            <div className="mb-4">
              <label className="block text-gray-700 font-semibold mb-2">
                Reply Meassage
              </label>
              <textarea
                value={requestMessage}
                onChange={(e) => setRequestMessage(e.target.value)}
                placeholder="Enter reply message here..."
                className="border border-gray-300 rounded px-3 py-2 w-full focus:outline-none"
                rows="5"
              ></textarea>
            </div>

            <div className="flex justify-center">
              <button
                onClick={handleSubmit}
                className="bg-green-500 text-white font-semibold px-4 py-2 rounded shadow-md mr-4 focus:outline-none"
              >
                Submit
              </button>
              <button
                onClick={handleReset}
                className="bg-red-500 text-white font-semibold px-4 py-2 rounded shadow-md focus:outline-none"
              >
                Reset
              </button>
            </div>
          </div>

          {/* Audio Recording Section */}
          <div className="bg-white shadow-md mt-12 rounded px-8 pt-6 mx-auto pb-8 mb-4 w-full md:w-1/2 lg:w-1/3">
            <h1 className="md:text-2xl text-lg font-bold text-center mb-4">
              Audio Recorder
            </h1>

            <div className="mb-4">
              <audio ref={audioRef} controls className="w-full" />
            </div>
          </div>

          <div className=" px-2 py-2">
            <div className="mb-4 px-4 py-4 rounded-full bg-gradient-to-b from-sky-400 to-indigo-500  fixed right-12 bottom-8">
              {/* Conditional rendering based on recording state */}
              {isRecording ? (
                <FaSquare
                  onClick={stopRecording}
                  className="text-white z-50 h-8 w-8 m-auto cursor-pointer"
                />
              ) : (
                <BsFillMicFill
                  onClick={startRecording}
                  className="text-white h-8 w-8 m-auto cursor-pointer"
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CTReplyToParentSubmit;
