import React, { useState, useEffect } from "react";
import Navbar from "../../components/Navbar";
import Sidebar from '../../components/Sidebar';
import { useSidebar } from "../../context/SidebarContext";
import { useParams } from "react-router-dom";
import Spinner from "../../components/Spinner";
import { getUserDataFromLocalByKey } from "../../API/getStudentInfoByKeyLocal";
import StudentProfileDetails from "../../components/StudentProfileDetails";
function StudentDetailsView() {

  const [userType, setUserType] = useState("");
  const [userDetails, setUserDetails] = useState(null);
  const [data, setData] = useState(true);
  const { customId, ADMNO } = useParams();
  const [loading, setLoading] = useState(true);
  const [initialResourceUrl, setInitialResourceUrl] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const users = JSON.parse(localStorage.getItem("registeredUsers")) || [];

      if (customId) {
        const decodedData = JSON.parse(atob(customId));
        let matchedItem = [];

        users.forEach((innerArray) => {
          const [userData] = innerArray;

          if (
            userData.userId === decodedData.userId &&
            userData.userType === decodedData.userType &&
            userData.schoolId === decodedData.schoolId &&
            userData.year === decodedData.year
          ) {
            matchedItem.push(userData);
          }
        });

        const keyval = "webResourceURL";
        const dataUrl = await getUserDataFromLocalByKey(
          decodedData.userId,
          decodedData.schoolId,
          decodedData.userType,
          decodedData.year,
          keyval
        );
        setInitialResourceUrl(dataUrl);
        setLoading(false);
        setUserDetails(matchedItem);
      }
    };

    fetchData();
  }, [customId]);

  //

  const { isSidebarOpen, currentSidebarTab, toggleSidebar,setIsSidebarOpen } = useSidebar();

  return (
    <>
      {data && (
        <Navbar
          data={userDetails}
          // isOpen={sidebarVisible}
          toggleSidebar={toggleSidebar}
        />
      )}

      <div className="flex">
        { data && <Sidebar data={userDetails} />}
        <div className={`w-full absolute ${isSidebarOpen?"lg:w-4/6 xl:w-9/12":"w-full"} sm:w-11/12 right-0 top-16 mx-auto   p-4 md:p-6 lg:p-8 xl:p-10`}>
          <StudentProfileDetails customId={customId} ADMNO={ADMNO} />
        </div>
      </div>
    </>
  );
}

export default StudentDetailsView;
